import React from 'react'
import { Card } from '../FollowUpList/FollowUpListFilter'
import Select, { components } from 'react-select';
import styled from 'styled-components'
import moment from 'moment';
import { isArray } from 'lodash'
import Flex from '../../../components/_styles/Flex';
import { DownArrow } from '../../RadAdmin/Cohorts/Icons'
import DatePicker from 'react-datepicker';

import { createPortal } from "react-dom";

const CalendarContainer = ({ children }) => {
  return createPortal(children, document.body);
};

const SelectContainer = styled.div`
  width: 240px;
`;
const DatePickerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #D2D3D7;
    border-radius: 5px;
    padding: 1px 10px
`
const IconContainer = styled.span`
    width: 18%;
    display: flex;
    align-items: center;
    justify-content: space-around;
`
const DatePickerButton = styled(Flex)`
  align-items: center;
  background: #FFFFFF;
  &:hover {
    cursor: pointer;
  }
`
const DateContainer = styled(Flex)`
  font-size:14px;
  color:#3F4857;
`
export const DatePickerInput = ({ onClick, startDate, endDate, displayFormat }) => {
    const formatDate = (date) => {
        return date ? moment(date).format(displayFormat) : 'No Date';
    }
    return (
        <DatePickerButton onClick={onClick}>
            <DateContainer>{`${formatDate(startDate)} - ${formatDate(endDate)}`}</DateContainer>
        </DatePickerButton>
    )
};

const Option = ({ type, ...props }) => {
    return (
        <>
            <components.Option {...props}>
                <span style={{ marginRight: 5, paddingTop: 4 }}>
                    <input
                        type={"radio"}
                        checked={props.isSelected}
                        onChange={() => null}
                        style={{ marginTop: 5 }}
                    />{" "}
                </span>
                <label>{props.label}</label>
            </components.Option>
        </>
    );
};


function Filter({ options, filterValue, setFilterValue, startDate, setStartDate, endDate, setEndDate, setSelectedDate, type="checkbox", isDisabled=false, selectContainerStyles={}, popperPlacement="auto", hasPortal=false, displayFormat="ll", isClearable=false }) {


    const onChange = dates => {
        if (isArray(dates)) {
            const [start, end] = dates;
            setSelectedDate("")
            setStartDate(start);
            setEndDate(end);
        }
    };


    return (
        <>
                <Card>
                    <SelectContainer style={selectContainerStyles} key={'item'}>
                        <span>
                            {filterValue?.label !== 'Custom date range' ?
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={options}
                                    isDisabled={isDisabled}
                                    value={filterValue}
                                    onChange={value => setFilterValue(value)}
                                    styles={{
                                        option: (styles, { isSelected }) => ({ ...styles, fontSize: '15px', textAlign: 'left', height: '40px', fontWeight: isSelected ? 600 : 100, alignItems: 'center' }),
                                    }}
                                    isSearchable={false}
                                    components={{
                                        Option: (props) => <Option {...props} type={type}/>, // Pass the type prop here
                                      }}
                                    isClearable={isClearable}
                                /> :
                                hasPortal?  
                                <DatePickerContainer>
                                <DatePicker
                                    onChange={onChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat='MM/dd/yyyy'
                                    customInput={<DatePickerInput startDate={startDate} endDate={endDate}  displayFormat={displayFormat}  />}
                                    selectsRange
                                    shouldCloseOnSelect={false}
                                    popperPlacement={popperPlacement}
                                    popperContainer={CalendarContainer}
                                />
                                <IconContainer>
                                    <span>{'|'}</span>
                                    <span onClick={() => {
                                        setFilterValue(null)
                                    }}>
                                        <DownArrow />
                                    </span>
                                </IconContainer>

                                </DatePickerContainer>:
                                <DatePickerContainer>
                                    <DatePicker
                                        onChange={onChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        dateFormat='MM/dd/yyyy'
                                        customInput={<DatePickerInput startDate={startDate} endDate={endDate}  displayFormat={displayFormat} />}
                                        selectsRange
                                        shouldCloseOnSelect={false}
                                        popperPlacement={popperPlacement}
                                    />
                                    <IconContainer>
                                        <span>{'|'}</span>
                                        <span onClick={() => {
                                            setFilterValue(null)
                                        }}>
                                            <DownArrow />
                                        </span>
                                    </IconContainer>

                                </DatePickerContainer>
                            }
                        </span>
                    </SelectContainer>
                </Card>
        </>
    )
}

export default Filter