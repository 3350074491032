import React from "react";

const HorizontalLoader = () => {
  return (
    <svg
      width="50px"
      height="50px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      className="lds-ellipsis"
      style={{ background: "none", position: "relative", top: "-17px" }}
    >
      {/*circle(cx="16",cy="50",r="10")*/}
      <circle cx={84} cy={50} r={0} fill="#ffffff">
        <animate
          attributeName="r"
          values="5;0;0;0;0"
          keyTimes="0;0.25;0.5;0.75;1"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          calcMode="spline"
          dur="1.9s"
          repeatCount="indefinite"
          begin="0s"
        />
        <animate
          attributeName="cx"
          values="84;84;84;84;84"
          keyTimes="0;0.25;0.5;0.75;1"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          calcMode="spline"
          dur="1.9s"
          repeatCount="indefinite"
          begin="0s"
        />
      </circle>
      <circle cx={16} cy={50} r="4.99895" fill="#ffffff">
        <animate
          attributeName="r"
          values="0;5;5;5;0"
          keyTimes="0;0.25;0.5;0.75;1"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          calcMode="spline"
          dur="1.9s"
          repeatCount="indefinite"
          begin="-0.95s"
        />
        <animate
          attributeName="cx"
          values="16;16;50;84;84"
          keyTimes="0;0.25;0.5;0.75;1"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          calcMode="spline"
          dur="1.9s"
          repeatCount="indefinite"
          begin="-0.95s"
        />
      </circle>
      <circle cx={84} cy={50} r="0.00105476" fill="#ffffff">
        <animate
          attributeName="r"
          values="0;5;5;5;0"
          keyTimes="0;0.25;0.5;0.75;1"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          calcMode="spline"
          dur="1.9s"
          repeatCount="indefinite"
          begin="-0.475s"
        />
        <animate
          attributeName="cx"
          values="16;16;50;84;84"
          keyTimes="0;0.25;0.5;0.75;1"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          calcMode="spline"
          dur="1.9s"
          repeatCount="indefinite"
          begin="-0.475s"
        />
      </circle>
      <circle cx="83.9928" cy={50} r={5} fill="#ffffff">
        <animate
          attributeName="r"
          values="0;5;5;5;0"
          keyTimes="0;0.25;0.5;0.75;1"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          calcMode="spline"
          dur="1.9s"
          repeatCount="indefinite"
          begin="0s"
        />
        <animate
          attributeName="cx"
          values="16;16;50;84;84"
          keyTimes="0;0.25;0.5;0.75;1"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          calcMode="spline"
          dur="1.9s"
          repeatCount="indefinite"
          begin="0s"
        />
      </circle>
      <circle cx="49.9928" cy={50} r={5} fill="#ffffff">
        <animate
          attributeName="r"
          values="0;0;5;5;5"
          keyTimes="0;0.25;0.5;0.75;1"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          calcMode="spline"
          dur="1.9s"
          repeatCount="indefinite"
          begin="0s"
        />
        <animate
          attributeName="cx"
          values="16;16;16;50;84"
          keyTimes="0;0.25;0.5;0.75;1"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          calcMode="spline"
          dur="1.9s"
          repeatCount="indefinite"
          begin="0s"
        />
      </circle>
    </svg>
  );
};

export default HorizontalLoader;
