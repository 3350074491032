import React from 'react';
import styled from 'styled-components';
import moment from 'moment';


const MessageCard = styled.button`
  width: 100%;
  border: ${props => props.active? '2px solid #00AEEF': '1px solid #C2CCD9'}
  border-radius: 8px;
  background-color: #FFFFFF;
  margin: 10px 0;
`

const MessageContent = styled.div`

`

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 24px;
`

const Name = styled.p`
  margin: 0;
  padding: 0;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: ${props => !props.isActiveFirst? '#C2CCD9': 'inherit'};
  text-align:left;
`

const Text = styled.p`
  margin: 0;
  padding: 0;
  margin-top: 7px;
  margin-bottom: 20px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: ${props => !props.isActiveFirst? '#C2CCD9': 'inherit'}
`
const Time = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15.08px;
  color: #8692A4;
  color: ${props => !props.isActiveFirst? '#C2CCD9': 'inherit'}
  margin-right:12px;
`
const MessageContainer = styled.div`
  margin-left: 24px;
  display: flex;
  justify-content: left;
  margin-right:12px;
`

const MessagePreview = ({ message, cb, currentChatNumber, isActiveFirst="true", lastMessageAt }) => {
  return (
    <MessageCard onClick={() => cb(message)} active={message.id === currentChatNumber?.id}>
      <MessageContent>
        <NameContainer>
          <Name isActiveFirst={isActiveFirst}>{message.name}</Name>
          <Time isActiveFirst={isActiveFirst}>{lastMessageAt && moment(lastMessageAt).fromNow()}</Time>
        </NameContainer>
        <MessageContainer>
          <Text isActiveFirst={isActiveFirst}>{message.text?.substring(0, 99)}</Text>
        </MessageContainer>
      </MessageContent>
    </MessageCard>
  );
};

export default MessagePreview;
