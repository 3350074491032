import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { DownArrow } from './Icons';
import DoneIcon from '@material-ui/icons/Done'

export const SelectedItemContainer = styled.div`
    border: 1px solid #C2CCD9;
    border-radius: 6px;
    padding: 4px 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Dropdown = styled.div`
    border: 1px solid #C2CCD9;
    border-radius: 6px;
    padding:${prop => prop.padding ? prop.padding : ""} 
    cursor: pointer;
    margin-top: 10px;
    height: auto;
    overflow: scroll;
    max-height: ${props => props.width ? props.width : '200px'}
    width: 100%;
    position: absolute;
    background-color: white;
    z-index: 1;
`
const Item = styled.div`
    border-bottom: 1px solid #C2CCD9;
    display: flex;
    align-items: center;
    padding: 5px 0px;
    cursor: pointer;
`
export const Text = styled.pre`
    color: #3F4857;
    font-family: Roboto Condensed;
    font-weight: 400;
    line-height: 17.6px;
    margin: 0px
    font-size: ${props => props.fontsize ? props.fontsize : "14px"}
`
export const IconContainer = styled.span`
`;

const CheckBox = styled.span`
    width: 17px;
    height: 17px;
    border: 1px solid #8692A4;
    border-radius: 4px;
    margin: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ListContainer = styled.div`
    padding-right: 0px;
    width: 100%;
    display: -webkit-inline-box;
    overflow: hidden;
`;

function DropdownComponent({ options, clickHandler, open, value, placeholder, onChange, bottomHandle }) {

    const handleScroll = (e) => {
        if(typeof bottomHandle === 'function'){
            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            if(bottom){
                bottomHandle()
            }
        }
    }

    return (
        <div style={{ width: '100%', position: 'relative' }}>
            <SelectedItemContainer onClick={clickHandler}>
                {
                    Array.isArray(value) ? 
                    <ListContainer>
                        {
                            !isEmpty(value) ? value.map((val, i) => (
                                <Text key={i} fontsize="16px">{!isEmpty(value) ?`${value.length > 1 && value.length - 1 !== i ?`${val}, `  : val}` : placeholder ? placeholder : 'Choose a value'}</Text>

                            )): <Text>{placeholder? placeholder: 'Choose a value'}</Text>
                        }
                    
                    </ListContainer>
                     :
                    <Text fontsize="16px">{!isEmpty(value) ? value : placeholder ? placeholder : 'Choose a value'}</Text>
                }
                <IconContainer><DownArrow /></IconContainer>
            </SelectedItemContainer>
            {
                open && 
                <Dropdown onScroll={handleScroll}>
                    {
                      options && options.map((item, i) => (
                            <Item key={i} onClick={() => onChange(item)}>
                                <CheckBox>
                                    {
                                        item === value || (Array.isArray(value) && value.includes(item)) &&
                                        <DoneIcon fontSize="small" />
                                    }
                                </CheckBox>
                                <Text>{item}</Text>
                            </Item>
                        ))
                    }
                </Dropdown>
            }
        </div>
    )
}

export default DropdownComponent
