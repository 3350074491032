import API from "../../libs/api";
import { showAlert } from "../radminUploads";

export const GET_USER_SSO_TOKEN_SUCCESS = "GET_USER_SSO_TOKEN_SUCCESS";
export const GET_USER_SSO_TOKEN_FAILED = "GET_USER_SSO_TOKEN_FAILED";


export const getSSOToken =
  () =>
  async (dispatch) => {
    try {
    const {data}=  await API.getRequest(`/api/v1/user/canny_token/`);

      dispatch({
        type: GET_USER_SSO_TOKEN_SUCCESS,
        payload: data.sso_token,
      });
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
      dispatch({
        type: GET_USER_SSO_TOKEN_FAILED,
        payload:error,
      });
    }
  };
