import React from 'react';

export const RefferedPatientsIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="17" height="17" rx="8.5" fill="#00AEEF"/>
    <path d="M8.5 8.5C9.88125 8.5 11 7.38125 11 6C11 4.61875 9.88125 3.5 8.5 3.5C7.11875 3.5 6 4.61875 6 6C6 7.38125 7.11875 8.5 8.5 8.5ZM8.5 9.75C6.83125 9.75 3.5 10.5875 3.5 12.25V13.5H13.5V12.25C13.5 10.5875 10.1687 9.75 8.5 9.75Z" fill="white"/>
    </svg>

  )
}

export const ImagingFollowUpsIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="17" height="17" rx="8.5" fill="#00AEEF"/>
    <path d="M9.5 3H4.5V14H12.5V6.3L9.5 3ZM10.5 11.8H6.5V10.7H10.5V11.8ZM10.5 9.6H6.5V8.5H10.5V9.6ZM9 6.85V3.825L11.75 6.85H9Z" fill="white"/>
    </svg>

  )
}

export const StarIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 0C3.36306 0 0 3.3535 0 7.5C0 11.6465 3.36306 15 7.5 15C11.6369 15 15 11.6465 15 7.5C15 3.3535 11.6369 0 7.5 0ZM12.3153 6.79299L10.3854 8.67516C10.2803 8.7707 10.2325 8.91401 10.2611 9.05733L10.7197 11.7134C10.7771 12.0669 10.414 12.3344 10.0892 12.172L7.70064 10.9204C7.57643 10.8535 7.42357 10.8535 7.29936 10.9204L4.91083 12.172C4.59554 12.3344 4.22293 12.0669 4.28025 11.7134L4.73885 9.05733C4.76752 8.91401 4.71975 8.7707 4.61465 8.67516L2.69427 6.79299C2.43631 6.54459 2.57962 6.1051 2.93312 6.05732L5.59873 5.66561C5.74204 5.6465 5.85669 5.56051 5.92357 5.42675L7.11783 3.00955C7.28025 2.68471 7.73885 2.68471 7.89172 3.00955L9.08599 5.42675C9.15287 5.55096 9.26752 5.6465 9.41083 5.66561L12.0764 6.05732C12.4299 6.1051 12.5637 6.54459 12.3153 6.79299Z" fill="#D9DAE1" />
  </svg>
)

export const StarBlueIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 0C3.36306 0 0 3.3535 0 7.5C0 11.6465 3.36306 15 7.5 15C11.6369 15 15 11.6465 15 7.5C15 3.3535 11.6369 0 7.5 0ZM12.3153 6.79299L10.3854 8.67516C10.2803 8.7707 10.2325 8.91401 10.2611 9.05733L10.7197 11.7134C10.7771 12.0669 10.414 12.3344 10.0892 12.172L7.70064 10.9204C7.57643 10.8535 7.42357 10.8535 7.29936 10.9204L4.91083 12.172C4.59554 12.3344 4.22293 12.0669 4.28025 11.7134L4.73885 9.05733C4.76752 8.91401 4.71975 8.7707 4.61465 8.67516L2.69427 6.79299C2.43631 6.54459 2.57962 6.1051 2.93312 6.05732L5.59873 5.66561C5.74204 5.6465 5.85669 5.56051 5.92357 5.42675L7.11783 3.00955C7.28025 2.68471 7.73885 2.68471 7.89172 3.00955L9.08599 5.42675C9.15287 5.55096 9.26752 5.6465 9.41083 5.66561L12.0764 6.05732C12.4299 6.1051 12.5637 6.54459 12.3153 6.79299Z" fill="#00AEEF" />
  </svg>
)

export const TimerIcon = () => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.58204 2.20117C6.04413 2.20117 4.63743 2.74782 3.52956 3.65161C3.29632 3.84111 3.28174 4.19097 3.49311 4.40963C3.68262 4.59913 3.97416 4.60642 4.17824 4.43878C5.19865 3.60788 6.52518 3.13412 7.96833 3.23616C10.6578 3.42566 12.808 5.61954 12.9538 8.30904C13.1214 11.4067 10.6433 13.9796 7.58204 13.9796C4.61556 13.9796 2.20303 11.5671 2.20303 8.60059H1.18262C1.18262 12.1283 4.05434 15 7.58204 15C11.1972 15 14.1199 11.9898 13.9742 8.34549C13.8503 4.93441 11.0077 2.20117 7.58204 2.20117Z" fill="#D9DAE1" />
    <path d="M1.44443 6.2904L0.0595943 8.13442C-0.0861783 8.33121 0.0523057 8.60089 0.292831 8.60089H3.01878C3.2593 8.60089 3.39779 8.33121 3.2593 8.13442L1.9182 6.2904C1.80158 6.13734 1.56105 6.13005 1.44443 6.2904Z" fill="#D9DAE1" />
    <path d="M7.58098 4.71551V8.60035L4.67281 11.1732C4.51246 11.3117 4.50517 11.5595 4.65824 11.7053C5.45998 12.456 6.54599 12.9079 7.74133 12.8642C9.94249 12.784 11.7428 11.0056 11.8448 8.80443C11.9542 6.49394 10.2122 4.56245 7.97456 4.35837C7.76319 4.3365 7.58098 4.50414 7.58098 4.71551Z" fill="#D9DAE1" />
    <path d="M8.44903 1.20996H6.91113V2.74786H8.44903V1.20996Z" fill="#D9DAE1" />
    <path d="M9.0684 1.5379H6.28414C6.05819 1.5379 5.87598 1.35569 5.87598 1.12974V0.408163C5.87598 0.182216 6.05819 0 6.28414 0H9.0684C9.29434 0 9.47656 0.182216 9.47656 0.408163V1.12974C9.47656 1.35569 9.29434 1.5379 9.0684 1.5379Z" fill="#D9DAE1" />
    <path d="M12.3048 5.00698L10.8908 3.59299C10.7304 3.43264 10.7304 3.17754 10.8908 3.01719L11.401 2.50698C11.5613 2.34663 11.8164 2.34663 11.9768 2.50698L13.3908 3.92098C13.5511 4.08133 13.5511 4.33643 13.3908 4.49678L12.8806 5.00698C12.7202 5.16733 12.4651 5.16733 12.3048 5.00698Z" fill="#D9DAE1" />
  </svg>
)

export const TimerBlueIcon = () => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.58204 2.20117C6.04413 2.20117 4.63743 2.74782 3.52956 3.65161C3.29632 3.84111 3.28174 4.19097 3.49311 4.40963C3.68262 4.59913 3.97416 4.60642 4.17824 4.43878C5.19865 3.60788 6.52518 3.13412 7.96833 3.23616C10.6578 3.42566 12.808 5.61954 12.9538 8.30904C13.1214 11.4067 10.6433 13.9796 7.58204 13.9796C4.61556 13.9796 2.20303 11.5671 2.20303 8.60059H1.18262C1.18262 12.1283 4.05434 15 7.58204 15C11.1972 15 14.1199 11.9898 13.9742 8.34549C13.8503 4.93441 11.0077 2.20117 7.58204 2.20117Z" fill="#00AEEF" />
    <path d="M1.44443 6.2904L0.0595943 8.13442C-0.0861783 8.33121 0.0523057 8.60089 0.292831 8.60089H3.01878C3.2593 8.60089 3.39779 8.33121 3.2593 8.13442L1.9182 6.2904C1.80158 6.13734 1.56105 6.13005 1.44443 6.2904Z" fill="#00AEEF" />
    <path d="M7.58098 4.71551V8.60035L4.67281 11.1732C4.51246 11.3117 4.50517 11.5595 4.65824 11.7053C5.45998 12.456 6.54599 12.9079 7.74133 12.8642C9.94249 12.784 11.7428 11.0056 11.8448 8.80443C11.9542 6.49394 10.2122 4.56245 7.97456 4.35837C7.76319 4.3365 7.58098 4.50414 7.58098 4.71551Z" fill="#00AEEF" />
    <path d="M8.44903 1.20996H6.91113V2.74786H8.44903V1.20996Z" fill="#00AEEF" />
    <path d="M9.0684 1.5379H6.28414C6.05819 1.5379 5.87598 1.35569 5.87598 1.12974V0.408163C5.87598 0.182216 6.05819 0 6.28414 0H9.0684C9.29434 0 9.47656 0.182216 9.47656 0.408163V1.12974C9.47656 1.35569 9.29434 1.5379 9.0684 1.5379Z" fill="#00AEEF" />
    <path d="M12.3048 5.00698L10.8908 3.59299C10.7304 3.43264 10.7304 3.17754 10.8908 3.01719L11.401 2.50698C11.5613 2.34663 11.8164 2.34663 11.9768 2.50698L13.3908 3.92098C13.5511 4.08133 13.5511 4.33643 13.3908 4.49678L12.8806 5.00698C12.7202 5.16733 12.4651 5.16733 12.3048 5.00698Z" fill="#00AEEF" />
  </svg>
)

export const CheckBoxIcon = () => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.0233 8.36672C14.9801 8.34492 14.9368 8.33404 14.8932 8.33404C14.7992 8.33404 14.7165 8.3701 14.6444 8.44235L13.9516 9.13502C13.8868 9.20003 13.8543 9.27931 13.8543 9.37321V12.1222C13.8543 12.5986 13.6851 13.0061 13.3458 13.3454C13.0066 13.6845 12.5989 13.8541 12.1226 13.8541H3.11724C2.64093 13.8541 2.2332 13.6845 1.89409 13.3454C1.5549 13.0061 1.38541 12.5986 1.38541 12.1222V3.11705C1.38541 2.64082 1.5549 2.23312 1.89409 1.8939C2.2332 1.55475 2.64093 1.38522 3.11724 1.38522H12.1227C12.2814 1.38522 12.4438 1.40686 12.6097 1.45019C12.653 1.46452 12.6855 1.47184 12.7069 1.47184C12.8008 1.47184 12.8838 1.43586 12.956 1.36372L13.4863 0.833351C13.5728 0.746877 13.6054 0.642168 13.5836 0.519603C13.562 0.404127 13.4969 0.321103 13.3887 0.270644C12.9993 0.0904926 12.577 0 12.1224 0H3.11724C2.25856 0 1.52431 0.304877 0.914556 0.914631C0.304877 1.52446 0 2.2586 0 3.11731V12.1227C0 12.9813 0.304877 13.7155 0.914594 14.3252C1.52435 14.9352 2.2586 15.24 3.11728 15.24H12.1226C12.9812 15.24 13.7155 14.9352 14.3252 14.3252C14.935 13.7155 15.2402 12.9813 15.2402 12.1227V8.68054C15.2401 8.52913 15.1676 8.42457 15.0233 8.36672Z" fill="#D9DAE1" />
    <path d="M17.7408 2.15359L16.5501 0.962851C16.3769 0.789713 16.1712 0.703125 15.933 0.703125C15.6951 0.703125 15.4891 0.789713 15.3161 0.962851L8.31304 7.96589L5.46656 5.11922C5.29335 4.94597 5.08765 4.85949 4.84961 4.85949C4.61153 4.85949 4.4059 4.94597 4.23265 5.11922L3.04207 6.3098C2.86889 6.48298 2.78223 6.68868 2.78223 6.92687C2.78223 7.16495 2.86889 7.37058 3.04207 7.54383L7.69624 12.198C7.86945 12.3713 8.07515 12.4577 8.3132 12.4577C8.55124 12.4577 8.7569 12.3713 8.93015 12.198L17.7407 3.38746C17.9138 3.21432 18.0006 3.00858 18.0006 2.7705C18.0006 2.53243 17.914 2.32672 17.7408 2.15359Z" fill="#D9DAE1" />
  </svg>
)

export const CheckBoxBlueIcon = () => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.0233 8.36672C14.9801 8.34492 14.9368 8.33404 14.8932 8.33404C14.7992 8.33404 14.7165 8.3701 14.6444 8.44235L13.9516 9.13502C13.8868 9.20003 13.8543 9.27931 13.8543 9.37321V12.1222C13.8543 12.5986 13.6851 13.0061 13.3458 13.3454C13.0066 13.6845 12.5989 13.8541 12.1226 13.8541H3.11724C2.64093 13.8541 2.2332 13.6845 1.89409 13.3454C1.5549 13.0061 1.38541 12.5986 1.38541 12.1222V3.11705C1.38541 2.64082 1.5549 2.23312 1.89409 1.8939C2.2332 1.55475 2.64093 1.38522 3.11724 1.38522H12.1227C12.2814 1.38522 12.4438 1.40686 12.6097 1.45019C12.653 1.46452 12.6855 1.47184 12.7069 1.47184C12.8008 1.47184 12.8838 1.43586 12.956 1.36372L13.4863 0.833351C13.5728 0.746877 13.6054 0.642168 13.5836 0.519603C13.562 0.404127 13.4969 0.321103 13.3887 0.270644C12.9993 0.0904926 12.577 0 12.1224 0H3.11724C2.25856 0 1.52431 0.304877 0.914556 0.914631C0.304877 1.52446 0 2.2586 0 3.11731V12.1227C0 12.9813 0.304877 13.7155 0.914594 14.3252C1.52435 14.9352 2.2586 15.24 3.11728 15.24H12.1226C12.9812 15.24 13.7155 14.9352 14.3252 14.3252C14.935 13.7155 15.2402 12.9813 15.2402 12.1227V8.68054C15.2401 8.52913 15.1676 8.42457 15.0233 8.36672Z" fill="#00AEEF" />
    <path d="M17.7408 2.15359L16.5501 0.962851C16.3769 0.789713 16.1712 0.703125 15.933 0.703125C15.6951 0.703125 15.4891 0.789713 15.3161 0.962851L8.31304 7.96589L5.46656 5.11922C5.29335 4.94597 5.08765 4.85949 4.84961 4.85949C4.61153 4.85949 4.4059 4.94597 4.23265 5.11922L3.04207 6.3098C2.86889 6.48298 2.78223 6.68868 2.78223 6.92687C2.78223 7.16495 2.86889 7.37058 3.04207 7.54383L7.69624 12.198C7.86945 12.3713 8.07515 12.4577 8.3132 12.4577C8.55124 12.4577 8.7569 12.3713 8.93015 12.198L17.7407 3.38746C17.9138 3.21432 18.0006 3.00858 18.0006 2.7705C18.0006 2.53243 17.914 2.32672 17.7408 2.15359Z" fill="#00AEEF" />
  </svg>
)

export const LikeIcon = () => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 4.6875V12.1875C0 12.7052 0.419711 13.125 0.937496 13.125H3.74999V3.75H0.937496C0.419711 3.75 0 4.16971 0 4.6875Z" fill="#D9DAE1" />
    <path d="M13.2687 4.68742H10.4992C10.2571 4.68742 10.1358 4.52583 10.095 4.45625C10.0543 4.38712 9.97191 4.20265 10.0891 3.99116L11.0655 2.2329C11.2797 1.84792 11.3017 1.39519 11.1264 0.990984C10.9511 0.586324 10.605 0.292898 10.177 0.18624L9.4885 0.014121C9.32096 -0.0279931 9.14335 0.0255651 9.02662 0.155112L5.28854 4.30793C4.90082 4.7396 4.6875 5.29624 4.6875 5.87576V10.7811C4.6875 12.0734 5.73898 13.1249 7.03124 13.1249L11.6945 13.1244C12.7473 13.1244 13.677 12.4153 13.9549 11.4005L14.9555 6.80821C14.9848 6.6805 15 6.54958 15 6.41866C15 5.46422 14.2231 4.6874 13.2687 4.6874L13.2687 4.68742Z" fill="#D9DAE1" />
  </svg>
)

export const LikeBlueIcon = () => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 4.6875V12.1875C0 12.7052 0.419711 13.125 0.937496 13.125H3.74999V3.75H0.937496C0.419711 3.75 0 4.16971 0 4.6875Z" fill="#00AEEF" />
    <path d="M13.2687 4.68742H10.4992C10.2571 4.68742 10.1358 4.52583 10.095 4.45625C10.0543 4.38712 9.97191 4.20265 10.0891 3.99116L11.0655 2.2329C11.2797 1.84792 11.3017 1.39519 11.1264 0.990984C10.9511 0.586324 10.605 0.292898 10.177 0.18624L9.4885 0.014121C9.32096 -0.0279931 9.14335 0.0255651 9.02662 0.155112L5.28854 4.30793C4.90082 4.7396 4.6875 5.29624 4.6875 5.87576V10.7811C4.6875 12.0734 5.73898 13.1249 7.03124 13.1249L11.6945 13.1244C12.7473 13.1244 13.677 12.4153 13.9549 11.4005L14.9555 6.80821C14.9848 6.6805 15 6.54958 15 6.41866C15 5.46422 14.2231 4.6874 13.2687 4.6874L13.2687 4.68742Z" fill="#00AEEF" />
  </svg>
)

