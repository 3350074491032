import styled from 'styled-components';

export const PageTitle = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 120%;
  color: #0C2945;
`

export const Card = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 40px;
`

export const CardHeader = styled.div`
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #252733;
  padding: 32px;
`
