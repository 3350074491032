import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Panel } from './styles';
import USER_STEPS from './userSteps';
import MultiStepTracker, { Step } from './MultiStepTracker';

const UserStatus = ({ currentStep }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const steps = isMobile ? USER_STEPS.filter((s) => s.label === currentStep.label) : USER_STEPS;

  return (
    <Panel p={30}>
      <MultiStepTracker currentStep={currentStep} single={isMobile}>
        {steps.map(({ IconComponent, label, id }) => (
          <Step key={`multi-${id}`} label={label} IconComponent={IconComponent} single={isMobile} />
        ))}
      </MultiStepTracker>
    </Panel>
  );
};

export default UserStatus;
