import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import logger from 'redux-logger';
import reduxThunk from 'redux-thunk';
import reducerList from './reducers';
import { default as reducer } from 'redux-csrf';

import { persistStore, persistReducer } from 'redux-persist';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import localForage from 'localforage';
import CONSTANTS from '../utils/constants';

const saveSubsetBlacklistFilter = createBlacklistFilter('auth', [
    'loginError',
    'signupError',
    'loading',
    'url',
]);

const persistConfig = {
    key: 'root',
    storage: localForage,
    whitelist: ['auth', 'app'],
    transforms: [saveSubsetBlacklistFilter],
};

const reducers = combineReducers({
    ...reducerList,
    _csrf: reducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

const middlewares = [reduxThunk];

if (process.env.NODE_ENV === CONSTANTS.ENV.DEVELOPMENT ||
  window.location.host === CONSTANTS.ENV.STAGING_DEV) {
    middlewares.push(logger);
}

const middleware = applyMiddleware(...middlewares);

const composeEnhancers = composeWithDevTools({
    name: 'WITHIN_HEALTH',
});

const configureStore = () => {
    let store = createStore(persistedReducer, composeEnhancers(middleware));
    let persistor = persistStore(store);
    return { store, persistor };
};

const configuredStore = configureStore();
export const store = configuredStore.store;
export const persistor = configuredStore.persistor;
