import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import Masked from 'react-input-mask';
import { SubsectionHeader, SectionHolder } from './common';
import CalendarIconInput from '../../../assets/checkout/calendar-input-icon.svg';
import * as checkoutActions from '../../../actions/checkout';
import { Input } from '../../Design/form';
import { isValidDob } from '../../../utils/utils';

const ImgHolder = styled.div`
  position: relative;
`;

const Img = styled.img`
  position: absolute;
  left: 95%;
  top: -30px;
`;

const MaskedWrapper = styled.div`
  margin-top: 10px;

  input {
    width: 100%;
  }
`;

const StyledError = styled.div`
  font-size: 0.778rem;
  margin-top: 4px;
  color: red;
`;

function DoB({ selectDateOfBirth }) {
  const dob = useSelector(state=>state.checkout.dob);
  const [invalidDate, setInvalidDate] = useState(false);
  const handleChange = (e) => {
    setInvalidDate(false);
    selectDateOfBirth(e.target.value);
    if(!isValidDob(e.target.value))setInvalidDate(true);
  };

  return (
    <SectionHolder>
      <SubsectionHeader>Date of birth</SubsectionHeader>
      <Masked mask="99-99-9999" onChange={handleChange} value={dob}>
        {() => (
          <MaskedWrapper>
            <Input />
          </MaskedWrapper>
        )}
      </Masked>
      <ImgHolder>
        <Img src={CalendarIconInput} />
      </ImgHolder>
      <StyledError>{invalidDate && "Please enter a valid date."}</StyledError>
    </SectionHolder>
  );
}

export default connect(null, checkoutActions)(DoB);
