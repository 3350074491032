import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Woman from '../../../assets/checkout/woman.png';
import { Button } from '../../../components/UI/Button';
import { selectCommunicationOption, setCurrentStep } from '../../../actions/checkout/actions';

const PanelHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Panel = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const CommandHolder = styled.div`
  background: #f2f4f9;
  border-radius: 12px 12px 12px 0px;
  padding: 24px;
  font-size: 0.889rem;
  line-height: 1rem;
  color: #3f4857;
`;

const ImgHolder = styled.div`
  margin-top: 20px;
`;

const WomanImg = styled.img`
  height: 36px;
  width: auto;
`;

const Headline = styled.div`
  font-weight: bold;
  font-size: 1rem;
  line-height: 0.3rem;
  color: #0c2945;
  margin-top: 30px;
  text-align: center;
`;

const OrderSummaryHolder = styled.div`
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 15px;
`;

const OrderSummary = styled.div`
  padding: 30px;
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopContainer = styled.div`
  display: flex;
`;

const TopTextHolder = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

const ProductDescription = styled.div`
  font-size: 0.778rem;
  line-height: 1.5rem;
  color: #3f4857;
  margin-top: 5px;
`;

const Divider = styled.div`
  border: 0.5px solid #c2ccd9;
  margin-top: 25px;
  margin-bottom: 15px;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MedHistoryRightPanel = () => {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(selectCommunicationOption('VOICE'));
    dispatch(setCurrentStep('CHECKOUT_SELECT_TIME'));

    window.analytics.track('Get a phone call instead Click');
  }, [dispatch]);

  return (
    <PanelHolder className='five-cols'>
      <Panel>
        <div>
          <CommandHolder>
            We need some additional medical history info about you. Please fill out the form. This information will be
            passed on to a licensed physician to facilitate your imaging order.
          </CommandHolder>
          <ImgHolder>
            <WomanImg src={Woman} />
          </ImgHolder>
          <OrderSummaryHolder>
            <Headline>Prefer to talk over the phone? </Headline>
            <OrderSummary>
              <ContentHolder>
                <TopContainer>
                  <TopTextHolder>
                    <ProductDescription>
                      We totally get it. Select the button below and we will redirect to you select a time to chat.
                    </ProductDescription>
                  </TopTextHolder>
                </TopContainer>
                <Divider />
                <BottomContainer>
                  <Button onClick={onClick} variant='outline' block primary>
                    Get a phone call instead
                  </Button>
                </BottomContainer>
              </ContentHolder>
            </OrderSummary>
          </OrderSummaryHolder>
        </div>
      </Panel>
    </PanelHolder>
  );
};

export default MedHistoryRightPanel;
