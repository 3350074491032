import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Box from '../../../components/_styles/Box';
import { Card, PageTitle } from '../../../styles';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/arrow_left.svg';
import {
  getDoctorAction,
  getPatientsListAction,
  getMorePatientReports,
  resetPatientsDataAction,
} from '../../../actions/radminUploads';
import Flex from '../../../components/_styles/Flex';
import PatientTable from './PatientTable/PatientTable';
import { EmptyState } from '../../../components/EmptyState/EmptyState';
import LoadMoreButton from '../../../components/LoadMoreButton';
import { ReactComponent as EmptyStateImg } from '../../../assets/empty-state-search.svg';

const DoctorName = styled.div`
  margin-left: 10px;
`;

const GoPreviousPageContainer = styled(Flex)`
  width: fit-content;
  align-items: center;
  color: #0c2945 !important;
  &:hover {
    cursor: pointer;
  }
`;

const ImportPatientsBtn = styled(Flex)`
  width: 177px;
  background: #ffc342;
  border-radius: 5px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  &:hover {
    cursor: pointer;
  }
`;

const PageTitleContainer = styled(Flex)`
  justify-content: space-between;
`;

const PatientList = ({ history }) => {
  const patients = useSelector(state => state.radAdmin.patients);
  const doctorForPatients = useSelector(state => state.radAdmin.doctorForPatients.data);
  const { doctorId } = useParams();
  const dispatch = useDispatch();
  const itemsPortionSize = 10;
  const [recommended, setRecommended] = useState(null);
  const [openedDetailsId, setOpenedDetailsId] = useState(null);
  const [displayItemsCount, setDisplayItemsCount] = useState(itemsPortionSize);
  const [pageNumber, setPageNumber] = useState(1);
  const [scroll, setScroll] = useState(null);
  const totalItems = patients.totalItems;

  useEffect(() => {
    if (!doctorForPatients) {
      dispatch(getDoctorAction(doctorId));
    }
  }, []);

  useEffect(() => {
    if (openedDetailsId) {
      dispatch(getMorePatientReports(openedDetailsId));
      setOpenedDetailsId(null);
    }
  }, [openedDetailsId]);

  useEffect(() => {
    if (patients.data && recommended) {
      dispatch(getPatientsListAction(1, doctorId, doctorForPatients.provider, recommended));
    }
    if (patients.data && !recommended) {
      dispatch(getPatientsListAction(1, doctorId, doctorForPatients.provider, null));
    }
  }, [recommended]);

  useEffect(() => {
    if (pageNumber) {
      // history.push(`/auth/radAdmin/referringDocs/${doctorId}/patients/${pageNumber}`);
      window.history.replaceState(null, null, `/auth/radAdmin/referringDocs/${doctorId}/patients/${pageNumber}`)
      dispatch(getPatientsListAction(pageNumber, doctorId, doctorForPatients.provider, recommended));
      setScroll(window.pageYOffset);
    }
  }, [pageNumber]);

  useEffect(() => {
    if (scroll) {
      window.scrollTo(0, scroll + 650);
      setScroll(null);
    }
  }, [scroll]);

  const moveToDoctors = () => {
    history.push('/auth/radAdmin/referringDocs/1');
    window.analytics.track('Patient-List-Referrer-Back-Click');
  };
  
  const moveToImportPatients = () => {
    history.push('/auth/radAdmin/Import');
    window.analytics.track('Patient-List-Import-Patients-Click');
  };

  const onLoadMore = () => {
    setDisplayItemsCount(prevCount => prevCount + itemsPortionSize);
    setPageNumber(prevNumber => prevNumber + 1);
  };

  return (
    <Box p={30}>
      <PageTitle>
        <PageTitleContainer>
          <GoPreviousPageContainer onClick={moveToDoctors}>
            <ArrowLeftIcon />
            <DoctorName>{doctorForPatients && doctorForPatients.name}</DoctorName>
          </GoPreviousPageContainer>
          <ImportPatientsBtn onClick={moveToImportPatients}>Import Patients</ImportPatientsBtn>
        </PageTitleContainer>
      </PageTitle>
      <Card>
        <PatientTable
          patientsData={patients.data && patients.data.slice(0, displayItemsCount)}
          isLoading={patients.isLoading}
          needMoreDetails={setOpenedDetailsId}
          resetDataAction={resetPatientsDataAction}
          noDataRender={() => (
            <EmptyState title={'No Results'} img={() => <EmptyStateImg />}>
              Sorry, there are no results
            </EmptyState>
          )}
        />
        {patients?.data && patients?.data.length !== 0 && (
          <LoadMoreButton title="Load more" handleClick={onLoadMore} isDisabled={totalItems < displayItemsCount} />
        )}
      </Card>
    </Box>
  );
};

export default PatientList;
