import React, { useState, useEffect, forwardRef } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import { isArray } from "lodash";
import Flex from "../../../components/_styles/Flex";
import DatePicker from "react-datepicker";
import { ReactComponent as RemoveRecoIcon } from "../../../assets/close-outline.svg";
import { ReactComponent as CloseIcon } from "../../../assets/cancel-outline.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar-outline.svg";
import {
  getExportReportAction,
  getSavedSearchesData,
} from "../../../actions/radminUploads";
import { Spinner } from "react-bootstrap";
import Select, { components } from "react-select";

const DateClearButton = styled.div`
  cursor: pointer;
  display: flex;
`;

const Modal = styled.div`
  display: ${(props) => (props.showModal ? "flex" : "none")};
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  animation: changeWidth 8s ease-in-out infinite;
  background: rgba(12, 41, 69, 0.38);
  backdrop-filter: blur(10px);
  border-right: 1px solid whitesmoke;
`;

const ModalContentContainer = styled.div`
  width: 386px;
  margin: auto;
  border-radius: 4px;
  background-color: white;
`;

const TitleText = styled.h2`
  color: #0c2945;
  font-weight: 700;
  font-size: 24px;
  font-family: Roboto Condensed;
  margin-bottom: 0;
`;

const DateFilterContainer = styled(Flex)`
  margin-bottom: 0px;
  justify-content: center;
  display: ${(props) =>
    props.isSelected !== undefined
      ? props.isSelected
        ? "block"
        : "none"
      : "block"};
`;

const TimeframeContainer = styled(Flex)`
  width: 80%;
  flex-direction: column;
  gap: 8px;
`;

const Timeframe = styled.p`
  margin: 0;
  padding: 0;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16.4px;
  letter-spacing: 0.2px;
  color: #6d6e6e;
  text-transform: uppercase;
`;

const DatePickerContainer = styled(Flex)`
  width: 227px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

const DatePickerButton = styled(Flex)`
  padding: 0px 10px;
  height: 38px;
  width: 227px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
`;

const DateContainer = styled(Flex)`
  font-size: 14px;
  color: #828282;
  font-family: Open Sans;
  font-weight: 400;
`;

const ExportButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
  gap:24px;
`;

const ExportButton = styled.button`
  width: 120px;
  height: 38px;
  background-color: #00aeef;
  color: #ffffff;
  opacity: ${(props) => (props.disabled ? 0.65 : 1)};
  border-radius: 4px;
  border: 2px solid #00aeef;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: transparent;
    color:#00aeef;
  }
`;

const CancelButton = styled.button`
  width: 120px;
  height: 38px;
  background-color: transparent;
  color: #000000;
  opacity: ${(props) => (props.disabled ? 0.65 : 1)};
  border-radius: 4px;
  border: 2px solid #CFCFCF;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: #CFCFCF;
    color:white;
  }
`;

const ModalHeader = styled.div`
  padding: 25px;
`;

const ModalActions = styled.div`
  display: ${(props) => props.display};
`;

const SelectDropdownTitle = styled.div`
  margin-bottom: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-transform: uppercase;
`;

const SelectContainer = styled.div`
  width: 336px;
  & div.select__control,
  div.select__value-container {
    height: 38px;
    cursor: pointer;
  }
`;


const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 27px;
`;

const BodyContainer = styled.div`
  display: flex;
  gap: 23px;
  flex-direction:column;
`;

const SeparatorText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #6d6e6e;
`;

function ExportReportsModal({ showModal, closeModal }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedValues, setSelectedValues] = useState();
  const [savedSearches, setSavedSearches] = useState([]);

  const [loadingMore, setLoadingMore] = useState(false);
  const [nextPage, setNextPage] = useState();

  const onCloseModal = (e) => {
    e.stopPropagation();
    setStartDate(null);
    setEndDate(null);
    closeModal();
  };

  const DatePickerInput = forwardRef(({ onClick, startDate, endDate }, ref) => {
    const formatDate = (date) => {
      return date ? moment(date).format("MMM, DD, YYYY") : "No Date";
    };
    return (
      <DatePickerButton onClick={onClick} ref={ref}>
        <DateContainer>
          {startDate && endDate
            ? `${formatDate(startDate)} - ${formatDate(endDate)}`
            : "Select date range"}
        </DateContainer>
        {(!startDate || !endDate) && <CalendarIcon />}
        {startDate && endDate && (
          <DateClearButton
            onClick={() => {
              setStartDate(null);
              setEndDate(null);
            }}
          >
            <RemoveRecoIcon style={{ height: "13px" }} />
          </DateClearButton>
        )}
      </DatePickerButton>
    );
  });

  const onChange = (dates) => {
    if (isArray(dates)) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
  };
  const onExportClick = () => {
    setLoading(true);
    const filterObj =  {
      reportText: selectedValues?.search,
      exclude: selectedValues?.exclude,
      location: selectedValues?.filters?.filter(itm=>itm.category ==='location').map(itm=>itm.value),
      radiologist: selectedValues?.filters?.filter(itm=>itm.category ==='radiologist').map(itm=>Number(itm.value)),
      referringPhysician: selectedValues?.filters?.filter(itm=>itm.category ==='referrers').map(itm=>Number(itm.value)),
      examCode: selectedValues?.filters?.filter(itm=>itm.category ==='exam_code').map(itm=>itm.value),
      messageSent:  selectedValues?.filters?.filter(itm=>itm.category ==='message_sent').map(itm=>Boolean(itm.value))?.[0],
      examDate:selectedValues?.filters?.filter(itm=>itm.category ==='exam_date').map(itm=>itm.value)?.[0],
      modality: selectedValues?.filters?.filter(itm=>itm.category ==='report_modality').map(itm=>itm.value),
    }
    if( selectedValues?.filters?.filter(itm=>itm.category ==='exam_code' && itm.negation ==="isNotEqual").length>0){
    delete filterObj.examCode;
    filterObj.examCodeNegated = selectedValues?.filters?.filter(itm=>itm.category ==='exam_code').map(itm=>itm.value)
    }

    if( selectedValues?.filters?.filter(itm=>itm.category ==='report_modality' && itm.negation ==="isNotEqual").length>0){
      delete filterObj.modality;
      filterObj.modalityNegated = selectedValues?.filters?.filter(itm=>itm.category ==='report_modality').map(itm=>itm.value)
      }
     dispatch(
      getExportReportAction(
        startDate ? moment(startDate).format("YYYY-MM-DD") : null,
        endDate ? moment(endDate).format("YYYY-MM-DD") : null,
        filterObj
      )
    ).then((res) => {
      setLoading(false);
      closeModal();
    });
  };

  useEffect(() => {
    dispatch(getSavedSearchesData(1)).then((res) => {
      if (res) {
        setSavedSearches(
          res?.results?.map((itm) => ({
            label:  itm?.name ||  itm.search_text,
            value: itm.id,
            search: itm.search_text,
            category: "search",
            exclude: itm.neg_search_text,
            filters:itm.filters || [],
            id: itm.id
          }))
        );
        if (res?.next) {
          setNextPage(2);
        }
      }
    });
  }, []);

  const loadMoreOptions = () => {
    if (nextPage && !loadingMore) {
      setLoadingMore(true);
      dispatch(getSavedSearchesData(nextPage)).then((res) => {
        setLoadingMore(false);
        if (res) {
          setSavedSearches((prev) => [
            ...prev,
            ...res?.results?.map((itm) => ({
              label:  itm?.name ||  itm.search_text,
              search: itm.search_text,
              value: itm.id,
              category: "search",
              exclude: itm.neg_search_text,
              filters:itm.filters || [],
              id: itm.id
            })),
          ]);
        }
        if (res?.next) {
          setNextPage((prev) => prev + 1);
        } else {
          setNextPage();
        }
      });
    }
  };

  const Option = (props) => {
    return (
      <>
        <components.Option {...props}>
          <span style={{ marginRight: 5, paddingTop: 5 }}>
            <input
              type="radio"
              checked={props.isSelected}
              onChange={() => null}
              style={{ marginTop: 5, cursor: "pointer" }}
            />{" "}
          </span>
          <label style={{ cursor: "pointer" }}>{props.label}</label>
        </components.Option>
      </>
    );
  };

  return (
    <Modal showModal={showModal}>
      <ModalContentContainer>
        <ModalHeader>
          <Header>
            <TitleText>Export Reports</TitleText>

            <CloseIcon style={{ cursor: "pointer" }} onClick={onCloseModal} />
          </Header>
          <BodyContainer>
            <DateFilterContainer>
              <TimeframeContainer>
                <Timeframe>Exam Date</Timeframe>

                <DatePickerContainer>
                  <DatePicker
                    popperPlacement={"auto"}
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    customInput={
                      <DatePickerInput
                        startDate={startDate}
                        endDate={endDate}
                      />
                    }
                    shouldCloseOnSelect={false}
                    selectsRange
                    dateFormat={"dd/MM/yyyy"}
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: "5px, 10px",
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: "viewport",
                      },
                    }}
                  />
                </DatePickerContainer>
              </TimeframeContainer>
            </DateFilterContainer>
           
            <SelectContainer>
              <SelectDropdownTitle>Saved Searches</SelectDropdownTitle>
              <Select
                components={{ Option }}
                className="basic-multi-select"
                classNamePrefix="select"
                options={savedSearches}
                value={selectedValues}
                onChange={(option) => {
                  setSelectedValues(option);
                }}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    backgroundColor: "white",
                    padding: "0px 7px",
                  }),
                  option: (styles) => ({
                    ...styles,
                    fontSize: "14px",
                    cursor: "pointer",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#828282",
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "Open Sans",
                    marginLeft: "0px",
                  }),
                }}
                isLoading={loadingMore}
                onMenuScrollToBottom={loadMoreOptions}
                isClearable={true}
              />
            </SelectContainer>
          </BodyContainer>
        </ModalHeader>
        <ModalActions display="block">
          <ExportButtonContainer>
          <CancelButton
              onClick={()=>{
                closeModal();
              }}
            >
              Cancel
            </CancelButton>
            <ExportButton
              disabled={(!startDate || !endDate) && !selectedValues}
              onClick={onExportClick}
            >
              {loading ? (
                <Spinner size="sm" animation="border" role="status" />
              ) : (
                "Export"
              )}
            </ExportButton>
          </ExportButtonContainer>
        </ModalActions>
      </ModalContentContainer>
    </Modal>
  );
}
export default ExportReportsModal;
