import React from 'react';
import styled, { css } from 'styled-components';

const containerSize = (props) => {
  const { size } = props;

  switch (size) {
    case 'small':
    case 'sm':
      return css`
        max-width: 600px;
      `;
    case 'medium':
    case 'md':
      return css`
        max-width: 848px;
      `;
    default:
      return css`
        max-width: 1700px;
      `;
  }
};

const containerStyles = (props) => css`
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: 481px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  ${containerSize(props)}
`;

const Base = styled.div`
  ${containerStyles}
`;

class Container extends React.Component {
  static defaultProps = {
    styling: containerStyles,
  };

  render() {
    const { children, control, ...rest } = this.props;

    return <Base {...rest}>{children}</Base>;
  }
}

export default Container;
