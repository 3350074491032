import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Flex from '../../UI/Flex/Flex';
import { selectCommunicationOption } from '../../../actions/checkout';

const CommunicationOptionHolder = styled.div`
  background: #ffffff;
  border: ${(props) => (props.active ? '1px solid #3f4857' : '1px solid #dce0e5')};
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 40px;
  cursor: pointer;
`;

const ContentHolder = styled.div`
  padding: 30px;
`;

const ContentHeadline = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: #0c2945;
  text-shadow: 0px 0.5px, 0.5px 0px, 0.5px 0px;
`;

const ImgHolder = styled.div`
  width: 120px;
  margin: auto;
`;

const InfoTextHolder = styled.div`
  margin-left: 24px;
  margin-top: -8px;
  text-align: start;
`;

const InfoText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #3f4857;
  margin-top: 8px;
`;

const CommunicationOption = ({ imgSrc, contentHeadline, infoText, option, next }) => {
  const dispatch = useDispatch();
  const selectedOption = useSelector((state) => state.checkout.communicationOption);

  const onClickOption = useCallback(() => {
    const optionToEvent = {
      'VOICE': 'Select Talk to Doctor',
      'ONLINE': 'Select Online visit'
    };

    dispatch(selectCommunicationOption(option));
    next();

    window.analytics.track(optionToEvent[option]);
  }, [dispatch, next, option]);

  return (
    <CommunicationOptionHolder onClick={onClickOption} active={selectedOption === option}>
      <ContentHolder>
        <Flex>
          <ImgHolder>
            <img src={imgSrc} alt='option-img' />
          </ImgHolder>
          <InfoTextHolder>
            <ContentHeadline>{contentHeadline}</ContentHeadline>
            <InfoText>{infoText}</InfoText>
          </InfoTextHolder>
        </Flex>
      </ContentHolder>
    </CommunicationOptionHolder>
  );
};

export default CommunicationOption;
