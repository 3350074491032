import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  height: ${props => props.height || '70vh'};
  display: flex;
  justify-content: center;
  align-items: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h2`
  margin: 25px 0 0 0;
  padding: 0;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 120%;
  color: #0C2945;
`

const Description = styled.p`
  margin: 4px 0 0 0;
  padding: 0;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #0C2945;
`

const Button = styled.button`
  margin-top: 20px;
  width: 150px;
  height: 40px;
  border: 0;
  border-radius: 5px;
  text-decoration: none;
  background: #00AEEF;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
`

const ImgContainer = styled.div`
  width: 176px;
  height: 145px;
`

export const EmptyState = ({img, title, children, buttonInfo, height,imageContainerStyles={}}) => {
  const RenderImg = img;
  return (
    <Container height={height}>
      <Content>
        { img && <ImgContainer style={imageContainerStyles}><RenderImg/></ImgContainer> }
        <Title>{title}</Title>
        <Description>{children}</Description>
        { buttonInfo && (
          <Button href={buttonInfo.link}>
            <Link to={buttonInfo.link} style={{color: '#FFFFFF', fontFamily:'Roboto Condensed'}}>{buttonInfo.text}</Link>
          </Button>)}       
      </Content>
    </Container>    
  )
}
