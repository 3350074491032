import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { updatePatientProfile } from '../../actions/patientProfile';
import Form from './components/Form';

const Container = styled.div`
  margin: 30px;
  padding: 40px;
  border-radius: 8px;
  border: 1px solid #c2ccd9;
  background: #ffffff;
`;

const Title = styled.div`
  margin-bottom: 40px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #0c2945;
`;

const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const doctor = useSelector((state) => ({
    fname: state.auth.user?.first_name,
    lname: state.auth.user?.last_name
  }));

  const patient = useSelector((state) => ({
    fname: state.patientProfile.user?.first_name,
    lname: state.patientProfile.user?.last_name
  }));

  const onSubmit = useCallback(async () => {
    await dispatch(updatePatientProfile());
    history.goBack();
  }, [dispatch, history]);

  return (
    <Container>
      <Title children='Telemedicine Call Notes' />
      <Form patient={patient} doctor={doctor} onSubmit={onSubmit} />
    </Container>
  );
};

export default Index;
