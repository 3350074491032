import React from 'react';
import styled from "styled-components";
import { ActionBasedIcon, TimeBasedIcon } from '../Icons';
import EnableFeature from '../../../../components/EnableFeature/EnableFeature'

const Text = styled.p`
    font-family: Roboto Condensed;
    font-size: 14px;
    line-height: 17.6px;
    font-weight: 600;
`
const Container = styled.div`
    background-color: ${props => props.bgColor}
    width: 35%;
    border: 1px solid #C2CCD9;
    border-radius: 8px; 
    cursor: pointer;
`
const Heading = styled.p`
    color: ${props => props.color};
    border-bottom: 1px solid #C2CCD9;
    text-align: center;
    padding: 5px 0;
`
const BottomContainer = styled.div`
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const ActionTypeContainer = styled.div`
    display: flex;
    justify-content: space-around
`
const Description = styled.p`
    color: ${props => props.color};
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
`
const DecriptionContainer = styled.div`
    height: 85px
`

const ActionType = ({ bgColor, color, title, Icon, description, nextHandler }) => (
    <Container bgColor={bgColor} onClick={nextHandler}>
        <Heading color={color}>{title}</Heading>
        <BottomContainer>
            <Icon />
            <DecriptionContainer>
                <Description color={color}>{description}</Description>
            </DecriptionContainer>
        </BottomContainer>
    </Container>
)

function CampaignType({ nextHandler, ...props }) {

    return (
        <div style={{ margin: '65px 0px' }}>
            <Text>What causes a person to be entered into a messaging campaign?</Text>
            <ActionTypeContainer>
                <EnableFeature featureName={'enable-action-campaigns'} {...props}>
                <ActionType
                    bgColor="#1A6DFF"
                    color="#F9FAFC"
                    title="Action Based"
                    Icon={() => (<ActionBasedIcon />)}
                    description="Campaigns that are started when an action takes place by the patient, provider or radiologist"
                    nextHandler={() => nextHandler("action")}
                />
                </EnableFeature>
                <ActionType
                    bgColor="#FFD57C"
                    color="#3F4857"
                    title="Time Based"
                    Icon={() => (<TimeBasedIcon />)}
                    description="A campaign that is started based off of a time stamp"
                    nextHandler={() => nextHandler("date")}
                />
            </ActionTypeContainer>
        </div>
    )
}

export default CampaignType
