import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Logo } from '../../../assets/logo.svg';
import { ReactComponent as CheckIcon } from '../../../assets/check.svg';

const AppLogo = styled(Logo)`
  left: 40px;
  position: absolute;
`;

const Container = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 2px solid #e6e9f1;
`;

const Steps = styled.div`
  width: 650px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Step = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ active }) => (active ? '#00AEEF' : '#c2ccd9')};

  svg {
    margin-right: 10px;
  }

  svg path {
    fill: ${({ active }) => (active ? '#00AEEF' : '#c2ccd9')};
  }

  svg path:last-child {
    fill: ${({ active }) => (active ? '#00AEEF' : '#ffffff')};
  }
`;

const Header = ({ activeStep }) => {
  return (
    <Container>
      <AppLogo />
      <Steps>
        <Step active={activeStep === 'PRACTICE_INFO'}>
          <CheckIcon /> Practice Information
        </Step>
        <Step active={activeStep === 'CONFIRM'}>
          <CheckIcon /> Confirm
        </Step>
      </Steps>
    </Container>
  );
};

export default Header;
