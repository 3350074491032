import React, { useState, useRef, useEffect, memo } from 'react';
import { useFormik } from 'formik';
import Select,  { components } from 'react-select';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import equalizer from '../../../assets/equalizer.png';
import filterOutline from '../../../assets/IO-icons/filter-outline.svg';
import close from '../../../assets/close.svg';
import { Card, FormContainer, SelectContainer, FilterContainer, Image, FilterButton, FilterButtonTitle,
  FilterMenuTitle, SelectDropdownTitle, Button,  SelectedItemContainer, SelectedItem, CloseSign} from './Filter';

export const SettingsContainer = styled.div`
  position: relative;
  background: #FFFFFF;
  border: 1px solid #C2CCD9;
  box-sizing: border-box;
  margin: 0 130px 25px 0;
  border-radius: 8px;
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: 200px;
  z-index: 1;
`

const RecomendationFilter = memo(({ onSetSelected, optionsTitle, options, currentValue }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [, setSelectedValue] = useState(null);

  const node = useRef();

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMenu]);

  const handleClickOutside = (e) => {
    if (node.current && node.current.contains(e.target)) {
      return;
    }
    setShowMenu(false);
  };
   
  const formik = useFormik({
    initialValues: {
       input: ''
     },
    onSubmit: (values, {resetForm}) => {
      if (!values.input) {
         setShowSubmit(false);
         showMenu(false);
      } else {
           setShowMenu(false);
           setSelectedValue(values.input);
           setShowSubmit(true)
           onSetSelected(values.input);
           resetForm();
      }
    },
  });

  const defaultValue = (options, value) => {
    return options ? options.find(option => option.value === value) : "";
  };

  const closeSelected = () => {
    setShowMenu(false);
    setShowSubmit(false);
    setSelectedValue(null);
    onSetSelected(null);
  }

  const IndicatorsContainer = props => {
    return (
      <div style={{ backgroundColor: '#FFF' }}>
        <components.IndicatorsContainer {...props} />
      </div>
    );
  };
  return (
    <>
      {
        !showMenu && currentValue && 
          <SelectedItemContainer>
            <SelectedItem> {currentValue}</SelectedItem>
            <CloseSign onClick={closeSelected}><Image src={close} alt={'close sign'}/></CloseSign>
          </SelectedItemContainer>
      }
      { 
        !showSubmit && !currentValue &&
        <FilterContainer onClick={() => setShowMenu(true)}> 
          { 
            !showSubmit && !showMenu && 
              <FilterButton >
                <FilterButtonTitle>Filter</FilterButtonTitle>
                <Image src={filterOutline} alt={'settings icon'} />
              </FilterButton>
          }
          {
            showMenu && !showSubmit? (
              <SettingsContainer ref={node}>
                <Row>
                  <Col sm={3} lg={3} xl={3}>
                    <Card>
                      <FilterMenuTitle> Add filters </FilterMenuTitle>
                      <SelectDropdownTitle>{optionsTitle}</SelectDropdownTitle>
                      <FormContainer>
                        <form onSubmit={formik.handleSubmit}>
                          <SelectContainer>
                            <Select 
                              components={{ IndicatorsContainer }}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={options} 
                              value={defaultValue(options, formik.values.input)}
                              onChange={value => formik.setFieldValue('input', value.value)}
                            />
                          </SelectContainer>
                          <Button>Apply Filters</Button>
                        </form>
                      </FormContainer>
                    </Card>
                  </Col>
                </Row>
              </SettingsContainer>
            )
            : (
              null
            )
          }
        </FilterContainer> 
      }
    </>
)
});

export default RecomendationFilter;
