import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as ChevronRightIcon } from "../../../assets/chevron_right.svg";
import Flex from "../../../components/_styles/Flex";
import DoubleDataCell from "../PatientList/LastScanCell";
import { generateColor } from "../../../utils/utils";
import { useHistory } from "react-router";

const TableRow = styled.div`
  display: flex;
  position: relative;
`;

const TableRowItem = styled.div`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
`;

const PatientCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #c2ccd9;
  border-radius: 8px;
  margin: 0px 0 10px 0;
  &:hover {
    border: 1px solid #00AEEF;
    transition: all 0.3s ease;
  }
`;

const PatientCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 0px;
`;

const StyledChevron = styled(ChevronRightIcon)`
  transform: rotate(${(props) => props.expanded});
  width: 26px;
  height: 26px;
  cursor: pointer;
  & > path {
    fill: #9fa2b4;
  }
  &:hover > path {
    fill: #3f4857;
    transition: all 0.3s ease;
  }
`;

export const AvatarContainer = styled(Flex)`
  margin: 0px 15px 0 0;
`;

export const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
`;

export const UserEmptyAvatar = styled(Flex)`
  background-color: ${(props) => props.bgcolor};
  margin-left: 15px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: #666b86;
  border: 2px solid #ffffff;
  border-radius: 50%;
`;

export const UserIcon = ({ name, avatar }) => {
  const splitName = name.split(" ");
  const initials = (
    (splitName[0] ? splitName[0].charAt(0) : "") +
    (splitName[1] ? splitName[1].charAt(0) : "")
  ).toUpperCase();
  return (
    <Flex>
      <AvatarContainer>
        {avatar ? (
          <UserAvatar src={avatar} />
        ) : (
          <UserEmptyAvatar bgcolor={generateColor()}>
            {initials}
          </UserEmptyAvatar>
        )}
      </AvatarContainer>
    </Flex>
  );
};

const PatientListInfoCard = ({ patientItem, needMoreDetails }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const history = useHistory();
  
  const getPatientDetail = patient => {
    setIsExpanded(!isExpanded);
    window.analytics.track('Patient-Detail-Click');
    patient.id &&
      history.push(
        `/auth/radAdmin/followUpList/patients/patientDetails/${patient?.id}`
      );
    };

  return (
    <TableRow>
      <PatientCard>
        <PatientCardHeader>
          <UserIcon name={`${patientItem.first_name} ${patientItem.last_name}`} />
          <TableRowItem flex={2}>
            <DoubleDataCell
              textAlign="left"
              mainInfo={`${patientItem.first_name} ${patientItem.last_name}`}
            />
          </TableRowItem>
          <TableRowItem flex={2}> 
            <DoubleDataCell textAlign="left" fontWeight="350" mainInfo={patientItem.mrn} />
          </TableRowItem>
          <TableRowItem flex={2}>
            <DoubleDataCell textAlign="left" fontWeight="350" mainInfo={patientItem.phone_number}/>
          </TableRowItem>
          <TableRowItem flex={2}>
          <span 
            onClick={() => {
              getPatientDetail(patientItem)
            }}
            style={{cursor: 'pointer'}}
          >
          <DoubleDataCell textAlign="left" fontWeight="350" mainInfo={patientItem.email}/>
          </span>
          </TableRowItem>
          <TableRowItem flex={0.5}>
            <StyledChevron
              expanded={isExpanded ? "90deg" : "0deg"}
              onClick={() => getPatientDetail(patientItem)}
            />
          </TableRowItem>
        </PatientCardHeader>
      </PatientCard>
   </TableRow>
  );
};

export default PatientListInfoCard;
