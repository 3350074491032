import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const StatusTooltip = withStyles({
  tooltip: {
    backgroundColor: '#F7F8FF',
    color: '',
    maxWidth: 250,
    fontStyle: 'Open SansMedium',
    letterSpacing: '-0.03px',
  },
})(Tooltip);

const CardList = styled.ul`
  margin: 0;
  padding: 10;
  list-style: none;
  position: relative;
  width: 250px;
  min-height: 370px;
  background-color: #F7F8FF;
`

const Container = styled.div`
  padding-top: 10px;
  margin-bottom: 10px;
  background-color: #F7F8FF;
`

const Title = styled.p`
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  color: black;
`

const Description = styled.p`
  padding: 0;
  margin: 0;
  font-size: 11px;
  line-height: 13px;
  color: black;
`

const cardsInfo = [
  {title: `Non-Mature`, description: `The report containing a recommendation for Follow-up is greater than 30 days before the timeframe recommended (Recommended Min Date)`},
  {title: `Coming Due`, description: `The report mentioning a Follow-up contains a recommendation RecoMinDate that was 15 days in the future (Recommended Min Date)`},
  {title: `Overdue`, description: `The report mentioning a Follow-up contains a recommendation date that was 1 day after the RecoMaxDate but before 60 days after`},
  {title: `Adherent`, description: `The reports recommendation was completed with a subsequent imaging report`},
  {title: `Expired`, description: `The recommendation for Follow-up is greater than the Reco Max Date plus 60 days`},
  {title: `Acknowledged`, description: `The referring provider has received a notification and seen the Follow-up recommendation`},
]

const StatusDescriptionCard = ({title, description}) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  )
}

export const StatusHeaderTooltip = ({children}) => {
  return (
    <>
      <StatusTooltip
        title={
          <CardList>{cardsInfo.map((i) => <li key={JSON.stringify(i)}><StatusDescriptionCard title={i.title} description={i.description}/></li>)}
          </CardList>}
        placement={'bottom-start'}
        >
          {children}
      </StatusTooltip>
    </>
  )
}
