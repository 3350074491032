import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Spinner } from 'react-bootstrap';
import { Divider, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    dialogContent: {
        fontSize: "16px",
        padding: "13px 30px 25px 30px",
        fontWeight: "bold"
    },
    title: {
        fontSize: "20px",
        marginBottom: "0px",
        fontWeight: "bold"
    },
    actionSection: {
        display: "flex",
        justifyContent: "space-around",
        width: "70%",
        margin: "0 auto",
        paddingBottom: "20px"
    },
    label: {
        padding: "5px 20px",
        fontSize: "12px",
        fontWeight: "bold"
    },
    rootButton: {
        backgroundColor: " #E55A54",
        '&:hover': {
            backgroundColor: " #E55A54"
        }
    },
    rootCancelButton: {
        backgroundColor: "#00AEEF",
        '&:hover' :{
            backgroundColor: "#00AEEF",
        }
    },
    divider: {
        outerHeight: "5px",
        innerHeight: "5px",
        backgroundColor: "blue",
        width: "100%"
    }
})

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function RemoveConfirmation({ openDialog, dialogHandler, selectedData, content, isLoading, deleteHandler, heading }) {
    const classes = useStyles();

    return (
        <div>
            <Dialog
                open={openDialog}
                aria-labelledby="customized-dialog-title"
                // maxWidth={20}
            >
                <DialogTitle id="customized-dialog-title" onClose={dialogHandler} style={{ borderBottom: '3px solid #4dabf5' }}>
                    <p className={classes.title}>{heading || 'Delete'}</p>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <p className={classes.dialogContent}>{content}</p>
                </DialogContent>
                <div className={classes.actionSection}>
                    <Button
                        classes={{ label: classes.label, root: classes.rootButton }}
                        onClick={deleteHandler}
                        variant="contained"
                        color="secondary"
                    >
                        {isLoading ? <Spinner animation="border" role="status" size="sm" style={{ margin: '0 10px' }} /> : "Delete"}
                    </Button>
                    <Button
                        classes={{ label: classes.label, root: classes.rootCancelButton }}
                        onClick={dialogHandler}
                        variant="contained"
                        color="primary">
                        Cancel
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}