import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ExploreButton, ShowMoreInfoModal } from "../common";

const LeftSection = styled.section`
  background: #e3e6eb;
  border-radius: 12px;
  height: 100%;
  padding: 40px;
  text-align: center;
`;
const ImgHolder = styled.div`
  min-width: 120px;
  margin: auto;
`;
const ProductName = styled.h5`
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  line-height: 128%;
  text-align: center;
  margin-top: 30px;
  text-shadow: 0px 0.5px, 0.5px 0px, 0.5px 0px;
  color: black;
`;

const ProductPrice = styled.div`
  font-size: 28px;
  font-weight: 900;
  color: black;
  align-items: center;
  text-align: center;
  margin-top: -10px;
  text-shadow: 0px 0.5px, 0.5px 0px, 0.5px 0px;
`;

function SchedulingLeftPanel() {
  const plan = useSelector((state) =>
    state.scan.items.find((i) => i.id === state.checkout.planId)
  );
  const [showModal, setShowModal] = useState(false);

  if (!plan) return null;

  return (
    <div className="four-cols">
      <LeftSection>
        <ImgHolder>
          <img src={plan.image} alt='Body cutout' />
        </ImgHolder>
        <ProductName>{plan.name}</ProductName>
        <ProductPrice>{`$${plan.price / 100}`}</ProductPrice>
        <ExploreButton onClick={() => setShowModal(!showModal)} lessMargin>
          Show more info
        </ExploreButton>
      </LeftSection>
      <ShowMoreInfoModal id={plan.id} showModal={showModal} handleShowModal={() => setShowModal(!showModal)}/>
    </div>
  );
}

export default SchedulingLeftPanel;
