import styled from "styled-components";

export const NavigationButton = styled.button`
  padding: 14px 16px;
  background: ${props => (props.previous ? "none" : "#00AEEF")};
  padding-left: ${props => (props.previous ? "0px !important" : "none")};
  color: ${props => (props.previous ? "#3F4857;" : "white")};
  border-radius: 5px;
  border: ${props => (props.previous ? "none" : "#00AEEF")};
  font-size: 0.778rem;
  line-height: 1rem;
  font-weight: bold;
  text-align: center;
  cursor: ${props => (props.disabled ? "" : "pointer")};
  opacity: ${props => (props.disabled ? "0.2" : "1")};
`;
