import React, { useState, useRef, useEffect } from 'react';
import { FormControl, Select, MenuItem, Divider, IconButton, TextareaAutosize, TextField, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { Label } from '../../../../components/Dialog/EditFormCenter';
import styled from 'styled-components';
import { TrackingMessage } from '../Icons';
import SMSContent from './SMSContent';
import ABTestingCampaign from './ABTestingCampaign'
const useStyles = makeStyles(theme => ({
    title: {
        fontSize: '13px',
        color: '#3F4857'
    },
    selectInput: {
        width: 300,
        padding: 10,
        fontSize: 16,
        '& .MuiOutlinedInput-input': {
            padding: 0
        },
    },
    textField: {
        width: '100%',
        '& .MuiOutlinedInput-input': {
            fontSize: 14,
            padding: '13.5px 14px'
        }
    },
    formControlDropdown: {
        width: '100%'
    },
    messageTitle: {
        fontSize: '14px',
        color: '#3F4857',
        lineHeight: '17.6px',
        fontWeight: 600,
        margin: '35px 0 3px 0'

    },
    contentContainer: {
        border: '1px solid #C2CCD9',
        borderRadius: 6,
        height: 200,
        overflow: 'scroll',
        cursor: 'text',
        display: 'flex'
    }
}))

const AddButton = styled.div`
    color: white;
    background-color: #64C67C;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    line-height: 17.6px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
`
const TrackingContainer = styled.div`
    margin-top: 10px
`
const InnnerContainer = styled.div`
    width: 70%;
`

function CampaignEmailAndMessage({ selectedSequence }) {
    const history = useHistory();
    const classes = useStyles();
    const emailContentRef = useRef(null);
    const [campaignType, setCampaignType] = useState("Email");
    const [emailContent, setEmailContent] = useState("")
    const [showABTesting, setABTesting] = useState(false);

    const handleAddToAB = () => {
        setABTesting(true);
    }

    useEffect(() => {
        if (selectedSequence === 'email') {
            setCampaignType('Email')
        } else if (selectedSequence === 'sms') {
            setCampaignType('SMS')
        }
    }, [selectedSequence])

    return (
        <div>
            {!showABTesting ? <>
            <FormControl variant="outlined" className={classes.formControlDropdown}>
                <Label className={classes.title}>Choose a campaign type</Label>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    className={classes.selectInput}
                    value={campaignType}
                    onChange={(e) => setCampaignType(e.target.value)}
                    name="anatomy"
                >
                    <MenuItem value="SMS">SMS</MenuItem>
                    <MenuItem value="Email">Email</MenuItem>
                </Select>
            </FormControl>
            <InnnerContainer>
                {
                    campaignType === 'Email' ?
                        <>

                            <InputLabel className={classes.messageTitle}>Email Subject</InputLabel>
                            <TextField
                                id="outlined-uncontrolled"
                                variant="outlined"
                                className={classes.textField}
                            // onChange={(e) => setTitle(e.target.value)}
                            />
                            <InputLabel className={classes.messageTitle}>Email Content</InputLabel>
                            <div className={classes.contentContainer} onClick={() => emailContentRef.current.focus()}>
                                <TextareaAutosize
                                    // maxRows={8}
                                    aria-label="maximum height"
                                    ref={emailContentRef}
                                    value={emailContent}
                                    onChange={(e) => setEmailContent(e.target.value)}
                                    style={{
                                        width: "99%",
                                        border: "none",
                                        fontSize: 13,
                                        color: '#3F4857',
                                        fontFamily: 'Roboto Condensed',
                                        display: 'inline',
                                        lineHeight: 2,
                                        resize: "none",
                                        margin: '0 auto'
                                    }}
                                    placeholder="Type here..."
                                >
                                </TextareaAutosize>
                            </div>
                        </> :
                        <SMSContent />

                }
                <AddButton onClick={() => handleAddToAB()}>Add Content to A/B Test</AddButton>

                <InputLabel className={classes.messageTitle}>Tracking</InputLabel>
                <TrackingContainer>
                    <TrackingMessage />
                </TrackingContainer>
            </InnnerContainer>
            </>
        : <ABTestingCampaign selectedSequence={selectedSequence} />}

        </div>
    )
}

export default CampaignEmailAndMessage
