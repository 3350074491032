import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import HorizontalLoader from '../HorizontalLoader/HorizontalLoader';
import buttonStyles from './buttonStyles';

const BaseButton = styled.button`
  ${buttonStyles}

  ${(props) =>
    props.loading &&
    css`
      height: 48px;
    `}
`;

class Button extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    href: PropTypes.string,
    size: PropTypes.string,
    block: PropTypes.bool,
    loading: PropTypes.bool,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    success: PropTypes.bool,
    warning: PropTypes.bool,
    danger: PropTypes.bool,
    info: PropTypes.bool,
  };

  static defaultProps = {
    size: 'regular',
    styling: buttonStyles,
    as: 'button',
  };

  static Styles = buttonStyles;

  render() {
    const { as, children, disabled, loading, ...rest } = this.props;

    return (
      <BaseButton
        {...rest}
        as={as}
        loading={loading}
        disabled={(disabled && this.props.as === 'button') || undefined}
      >
        {loading && <HorizontalLoader />}
        {!loading && children}
      </BaseButton>
    );
  }
}

export default Button;
