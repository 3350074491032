import CONSTANTS from './constants';

const isDev =
  process.env.NODE_ENV === CONSTANTS.ENV.DEVELOPMENT ||
  window.location.host === CONSTANTS.ENV.STAGING_DEV;

const isProd =
  process.env.NODE_ENV === CONSTANTS.ENV.PRODUCTION ||
  window.location.host === CONSTANTS.ENV.STAGING_MASTER;

export function getStripeKey() {
    if (isDev) {
        console.log(process.env.NODE_ENV, 'dev-stripe');
        return '';
    } else if (isProd) {
        console.log(process.env.NODE_ENV, 'prod-stripe');
        return '';
    }
}
export function getSegmentKey() {
    if (isDev) {
        console.log(process.env.NODE_ENV, 'dev-segment');
        return CONSTANTS.INTEGRATION.SEGMENT_KEY_TEST;
    } else if (isProd) {
        console.log(process.env.NODE_ENV, 'prod-segment');
        return CONSTANTS.INTEGRATION.SEGMENT_KEY_LIVE;
    }
}

export function getHelloSignKey() {
    if (isDev) {
        console.log(process.env.NODE_ENV, 'dev-hellosign');
        return CONSTANTS.INTEGRATION.HELLO_SIGN_CLIENT_ID_TEST;
    } else if (isProd) {
        console.log(process.env.NODE_ENV, 'prod-hellosign');
        return CONSTANTS.INTEGRATION.HELLO_SIGN_CLIENT_ID;
    }
}

export function getSentryKey() {                                                                                                                                                                                                                                                                                                                      
    console.log(process.env.NODE_ENV, 'sentry');
    return CONSTANTS.INTEGRATION.SENTRY_KEY;
}

export function getCannyAppId() {
    if (isDev) {
        return CONSTANTS.INTEGRATION.CANNY_APP_ID_TEST;
    } else if (isProd) {
        return CONSTANTS.INTEGRATION.CANNY_APP_ID_LIVE;
    }
}


export function getCannyBoardToken() {
    if (isDev) {
        return CONSTANTS.INTEGRATION.CANNY_BOARD_TOKEN_TEST;
    } else if (isProd) {
        return CONSTANTS.INTEGRATION.CANNY_BOARD_TOKEN_LIVE;
    }
}