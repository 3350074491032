import React, { useState, useEffect } from 'react';
import { AccountContentContainer, SubmitButton } from './common';
import VerificationInput from "react-verification-input";
import { useHistory } from 'react-router-dom';
import { Alert, AlertContainer } from 'react-bs-notifier';
import { Alert as ErrorAlert } from 'react-bootstrap';
import { MultiFactorAuthentication, resendVerificationToken } from '../../actions/auth';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const useStyles = makeStyles({
    container: {
        width: '99% !important'
    },
    character: {
        lineHeight: '50px',
        fontSize: '23px !important',
        backgroundColor: 'white !important',
        border: '1px solid #00AEEF !important',
        borderRadius: '8px',
        marginLeft: '8px',
    },
});

export const Heading = styled.p`
    color: #0C2945;
    font-familty: Roboto Condensed;
    font-size: 21px;
    line-height: 35.2px;
    font-weight: 700
`
export const Text = styled.p`
    color: #3F4857;
    font-familty: Roboto Condensed;
    font-size: 13px;
    line-height: 21.95px;
    font-weight: 400;
    margin-bottom: 60px !important;
`
export const VerificationContainer = styled.div`
    margin-bottom: 60px;
`
const ResendCodeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`
const ResendCodeText = styled.p`
    font-size: 15px;
    color: #00AEEF;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600
`


const AlertComponent = ({type, headline, content, timeout = 3000, onDismiss}) => {
    let alertObj = {type, headline, timeout, children: content, active: true } 
    return(
        <AlertContainer>{<Alert {...alertObj} onDismiss={onDismiss}/>}</AlertContainer>
    )
}

function MultiFactorAuth() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const token = useSelector(state => state.auth.token)

    const [verificationCode, setVerificationCode] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState('')

    useEffect(() => {
        if (!token) {
            history.push('/login');
          }
    })

    useEffect(() => {
        if(isSuccess){
            setTimeout(() => {
                setIsSuccess(false)
            }, 3000);
        }
    },[isSuccess])

    const resendToken = () => {
            dispatch(resendVerificationToken())
            .then(res => {
                if(res && res.success){
                    setIsSuccess(true)
                }
            })
    }

    const redirectUser = (currentUser) => {
    
        window.analytics.track('Login Success', { email: currentUser.email });
    
        if (currentUser.role === 'doctor') {
            history.push('/auth/appointments');
            return;
          }
          const isUserHaveUploads = currentUser.isHaveUploads;
    
          if (currentUser.role === 'radiology_admin' && isUserHaveUploads) {
            history.push('/auth/radAdmin/Overview');
            return;
          }
          if (currentUser.role === 'radiology_admin' && !isUserHaveUploads) {
            history.push('/auth/radAdmin/Dashboard');
            return;
          }
    
          history.push('/auth/dashboard');
    }

    const submitHandle = () => {
        if(verificationCode.length !== 6) return
        setError('')
        dispatch(MultiFactorAuthentication(verificationCode))
        .then(res => {
            if(res && res.success){
                redirectUser(res.user, res.checkout)
            }else{
                setError("Verification Failed")
            }
        })
    }

    return (
        <>
        {isSuccess &&
        <AlertComponent 
            type="success" 
            headline="Sucess" 
            content="Token successfully sent!"
            onDismiss={() => setIsSuccess(false)}
        />}
        <AccountContentContainer>
            <Heading>Multi-Factor Authentication</Heading>
            <Text>It looks like we need you to confirm your identity. We just sent you a code to your mobile phone number via SMS. Please verify those numbers below.</Text>
            {error && <ErrorAlert variant='danger'>{error}</ErrorAlert>}
            <VerificationContainer>
                <VerificationInput
                    classNames={{
                        container: classes.container,
                        character: classes.character,
                        characterInactive: "character--inactive",
                        // characterSelected: "character--selected",
                    }}
                    onChange={(value) => setVerificationCode(value)}
                    value={verificationCode}
                    autoFocus
                />
            </VerificationContainer>
            <ResendCodeContainer>
                    <ResendCodeText onClick={resendToken}>Resend the token</ResendCodeText>
            </ResendCodeContainer>
            <SubmitButton onClick={submitHandle} isDisabled={verificationCode.length !== 6}>Verify & Log in</SubmitButton>
      
        </AccountContentContainer>
        </>
    )
}

export default MultiFactorAuth
