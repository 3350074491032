
import { desiredOrderAppointments, desiredOrderEmails, desiredOrderNotes, desiredOrderPrescriptions, desiredOrderRecoHistory, desiredOrderRecommendations, desiredOrderSms } from "./Constants";

 export const flattenIndividualArray = (selectedValues,data, flattenData,uniqueStatuses)=>{
  
        flattenData = data?.map((item) => {
          const obj = {};
          if (item?.data?.length > 0) {
            item.data.forEach((d) => {
              obj[
                selectedValues["group_by"]
                  ?.sort((a, b) => {
                    let desiredOrderArray = "";
                    if (selectedValues["data_type"]?.value === "prescriptions") {
                      desiredOrderArray = desiredOrderPrescriptions;
                    }
                    if (selectedValues["data_type"]?.value === "appointments") {
                      desiredOrderArray = desiredOrderAppointments;
                    }
                    if (selectedValues["data_type"]?.value === "sms") {
                      desiredOrderArray = desiredOrderSms;
                    }
                    if (selectedValues["data_type"]?.value === "emails") {
                      desiredOrderArray = desiredOrderEmails;
                    }
                    if (selectedValues["data_type"]?.value === "reco-history") {
                      desiredOrderArray = desiredOrderRecoHistory;
                    }
                    if (selectedValues["data_type"]?.value === "recommendations") {
                      desiredOrderArray = desiredOrderRecommendations;
                    }
                    if (selectedValues["data_type"]?.value === "notes") {
                      desiredOrderArray = desiredOrderNotes;
                    }
    
                    return (
                      desiredOrderArray.indexOf(a.label) -
                      desiredOrderArray.indexOf(b.label)
                    );
                  })
                  .map((itm) => d[itm.value] || "empty")
                  .join(" - ")
              ] = d["id__count"];
            });
          }
          return { date: item.date, ...obj };
        });
    
        uniqueStatuses = [
          ...new Set(
            data?.flatMap((item) =>
              item.data?.map((d) => {
                return selectedValues["group_by"]
                  ?.sort((a, b) => {
                    let desiredOrderArray = "";
                    if (selectedValues["data_type"]?.value === "prescriptions") {
                      desiredOrderArray = desiredOrderPrescriptions;
                    }
                    if (selectedValues["data_type"]?.value === "appointments") {
                      desiredOrderArray = desiredOrderAppointments;
                    }
                    if (selectedValues["data_type"]?.value === "sms") {
                      desiredOrderArray = desiredOrderSms;
                    }
                    if (selectedValues["data_type"]?.value === "emails") {
                      desiredOrderArray = desiredOrderEmails;
                    }
                    if (selectedValues["data_type"]?.value === "reco-history") {
                      desiredOrderArray = desiredOrderRecoHistory;
                    }
                    if (selectedValues["data_type"]?.value === "recommendations") {
                      desiredOrderArray = desiredOrderRecommendations;
                    }
                    if (selectedValues["data_type"]?.value === "notes") {
                      desiredOrderArray = desiredOrderNotes;
                    }
    
                    return (
                      desiredOrderArray.indexOf(a.label) -
                      desiredOrderArray.indexOf(b.label)
                    );
                  })
                  .map((itm) => d[itm.value] || "empty")
                  .join(" - ");
              })
            )
          ),
        ];
      

        return {flattenData,uniqueStatuses}
    
 }
 

 export const flattenCompareArray =  (data, flattenedData) => {
    flattenedData = data?.flatMap((group) =>
        group?.map((item) => {
          const statusCounts = item?.data?.reduce((acc, statusItem) => {
            const label = item.label || "Unknown Label";
            let status = "";
            if (item.category === "recommendations") {
              if (
                (statusItem.status ||
                  statusItem.status === null ||
                  statusItem.status === "") &&
                (statusItem.modality ||
                  statusItem.modality === "" ||
                  statusItem.modality === null) &&
                (statusItem.anatomy ||
                  statusItem.anatomy === "" ||
                  statusItem.anatomy === null)
              ) {
                status =
                  (statusItem.status || "empty") +
                  " - " +
                  (statusItem.modality || "empty") +
                  " - " +
                  (statusItem.anatomy || "empty");
              } else if (
                (statusItem.modality ||
                  statusItem.modality === "" ||
                  statusItem.modality === null) &&
                (statusItem.anatomy ||
                  statusItem.anatomy === "" ||
                  statusItem.anatomy === null)
              ) {
                status =
                  (statusItem.modality || "empty") +
                  " - " +
                  (statusItem.anatomy || "empty");
              } else if (
                (statusItem.status ||
                  statusItem.status === null ||
                  statusItem.status === "") &&
                (statusItem.modality ||
                  statusItem.modality === "" ||
                  statusItem.modality === null)
              ) {
                status =
                  (statusItem.status || "empty") +
                  " - " +
                  (statusItem.modality || "empty");
              } else if (
                (statusItem.status ||
                  statusItem.status === null ||
                  statusItem.status === "") &&
                (statusItem.anatomy ||
                  statusItem.anatomy === "" ||
                  statusItem.anatomy === null)
              ) {
                status =
                  (statusItem.status || "empty") +
                  " - " +
                  (statusItem.anatomy || "empty");
              } else if (
                statusItem.anatomy ||
                statusItem.anatomy === "" ||
                statusItem.anatomy === null
              ) {
                status = status.anatomy || "empty";
              } else if (
                statusItem.modality ||
                statusItem.modality === "" ||
                statusItem.modality === null
              ) {
                status = statusItem.modality || "empty";
              } else if (
                statusItem.status ||
                statusItem.status === null ||
                statusItem.status === ""
              ) {
                status = statusItem.status || "empty";
              }
            }
            if (
              item.category === "prescriptions" ||
              item.category === "appointments"
            ) {
                if (
                statusItem.modality ||
                statusItem.modality === null ||
                statusItem.modality === ""
              ) {
                status = statusItem.modality || "empty";
              }
            }
            if (item.category === "reco-history") {
              if (
                (statusItem.status_override_from ||
                  statusItem.status_override_from === null ||
                  statusItem.status_override_from === "") &&
                (statusItem.user__email || statusItem.user__email === "") &&
                (statusItem.status_override_to ||
                  statusItem.status_override_to === null ||
                  statusItem.status_override_to === "")
              ) {
                status =
                  (statusItem.user__email || "empty") +
                  " - " +
                  (statusItem.status_override_from || "empty") +
                  " - " +
                  (statusItem.status_override_to || "empty");
              } else if (
                (statusItem.status_override_from ||
                  statusItem.status_override_from === null ||
                  statusItem.status_override_from === "") &&
                (statusItem.status_override_to ||
                  statusItem.status_override_to === null ||
                  statusItem.status_override_to === "")
              ) {
                status =
                  (statusItem.status_override_from || "empty") +
                  " - " +
                  (statusItem.status_override_to || "empty");
              } else if (
                (statusItem.status_override_to ||
                  statusItem.status_override_to === null ||
                  statusItem.status_override_to === "") &&
                (statusItem.user__email || statusItem.user__email === "")
              ) {
                status =
                  (statusItem.user__email || "empty") +
                  " - " +
                  (statusItem.status_override_to || "empty");
              } else if (
                (statusItem.status_override_from ||
                  statusItem.status_override_from === null ||
                  statusItem.status_override_from === "") &&
                (statusItem.user__email || statusItem.user__email === "")
              ) {
                status =
                  (statusItem.user__email || "empty") +
                  " - " +
                  (statusItem.status_override_from || "empty");
              } else if (
                statusItem.user__email ||
                statusItem.user__email === ""
              ) {
                status = statusItem.user__email || "empty";
              } else if (
                statusItem.status_override_from ||
                statusItem.status_override_from === null ||
                statusItem.status_override_from === ""
              ) {
                status = statusItem.status_override_from || "empty";
              } else if (
                statusItem.status_override_to ||
                statusItem.status_override_to === null ||
                statusItem.status_override_to === ""
              ) {
                status = statusItem.status_override_to || "empty";
              }
            }
            if (item.category === "notes") {
              if (
                (statusItem.task ||
                  statusItem.task === null ||
                  statusItem.task === "") &&
                (statusItem.user__email || statusItem.user__email === "")
              ) {
                status =
                  (statusItem.user__email || "empty") +
                  " - " +
                  (statusItem.task || "empty");
              } else if (
                statusItem.user__email ||
                statusItem.user__email === ""
              ) {
                status = statusItem.user__email || "empty";
              } else if (
                statusItem.task ||
                statusItem.task === null ||
                statusItem.task === ""
              ) {
                status = statusItem.task || "empty";
              }
            }
            if (item.category === "sms" || item.category === "emails") {
              status = statusItem.status || "empty";
            }

            if (
              label.includes("Reports") ||
              (label.includes("Prescriptions") && !item?.groupBy) ||
              (label.includes("Appointments") && !item?.groupBy) || 
              (label.includes("Notes") && !item?.groupBy) || 
              (label.includes("Recommendations") && !item?.groupBy) || 
              (label.includes("Sms") && !item?.groupBy) || 
              (label.includes("Emails") && !item?.groupBy) || 
              (label.includes("Reco-history") && !item?.groupBy)  
            ) {
              acc[`${label}`] =
                (acc[`${label} (${status})`] || 0) +
                statusItem.hasOwnProperty("id__count")
                  ? statusItem?.id__count
                  : statusItem?.imagine_metadata__charge_amount__sum;
              return acc;
            } else if (status) {
              acc[`${label} (${status})`] =
                (acc[`${label} (${status})`] || 0) +
                statusItem.hasOwnProperty("id__count")
                  ? statusItem?.id__count
                  : statusItem?.imagine_metadata__charge_amount__sum;
              return acc;
            }
          }, {});
          return {
            date: item.date,
            category: item.category,
            ...statusCounts,
          };
        })
      );

      return {flattenedData}
 }

 export const groupStatusesByCategory = (selectedValues,statuses, statusColors) => {
    return statuses.reduce((acc, status) => {
      const [category, statusName] = status.split(" (");
      const cleanStatus = statusName ? statusName.replace(")", "") : "unknown";

      if (!acc[category]) {
        acc[category] = [];
      }

      if (category.includes("Reports:")) {
        acc[category].push({
          status:
            selectedValues["data_subset"]["reports"]?.value === "charge"
              ? "Sum"
              : "Count",
          color: statusColors[status],
        });
      } else if (category.includes("Appointments:") && !statusName) {
        acc[category].push({
          status: "Count",
          color: statusColors[status],
        });
      } else if (category.includes("Prescriptions:") && !statusName) {
        acc[category].push({
          status: "Count",
          color: statusColors[status],
        });
      } else if (category.includes("Emails:") && !statusName) {
        acc[category].push({
          status: "Count",
          color: statusColors[status],
        });
      } else if (category.includes("Notes:") && !statusName) {
        acc[category].push({
          status: "Count",
          color: statusColors[status],
        });
      } else if (category.includes("Sms:") && !statusName) {
        acc[category].push({
          status: "Count",
          color: statusColors[status],
        });
      } else if (category.includes("Recommendations:") && !statusName) {
        acc[category].push({
          status: "Count",
          color: statusColors[status],
        });
      } else if (category.includes("Reco-history:") && !statusName) {
        acc[category].push({
          status: "Count",
          color: statusColors[status],
        });
      }  else {
        acc[category].push({
          status: cleanStatus,
          color: statusColors[status],
        });
      }

      return acc;
    }, {});
  };