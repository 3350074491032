import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../styles";
import Box from "../../../components/_styles/Box";
import styled from "styled-components";

import SubscriptionCenter from "./SubscriptionCenter";
import { RecoConfigWithFeature } from "../RecoConfigurationList/RecoConfig";
import Roles from "./Roles";
import EnableFeature from "../../../components/EnableFeature/EnableFeature";
import OutboundIntegrations from "../OutboundIntegrations";

import InvitedUsers from "./InvitedUsers";
import { useSelector } from "react-redux";
import ApiTokenGeneration from "./ApiTokenGeneration";
import { useHistory, useLocation } from "react-router";

const SwitchContainer = styled.div`
  padding: 4px;
  display: flex;
  background: #e6e9f1;
  border-radius: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0c2945;
  width: fit-content;
  margin-bottom: 50px;
`;

const SwitchButton = styled.button`
  padding: 7px 35.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.active ? "#0C2945" : "#E6E9F1")};
  color: ${(props) => (props.active ? "#F9FAFC" : "#0C2945")};
  font-weight: ${(props) => (props.active ? 700 : 600)};
  border: 0;
  outline: 0;
  border-radius: 6px;
`;
const Headercontainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Divider = styled.div`
  border-top: 1px solid #b3b3b3;
  margin-bottom: 50px;
`;

const Settings = () => {
  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);
  const location = useLocation();
  const history = useHistory();
  const initialActiveTab =   !permission
  ? "users"
  : permission &&
    rolePermissions
      .find((itm) => itm.name === "Settings")
      ?.permissions.find((itm) => itm.codename === "view_settings")
      ?.is_authorized
  ? "token-generation"
  : ""
  const [activeTab, setActiveTab] = useState(()=>{
    const params = new URLSearchParams(location.search);
    return params.get('type') ?? initialActiveTab
  });
  
  const updateURLParams = (paramsToUpdate) => {
    const params = new URLSearchParams(location.search);

    Object.keys(paramsToUpdate).forEach((key) => {
      if (paramsToUpdate[key]) {
        params.set(key, paramsToUpdate[key]);
      } else {
        params.delete(key);
      }
    });

    history.replace({ search: params.toString() });
  };



  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (!params.get('type')) {
      updateURLParams({ type: initialActiveTab });
    }
  }, []);


  return (
    <Box p={30}>
      <Headercontainer>
        <PageTitle>{!permission? "Admin Settings" :"My Settings"}</PageTitle>
        <SwitchContainer>
          {!permission && (
            <>
              <SwitchButton
                active={activeTab === "users"}
                onClick={() => {
                  window.analytics.track("Manage-Users-Click");
                  setActiveTab("users");
                  updateURLParams({ type: 'users'});
                }}
              >
                Manage Users
              </SwitchButton>

              <SwitchButton
                active={activeTab === "roles"}
                onClick={() => {
                  window.analytics.track("Role-Permissions-Click");
                  setActiveTab("roles");
                  updateURLParams({ type: 'roles'});
                }}
              >
                Role Permissions
              </SwitchButton>

              <SwitchButton
                active={activeTab === "subscriptions"}
                onClick={() => {
                  window.analytics.track("Subsription-Center-Click");
                  setActiveTab("subscriptions");
                  updateURLParams({ type: 'subscriptions'});
                }}
              >
                Subscription Center
              </SwitchButton>
              <EnableFeature featureName={"enable-reco-config"}>
                <SwitchButton
                  active={activeTab === "configs"}
                  onClick={() => {
                    window.analytics.track(
                      "Recommendation-Configuration-Click"
                    );
                    setActiveTab("configs");
                    updateURLParams({ type: 'configs'});
                  }}
                >
                  Recommendation Configuration
                </SwitchButton>
              </EnableFeature>
              <SwitchButton
                  active={activeTab === "token-generation"}
                  onClick={() => {
                    window.analytics.track("API-Token-Generation-Click");
                    setActiveTab("token-generation");
                    updateURLParams({ type: 'token-generation'});
                  }}
                >
                  API Token Generation
                </SwitchButton>
              <SwitchButton
                active={activeTab === "outbounds"}
                onClick={() => {
                  window.analytics.track("Outbound-Integrations-Click");
                  setActiveTab("outbounds");
                  updateURLParams({ type: 'outbounds'});
                }}
              >
                Outbound Integrations
              </SwitchButton>
            </>
          )}

          {permission &&
            rolePermissions
              .find((itm) => itm.name === "Settings")
              ?.permissions.find((itm) => itm.codename === "view_settings")
              ?.is_authorized && (
              <>
                <SwitchButton
                  active={activeTab === "token-generation"}
                  onClick={() => {
                    window.analytics.track("API-Token-Generation-Click");
                    setActiveTab("token-generation");
                    updateURLParams({ type: 'token-generation'});
                  }}
                >
                  API Token Generation
                </SwitchButton>
                <SwitchButton
                  active={activeTab === "subscriptions"}
                  onClick={() => {
                    window.analytics.track("Subsription-Center-Click");
                    setActiveTab("subscriptions");
                    updateURLParams({ type: 'subscriptions'});
                  }}
                >
                  Subscription Center
                </SwitchButton>
              </>
            )}
        </SwitchContainer>
      </Headercontainer>
      <Divider />
      {activeTab === "users" ? (
        <InvitedUsers />
      ) : activeTab === "roles" ? (
        <Roles />
      ) : activeTab === "subscriptions" ? (
        <SubscriptionCenter />
      ) : activeTab === "configs" ? (
        <RecoConfigWithFeature />
      ) : activeTab === "outbounds" ? (
        <OutboundIntegrations />
      ) : activeTab === "token-generation" ? (
        <ApiTokenGeneration />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default Settings;
