import React, { useState } from "react";
import styled from "styled-components";
import {
  FormContainer,
  FormHeader,
  ModifiedFormRow,
  ModifiedFormItem,
  ModifiedInput,
} from "./common";
import {
  FormLabel
} from "../../components/Design/form";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import * as authActions from "../../actions/auth/actions";
import { NavigationButton } from "../../components/Design/button";

const AccountInfoHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const ProfPicImg = styled.img`
  height: 50px;
`;

const AccountInfoText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const AccountHolderName = styled.div`
  font-weight: 600;
  font-size: 1.111rem;
  line-height: 28px;
  color: #0c2945;
`;

const Divider = styled.div`
  border-bottom: 1px solid #c2ccd9;
  margin: 20px 0px;
`;

const SaveButton = styled(NavigationButton)`
  padding: 15px 30px;
  width: 200px;
  float: right;
  margin: 30px 5px 5px 5px;
`;

function fullName(user) {
  if (!user.first_name && !user.last_name) {
    return null;
  }

  let name;

  if (user.first_name) {
    name = user.first_name;
  }

  if (user.last_name) {
    name += " ";
    name += user.last_name;
  }

  return name;
}

function AccountInfo({ updateUser }) {
  const user = useSelector((state) => state.auth.user);
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [email, setEmail] = useState(user.email);
  const [phoneNumber, setPhoneNumber] = useState(user.contactInfo.phone_number);

  const handleSendAccountInfo = (e) => {
    e.preventDefault();
    updateUser({ firstName, lastName, email, phoneNumber, userId: user.id, contactInfoId: user.contactInfo.id });
  };

  return (
    <FormContainer>
      <FormHeader>Account Information</FormHeader>
      <AccountInfoHeader>
        <ProfPicImg
          src="https://previews.123rf.com/images/metelsky/metelsky1809/metelsky180900233/109815470-man-avatar-profile-male-face-icon-vector-illustration-.jpg"
          alt="account-info-prof-pic"
        />
        <AccountInfoText>
          <AccountHolderName>{fullName(user)}</AccountHolderName>
        </AccountInfoText>
      </AccountInfoHeader>
      <Divider />
      <form>
        <ModifiedFormRow>
          <ModifiedFormItem>
            <FormLabel>First Name</FormLabel>
            <ModifiedInput
              placeholder="First name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </ModifiedFormItem>
          <ModifiedFormItem>
            <FormLabel>Last Name</FormLabel>
            <ModifiedInput
              placeholder="Last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </ModifiedFormItem>
        </ModifiedFormRow>
        <ModifiedFormRow>
          <ModifiedFormItem>
            <FormLabel>Email</FormLabel>
            <ModifiedInput
              placeholder="Enter your email here"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </ModifiedFormItem>
          <ModifiedFormItem>
          <FormLabel>Phone Number</FormLabel>
            <ModifiedInput
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </ModifiedFormItem>
        </ModifiedFormRow>
        {/*<FormRow>
          <FormItem>
            <FormLabel>Current Password</FormLabel>
            <ModifiedInput type="password" placeholder="Enter your password here" />
          </FormItem>
        </FormRow>*/}
        <SaveButton primary onClick={(e) => handleSendAccountInfo(e)}>
          Save Account Info
        </SaveButton>
      </form>
    </FormContainer>
  );
}

export default withRouter(connect(null, authActions)(AccountInfo));
