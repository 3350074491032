import React, { useState, forwardRef } from "react";
import styled from "styled-components";
import Flex from "../../../components/_styles/Flex";
import moment from "moment";
import { ReactComponent as CancelOutline } from "../../../assets/cancel-outline.svg";
import { getExportMessages } from "../../../actions/radminUploads";
import { useDispatch } from "react-redux";
import Select, { components } from "react-select";
import Filter from "./Filter";
import { Spinner } from "react-bootstrap";

const Modal = styled.div`
  display: ${(props) => (props.showModal ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  animation: changeWidth 8s ease-in-out infinite;
  background: rgba(12, 41, 69, 0.38);
  backdrop-filter: blur(10px);
  border-right: 1px solid whitesmoke;
`;

const ModalContentContainer = styled.div`
  max-width: 520px;
  width: 70%;
  min-height: 350px;
  margin: auto;
  padding: 25px;
  border-radius: 4px;
  background-color: white;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CloseBtn = styled.span`
  display: inline-block;
  padding: 10px 10px;
  color: #ce2030;
  &:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`;

const ModalTitle = styled.p`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  margin: 0px;
  color: #0c2945;
`;

const ModalInfo = styled.p`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  margin-top: 15px;
  color: #0c2945;
`;

const DateFilterContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
  display: ${(props) =>
    props.isSelected !== undefined
      ? props.isSelected
        ? "block"
        : "none"
      : "block"};
`;

const TimeframeContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    padding: 0px;
    width: 100%;
    & > div {
      width: 100%;
      height: 38px;
      & > span > div > .select__control {
        height: 38px;
      }
      & > span > div {
        height: 38px;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Timeframe = styled.p`
  margin: 0;
  padding: 0;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  letter-spacing: 0.2px;
  color: #6d6e6e;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

export const DatePickerContainer = styled(Flex)`
  width: 235px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

export const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
`;

export const DateContainer = styled(Flex)`
  margin-right: 15px;
`;

const ExportButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ExportButton = styled.button`
  color: white;
  border: none;
  border-radius: 4px;
  width: 227px;
  height: 38px;
  background: ${(props) => props.background};
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 50%;
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: ${(props) => props.marginBottom};
`;

const directionOptions = [
  { value: "inbound and outbound", label: "Incoming & Outgoing" },
  { value: "inbound", label: "Incoming" },
  { value: "outbound", label: "Outgoing" },
];

const options = [
  { value: "Last 7 days", label: "Last 7 days" },
  { value: "Last 30 days", label: "Last 30 days" },
  { value: "Last 3 months", label: "Last 3 months" },
  { value: "Last 12 months", label: "Last 12 months" },
  { value: "Custom date range", label: "Custom date range" },
];

const statusOptions = [
  { value: "all", label: "All SMSes" },
  { value: "drafted", label: "Drafted" },
  { value: "attempted", label: "Attempted" },
  { value: "sent", label: "Sent" },
  { value: "delivered", label: "Delivered" },
  { value: "clicked", label: "Clicked" },
  { value: "converted", label: "Converted" },
  { value: "bounced", label: "Bounced" },
  { value: "failed", label: "Failed" },
  { value: "undeliverable", label: "Undeliverable" },
];

const SelectContainer = styled.div`
  width: 100%;
`;

const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        <span style={{ marginRight: 5, paddingTop: 4 }}>
          <input
            type="radio"
            checked={props.isSelected}
            onChange={() => null}
            style={{ marginTop: 5 }}
          />{" "}
        </span>
        <label>{props.label}</label>
      </components.Option>
    </>
  );
};

const ExportMessageModal = ({ showModal, closeModal }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [directionValue, setDirectionValue] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [statusValue, setStatusValue] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onCloseModal = (e) => {
    e.stopPropagation();
    closeModal();
  };

  React.useEffect(() => {
    if (filterValue) {
      var date = new Date();
      let requiredDate;
      let lastMoment;
      if (filterValue.value === "Custom date range") return;
      setStartDate("");
      setEndDate("");
      if (filterValue.value === "Last 7 days") {
        lastMoment = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
      } else if (filterValue.value === "Last 30 days") {
        lastMoment = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000);
      } else if (filterValue.value === "Last 3 months") {
        lastMoment = new Date(date.getTime() - 90 * 24 * 60 * 60 * 1000);
      }
      if (filterValue.value === "Last 12 months") {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear() - 1;
        if (day < 10) {
          day = "0" + day;
        }
        if (month < 10) {
          month = "0" + month;
        }
        requiredDate = `${year}-${month}-${day}`;
        setSelectedDate(requiredDate);
        return;
      } else {
        let day = lastMoment.getDate();
        let month = lastMoment.getMonth() + 1;
        let year = lastMoment.getFullYear();
        requiredDate = `${year}-${month}-${day}`;
        setSelectedDate(requiredDate);
      }
    }
  }, [filterValue]);

  const handleExportClick = () => {
    let day = new Date().getDate();
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    let requiredDate = `${year}-${month}-${day}`;
    const dateRange = {
      startDate:
        filterValue.value === "Custom date range"
          ? moment(startDate).format("YYYY-MM-DD")
          : selectedDate,
      endDate:
        filterValue.value === "Custom date range"
          ? moment(endDate).format("YYYY-MM-DD")
          : requiredDate,
      directionValue: directionValue?.value || "",
      statusValue: statusValue?.value || "",
    };
    setLoading(true);
    window.analytics.track("Message-Deliveries-Sms-Export-Submit",{...dateRange})
    dispatch(getExportMessages(dateRange)).then(() => {
      closeModal();
      setLoading(false);
    });
  };

  return (
    <Modal showModal={showModal}>
      <ModalContentContainer>
        <ModalActions display="inline-block">
          <ModalTitle>Export SMSes</ModalTitle>
          <CancelOutline
            style={{ cursor: "pointer" }}
            onClick={(e) => onCloseModal(e)}
          />
        </ModalActions>
        <ModalInfo>
          Please select a date range of Message Delivery date that you would
          like to export
        </ModalInfo>

        <Row marginBottom="16px">
          <Col>
            <DateFilterContainer>
              <TimeframeContainer>
                <Timeframe>TIMEFRAME *</Timeframe>
                <Filter
                  options={options}
                  filterValue={filterValue}
                  setFilterValue={setFilterValue}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  setSelectedDate={setSelectedDate}
                  type="radio"
                />
              </TimeframeContainer>
            </DateFilterContainer>
          </Col>
          <Col>
            <Timeframe>Direction</Timeframe>
            <SelectContainer key={"item"}>
              <Select
                className="basic-multi-select"
                classNamePrefix="select"
                options={directionOptions}
                value={directionValue}
                onChange={(value) => setDirectionValue(value)}
                styles={{
                  option: (styles, { isSelected }) => ({
                    ...styles,
                    fontSize: "15px",
                    textAlign: "left",
                    height: "40px",
                    fontWeight: isSelected ? 600 : 100,
                    alignItems: "center",
                  }),
                }}
                isSearchable={false}
                components={{
                  Option,
                }}
              />
            </SelectContainer>
          </Col>
        </Row>

        <Row marginBottom="20px">
          <Col>
            <Timeframe>status</Timeframe>
            <SelectContainer key={"item"}>
              <Select
                className="basic-multi-select"
                classNamePrefix="select"
                options={statusOptions}
                value={statusValue}
                onChange={(value) => setStatusValue(value)}
                styles={{
                  option: (styles, { isSelected }) => ({
                    ...styles,
                    fontSize: "15px",
                    textAlign: "left",
                    height: "40px",
                    fontWeight: isSelected ? 600 : 100,
                    alignItems: "center",
                  }),
                }}
                isSearchable={false}
                components={{
                  Option,
                }}
              />
            </SelectContainer>
          </Col>
          <Col />
        </Row>

        <ExportButtonContainer>
          <ExportButton disabled={!filterValue}  background={!filterValue ? "#00AEEF4D" : "#00AEEF"} onClick={handleExportClick}>
          {loading ? <Spinner animation="border" role="status" size="sm" /> : 'Export'}
          </ExportButton>
        </ExportButtonContainer>
      </ModalContentContainer>
    </Modal>
  );
};
export default ExportMessageModal;
