import {
    SELECT_COMMUNICATION_OPTION,
    INCREMENT_CHECKOUT_STEP,
    SELECT_GENDER,
    TOGGLE_MEDICAL_CONDITION,
    TOGGLE_SYMPTOM,
    TOGGLE_CURRENT_MEDS,
    TOGGLE_ALLERGY_OPTION,
    TOGGLE_METAL_ITEM,
    SELECT_DATE_OF_BIRTH,
    SEND_SCAN_PURCHASE_SUCCESS,
    SEND_SCAN_PURCHASE_FAILURE,
    RESET_PURCHASE,
    SET_PROVIDER_ID,
    SET_LOCATION_ID,
    SET_PLAN_ID,
    SET_DATE_TIME,
    SET_CURRENT_STEP,
    RESET_STATE,
    PATCH_STATE,
    SEND_PAYMENT_SUCCESS,
    SEND_PAYMENT_FAILURE,
    CREATE_APPOINTMENT_RESOLVE
} from '../actions/checkout';

const initialState = {
    providerId: null,
    locationId: null,
    planId: null,
    dateTime: {
        'pre-scan': null,
        'scan': null
    },
    communicationOption: '',
    activeStep: 0,
    currentStep: '',
    checkoutId: '',
    checkoutUrl: '',
    dob: '',
    gender: '',
    medicalConditions: {},
    symptoms: {},
    currentMeds: '',
    allergyOption: '',
    metalItems: {},
    paymentResponse: { isError: false, isDone: false },
    appointments: []
};

const checkoutReducer = (state = initialState, action) => {
    switch (action.type) {
    case PATCH_STATE:
        return {
            ...state,
            ...action.payload
        };
    case RESET_STATE:
        return {
            ...initialState
        };
    case SET_PROVIDER_ID:
        return {
            ...state,
            providerId: action.payload
        };
    case SET_LOCATION_ID:
        return {
            ...state,
            locationId: action.payload
        };
    case SET_PLAN_ID:
        return {
            ...state,
            planId: action.payload
        };
    case SET_DATE_TIME:
        return {
            ...state,
            dateTime: {
                ...state.dateTime,
                [action.payload.type]: action.payload.data
            }
        };
    case SELECT_COMMUNICATION_OPTION:
        return {
            ...state,
            communicationOption: action.option
        };
    case INCREMENT_CHECKOUT_STEP:
        return {
            ...state,
            activeStep: state.activeStep + action.payload
        };
    case SELECT_GENDER:
        return {
            ...state,
            gender: action.gender
        };
    case TOGGLE_MEDICAL_CONDITION:
        return {
            ...state,
            medicalConditions:
          action.payload === 'None'
              ? {
                  'None': !state.medicalConditions['None']
              }
              : {
                  ...state.medicalConditions,
                  [action.payload]: !state.medicalConditions[action.payload],
                  'None': false
              }
        };
    case TOGGLE_METAL_ITEM:
        return {
            ...state,
            metalItems:
          action.payload === 'None'
              ? {
                  'None': !state.metalItems['None']
              }
              : {
                  ...state.metalItems,
                  [action.payload]: !state.metalItems[action.payload],
                  'None': false
              }
        };
    case TOGGLE_SYMPTOM:
        return {
            ...state,
            symptoms:
          action.payload === 'Just preventive scan'
              ? {
                  'Just preventive scan': !state.symptoms['Just preventive scan']
              }
              : {
                  ...state.symptoms,
                  [action.payload]: !state.symptoms[action.payload],
                  'Just preventive scan': false
              }
        };
    case TOGGLE_CURRENT_MEDS:
        return {
            ...state,
            currentMeds: action.answer
        };
    case TOGGLE_ALLERGY_OPTION:
        return {
            ...state,
            allergyOption: action.option
        };
    case SELECT_DATE_OF_BIRTH:
        return {
            ...state,
            dob: action.option
        };
    case SET_CURRENT_STEP:
        return {
            ...state,
            currentStep: action.payload
        };
    case SEND_SCAN_PURCHASE_SUCCESS:
        return {
            ...state,
            paymentResponse: { isError: false, isDone: true, isSuccess: false, ...action.payload }
        };
    case SEND_SCAN_PURCHASE_FAILURE:
        return {
            ...state,
            paymentResponse: { isError: true, isDone: true, isSuccess: false, ...action.payload }
        };
    case SEND_PAYMENT_SUCCESS:
        return {
            ...state,
            paymentResponse: { isError: false, isDone: true, isSuccess: true, ...action.payload }
        };
    case SEND_PAYMENT_FAILURE:
        return {
            ...state,
            paymentResponse: { isError: true, isDone: true, isSuccess: false, ...action.payload }
        };
    case RESET_PURCHASE:
        return {
            ...state,
            paymentResponse: { isError: false, isDone: false, isSuccess: false }
        };
    case CREATE_APPOINTMENT_RESOLVE:
        return {
            ...state,
            appointments: [...state.appointments, action.payload]
        };
    default:
        return state;
    }
};

export default checkoutReducer;
