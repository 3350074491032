import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

const MessageRow = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0;
  flex-direction: column;
  align-items: ${({ inbound }) => (inbound ? 'flex-start' : 'flex-end')};
`;

const MessageItem = styled.div`
  padding: 10px 15px;
  max-width: 60%;
  border: ${({ inbound }) => (inbound ? '1px solid #C2CCD9' : 'none')};
  border-radius: ${({ inbound }) => (inbound ? '0 12px 12px 12px' : '12px 0 12px 12px')};
  background-color: ${({ inbound }) => (inbound ? '#FFFFFF' : '#F2F4F9')};
`;

const MessageTime = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #8692a4;
  margin-bottom: 3px;
  margin-right: ${({ inbound }) => (inbound ? '0' : '4px')};
  margin-left: ${({ inbound }) => (inbound ? '4px' : '0')};
`;

const Message = ({ message, time, direction }) => {
  const isIncomingMessage = direction === 'inbound' ? true : false;
  return (
    <MessageRow inbound={isIncomingMessage}>
      <MessageTime inbound={isIncomingMessage}>{moment(time).format('MM-DD-YYYY HH:mm')}</MessageTime>
      <MessageItem inbound={isIncomingMessage}>{message.text}</MessageItem>
    </MessageRow>
  );
};

export default Message;
