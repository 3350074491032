import React, { useCallback } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components';

export const SubsectionHeader = styled.div`
  font-weight: 600;
  font-size: 0.889rem;
  line-height: 1.111rem;
  color: #3f4857;
`;

export const SectionHolder = styled.div`
  margin-top: 40px;
`;

export const CheckboxOptionHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const CheckboxOption = styled.div`
  width: ${(props) => (props.isMobile ? '100%' : props.longText ? '55%' : '32%')};
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px 15px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;

const CheckboxContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
`;

const CheckboxInput = styled.input`
  background: #ffffff;
  border: 1px solid #8692a4;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
`;

const CheckboxText = styled.label`
  margin-left: 10px;
  margin-bottom: 0;
  font-size: 0.778rem;
  line-height: 1rem;
  color: #3f4857;
  margin-top: -1px;
  cursor: pointer;
`;

export function CheckboxOptionItem({ text, toggleFunction, longText, checked }) {
  const isMobile = useMediaQuery('(max-width: 991px)');

  const onClick = useCallback(() => {
    toggleFunction(text);
  }, []);

  return (
    <CheckboxOption longText={longText} isMobile={isMobile} onClick={onClick}>
      <CheckboxContent>
        <CheckboxInput name={text} checked={checked} type='checkbox' />
        <CheckboxText children={text} />
      </CheckboxContent>
    </CheckboxOption>
  );
}

const RadioOption = styled.div`
  width: ${(props) => (props.half ? '48%' : '32%')};
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px 15px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;

const RadioInput = styled.input`
  background: #ffffff;
  border: 1px solid #8692a4;
  box-sizing: border-box;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  cursor: pointer;
`;

export function RadioOptionItem({ id, text, half, onChange, name, checked }) {
  const onClick = useCallback(() => {
    onChange({ target: { value: text } });
  }, []);

  return (
    <RadioOption half={half} onClick={onClick}>
      <CheckboxContent>
        <RadioInput id={id} value={text} name={name} checked={checked} type='radio' />
        <CheckboxText htmlFor={id}>{text}</CheckboxText>
      </CheckboxContent>
    </RadioOption>
  );
}

export const RadioOptionsHolder = styled.form`
  display: flex;
  justify-content: space-between;
`;
