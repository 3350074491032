import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import Button from '../../components/UI/Button/Button';
import CalendarIcon from './CalendarIcon';
import Text from '../../components/_styles/Text';
import Flex from '../../components/_styles/Flex';
import { Panel } from './styles';
import AddToCalendarModal from './AddToCalendarModal';

const NextStepPanel = ({ currentStep, user }) => {
  const { tooltipContent, action } = currentStep;

  const [modal, setModal] = useState(false);

  const openModal = useCallback(() => {
    setModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setModal(false);
  }, []);

  const onClickButton = useCallback(() => {
    if (action.hasModal) {
      openModal();
    }

    window.analytics.track('Your Next Step Button Click ');
  }, [action]);

  return (
    <Panel p={20} minHeight={300}>
      <Text uppercase>Your next step</Text>
      <Flex textAlign='center' flexDirection='column' flex='1'>
        <Flex.Col py={37} flex='1'>
          <CalendarIcon />
          <Text fontSize='1'>{tooltipContent}</Text>
        </Flex.Col>
        {!action ? (
          <Button>No Action Required...</Button>
        ) : (
          <Button
            primary
            block
            as={action.hasModal ? 'button' : Link}
            to={!action.hasModal && action.link}
            onClick={onClickButton}
            disabled={!action}
          >
            {action.hasModal ? action.content : tooltipContent}
          </Button>
        )}
        {action.hasModal && user.appointments.length > 0 && (
          <AddToCalendarModal
            onClose={closeModal}
            isOpen={modal}
            action={action}
            mostRecentAppointment={user.mostRecentAppointment}
          />
        )}
      </Flex>
    </Panel>
  );
};

export default NextStepPanel;
