import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Media from 'react-media';

import Flex from '../../components/_styles/Flex';
import Heading from '../../components/_styles/Heading';
import ReportCard from './ReportCard';
import CircleButton from './CircleButton';
import PersonalSidebar from './PersonalSidebar';
import theme from '../../theme';
import Grid from '../../components/_styles/Grid';

const SAMPLE_REPORT = {
  id: 1,
  scan: 'Sample Report',
  date: new Date(),
  doctor: {
    first_name: 'Raymond',
    last_name: 'Barr'
  },
  office_location: {
    title: 'New York, NY',
    provider: {
      name: ''
    }
  }
};

const ReportPage = () => {
  const reports = useSelector((state) => state.reports.items);

  return (
    <Flex>
      <Flex.Col flexGrow={1} p={30}>
        <Heading as='h4'>Personal Radiology Reports</Heading>
        <Grid gridTemplateColumns='repeat(auto-fill, minmax(270px, 1fr))' gridGap={10}>
          {reports.length ? (
            reports.map((report) => (
              <Grid.Col key={report.id}>
                <ReportCard {...report} />
              </Grid.Col>
            ))
          ) : (
            <ReportCard {...SAMPLE_REPORT} />
          )}
          <CircleButton />
        </Grid>
      </Flex.Col>
      <Media
        queries={{
          sm: { maxWidth: theme.breakpoints.sm }
        }}
      >
        {(matches) => (
          <Fragment>
            {!matches.sm && (
              <Flex.Col>
                <PersonalSidebar />
              </Flex.Col>
            )}
          </Fragment>
        )}
      </Media>
    </Flex>
  );
};

export default ReportPage;
