import React from 'react'
import { Typography} from '@material-ui/core'
import { makeStyles, styled as materialStyle } from '@material-ui/core/styles';
import styled from "styled-components";

import Flex from '../../components/_styles/Flex';
import { formatDate as dateFormat } from '../../../src/utils/utils';

const useStyles = makeStyles((theme) => ({
    p: {
        fontSize: '16px',
        marginBottom: '0px',
        fontWeight: 400,
        lineHeight: 1.3,
        color:'#000000',
        fontFamily:'Open Sans'
    },
    reportTextBox: {
        backgroundColor: '#FAFAFA',
        borderRadius: '8px',
        overflow: "auto",
        padding: '16px',
        margin: '10px 0',
        border: '1px solid #AAAEBD',
        display:'flex',
        gap:'14px'
    },
  
    heading: {
        fontSize: '22px',
        fontWeight: 700,
        color:'#0C2945',
        fontFamily:'Roboto Condensed',
    },
    date:{
        fontSize:'16px',
        fontWeight:600,
        color:'#000000',
        display:'inline-block',
        width:'128px'
    }

}))

export const Label = materialStyle(Typography)({
    color: '#AAAEBD',
    fontSize: 11,
});

const ExamDateLabel = styled.p`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #6D6E6E;
  display:flex;
  flex-direction:column;
`



function EditFormReport({ state }) {
    const classes = useStyles();
    const textContent = state.reportText.replace(/\r\n/g, '<br>')
    .replace(/\r/g, '<br>') 
    .replace(/\n/g, '<br>')
 
    return (
        <div>
            <Typography className={classes.heading}>Report Text</Typography>
            <div className={classes.reportTextBox}>
            <ExamDateLabel>Exam Date: <span className={classes.date}> { dateFormat(state.initialExamDate) }</span></ExamDateLabel>
            <div className={classes.p} dangerouslySetInnerHTML={{__html: textContent }} />
            </div>
        </div>
    )
}

export default EditFormReport
