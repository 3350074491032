import { uniq } from 'lodash';

import API from '../libs/api';

const scoped = (name) => `PATIENT_PROFILE/${name}`;

export const GET_PATIENT_PROFILE_FETCH = scoped('GET_PATIENT_PROFILE_FETCH');
export const GET_PATIENT_PROFILE_RESOLVE = scoped('GET_PATIENT_PROFILE_RESOLVE');
export const GET_PATIENT_PROFILE_REJECT = scoped('GET_PATIENT_PROFILE_REJECT');

export const UPDATE_PATIENT_PROFILE_FETCH = scoped('UPDATE_PATIENT_PROFILE_FETCH');
export const UPDATE_PATIENT_PROFILE_RESOLVE = scoped('UPDATE_PATIENT_PROFILE_RESOLVE');
export const UPDATE_PATIENT_PROFILE_REJECT = scoped('UPDATE_PATIENT_PROFILE_REJECT');

export const CREATE_OR_UPDATE_RISK_FETCH = scoped('CREATE_OR_UPDATE_RISK_FETCH');
export const CREATE_OR_UPDATE_RISK_RESOLVE = scoped('CREATE_OR_UPDATE_RISK_RESOLVE');
export const CREATE_OR_UPDATE_RISK_REJECT = scoped('CREATE_OR_UPDATE_RISK_REJECT');

export const CREATE_OR_UPDATE_RECOMMENDATION_FETCH = scoped('CREATE_OR_UPDATE_RECOMMENDATION_FETCH');
export const CREATE_OR_UPDATE_RECOMMENDATION_RESOLVE = scoped('CREATE_OR_UPDATE_RECOMMENDATION_RESOLVE');
export const CREATE_OR_UPDATE_RECOMMENDATION_REJECT = scoped('CREATE_OR_UPDATE_RECOMMENDATION_REJECT');

export const GET_SIGN_URL_SUCCESS = scoped('GET_SIGN_URL_SUCCESS');
export const GET_SIGN_URL_ERROR = scoped('GET_SIGN_URL_ERROR');

export const GET_PRESCRIPTION_FETCH = scoped('GET_PRESCRIPTION_FETCH');
export const GET_PRESCRIPTION_RESOLVE = scoped('GET_PRESCRIPTION_RESOLVE');
export const GET_PRESCRIPTION_REJECT = scoped('GET_PRESCRIPTION_REJECT');

export const TOGGLE_B2B_PRESCRIPTION = scoped('TOGGLE_B2B_PRESCRIPTION');
export const TOGGLE_B2B_PRESCRIPTION_MODAL = scoped('TOGGLE_B2B_PRESCRIPTION_MODAL');

export const CREATE_B2B_APPOINTMENT_FETCH = scoped('CREATE_B2B_APPOINTMENT_FETCH');
export const CREATE_B2B_APPOINTMENT_RESOLVE = scoped('CREATE_B2B_APPOINTMENT_RESOLVE');
export const CREATE_B2B_APPOINTMENT_REJECT = scoped('CREATE_B2B_APPOINTMENT_REJECT');

export const toggleB2BPrescription = (payload) => ({ type: TOGGLE_B2B_PRESCRIPTION, payload });
export const toggleB2BPrescriptionModal = (payload) => ({ type: TOGGLE_B2B_PRESCRIPTION_MODAL, payload });

export const getPatientProfile = (userId, scanPurchaseId) => async (dispatch, getState) => {
  try {
    const loading = getState().patientProfile.loading;
    if (loading) return;

    dispatch({ type: GET_PATIENT_PROFILE_FETCH });

    const [resUser, resContact, resHealth, resAppointment, resPrescriptions] = await Promise.all([
      API.getRequest(`/api/v1/user/${userId}/`),
      API.getRequest(`/api/v1/user/${userId}/contact_info/`),
      API.getRequest(`/api/v1/user/${userId}/health_history/`),
      API.getRequest(`/api/v1/appointment/?patient=${userId}&scan_purchase=${scanPurchaseId}`),
      API.getRequest(`/api/v1/prescription/?patient=${userId}`)
    ]);

    const prescanAppt = resAppointment.data.results.find((a) => a.appointment_type === 'pre-scan');
    const scanAppt = resAppointment.data.results.find((a) => a.appointment_type === 'scan');

    const resScanApptLocation = await API.getRequest(`/api/v1/location/${scanAppt.location}/`);
    scanAppt.location = resScanApptLocation.data;

    const prescriptionApptId = uniq(resPrescriptions.data.results.map((r) => r.appointment));
    const resPrescriptionAppt = await Promise.all(
      prescriptionApptId.map((id) => API.getRequest(`/api/v1/appointment/${id}/`))
    );
    const mapPrescriptionAppt = resPrescriptionAppt.reduce((acc, i) => ({ ...acc, [i.data.id]: i.data }), {});
    resPrescriptions.data.results = resPrescriptions.data.results.map((r) => ({
      ...r,
      appointment: mapPrescriptionAppt[r.appointment]
    }));

    const [resReports, resNoteTypes] = await Promise.all([
      API.getRequest(`/api/v1/report/?appointment=${scanAppt.id}`),
      API.getRequest(`/api/v1/recommendation_types/?scan=${scanAppt.scan}`)
    ]);

    const reportId = resReports.data.results[0]?.id;

    const [resRecommendations, resRisks] = await (reportId
      ? Promise.all([
          API.getRequest(`/api/v1/report/${reportId}/recommendation/`),
          API.getRequest(`/api/v1/report/${reportId}/risk/`)
        ])
      : Promise.all([{ data: { results: [] } }, { data: { results: [] } }]));

    dispatch({
      type: GET_PATIENT_PROFILE_RESOLVE,
      payload: {
        user: resUser.data ?? null,
        contactInfo: resContact.data.results[0] ?? null,
        healthHistory: resHealth.data.results[0] ?? null,
        appointments: {
          prescan: prescanAppt ?? null,
          scan: scanAppt ?? null
        },
        report: resReports.data.results[0] ?? null,
        prescriptions: resPrescriptions.data.results ?? [],
        noteTypes: resNoteTypes.data.results ?? [],
        recommendations: resRecommendations.data.results ?? [],
        risks: resRisks.data.results ?? []
      }
    });
  } catch (error) {
    console.error(error);
    dispatch({ type: GET_PATIENT_PROFILE_REJECT });
  }
};

export const updatePatientProfile = () => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_PATIENT_PROFILE_FETCH });

    const patient = getState().patientProfile;
    const values = getState().form['PATIENT_PROFILE_NOTES'].values;
    const constConditions = getState().constants.medicalConditions;
    const constSymptoms = getState().constants.symptoms;

    const mapConditions = constConditions.reduce((acc, { id, name }) => ({ ...acc, [name]: id }), {});
    const mapSymptoms = constSymptoms.reduce((acc, { id, name }) => ({ ...acc, [name]: id }), {});

    const conditions = Object.entries(values.conditions)
      .filter(([_, value]) => value)
      .map(([key, _]) => mapConditions[key]);
    const symptoms = Object.entries(values.symptoms)
      .filter(([_, value]) => value)
      .map(([key, _]) => mapSymptoms[key]);
    const hasMetal = Boolean(Object.entries(values.metal).filter(([key, value]) => value && key !== 'none').length);

    await Promise.all([
      API.putRequest(`/api/v1/appointment/${patient.appointments.prescan.id}/`, {
        symptoms: symptoms,
        date_time: patient.appointments.prescan.date_time
      }),
      API.putRequest(`/api/v1/user/${patient.user.id}/health_history/${patient.healthHistory.id}/`, {
        metal_present_in_body: hasMetal,
        medical_conditions: conditions,
        allergies: values.medicationAllergies
      })
    ]);

    dispatch({ type: UPDATE_PATIENT_PROFILE_RESOLVE });
  } catch (error) {
    console.error(error);
    dispatch({ type: UPDATE_PATIENT_PROFILE_REJECT });
  }
};

export const createOrUpdateRisk = (typeId, value) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_OR_UPDATE_RISK_FETCH });

    const reportId = getState().patientProfile.report.id;
    const risk = getState().patientProfile.risks.find((r) => r.recommendation_type === typeId);

    let resRisk = null;

    if (risk) {
      resRisk = await API.putRequest(`/api/v1/report/${reportId}/risk/${risk.id}/`, {
        recommendation_type: typeId,
        text: value
      });
    } else {
      resRisk = await API.postRequest(`/api/v1/report/${reportId}/risk/`, {
        recommendation_type: typeId,
        text: value
      });
    }

    dispatch({ type: CREATE_OR_UPDATE_RISK_RESOLVE, payload: resRisk.data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CREATE_OR_UPDATE_RISK_REJECT });
  }
};

export const createOrUpdateRecommendation = (typeId, value) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_OR_UPDATE_RECOMMENDATION_FETCH });

    const reportId = getState().patientProfile.report.id;
    const recommendation = getState().patientProfile.recommendations.find((r) => r.recommendation_type === typeId);

    let resRecommendation = null;

    if (recommendation) {
      resRecommendation = await API.putRequest(`/api/v1/report/${reportId}/recommendation/${recommendation.id}/`, {
        recommendation_type: typeId,
        text: value
      });
    } else {
      resRecommendation = await API.postRequest(`/api/v1/report/${reportId}/recommendation/`, {
        recommendation_type: typeId,
        text: value
      });
    }

    dispatch({ type: CREATE_OR_UPDATE_RECOMMENDATION_RESOLVE, payload: resRecommendation.data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CREATE_OR_UPDATE_RECOMMENDATION_REJECT });
  }
};

export const getSignUrl = (prescriptionId) => {
  return async (dispatch) => {
    try {
      const signInfo = await API.getRequest(`/api/v1/prescription/${prescriptionId}/get_doctor_sign_url/`);
      dispatch({
        type: GET_SIGN_URL_SUCCESS,
        payload: {
          data: signInfo.data
        }
      });
    } catch (err) {
      dispatch({
        type: GET_SIGN_URL_ERROR,
        payload: err
      });
    }
  };
};

export const getPrescription = (prescriptionId) => async (dispatch) => {
  try {
    dispatch({ type: GET_PRESCRIPTION_FETCH });

    const resPrescription = await (prescriptionId
      ? API.getRequest(`/api/v1/prescription/${prescriptionId}`)
      : Promise.resolve({ data: null }));

    dispatch({ type: GET_PRESCRIPTION_RESOLVE, payload: resPrescription.data });
  } catch (error) {
    console.error(error);
    dispatch({ type: GET_PRESCRIPTION_REJECT });
  }
};

export const createB2BAppointment = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_B2B_APPOINTMENT_FETCH });

    const user = getState().auth.user;
    const checkout = getState().checkout;
    const patientProfile = getState().patientProfile;

    const resAppt = await API.postRequest('/api/v1/appointment/', {
      appointment_type: 'scan',
      date_time: checkout.dateTime.scan,
      location: checkout.locationId,
      patient: patientProfile.user.id,
      doctor: user.id,
      symptoms: [],
      scan: checkout.planId,
      scan_purchase: patientProfile.appointments.scan.scan_purchase
    });

    dispatch({ type: CREATE_B2B_APPOINTMENT_RESOLVE, payload: resAppt.data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CREATE_B2B_APPOINTMENT_REJECT });
  }
};
