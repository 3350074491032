import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Form as ReduxForm, Field as ReduxField } from 'redux-form';
import styled from 'styled-components';
import validator from 'validator';

import { Header } from '../common';
import FormField from './FormField';
import Phone from '../../../assets/checkout/phone-icon.svg';

const FormHolder = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
`;

const FormRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FieldSeparator = styled.div`
  padding: 0px 16px;
`;

const PersonalInfoLeftPanel = ({ handleSubmit }) => {
  return (
    <div className='seven-cols'>
      <Header imgSrc={Phone} text='Lets get started with the basics' />
      <FormHolder>
        <ReduxForm onSubmit={handleSubmit}>
          <FormRow>
            <ReduxField
              component={FormField}
              placeholder='Enter your first name here'
              label='First Name'
              name='firstName'
            />
            <FieldSeparator />
            <ReduxField
              component={FormField}
              placeholder='Enter your last name here'
              label='Last Name'
              name='lastName'
            />
          </FormRow>
          <FormRow>
            <ReduxField
              component={FormField}
              placeholder='Please enter your Phone Number (i.e. 646-470-3785)'
              label='Phone Number'
              name='phoneNumber'
            />
          </FormRow>
        </ReduxForm>
      </FormHolder>
    </div>
  );
};

export default compose(
  connect((state) => ({
    initialValues: {
      firstName: state.auth.user.first_name,
      lastName: state.auth.user.last_name,
      phoneNumber: state.auth.user.contactInfo.phone_number
    }
  })),
  reduxForm({
    form: 'PERSONAL_INFO',
    validate: ({ firstName, lastName, phoneNumber }) => {
      const errors = {};

      if (!firstName) {
        errors.firstName = 'First name required.';
      }

      if (!lastName) {
        errors.lastName = 'Last name required.';
      }

      if (!phoneNumber || !validator.isMobilePhone(phoneNumber, ['en-US'])) {
        errors.phoneNumber = 'Please enter a valid Phone number.';
      }

      return errors;
    }
  })
)(PersonalInfoLeftPanel);
