import styled from 'styled-components';
import PropTypes from 'prop-types';

import Box from '../../_styles/Box';

const Scrollable = styled(Box)`
  height: ${props => props.containerHeight};
  white-space: nowrap;
  width: ${props => props.containerWidth};

  ${props =>
    props.overflow === 'x' ? 'overflow-x: scroll' : 'overflow-y: scroll'}

  ${props => props.hideYScrollbar && 'overflow-y: hidden'}

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
    background: #c2ccd9;
    border: 1px solid #ccc;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: #0c2945;
  }

  &::-webkit-scrollbar-thumb:active {
    background: linear-gradient(left, #22add4, #1e98ba);
  }
`;

Scrollable.propTypes = {
  containerHeight: PropTypes.string.isRequired
};

Scrollable.defaultProps = {
  overflow: 'y'
};

export default Scrollable;
