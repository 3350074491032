import { reducer as reduxForm } from 'redux-form';
import auth from './authReducer';
import flash from './flashReducer';
import app from './appReducer';
import checkout from './checkoutReducer';
import reports from './reportReducer';
import provider from './provider';
import scan from './scan';
import apiErrors from './apiErrorsReducer';
import patientProfile from './patientProfile';
import patientAppointments from './patientAppointments';
import registerDoctor from './registerDoctor';
import constants from './constants';
import doctor from './doctor';
import radAdmin from './radAdmin';

export default {
    form: reduxForm,
    auth,
    flash,
    app,
    checkout,
    reports,
    provider,
    scan,
    apiErrors,
    patientProfile,
    patientAppointments,
    constants,
    registerDoctor,
    doctor,
    radAdmin
};
