import React from 'react';
import Flex from '../../../../components/_styles/Flex';
import { ReactComponent as CheckBlueIcon } from '../../../../assets/check_blue.svg';
import { ReactComponent as EmptyCircleIcon } from '../../../../assets/empty_circle.svg';
import styled from 'styled-components';

const StepContainer = styled(Flex)`
  padding:${props => props.customPadding? props.customPadding : '0 20px 20px 20px'}
  align-items: center;
  border-bottom: ${props => props.isCompleted ? '3px solid #00AEEF' : 'none'}
  cursor:${props => props.pointer? 'pointer': 'default'}

`

const IconContainer = styled(Flex)`
  margin-right: 6px;
`

const StepName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${props => props.isCompleted ? '#00AEEF' : '#C2CCD9'}
`

const Step = ({ name, isCompleted, customPadding, callBack }) => {

  const callBackHandle = (selectedStep) => {
    if(typeof callBack === 'function'){
      callBack(selectedStep)
    }
  }

  return (
    <StepContainer 
      isCompleted={isCompleted} 
      customPadding={customPadding} 
      onClick={() => callBackHandle(name)} 
      pointer={typeof callBack === 'function'}
    >
      <IconContainer>
        {isCompleted ? (
          <CheckBlueIcon />
        ) : (
          <EmptyCircleIcon mr={10} />
        )}
      </IconContainer>
      <StepName isCompleted={isCompleted}>{name}</StepName>
    </StepContainer>
  )
};

export default Step;
