import React, { useEffect, useState, forwardRef } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import {
  CloseSign,
  Image,
  SelectedItemContainer,
  SelectedItem,
} from "./../FollowUpList/FollowUpList";

import { getMarketingAttribution } from "../../../actions/radminUploads";
import { Spinner } from "react-bootstrap";
import { ReactComponent as MarketingAttributePatientIcon } from "../../../assets/patient-marketing-attribute-icon.svg";
import { ReactComponent as InfoTooltipIcon } from "../../../assets/info-tooltip-icon.svg";

import { Link, useLocation } from "react-router-dom";
import { Tooltip, withStyles } from "@material-ui/core";
import moment from "moment";
import Flex from "../../../components/_styles/Flex";

import {
  DEFAULT_VALUES,
  MarketingAttributionFilter,
} from "./MarketingAttributionFilter";
import close from "../../../assets/cancel-outline-chip.svg";

const FilterCard = styled(Flex)`
  justify-content: flex-end;
  flex-grow: 1;
`;

const SelectedFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: right;
  align-items: center;
  font-size: 10px;
  gap: 10px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 123px;
`;

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid #dfe0eb;
  width: 100%;
  padding-top: 18px;
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: 78px;
  margin-bottom: 21px;
  background: #ffffff;
`;

const TitleLabel = styled.h2`
  font-weight: 700;
  font-size: 21px;
  color: #0c2945;
  margin-bottom: 0;
  font-family: Roboto Condensed;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 38px;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 58px;
`;

const PatientsCount = styled.p`
  color: #b864c6;
  font-size: 40px;
  font-weight: 600;
  font-family: Roboto Condensed;
  margin-bottom: 0px;
`;
const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  color: #00aeef;
  margin-top: 20px;
`;

const StyledTooltip = (props) => {
  const { className, ...other } = props;

  return (
    <Tooltip
      {...other}
      PopperProps={{
        className: className,
        style: {
          backgroundColor: "#AEE3F8",
          marginBottom: "20px",
          borderRadius: "4px",
          zIndex: 3,
        },
      }}
    />
  );
};

const StyledTooltipWrapper = withStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    color: "black!important",
    fontSize: "14px!important",
    fontWeight: 400 + "!important",
    width: "220px",
    padding: "10px",
    textAlign: "center",
    margin: "0px",
    position: "relative",
  },
}))(StyledTooltip);

const TitleRow = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
`;

const MarketingAttribution = () => {
  const dispatch = useDispatch();
  const marketingAttributionData = useSelector(
    (state) => state.radAdmin?.marketingAttribution?.data
  );

  const marketingAttributionDataLoading = useSelector(
    (state) => state.radAdmin?.marketingAttribution?.isLoading
  );

  const [filterValuesForRequest, setFilterValuesForRequest] =
    useState(DEFAULT_VALUES);

  const closeSelected = (category, selectedItem) => {
    const filters = filterValuesForRequest[category].filter((item) => {
      return item !== selectedItem;
    });
    setFilterValuesForRequest({
      ...filterValuesForRequest,
      [category]: filters,
    });
  };

  useEffect(() => {
    dispatch(getMarketingAttribution(filterValuesForRequest));
  }, [filterValuesForRequest]);

  const [filterBy, setFilterBy] = useState([]);

  let modalityFilter = Array.isArray(filterValuesForRequest?.modality)
    ? filterValuesForRequest?.modality
        ?.map((itm) => `&reco_modality=${itm.value}`)
        .join("")
    : "";
  let anatomyFilter = Array.isArray(filterValuesForRequest?.anatomy)
    ? filterValuesForRequest?.anatomy
        ?.map((itm) => `&reco_anatomy=${itm.value}`)
        .join("")
    : "";

  let examDateGte = filterValuesForRequest?.initialExamDatesFilter?.startDate
    ? `&initial_exam_date_gte=${filterValuesForRequest?.initialExamDatesFilter?.startDate}`
    : "";
  let examDateLte = filterValuesForRequest?.initialExamDatesFilter?.endDate
    ? `&initial_exam_date_lte=${filterValuesForRequest?.initialExamDatesFilter?.endDate}`
    : "";

  let formattedDate =
    filterValuesForRequest?.date &&
    moment(filterValuesForRequest?.date).format("YYYY-MM-DD");

  let range_start_gte =
    filterValuesForRequest?.selectedOption === "range start" &&
    filterValuesForRequest?.selectedConfiguration === "gte" &&
    filterValuesForRequest?.date
      ? `&range_start_gte=${formattedDate}`
      : "";
  let range_start_lte =
    filterValuesForRequest?.selectedOption === "range start" &&
    filterValuesForRequest?.selectedConfiguration === "lte" &&
    filterValuesForRequest?.date
      ? `&range_start_lte=${formattedDate}`
      : "";
  let range_end_gte =
    filterValuesForRequest?.selectedOption === "range end" &&
    filterValuesForRequest?.selectedConfiguration === "gte" &&
    filterValuesForRequest?.date
      ? `&range_end_gte=${formattedDate}`
      : "";
  let range_end_lte =
    filterValuesForRequest?.selectedOption === "range end" &&
    filterValuesForRequest?.selectedConfiguration === "lte" &&
    filterValuesForRequest?.date
      ? `&range_end_lte=${formattedDate}`
      : "";

  let range = "";

  if (
    filterValuesForRequest?.initialRangeDatesFilter?.startDate &&
    filterValuesForRequest?.initialRangeDatesFilter?.endDate
  ) {
    const formattedStartDate = moment(
      filterValuesForRequest?.initialRangeDatesFilter.startDate
    ).format("YYYY-MM-DD");
    const formattedEndDate = moment(
      filterValuesForRequest?.initialRangeDatesFilter.endDate
    ).format("YYYY-MM-DD");

    range = `&${
      filterValuesForRequest?.selectedOption === "range end"
        ? "range_end"
        : "range_start"
    }_gte=${formattedStartDate}&${
      filterValuesForRequest?.selectedOption === "range end"
        ? "range_end"
        : "range_start"
    }_lte=${formattedEndDate}`;
  }

  return (
    <Container>
      <TopContainer>
        <TitleContainer>
          <TitleRow>
            <div
              style={{
                paddingTop: "20px",
                display: "flex",
                gap: "10px",
                marginRight: "15px",
              }}
            >
              <TitleLabel>Converted</TitleLabel>
              <StyledTooltipWrapper
                placement="right"
                title={
                  "Includes count of patients that have completed an action and have changed to an adherent, scheduled, or rx received status after we sent them a message"
                }
              >
                <InfoTooltipIcon
                  style={{ cursor: "pointer", alignSelf: "center" }}
                />
              </StyledTooltipWrapper>
            </div>
          </TitleRow>

          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <SelectedFilters>
              {filterValuesForRequest?.initialExamDatesFilter?.startDate &&
                filterValuesForRequest?.initialExamDatesFilter?.endDate && (
                  <SelectedItemContainer
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <SelectedItem>
                      {"Exam Date Range : " +
                        filterValuesForRequest?.initialExamDatesFilter
                          ?.startDate +
                        " - " +
                        filterValuesForRequest?.initialExamDatesFilter?.endDate}
                    </SelectedItem>
                    <CloseSign
                      onClick={() =>
                        setFilterValuesForRequest((prev) => ({
                          ...prev,
                          initialExamDatesFilter: null,
                          initialExamStartDate: null,
                          initialExamEndDate: null,
                          selectedSubOptionExamDate: null,
                          examDateView: true,
                        }))
                      }
                    >
                      <Image src={close} alt={"close sign"} />
                    </CloseSign>
                  </SelectedItemContainer>
                )}
              {filterValuesForRequest?.initialRangeDatesFilter?.startDate &&
                filterValuesForRequest?.initialRangeDatesFilter?.endDate && (
                  <SelectedItemContainer
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <SelectedItem>
                      {"Reco Due Date Range : " +
                        filterValuesForRequest?.initialRangeDatesFilter
                          ?.startDate +
                        " - " +
                        filterValuesForRequest?.initialRangeDatesFilter
                          ?.endDate}
                    </SelectedItem>
                    <CloseSign
                      onClick={() =>
                        setFilterValuesForRequest((prev) => ({
                          ...prev,
                          initialRangeDatesFilter: null,
                          initialRangeStartDate: null,
                          initialRangeEndDate: null,
                          selectedSubOptionReco: null,
                          date: null,
                          selectedConfiguration: null,
                          selectedOption: null,
                          recoRangeDateView: true,
                        }))
                      }
                    >
                      <Image src={close} alt={"close sign"} />
                    </CloseSign>
                  </SelectedItemContainer>
                )}

              {filterValuesForRequest?.date && (
                <SelectedItemContainer
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <SelectedItem>
                    {`Reco Due Date ${
                      filterValuesForRequest?.selectedOption === "range start"
                        ? "Range Start"
                        : "Range End"
                    } ${
                      filterValuesForRequest?.selectedConfiguration === "gte"
                        ? "Gte"
                        : "Lte"
                    }  : ` +
                      moment(filterValuesForRequest?.date).format("YYYY-MM-DD")}
                  </SelectedItem>
                  <CloseSign
                    onClick={() =>
                      setFilterValuesForRequest((prev) => ({
                        ...prev,
                        initialRangeDatesFilter: null,
                        initialRangeStartDate: null,
                        initialRangeEndDate: null,
                        selectedSubOptionReco: null,
                        date: null,
                        selectedConfiguration: null,
                        selectedOption: null,
                        recoRangeDateView: true,
                      }))
                    }
                  >
                    <Image src={close} alt={"close sign"} />
                  </CloseSign>
                </SelectedItemContainer>
              )}

              {Object.values({
                modality: filterValuesForRequest.modality,
                anatomy: filterValuesForRequest.anatomy,
              }).map((item) => {
                if (!item) return null;
                return item.map((i) => {
                  return (
                    <SelectedItemContainer
                      style={{ display: "flex", alignItems: "center" }}
                      key={i.value}
                    >
                      <SelectedItem>
                        {i.category + " : "}
                        {i.value.replace(/_/g, " ")}
                      </SelectedItem>
                      <CloseSign onClick={() => closeSelected(i.category, i)}>
                        <Image src={close} alt={"close sign"} />
                      </CloseSign>
                    </SelectedItemContainer>
                  );
                });
              })}
            </SelectedFilters>
            <FilterCard>
              <MarketingAttributionFilter
                filterBy={filterBy}
                setFilter={setFilterBy}
                filterValuesForRequest={filterValuesForRequest}
                setFilterValuesForRequest={setFilterValuesForRequest}
              />
            </FilterCard>
          </div>
        </TitleContainer>
      </TopContainer>
      <CardsContainer>
        {marketingAttributionDataLoading ? (
          <SpinnerWrapper>
            <Spinner role="status" animation="border" size="sm" />
          </SpinnerWrapper>
        ) : (
          <>
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <PatientsCount>
                {marketingAttributionData?.id__count ?? 0} Patients
              </PatientsCount>
            </div>

            <MarketingAttributePatientIcon />
            {!marketingAttributionDataLoading &&
              marketingAttributionData?.id__count > 0 && (
                <StyledLink
                  to={`/auth/radAdmin/followUp/patients/?reco_status=adherent&reco_status=scheduled&reco_status=rx_received&message_sent=${true}${modalityFilter}${anatomyFilter}${examDateGte}${examDateLte}${range_start_gte}${range_start_lte}${range_end_gte}${range_end_lte}${range}`}
                >
                  View Patients
                </StyledLink>
              )}
          </>
        )}
      </CardsContainer>
    </Container>
  );
};

export default MarketingAttribution;
