import React, { useState } from 'react';
import styled from 'styled-components';
import { makeStyles, styled as materialStyle } from '@material-ui/core/styles';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { TextField, InputLabel } from '@material-ui/core';
import { Col, Container, Row } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  formControlDropdown: {
    margin: '0px 0px 0px 50px',
    width: '50%',
  },
  selectInput: {
    padding: 10,
    fontSize: 14,
    '& .MuiOutlinedInput-input': {
      padding: 0,
    },
  },
  textField: {
    width: '30%',
    '& .MuiOutlinedInput-input': {
      fontSize: 14,
      padding: '10px 10px',
      textAlign: 'center',
    },
  },
}));

const Modal = styled.div`
  display: ${(props) => (props.showModal ? 'block' : 'none')};
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  padding-top: 80px;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  animation: changeWidth 8s ease-in-out infinite;
  background: rgba(12, 41, 69, 0.38);
  backdrop-filter: blur(10px);
  border-right: 1px solid whitesmoke;
`;
const ModalContentContainer = styled.div`
  max-width: 760px;
  width: 70%;
  min-height: 450px;
  height: 45%;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  max-width: 760px;
  height: 300px;
  margin: 0 auto;
  border-radius: 10px;
  @media (max-width: 768px) {
    margin: 0;
  }
`;

const CloseBtn = styled.span`
  display: inline-block;
  float: left;
  margin-right: 40px;
  margin-top: 15px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ce2030;
  &:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`;

const TitleText = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  padding-top: 20px;
  padding-left: 20px;
  color: #0c2945;
`;

const TextLabel = styled.label`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2e2f30;
`;

const ActionsWrapper = styled.div`
  position: relative;
  padding-right: 20px;
  align-self: flex-end;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  @media (max-width: 768px) {
    position: static;
    margin: 0 auto;
    justify-content: center;
  }
`;

const SaveButton = styled.button`
  width: 150px;
  height: 50px;
  background-color: #00AEEF;
  color: #ffffff;
  opacity: ${(props) => (props.disabled ? 0.65 : 1)};
  border-radius: 5px;
  border: 0;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`;

const ModifiedCol = styled.div`
  display: flex;
`;

const ContentWrapper = styled.div``;

const options = ['minutes', 'hours', 'days', 'weeks'];

function TimeDelayModal({ showModal, closeModal, onSave, currentNode }) {
  const [selectedNumber, setSelectedNumber] = useState(
    currentNode?.selectedNumber || 2
  );
  const [selectedDelay, setSelectedDelay] = useState(
    currentNode?.selectedDelay || 'days'
  );

  const onCloseModal = (e) => {
    e.stopPropagation();
    closeModal();
  };

  const classes = useStyles();

  return (
    <Modal showModal={showModal}>
      <ModalContentContainer>
        <TitleText>Edit Time Delay</TitleText>
        <ModalContent>
          <ContentWrapper>
            <Container>
              <Row>
                <Col>
                  <TextLabel>Delay Action for:</TextLabel>{' '}
                </Col>
                <Col>
                  <ModifiedCol>
                    <TextField
                      id="outlined-uncontrolled"
                      variant="outlined"
                      value={selectedNumber}
                      onChange={(e) => setSelectedNumber(e.target.value)}
                      className={classes.textField}
                      placeholder="1..."
                    />
                    <FormControl
                      variant="outlined"
                      className={classes.formControlDropdown}
                    >
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        className={classes.selectInput}
                        value={selectedDelay}
                        onChange={(e) => setSelectedDelay(e.target.value)}
                        name="delay"
                      >
                        {options.map(
                          (x, i) =>
                            x && (
                              <MenuItem key={i} value={x}>
                                {x}
                              </MenuItem>
                            )
                        )}
                      </Select>
                    </FormControl>
                  </ModifiedCol>
                </Col>
              </Row>
            </Container>
          </ContentWrapper>
        </ModalContent>
        <ActionsWrapper>
          <CloseBtn onClick={onCloseModal}> Close</CloseBtn>
          <SaveButton
            onClick={() =>
              onSave({
                selectedNumber,
                selectedDelay,
              })
            }
          >
            Save
          </SaveButton>
        </ActionsWrapper>
      </ModalContentContainer>
    </Modal>
  );
}
export default TimeDelayModal;
