import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { lowerCase } from 'lodash';
import styled from 'styled-components';
import { styled as materialStyle } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { PreviousPageLink } from '../../Dashboard/PatientImport/index';
import * as authActions from '../../../../actions/auth/actions';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/arrow_left.svg';
import NewSequenceModal from './NewSequenceModal';
import TimeDelayModal from './TimeDelayModal';
import PreviewEmail from './PreviewEmail';
import RemoveConfirmation from '../../../../components/Dialog/RemoveConfirmation';
import Flex from '../../../../components/_styles/Flex';
import Step from '../../Dashboard/PatientImport/Step';
import SmsCreationDialog from '../../Messages/SmsCreationDialog.js';
import { Sequences } from './Sequences';
import {
  createCampaignStep,
  fetchCampaignSteps,
  deleteCampaignStep,
  createABSequenceStep,
  editCampaignStep,
} from '../../../../actions/radminUploads';

const MainContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;
export const FormItem = styled.div`
  width: 100%;
`;

const BackButtonContainer = styled.div`
  display: flex;
  padding-top: 4%;
  padding-bottom: 10px;
  width: 60%;
  margin: 0 auto;
  align-items: center;
`;

const TitleText = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #0c2945;
`;

const CustomButton = materialStyle(Grid)({
  backgroundColor: '#00AEEF',
  display: 'flex',
  fontFamily: 'Roboto Condensed',
  width: (prop) => (prop.width ? prop.width : 135),
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  fontSize: 15,
  fontWeight: 600,
  borderRadius: 5,
  height: '55px',
  padding: '0 10px',
  cursor: 'pointer',
  margin: (prop) => (prop.margin ? prop.margin : 0),
});

const StepsContainer = styled.div`
  margin: auto;
  margin-top: 20px;
`;

const TitleTextWrapper = styled.div`
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
`;

const STEPS = [
  { order: 1, name: 'Setup' },
  { order: 2, name: 'Triggers' },
  { order: 3, name: 'Design' },
  { order: 4, name: 'Preview' },
];

function ContactSequence(props) {
  const dispatch = useDispatch();
  const campaigns = useSelector((state) => state.radAdmin.campaigns);
  const history = useHistory();

  const [currentStepOrder, setCurrentStepOrder] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);
  const [showModal, setShowModal] = useState(false);
  const [showTDModal, setShowTDModal] = useState(false);
  const [showSMSModal, setShowSMSModal] = useState(false);
  const [preview, setPreview] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedStep, setSelectedStep] = useState(0);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState('design');
  const [currentNode, setCurrentNode] = useState(null);

  const sequences = campaigns?.createdCampaign?.steps || [];

  useEffect(() => {
    if (!sequences.length) {
      dispatch(fetchCampaignSteps());
    }
  }, [sequences]);

  useEffect(() => {
    if (!completedSteps.includes(currentStepOrder)) {
      setCompletedSteps([...completedSteps, currentStepOrder]);
    } else {
      if (currentStepOrder !== 1) {
        setCompletedSteps(
          completedSteps.filter((item) => item !== currentStepOrder)
        );
      }
    }
  }, [currentStepOrder]);

  useEffect(() => {
    if (props.location.state) {
      const {
        location: {
          state: { selectedStep },
        },
      } = props;
      if (selectedStep) addABSequenceStep(selectedStep);
    }
  }, []);

  useEffect(() => {
    if (currentStep === 'setup') {
      setCompletedSteps([1]);
    } else if (currentStep === 'triggers') {
      setCompletedSteps([1, 2]);
    } else if (currentStep === 'design') {
      setCompletedSteps([1, 2, 3]);
    } else if (currentStep === 'preview') {
      setCompletedSteps([1, 2, 3, 4]);
    }
  }, [history.location]);

  const addABSequenceStep = (index) => {
    dispatch(createABSequenceStep(index));
  };

  const handleModals = () => {
    setShowModal(false);
    setShowTDModal(true);
  };

  const onAddTimeDelay = async ({ selectedNumber, selectedDelay }) => {
    const createOrEdit = currentNode ? editCampaignStep : createCampaignStep;

    dispatch(
      createOrEdit({
        id: currentNode?.id,
        type: 'timeDelay',
        stepNumber: selectedIndex + 1,
        selectedNumber,
        selectedDelay,
      })
    );

    setShowTDModal(false);
    setCurrentNode(null);
  };

  const emailAndSmsHandle = ({ type }, index) => {
    props.history.push({
      pathname: `/auth/radAdmin/Campaigns/create/design`,
      state: { type, selectedStep: index },
      search: `?type=${type}`,
    });
  };

  const modalHandler = (index) => {
    setShowModal(true);
    setSelectedIndex(index);
    setCurrentNode(null);
  };

  const handleAddNewStep = ({ type, text, btn, title, sendFrom, content }) => {
    const createOrEdit = currentNode ? editCampaignStep : createCampaignStep;

    dispatch(
      createOrEdit({
        id: currentNode?.id,
        type,
        text,
        btn,
        stepNumber: selectedIndex + 1,
        title,
        sendFrom,
        content,
      })
    );

    setShowModal(false);
    setCurrentNode(null);
  };

  const dialogHandler = (event, index) => {
    event.stopPropagation();
    setSelectedStep(index);
    setRemoveDialogOpen(!removeDialogOpen);
  };

  const deleteStepHandler = () => {
    setRemoveDialogOpen(false);

    dispatch(deleteCampaignStep(selectedStep));
  };

  const stepsClickHandle = (step) => {
    if (step === 'Design' || step === 'Preview') return;
    history.push(
      `/auth/radAdmin/Campaigns/${
        campaigns.createdCampaign.id
      }/create/${lowerCase(step)}`
    );
  };

  const handlePreviewPage = () => {
    const id = campaigns.createdCampaign.id;

    history.push(`/auth/radAdmin/Campaigns/${id}/create/preview`);
  };

  return (
    <div>
      {preview ? (
        <div>
          <BackButtonContainer onClick={() => props.history.goBack()}>
            <span style={{ cursor: 'pointer', display: 'flex' }}>
              <ArrowLeftIcon />
            </span>
            <PreviousPageLink>Previous Page</PreviousPageLink>
          </BackButtonContainer>
          <MainContainer>
            <PreviewEmail />
          </MainContainer>
        </div>
      ) : (
        <div>
          <RemoveConfirmation
            heading="Remove step"
            content="Are you sure you want to remove this step?"
            openDialog={removeDialogOpen}
            dialogHandler={dialogHandler}
            deleteHandler={deleteStepHandler}
          />
          <BackButtonContainer>
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => {
                props.history.goBack();
              }}
            ></div>
            <StepsContainer>
              <Flex>
                {STEPS.map((step, i) => (
                  <Step
                    key={i}
                    name={step.name}
                    isCompleted={completedSteps.includes(step.order)}
                    callBack={stepsClickHandle}
                  />
                ))}
              </Flex>
            </StepsContainer>
            <CustomButton onClick={handlePreviewPage}>Next</CustomButton>
          </BackButtonContainer>
          <TitleTextWrapper>
            <TitleText>Contact Sequence</TitleText>
          </TitleTextWrapper>

          <MainContainer>
            <Sequences
              modalHandler={modalHandler}
              setShowSMSModal={setShowSMSModal}
              setCurrentNode={setCurrentNode}
              dialogHandler={dialogHandler}
              emailAndSmsHandle={emailAndSmsHandle}
              setShowTDModal={setShowTDModal}
            />

            <NewSequenceModal
              showModal={showModal}
              closeModal={() => setShowModal(false)}
              selectTimeDelay={() => handleModals()}
              addMessageStep={() => {
                setShowModal(false);
                setShowSMSModal(true);
              }}
            />

            {showTDModal && (
              <TimeDelayModal
                showModal={showTDModal}
                closeModal={() => setShowTDModal(false)}
                onSave={onAddTimeDelay}
                currentNode={currentNode}
              />
            )}

            {showSMSModal && (
              <SmsCreationDialog
                openMessage={showSMSModal}
                openMessageHandler={() => setShowSMSModal(false)}
                onSave={(data) => {
                  handleAddNewStep(data);
                  setShowSMSModal(false);
                }}
                patientDetails={{}}
                isContactSequence={true}
                currentNode={currentNode}
              />
            )}
          </MainContainer>
        </div>
      )}
    </div>
  );
}

export default withRouter(connect(null, authActions)(ContactSequence));
