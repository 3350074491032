import styled from "styled-components";

const Panel = styled.div`
  padding: 30px;
  border: 1px solid #efefef;
  text-align: ${props => props.textAlign || "center"};
  border-radius: 3px;
  z-index: 1;
  background: #fff;
  color: #333;
  height: 550px;
  width: 100%;

  .title {
    font-size: 28px;
    font-weight: 700;
  }

  p {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    border-radius: 0px;
    border: none;
    height: 100%;
    margin-top: -30px;
  }

  @media (min-width: 768px) {
    width: 720px;
    margin: 0 auto
  }

  @media (min-width: 992px) {
    width: 960px;
  }

  @media (min-width: 1200px) {
    width: 1080px;
    margin: 0 auto
  }
`;

export default Panel;
