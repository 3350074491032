import React from 'react';

export const BackNavigateIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill="#00AEEF"/>
    </svg>
)

export const EditButton = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none">
        <path d="M16.0644 13.4736L17.3422 12.2238C17.5419 12.0285 17.8893 12.1652 17.8893 12.4464V18.1253C17.8893 19.1603 17.0308 20 15.9726 20H1.91671C0.858526 20 0 19.1603 0 18.1253V4.37729C0 3.34228 0.858526 2.50256 1.91671 2.50256H12.838C13.1215 2.50256 13.2652 2.83845 13.0656 3.03764L11.7878 4.28746C11.7279 4.34604 11.648 4.37729 11.5601 4.37729H1.91671V18.1253H15.9726V13.6923C15.9726 13.6103 16.0045 13.5322 16.0644 13.4736ZM22.3177 5.59195L11.8317 15.8483L8.22188 16.2388C7.17568 16.3521 6.28521 15.4889 6.40101 14.4578L6.80032 10.9271L17.2863 0.6708C18.2007 -0.2236 19.6782 -0.2236 20.5886 0.6708L22.3137 2.35805C23.2281 3.25245 23.2281 4.70146 22.3177 5.59195ZM18.3724 6.79881L16.0524 4.52961L8.63317 11.7903L8.34167 14.3407L10.9492 14.0556L18.3724 6.79881ZM20.96 3.68598L19.235 1.99873C19.0712 1.8386 18.8037 1.8386 18.644 1.99873L17.4101 3.20559L19.7301 5.47478L20.964 4.26793C21.1237 4.10389 21.1237 3.84612 20.96 3.68598Z" fill="#186EF0"/>
    </svg>
)

export const RightArrow = () => (
    <svg width="6" height="13" viewBox="0 0 6 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.92617 6.57266C5.92655 6.37795 5.85874 6.18925 5.73451 6.03933L1.56784 1.03933C1.42639 0.869144 1.22313 0.762124 1.00278 0.741808C0.782422 0.721491 0.563021 0.789543 0.39284 0.930991C0.22266 1.07244 0.11564 1.2757 0.0953231 1.49606C0.0750067 1.71641 0.143058 1.93581 0.284507 2.10599L4.01784 6.57266L0.41784 11.0393C0.348619 11.1246 0.296926 11.2226 0.265732 11.3279C0.234539 11.4332 0.224461 11.5436 0.236077 11.6528C0.247693 11.762 0.280774 11.8678 0.333419 11.9642C0.386063 12.0605 0.457234 12.1456 0.54284 12.2143C0.628524 12.2906 0.729047 12.3484 0.838108 12.3841C0.947169 12.4198 1.06242 12.4325 1.17664 12.4216C1.29086 12.4106 1.40159 12.3762 1.50188 12.3205C1.60218 12.2647 1.68989 12.1889 1.75951 12.0977L5.78451 7.09766C5.8891 6.94342 5.93898 6.75858 5.92617 6.57266Z" fill="#3F4857"/>
    </svg>
)

export const PaginationLeftArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 9 15" fill="none">
        <path d="M0.999969 7.00125C0.999512 6.7676 1.08089 6.54116 1.22997 6.36125L6.22997 0.361252C6.39971 0.157036 6.64362 0.0286112 6.90804 0.0042315C7.17247 -0.0201482 7.43575 0.0615137 7.63997 0.231252C7.84419 0.400991 7.97261 0.644902 7.99699 0.909329C8.02137 1.17375 7.93971 1.43704 7.76997 1.64125L3.28997 7.00125L7.60997 12.3613C7.69303 12.4635 7.75507 12.5812 7.7925 12.7076C7.82993 12.8339 7.84202 12.9664 7.82808 13.0974C7.81414 13.2285 7.77445 13.3554 7.71127 13.4711C7.6481 13.5867 7.5627 13.6887 7.45997 13.7713C7.35715 13.8628 7.23652 13.9322 7.10565 13.975C6.97477 14.0178 6.83647 14.0331 6.69941 14.0199C6.56235 14.0068 6.42947 13.9655 6.30912 13.8986C6.18876 13.8317 6.08351 13.7407 5.99997 13.6313L1.16997 7.63125C1.04445 7.44617 0.984598 7.22435 0.999969 7.00125Z" fill="black"/>
    </svg>
)

export const PaginationRightArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 9 15" fill="none">
       <path d="M8.00003 7.99875C8.00049 8.2324 7.91911 8.45884 7.77003 8.63875L2.77003 14.6387C2.60029 14.843 2.35638 14.9714 2.09196 14.9958C1.82753 15.0201 1.56425 14.9385 1.36003 14.7687C1.15581 14.599 1.02739 14.3551 1.00301 14.0907C0.978631 13.8262 1.06029 13.563 1.23003 13.3587L5.71003 7.99875L1.39003 2.63875C1.30697 2.53646 1.24493 2.41876 1.2075 2.29242C1.17007 2.16609 1.15798 2.03359 1.17192 1.90257C1.18586 1.77154 1.22555 1.64456 1.28873 1.52892C1.3519 1.41328 1.4373 1.31127 1.54003 1.22875C1.64285 1.13717 1.76348 1.06781 1.89435 1.02502C2.02523 0.982224 2.16353 0.966921 2.30059 0.980063C2.43765 0.993206 2.57053 1.03451 2.69088 1.1014C2.81124 1.16828 2.91649 1.2593 3.00003 1.36875L7.83003 7.36875C7.95555 7.55383 8.0154 7.77565 8.00003 7.99875Z" fill="black"/>
    </svg>
)

export const DownArrow = () => (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.01914 6.49844C6.21385 6.49882 6.40254 6.43101 6.55247 6.30677L11.5525 2.14011C11.7227 1.99866 11.8297 1.7954 11.85 1.57504C11.8703 1.35469 11.8023 1.13529 11.6608 0.965106C11.5194 0.794925 11.3161 0.687905 11.0957 0.667589C10.8754 0.647272 10.656 0.715323 10.4858 0.856772L6.01914 4.59011L1.55247 0.990105C1.46723 0.920884 1.36915 0.869191 1.26387 0.837998C1.15858 0.806804 1.04818 0.796726 0.938986 0.808342C0.829796 0.819958 0.723977 0.853039 0.627613 0.905684C0.531249 0.958329 0.446239 1.0295 0.37747 1.11511C0.301154 1.20079 0.243355 1.30131 0.207694 1.41037C0.172033 1.51943 0.159281 1.63468 0.170234 1.7489C0.181187 1.86312 0.215608 1.97385 0.271344 2.07415C0.32708 2.17445 0.402929 2.26215 0.494138 2.33177L5.49414 6.35677C5.64837 6.46137 5.83322 6.51125 6.01914 6.49844Z" fill="#3F4857"/>
    </svg>
)

export const PersonIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" fill="none">
        <rect width="57" height="57" rx="12" fill="#E3FBE9"/>
        <path d="M28 15.5C29.375 15.5 30.5 16.625 30.5 18C30.5 19.375 29.375 20.5 28 20.5C26.625 20.5 25.5 19.375 25.5 18C25.5 16.625 26.625 15.5 28 15.5ZM39.25 24.25H31.75V40.5H29.25V33H26.75V40.5H24.25V24.25H16.75V21.75H39.25V24.25Z" fill="#64C67C"/>
    </svg>
)