import moment from 'moment';

import API from '../../libs/api';
import { serializeState, deserializeState } from '../../libs/url';
import { loadUserInfo } from '../auth/actions';

const scoped = (name) => `CHECKOUT/${name}`;

export const SELECT_COMMUNICATION_OPTION = scoped('SELECT_COMMUNICATION_OPTION');
export const INCREMENT_CHECKOUT_STEP = scoped('INCREMENT_CHECKOUT_STEP');
export const SELECT_GENDER = scoped('SELECT_GENDER');
export const TOGGLE_MEDICAL_CONDITION = scoped('TOGGLE_MEDICAL_CONDITION');
export const TOGGLE_SYMPTOM = scoped('TOGGLE_SYMPTOM');
export const TOGGLE_CURRENT_MEDS = scoped('TOGGLE_CURRENT_MEDS');
export const TOGGLE_ALLERGY_OPTION = scoped('TOGGLE_ALLERGY_OPTION');
export const TOGGLE_METAL_ITEM = scoped('TOGGLE_METAL_ITEM');
export const SELECT_DATE_OF_BIRTH = scoped('SELECT_DATE_OF_BIRTH');
export const UPDATE_MEDICAL_HISTORY = scoped('UPDATE_MEDICAL_HISTORY');
export const UPDATE_APPOINTMENT_DATA = scoped('UPDATE_APPOINTMENT_DATA');
export const SEND_SCAN_PURCHASE_SUCCESS = scoped('SEND_SCAN_PURCHASE_SUCCESS');
export const SEND_SCAN_PURCHASE_FAILURE = scoped('SEND_SCAN_PURCHASE_FAILURE');
export const RESET_PURCHASE = scoped('RESET_PURCHASE');
export const SEND_PAYMENT_SUCCESS = scoped('SEND_PAYMENT_SUCCESS');
export const SEND_PAYMENT_FAILURE = scoped('SEND_PAYMENT_FAILURE');

export const RESET_STATE = scoped('RESET_STATE');
export const PATCH_STATE = scoped('PATCH_STATE');

export const SET_PROVIDER_ID = scoped('SET_PROVIDER_ID');
export const SET_LOCATION_ID = scoped('SET_LOCATION_ID');
export const SET_PLAN_ID = scoped('SET_PLAN_ID');
export const SET_DATE_TIME = scoped('SET_DATE_TIME');
export const SET_CURRENT_STEP = scoped('SET_CURRENT_STEP');

export const CREATE_APPOINTMENT_FETCH = scoped('CREATE_APPOINTMENT_FETCH');
export const CREATE_APPOINTMENT_RESOLVE = scoped('CREATE_APPOINTMENT_RESOLVE');
export const CREATE_APPOINTMENT_REJECT = scoped('CREATE_APPOINTMENT_REJECT');

export const CREATE_SCAN_PURCHASE_FETCH = scoped('CREATE_SCAN_PURCHASE_FETCH');
export const CREATE_SCAN_PURCHASE_RESOLVE = scoped('CREATE_SCAN_PURCHASE_RESOLVE');
export const CREATE_SCAN_PURCHASE_REJECT = scoped('CREATE_SCAN_PURCHASE_REJECT');

export const UPDATE_LAST_APPOINTMENT_FETCH = scoped('UPDATE_LAST_APPOINTMENT_FETCH');
export const UPDATE_LAST_APPOINTMENT_RESOLVE = scoped('UPDATE_LAST_APPOINTMENT_RESOLVE');
export const UPDATE_LAST_APPOINTMENT_REJECT = scoped('UPDATE_LAST_APPOINTMENT_REJECT');

export const resetState = (payload) => ({ type: RESET_STATE, payload });

export const setProviderId = (payload) => ({ type: SET_PROVIDER_ID, payload });

export const setLocationId = (payload) => ({ type: SET_LOCATION_ID, payload });

export const setPlanId = (payload) => ({ type: SET_PLAN_ID, payload });

export const setDateTime = (payload) => ({ type: SET_DATE_TIME, payload });

export const setCurrentStep = (payload) => ({ type: SET_CURRENT_STEP, payload });

export const initMedHistForm = () => (dispatch, getState) => {
  const genderMap = {
    'M': 'Male',
    'F': 'Female'
  };

  const invertDate = (date) => {
    if (!date) return null;
    const [y, m, d] = date.split('-');
    return `${m}-${d}-${y}`;
  };

  const user = getState().auth.user;

  dispatch(toggleAllergyOption(user.healthHistory.allergies));
  dispatch(selectGender(genderMap[user.healthHistory.gender]));
  dispatch(selectDateOfBirth(invertDate(user.healthHistory.date_of_birth)));
  user.medicalConditions.forEach((c) => dispatch(toggleMedicalCondition(c.description)));
};

export const createScanPurchase = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_SCAN_PURCHASE_FETCH });

    const appointments = getState().checkout.appointments;
    const response = await API.postRequest('/api/v1/scan_purchase/create_from_appointment/', {
      appointments: appointments.map(({ id }) => id)
    });

    dispatch({ type: CREATE_SCAN_PURCHASE_RESOLVE, payload: response.data });

    return response.data;
  } catch (error) {
    console.error(error);
    dispatch({ type: CREATE_SCAN_PURCHASE_REJECT });
  }
};

export const createAppointmentForCommunicationType = () => async (dispatch, getState) => {
  const userId = getState().auth.user.id;
  const checkout = getState().checkout;
  const symptomsMap = getState().constants.symptoms.reduce((acc, { id, name }) => ({ ...acc, [name]: id }), {});

  await dispatch(
    createAppointment({
      patient: userId,
      scan: checkout.planId,
      symptoms:
        checkout.communicationOption === 'ONLINE'
          ? Object.entries(checkout.symptoms)
              .filter(([_, value]) => value)
              .map(([key, _]) => symptomsMap[key])
          : undefined,
      date_time: checkout.communicationOption === 'ONLINE' ? moment().toISOString() : checkout.dateTime['pre-scan'],
      appointment_type: 'pre-scan'
    })
  );

  await dispatch(
    createAppointment({
      patient: userId,
      scan: checkout.planId,
      location: checkout.locationId,
      date_time: checkout.dateTime['scan'],
      appointment_type: 'scan'
    })
  );
};

export const createAppointment = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_APPOINTMENT_FETCH });
    const response = await API.postRequest(`/api/v1/appointment/`, data);
    dispatch({ type: CREATE_APPOINTMENT_RESOLVE, payload: response.data });
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch({ type: CREATE_APPOINTMENT_REJECT });
  }
};

export const updateLastAppointment = (symptoms) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_LAST_APPOINTMENT_FETCH });

    const userId = getState().auth.user.id;
    const symptomsMap = getState().constants.symptoms.reduce((acc, { id, name }) => ({ ...acc, [name]: id }), {});

    const symptomsId = Object.entries(symptoms)
      .filter(([_, value]) => value)
      .map(([key, _]) => symptomsMap[key]);

    const resAppointments = await API.getRequest(`/api/v1/appointment/?patient=${userId}&appointment_type=pre-scan`);

    if (resAppointments.data.results.length) {
      await API.putRequest(`/api/v1/appointment/${resAppointments.data.results[0].id}/`, {
        symptoms: symptomsId
      });
    } else {
      await API.postRequest(`/api/v1/appointment/`, {
        patient: userId,
        symptoms: symptomsId,
        date_time: moment().toISOString()
      });
    }

    dispatch({ type: UPDATE_LAST_APPOINTMENT_RESOLVE });
  } catch (error) {
    console.error(error);
    dispatch({ type: UPDATE_LAST_APPOINTMENT_REJECT });
  }
};

export const updateMedicalHistory = ({ id, gender, dob, medicalConditions, allergyOption, metalItems }) => {
  return async (dispatch) => {
    try {
      const healthHistoryResponse = await API.getRequest(`/api/v1/user/${id}/health_history`);
      const medicalConditionsResponse = await API.getRequest(`/api/v1/medical_condition/`);
      const healthHistory = healthHistoryResponse.data.results[0];
      const conditions = medicalConditionsResponse.data.results.reduce((result, element) => {
        if (Object.keys(medicalConditions).includes(element.name)) {
          const fieldName = element.name;
          if (medicalConditions[fieldName]) result.push(element.id);
        }
        return result;
      }, []);
      const body = {
        id: healthHistory.id,
        date_of_birth: moment(dob).format('YYYY-MM-DD'),
        gender: gender.charAt(0),
        medical_conditions: conditions,
        metal_present_in_body: metalItems['None'] === true ? false : Object.values(metalItems).some((m) => m === true),
        allergies: allergyOption
      };
      const response = await API.putRequest(`/api/v1/user/${id}/health_history/${healthHistory.id}`, body);
      dispatch({
        type: UPDATE_MEDICAL_HISTORY,
        payload: response.data
      });
      await loadUserInfo()(dispatch);
    } catch (err) {
      console.log(err.message);
    }
  };
};

export const selectDateOfBirth = (option) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SELECT_DATE_OF_BIRTH,
        option
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const selectCommunicationOption = (option) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SELECT_COMMUNICATION_OPTION,
        option
      });
    } catch (err) {}
  };
};

export const incrementCheckoutStep = (value) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: INCREMENT_CHECKOUT_STEP,
        payload: value
      });
    } catch (err) {}
  };
};

export const selectGender = (gender) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SELECT_GENDER,
        gender
      });
    } catch (err) {}
  };
};

export const toggleMedicalCondition = (condition) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TOGGLE_MEDICAL_CONDITION,
        payload: condition
      });
    } catch (err) {}
  };
};

export const toggleSymptom = (symptom) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TOGGLE_SYMPTOM,
        payload: symptom
      });
    } catch (err) {}
  };
};

export const toggleCurrentMeds = (answer) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TOGGLE_CURRENT_MEDS,
        answer
      });
    } catch (err) {}
  };
};

export const toggleAllergyOption = (option) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TOGGLE_ALLERGY_OPTION,
        option
      });
    } catch (err) {}
  };
};

export const toggleMetalItem = (item) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TOGGLE_METAL_ITEM,
        payload: item
      });
    } catch (err) {}
  };
};

export const resetPurchase = () => {
  return async (dispatch) => {
    dispatch({
      type: RESET_PURCHASE,
      payload: {}
    });
  };
};

export const paymentSuccess = (response) => {
  return async (dispatch) => {
    dispatch({
      type: SEND_PAYMENT_SUCCESS,
      payload: response
    });
  };
};

export const paymentFailure = (error) => {
  return async (dispatch) => {
    dispatch({
      type: SEND_PAYMENT_FAILURE,
      payload: error
    });
  };
};

export const serializeStateToUrl = (history) => (dispatch, getState) => {
  const checkout = getState().checkout;

  const search = serializeState({
    planId: checkout.planId,
    locationId: checkout.locationId,
    currentStep: checkout.currentStep,
    activeStep: checkout.activeStep,
    dateTime: checkout.dateTime,
    communicationOption: checkout.communicationOption,
    symptoms: checkout.symptoms,
    appointments: checkout.appointments.map(({ id }) => id)
  });

  history.replace(`${history.location.pathname}?${search}`);
};

export const deserializeStateFromUrl = (history) => (dispatch, getState) => {
  const step = getState().checkout.currentStep;
  const state = deserializeState(history.location.search);

  if (state) {
    dispatch({ type: PATCH_STATE, payload: state });
  }

  if (!state && !step) {
    history.push('/plans');
  }
};
