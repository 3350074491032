import { GET_CONSTANTS_FETCH, GET_CONSTANTS_RESOLVE, GET_CONSTANTS_REJECT } from '../actions/constants';

const initialState = {
    loading: false,
    medicalConditions: [],
    symptoms: [],
    featureFlags: []
};

export default (state = initialState, action) => {
    switch (action.type) {
    case GET_CONSTANTS_FETCH:
        return {
            ...state,
            loading: true
        };
    case GET_CONSTANTS_RESOLVE:
        return {
            ...state,
            ...action.payload,
            loading: false
        };
    case GET_CONSTANTS_REJECT:
        return initialState;
    default:
        return state;
    }
};
