import React from "react";
import styled from "styled-components";

const FormGroupHolder = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const Input = styled.input`
  margin-top: 8px;
  height: 50px;
    font-size: 16px;
    line-height: 24px;
    display: block;
    // width: ${props => (props.fullWidth ? "100%" : "50%")};
    padding: 6px 12px;
    color: #555555;
    background-color: #fff;
    background-image: none;
    //  border-color: ${props => (props.error ? "#ff5270" : "#dbdbdb")};
    transition: border-color ease-in-out 0.15s;
    box-shadow ease-in-out 0.15s;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  border-radius: 5px;
`;

export const Label = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3f4857;
`;

const FormGroup = field => {
  const {
    type,
    input,
    placeholder,
    meta: { error, touched }
  } = field;

  return (
    <FormGroupHolder>
      <Label>{field.label}</Label>
      <Input
        placeholder={placeholder}
        type={type}
        error={touched && error}
        {...input}
      />
      <div className="error">{touched ? error : ""}</div>
    </FormGroupHolder>
  );
};

export default FormGroup;
