import {
  FaRegCheckCircle,
  FaLaptopMedical,
  FaFilePrescription,
  FaNotesMedical,
  FaFileMedicalAlt,
} from 'react-icons/fa';
import { TiDocumentText } from 'react-icons/ti';

const USER_STEPS = [
  {
    id: 1,
    IconComponent: FaRegCheckCircle,
    label: 'Select Plan',
    tooltipContent: 'Check in on your health, schedule a new scan',
    action: {
      link: '/steps',
      hasModal: false,
    },
  },
  {
    id: 2,
    IconComponent: TiDocumentText,
    label: 'Medical History',
    tooltipContent: 'Please fill in your medical history',
    action: {
      link: 'profile',
      hasModal: false,
    },
  },
  {
    id: 3,
    IconComponent: FaLaptopMedical,
    label: 'Telehealth Visit',
    tooltipContent: 'Your telehealth visit is scheduled',
    action: {
      hasModal: true,
      content: 'Add to Calendar',
      title: 'Pre scan',
    },
  },
  {
    id: 4,
    IconComponent: FaFilePrescription,
    label: 'Prescription',
    tooltipContent: 'The doctor is writing your prescription',
    action: false,
  },
  {
    id: 5,
    IconComponent: FaNotesMedical,
    label: 'Scanning',
    tooltipContent: 'You are scheduled for a scan',
    action: {
      hasModal: true,
      content: 'Add to Calendar',
      title: 'Post scan',
    },
  },
  {
    id: 6,
    IconComponent: FaFileMedicalAlt,
    label: 'Review Results',
    tooltipContent: 'Your results are in!',
    action: {
      link: '', // TODO: create a schedule link
      hasModal: false,
    },
  },
];

export default USER_STEPS;
