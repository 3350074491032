import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import styled from 'styled-components';
import moment from 'moment';

import { FormRow, Form, FormItem, FormLabel, InputHolder, InputSeparator } from './StripeElements';
import { CheckoutNavigationHolder, CheckoutNavigationButton } from '../common';
import {
  createScanPurchase,
  paymentFailure,
  paymentSuccess,
  incrementCheckoutStep,
  setCurrentStep
} from '../../../actions/checkout';
import { getStripeKey } from '../../../utils/keys';

const ErrorSection = styled.div`
  color: red;
  padding-top: 15px;
`;

const SuccessSection = styled.div`
  color: green;
  padding-top: 15px;
`;

const STRIPE_SECRET = getStripeKey();

const CheckoutForm = () => {
  const dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState(null);
  const [enablePay, setEnablePay] = useState(true);

  const user = useSelector((state) => state.auth.user);
  const plan = useSelector((state) => state.scan.items.find((i) => i.id === state.checkout.planId));

  const handleSubmit = async (event) => {
    event.preventDefault();
    setEnablePay(false);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const { error } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement)
    });

    if (!error) {
      setError(null);
      let response = {};

      try {
        const purchase = await dispatch(createScanPurchase());

        response = await stripe.confirmCardPayment(purchase.stripe_secret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: `${user.first_name} ${user.last_name}`
            }
          }
        });
      } catch (err) {
        setError(err);
        dispatch(paymentFailure(err));

        window.analytics.track('Payment Error');
      }

      if (response.error) {
        // Show error to your customer (e.g., insufficient funds)
        setError(response.error.message);
        dispatch(paymentFailure(response.error));

        window.analytics.track('Payment Error');
      } else {
        // The payment has been processed!
        if (response.paymentIntent?.status === 'succeeded') {
          dispatch(paymentSuccess());

          window.analytics.track('Payment Success', {
            scanType: plan.name,
            price: plan.price,
            dateTime: moment().toISOString()
          });
        }
      }
    } else {
      setEnablePay(true);
      setError(error);
    }
  };

  const handlePrevious = () => {
    dispatch(incrementCheckoutStep(-1));
    dispatch(setCurrentStep('CHECKOUT_SELECT_LOCATION'));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormRow>
        <FormItem>
          <FormLabel>Card Number</FormLabel>
          <InputHolder>
            <CardNumberElement
              placeholder='
                &#8226; &#8226; &#8226; &#8226;&nbsp;
                &#8226; &#8226; &#8226; &#8226;&nbsp;
                &#8226; &#8226; &#8226; &#8226;&nbsp;
                &#8226; &#8226; &#8226; &#8226;'
            />
          </InputHolder>
        </FormItem>
      </FormRow>
      <FormRow>
        <FormItem>
          <FormLabel>Exp. Date</FormLabel>
          <InputHolder>
            <CardExpiryElement placeholder='02/21' />
          </InputHolder>
        </FormItem>
        <InputSeparator />
        <FormItem>
          <FormLabel>CVC</FormLabel>
          <InputHolder>
            <CardCvcElement placeholder='&#8226; &#8226; &#8226;' />
          </InputHolder>
        </FormItem>
      </FormRow>
      <ErrorSection>{error && error.message}</ErrorSection>
      <CheckoutNavigationHolder>
        <CheckoutNavigationButton onClick={handlePrevious} previous>
          Previous
        </CheckoutNavigationButton>
        <CheckoutNavigationButton type='submit' disabled={!stripe || !enablePay}>
          {!enablePay && <Spinner style={{ marginRight: '10px' }} as='span' animation='border' size='sm' />}
          <span children='Confirm Payment' />
        </CheckoutNavigationButton>
      </CheckoutNavigationHolder>
    </Form>
  );
};

const stripePromise = loadStripe(STRIPE_SECRET);

const Index = ({ onSuccess }) => {
  const paymentResponse = useSelector((state) => state.checkout.paymentResponse);

  if (paymentResponse.isDone && !paymentResponse.isError && paymentResponse.isSuccess) {
    onSuccess();
    return <SuccessSection>Payment was successful!</SuccessSection>;
  }

  if (paymentResponse.isDone && paymentResponse.isError) {
    return <ErrorSection>There was a problem with your payment</ErrorSection>;
  }

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default Index;
