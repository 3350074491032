import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectLocation from '../../RadiologySearchPage/RadiologySearchPage';
import SelectDatetime from '../../../components/Scheduling/SelectDateTime';
import { resetState, setPlanId } from '../../../actions/checkout';
import {
  toggleB2BPrescription,
  toggleB2BPrescriptionModal,
  createB2BAppointment
} from '../../../actions/patientProfile';

const PrescriptionB2B = () => {
  const dispatch = useDispatch();

  const scanId = useSelector((state) => state.patientProfile.appointments.prescan.scan);

  const [step, setStep] = useState('STEP_LOCATION');

  const onNxext = useCallback(async () => {
    if (step === 'STEP_LOCATION') {
      dispatch(setPlanId(scanId));
      setStep('STEP_DATETIME');
      return;
    }

    if (step === 'STEP_DATETIME') {
      await dispatch(createB2BAppointment());

      dispatch(toggleB2BPrescription(false));
      dispatch(toggleB2BPrescriptionModal(true));
      dispatch(resetState());
      return;
    }
  }, [dispatch, scanId, step]);

  const onPrevious = useCallback(() => {
    setStep('STEP_LOCATION');
  }, []);

  if (step === 'STEP_LOCATION') {
    return <SelectLocation next={onNxext} />;
  }

  if (step === 'STEP_DATETIME') {
    return <SelectDatetime style={{ padding: '30px' }} onPrevious={onPrevious} onNext={onNxext} />;
  }

  return null;
};

export default PrescriptionB2B;
