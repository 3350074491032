import React, { useCallback } from 'react';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import SuccessIcon from '../../../assets/checkout/success-icon.svg';
import { NavigationButton } from '../../Design/button';
import './Modal.css';

const ModalContent = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: auto;
  text-align: center;
`;

const SuccessImg = styled.img``;

const SuccessText = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #0c2945;
  margin-top: 30px;
`;

const OrderNumberText = styled.div`
  margin-top: 6px;
  font-size: 14px;
  line-height: 18px;
  color: #3f4857;
`;

const DescriptionText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #8692a4;
  margin-top: 35px;
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  width: 100%;
`;

const ModalButton = styled(NavigationButton)`
  padding: 15px 32px !important;
`;

const WhiteModalButton = styled(ModalButton)`
  background: inherit !important;
  color: #00AEEF;
  border: 1px solid #00AEEF;
  box-sizing: border-box;
`;

function ConfirmationModal({ isOpen, onRequestClose, order }) {
  const onClickLink = useCallback((route) => {
    const routeToEvent = {
      'plans': 'Modal Home Select',
      'dashboard': 'Modal Dash Select'
    };

    window.analytics.track(routeToEvent[route]);
  });

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} ariaHideApp={false}>
      <ModalContent>
        <SuccessImg src={SuccessIcon} alt='success' />
        <SuccessText>Your payment was successful!</SuccessText>
        <OrderNumberText>Order number: {order}</OrderNumberText>
        <DescriptionText>
          Thanks for completing the Scheduling workflow. We'll be in touch shortly with next steps!
        </DescriptionText>
        <ButtonSection>
          <WhiteModalButton as={Link} to='/plans' onClick={() => onClickLink('plans')}>
            Go to Home Page
          </WhiteModalButton>
          <ModalButton as={Link} to='/auth/dashboard' onClick={() => onClickLink('dashboard')}>
            Go to Dashboard
          </ModalButton>
        </ButtonSection>
      </ModalContent>
    </ReactModal>
  );
}

export default ConfirmationModal;
