import React from 'react';

export const EditRecoIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="15" fill="#C2CCD9" />
            <path d="M16.1133 11.3408L18.2346 13.4621L13.4616 18.2351L11.3403 16.1138L16.1133 11.3408Z" fill="white" />
            <path d="M17.1738 10.2803C17.4667 9.98744 17.9415 9.98744 18.2344 10.2803L19.2951 11.341C19.588 11.6339 19.588 12.1088 19.2951 12.4017L18.7648 12.932L16.6434 10.8107L17.1738 10.2803Z" fill="white" />
            <path d="M10.8104 18.7655L10.8104 16.6442L12.9318 18.7655L10.8104 18.7655Z" fill="white" />
        </svg>
    )
}

export const RemoveRecoIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="14.5" fill="#F12B2C" stroke="#F12B2C" />
            <path d="M17.9095 9.98995C18.4562 9.44322 19.3426 9.44322 19.8894 9.98995C20.4361 10.5367 20.4361 11.4231 19.8894 11.9698L11.9698 19.8894C11.423 20.4362 10.5366 20.4362 9.98987 19.8894C9.44313 19.3427 9.44313 18.4563 9.98987 17.9095L17.9095 9.98995Z" fill="white" />
            <path d="M9.99093 11.9704C9.44419 11.4237 9.44419 10.5373 9.99093 9.99052C10.5377 9.44379 11.4241 9.44379 11.9708 9.99052L19.8904 17.9101C20.4372 18.4568 20.4372 19.3433 19.8904 19.89C19.3437 20.4367 18.4573 20.4367 17.9105 19.89L9.99093 11.9704Z" fill="white" />
        </svg>

    )
}

export const EditIcon = () => {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5396 2.84128L12.1592 4.46016L10.5396 2.84128ZM11.5811 1.4151L7.20158 5.79457C6.9753 6.02054 6.82097 6.30844 6.75805 6.62199L6.35352 8.64693L8.37848 8.24163C8.69201 8.17893 8.97954 8.02522 9.2059 7.79887L13.5854 3.4194C13.717 3.28779 13.8214 3.13156 13.8926 2.95961C13.9639 2.78766 14.0005 2.60337 14.0005 2.41725C14.0005 2.23113 13.9639 2.04684 13.8926 1.87489C13.8214 1.70294 13.717 1.54671 13.5854 1.4151C13.4538 1.2835 13.2976 1.17911 13.1256 1.10788C12.9537 1.03666 12.7694 1 12.5833 1C12.3971 1 12.2128 1.03666 12.0409 1.10788C11.8689 1.17911 11.7127 1.2835 11.5811 1.4151V1.4151Z" stroke="#00AEEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.4707 10.1764V12.4705C12.4707 12.8761 12.3096 13.2651 12.0227 13.5519C11.7359 13.8388 11.3469 13.9999 10.9413 13.9999H2.52943C2.1238 13.9999 1.73478 13.8388 1.44796 13.5519C1.16114 13.2651 1 12.8761 1 12.4705V4.05871C1 3.65308 1.16114 3.26407 1.44796 2.97725C1.73478 2.69043 2.1238 2.5293 2.52943 2.5293H4.82357" stroke="#00AEEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const CalendarIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 5H12V10C12 11.1046 11.1046 12 10 12H2C0.895431 12 0 11.1046 0 10V5Z" fill="#3F4857" />
            <path d="M0 3.8125C0 2.70793 0.895431 1.8125 2 1.8125H10C11.1046 1.8125 12 2.70793 12 3.8125V4.0767H0V3.8125Z" fill="#3F4857" />
            <path d="M2.30859 0.576923C2.30859 0.258297 2.56689 0 2.88552 0C3.20414 0 3.46244 0.258297 3.46244 0.576923V1.81136H2.30859V0.576923Z" fill="#3F4857" />
            <path d="M8.53906 0.576923C8.53906 0.258297 8.79736 0 9.11599 0C9.43461 0 9.69291 0.258297 9.69291 0.576923V1.81136H8.53906V0.576923Z" fill="#3F4857" />
        </svg>
    )
}

export const RecoMoveLeft = () => {
    return (
        <svg width="95" height="95" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M47.5 5.9375C36.4769 5.9375 25.9054 10.3164 18.1109 18.1109C10.3164 25.9054 5.9375 36.4769 5.9375 47.5C5.9375 58.5231 10.3164 69.0946 18.1109 76.8891C25.9054 84.6836 36.4769 89.0625 47.5 89.0625C58.5231 89.0625 69.0946 84.6836 76.8891 76.8891C84.6836 69.0946 89.0625 58.5231 89.0625 47.5C89.0625 36.4769 84.6836 25.9054 76.8891 18.1109C69.0946 10.3164 58.5231 5.9375 47.5 5.9375ZM47.5 95C34.9022 95 22.8204 89.9956 13.9124 81.0876C5.00445 72.1796 0 60.0978 0 47.5C0 34.9022 5.00445 22.8204 13.9124 13.9124C22.8204 5.00445 34.9022 0 47.5 0C60.0978 0 72.1796 5.00445 81.0876 13.9124C89.9955 22.8204 95 34.9022 95 47.5C95 60.0978 89.9955 72.1796 81.0876 81.0876C72.1796 89.9956 60.0978 95 47.5 95ZM68.2812 50.4688C69.0686 50.4688 69.8237 50.156 70.3805 49.5992C70.9372 49.0425 71.25 48.2874 71.25 47.5C71.25 46.7126 70.9372 45.9575 70.3805 45.4008C69.8237 44.844 69.0686 44.5312 68.2812 44.5312H33.8853L46.6331 31.7894C47.1906 31.2319 47.5037 30.4759 47.5037 29.6875C47.5037 28.8991 47.1906 28.1431 46.6331 27.5856C46.0757 27.0282 45.3196 26.715 44.5312 26.715C43.7429 26.715 42.9868 27.0282 42.4294 27.5856L24.6169 45.3981C24.3404 45.6739 24.1211 46.0015 23.9714 46.3622C23.8217 46.7229 23.7447 47.1095 23.7447 47.5C23.7447 47.8905 23.8217 48.2771 23.9714 48.6378C24.1211 48.9985 24.3404 49.3261 24.6169 49.6019L42.4294 67.4144C42.9868 67.9718 43.7429 68.285 44.5312 68.285C45.3196 68.285 46.0757 67.9718 46.6331 67.4144C47.1906 66.8569 47.5037 66.1009 47.5037 65.3125C47.5037 64.5241 47.1906 63.7681 46.6331 63.2106L33.8853 50.4688H68.2812Z" fill="#00AEEF"/>
        </svg>
    )
}

export const RecoMoveRight = () => {
    return (
        <svg width="95" height="95" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M47.5 89.0625C58.5231 89.0625 69.0946 84.6836 76.8891 76.8891C84.6836 69.0946 89.0625 58.5231 89.0625 47.5C89.0625 36.4769 84.6836 25.9054 76.8891 18.1109C69.0946 10.3164 58.5231 5.9375 47.5 5.9375C36.4769 5.9375 25.9054 10.3164 18.1109 18.1109C10.3164 25.9054 5.9375 36.4769 5.9375 47.5C5.9375 58.5231 10.3164 69.0946 18.1109 76.8891C25.9054 84.6836 36.4769 89.0625 47.5 89.0625ZM47.5 0C60.0978 0 72.1796 5.00445 81.0876 13.9124C89.9956 22.8204 95 34.9022 95 47.5C95 60.0978 89.9956 72.1796 81.0876 81.0876C72.1796 89.9955 60.0978 95 47.5 95C34.9022 95 22.8204 89.9955 13.9124 81.0876C5.00445 72.1796 0 60.0978 0 47.5C0 34.9022 5.00445 22.8204 13.9124 13.9124C22.8204 5.00445 34.9022 0 47.5 0ZM26.7188 44.5312C25.9314 44.5312 25.1763 44.844 24.6195 45.4008C24.0628 45.9575 23.75 46.7126 23.75 47.5C23.75 48.2874 24.0628 49.0425 24.6195 49.5992C25.1763 50.156 25.9314 50.4688 26.7188 50.4688L61.1147 50.4688L48.3669 63.2106C47.8094 63.7681 47.4963 64.5241 47.4963 65.3125C47.4963 66.1009 47.8094 66.8569 48.3669 67.4144C48.9243 67.9718 49.6804 68.285 50.4688 68.285C51.2571 68.285 52.0132 67.9718 52.5706 67.4144L70.3831 49.6019C70.6596 49.3261 70.8789 48.9985 71.0286 48.6378C71.1783 48.2771 71.2553 47.8905 71.2553 47.5C71.2553 47.1095 71.1783 46.7229 71.0286 46.3622C70.8789 46.0015 70.6596 45.6739 70.3831 45.3981L52.5706 27.5856C52.0132 27.0282 51.2571 26.715 50.4688 26.715C49.6804 26.715 48.9243 27.0282 48.3669 27.5856C47.8094 28.1431 47.4963 28.8991 47.4963 29.6875C47.4963 30.4759 47.8094 31.2319 48.3669 31.7894L61.1147 44.5313L26.7188 44.5312Z" fill="#00AEEF"/>
        </svg>
    )
}



