import React from 'react';
import styled from 'styled-components';
import Flex from '../../../components/_styles/Flex';

const Container = styled(Flex)`
  align-items: center;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
`

export const Square = styled.div`
  width: 20px;
  height: 20px; 
  margin-right: 8px;
  border-radius: 5px;
  background: ${props => props.color};
`

const LegendText = styled.div`
  font-family: Open Sans;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #000000;
`
const LegendValue = styled.div`
font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #000000;
  margin-left: 3px
`

const LegendItem = ({ legendText, color, value}) => (
  <Container>
    <Square color={color} />
    <LegendText>{legendText}</LegendText>
    {value &&
    <LegendValue>{value}</LegendValue>
    }
  </Container>
);

export default LegendItem;
