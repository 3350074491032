import styled from 'styled-components';
import Flex from '../../components/_styles/Flex';

export const Panel = styled(Flex)`
  border: 1px solid #c2ccd9;
  border-radius: 8px;
  background-color: #fff;
  color: #8692a4;
  margin-bottom: ${({ nomargin }) => (nomargin ? 0 : '30px')};
  flex-direction: column;
`;

export const ProgressIconWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 62px;
  transform: translateX(-50%);
`;

export const Progress = styled.div`
  height: 3px;
  background: #e6e9f1;
`;

Progress.Fill = styled.div`
  height: inherit;
  background: #47a0f7;
  width: ${(props) => props.step}%;
`;

export const ReportsHead = styled(Flex)`
  background: #e6e9f1;
  font-size: 14px;
  color: #3f4857;
  padding: 10px;
  border-radius: 8px;
`;

export const ReportsBody = styled.div`
  font-size: 14px;

  a {
    &:hover {
      text-decoration: none;
    }

    > div {
      border-bottom: 1px solid #c2ccd9;
      padding: 10px;

      cursor: pointer;

      &:hover {
        background: #f3f3f3;
      }
    }
  }
`;
