import API from '../../libs/api';
import { showAlert } from '../../actions/radminUploads'


export const GET_RADMIN_EXPORT_REQUEST = 'GET_RADMIN_EXPORT_REQUEST';
export const GET_RADMIN_EXPORT_SUCCESS = 'GET_RADMIN_EXPORT_SUCCESS';
export const GET_RADMIN_EXPORT_FAILURE = 'GET_RADMIN_EXPORT_FAILURE';

export const GET_MESSAGE_EXPORT_REQUEST = 'GET_MESSGAE_EXPORT_REQUEST';
export const GET_MESSAGE_EXPORT_SUCCESS = 'GET_MESSGAE_EXPORT_SUCCESS';
export const GET_MESSAGE_EXPORT_FAILURE = 'GET_MESSGAE_EXPORT_FAILURE';

export const GET_NOTES_EXPORT_REQUEST = 'GET_NOTES_EXPORT_REQUEST';
export const GET_NOTES_EXPORT_SUCCESS = 'GET_NOTES_EXPORT_SUCCESS';
export const GET_NOTES_EXPORT_FAILURE = 'GET_NOTES_EXPORT_FAILURE';

export const GET_EMAILS_EXPORT_REQUEST = 'GET_EMAILS_EXPORT_REQUEST';
export const GET_EMAILS_EXPORT_SUCCESS = 'GET_EMAILS_EXPORT_SUCCESS';
export const GET_EMAILS_EXPORT_FAILURE = 'GET_EMAILS_EXPORT_FAILURE';

export const GET_FAXES_EXPORT_REQUEST = 'GET_FAXES_EXPORT_REQUEST';
export const GET_FAXES_EXPORT_SUCCESS = 'GET_FAXES_EXPORT_SUCCESS';
export const GET_FAXES_EXPORT_FAILURE = 'GET_FAXES_EXPORT_FAILURE';


export const getRadminExports = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_RADMIN_EXPORT_REQUEST, payload : pageNumber });

    if (pageNumber === 0) {
      dispatch({
        type: GET_RADMIN_EXPORT_SUCCESS,
        payload: { items: [], totalItems: 0, next: null, pageNumber: 1 },
      });
      return;
    }

    let responseData;
    let totalItems;
    let items;

    const { data } = await API.getRequest(
      `/api/v1/export/?page=${pageNumber}&ordering=-created`
    );
    responseData = data.results;
    totalItems = data.count;
    
    dispatch({
      type: GET_RADMIN_EXPORT_SUCCESS,
      payload: {
        items: responseData,
        totalItems: totalItems,
        next: responseData.next,
        pageNumber: pageNumber,
      },
    });
  } catch (error) {
    dispatch({ type: GET_RADMIN_EXPORT_FAILURE });
    dispatch(showAlert('danger', 'Error'));

  }
};

export const getExportMessages = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_MESSAGE_EXPORT_REQUEST, payload: pageNumber });
    if (pageNumber === 0) {
      dispatch({
        type: GET_MESSAGE_EXPORT_SUCCESS,
        payload: { items: [], totalItems: 0, next: null, pageNumber: 1 },
      });
      return;
    }
    let responseData;
    let totalItems;
    let responseDataA;
    let items;
    const { data } = await API.getRequest(
      `/api/v1/message_export/?page=${pageNumber}&ordering=-created`
    );
    let dataA = data.results.map((d) => {
      return {
        ...d,
        timeRange: `${d.created_greater_than_filter} / ${d.created_less_than_filter}`,
      };
    });
      responseData = dataA;
      responseDataA = data.results;
      totalItems = data.count;
      
      dispatch({
        type: GET_MESSAGE_EXPORT_SUCCESS,
        payload: {
          items: responseData,
          totalItems: totalItems,
          next: responseDataA.next,
          pageNumber: pageNumber,
        },
      });
  } catch (error) {
    dispatch({ type: GET_MESSAGE_EXPORT_FAILURE });
    dispatch(showAlert("danger", "Error"));
  }
};



export const getExportNotes = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_NOTES_EXPORT_REQUEST, payload: pageNumber });
    if (pageNumber === 0) {
      dispatch({
        type: GET_NOTES_EXPORT_SUCCESS,
        payload: { items: [], totalItems: 0, next: null, pageNumber: 1 },
      });
      return;
    }
    let responseData;
    let totalItems;
    let responseDataA;
    const { data } = await API.getRequest(
      `/api/v1/notes_export/?page=${pageNumber}&ordering=-created`
    );

    let notesData = data.results
    if (data.results && data.results.length > 0) {

      const patientFilter =  data.results.map(({ patient_filter }) => API.getRequest(`/api/v1/patient/${patient_filter}`));
      const userFilter =  data.results.map(({ user }) => API.getRequest(`/api/v1/user/${user}`)) ;
      
      const patientInfo = Promise.allSettled(patientFilter);
      const userInfo = Promise.allSettled(userFilter);
    
      const [patientResults, userResults] = await Promise.all([
        patientInfo,
        userInfo
      ]);

      notesData = notesData.map((x, i) => {
        return {
          ...x,
          user: userResults[i].status === 'fulfilled' && userResults[i].value.data.first_name + ' ' + userResults[i].value.data.last_name,
          patient_filter:  patientResults[i].status === 'fulfilled' && patientResults[i].value.data.first_name + ' ' +patientResults[i].value.data.last_name ,
        };
      });
    }
    
      responseData = notesData;
      responseDataA = data.results;
      totalItems = data.count;
      
      dispatch({
        type: GET_NOTES_EXPORT_SUCCESS,
        payload: {
          items: responseData,
          totalItems: totalItems,
          next: responseDataA.next,
          pageNumber: pageNumber,
        },
      });
  } catch (error) {
    dispatch({ type: GET_NOTES_EXPORT_FAILURE });
    dispatch(showAlert("danger", "Error"));
  }
};


export const getExportEmails = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_EMAILS_EXPORT_REQUEST, payload: pageNumber });
    if (pageNumber === 0) {
      dispatch({
        type: GET_EMAILS_EXPORT_SUCCESS,
        payload: { items: [], totalItems: 0, next: null, pageNumber: 1 },
      });
      return;
    }
    let responseData;
    let totalItems;
    let responseDataA;
    const { data } = await API.getRequest(
      `/api/v1/email-export/?page=${pageNumber}&ordering=-created`
    );

    let emailsData = data.results
    if (data.results && data.results.length > 0) {

      const userFilter =  data.results.map(({ user }) => API.getRequest(`/api/v1/user/${user}`)) ;
      
  
      const userInfo = Promise.allSettled(userFilter);
    
      const [userResults] = await Promise.all([
        userInfo
      ]);

      emailsData = emailsData.map((x, i) => {
        return {
          ...x,
          status_filter: x.status_filter || 'all',
          user: userResults[i].status === 'fulfilled' && userResults[i].value.data.first_name + ' ' + userResults[i].value.data.last_name,
        };
      });
    }
    
      responseData = emailsData;
      responseDataA = data.results;
      totalItems = data.count;
      
      dispatch({
        type: GET_EMAILS_EXPORT_SUCCESS,
        payload: {
          items: responseData,
          totalItems: totalItems,
          next: responseDataA.next,
          pageNumber: pageNumber,
        },
      });
  } catch (error) {
    dispatch({ type: GET_EMAILS_EXPORT_FAILURE });
    dispatch(showAlert("danger", "Error"));
  }
};



export const getExportFaxes = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_FAXES_EXPORT_REQUEST, payload: pageNumber });
    if (pageNumber === 0) {
      dispatch({
        type: GET_FAXES_EXPORT_SUCCESS,
        payload: { items: [], totalItems: 0, next: null, pageNumber: 1 },
      });
      return;
    }
    const { data } = await API.getRequest(
      `/api/v1/fax-export/?page=${pageNumber}&ordering=-created`
    );

    let faxesData = data.results
    if (data.results && data.results.length > 0) {

      const userFilter =  data.results.map(({ user }) => API.getRequest(`/api/v1/user/${user}`)) ;
      
  
      const userInfo = Promise.allSettled(userFilter);
    
      const [userResults] = await Promise.all([
        userInfo
      ]);

      faxesData = faxesData.map((x, i) => {
        return {
          ...x,
          status_filter: x.status_filter || 'all',
          user: userResults[i].status === 'fulfilled' && userResults[i].value.data.first_name + ' ' + userResults[i].value.data.last_name,
        };
      });
    }
    
      
      dispatch({
        type: GET_FAXES_EXPORT_SUCCESS,
        payload: {
          items: faxesData,
          totalItems: data?.count,
          next: data?.results?.next,
          pageNumber: pageNumber,
        },
      });
  } catch (error) {
    dispatch({ type: GET_FAXES_EXPORT_FAILURE });
    dispatch(showAlert("danger", "Error"));
  }
};