import React from 'react';
import styled from 'styled-components';

import Duration from '../../assets/checkout/duration.svg';
import Globe from '../../assets/checkout/globe.svg';
import Calendar from '../../assets/checkout/calendar.svg';
import Clock from '../../assets/checkout/clock.svg';

const PanelDetailsHolder = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 0px 10px;
`;

const DetailsRow = styled.div`
  display: flex;
  height: 25px;
  margin: 10px 0px;
`;

const ImgHolder = styled.div`
  margin: auto 0;
  width: 25px;
`;

const DetailsText = styled.div`
  color: #000000;
  font-size: 14px;
  line-height: 128%;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  margin: auto 10px;
`;

const getTimezone = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const parts = tz.split('/');
  let unsplitCity = '';
  if (parts.length > 1) {
    unsplitCity = parts[1].split('_').join(' ');
  }
  return parts[0] + '/' + unsplitCity;
};

const detailRows = [
  { img: Duration, text: '15 min' },
  { img: Globe, text: getTimezone() },
  { img: Calendar, text: 'Not Selected Yet' },
  { img: Clock, text: 'Not Selected Yet' }
];

function DetailsRowItem({ imgSrc, text, bold }) {
  return (
    <DetailsRow>
      <ImgHolder>
        <img src={imgSrc} alt='rows-item' />
      </ImgHolder>
      <DetailsText bold={bold}>{text}</DetailsText>
    </DetailsRow>
  );
}

function RightPanelDetails() {
  return (
    <PanelDetailsHolder>
      {detailRows.map((item, i) => {
        return <DetailsRowItem imgSrc={item.img} text={item.text} bold={i < 2} key={i} />;
      })}
    </PanelDetailsHolder>
  );
}

export default RightPanelDetails;
