import React from "react";
import styled from "styled-components";
import { connect, useSelector } from "react-redux";
import * as checkoutActions from "../../../actions/checkout";
import { SubsectionHeader, SectionHolder } from "./common"

const SelectionSection = styled.div`
  display: flex;
  margin-top: 10px;
`;

const SelectionOption = styled.div`
  background: ${props => props.active ? "#dfefff" : "none"} 
  border: 1px solid #00AEEF;
  box-sizing: border-box;
  border-radius: 5px;
  width: 189px;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-right: 5px;
`;

const SelectionOptionText = styled.div`
  font-size: 0.778rem;
  line-height: 1rem;
  display: flex;
  color: #00AEEF;
  font-weight: bold;
`;

const GENDERS = ["Male", "Female"];

function SelectOption({ text, active, select }) {
  return (
    <SelectionOption active={active} onClick={select}>
      <SelectionOptionText>{text}</SelectionOptionText>
    </SelectionOption>
  );
}

function ChooseGender({ selectGender}) {
  const activeGender = useSelector(state=>state.checkout.gender);
  return (
    <SectionHolder>
      <SubsectionHeader>Your Gender</SubsectionHeader>
      <SelectionSection>
        {GENDERS.map((g, i) => {
          return (
            <SelectOption
              active={g === activeGender}
              select={() => selectGender(g)}
              text={g}
              key={i}
            />
          );
        })}
      </SelectionSection>
    </SectionHolder>
  );
}

export default connect(null, checkoutActions)(ChooseGender);
