import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Spinner } from 'react-bootstrap';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Divider, IconButton, Typography, TextField, InputLabel } from '@material-ui/core';
import {  ReactComponent as CloseIcon} from '../../assets/cancel-outline.svg'
import { createNote } from '../../actions/radminUploads/index';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'
import Select, { components } from "react-select";
import styled from 'styled-components';


const useStyles = makeStyles({
    dialogWidth:{
        width:'431px'
    },
    dialogContent: {
        fontSize: "16px",
        padding: "13px 0 25px 0",
        fontWeight: "bold"
    },
    title: {
        fontSize: "24px",
        marginBottom: "0px",
        fontWeight: "bold",
        color:'#0C2945',
        fontFamily:'Roboto Condensed'
    },
    actionSection: {
        padding: "0px 25px 25px 25px",
        alignSelf: 'center'
    },
    label: {
        fontSize: "12px",
        fontWeight: "bold",
        width:'227px'

    },
    rootButton: {
        backgroundColor: " #E55A54",
    },
    rootCancelButton: {
        backgroundColor: "#00AEEF",
        boxShadow:'none',
        textTransform:'capitalize'
    },
    divider: {
        outerHeight: "5px",
        innerHeight: "5px",
        backgroundColor: "blue",
        width: "100%"
    },
    editNoteTitle: {
        marginTop: '16px',
        fontSize: '14px'
    },
    textField: {
        width: '100%',
        '& .MuiOutlinedInput-input': {
            fontSize: 14,
            padding: '13.5px 14px'
        }
    },
    textArea: {
        width: '100%',
        '& .MuiOutlinedInput-inputMultiline': {
            fontSize: 14,
        },
    }
})

export const styles = (theme) => ({
    root: {
        margin: 0,
        padding: '25px 25px 0px 25px',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between'
    },
});

export const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                    <CloseIcon style={{cursor:'pointer'}} onClick={onClose} />
            ) : null}
        </MuiDialogTitle>
    );
});

const taskOptions = [
    { value: "call", label: "Call" },
    { value: "fax", label: "Fax" },
    { value: "email", label: "Email" },
    { value: "sms", label: "SMS" },
    { value: "letter", label: "Letter" },
    {value:'general',label:'General'}
  ];
  
  
  
  const recipientOptions = [
    { value:'patient', label:'Patient'},
    {value:'referring_physician', label:'Referring Physician'},
    {value:'internal', label:'Internal'},
    {value:'None', label:'None'}
  ]

  const IndicatorsContainer = (props) => {
    return (
      <>
        <components.Option {...props}>
          <span style={{ marginRight: 5, paddingTop: 5 }}>
            <input
              type="radio"
              checked={props.isSelected}
              onChange={() => null}
              style={{ marginTop: 5 }}
            />{" "}
          </span>
          <label>{props.label}</label>
        </components.Option>
      </>
    );
  };

  
const SelectContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const SelectDropdownTitle = styled.div`
  margin-bottom: 4px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6D6E6E;
  text-transform: uppercase;
`;




export default function NewNote({ openDialog, addNoteClickHandler, selectedData,notesOrder,setNotesPage }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [noteText, setNoteText] = useState('');
    const [selectedTask, setSelectedTask] = useState("")
    const [selectedRecipient, setSelectedRecipient] = useState("")

    const saveNoteHandler = (patient) => {
        let data = {
            patient,
            note_text: noteText,
            title,
            task:selectedTask?.value,
            recipient:selectedRecipient?.value,
        }
        if (!data.task) {
            delete data.task;
          }
          if (!data.recipient) {
            delete data.recipient;
          }
        setLoading(true);
        dispatch(createNote(data, notesOrder)).then(() =>{
          setLoading(false);
          setNotesPage(1);
          addNoteClickHandler();
        } 
        )
        window.analytics.track('Follow-Up-List-Save-Note');
    }

    return (
        <div>
            <Dialog
                open={openDialog}
                aria-labelledby="customized-dialog-title"
                maxWidth={20}
                classes={{ paper: classes.dialogWidth }}
            >
                <DialogTitle id="customized-dialog-title" onClose={addNoteClickHandler}>
                    <p className={classes.title}>New Note</p>
                </DialogTitle>
                <DialogContent>
              
          
         
              <SelectContainer style={{width:'227px'}}>
                <SelectDropdownTitle> Task</SelectDropdownTitle>
                <Select
                  components={{Option: IndicatorsContainer }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={taskOptions}
                  value={selectedTask}
                  onChange={(value) => setSelectedTask(value)}
                  styles={{
                    option: (styles) => ({ ...styles, fontSize: "14px" }),
                  }}
                />
              </SelectContainer>
        
           
              <SelectContainer style={{width:'227px'}}>
                <SelectDropdownTitle>Recipient</SelectDropdownTitle>
                <Select
                  components={{Option: IndicatorsContainer }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={recipientOptions}
                  value={selectedRecipient}
                  onChange={(value) => setSelectedRecipient(value)}
                  styles={{
                    option: (styles) => ({ ...styles, fontSize: "14px" }),
                  }}
                />
              </SelectContainer>
       
            
           
                     <SelectContainer>
                     <SelectDropdownTitle>Note Title *</SelectDropdownTitle>
                    <TextField
                        id="outlined-uncontrolled"
                        variant="outlined"
                        className={classes.textField}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                     </SelectContainer>
                   <SelectContainer>
                   <SelectDropdownTitle>Note Content *</SelectDropdownTitle>
                    <TextField
                        id="outlined-uncontrolled"
                        variant="outlined"
                        multiline={true}
                        minRows={7}
                        className={classes.textArea}
                        onChange={(e) => setNoteText(e.target.value)}
                        style={{ fontSize: 14 }}
                    />
                   </SelectContainer>
                 
                </DialogContent>
                <div className={classes.actionSection}>
                    <Button
                        classes={{ label: classes.label, root: classes.rootCancelButton }}
                        onClick={() => saveNoteHandler(selectedData.patientId)}
                        disabled={!noteText || !title}
                        variant="contained"
                        color="primary">
                        {loading ? <Spinner animation="border" role="status" size="sm" /> : "Save"}
                    </Button>
                </div>
            </Dialog>
        </div>
    );

}
