import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Flex from '../../../components/_styles/Flex';
import { Arrow  } from './Icons';
import { ReactComponent as  StatusIcon} from '../../../assets/statusIcon.svg';
import { Popover } from '@material-ui/core';
export const FilterContainer = styled(Flex)`
  width: 215px;
  height: 50px;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
  position:relative;
  @media (max-width: 768px) {
    margin-top: 15px;
  }
`

export const SettingsContainer = styled.div`
  width: 244px;
  background-color: #F7F8FF;
  border: 1px solid #D2D3D7;
  box-sizing: border-box;
  border-radius: 8px;
  // position: absolute;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  z-index: 2;
`

const ChangeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width? '170px' : '125px'} ;
  height: 25px;
  background: #FFFFFF;
  border: 1px solid #9FA2B4;
  box-shadow: 0px 2px 3px rgba(194, 204, 217, 0.4);
  border-radius: 15px;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
`

const ArrowContainer = styled.span`
  margin-left: 10px;
`

const ChangeButtonTitle = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #9FA2B4;
`

const Status = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  padding-left:16px;
  height: 25px;
  width: 100%;
  border-radius: 12px;
  // border: ${props => props.fromMessage === "true"? `2px solid ${props.color}` : "none"}
  background-color: ${props => props.color};
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  font-family:Open Sans;
  line-height: 15px;
  letter-spacing: 0.2px;
  color:#FFFFFF;
  white-space: no-wrap;
  margin-bottom: 12px;
`

const StatusIconContainer = styled.span`
  // margin-right: 8px;
`

const ActionList = styled.ul`
padding: 12px;
list-style: none;
margin-bottom:0;
& li:last-child p{
  margin-bottom: 0;
}
`

const ActionItem = styled.li`
  margin: 0px;
  padding: 0;
  min-width: 136px;
  cursor:pointer;
`

const ActionStatus = ({status, color, fromMessage, selectedStatus}) => {
  return (
    <Status selectedStatus={selectedStatus === status} color={color} fromMessage={fromMessage}>
      {status} 
      {(status === 'Adherent' || status === 'Scheduled not Completed' || status === 'Rx Received' ) && 
     <StatusIconContainer> 
      <StatusIcon /> 
     </StatusIconContainer>
}
    </Status>
  )
}

const actionStatuses = [
  {status: 'Adherent', color: '#64C67C'},
  {status: 'Acknowledged', color: '#00AEEF'},
  {status: 'Not Clinically Necessary', color: '#FFD57C'},
  {status: 'Completed Elsewhere', color: '#6F52ED'},
  {status: 'Scheduled not Completed', color: '#FF7A00'},
  {status: 'Patient Deceased', color: ' #2E2F30'},
  {status: 'Unsubscribed', color: '#F73B3B'},
  {status: 'Navigator Closed', color: '#A7A9AD'},
  {status: 'Needs Prior Auth', color: '#DD6ECD'},
  {status: 'Needs Prescription', color: '#FF5F65'},
  {status: 'Rx Received', color: '#186EF0'},
  {status: 'Unresponsive / Comms Exhausted', color: '#808080'},
]

const ActionStatusList= ({ onSetSelected, canShow, id, fromMessage, handleSelectStatus, selectedStatus, fromEditRecoDialog }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null)
  const node = useRef();
  useEffect(() => {
    if (showMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMenu]);

  const handleClickOutside = (e) => {
    e.stopPropagation()
    if (node.current && node.current.contains(e.target)) {
      return;
    }
    setShowMenu(false);
  };

  const handleSelected = (id, status) => {
    setSelectedValue(status);
    onSetSelected({id, status});
    setShowMenu(false);
  }


  
const [anchorEl, setAnchorEl] = React.useState(null);

const openPopup = Boolean(anchorEl);
const popoverid = openPopup ? 'simple-popover' : undefined;

const handleClick = (event, row) => {
  setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
};

  return (
    <>
      {
        !fromMessage ? (
          <FilterContainer onClick={(e) =>{
            e.stopPropagation()
            setShowMenu(true && canShow && !selectedValue)
            handleClick(e)
          }
           }>
          {
            (!fromMessage && !showMenu) &&
              <ChangeButton disabled={!canShow} width={fromEditRecoDialog? true: false}>
                <ChangeButtonTitle>{fromEditRecoDialog? 'Change Reco Status': 'Change'}</ChangeButtonTitle>
                <ArrowContainer><Arrow /></ArrowContainer>
              </ChangeButton>
          }
          {
            showMenu && !selectedValue ? (
              <Popover
              id={popoverid}
              open={openPopup}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: -10,
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  left:'30px!important',
                  right:'30px!important',
                  borderRadius: '8px',
                },
              }}
              >
             
             <SettingsContainer ref={node} margin={fromEditRecoDialog? true : false}>
                <ActionList>
                  {
                    actionStatuses.map(({status, color}) => (
                      <ActionItem key={status} onClick={() => handleSelected(id, status)}><ActionStatus status={status} color={color} fromMessage="false"/></ActionItem>
                    ))
                  }
                </ActionList>
              </SettingsContainer>
              </Popover>
            
            )
            : (
              null
            )
          }
          </FilterContainer>
        ):
          <ActionList>
                  {
                    actionStatuses.map(({status, color}) => (
                      <ActionItem key={status} onClick={() => handleSelectStatus({id, status})}><ActionStatus status={status} color={color} selectedStatus={selectedStatus} fromMessage="true"/></ActionItem>
                    ))
                  }
          </ActionList>
      }
    </>
  )
};

export default ActionStatusList;
