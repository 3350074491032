import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as IconChevronUp } from '../../../assets/chevron_up.svg';
import { ReactComponent as IconChevronDown } from '../../../assets/chevron_down.svg';

import { createOrUpdateRisk, createOrUpdateRecommendation } from '../../../actions/patientProfile';
import { Button } from './Common';

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #c2ccd9;
  border-radius: 8px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const AccordionHead = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 30px;
  border-bottom: ${({ bordered }) => (bordered ? '1px solid #c2ccd9' : 'none')};
  user-select: none;
  cursor: pointer;
`;

const AccordionBody = styled.div`
  display: flex;
  flex-direction: row;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px;
  border-right: ${({ bordered }) => (bordered ? '1px solid #c2ccd9' : 'none')};
`;

const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin: 0;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #0c2945;
  }

  div {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-radius: 50%;
    background: ${({ iconColor }) => iconColor ?? 'transparent'};
  }
`;

const Textarea = styled.textarea`
  min-height: 100px;
  max-height: 100px;
  padding: 10px;
  margin: 20px 0 30px 20px;
  border: 1px solid #c2ccd9;
  border-radius: 5px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #0c2945;
  resize: none;

  &::placeholder {
    color: #8692a4;
  }
`;

const SaveButton = styled(Button)`
  width: 200px;
  align-self: flex-end;
`;

const ExpandButton = styled.button`
  margin: 0;
  padding: 0;
  right: 30px;
  position: absolute;
  border: none;
  outline: none !important;
  background: transparent;
  user-select: none;
  cursor: pointer;
`;

const HeadTitle = styled.div`
  margin-left: 15px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #0c2945;
`;

const IconPlaceholder = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 12px;
  background: #00AEEF;
  opacity: 0.6;
`;

const Accordion = ({ id, name }) => {
  const dispatch = useDispatch();

  const initialRisk = useSelector(
    (state) => state.patientProfile.risks.find((r) => r.recommendation_type === id)?.text ?? ''
  );
  const initialRecommendation = useSelector(
    (state) => state.patientProfile.recommendations.find((r) => r.recommendation_type === id)?.text ?? ''
  );

  const [opened, setOpened] = useState(false);
  const [risk, setRisk] = useState('');
  const [recommendation, setRecommendation] = useState('');

  const onToggle = useCallback(() => {
    setOpened((value) => !value);
  }, []);

  const onChangeRisk = useCallback((event) => {
    setRisk(event.target.value);
  }, []);

  const onChangeRecommendation = useCallback((event) => {
    setRecommendation(event.target.value);
  }, []);

  const onSaveRisk = useCallback(() => {
    dispatch(createOrUpdateRisk(id, risk));
  }, [dispatch, id, risk]);

  const onSaveRecommendation = useCallback(() => {
    dispatch(createOrUpdateRecommendation(id, recommendation));
  }, [dispatch, id, recommendation]);

  useEffect(() => {
    setRisk(initialRisk);
    setRecommendation(initialRecommendation);
  }, [initialRisk, initialRecommendation]);

  return (
    <AccordionContainer>
      <AccordionHead onClick={onToggle} bordered={opened}>
        <IconPlaceholder />
        <HeadTitle children={name} />
        <ExpandButton children={opened ? <IconChevronUp /> : <IconChevronDown />} />
      </AccordionHead>
      {opened && (
        <AccordionBody>
          <Section bordered>
            <SectionTitle iconColor='#f73b3b'>
              <div />
              <p children='Founded risks' />
            </SectionTitle>
            <Textarea
              onChange={onChangeRisk}
              value={risk}
              placeholder='Identify risks and recommendations based on the scan. These notes will be shared with the patient.'
            />
            <SaveButton onClick={onSaveRisk} disabled={!risk || risk === initialRisk} children='Save' />
          </Section>
          <Section>
            <SectionTitle iconColor='#ff9743'>
              <div />
              <p children='Recommendations' />
            </SectionTitle>
            <Textarea
              onChange={onChangeRecommendation}
              value={recommendation}
              placeholder='Identify risks and recommendations based on the scan. These notes will be shared with the patient.'
            />
            <SaveButton
              onClick={onSaveRecommendation}
              disabled={!recommendation || recommendation === initialRecommendation}
              children='Save'
            />
          </Section>
        </AccordionBody>
      )}
    </AccordionContainer>
  );
};

const RisksAndRecommendations = () => {
  const sections = useSelector((state) => state.patientProfile.noteTypes);

  return (
    <>
      {sections.map((section) => (
        <Accordion key={section.id} {...section} />
      ))}
    </>
  );
};

export default RisksAndRecommendations;
