import { CHANGE_VIEW, RESET_STATE } from '../actions/registerDoctor';

const initialState = {
    view: 'BASIC_INFO'
};

export default (state = initialState, action) => {
    switch (action.type) {
    case CHANGE_VIEW:
        return {
            ...state,
            view: action.payload
        };
    case RESET_STATE:
        return {
            ...initialState
        };
    default:
        return state;
    }
};
