import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { setLocationId } from '../../actions/checkout';
import Flex from '../../components/_styles/Flex';
import Text from '../../components/_styles/Text';
import { PinOutlineIcon, ClockIcon, GlobeIcon, Arrow } from './Icons';
import RadLogo from './RadLogo';
import { Image } from 'react-bootstrap';

const SearchResultCardWrapper = styled.div`
  border-radius: 6px;
  border: 1px solid #d2d3d7;
  margin-bottom: 20px;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    border: 1px solid #00AEEF;
  }

  ${(props) => props.active && 'border: 1px solid #00AEEF'}
`;

const Anchor = styled.div`
  a {
    color: #00AEEF;
    text-transform: uppercase;
    font-size: 14px;
    text-decoration-line: underline;
  }
`;

const LogoImage = styled(Image)`
  width: 100px;
  height: 120px;
  object-fit: contain;
`;

const SearchResultCard = ({ provider, location, active, nextStep, next, compact }) => {
  const dispatch = useDispatch();

  const onClickLink = useCallback(() => {
    window.analytics.track('Auth Location Submit', { locationId: location.id, address: location.address });
  }, [location]);

  const onClickA = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(setLocationId(location.id));
      next();

      window.analytics.track('Scan Appt Submit-1', { locationId: location.id, address: location.address });
    },
    [location]
  );

  return (
    <SearchResultCardWrapper active={active}>
      <Flex>
        {!compact && (
          <Flex.Col p={20} bg='' flexDirection='column' justifyContent='center' alignItems='center' width="150px" isFlex>
            {location?.logo ? <LogoImage src={location.logo}/> : (provider?.logo ? <LogoImage src={provider.logo}/> : <RadLogo />)}
          </Flex.Col>
        )}
        <Flex.Col flex={1} p={20}>
          <Text fontSize={3} m={0} fontWeight='bold'>
            {provider.name} - {location.title}
          </Text>
          <Text>
            <PinOutlineIcon /> {location.address}
          </Text>
          <Text>
            <GlobeIcon /> {location.website}
          </Text>
          <Text>
            <ClockIcon /> {location.hours}
          </Text>
          <Anchor>
            {next ? (
              <a href='' onClick={onClickA}>
                Schedule scan here <Arrow />
              </a>
            ) : (
              <Link to={nextStep} onClick={onClickLink}>
                Schedule scan here <Arrow />
              </Link>
            )}
          </Anchor>
        </Flex.Col>
      </Flex>
    </SearchResultCardWrapper>
  );
};

export default SearchResultCard;
