import React, { Fragment } from "react";
import styled from "styled-components";
import Container from "../../components/UI/Container/Container";
import Grid from "../../components/UI/Grid/Grid";
// import LeftNavigationPanel from "./LeftNavigationPanel";
import MainSection from "./MainSection";

const ModifiedContainer = styled(Container)`
  margin: 0 !important;
  padding: 0 !important;
  max-width: none !important;
`;

const ModifiedGrid = styled(Grid)`
  padding-top: 0 !important;
  grid-gap: 0px !important;
`;

function Profile() {
  return (
    <Fragment>
      <ModifiedContainer>
        <ModifiedGrid>
          {/* <LeftNavigationPanel /> */}
          <MainSection />
        </ModifiedGrid>
      </ModifiedContainer>
    </Fragment>
  );
}

export default Profile;
