import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Select, { components } from "react-select";

import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { getKeyMetrics } from "../../../actions/radminUploads";
import { Spinner } from "react-bootstrap";
import { ReactComponent as NotSignificantIcon } from "../../../assets/not-significant-icon.svg";
import { ReactComponent as SignificantIncreaseIcon } from "../../../assets/significant-increase-icon.svg";
import { ReactComponent as SignificantDecreaseIcon } from "../../../assets/significant-decrease-icon.svg";
import { fontWeight } from "styled-system";

const useStyles = makeStyles((theme) => ({
  barContainer: {
    height: 122,
    width: 40,
    borderRadius: 2,
    position: "relative",
  },
  filledBar: {
    borderRadius: "inherit",
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
}));

const PreviousLabel = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: #7b7f83;
  line-height: 16.34px;
`;

const PercentageLabel = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 21.79px;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  gap:3px;
`;

const CurrentValueLabel = styled.span`
  font-size: 40px;
  font-weight: 700;
  color: #0c2945;
  line-height: 46.8px;
  font-family: Roboto Condensed;
`;

const HeaderLabel = styled.h3`
  font-size: ${props=>props.singleRowView? '14px':'13px'};
  color: #0c2945;
  margin-bottom: 0;
  line-height: 16.41px;
  font-family: Roboto Condensed;
`;

const Card = styled.div`
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  width: ${(props)=>props.singleRowView?"264px" :"210px"};
  padding-top: 26px;
  padding-bottom: 26px;
  padding-left: ${(props)=>props.singleRowView?"26px" :"20px"};
  background: ${(props) => props.background};
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 174px;
`;

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid #dfe0eb;
  width: 100%;
  padding-top: 18px;
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: 38px;
  margin-bottom: 21px;
  background: #ffffff;
`;

const TitleLabel = styled.h2`
  font-weight: 700;
  font-size: 21px;
  color: #0c2945;
  margin-bottom: 0;
  font-family: Roboto Condensed;
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ColorMarking = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background: ${(props) => props.background};
`;

const ColorMarkingLabel = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

const ColorMarkingRow = styled.div`
  display: flex;
  gap: 8px;
`;

const ColorMarkingContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 38px;
  align-items: center;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 53px;
`;

const CardRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;
const CardLeftColumn = styled.div``;
const CardColumnContainer = styled.div`
  display: flex;
`;

const HeaderLabelTag = styled.span`
  font-weight: ${(props) => props.fontWeight || 400};
`;

const VerticalProgressBar = ({ percentage, bgColor, barBg, singleRowView }) => {
  const classes = useStyles();
  const absoluteValue = Math.abs(percentage);

  const percentageVal = Math.min(Math.max(absoluteValue, 0), 100);

  return (
    <div className={classes.barContainer} style={{ background: barBg, marginRight:singleRowView? '20px':'15px' }}>
      <div
        className={classes.filledBar}
        style={{ height: `${percentageVal}%`, background: bgColor }}
      />
    </div>
  );
};

const SelectContainerBox = styled.div`
  width: 227px;
  & div.select__control,
  div.select__value-container {
    height: 38px;
    cursor: pointer;
  }
`;

const options = [
  { value: "7", label: "Last 7 days", prevLabel: "7 days" },
  { value: "30", label: "Last 30 days", prevLabel: "30 days" },
  { value: "90", label: "Last 3 months", prevLabel: "3 months" },
  { value: "180", label: "Last 6 months", prevLabel: "6 months" },
  { value: "365", label: "Last 12 months", prevLabel: "1 year" },
];

const Option = ({ type, ...props }) => {
  return (
    <>
      <components.Option {...props}>
        <span style={{ marginRight: 5, paddingTop: 4 }}>
          <input
            type={"radio"}
            checked={props.isSelected}
            onChange={() => null}
            style={{ marginTop: 5 }}
          />{" "}
        </span>
        <label>{props.label}</label>
      </components.Option>
    </>
  );
};

const IndicatorsContainer = (props) => {
  return (
    <div>
      <components.IndicatorsContainer {...props} />
    </div>
  );
};

const KeyMetrics = ({singleRowView}) => {
  const [frequency, setFrequency] = useState({
    value: "7",
    label: "Last 7 days",
    prevLabel: "7 days",
  });

  const dispatch = useDispatch();
  const keyMetricsData = useSelector(
    (state) => state.radAdmin?.keyMetrics?.data
  );

  const keyMetricsDataLoading = useSelector(
    (state) => state.radAdmin?.keyMetrics?.isLoading
  );

  useEffect(() => {
    dispatch(getKeyMetrics(frequency?.value));
  }, [frequency]);

  return (
    <Container>
      <TopContainer>
        <TitleContainer>
          <TitleLabel>Key Metrics</TitleLabel>
          <SelectContainerBox>
            <Select
              components={{ IndicatorsContainer, Option }}
              className="basic-multi-select"
              classNamePrefix="select"
              options={options}
              value={frequency}
              onChange={(value) => {
                setFrequency(value);
              }}
              styles={{
                control: (styles) => ({
                  ...styles,
                  backgroundColor: "white",
                  padding: "0px 7px",
                  fontSize: "14px",
                  fontWeight:400,
                  color:'#252525'
                }),
                option: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  cursor: "pointer",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: "#828282",
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Open Sans",
                  marginLeft: "0px",
                }),
              }}
            />
          </SelectContainerBox>
        </TitleContainer>
        <ColorMarkingContainer>
          <ColorMarkingRow>
            <ColorMarking background={"#64C67C"}></ColorMarking>
            <ColorMarkingLabel>
              Statistically Significant Increase
            </ColorMarkingLabel>
          </ColorMarkingRow>
          <ColorMarkingRow>
            <ColorMarking background={"#BC0000"}></ColorMarking>

            <ColorMarkingLabel>
              Statistically Significant Decrease
            </ColorMarkingLabel>
          </ColorMarkingRow>
          <ColorMarkingRow>
            <ColorMarking background={"#7B7F83"}></ColorMarking>

            <ColorMarkingLabel>Not Significant</ColorMarkingLabel>
          </ColorMarkingRow>
        </ColorMarkingContainer>
      </TopContainer>

      {keyMetricsDataLoading && (
        <SpinnerWrapper>
          <Spinner role="status" animation="border" size="sm" />
        </SpinnerWrapper>
      )}
      <CardsContainer>
        {keyMetricsData?.map((itm) => (
          <Card
            background={
              itm.percentage_change < 0
                ? "#FFEEEE"
                : itm.percentage_change > 10
                ? "#F2FFF4"
                : "#F6F6F6"
            }
            singleRowView={singleRowView}
          >
            <CardColumnContainer>
              <CardLeftColumn>
                {" "}
                <VerticalProgressBar
                  barBg={
                    itm.percentage_change < 0
                      ? "#F4B8B8"
                      : itm.percentage_change > 10
                      ? "#D4EFD8"
                      : "#DADBDC"
                  }
                  bgColor={
                    itm.percentage_change < 0
                      ? "#BC0000"
                      : itm.percentage_change > 10
                      ? "#64C67C"
                      : "#7B7F83"
                  }
                  percentage={itm.percentage_change}
                  singleRowView={singleRowView}
                />
              </CardLeftColumn>
              <CardRightColumn>
                <HeaderLabel singleRowView={singleRowView}>
                  {itm.name === "num_of_appointments" ? (
                    <>
                      <HeaderLabelTag fontWeight={700}>
                        Scheduled{" "}
                      </HeaderLabelTag>{" "}
                      <HeaderLabelTag>Appointments</HeaderLabelTag>
                    </>
                  ) : itm.name === "num_of_adherent_recos" ? (
                    <>
                      <HeaderLabelTag fontWeight={700}>
                        Adherent{" "}
                      </HeaderLabelTag>{" "}
                      <HeaderLabelTag>Recos</HeaderLabelTag>
                    </>
                  ) : itm.name === "num_of_overdue_recos" ? (
                    <>
                      <HeaderLabelTag fontWeight={700}>Overdue </HeaderLabelTag>{" "}
                      <HeaderLabelTag>Recos</HeaderLabelTag>
                    </>
                  ) : (
                    <>
                      <HeaderLabelTag style={{ fontWeight: 700 }}>
                        Expired{" "}
                      </HeaderLabelTag>{" "}
                      <HeaderLabelTag>Recos</HeaderLabelTag>
                    </>
                  )}
                </HeaderLabel>
                <CurrentValueLabel>{itm.current_value}</CurrentValueLabel>
                <PercentageLabel
                  color={
                    itm.percentage_change < 0
                      ? "#BC0000"
                      : itm.percentage_change > 10
                      ? "#64C67C"
                      : "#7B7F83"
                  }
                >
                  {itm.percentage_change < 0 ? (
                    <SignificantDecreaseIcon />
                  ) : itm.percentage_change > 10 ? (
                    <SignificantIncreaseIcon />
                  ) : (
                    <NotSignificantIcon />
                  )}
                  {Math.abs(itm.percentage_change)}%
                </PercentageLabel>
                <PreviousLabel>vs previous {frequency.prevLabel}</PreviousLabel>
              </CardRightColumn>
            </CardColumnContainer>
          </Card>
        ))}
      </CardsContainer>
    </Container>
  );
};

export default KeyMetrics;
