import React from 'react';
import styled from 'styled-components';

const TText = ({ className, label, input, ...rest }) => {
  const name = input ? input.name : rest.name;
  const props = input ? { ...input, ...rest } : rest;

  return (
    <div className={className}>
      <label htmlFor={name} children={label} />
      <input {...props} id={name} name={name} type='text' />
    </div>
  );
};

const TRadio = ({ className, label, input, ...rest }) => {
  const name = input ? input.name : rest.name;
  const props = input ? { ...input, ...rest } : rest;

  return (
    <div className={className}>
      <input {...props} id={`${name}.${label}`} name={name} value={label} type='radio' />
      <label htmlFor={`${name}.${label}`} children={label} />
    </div>
  );
};

const TCheckbox = ({ className, label, input, ...rest }) => {
  const name = input ? input.name : rest.name;
  const props = input ? { ...input, ...rest } : rest;

  return (
    <div className={className}>
      <input {...props} id={name} name={name} type='checkbox' />
      <label htmlFor={name} children={label} />
    </div>
  );
};

const TTextarea = ({ className, input, ...rest }) => {
  const name = input ? input.name : rest.name;
  const props = input ? { ...input, ...rest } : rest;

  return <textarea {...props} id={name} name={name} className={className} />;
};

export const Text = styled(TText)`
  input {
    width: 100%;
    height: 50px;
    padding: 15px;
    border: 1px solid #c2ccd9;
    border-radius: 5px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #3f4857;
  }

  input:focus {
    border: 1px solid #00AEEF;
  }

  input::placeholder {
    color: #8692a4;
  }

  label {
    margin-bottom: 5px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #3f4857;
  }
`;

export const Radio = styled(TRadio)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  border: 1px solid #c2ccd9;
  border-radius: 5px;

  label {
    margin: 0 0 0 10px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #3f4857;
  }
`;

export const Checkbox = styled(TCheckbox)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  label {
    margin: 0 0 0 10px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #3f4857;
  }
`;

export const Textarea = styled(TTextarea)`
  width: 100%;
  min-height: 100px;
  padding: 15px;
  border: 1px solid #c2ccd9;
  border-radius: 5px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #3f4857;

  &:focus {
    border: 1px solid #00AEEF;
  }

  &::placeholder {
    color: #8692a4;
  }
`;
