import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SubsectionHeader, SectionHolder, CheckboxOptionItem, CheckboxOptionHolder } from './common';
import { toggleSymptom } from '../../../actions/checkout';

const Symptoms = () => {
  const dispatch = useDispatch();

  const options = useSelector((state) => state.constants.symptoms);
  const values = useSelector((state) => state.checkout.symptoms);

  const onToggle = useCallback(
    (value) => {
      dispatch(toggleSymptom(value));
    },
    [dispatch]
  );

  return (
    <SectionHolder>
      <SubsectionHeader children='Check the symptoms that you`re currently experiencing' />
      <CheckboxOptionHolder>
        {options.map(({ id, name }) => (
          <CheckboxOptionItem key={id} text={name} checked={values[name]} toggleFunction={onToggle} />
        ))}
      </CheckboxOptionHolder>
    </SectionHolder>
  );
};

export default Symptoms;
