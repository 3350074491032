import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { CtIcon, PetIcon, MriIcon, UltrasoundIcon, MammogramIcon, XrayIcon } from '../../Messages/Icons';

const CardList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  width: 85vw;
  list-style: none;
  overflow: auto;
  user-select: text;
`;

const ListItem = styled.li`
  margin-top: 10px;
  margin-right: 10px;
`;

const IconContainer = styled.div`
  position: relative;
  z-index: 2;
  padding: 0;
  margin: 20px 0;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00AEEF;
  border-radius: 50%;
  border: 2px solid #ffffff;
`;

const HistoryCardContainer = styled.div`
  position: relative;
  width: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:before {
    position: absolute;
    content: '';
    left: 14px;
    top: 145px;
    height: 2px;
    width: 110%;
    background: #c2ccd9;
  }
`;

const InfoCardContainer = styled.div`
  margin-left: 25px;
  width: 100%;
  padding: 10px 0 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  border-radius: 8px;
`;

const ModalityTitle = styled.p`
  margin: 0;
  padding: 0;
  line-height: 120%;
  color: #0c2945;
`;

const Name = styled.p`
  margin: 0;
  padding: 0;
  font-family: "Open Sans",sans-serif !important;;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #252733;
`;

const Date = styled.p`
  margin: 7px 0 0 0;
  padding: 0;
  font-family: "Open Sans",sans-serif !important;;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #72808a;
`;

const setCurrectIcon = name => {
  name = name.toLowerCase();
  switch (name) {
    case 'ct':
      return CtIcon;
    case 'pet':
      return PetIcon;
    case 'mri':
      return MriIcon;
    case 'ultrasound':
      return UltrasoundIcon;
    case 'mammogram':
      return MammogramIcon;
    case 'x-ray':
      return XrayIcon;
    default:
      return CtIcon;
  }
};

const HistoryCard = ({ icon, info }) => {
  const Icon = icon;
  return (
    <>
      <HistoryCardContainer>
        <InfoCardContainer>
          <ModalityTitle>{info.modality}</ModalityTitle>
          <Name>{info.name}</Name>
          <Date>{info.date}</Date>
          <Date>{info.accession_number}</Date>
        </InfoCardContainer>
        <IconContainer>
          <Icon />
        </IconContainer>
      </HistoryCardContainer>
    </>
  );
};

const HistoryTimeline = ({ reports, needMoreDetails }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const cardsContainerRef = useRef();

  useEffect(() => {
    const handleScroll = e => {
      const element = e.target;
      if (element.scrollWidth - element.scrollLeft === element.clientWidth && reports.next) {
        needMoreDetails(reports?.results[0]?.patient);
      }
    };

    if (cardsContainerRef.current) {
      cardsContainerRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (cardsContainerRef.current) {
        cardsContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isScrolled]);

  const onHandleScroll = () => {
    setIsScrolled(true);
  };

  return (
    <CardList onScroll={onHandleScroll} ref={cardsContainerRef}>
      {reports?.results &&
        reports.results.map(({ id, modality, physicianName, initial_exam_date, accession_number }) => (
          <ListItem key={id}>
            <HistoryCard
              icon={setCurrectIcon(modality)}
              info={{ modality, name: physicianName, date: initial_exam_date, accession_number }}
            />
          </ListItem>
        ))}
    </CardList>
  );
};

export default HistoryTimeline;
