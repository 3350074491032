import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { isNull } from 'lodash'
import Button from '@material-ui/core/Button';
import TextsmsIcon from '@material-ui/icons/Textsms';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import EditFormTop from './EditFormTop';
import EnableFeature from '../../components/EnableFeature/EnableFeature';
import EditFormBottom from './EditFormBottom';
import EditFormCenter from './EditFormCenter';
import { updateFollowupList, getNotes, getTwilioConfig, changeActionStatusAction, getFindingsBoi, updateFollowupListOnSaveReco, updateFollowupListPhysicianOnSaveReco, showAlert, updatePatientOnSaveReco, updatePatientPhysicianOnSaveReco, updatePatientRecoList } from '../../actions/radminUploads/index';
import { ViewDetailsButton, IconContainer } from '../../pages/RadAdmin/FollowUpList/FollowUpList';
// import { RecoMoveLeft, RecoMoveRight } from './Icon'
import ActionStatusList from '../../pages/RadAdmin/FollowUpList/ActionStatusList';
import { getCountByStatusWithStatusFormat } from '../../pages/RadAdmin/FollowUpList/FollowUpList';
import SmsCreationDialog from '../../pages/RadAdmin/Messages/SmsCreationDialog';
import RemoveReco from '../../components/Dialog/RemoveReco';
import {  ReactComponent as CloseIcon} from '../../assets/cancel-outline.svg'
import {  ReactComponent as DeleteIcon} from '../../assets/FollowUpDeleteIcon.svg'
import {  ReactComponent as MessageIcon} from '../../assets/FollowUpMessageIcon.svg'
import {  ReactComponent as RecoMoveLeft} from '../../assets/LeftChevron.svg'
import {  ReactComponent as RecoMoveRight} from '../../assets/RightChevron.svg'
import EditFormReport from './EditFormReport';
import styled from 'styled-components';



const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: '28px',
        fontWeight: 700,
        color:'#0C2945',
        fontFamily:'Roboto Condensed',
        marginRight:'40px'
    },
    saveBtn: {
        background: '#4dabf5',
        color: 'white',
        padding: '3px 20px',
        marginTop:'24px',
        marginBottom:'21px',
        width: '145px',
        textTransform:'capitalize',
        '&:hover': {
            background: '#4dabf5',
        },
        '&:disabled':{
        background:'#00AEEF4D',
        color:'white'
        }
    }, 
    accessionNumLabel: {
        fontFamily: 'Roboto Condensed',
        fontSize: 12,
        lineHeight: '14.6px',
        fontWeight: 400,
        color:'#6D6E6E',
        textTransform:'uppercase'
    },
    accessionNum: {
        fontSize: 16,
        lineHeight: '21.7px',
        fontWeight: 600,
        color:'##000000'
    },
    iconConatiner: {
        cursor: 'pointer'
    },
    backdropContainer: {
        height: '100vh',
        position: 'absolute',
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.5)'
    },
    backdropInnerConatiner: {
        margin: '0 auto',
        width: '95%',
        justifyContent: 'space-between',
        display: 'flex'
    },
    hide: {
        opacity: 0
    },
    deleteBtn: {
        border: 'none',
        background: '#F12B2C',
        color: 'white',
        borderRadius: '20px',
        fontSize: '14px',
        padding: '3px 18px',
        lineHeight: '16.8px',
        fontWeight: 600
    },
    paper: { minWidth: "80%" },
}))

const styles = (theme) => ({
    root: {
        margin: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    let { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <>
            {children}
            </>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        width: '100%',
        margin: '0 auto'
    },
}))(MuiDialogContent);


const DialogContentStyled = styled(DialogContent)`

`

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        width: "100%",
        display: 'flex',
        justifyContent: 'center'
    },
}))(MuiDialogActions);

const BackdropComponent = ({chevronHandler, selectedData, activeStatus, notShowBottom, setNotesOrder, setNotesPage, fromPatientDetails}) => {
    const classes = useStyles();
    const followUpList = useSelector(state => state.radAdmin.followUpList.data);
    const totalItems = useSelector(state => state.radAdmin.followUpList.totalItems);
    const totalStatuses = useSelector(state => state.radAdmin.followUpList.totalStatuses.data);
    const patientRecosRow = useSelector(
        (state) => state.radAdmin.patientDetails.recommendations?.data
      );
    const totalRecommendations = useSelector(
        (state) => state.radAdmin.patientDetails.recommendations?.totalItems
      );
     

    if(!selectedData || notShowBottom) return null
    let requiredIndex = fromPatientDetails? patientRecosRow?.findIndex(
        (reco) => reco.id === selectedData.id
      ): followUpList?.findIndex(reco => reco.id === selectedData.id);
    

    return (
    <div className={classes.backdropContainer}>
        <div className={classes.backdropInnerConatiner}>
            <span className={requiredIndex === 0 ? classes.hide : classes.iconConatiner } onClick={() => {
                setNotesOrder('ascending')
                setNotesPage(1)
                chevronHandler('previous')
        }}>
                <RecoMoveLeft  />
            </span>
             <span className={ fromPatientDetails? totalRecommendations !== patientRecosRow?.length || requiredIndex !== patientRecosRow?.length -1 ?classes.iconConatiner: classes.hide : getCountByStatusWithStatusFormat(activeStatus, totalStatuses) !== followUpList?.length || requiredIndex !== followUpList?.length -1 ? classes.iconConatiner: classes.hide} onClick={() => {
                setNotesOrder('ascending')
                setNotesPage(1)
                chevronHandler('forward')
             }}>
                <RecoMoveRight  />
            </span>
        </div>
    </div>
)}

export default function CustomizedDialogs({ openDialog, EditRecoHandler, selectedData, chevronHandler, activeStatus, notShowBottom, RemoveRecoHandler, openRemoveRecoDialog, editModalHandle, setSelectedData=()=>{}, fromPatientDetails=false }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isLoadingFollowUpList = useSelector(state => state.radAdmin.followUpList.isLoading);
    const [notesPage, setNotesPage] = useState(1);
    const [notesOrder,setNotesOrder] = useState('descending')
    const [canChangeAction, setCanChangeAction] = useState(true);
    const [openMessage, setOpenMessage] = useState(false);
    const [actionStatus, setActionStatus] = useState(null);
    const [state, setState] = useState({
        ptFirstName: "",
        ptLastName: "",
        ptEmail: "",
        ptPhone: "",
        ptDOB: "",
        ptAge: "",
        ptId:"",
        physicianFirstName: "",
        physicianLastName: "",
        physicianEmail: "",
        physicianPhone: "",
        physicianNpi: "",
        radFirstName: "",
        radLastName: "",
        radEmail: "",
        radPhone: "",
        anatomy: "",
        modality: "",
        startDate: "",
        endDate: "",
        reportText: "",
        relevantBlob: "",
        ptMrn:'',
        physicianFax:'',
        conditional:""

    })

    const [isValid, setIsValid] = useState(true);
    const [isValidPhysicianEmail, setIsValidPhysicianEmail] = useState(true);
    const [type, setType] = useState("");
    const permission = useSelector((state) => state.auth.user.permission_level); 
    const rolePermissions = useSelector(
       (state) =>state.auth.user.permissions
     );

    const updatePermission = !fromPatientDetails?  rolePermissions?.find(itm=>itm.name ==="Follow-Ups")?.permissions.find(itm=>itm.codename === "change_follow-ups")?.is_authorized:  rolePermissions?.find(itm=>itm.name ==="Patients")?.permissions.find(itm=>itm.codename === "change_patients")?.is_authorized
    const deletePermission = rolePermissions?.find(itm=>itm.name ==="Follow-Ups")?.permissions.find(itm=>itm.codename === "delete_follow-ups")?.is_authorized
  

    useEffect(() => {
        dispatch(getTwilioConfig())
      }, []);

      useEffect(() => {
        if (actionStatus) {
          dispatch(changeActionStatusAction(actionStatus.id, actionStatus.status));
          setActionStatus(null);
        }
      }, [actionStatus, dispatch]);  

      useEffect(() => {
        if (!isNull(selectedData) && selectedData !== undefined) {
            let { patient, referring_physician, radiologist, anatomy, modality, rangeStart, rangeEnd, reportText, initialExamDate, conditional } = selectedData
            setState({
                ...state,
                ptFirstName: patient && patient.first_name ? patient.first_name : "",
                ptLastName: patient && patient.last_name ? patient.last_name : "",
                ptEmail: patient && patient.email ? patient.email : "",
                ptPhone: patient && patient.phone_number ? (patient.phone_number) : "",
                ptDOB: patient && patient.date_of_birth ? patient.date_of_birth : "",
                ptAge: patient && patient.age ? patient.age : "",
                ptId: patient && patient.id ? patient.id : "",
                physicianNpi: referring_physician && referring_physician.unique_id ? referring_physician.unique_id : "",
                physicianFirstName: referring_physician && referring_physician.first_name ? referring_physician.first_name : "",
                physicianLastName: referring_physician && referring_physician.last_name ? referring_physician.last_name : "",
                physicianEmail: referring_physician && referring_physician.email ? referring_physician.email : "",
                physicianPhone: referring_physician && referring_physician.phone_number ? (referring_physician.phone_number) : "",
                radFirstName: radiologist && radiologist.first_name ? radiologist.first_name : "",
                radLastName: radiologist && radiologist.last_name ? radiologist.last_name : "",
                radEmail: radiologist && radiologist.email ? radiologist.email : "",
                radPhone: radiologist && radiologist.phone_number ? parseInt(radiologist.phone_number) : "",
                anatomy: anatomy || "",
                modality: modality || "",
                startDate: rangeStart || "",
                endDate: rangeEnd || "",
                reportText: reportText || "",
                initialExamDate: initialExamDate || "",
                relevantBlob: selectedData.relevantBlob || "",
                ptMrn: patient && patient?.mrn ? patient?.mrn : '',
                physicianFax:referring_physician && referring_physician?.fax ? referring_physician?.fax : "",
                conditional: (conditional === true? "true": conditional === false ? 'false' : "") || ""
            })
        }
        if (selectedData) {
            dispatch(getNotes(selectedData.patientId, notesPage, notesOrder))
        }
    }, [selectedData?.id])

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handlePhoneChange= (val) => {
        setState(prev=>({
            ...prev,
            ptPhone:'+' +  val
        }))
    }

    
    const handlePhoneChangePhysician= (val) => {
        setState(prev=>({
            ...prev,
            physicianPhone:'+' + val
        }))
    }

    
    const handleFaxChangePhysician= (val) => {
        setState(prev=>({
            ...prev,
            physicianFax: '+' + val
        }))
    }


    const LoadMoreNotes = () => {
        setNotesPage(notesPage + 1)
        dispatch(getNotes(selectedData.patientId, notesPage + 1, notesOrder))
    }

    const toggleNotesOrder = ()=>{
        setNotesOrder(prev=>prev==='ascending'?'descending':'ascending')
        setNotesPage(1)
        dispatch(getNotes(selectedData.patientId, 1, notesOrder==='ascending'?'descending':'ascending'))
    }

    const changeDateHandler = (start, end) => {
        if (start !== null) {
            setState({
                ...state,
                startDate: start,
            })
        }
        if (end !== null) {
            setState({
                ...state,
                endDate: end,
            })
        }
    }

    const saveHandler = () => {
        if((permission && updatePermission) || !permission){          
        let startDate = state.startDate.length === 10 ? state.startDate : new Date(state.startDate).toISOString().slice(0, 10);
        let endDate = state.endDate === 10 ? state.endDate : new Date(state.endDate).toISOString().slice(0, 10);
        let data = {
            user_range_start: startDate,
            user_range_end: endDate,
            user_modality: state.modality,
            user_anatomy: state.anatomy,
            conditional:state.conditional
        };
        setType('reco');
        if(fromPatientDetails){
            dispatch(updatePatientRecoList(selectedData.id, data));
        }else{
            dispatch(updateFollowupList(selectedData.id, data));
        }
    }else{
             dispatch(
               showAlert('danger', 'Error', `You don't have permission to change reco details`)
             );
           }
        
    }

    const openMessageHandler = () => {
        window.analytics.track('Follow-Up-List-Send-Message-Button-Click');
        setOpenMessage(!openMessage)
    }

    const onSetActionStatus = status => {
        if((permission && updatePermission) || !permission){
            setActionStatus(status);
            window.analytics.track('Follow-Up-List-Action-Status-Change-Event');
           }else{
             dispatch(
               showAlert('danger', 'Error', `You don't have permission to change reco status`)
             );
           }
      };

      useEffect(()=>{
        dispatch(getFindingsBoi(selectedData?.id))
      },[selectedData?.id])

    return (
        <div>
            <SmsCreationDialog 
                openMessage={openMessage}
                openMessageHandler={openMessageHandler}
                patientDetails={selectedData && selectedData}
            />
            <RemoveReco 
                openEditModal={openDialog} 
                openDialog={openRemoveRecoDialog}
                selectedData={selectedData} 
                RemoveRecoHandler={RemoveRecoHandler} 
            />

            <Dialog 
            classes={{ paper: classes.paper}}
                onClose={editModalHandle} aria-labelledby="customized-dialog-title"
                open={openDialog}
                BackdropComponent={() => <BackdropComponent setNotesOrder={setNotesOrder} setNotesPage={setNotesPage} chevronHandler={chevronHandler} selectedData={selectedData} activeStatus={activeStatus} notShowBottom={notShowBottom} fromPatientDetails={fromPatientDetails}/> }
            >
                <DialogTitle id="customized-dialog-title" style={{ boxShadow: '0px 8px 10px 0px #0000001A', zIndex:2 }}>
                    <div style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent:'space-between'}}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
                        <Typography className={classes.heading} >Recommendation Details</Typography>

                    <div style={{ display: 'flex', flexDirection: 'column', marginRight:'30px'}}>
                    <Typography className={classes.accessionNumLabel}>Accession Number</Typography>
                    <Typography className={classes.accessionNum}>{selectedData && selectedData.accessionNumber}</Typography>
                    </div>
                    { !fromPatientDetails &&
                    <DeleteIcon style={{marginRight:'30px', cursor:'pointer'}} onClick={() => {
                        if((permission && deletePermission) || !permission){
                            RemoveRecoHandler(selectedData, 'fromEditModal')
                           }else{
                             dispatch(
                               showAlert('danger', 'Error', `You don't have permission to delete reco`)
                             );
                           }
                         
                    }} />
                    }
                    {
                        !fromPatientDetails &&   <EnableFeature featureName={'enable-outgoing-messaging'} onClick={openMessageHandler}>
                        <MessageIcon style={{marginRight:'30px', cursor:'pointer'}}  />
                       </EnableFeature>
                    }
                  
                    {!fromPatientDetails &&<div style={{display: 'flex', alignItems: 'center'}}>
                    <ActionStatusList 
                        canShow={canChangeAction}
                        id={selectedData && selectedData.id}
                        onSetSelected={onSetActionStatus}
                        fromEditRecoDialog="true"
                    />
                   
                  </div>
                    }
                    </div>
                   
                  <IconButton aria-label="close" className={classes.closeButton} onClick={editModalHandle}>
                    <CloseIcon />
                    </IconButton>
                    </div>
                </DialogTitle>
                <DialogContentStyled>
                    <EditFormTop state={state} handleChange={handleChange} handlePhoneChange={handlePhoneChange}  handlePhoneChangePhysician={handlePhoneChangePhysician}
                    handleFaxChangePhysician={handleFaxChangePhysician}  isValid={isValid} 
                    setIsValid={setIsValid}
                    isValidPhysicianEmail={isValidPhysicianEmail}
                    setIsValidPhysicianEmail={setIsValidPhysicianEmail} 
                    /> 
                    <EditFormCenter isLoadingFollowUpList={isLoadingFollowUpList} saveHandler={saveHandler} setNotesPage={setNotesPage} notesOrder={notesOrder} toggleNotesOrder={toggleNotesOrder} LoadMoreNotes={LoadMoreNotes} notShowBottom={notShowBottom} state={state} handleChange={handleChange} selectedData={selectedData} changeDateHandler={changeDateHandler}    
                    type={type}
                    />
                    <EditFormReport state={state} />
                </DialogContentStyled>
                <div style={{width:'100%', display:'flex', justifyContent:'center', boxShadow: '0px -4px 10px 0px #0000001A'}}>
                    <Button disabled={( isValid && isValidPhysicianEmail &&  (
                    (selectedData?.patient && (selectedData?.patient.email !== state.ptEmail || selectedData?.patient.phone_number !== state.ptPhone)) ||
                    (selectedData?.referring_physician && ( selectedData?.referring_physician.email !== state.physicianEmail || selectedData?.referring_physician.fax !== state.physicianFax ||  selectedData?.referring_physician.phone_number !== state.physicianPhone))))? 
                    false : true} 
                    className={classes.saveBtn}
                    onClick={()=>{
                        if((permission && updatePermission) || !permission){
                        setType('others');
                        if( selectedData?.patient && (selectedData?.patient.email !== state.ptEmail ||  selectedData?.patient.phone_number !== state.ptPhone) ){
                          if(fromPatientDetails){
                            dispatch(updatePatientOnSaveReco(selectedData.patient.id , {email: state.ptEmail, phone_number:state.ptPhone}, selectedData.id)).then(res=>{
                                if(res){
                                    setSelectedData(res)
                                }
                            
                            })
                          }else{
                            dispatch(updateFollowupListOnSaveReco(selectedData.patient.id , {email: state.ptEmail, phone_number:state.ptPhone}, selectedData.id)).then(res=>{
                            if(res){
                                setSelectedData(res)
                            }
                        
                        })
                         }
                        }
                        if( selectedData?.referring_physician && ( selectedData?.referring_physician.email !== state.physicianEmail || selectedData?.referring_physician.fax !== state.physicianFax ||  selectedData?.referring_physician.phone_number !== state.physicianPhone)){
                         if(fromPatientDetails){
                            dispatch(updatePatientPhysicianOnSaveReco(selectedData.referring_physician.id , {fax: state.physicianFax, phone_number:state.physicianPhone, email: state.physicianEmail}, selectedData.id)).then(res=>{
                                if(res){
                                    setSelectedData(res)
                                }
                            })
                         }else{
                            dispatch(updateFollowupListPhysicianOnSaveReco(selectedData.referring_physician.id , {fax: state.physicianFax, phone_number:state.physicianPhone, email: state.physicianEmail}, selectedData.id)).then(res=>{
                                if(res){
                                    setSelectedData(res)
                                }
                            })
                         }
                           
                        }
                           }else{
                             dispatch(
                               showAlert('danger', 'Error', `You don't have permission to change reco details`)
                             );
                           }
                     
                    }
                    }>
                          {
                            isLoadingFollowUpList && type ==='others' ?
                                <Spinner
                                    animation="border"
                                    role="status"
                                    style={{ width: 30, height: 30 }}
                                /> :
                                "Save"
                        }
                    </Button>
                    </div>
                   
             
            </Dialog>
        </div>
    );
}