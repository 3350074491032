export const FLASH_SUCCESS = "FLASH_SUCCESS";
export const FLASH_ERROR = "FLASH_ERROR";
export const FLASH_CLOSE = "FLASH_CLOSE";

export const flashSuccess = (message, persist = false) => {
  return async dispatch => {
    try {
      dispatch({
        type: FLASH_SUCCESS,
        payload: message,
        persist
      });
    } catch (err) {}
  };
};

export const flashError = (message, persist = false) => {
  return async dispatch => {
    try {
      dispatch({
        type: FLASH_ERROR,
        payload: message,
        persist
      });
    } catch (err) {}
  };
};

export const flashClose = () => {
  return async dispatch => {
    try {
      dispatch({
        type: FLASH_CLOSE
      });
    } catch (err) {}
  };
};
