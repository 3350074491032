import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Logo from '../Logo/Logo';
import checkmark from '../../../assets/checkout/checkmark-circle-done.png';
import checkmarkDisabled from '../../../assets/checkout/checkmark-circle-disabled.png';
import { HeaderContainer, HeaderHolder, HeaderWrapper, StepsHolder, StepHolder, SvgHolder, StepText } from './styles';

const STEPS = [
  { id: 0, text: 'Basics' },
  { id: 1, text: 'Time' },
  { id: 2, text: 'Location' },
  { id: 3, text: 'Confirm' }
];

const NavCheckoutStep = ({ imgSrc, completed, text, activeStep, compact }) => {
  return (
    <StepHolder>
      {!compact && (
        <SvgHolder>
          <img src={imgSrc} alt='checkmark' />
        </SvgHolder>
      )}
      <StepText completed={completed} activeStep={activeStep}>
        {text}
      </StepText>
    </StepHolder>
  );
};

const AppHeaderCheckout = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const activeStep = useSelector((state) => state.checkout.activeStep);

  const steps = isMobile ? STEPS.filter((s) => s.id === activeStep) : STEPS;

  return (
    <HeaderContainer>
      <HeaderHolder>
        <HeaderWrapper>
          <Link to='/'>
            <Logo onWhite />
          </Link>
          <StepsHolder>
            {steps.map(({ id, text }) => {
              return (
                <NavCheckoutStep
                  key={id}
                  text={text}
                  activeStep={id === activeStep}
                  imgSrc={id < activeStep ? checkmark : checkmarkDisabled}
                  completed={id < activeStep}
                  compact={isMobile}
                />
              );
            })}
          </StepsHolder>
        </HeaderWrapper>
      </HeaderHolder>
    </HeaderContainer>
  );
};

export default AppHeaderCheckout;
