import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { PreviousPageLink } from "../../Dashboard/PatientImport/index";
import { withRouter } from "react-router-dom";
import * as authActions from "../../../../actions/auth/actions";
import Flex from "../../../../components/_styles/Flex";
import { ReactComponent as ArrowLeftIcon } from "../../../../assets/arrow_left.svg";
import { Col, Row } from "react-bootstrap";

export const Card = styled.div`
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  min-height: 800px;    
  border-radius: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
`;

export const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin: 16px 14px 20px 24px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TitleAndLegend = styled(Flex)`
  padding: 25px;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #000000;
  align-items: center;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const FormItem = styled.div`
  width: 100%;
`;

const ModifiedCol = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const PhoneMockWrapper = styled.div`
  align-items: center;
  position: relative;
  left: 17%;
  border: 1px solid #939393;
  border-radius: 30px;
  width: 300px;
  height: 700px;
`;

const InnerScreen = styled.div`
  height: 570px;
  width: 270px;
  top: 60px;
  left: 14px;
  position: absolute;
  border: 1px solid #939393;
`;

const HomeButton = styled.div`
  height: 45px;
  width: 45px;
  bottom: 12px;
  left: 125px;
  border-radius: 10px;
  position: absolute;
  border: 1px solid #939393;
`;

const PowerButton = styled.div`
  border: 1px solid #939393;
  border-width: 1px 1px 0px 1px;
  border-radius: 5px 5px 0px 0px;
  position: absolute;
  left: 210px;
  top: -5px;
  height: 5px;
  width: 50px;
`;

const SensorCircle = styled.div`
  border: 1px solid #939393;
  border-radius: 5px;
  position: absolute;
  left: 115px;
  top: 25px;
  height: 10px;
  width: 10px;
`;

const PhoneSpeaker = styled.div`
  border: 1px solid #939393;
  border-radius: 5px;
  position: absolute;
  left: 140px;
  top: 25px;
  height: 10px;
  width: 40px;
`;

const BackButtonContainer = styled.div`
  display: flex;
  padding-top: 4%;
  padding-bottom: 10px;
  margin: 0 auto;
  align-items: center;
`;

const SaveButton = styled.button`
  padding: 15px 30px;
  width: 100%;
  margin: 20px 0px 10px 0px;
  background: #00AEEF;
  color: white;
  border-radius: 5px;
  border: none;
`;

const DetailsWrapper = styled.div`
  margin: 10px 30px;
`;

const TotalAudience = styled.div`
  background: #3751ff;
  padding: 14px 0px;
  display: flex;
  border-radius: 4px 4px 0px 0px;
`;

const TextLabel = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #ffffff;
  position: relative;
  margin: auto;
`;

const MessageTemplate = styled.div`
  background: #f2f4f9;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  border-radius: 10px 0px 10px 10px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  padding: 10px;
  margin-bottom: 10px;
  color: #3f4857;
`;

const MessagesWrapper = styled.div`
  position: absolute;
  width: 76%;
  right: 30px;
  top: 75px;
`;

function PreviewSMS() {
  const history = useHistory();

  const handleSaveDraft = () => {
    history.push("/auth/radAdmin/Campaigns");
  };

  return (
    <div>
      <Card>
        <Header>
          <Title>Preview</Title>
        </Header>
        <div>
          <Row style={{ marginTop: "35px" }}>
            <Col>
              <ModifiedCol>
                <PhoneMockWrapper>
                  <InnerScreen></InnerScreen>
                  <HomeButton></HomeButton>
                  <PowerButton></PowerButton>
                  <SensorCircle></SensorCircle>
                  <PhoneSpeaker></PhoneSpeaker>
                  <MessagesWrapper>
                    <MessageTemplate>
                      {`Hey {{ First_name }} - We saw that you had an exam on
                  {{ appointment_date }} and there were some health items to
                  follow up on. Click below to schedule an appointment to follow
                  your doctor’s recommendation`}
                    </MessageTemplate>
                    <MessageTemplate>
                      {`{{appointment_scheduling_link}}`}
                    </MessageTemplate>
                  </MessagesWrapper>
                </PhoneMockWrapper>
              </ModifiedCol>
            </Col>
            <Col>
              <DetailsWrapper>
                <TotalAudience>
                  <TextLabel>Total Audience</TextLabel>
                  <TextLabel>122 Recipients</TextLabel>
                </TotalAudience>
                <SaveButton>Publish</SaveButton>
                <BackButtonContainer onClick={() => handleSaveDraft()}>
                  <span style={{ cursor: "pointer", display: "flex" }}>
                    <ArrowLeftIcon />
                  </span>
                  <PreviousPageLink>Save Draft</PreviousPageLink>
                </BackButtonContainer>
              </DetailsWrapper>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
}

export default withRouter(connect(null, authActions)(PreviewSMS));
