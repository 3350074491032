import React from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid #dfe0eb;
  background: #fff;
  padding: 57px 30px;
  box-sizing: border-box;
  height: 238.6px;
`;
const Title = styled.h2`
text-align:center;
margin-bottom:30px;
color:${({ color }) => color}
font-weight:500;
font-family:Roboto Condensed;
font-size:40px;
`;
const Description = styled.p`
  text-align: center;
  font-weight: 600;
  font-size: 16px;
`;
const TimeStamp = styled.p`
  text-align: center;
`;

const RevenueCard = ({ price, label, color, isLoading }) => {
  return (
    <Container>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Spinner
            animation="border" role="status" size="sm"
          />
        </div>
      ) : (
        <>
          <Title color={color}>${price}</Title>
          <Description>{label}</Description>
        </>
      )}
    </Container>
  );
};

export default RevenueCard;
