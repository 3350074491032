import API from '../../libs/api';
import { signup, patchUserData } from '../auth';

export const CHANGE_VIEW = 'CHANGE_VIEW';
export const RESET_STATE = 'RESET_STATE';

export const changeView = (payload) => ({ type: CHANGE_VIEW, payload });
export const resetState = (payload) => ({ type: RESET_STATE, payload });

export const processStep = (history) => async (dispatch, getState) => {
  const view = getState().registerDoctor.view;

  if (view === 'BASIC_INFO') {
    const values = getState().form['createAccount'].values;
    await dispatch(signup(values, true));
    const user = getState().auth.user;

    if (!user.id) return;

    const resUser = await API.putRequest(`/api/v1/user/${user.id}/`, { email: user.email, role: 'unver_doctor' });
    const resDoctor = await API.getRequest(`/api/v1/user/${user.id}/doctor/`);

    dispatch(patchUserData({ role: resUser.data.role, doctor: resDoctor.data.results[0] }));
    dispatch(changeView('PRACTICE_INFO'));
  }

  if (view === 'PRACTICE_INFO') {
    const user = getState().auth.user;
    const values = getState().form['REGISTER_DOCTOR'].values;

    const [resUser, resDoctor, resContact] = await Promise.all([
      API.putRequest(`/api/v1/user/${user.id}/`, {
        email: user.email,
        role: user.role,
        first_name: values.fname,
        last_name: values.lname
      }),
      API.putRequest(`/api/v1/user/${user.id}/doctor/${user.doctor.id}/`, {
        npi_number: values.npi
      }),
      API.putRequest(`/api/v1/user/${user.id}/contact_info/${user.contactInfo.id}/`, {
        phone_number: values.phone
      })
    ]);

    dispatch(patchUserData({ ...resUser.data, doctor: resDoctor.data, contactInfo: resContact.data }));
    dispatch(changeView('CONFIRM'));
  }
};
