import React from 'react';
import { connect } from 'react-redux';
import {
  SubsectionHeader,
  SectionHolder,
  RadioOptionItem,
  RadioOptionsHolder,
} from './common';
import * as checkoutActions from '../../../actions/checkout';

function CurrentMed({ toggleCurrentMeds, currentMeds }) {
  const onChange = (e) => {
    toggleCurrentMeds(e.target.value);
  };

  return (
    <SectionHolder>
      <SubsectionHeader>
        Are you currently taking any medication?
      </SubsectionHeader>
      <RadioOptionsHolder>
        {['Yes', 'No'].map((opt, i) => {
          return (
            <RadioOptionItem
              key={`CurrentMed-${i}`}
              id={`CurrentMed-${i}`}
              onChange={onChange}
              checked={opt === currentMeds}
              // toggle={toggleCurrentMeds}
              half={true}
              text={opt}
              name="meds"
            />
          );
        })}
      </RadioOptionsHolder>
    </SectionHolder>
  );
}

function mapStateToProps(state) {
  const { currentMeds } = state.checkout;

  return {
    currentMeds,
  };
}

export default connect(mapStateToProps, checkoutActions)(CurrentMed);
