import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, MarkerClusterer, InfoWindow } from '@react-google-maps/api';

import { GOOGLE_MAP_API_KEY } from '../../libs/geo';

function GoogleMaps({ locations, currentLocation, onClick }) {
  const [center, setCenter] = useState({});
  const [google, setGoogle] = useState({});

  useEffect(() => {
    setCenter({ lat: currentLocation?.latitude ?? 0, lng: currentLocation?.longitude ?? 0 });
  }, [currentLocation]);

  const centerIcon = (location) => {
    return currentLocation?.address === location.address ? [38, 38] : [10, 10];
  };

  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: `calc(100vh - 65px)` }}>
        <LoadScript id='script-loader' googleMapsApiKey={GOOGLE_MAP_API_KEY} onLoad={() => setGoogle(window.google)}>
          <GoogleMap
            id='circle-example'
            mapContainerStyle={{
              height: '100%',
              width: '100%'
            }}
            zoom={11}
            center={center}
          >
            <MarkerClusterer>
              {(clusterer) =>
                locations.map((location, idx) => (
                  <div key={location.id}>
                    <Marker
                      position={{ lat: location.latitude, lng: location.longitude }}
                      clusterer={clusterer}
                      icon={{
                        url: `${process.env.PUBLIC_URL}/${
                          currentLocation?.address === location.address ? 'icon-marker-selected.png' : 'icon-marker.png'
                        }`,
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(...centerIcon(location))
                      }}
                      onMouseUp={() => onClick(location, idx)}
                    />
                    {currentLocation?.address === location.address && (
                      <InfoWindow position={{ lat: location.latitude, lng: location.longitude }} mapPaneName='test'>
                        <div>{location.address}</div>
                      </InfoWindow>
                    )}
                  </div>
                ))
              }
            </MarkerClusterer>
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
}

export default GoogleMaps;
