import { GET_REPORTS_FETCH, GET_REPORTS_RESOLVE, GET_REPORTS_REJECT, GET_REPORT_RESOLVE } from '../actions/reports';

const initialState = {
    loading: false,
    items: [],
    reportPage: {
        report: null,
        risks: [],
        recommendations: [],
        noteTypes: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
    case GET_REPORTS_FETCH:
        return {
            ...state,
            loading: true
        };
    case GET_REPORTS_RESOLVE:
        return {
            ...state,
            loading: false,
            items: action.payload
        };
    case GET_REPORTS_REJECT:
        return initialState;
    case GET_REPORT_RESOLVE:
        return {
            ...state,
            reportPage: action.payload
        };
    default:
        return state;
    }
};
