import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { InputLabel } from '@material-ui/core';
import { Prompt } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Flex from '../../../../components/_styles/Flex';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from './common';
import Dropdown from '../../SharedComponents/Dropdown';
import { getCohorts } from '../../../../actions/radminUploads';


const useStyles = makeStyles({
    title: {
        fontSize: '13px',
        color: '#3F4857'
    },
    textField: {
        '& .MuiOutlinedInput-input': {
            fontSize: 14,
            padding: '13.5px 14px'
        }
    },
})

export const Heading = styled.p`
    color: #3F4857;
    font-family: Roboto Condensed;
    font-weight: 600;
    line-height: 17.6px;
    font-size: 14px;
    margin-top: ${prop => prop.marginTop ? prop.marginTop : ""}
`
const MainHeading = styled.p`
    color: #3F4857;
    font-family: Roboto Condensed;
    font-weight: 600;
    line-height: 17.6px;
    font-size: 18px;
    margin-top: ${prop => prop.marginTop ? prop.marginTop : ""}
`
export const Text = styled.p`
    color: #3F4857;
    font-family: Roboto Condensed;
    font-weight: 400;
    line-height: 17.6px;
    margin: 0px
    font-size:${props => props.fontSize ? props.fontSize : "14px"}
    margin-top: ${props => props.marginTop ? props.marginTop : ""}
`
const InputContaier = styled.div`
    width: 60%
`
const InnnerContainer = styled.div`
    width: 95%;
    margin: 0 auto;
`
const SecondSection = styled.div`
    height: 275px;
`
const eventOptions = ['Follow up Identified', 'Reco Status changed to Overdue', 'Reco Status changed to Expired', 'Reco Status changed to Coming Due']
export const segmentConditionOptions = ['Is', 'Is Not']

function ActionBasedTrigger({cohorts, segment, segmentHandle, shouldBlockNavigation}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const totalCohorts = useSelector(state => state.radAdmin.cohorts.totalItems)

    const [page, setPage] = useState(1)
    const [selectedEvent, setSelectedEvent] = useState("Find a specific event");
    const [isAddSegmentClicked, setIsAddSegmentClicked] = useState(false);
    const [isClickedOnCondition, setIsClickedOnCondition] = useState(false);
    const [isClickedCohortDropdown, setIsClickedCohortDropdown] = useState(false);
    const [isClickedEventDropdown, setIsClickedEventDropdown] = useState(false);

    useEffect(() => {
        dispatch(getCohorts(page))
    }, []);

    const toBottomHandle = () => {
        if(totalCohorts !== cohorts.length){
            let nextPage = page + 1
            dispatch(getCohorts(nextPage));
            setPage(nextPage)
        }
    }

    return (
        <>
        <Prompt
                when={shouldBlockNavigation}
                message='You have unsaved changes, are you sure you want to leave?'
        />
        <div>
            <MainHeading>Trigger Condition</MainHeading>
            <InnnerContainer>
                <Heading>Trigger Event</Heading>
                <InputContaier>
                    <InputLabel className={classes.title}>People will enter this campaign when there is an identified risk trigger matching...</InputLabel>
                    <Dropdown 
                        options={eventOptions}
                        clickHandler={() => setIsClickedEventDropdown(!isClickedEventDropdown)}
                        open={isClickedEventDropdown}
                        value={selectedEvent}
                        onChange={(value) => {
                            setSelectedEvent(value)
                            setIsClickedEventDropdown(false)
                        }}
                    />
                </InputContaier>
                <SecondSection>
                <Heading marginTop="45px">Segment Conditions</Heading>
                <Text style={{ marginBottom: 10 }}>Check before the first question to make sure people match conditions</Text>
                {
                    !isAddSegmentClicked ?
                        <Button color="white" bgColor="#7F8996" onClick={() => setIsAddSegmentClicked(true)}>Add Segment Conditions</Button> :
                        <InputContaier>
                            <Flex style={{ justifyContent: 'space-between' }}>
                                <div style={{ width: '20%', position: 'relative' }}>
                                <Dropdown 
                                    options={segmentConditionOptions}
                                    clickHandler={() => setIsClickedOnCondition(!isClickedOnCondition)}
                                    open={isClickedOnCondition}
                                    value={segment.condition}
                                    onChange={(value) => {
                                        segmentHandle({...segment, condition: value})
                                        setIsClickedOnCondition(false)
                                    }}
                                />
                                </div>
                                <div style={{width: '70%'}}>
                                <Dropdown 
                                    options={cohorts.map(cohort => cohort.cohort_title)}
                                    clickHandler={() => setIsClickedCohortDropdown(!isClickedCohortDropdown)}
                                    open={isClickedCohortDropdown}
                                    value={segment.cohortTitle}
                                    onChange={(value) => {
                                        let { id } = cohorts.find(x => x.cohort_title === value)
                                        segmentHandle({...segment, cohortTitle: value, cohortId: id})
                                        setIsClickedCohortDropdown(false)
                                    }}
                                    bottomHandle={toBottomHandle}
                                />
                                </div>
                            </Flex>
                        </InputContaier>
                }
                </SecondSection>
            </InnnerContainer>
        </div>
        </>
    )
}

export default ActionBasedTrigger
