import styled, { css } from 'styled-components';

const ButtonMode = {
  'primary': css`
    border: 2px solid #00AEEF;
    background: #00AEEF;
    color: #ffffff;
  `,
  'secondary': css`
    border: 2px solid #d9ecff;
    background: #d9ecff;
    color: #00AEEF;
  `,
  'tertiary': css`
    border: 2px solid #e6e9f1;
    background: #e6e9f1;
    color: #3f4857;
  `,
  'ghost': css`
    border: 2px solid #00AEEF;
    background: transparent;
    color: #00AEEF;
  `
};

export const Button = styled.button.attrs({ type: 'button' })`
  margin: 0;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  outline: none !important;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  ${({ mode }) => ButtonMode[mode]}
`;

export const ButtonGroup = styled.div`
  display: inline-block;
  background: #e6e9f1;
  border-radius: 5px;
  overflow: hidden;

  ${Button} {
    min-width: 180px;
  }
`;
