import React from 'react';
import Alert from 'react-bootstrap/Alert';
import TooltipStyles from './styles';

const Tooltip = ({ step, tooltipContent, last }) => {
  return (
    <TooltipStyles currentStep={step}>
      <Alert style={{ position: 'relative', left: last ? '-35%' : undefined }} className='alert' variant='primary'>
        {tooltipContent}
        <div style={{ left: last ? '85%' : undefined }} className='arrow' />
      </Alert>
    </TooltipStyles>
  );
};

export default Tooltip;
