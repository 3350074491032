import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import styled from 'styled-components';

import Plans from './components/Scheduling/Plans';
import Plan from './components/Scheduling/SchedulingLanding';
import Checkout from './pages/Checkout';
import Login from './components/Account/Login';
import MultiFactorAuthentication from './components/Account/MultiFactorAuth';
import VerificationForDevice from './components/Account/VerificationForDevice'
import Register from './components/Account/CreateAccount';
import RegisterDoctor from './pages/RegisterDoctor';
import ForgotPassword from './components/Account/ForgotPassword';
import ResetPassword from './components/Account/ResetPassword';
import AuthenticateUser from './components/auth/Authentication';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import DocPreScanCallNotes from './components/DocPreScanCallNotes/DocPreScanCallNotes';
import Dashboard from './components/Dashboard/Dashboard';
import { loadUserInfo } from './actions/auth';
import { getScan } from './actions/scan';

const MainWrapper = styled.div`
  height: inherit;
`;

const PublicRoutes = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    dispatch(getScan());

    if (isLoggedIn) {
      dispatch(loadUserInfo());
    }
  }, []);

  return (
    <MainWrapper className='main-wrapper'>
      <ErrorBoundary>
        <Switch>
          <Redirect exact from='/' to='/login' />
          <Route exact path='/plans' component={Plans} />
          <Route exact path='/plans/:id' component={Plan} />
          <Route exact path='/plans/:id/checkout' component={Checkout} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/multi-factor-authentication' component={MultiFactorAuthentication} />
          <Route exact path='/verification-for-device' component={VerificationForDevice} />
          <Route exact path='/register' component={Register} />
          <Route exact path='/register-doctor' component={RegisterDoctor} />
          <Route exact path='/forgot-password' component={ForgotPassword} />
          <Route exact path='/password/reset/confirm/:uid/:token' component={ResetPassword} />
          <Route path='/docprescancallnotes' component={DocPreScanCallNotes} />
          <Route path='/auth' component={AuthenticateUser(Dashboard)} />
        </Switch>
      </ErrorBoundary>
    </MainWrapper>
  );
};

export default PublicRoutes;
