import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MUISlider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";

import Flex from "../_styles/Flex";
import Text from "../_styles/Text";

import "./MUISliderOverride.css";

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const Slider = withStyles({
  root: {
    color: "#D0F9DA",
    height: 2,
    padding: "15px 0",
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: "#00AEEF",
    boxShadow: iOSBoxShadow,
    marginTop: -5,
    marginLeft: -14,
    "&:focus,&:hover,&$active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 11px)",
    top: -22,
    "& *": {
      // background: 'transparent',
      color: "#000",
    },
    width: 150,
  },
  track: {
    height: 19,
    borderRadius: "10px",
  },
  rail: {
    height: 19,
    opacity: 1,
    backgroundColor: "#6F52ED",
    borderRadius: "10px",
  },
  mark: {
    backgroundColor: "#6F52ED",
    height: 8,
    width: 1,
    marginTop: -3,
    borderRadius: "10px",
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
})(MUISlider);

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

export default function CustomizedSlider({ onChange, defaultValue, remainingValue }) {
  return (
    <Flex>
      <Flex.Col flex={10}>
        <Slider
          defaultValue={defaultValue}
          onChange={onChange}
        />
      </Flex.Col>
    </Flex>
  );
}
