import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Box from '../../../components/_styles/Box';
import Button from '../../../components/UI/Button/Button';
import Flex from '../../../components/_styles/Flex';
import { IntegrationsPanel } from './styles';

import { getRadminUploads } from '../../../actions/radminUploads/uploadHistory';

const ImportHistory = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.user.id);


  const getImports = async (e) => {
    e.preventDefault();
    dispatch(getRadminUploads(userId));

  };

  return (
    <Box p={30}>
      <Link to={'/auth/radAdmin/Import'}>Go Back to Previous Page</Link>
      <Row>
        <Col lg={12} xl={8}>
          <IntegrationsPanel p={20} minHeight={500} minWidth={900}>
            <h3>Import History</h3>
            <Flex textAlign="left" flexDirection="column" flex="1">
            <Button success onClick={getImports}>
              Get Import History
            </Button>
            </Flex>
          </IntegrationsPanel>
        </Col>
      </Row>
    </Box>
  );
};

export default ImportHistory;
