import React, { useState, useEffect, forwardRef } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";

import { ReactComponent as CalendarIcon } from "../../../assets/calendar-outline.svg";
import { ReactComponent as RemoveDateIcon } from "../../../assets/close-outline.svg";
import {
  showAlert,
  getUsersList,
  updateTask,
} from "../../../actions/radminUploads/index";

import { ReactComponent as CloseIcon } from "../../../assets/cancel-outline.svg";

import styled from "styled-components";
import Flex from "../../../components/_styles/Flex";
import moment from "moment";
import { capitalize } from "lodash";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "28px",
    fontWeight: 700,
    color: "#0C2945",
    fontFamily: "Roboto Condensed",
    marginRight: "40px",
  },
  saveBtn: {
    background: "#4dabf5",
    color: "white",
    padding: "3px 20px",
    marginTop: "24px",
    marginBottom: "21px",
    width: "145px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#4dabf5",
    },
    "&:disabled": {
      background: "#00AEEF4D",
      color: "white",
    },
  },

  date: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#252733",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  let { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <>{children}</>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "98%",
    margin: "0 auto",
  },
}))(MuiDialogContent);

const DialogContentStyled = styled(DialogContent)``;

const StyledRowHeader = styled.h2`
  font-family: Roboto Condensed;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.4px;
  color: #0c2945;
  margin-bottom: 15px;
`;

const StyledRowContainer = styled.div`
  display: flex;
  border: 1px solid #cacaca;
  padding: 20px;
  border-radius: 8px;
  gap: 15px;
  background: #fafafa;
  margin-bottom: 30px;
`;

const DateClearButton = styled.div`
  cursor: pointer;
  display: flex;
`;

const DatePickerButton = styled(Flex)`
  padding: 0px 10px;
  height: 43px;
  width: 227px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
`;

const DateContainer = styled(Flex)`
  font-size: 14px;
  color: #828282;
  font-family: Open Sans;
  font-weight: 400;
`;

const taskOptions = [
  { value: "call", label: "Call" },
  { value: "fax", label: "Fax" },
  { value: "email", label: "Email" },
  { value: "sms", label: "SMS" },
  { value: "letter", label: "Letter" },
  { value: "general", label: "General" },
];

const priorityOptions = [
  { value: "high", label: "High" },
  { value: "medium", label: "Medium" },
  { value: "low", label: "Low" },
];

const SelectContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const SelectDropdownTitle = styled.div`
  margin-bottom: 4px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-transform: uppercase;
`;

const CustomDatePickerTime = styled.div`
  & .react-datepicker__time-container {
    width: 100px; 
  }

  & .react-datepicker__time {
    width: 100px;
  }

  & .react-datepicker__time-box {
    width: 100px !important;
  }
`;


const IndicatorsContainer = (props) => {
  return (
    <>
      <components.Option {...props}>
        <span style={{ marginRight: 5, paddingTop: 5 }}>
          <input
            type="radio"
            checked={props.isSelected}
            onChange={() => null}
            style={{ marginTop: 5 }}
          />{" "}
        </span>
        <label>{props.label}</label>
      </components.Option>
    </>
  );
};

export default function EditTask({
  openDialog,
  selectedData,
  editModalHandle,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoadingTask = useSelector(
    (state) => state.radAdmin?.cohortsRecommendations?.tasks?.isLoading
  );

  const [type, setType] = useState("");
  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);

  const updatePermission = rolePermissions
    ?.find((itm) => itm.name === "Recommendation Cohorts")
    ?.permissions.find(
      (itm) => itm.codename === "change_recommendationcohorts"
    )?.is_authorized;

  const [selectedTask, setSelectedTask] = useState(
    selectedData?.type
      ? { value: selectedData?.type, label: capitalize(selectedData?.type) }
      : ""
  );
  const [selectedPriority, setSelectedPriority] = useState(
    selectedData?.priority
      ? { value: selectedData?.priority, label: capitalize(selectedData?.priority) }
      : ""
  );
  const [selectedAssignedTo, setSelectedAssignedTo] = useState(
    selectedData?.assigned_to
      ? {
          value: selectedData?.assigned_to?.id,
          label: selectedData?.assigned_to?.name,
        }
      : ""
  );
  const [date, setDate] = useState(new Date(selectedData?.due_at) || null);


  const [assignedToOptions, setAssignedToOptions] = useState([]);

  useEffect(() => {
    dispatch(getUsersList()).then((res) => {
      if (res) {
        setAssignedToOptions(
          res.map((itm) => ({
            value: itm.id,
            label: itm.first_name + itm.last_name,
          }))
        );
      }
    });
  }, []);

  const DateTimePickerInput = forwardRef(({ onClick, date }, ref) => {
    const formatDate = (date) => {
      return date ? moment(date).format("MMM, DD, YYYY at HH:mm") : "No Date";
    };
    return (
      <DatePickerButton onClick={onClick} ref={ref}>
        <DateContainer>
          {date ? `${formatDate(date)}` : "Select date & time"}
        </DateContainer>
        {!date && <CalendarIcon />}
        {date && (
          <DateClearButton
            onClick={() => {
              setDate(null);
            }}
          >
            <RemoveDateIcon style={{ height: "13px" }} />
          </DateClearButton>
        )}
      </DatePickerButton>
    );
  });

  return (
    <div>
      <Dialog
        fullWidth
        onClose={editModalHandle}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        maxWidth="lg"
      >
        <DialogTitle
          id="customized-dialog-title"
          style={{ boxShadow: "0px 8px 10px 0px #0000001A", zIndex: 2 }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 40,
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <Typography className={classes.heading}>Edit Task</Typography>
            </div>

            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={editModalHandle}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContentStyled>
          <div>
            <StyledRowHeader>Task</StyledRowHeader>
            <StyledRowContainer>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SelectDropdownTitle>Task *</SelectDropdownTitle>
                <SelectContainer style={{ width: "227px" }}>
                  <Select
                    components={{ Option: IndicatorsContainer }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={taskOptions}
                    value={selectedTask}
                    onChange={(value) => setSelectedTask(value)}
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                    }}
                  />
                </SelectContainer>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SelectDropdownTitle>Assigned To *</SelectDropdownTitle>
                <SelectContainer style={{ width: "227px" }}>
                  <Select
                    components={{ Option: IndicatorsContainer }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={assignedToOptions}
                    value={selectedAssignedTo}
                    onChange={(value) => setSelectedAssignedTo(value)}
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                    }}
                  />
                </SelectContainer>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SelectDropdownTitle>Due Date & Time *</SelectDropdownTitle>
                <CustomDatePickerTime>
                <DatePicker
                  selected={date}
                  onChange={(date) => setDate(date)}
                  showTimeSelect
                  timeIntervals={30}
                  customInput={<DateTimePickerInput date={date} />}
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport'
                    }
                  }}
                />

                </CustomDatePickerTime>
           
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SelectDropdownTitle>Priority *</SelectDropdownTitle>
                <SelectContainer style={{ width: "227px" }}>
                  <Select
                    components={{ Option: IndicatorsContainer }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={priorityOptions}
                    value={selectedPriority}
                    onChange={(value) => setSelectedPriority(value)}
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                    }}
                  />
                </SelectContainer>
              </div>
            </StyledRowContainer>
          </div>
        </DialogContentStyled>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            boxShadow: "0px -4px 10px 0px #0000001A",
          }}
        >
          <Button
            disabled={
              !selectedPriority || !selectedAssignedTo || !selectedTask || !date 
            }
            className={classes.saveBtn}
            onClick={() => {
              if ((permission && updatePermission) || !permission) {
                setType("others");
                dispatch(
                  updateTask(
                    selectedData?.id,
                    {
                      due_at: date,
                      assigned_to: selectedAssignedTo?.value,
                      priority: selectedPriority?.value,
                      type: selectedTask?.value,
                    },
                    selectedAssignedTo?.label
                  )
                ).then((res) => {
                  if (res) {
                    editModalHandle();
                  }
                });
              } else {
                dispatch(
                  showAlert(
                    "danger",
                    "Error",
                    `You don't have permission to edit task`
                  )
                );
              }
            }}
          >
            {isLoadingTask && type === "others" ? (
              <Spinner
                animation="border"
                role="status"
                style={{ width: 30, height: 30 }}
              />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
