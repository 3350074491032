import React, { useState, useRef, useEffect, useCallback } from 'react';
import Select, { components } from 'react-select';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Flex from '../../../components/_styles/Flex';
import equalizer from '../../../assets/equalizer.png';
import filterOutline from '../../../assets/IO-icons/filter-outline.svg';
import { useLocation } from 'react-router';

const Card = styled(Flex)`
  width: 270px;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 22px 22px 19px;
`;

const FormContainer = styled.div`
  width: 230px;
`;

const SelectContainer = styled.div`
  margin-bottom: 20px;
`;

const FilterContainer = styled(Flex)`
  width: ${props => (props.showMenu ? '0' : '120px')};
  height: ${props => (props.showMenu ? '0' : '38px')};
  justify-content: space-around;
  align-items: center;
  margin: 0 10px;
  background: #ffffff;
  border: ${props => (props.showMenu === 1 ? '0' : '1px')} solid #c2ccd9;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-top: 15px;
  }
`;

const SettingsContainer = styled.div`
  position: relative;
  z-index: 1;
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  margin: 0 0 20px -211px;
  border-radius: 8px;
  margin-right: ${props => props.marginRight === "true" ? "20%" : ""};
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0%;
  z-index: 2;
`;
const Image = styled.img`
  display: block;
  width: 20px;
`;

const FilterButton = styled(Flex)`
  width: 100%;
  justify-content: space-around;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #00AEEF;
  height:38px;
`;

const FilterMenuTitle = styled.div`
  margin-bottom: 14px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
`;

const SelectDropdownTitle = styled.div`
  margin-bottom: 4px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.6;
  text-transform: uppercase;
`;

const Button = styled.button`
  width: 226px;
  height: 50px;
  background-color: #00AEEF;
  border-radius: 4px;
  border: 0;
  font: bold 16px 'Open Sans', sans-serif;
  text-align: center;
  color: #ffffff;
  cursor: ${props => (props.disabled ? '' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.3' : '1')};
`;

const FilterTypeTitle = styled.p`
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 0;
  cursor: default;
`;

const FilterLabel = styled.label`
  display: flex;
  margin: 2px auto;
  align-items: center;
`;

const FilterType = styled.span`
  cursor: pointer;
  text-transform: capitalize;
  font-size: 16px;
  margin-right: 3px;
  &:hover {
    font-size: 20px;
  }
`;

const FilterCategoriesContainer = styled.div`
  margin: 0 auto 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DEFAULT_VALUES = {
  radiologist: '',
  modality: '',
  anatomy: '',
  timeframe: '',
  careType: ''
};

export const HistoricalAdherenceFilter = props => {
  const { filterBy, setFilter, filterTypes, setFilterBy, setFilterValuesForRequest, setPageNumber } = props;
  const { filterValuesForRequest, marginRight, getFilterDetail } = props;

  const radiologists = useSelector(state => state.radAdmin.followUpList?.dropdownRadiologists.data);
  const modalities = useSelector(state => state.radAdmin.followUpList.modalities.data);
  const anatomies = useSelector(state => state.radAdmin.followUpList.anatomies.data);
  const timeframeStatuses = useSelector(state => state.radAdmin.followUpList.timeframeStatuses.data);

  const node = useRef();
  const [filterStep, setFilterStep] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState(['radiologist', 'modality', 'anatomy', 'timeframe']);
  const [selectedValues, setSelectedValues] = useState(DEFAULT_VALUES);

  const location = useLocation()

  const radiologistOptions = radiologists?.length
    ? radiologists.map(item => ({ value: item.id, label: `${item?.first_name} ${item?.last_name}`, category: 'radiologist' })).filter(item => item.value)
    : [];
  const modalityOptions = modalities?.length
    ?  [{ value: 'empty', label: '--Empty--', category: "modality" }].concat(modalities.map(item => ({ value: item, label: item, category: 'modality' })).filter(item => item.value))
    : [];
  const anatomyOptions = anatomies?.length
    ?  [{ value: 'empty', label: '--Empty--', category: "anatomy" }].concat(anatomies.map(item => ({ value: item, label: item, category: 'anatomy' })).filter(item => item.value))
    : [];
  const timeframeStatusOptions = timeframeStatuses?.length
    ? timeframeStatuses.map(item => ({ value: item, label: item, category: 'timeframe' })).filter(item => item.value)
    : [];

  const checkQParams = (obj) => {
    let params = []
    for (var key in obj) {
      if (obj[key] !== null && obj[key] != "")
         params.push(obj[key]); 
      }
      return params; 
  }

  useEffect(() => {
     let categories = checkQParams(filterValuesForRequest)
     let paramArray = []
     for (let i=0 ;i < categories.length; i++) {
        paramArray.push(`&${categories[i].category}=${categories[i].value}`)     
     }
     let paramString = paramArray.join("") 
     if (paramString && paramString !== undefined) {
        if (location?.pathname.includes('radAdmin/followUpList')) getFilterDetail(paramString) 
     }
 }, [filterValuesForRequest]);


  const handleFormSubmit = event => {
    event.preventDefault();
    const mergedFilters = {
      radiologist: selectedValues['radiologist'] || filterValuesForRequest['radiologist'],
      modality: selectedValues['modality'] || filterValuesForRequest['modality'],
      anatomy: selectedValues['anatomy'] || filterValuesForRequest['anatomy'],
      timeframe: selectedValues['timeframe'] || filterValuesForRequest['timeframe'],
    };
    setFilterBy([]);
    setFilterStep(0);
    setFilterValuesForRequest(mergedFilters);
    setSelectedValues(DEFAULT_VALUES);
    setPageNumber(1);

    const selectedCategories = Object.values(mergedFilters)
      .map(i => i.category)
      .filter(i => i);
    window.analytics.track(`Follow-Up-List-Filter-${selectedCategories.join('-').toUpperCase()}`);
  };

  const handleClickOutside = useCallback(
    e => {
      if (node.current && node.current.contains(e.target)) {
        return;
      }
      setFilterBy([]);
      setFilterStep(0);
      setSelectedValues(DEFAULT_VALUES);
    },
    [setFilterBy, setSelectedValues]
  );

  useEffect(() => {
    if (filterStep) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterStep, handleClickOutside]);

  const IndicatorsContainer = props => {
    return (
      <div style={{ backgroundColor: '#FFF' }}>
        <components.IndicatorsContainer {...props} />
      </div>
    );
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            style={{ margin: '5px'}}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white'}),
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        fontSize: '14px',
        color: 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
        backgroundColor: isSelected ? '#D9ECFF' : 'white',              
        display: 'flex',
      };
    },   
    multiValue: (styles, { data }) => ({
      ...styles,
      borderRadius: '25px',
      backgroundColor:'#EEEEEE',
      color:'#A1A1A1',
      padding:'4px 10px'
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color:'#A1A1A1',
      fontSize:'10px'
    }),
  };

  const handleFilter = () => {
    setFilterStep(1)
    setFilter(selectedCategories)
  }
  return (
    <>
      <FilterContainer showMenu={filterStep}>
        {filterStep === 0 && (
          <FilterButton onClick={() => handleFilter()}>Filter<Image src={filterOutline} alt={'settings icon'} />
          </FilterButton>
        )}
        <SettingsContainer ref={node} marginRight={marginRight}>
          {filterStep === 1 && (
            <Row>
              <Col sm={3} lg={3} xl={3}>
                <Card>
                  <FilterMenuTitle> Add filters </FilterMenuTitle>
                  <FormContainer>
                    <form onSubmit={handleFormSubmit}>
                      {filterBy.map(item => {
                        switch (item) {
                          case 'radiologist': {
                            return (
                              <SelectContainer key={item}>
                                <SelectDropdownTitle>{item}</SelectDropdownTitle>
                                <Select
                                  styles={colourStyles}
                                  options={radiologistOptions}
                                  isMulti
                                  closeMenuOnSelect={false}
                                  hideSelectedOptions={false}
                                  components={{
                                    Option, IndicatorsContainer
                                  }}
                                  onChange={value => setSelectedValues({ ...selectedValues, radiologist: value })}
                                  allowSelectAll={true}
                                  value={selectedValues['radiologist']}
                                />
                              </SelectContainer>
                            );
                          }
                          case 'modality': {
                            return (
                              <SelectContainer key={item}>
                                <SelectDropdownTitle>
                                  {item}
                                </SelectDropdownTitle>
                                <Select
                                  styles={colourStyles}
                                  options={modalityOptions}
                                  isMulti
                                  closeMenuOnSelect={false}
                                  hideSelectedOptions={false}
                                  components={{
                                    Option, IndicatorsContainer
                                  }}
                                  onChange={value => setSelectedValues({ ...selectedValues, modality: value })}
                                  allowSelectAll={true}
                                  value={selectedValues['modality']}
                                  
                                />                               
                              </SelectContainer>
                            );
                          }
                          case 'anatomy': {
                            return (
                              <SelectContainer key={item}>
                                <SelectDropdownTitle>{item}</SelectDropdownTitle>
                                <Select
                                  styles={colourStyles}
                                  options={anatomyOptions}
                                  isMulti
                                  closeMenuOnSelect={false}
                                  hideSelectedOptions={false}
                                  components={{
                                    Option, IndicatorsContainer
                                  }}
                                  onChange={value => setSelectedValues({ ...selectedValues, anatomy: value })}
                                  allowSelectAll={true}
                                  value={selectedValues['anatomy']}
                                />                               
                              </SelectContainer>
                            );
                          }
                          case 'timeframe': {
                            return (
                              <SelectContainer key={item}>
                                <SelectDropdownTitle>{item}</SelectDropdownTitle>
                                <Select
                                  styles={colourStyles}
                                  options={timeframeStatusOptions}
                                  isMulti
                                  closeMenuOnSelect={false}
                                  hideSelectedOptions={false}
                                  components={{
                                    Option, IndicatorsContainer
                                  }}
                                  onChange={value => setSelectedValues({ ...selectedValues, timeframe: value })}
                                  allowSelectAll={true}
                                  value={selectedValues['timeframe']}
                                />                               
                              </SelectContainer>
                            );
                          }
                          default:
                            return null;
                        }
                      })}
                      <Button>Apply Filters</Button>
                    </form>
                  </FormContainer>
                </Card>
              </Col>
            </Row>
          )}
        </SettingsContainer>
      </FilterContainer>
    </>
  );
};
