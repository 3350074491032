import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { PreviousPageLink } from "../../Dashboard/PatientImport/index";
import { withRouter } from "react-router-dom";
import * as authActions from "../../../../actions/auth/actions";
import Flex from "../../../../components/_styles/Flex";
import { ReactComponent as ArrowLeftIcon } from "../../../../assets/arrow_left.svg";
import { Col, Row } from "react-bootstrap";
import {
  FBIcon,
  LinkedinIcon,
  TwitterIcon,
  FormatIcon,
  CopyIcon,
  LinkIcon,
  EmojiIcon,
  DriveIcon,
  PhotoIcon,
  PrivateIcon,
  OptionsIcon,
  DeleteIcon,
} from "../Icons";

export const Card = styled.div`
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  /* margin: 0 0 20px 0; */
  border-radius: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
`;

export const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin: 16px 14px 20px 24px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TitleAndLegend = styled(Flex)`
  padding: 25px;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #000000;
  align-items: center;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const FormItem = styled.div`
  width: 100%;
`;

const EmailPreviewContainer = styled.div`
  background: #ffffff;
  border: 1px solid #c2ccd9;
  min-height: 300px;
  border-radius: 4px 4px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const PreviewHeader = styled.div`
  background: #404040;
  border-radius: 4px 4px 0px 0px;
`;

const PreviewContainer = styled.div`
  padding: 0px 10px;
`;

const Label = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "13px")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "16px")};
  color: ${(props) => (props.color ? props.color : "#777777")};
  padding: 6px 0px;
  border-bottom: ${(props) =>
    props.borderBottom
      ? props.borderBottom
      : `1px solid rgba(100, 121, 143, 0.122)`};
  height: ${(props) => (props.height ? props.height : "unset")};
`;

const PreviewLabel = styled.label`
  font-style: normal;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0px;
  padding: 8px;
  letter-spacing: 0.2px;
  color: #ffffff;
`;

const ActionsWrapper = styled.div``;

const ActionButtons = styled.div`
  display: flex;
  justify-content: end;
  padding-right: 25px;
  padding-bottom: 15px;
`;

const IconsWrapper = styled.span`
  margin-top: 6px;
  padding-bottom: 20px;
`;

const RightIcons = styled.span`
  float: right;
  position: relative;
  top: 28px;
`;

const IconSpan = styled.span`
  margin-left: 5px;
`;

const BackButtonContainer = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 10px;
  margin-right: 50px;
  align-items: center;
`;

const SaveButton = styled.button`
  padding: 15px 30px;
  margin: 20px 0px 10px 0px;
  background: #00AEEF;
  color: white;
  border-radius: 5px;
  border: none;
`;

const SendButton = styled.button`
  padding: 10px 30px;
  margin: 20px 0px 10px 0px;
  background: #1a73e8;
  border-radius: 4px;
  color: white;
  text-align: left;
  border: none;
`;

const EmailSignatureWrapper = styled.span`
  margin-left: 100px;
  display: grid;
`;

const SigName = styled.label`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 0px;
  color: #646384;
`;

const SigRole = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 0px;
  color: #222222;
`;

function PreviewEmail() {
  const history = useHistory();

  const handleSaveDraft = () => {
    history.push("/auth/radAdmin/Campaigns");
  };

  return (
    <div>
      <Card>
        <Header>
          <Title>Preview</Title>
        </Header>
        <div>
          <Row>
            <Col style={{ padding: "20px 100px" }}>
              <EmailPreviewContainer>
                <PreviewHeader>
                  <PreviewLabel>122 Recipients</PreviewLabel>
                </PreviewHeader>

                <PreviewContainer>
                  <Label>{`{{ patient_email }}`}</Label>
                  <Label color="#3E3F41">{`Hi there {{ First Name}}`}</Label>
                  <Label
                    color="#3E3F41"
                    height="150px"
                    lineHeight="19px"
                    borderBottom="none"
                  >
                    {`Hey {{ First_name }} - We saw that you had an exam on {{ Last_appointment_date}} and there were some health items to follow up on. Click below to schedule an appointment to follow your doctor’s recommendations`}
                    <p>{`{{ appointment_link }}`}</p>
                  </Label>

                  <EmailSignatureWrapper>
                    <SigName>Bansilal Brata</SigName>
                    <SigRole>Chief Radiologist</SigRole>
                    <SigRole>323.559.3439</SigRole>
                    <SigRole>www.seewithin.co</SigRole>
                    <IconsWrapper>
                      <FBIcon />
                      <IconSpan>
                        <LinkedinIcon />
                      </IconSpan>
                      <IconSpan>
                        <TwitterIcon />
                      </IconSpan>
                    </IconsWrapper>
                  </EmailSignatureWrapper>

                  <ActionsWrapper>
                    <SendButton>Send </SendButton>
                    <IconSpan>
                      <FormatIcon />
                    </IconSpan>
                    <IconSpan>
                      <CopyIcon />
                    </IconSpan>
                    <IconSpan>
                      <LinkIcon />
                    </IconSpan>
                    <IconSpan>
                      <EmojiIcon />
                    </IconSpan>
                    <IconSpan>
                      <DriveIcon />
                    </IconSpan>
                    <IconSpan>
                      <PhotoIcon />
                    </IconSpan>
                    <IconSpan>
                      <PrivateIcon />
                    </IconSpan>

                    <RightIcons>
                      <OptionsIcon />
                      <IconSpan>
                        <DeleteIcon />
                      </IconSpan>
                    </RightIcons>
                  </ActionsWrapper>
                </PreviewContainer>
              </EmailPreviewContainer>
            </Col>
          </Row>
        </div>
        <ActionButtons>
          <BackButtonContainer onClick={() => handleSaveDraft()}>
            <span style={{ cursor: "pointer", display: "flex" }}>
              <ArrowLeftIcon />
            </span>
            <PreviousPageLink>Save Draft</PreviousPageLink>
          </BackButtonContainer>
          <SaveButton>Publish</SaveButton>
        </ActionButtons>
      </Card>
    </div>
  );
}

export default withRouter(connect(null, authActions)(PreviewEmail));
