import { uniq } from 'lodash';

import API from '../../libs/api';

const scoped = (name) => `REPORTS/${name}`;

export const GET_REPORTS_FETCH = scoped('GET_REPORTS_FETCH');
export const GET_REPORTS_RESOLVE = scoped('GET_REPORTS_RESOLVE');
export const GET_REPORTS_REJECT = scoped('GET_REPORTS_REJECT');

export const GET_REPORT_FETCH = scoped('GET_REPORT_FETCH');
export const GET_REPORT_RESOLVE = scoped('GET_REPORT_RESOLVE');
export const GET_REPORT_REJECT = scoped('GET_REPORT_REJECT');

export const getReports = () => async (dispatch) => {
  try {
    dispatch({ type: GET_REPORTS_FETCH });

    const resReports = await API.getRequest('/api/v1/report/');

    const doctors = uniq(resReports.data.results.map((r) => r.doctor));
    const locations = uniq(resReports.data.results.map((r) => r.office_location));

    const [resDoctors, resLocations] = await Promise.all([
      Promise.all(doctors.map((id) => API.getRequest(`/api/v1/user/${id}/`))),
      Promise.all(locations.map((id) => API.getRequest(`/api/v1/location/${id}/`)))
    ]);

    const providers = uniq(resLocations.map((res) => res.data.provider));
    const resProviders = await Promise.all(providers.map((id) => API.getRequest(`/api/v1/provider/${id}/`)));

    const mapProviders = resProviders.reduce((acc, res) => ({ ...acc, [res.data.id]: res.data }), {});
    const mapDoctors = resDoctors.reduce((acc, res) => ({ ...acc, [res.data.id]: res.data }), {});
    const mapLocations = resLocations.reduce(
      (acc, res) => ({ ...acc, [res.data.id]: { ...res.data, provider: mapProviders[res.data.provider] } }),
      {}
    );

    const reports = resReports.data.results.map((r) => ({
      ...r,
      doctor: mapDoctors[r.doctor],
      office_location: mapLocations[r.office_location]
    }));

    dispatch({ type: GET_REPORTS_RESOLVE, payload: reports });

    return reports;
  } catch (error) {
    console.error(error);
    dispatch({ type: GET_REPORTS_REJECT });
  }
};

export const getReport = (reportId) => async (dispatch) => {
  try {
    dispatch({ type: GET_REPORT_FETCH });

    const [resReport, resRisks, resRecommendations] = await Promise.all([
      API.getRequest(`/api/v1/report/${reportId}/`),
      API.getRequest(`/api/v1/report/${reportId}/risk/`),
      API.getRequest(`/api/v1/report/${reportId}/recommendation/`)
    ]);

    const resAppointment = await API.getRequest(`/api/v1/appointment/${resReport.data.appointment}/`);
    const resNoteTypes = await API.getRequest(`/api/v1/recommendation_types/?scan=${resAppointment.data.scan}`);

    dispatch({
      type: GET_REPORT_RESOLVE,
      payload: {
        report: {
          ...resReport.data,
          appointment: resAppointment.data
        },
        risks: resRisks.data.results,
        recommendations: resRecommendations.data.results,
        noteTypes: resNoteTypes.data.results
      }
    });
  } catch (error) {
    console.error(error);
    dispatch({ type: GET_REPORT_REJECT });
  }
};
