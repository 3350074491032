import React from 'react';

export const InfoButtonIcon = ({fill}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill? fill : 'none'} xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99984 1.66699C8.35166 1.66699 6.7405 2.15573 5.37009 3.07141C3.99968 3.98709 2.93158 5.28858 2.30084 6.8113C1.67011 8.33401 1.50509 10.0096 1.82663 11.6261C2.14817 13.2426 2.94185 14.7274 4.10728 15.8929C5.27272 17.0583 6.75758 17.852 8.37409 18.1735C9.9906 18.4951 11.6662 18.3301 13.1889 17.6993C14.7116 17.0686 16.0131 16.0005 16.9288 14.6301C17.8444 13.2597 18.3332 11.6485 18.3332 10.0003C18.3332 8.90598 18.1176 7.82234 17.6988 6.8113C17.28 5.80025 16.6662 4.88159 15.8924 4.10777C15.1186 3.33395 14.1999 2.72012 13.1889 2.30133C12.1778 1.88254 11.0942 1.66699 9.99984 1.66699ZM9.99984 16.667C8.6813 16.667 7.39237 16.276 6.29604 15.5435C5.19971 14.8109 4.34523 13.7697 3.84064 12.5515C3.33606 11.3334 3.20404 9.99293 3.46127 8.69972C3.71851 7.40652 4.35345 6.21863 5.2858 5.28628C6.21815 4.35393 7.40603 3.71899 8.69924 3.46176C9.99245 3.20452 11.3329 3.33654 12.5511 3.84113C13.7692 4.34571 14.8104 5.2002 15.543 6.29652C16.2755 7.39285 16.6665 8.68178 16.6665 10.0003C16.6665 11.7684 15.9641 13.4641 14.7139 14.7144C13.4636 15.9646 11.768 16.667 9.99984 16.667Z" fill="#00AEEF"/>
    <path d="M9.99984 7.49967C10.4601 7.49967 10.8332 7.12658 10.8332 6.66634C10.8332 6.2061 10.4601 5.83301 9.99984 5.83301C9.5396 5.83301 9.1665 6.2061 9.1665 6.66634C9.1665 7.12658 9.5396 7.49967 9.99984 7.49967Z" fill="#00AEEF"/>
    <path d="M9.99984 8.33301C9.77882 8.33301 9.56686 8.42081 9.41058 8.57709C9.2543 8.73337 9.1665 8.94533 9.1665 9.16634V13.333C9.1665 13.554 9.2543 13.766 9.41058 13.9223C9.56686 14.0785 9.77882 14.1663 9.99984 14.1663C10.2209 14.1663 10.4328 14.0785 10.5891 13.9223C10.7454 13.766 10.8332 13.554 10.8332 13.333V9.16634C10.8332 8.94533 10.7454 8.73337 10.5891 8.57709C10.4328 8.42081 10.2209 8.33301 9.99984 8.33301Z" fill="#00AEEF"/>
    </svg>
  )
}

export const Arrow = ({fill}) => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill={`${fill ? fill : 'none'}`} xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L6 6L11 1" stroke="#9FA2B4" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  )
}

export const StatusIcon = ({fill}) => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill={`${fill ? fill : 'none'}`} xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8 0C7.7 0 4.3 3.2 3.9 7.2H1C0.4 7.2 0 7.6 0 8.2C0 8.8 0.4 9.2 1 9.2H8.6C8.9 9.2 9.2 9.1 9.4 8.8L10 8.1L11.1 10.9C11.2 11.3 11.6 11.5 11.9 11.5H12C12.3 11.5 12.7 11.3 12.8 11L14.2 8.9H15.3C15.9 8.9 16.3 8.5 16.3 7.9C16.3 7.3 15.9 6.9 15.3 6.9H13.7C13.4 6.9 13 7.1 12.9 7.4L12.3 8.4L11.3 5.8C11.2 5.5 10.9 5.2 10.5 5.2C10.1 5 9.7 5.2 9.5 5.5L8.1 7.2H6C6.4 3.9 9.6 1.4 13.1 2.1C15.3 2.6 17.1 4.4 17.6 6.6C18.4 10.4 15.5 13.8 11.8 13.8C10.3 13.8 8.9 13.2 7.8 12.3C7.4 12 6.9 12 6.5 12.3C6 12.7 6 13.4 6.4 13.8C8.2 15.4 10.7 16.3 13.4 15.7C16.4 15.1 18.9 12.6 19.5 9.6C20.5 4.4 16.7 0 11.8 0Z" fill="white"/>
    </svg>
  )
}

export const TriangleIcon = ({fill}) => {
  return (
    <svg width="7" height="6" viewBox="0 0 7 6" fill={`${fill ? fill : 'none'}`} xmlns="http://www.w3.org/2000/svg">
      <path d="M4.30296 4.9183C3.90314 5.45691 3.09686 5.45691 2.69705 4.9183L0.787638 2.34604C0.297831 1.6862 0.768822 0.75 1.59059 0.75L5.40941 0.749999C6.23118 0.749999 6.70217 1.68619 6.21236 2.34604L4.30296 4.9183Z" fill="#00AEEF"/>
    </svg>
  )
}

export const NoteIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.75 20H11.25C10.9185 20 10.6005 20.1317 10.3661 20.3661C10.1317 20.6005 10 20.9185 10 21.25C10 21.5815 10.1317 21.8995 10.3661 22.1339C10.6005 22.3683 10.9185 22.5 11.25 22.5H18.75C19.0815 22.5 19.3995 22.3683 19.6339 22.1339C19.8683 21.8995 20 21.5815 20 21.25C20 20.9185 19.8683 20.6005 19.6339 20.3661C19.3995 20.1317 19.0815 20 18.75 20Z" fill="#64C67C"/>
      <path d="M11.25 17.5H15C15.3315 17.5 15.6495 17.3683 15.8839 17.1339C16.1183 16.8995 16.25 16.5815 16.25 16.25C16.25 15.9185 16.1183 15.6005 15.8839 15.3661C15.6495 15.1317 15.3315 15 15 15H11.25C10.9185 15 10.6005 15.1317 10.3661 15.3661C10.1317 15.6005 10 15.9185 10 16.25C10 16.5815 10.1317 16.8995 10.3661 17.1339C10.6005 17.3683 10.9185 17.5 11.25 17.5Z" fill="#64C67C"/>
      <path d="M24.675 10.4125L17.875 2.91248C17.7581 2.78299 17.6155 2.67939 17.4562 2.60835C17.2968 2.53731 17.1244 2.50039 16.95 2.49998H8.2C7.78469 2.49503 7.37247 2.57193 6.98688 2.7263C6.60129 2.88067 6.24988 3.10948 5.95273 3.39967C5.65557 3.68986 5.41849 4.03574 5.25502 4.41756C5.09155 4.79938 5.0049 5.20967 5 5.62498V24.375C5.0049 24.7903 5.09155 25.2006 5.25502 25.5824C5.41849 25.9642 5.65557 26.3101 5.95273 26.6003C6.24988 26.8905 6.60129 27.1193 6.98688 27.2737C7.37247 27.428 7.78469 27.5049 8.2 27.5H21.8C22.2153 27.5049 22.6275 27.428 23.0131 27.2737C23.3987 27.1193 23.7501 26.8905 24.0473 26.6003C24.3444 26.3101 24.5815 25.9642 24.745 25.5824C24.9084 25.2006 24.9951 24.7903 25 24.375V11.25C24.9992 10.9402 24.8834 10.6417 24.675 10.4125ZM17.5 6.24998L20.925 9.99998H18.425C18.2941 9.99208 18.1661 9.95818 18.0484 9.90027C17.9308 9.84236 17.8258 9.76159 17.7397 9.66269C17.6536 9.56378 17.5881 9.44871 17.5469 9.3242C17.5057 9.19969 17.4898 9.06822 17.5 8.93748V6.24998ZM21.8 25H8.2C8.11297 25.005 8.02579 24.9929 7.94347 24.9642C7.86115 24.9355 7.7853 24.8908 7.72026 24.8328C7.65523 24.7747 7.6023 24.7044 7.56451 24.6258C7.52671 24.5473 7.50479 24.462 7.5 24.375V5.62498C7.50479 5.53793 7.52671 5.45269 7.56451 5.37413C7.6023 5.29557 7.65523 5.22524 7.72026 5.16718C7.7853 5.10912 7.86115 5.06446 7.94347 5.03577C8.02579 5.00708 8.11297 4.99491 8.2 4.99998H15V8.93748C14.9798 9.85826 15.3247 10.7496 15.9593 11.4171C16.594 12.0845 17.4669 12.4738 18.3875 12.5H22.5V24.375C22.4952 24.462 22.4733 24.5473 22.4355 24.6258C22.3977 24.7044 22.3448 24.7747 22.2797 24.8328C22.2147 24.8908 22.1389 24.9355 22.0565 24.9642C21.9742 24.9929 21.887 25.005 21.8 25Z" fill="#64C67C"/>
    </svg>
    
  )
}

export const RecoNoteNavIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#00AEEF"/>
      <path d="M13.878 9.79854C13.8784 9.97291 13.8101 10.1419 13.6849 10.2762L9.48708 14.7538C9.34458 14.9062 9.1398 15.0021 8.91779 15.0203C8.69579 15.0385 8.47475 14.9775 8.30329 14.8509C8.13184 14.7242 8.02402 14.5422 8.00355 14.3448C7.98308 14.1475 8.05164 13.951 8.19415 13.7986L11.9554 9.79854L8.32848 5.79847C8.25874 5.72214 8.20666 5.63431 8.17524 5.54002C8.14381 5.44574 8.13366 5.34686 8.14536 5.24908C8.15706 5.15129 8.19039 5.05653 8.24343 4.97023C8.29647 4.88393 8.36817 4.8078 8.45442 4.74622C8.54074 4.67788 8.64202 4.62611 8.75189 4.59418C8.86177 4.56224 8.97788 4.55082 9.09296 4.56063C9.20803 4.57044 9.31959 4.60127 9.42063 4.65118C9.52168 4.70109 9.61004 4.76902 9.68018 4.8507L13.7353 9.32838C13.8407 9.46651 13.8909 9.63204 13.878 9.79854Z" fill="white"/>
    </svg>
  )
}
