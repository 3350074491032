import React, { createRef, useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import RSelect from 'react-select';
import styled from 'styled-components';

import Box from '../../components/_styles/Box';
import Flex from '../../components/_styles/Flex';
import Heading from '../../components/_styles/Heading';
import Text from '../../components/_styles/Text';
import SearchResultCard from './SearchResultCard';
import Slider from '../../components/Slider';
import GoogleMaps from '../../components/GoogleMaps';
import Scrollable from '../../components/UI/Scrollable';
import { setProviderId } from '../../actions/checkout';
import { getProvidersAndLocations, getLocationsNextPage } from '../../actions/provider';
import { findGeocodeByAddress, filterGeocodesInRadius } from '../../libs/geo';

const Select = styled(RSelect)`
  flex: 1;
  width: 200px;
  height: 38px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #3f4857;

  &:first-child {
    margin-right: 20px;
  }
`;

const Input = styled.input.attrs({ type: 'text' })`
  flex: 1;
  width: 200px;
  height: 38px;
  margin-right: 20px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #c2ccd9;
  font-family: Roboto Condensed !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #3f4857;
`;

const Button = styled.button.attrs({ type: 'button' })`
  width: 100px;
  height: 38px;
  padding: 0;
  border: none;
  outline: none !important;
  border-radius: 5px;
  background: #00AEEF;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #f9fafc;

  &:disabled {
    background: #d2d3d7;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;

  ${Button}:first-child {
    margin-right: 10px;
  }
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;

    ${Select} {
      width: unset;
      margin: 0 0 20px 0;
    }

    ${Input} {
      width: unset;
      margin: 0 0 20px 0;
    }

    ${Button} {
      flex: 1;
    }
  }
`;

const useFilter = (items, scanFilter, geocodeFilter) => {
  const [filtered, setFiltered] = useState(items);

  useEffect(() => {
    let filtered = items;

    if (scanFilter) {
      filtered = filtered.filter((i) => i.scans_available.includes(scanFilter));
    }

    if (geocodeFilter) {
      filtered = filterGeocodesInRadius(filtered, geocodeFilter, 8046);
    }

    setFiltered(filtered);
  }, [items, scanFilter, geocodeFilter]);

  return filtered;
};

function RadiologySearchPage({ showSlider, title, nextStep, next }) {
  const dispatch = useDispatch();

  const locations = useSelector((state) => state.provider.locations);
  const hasMoreLocations = useSelector((state) => state.provider.locationsPagination.hasNextPage);
  const providersMap = useSelector((state) => state.provider.providers.reduce((map, p) => ({ ...map, [p.id]: p }), {}));
  const providerOptions = useSelector((state) => state.provider.providers.map((i) => ({ value: i.id, label: i.name })));
  const scanOptions = useSelector((state) => state.scan.items.map((i) => ({ value: i.id, label: i.name })));
  const checkoutScanId = useSelector((state) => state.checkout.planId);
  const providerId = useSelector((state) => state.checkout.providerId);

  const [address, setAddress] = useState('');
  const [currentLocation, setCurrentLocation] = useState(null);

  const [filterScan, setFilterScan] = useState(checkoutScanId);
  const [filterGeocode, setFilterGeocode] = useState(null);

  const cardRefs = locations.map(createRef);
  const scrollableRef = useRef();

  const filteredItems = useFilter(locations, filterScan, filterGeocode);

  useEffect(() => {
    dispatch(getProvidersAndLocations());
  }, []);

  useEffect(() => {
    if (filteredItems[0]) {
      setCurrentLocation(filteredItems[0]);
    } else {
      setCurrentLocation(filterGeocode);
    }
  }, [filteredItems[0]]);

  const handleClick = useCallback(
    (location, idx) => {
      const firstCard = cardRefs[0].current;
      const selectedCard = cardRefs[idx].current;

      setCurrentLocation(location);

      if (scrollableRef.current) {
        scrollableRef.current.scrollTop = selectedCard.offsetTop - firstCard.offsetTop;
      }
    },
    [cardRefs, scrollableRef]
  );

  const handleLocationsLoading = useCallback(
    debounce(() => dispatch(getLocationsNextPage()), 250),
    []
  );

  const handleChangeProvider = useCallback(
    (option) => {
      dispatch(setProviderId(option.value));
      dispatch(getProvidersAndLocations());
    },
    [dispatch]
  );

  const handleChangeScan = useCallback((option) => {
    setFilterScan(option.value);
  }, []);

  const handleChangeAddress = useCallback((event) => {
    setAddress(event.target.value);
  }, []);

  const handleClickGo = useCallback(async () => {
    const geocode = await findGeocodeByAddress(address);
    setFilterGeocode(geocode);
  }, [address]);

  const handleClickReset = useCallback(() => {
    setAddress('');
    setFilterScan(null);
    setFilterGeocode(null);
    dispatch(setProviderId(null));
    dispatch(getProvidersAndLocations());
  }, [dispatch]);

  const isMobile = useMediaQuery('(max-width: 768px)');

  if (isMobile) {
    return (
      <Box bg='#ffffff' p={30}>
        <Heading as='h2'>{title}</Heading>
        <Filters>
          <Select
            onChange={handleChangeProvider}
            value={providerOptions.find((o) => o.value === providerId) || null}
            options={providerOptions}
            isSearchable={false}
            isClearable={false}
            placeholder='Select provider'
          />
          <Select
            onChange={handleChangeScan}
            value={scanOptions.find((o) => o.value === filterScan) || null}
            options={scanOptions}
            isSearchable={false}
            isClearable={false}
            placeholder='Select scan'
          />
          <Input onChange={handleChangeAddress} value={address} placeholder='Enter zip code' />
          <ButtonGroup>
            <Button onClick={handleClickGo} disabled={!address} children='Go' />
            <Button onClick={handleClickReset} children='Reset' />
          </ButtonGroup>
        </Filters>
        <InfiniteScroll loadMore={handleLocationsLoading} hasMore={hasMoreLocations} initialLoad={false}>
          {filteredItems.map((location, idx) => (
            <div ref={cardRefs[idx]} onClick={() => handleClick(location, idx)} key={location.id}>
              <SearchResultCard
                provider={providersMap[location.provider] ?? {}}
                location={location}
                active={location.id === currentLocation?.id}
                next={next}
                nextStep={nextStep}
                compact
              />
            </div>
          ))}
          {!Boolean(filteredItems.length) && <div children='No results, please click "Reset" to search again' />}
        </InfiniteScroll>
      </Box>
    );
  }

  return (
    <Flex>
      <Flex.Col flex={1}>
        <Box bg='#fff' p={30}>
          <Heading as='h2'>{title}</Heading>
          {showSlider && (
            <Box mb={15}>
              <Box mb={25}>
                <Text>Distance to Center</Text>
              </Box>
              <Slider />
            </Box>
          )}
          <Filters>
            <Select
              onChange={handleChangeProvider}
              value={providerOptions.find((o) => o.value === providerId) || null}
              options={providerOptions}
              isSearchable={false}
              isClearable={false}
              placeholder='Select provider'
            />
            <Select
              onChange={handleChangeScan}
              value={scanOptions.find((o) => o.value === filterScan) || null}
              options={scanOptions}
              isSearchable={false}
              isClearable={false}
              placeholder='Select scan'
            />
          </Filters>
          <Filters>
            <Input onChange={handleChangeAddress} value={address} placeholder='Enter zip code' />
            <ButtonGroup>
              <Button onClick={handleClickGo} disabled={!address} children='Go' />
              <Button onClick={handleClickReset} children='Reset' />
            </ButtonGroup>
          </Filters>
          <Scrollable containerHeight='calc(100vh - 265px)' pr={30} ref={scrollableRef}>
            <InfiniteScroll
              loadMore={handleLocationsLoading}
              hasMore={hasMoreLocations}
              initialLoad={false}
              useWindow={false}
            >
              {filteredItems.map((location, idx) => (
                <div ref={cardRefs[idx]} onClick={() => handleClick(location, idx)} key={location.id}>
                  <SearchResultCard
                    provider={providersMap[location.provider] ?? {}}
                    location={location}
                    active={location.id === currentLocation?.id}
                    next={next}
                    nextStep={nextStep}
                  />
                </div>
              ))}
              {!Boolean(filteredItems.length) && <div children='No results, please click "Reset" to search again' />}
            </InfiniteScroll>
          </Scrollable>
        </Box>
      </Flex.Col>
      <Flex.Col flex={1}>
        <GoogleMaps locations={filteredItems} currentLocation={currentLocation} onClick={handleClick} />
      </Flex.Col>
    </Flex>
  );
}

RadiologySearchPage.defaultProps = {
  showSlider: false,
  title: 'Schedule Next Scan',
  nextStep: '/plans'
};

export default RadiologySearchPage;
