import API from "../../libs/api";
import { showAlert } from "../radminUploads";

export const GET_CHANGELOG_REQUEST = "GET_CHANGELOG_REQUEST";
export const GET_CHANGELOG_SUCCESS = "GET_CHANGELOG_SUCCESS";
export const GET_CHANGELOG_FAILED = "GET_CHANGELOG_FAILED";

export const getChangelog = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CHANGELOG_REQUEST });

    const { data } = await API.getRequest(`/api/v1/changelogs/`);

    dispatch({
      type: GET_CHANGELOG_SUCCESS,
      payload: {
        items: data,
      },
    });
  } catch (error) {
    dispatch(showAlert("danger", "Error", error.message));
    dispatch({
      type: GET_CHANGELOG_FAILED,
      payload: error,
    });
  }
};
