import styled from "styled-components";

const FormRow = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  color: black;
`;

const Form = styled.form`
  margin-top: 40px;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3f4857;
  margin: 12px 0px;
`;

const InputHolder = styled.div`
  border: 1px solid #c2ccd9;
  border-radius: 5px;
  padding: 10px;
`;

const InputSeparator = styled.div`
  padding: 0px 10px;
`;

export {
    FormRow,
    Form,
    FormItem,
    FormLabel,
    InputHolder,
    InputSeparator
}