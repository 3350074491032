import React from "react";
import styled from "styled-components";
import Gender from "../../components/Scheduling/MedicalHistory/ChooseGender";
import DoB from "../../components/Scheduling/MedicalHistory/DoB";
import MedicalCondition from "../../components/Scheduling/MedicalHistory/MedicalCondition";
import Symptoms from "../../components/Scheduling/MedicalHistory/Symptoms";
import CurrentMed from "../../components/Scheduling/MedicalHistory/CurrentMed";
import Allergies from "../../components/Scheduling/MedicalHistory/Allergies";
import Metal from "../../components/Scheduling/MedicalHistory/Metal";
import { FormContainer, FormHeader } from "./common";

const ModifiedFormContainer = styled(FormContainer)`
  width: 100% !important;
  margin-top: 20px;
  height: 100% !important;
`;

const InnerFormContainer = styled.div`
  width: 80%;
  @media (max-width:991px){
    width: 100%;
  }
`;

function MedicalHistory() {
  return (
    <ModifiedFormContainer>
      <FormHeader>Medical History</FormHeader>
      <InnerFormContainer>
        <Gender />
        <DoB />
        <MedicalCondition />
        <Symptoms />
        <CurrentMed />
        <Allergies />
        <Metal />
      </InnerFormContainer>
    </ModifiedFormContainer>
  );
}

export default MedicalHistory;
