import React, { useState, useEffect, useRef } from 'react';
import { Card } from './ImagingAdherenceChart';
import { isEmpty, isNull } from 'lodash';
import moment from 'moment';
import { Row, Col, Spinner } from 'react-bootstrap';
import Flex from '../../../components/_styles/Flex';
import styled, { keyframes } from "styled-components";
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip,LabelList, Cell, Text as TextLabel, Legend, Label } from 'recharts';
import { Title } from './RecomendationModalities';
import { DatePickerContainer, TooltipContainer, TooltipRow } from './ImagingAdherenceChart';
import { CloseSign, Image, SelectedItemContainer, SelectedItem } from './../FollowUpList/FollowUpList'
import { PercentageText, Text, BottomSection, ShowPerson, IconContainer } from './AdherenceOfToday';
import LegendItem, { Square } from './LegendItem';
import { RedPerson, GreenPerson } from './icons';
import { ReactComponent as TriangleDownIcon } from '../../../assets/triangle-down.svg';
import { DateContainer, DatePickerButton } from '../../../components/Dialog/EditFormCenter';
import { getAdherenceOfDate } from '../../../actions/radminUploads';
import { getAdherenceOverRate, getAdherenceBenchmark } from '../../../actions/radminUploads';
import { isArray } from 'lodash';
import close from '../../../assets/cancel-outline-chip.svg';

import { HistoricalAdherenceFilter, DEFAULT_VALUES } from './HistoricalAdheranceFilter';
import { filterTypes, restStatusesObj } from '../../RadAdmin/FollowUpList/FollowUpList';
import { AdherenceTimeFilter } from './AdherenceTimeFilter';




const Container = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    height: 55px;
    margin-top: 10px;
`;

const keyFrames = keyframes`
    from {opacity: 0;}
    to {opacity: 1;}
`

const GraphContainer = styled.div`
  width: 95vw;
  display: flex;
  margin-top: 10px;
  animation-name: ${keyFrames}
  animation-duration: 2s;
`;

const BarChartContainer = styled.div`
  width: 95vw;
  display: flex;
  margin-top: 10px;
  animation-name: ${keyFrames}
  animation-duration: 2s;
`;

const ComplianceContainer = styled.div`
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const FilterCard = styled(Flex)`
   justify-content: flex-end;
   flex-grow: 1;
   margin-top: 15px;
   margin-right:14px;
`;

const SelectedFilters = styled.div`
    display:'flex',
    flex-wrap:'wrap',
    width:'100%',
    justify-content: 'right'
`

const COLORS = {
    NON_MATURE: '#9FA2B4',
    ADHERENT: '#64C67C',
    COMING_DUE: '#FFD57C',
    OVERDUE: '#F73B3B',
    EXPIRED: '#6F52ED'
}

const AdherenceValue = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #000000;
`
const HistoricalBenchmarkValue = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #000000;
`

const DatesContainer = styled.div`
    padding: 10px 15px;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #D2D3D7;
    border-radius: 8px;
`

const requiredStatus = [{status:'Non-Mature', color: COLORS.NON_MATURE}, {status: 'Coming Due', color: COLORS.COMING_DUE}, {status: 'Overdue', color: COLORS.OVERDUE }, {status:'Adherent', color: COLORS.ADHERENT} , {status: 'Non-Compliant', color: COLORS.EXPIRED}]

const LineChartCustomTooltip = ({ active, payload, benchmark }) => {
    if (active && payload) {
      return (
        <div>
        <TooltipContainer style={{backgroundColor: '#D9ECFF'}}>
            <div style={{color: '#00AEEF', fontSize:'10px'}}>{(payload[0].payload.name)}</div>
            <AdherenceValue>Adherence: {payload[0].payload.adherence === 0 ? '0' : payload[0].payload.adherence.toFixed(2)} %</AdherenceValue>
            <HistoricalBenchmarkValue>Historical Benchmark: {benchmark} %</HistoricalBenchmarkValue>
            <div>
            <div style={{margin:'5px'}}><LegendItem legendText="Non-Mature: " color={COLORS.NON_MATURE} value={payload[0].payload.nonMature}/></div>
            <div style={{margin:'5px'}}><LegendItem legendText="Adherent: " color={COLORS.ADHERENT} value={payload[0].payload.adherent}/></div>
            <div style={{margin:'5px'}}><LegendItem legendText="Overdue: " color={COLORS.OVERDUE} value={payload[0].payload.overdue}/></div>
            <div style={{margin:'5px'}}><LegendItem legendText="Coming Due: " color={COLORS.COMING_DUE} value={payload[0].payload.comingDue}/></div>
            <div style={{margin:'5px'}}><LegendItem legendText="Non-Compliant: " color={COLORS.EXPIRED} value={payload[0].payload.expired}/></div>
            </div>
        </TooltipContainer>
        </div>
      );
    }
    return null;
  };

export const CustomizedActiveDot = ({cx, cy, payload, value, index, handleClick}) => {
        return (
              <circle cx={cx} cy={cy} r={5} fill="#FDBB13" stroke="#FDBB13" cursor="pointer" strokeWidth={5} r={4} onClick={() => handleClick(payload)}/>
        )
}

export const CustomizedDot = ({cx, cy, stroke, payload, value, index}) => {
    return (
        <circle cx={cx} cy={cy} r={5} fill="#FDBB13" stroke="#FDBB13" strokeWidth={5} r={4} />
  )
}

const CustomLabel = (props) => {
    const { x, y, value } = props;
    const formattedValue = Number.isInteger(value) ? value : value.toFixed(2);
  
    return (
      <text fontWeight={600} x={x} y={y} dy={-10} fill={'#00AEEF'} fontSize={10} textAnchor="middle">
        {formattedValue}% 
      </text>
    );
  };
  

const CustomTooltip = ({ active, payload, label, date, selectedLabel }) => {
    let requiredObj = requiredStatus.find(x => x.status === label)
    if (active && payload) {
      return (
        <TooltipContainer>
          <div>{selectedLabel}</div>
          <TooltipRow>
            <Square color={requiredObj.color || ''} />
            <div>{`${label}: ${payload[0].value}`}</div>
          </TooltipRow>
        </TooltipContainer>
      );
    }
    return null;
  };

export class DatePickerInput extends React.Component {
    formatDate = (date) => {
        return date ? `${moment(date).format('LL')}` : 'No Date';
    }
    render(props) {
        return (
        <DatePickerButton onClick={this.props.onClick}>
            <DateContainer>{`${this.formatDate(this.props.date)}`}</DateContainer>
            <TriangleDownIcon />
        </DatePickerButton>
        )
    }
}

// const DatePickerInput = ({ onClick, date }) => {
//     const formatDate = (date) => {
//         return date ? `${moment(date).format('LL')}` : 'No Date';
//     }
//     return (
//         <DatePickerButton onClick={onClick}>
//             <DateContainer>{`${formatDate(date)}`}</DateContainer>
//             <TriangleDownIcon />
//         </DatePickerButton>
//     )
// };

function HistoricalAdherence({data}) {
    const dispatch = useDispatch();
    const [date, setDate] = useState(null)
    const [filteredData, setFilteredData] = useState([]);
    const [showLineChart, setShowLineChart] = useState(true)
    const [showBarChart, setShowBarChart] = useState(false)
    const graphData = useSelector(state => state.radAdmin?.followUpList.adherenceOverRate.data)
    const graphStartDate = useSelector(state => state.radAdmin?.followUpList.adherenceOverRate.startDate)
    const graphEndDate = useSelector(state => state.radAdmin?.followUpList.adherenceOverRate.endDate)
    const loading = useSelector(state => state.radAdmin?.followUpList.adherenceOverRate.loading);
    const loadingDetails = useSelector(state => state.radAdmin?.followUpList.adherenceOfDate.loading);
    const [filterValuesForRequest, setFilterValuesForRequest] = useState(DEFAULT_VALUES);
    const [filterBy, setFilterBy] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [benchmark, setBenchMark] = useState();
    const [frequency, setFrequency] = useState({ value: "months", label: "Per Month", category: "frequency" });
    const [selectedLabel, setSelectedLabel] = useState();
    const mounted  = useRef()
    const recoConfigData = useSelector((state) => state.radAdmin.recoConfig.data);


    useEffect(() => {
        let arr = []
        if(!isEmpty(data)){
            let obj = data[0]
             arr = requiredStatus.map((item, i) => {
                 return{
                     count: i === 0? obj.non_mature_count: i === 1? obj.coming_due_count : i === 2? obj.overdue_count: i === 3? obj.adherent_count : obj.expired_count,
                     status: item.status
                 }
             })
        }
        setFilteredData(arr)
    }, [data]);

    useEffect(() => {
        let x = new Date();
        x.setDate(1);
        x.setMonth(x.getMonth()-1)
        setDate(x)
    }, [])

    useEffect(() => {
        if(!isNull(date)){
            dispatch(getAdherenceOfDate(date, filterValuesForRequest, frequency, selectedLabel, graphStartDate, graphEndDate, graphData));
        }
    }, [date, dispatch, filterValuesForRequest])

    useEffect(() => {
      const startDate = mounted?.current ? graphStartDate : moment().subtract(recoConfigData?.defaultRangeStart, "days").format("YYYY-MM-DD");
      const endDate = mounted?.current? graphEndDate : moment().subtract(recoConfigData?.defaultRangeEnd, "days").format("YYYY-MM-DD");
 
      dispatch(
        getAdherenceOverRate(filterValuesForRequest, startDate, endDate, frequency)
      );
      dispatch(getAdherenceBenchmark(filterValuesForRequest)).then(res => setBenchMark(res));
      mounted.current = true;
    }, [filterValuesForRequest]);

    const dateRangeChange=(startDate, endDate,filter,frequency)=>{
      window.analytics.track("Adherence-Timeframe-Change-Event",{startDate,endDate});
      dispatch(getAdherenceOverRate(filterValuesForRequest, startDate,endDate, frequency))
    }

    const onChange = dates => {
        let x = dates;
        x.setDate(1);
        setDate(x);
    };

    const closeSelected = (category, selectedItem) => {
        setPageNumber(1);
        const filters = filterValuesForRequest[category].filter(item => {return item !== selectedItem});
        setFilterValuesForRequest({ ...filterValuesForRequest, [category]: filters });
    };

    const fillColor = (status) => {
        switch (status) {
            case 'Non-Mature':
                return COLORS.NON_MATURE
                break;
            case 'Adherent':
                return COLORS.ADHERENT
                break;
            case 'Overdue':
                return COLORS.OVERDUE
                break;
            case 'Coming Due':
                return COLORS.COMING_DUE
                break;
            case 'Non-Compliant':
                return COLORS.EXPIRED
                break;
            default:
                return 'black'

        }
    }

    const handleDotClick = (payload) => {
        const date = new Date(payload.name)
        setShowLineChart(false)
        setShowBarChart(true)
        setDate(date);
        setSelectedLabel(payload.name);
    }

    const getPersonToShow = () => {
        if (!isEmpty(data)) {
            var adherent = data[0].adherent_count;
            var overdue = data[0].overdue_count;
            var coming_due = data[0].coming_due_count;
            var expired = data[0].expired_count;
        }
        let personIconsArray = []
        let countOfAdherent = adherent || 0
        let countOfOverdue = overdue || 0
        let countOfComingDue = coming_due || 0
        let countOfExpired = expired || 0
        let dividedBy = (countOfAdherent + countOfExpired);
        let number = dividedBy === 0? 0 : (countOfAdherent / dividedBy) * 100;

        number = Number((number).toFixed());
        let numberToShow = dividedBy === 0? 0 : (countOfAdherent / dividedBy) * 100;
        for (let i = 0; i < 10; i++) {
            if (number >= 5 && number <= 14 && i === 0) {
                personIconsArray.push(<GreenPerson />)
            } else if (number >= 15 && number <= 24 && i <= 1) {
                personIconsArray.push(<GreenPerson />)
            } else if (number >= 25 && number <= 34 && i <= 2) {
                personIconsArray.push(<GreenPerson />)
            } else if (number >= 35 && number <= 44 && i <= 3) {
                personIconsArray.push(<GreenPerson />)
            } else if (number >= 45 && number <= 54 && i <= 4) {
                personIconsArray.push(<GreenPerson />)
            } else if (number >= 55 && number <= 64 && i <= 5) {
                personIconsArray.push(<GreenPerson />)
            }
            else if (number >= 65 && number <= 74 && i <= 6) {
                personIconsArray.push(<GreenPerson />)
            }
            else if (number >= 75 && number <= 84 && i <= 7) {
                personIconsArray.push(<GreenPerson />)
            }
            else if (number >= 85 && number <= 94 && i <= 8) {
                personIconsArray.push(<GreenPerson />)
            }
            else if (number >= 95 && i <= 9) {
                personIconsArray.push(<GreenPerson />)
            } else {
                personIconsArray.push(<RedPerson />)
            }
        }
        return {
            personIconsArray,
            numberToShow: numberToShow || 0
        }
    }

    const closeSelectedDate = () => {
       setShowBarChart(false)
       setShowLineChart(true)
    }

    return (
      <Card>
        <Row>
          <Col sm={12} md={4}>
            <Container>
              <Title right="32px">Adherence as of:</Title>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {showBarChart && (
                  <>
                    {date && (
                      <SelectedItemContainer
                        style={{
                          marginRight: "5px",
                          maxWidth: "max-content",
                          marginBottom: "2px",
                        }}
                      >
                        <SelectedItem>
                          {selectedLabel}
                        </SelectedItem>
                        <CloseSign
                          style={{ cursor: "pointer" }}
                          onClick={() => closeSelectedDate(date)}
                        >
                          <Image src={close} alt={"close sign"} />
                        </CloseSign>
                      </SelectedItemContainer>
                    )}
                  </>
                )}
                {showLineChart ? (
                  <AdherenceTimeFilter dateRangeChange={dateRangeChange} graphStartDate={graphStartDate} graphEndDate={graphEndDate} showGroupBy={true} frequency={frequency} setFrequency={setFrequency} />
                ) : (
                 null
                )}
              </div>
            </Container>
          </Col>
          <Col sm={12} md={8}>
            <div style={{ display: "flex", alignItems:'center', height:'100%' }}>
              <SelectedFilters
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  justifyContent: "right",
                  alignItems: "center",
                  fontSize:'10px'
                }}
              >
                {Object.values(filterValuesForRequest).map((item) => {
                  if (!item) return null;
                  return item.map((i) => {
                    return (
                      <SelectedItemContainer style={{display:'flex', alignItems:'center'}} key={i.value}>
                        {Number.isInteger(i.value)
                          ? i.label
                          : i.value.replace(/_/g, " ")}
                        <CloseSign onClick={() => closeSelected(i.category, i)}>
                          <Image src={close} alt={"close sign"} />
                        </CloseSign>
                      </SelectedItemContainer>
                    );
                  });
                })}
              </SelectedFilters>
              <FilterCard>
                <HistoricalAdherenceFilter
                  filterBy={filterBy}
                  setFilter={setFilterBy}
                  filterTypes={filterTypes}
                  setFilterBy={setFilterBy}
                  setPageNumber={setPageNumber}
                  filterValuesForRequest={filterValuesForRequest}
                  setFilterValuesForRequest={setFilterValuesForRequest}
                  marginRight="true"
                />
              </FilterCard>
            </div>
          </Col>
        </Row>
        {showLineChart ? (
          <GraphContainer>
            <ResponsiveContainer width="100%" height={400}>
              {
                loading?  
                <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner animation="border" role="status" size="sm"/> 
              </div>:   <LineChart
                width={500}
                height={350}
                data={graphData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis fontWeight={600} fontSize="13px" stroke="#3F4857" dataKey="name" />
                <YAxis
                  ticks={[0, 20, 40, 60, 80, 100]}
                  tickFormatter={(value) => `${value}%`}
                  padding={{ bottom: 50 }}
                  fontWeight={700}
                  stroke="#A6ACBE"
                  fontSize="13px"
                />
                <Tooltip content={<LineChartCustomTooltip benchmark={benchmark} />} />
                <Line
                  dataKey="adherence"
                  stroke="#00AEEF"
                  dot={<CustomizedDot />}
                  activeDot={
                    <CustomizedActiveDot handleClick={handleDotClick} />
                  }
                  strokeWidth={3}
                  
                >
                  <LabelList content={<CustomLabel />} />

                </Line>
                {/* Benchmark Line */}
                <Line
                  data={graphData.map((itm) => ({ ...itm, benchmark: benchmark }))} 
                  type="monotone"
                  dataKey="benchmark"
                  stroke="#64C67C" 
                  strokeWidth={2}
                  dot={false}
                  isAnimationActive={false}
                  strokeDasharray="5 5"
                />

                <Legend
                  verticalAlign="bottom"
                  align="center"
                  height={30}
                  iconType="plainline"
                  iconSize={30}
                  layout="horizontal"
                  formatter={(value, entry, index) => {
                    if (index === 1) {
                      return <span style={{fontSize:'13px', fontWeight:600}}>
                      Historical Benchmark of last 15 months
                      </span>
                    }
                    return <span style={{fontSize:"13px", fontWeight:600}}>
                    Adherence
                    </span>
                  }}
                />
              </LineChart>
              }
            
            </ResponsiveContainer>
          </GraphContainer>
        ) : (
          <BarChartContainer>
            <ResponsiveContainer width="80%" height={320}>
            {loadingDetails ?  
                <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner animation="border" role="status" size="sm"/> 
              </div>: 
              <BarChart
                data={filteredData}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid strokeDasharray="5 5" vertical={false} />
                <XAxis
                  dataKey="status"
                  axisLine={false}
                  tickLine={false}
                  scale="point"
                  padding={{ left: 50, right: 40 }}
                />
                <YAxis axisLine={false} tickLine={false} />
                <Tooltip content={<CustomTooltip date={date} selectedLabel={selectedLabel} />} />
                <Bar dataKey="count" barSize={70} fill={COLORS.ADHERENT}>
                  {requiredStatus.map((item, index) => (
                    <Cell key={`cell-${index}`} fill={fillColor(item.status)} />
                  ))}
                  {requiredStatus.map((item, index) => (
                    <LabelList key={`cell-${index}`} position="top" />
                  ))}
                </Bar>
              </BarChart>
             }
            </ResponsiveContainer>
            <ComplianceContainer>
              <PercentageText>
                {Number(getPersonToShow().numberToShow.toFixed(2))}%
              </PercentageText>
              <Text>
                Adherence rate as of{" "}
                {selectedLabel}
              </Text>
              <BottomSection>
                <ShowPerson>
                  {getPersonToShow().personIconsArray.map((x, i) => (
                    <IconContainer key={i}>{x}</IconContainer>
                  ))}
                </ShowPerson>
              </BottomSection>
            </ComplianceContainer>
          </BarChartContainer>
        )}
      </Card>
    );
}

export default HistoricalAdherence
