import React from "react";
import PropTypes from "prop-types";

import cs from "classnames";
import styled from "styled-components";

const FlexWrapper = styled.div`
  display: flex;
  flex: ${props => props.styles.flex};
  align-items: ${props => props.styles.alignItems};
  justify-content: ${props => props.styles.justifyContent};
  flex-wrap: ${props => props.styles.flexWrap};
  flex-direction: ${props => props.styles.flexDirection};
  height: ${props => props.styles.height};
  padding: ${props => props.styles.padding + "px"};

  @media (max-width: 760px) {
    flex-direction: ${props => (props.responsive ? "column" : "row")};
  }
`;

const FlexColumn = styled.div`
  margin: ${props => (props.noMargin ? "0" : "0 15px")};
  align-self: ${props => props.alignSelf};

  width: 100%;

  @media (min-width: 576px) {
    width: ${props => props.w};
  }
`;

class Flex extends React.Component {
  classes() {
    return cs(this.props.className, "flex-wrapper");
  }

  render() {
    const {
      shortHand,
      vAlign,
      jContent,
      fWrap,
      direction,
      height,
      padding
    } = this.props;

    const styles = {
      flex: shortHand,
      alignItems: vAlign,
      justifyContent: jContent,
      flexWrap: fWrap,
      flexDirection: direction,
      height: height,
      padding
    };

    return (
      <FlexWrapper
        styles={styles}
        className={this.classes()}
        style={this.props.style}
        responsive={this.props.responsive}
      >
        {this.props.children}
      </FlexWrapper>
    );
  }
}

Flex.Column = props => {
  return (
    <FlexColumn
      className="flex-column"
      w={props.width}
      alignSelf={props.alignSelf}
      noMargin={props.noMargin}
    >
      {props.children}
    </FlexColumn>
  );
};

Flex.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Flex;
