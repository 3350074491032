import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { media } from '../../../theme';

const Image = styled.img`
  height: ${(props) => props.height}em;
  margin: 0px auto;

  ${media.md`
    margin: ${(props) => props.margin}px;
  `}
  
  @media (max-width: 991px) {
    max-width: inherit;
  }
`;

const Logo = ({ onWhite, height, margin }) => {
  const renderLogo = `${process.env.PUBLIC_URL}/${
    onWhite ? 'within_wordmark_blue.png' : 'within-wordmark-tagline-web-600x136-white.png'
  }`;

  return (
    <Image
      id="logo"
      src={renderLogo}
      alt="Within Health Logo"
      height={height}
      margin={margin}
    />
  );
};

Logo.propTypes = {
  onWhite: PropTypes.bool,
  height: PropTypes.number,
  margin: PropTypes.number,
};

Logo.defaultProps = {
  onWhite: false,
  height: 1.5,
  margin: 10,
};

export default Logo;
