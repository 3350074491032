import React from 'react';
import styled from 'styled-components';
import { RefferedPatientsIcon, ImagingFollowUpsIcon } from './Icons';

export const Card = styled.div`
  background: #FFFFFF;
  border: 1px solid #C2CCD9;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  border-radius: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #666B86;
  cursor: pointer;
  &:hover {
    border: 1px solid #00AEEF;
    transition: all 0.3s ease;
  }
`

export const Top = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 0 30px 0;
`

export const Initials = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(214,207,255,1) 100%);
  font-size: 18px;
`

export const Name = styled.p`
  margin: 14px 0 0 0;
  padding: 0;
`

export const Label = styled.span`
  margin: 0;
  padding: 0;
`

export const AdherentCount = styled.p`
  margin: 7px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 24px;
  background: #64C67C;
  border-radius: 100px;
  font-family: "Open Sans",sans-serif !important;;
  font-size: 11px;
  line-height: 14px;
  color: #FFFFFF;
`

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StatsList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  width: 100%;
  border-top: 1px solid #DFE0EB;
`

export const ListItem = styled.li`
  width: 25%;
  border-right: 1px solid #DFE0EB;
`

export const ItemWrapper = styled.p`
  margin: 5px 0 5px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const IconContainer = styled.span`
  margin-right: 0;
`

export const Count = styled.span`
  font-family: "Open Sans",sans-serif !important;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #0C2945;
`

const CircleIcon = styled.span`
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${props => props.color ? '#F12B2C' : '#FEC400'};
`

const TopDoctorCard = ({info, goToPatientList}) => {
  return (
    <Card onClick={() => goToPatientList(info)}>
      <Top>
        <TopWrapper>
          <Initials>{info.initials}</Initials> 
          <Name>{info.name}</Name>
          <AdherentCount>{info.adherentPatients}</AdherentCount>
          <Label>Adherent</Label>
        </TopWrapper>
      </Top>
      <StatsList>
        <ListItem>
          <ItemWrapper>
            <IconContainer><RefferedPatientsIcon /></IconContainer>
            <Count>{info.referredPatients}</Count>
            <Label>Patients</Label>
          </ItemWrapper>
        </ListItem>
        <ListItem>
          <ItemWrapper>
            <IconContainer><ImagingFollowUpsIcon /></IconContainer>
            <Count>{info.imagingRecos}</Count>
            <Label>Reports</Label>
          </ItemWrapper>
        </ListItem>
        <ListItem>
          <ItemWrapper>
            <IconContainer><CircleIcon /></IconContainer>
            <Count>{info.comingDuePatients}</Count>
            <Label>Coming Due</Label>
          </ItemWrapper>
        </ListItem>
        <ListItem>
          <ItemWrapper>
            <IconContainer><CircleIcon color={'#F12B2C'} /></IconContainer>
            <Count>{info.overduePatients}</Count>
            <Label>Overdue</Label>
          </ItemWrapper>
        </ListItem>
      </StatsList>
    </Card>
  )
}

export default TopDoctorCard;
