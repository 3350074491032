import React from 'react';
import styled from 'styled-components';

const Modal = styled.div`
  display: ${props => props.showModal ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3;  
  top: 0;
  left: 0;
  width: 100%; 
  height: 100vh; 
  overflow: auto; 
  background: rgba(12, 41, 69, 0.05);
  backdrop-filter: blur(2px);
`

const ModalContentContainer = styled.div`
  width: 900px;
  margin: auto; 
  background-color: white;     
  @media (max-width: 768px) {
    width: 730px;
  }     
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
`

export const ModalPart = styled.div`
  margin-bottom: 35px;
`

export const ModalTitle = styled.p`
  position: relative;
  margin: 0;
  padding: 0;
  margin-top: 19px;
  width: 100%;
  text-align: left;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 120%;
  color: #0C2945;
  &::after {
    content: '';
    position: absolute;
    width: 657px;
    height: 3px;
    background-color: #00AEEF;
    top: 28px;
    left: -30px;
  }
`

export const PatientInfoContainer = styled.div`
  width: 100%;
  margin-top: 34px;
  display: flex;
  justify-content: space-around;
`

export const InfoBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-top: 20px;
`

export const PatientInfo = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #3F4857;
  text-align: left;
`

export const HorizontalLine = styled.hr`
  width: 100%;
  height: 1px;
  margin: 0;
  padding: 0;
  margin-top: 2px;
  color: #DFE0EB;
`

export const InfoType = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 120%;
  color: #9FA2B4;
  text-align: left;
`

const ConfirmButton = styled.button`
  width: 95px;
  height: 30px;
  margin-top: 28px;
  margin-bottom: 20px;
  align-self: flex-end;
  background-color: #00AEEF;
  border-radius: 5px;
  border: 0;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
`

const ReportText = styled.p`
  margin: 48px 0 0 0;
  padding: 0;
  height: 150px;
  overflow: auto;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #3F4857;
  letter-spacing: 0.2px;
`

const DoctorInfoContainer = styled.p`
  margin-top: 33px;
  display: flex;
  justify-content: space-between;
  width: 550px;
`

const DoctorInfo = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #3F4857;
`

const InfoBlock = ({blockName, children}) => {
  return (
    <InfoBlockContainer>
      <PatientInfo>{children}</PatientInfo>
      <HorizontalLine />
      <InfoType>{blockName}</InfoType>
    </InfoBlockContainer>
  )
}

const PatientDetailsModal = ({showDetails, closeDetails, patientDetails}) => {
  const onCloseModal = (e) => {
    e.stopPropagation();
    closeDetails();
  }
  return (
    <>
    {
      patientDetails && (
      <Modal 
        onClick={onCloseModal}
        showModal={showDetails}
      >
        <ModalContentContainer>
          <Wrapper>
            <ModalPart>
              <ModalTitle>Patient Details</ModalTitle>
              <PatientInfoContainer>
                <InfoBlock blockName={'First Name'}>{patientDetails?.patient?.first_name}</InfoBlock>
                <InfoBlock blockName={'Last Name'}>{patientDetails?.patient?.last_name}</InfoBlock>
                <InfoBlock blockName={'Email'}>{patientDetails?.patient?.email}</InfoBlock>
                <InfoBlock blockName={'Phone Number'}>{patientDetails?.patient?.phone_number}</InfoBlock>
              </PatientInfoContainer>
            </ModalPart>
            <ModalPart>
              <ModalTitle>Report</ModalTitle>
              <ReportText>{patientDetails?.reportText}</ReportText>
            </ModalPart>
            <ModalPart>
              <ModalTitle>Referring Physician</ModalTitle>
              <DoctorInfoContainer>
                <DoctorInfo>{patientDetails?.referring_physician?.first_name}</DoctorInfo>
                <DoctorInfo>{patientDetails?.referring_physician?.last_name}</DoctorInfo>
                <DoctorInfo>{patientDetails?.referring_physician?.phone_number}</DoctorInfo>
              </DoctorInfoContainer>
            </ModalPart>
            <ModalPart>
              <ModalTitle>Radiologist</ModalTitle>
              <DoctorInfoContainer>
                <DoctorInfo>{patientDetails?.radiologist?.first_name}</DoctorInfo>
                <DoctorInfo>{patientDetails?.radiologist?.last_name}</DoctorInfo>
              </DoctorInfoContainer>
            </ModalPart>
            <ConfirmButton type={'button'} onClick={onCloseModal}>OK</ConfirmButton>
          </Wrapper>        
        </ModalContentContainer>
      </Modal> 
    )}
    </>
  )
}

export default PatientDetailsModal;
