import API from '../libs/api';

export const GET_CONSTANTS_FETCH = 'GET_CONSTANTS_FETCH';
export const GET_CONSTANTS_RESOLVE = 'GET_CONSTANTS_RESOLVE';
export const GET_CONSTANTS_REJECT = 'GET_CONSTANTS_REJECT';

export const getConstants = () => async (dispatch, getState) => {
    try {
        const loading = getState().constants.loading;
        if (loading) return;

        dispatch({ type: GET_CONSTANTS_FETCH });

        const [resFlags] = await Promise.all([
            API.getRequest('/api/v1/feature_flag/')
        ]);
        const { data: { count: totalRecords }} = resFlags
        let pages = 1;
        if (totalRecords) pages = Math.ceil(totalRecords/10);
        const pagesArray = Array(pages).fill().map((x, i) => i + 1)
        const featureFlagArray = await Promise.all(pagesArray.map(page => API.getRequest(`/api/v1/feature_flag/?page=${page}`)));
        let results = [];
        results = results.concat(...featureFlagArray.map(item => item?.data?.results))
        dispatch({
            type: GET_CONSTANTS_RESOLVE,
            payload: {
                featureFlags: results.map((r) => ({ ...r, value: r.value.toLowerCase() === 'true' }))
            }
        });
    } catch (error) {
        console.error(error);
        dispatch({ type: GET_CONSTANTS_REJECT });
    }
};