import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Nav, NavDropdown, Navbar, Image } from 'react-bootstrap';
import styled from 'styled-components';
import styles from './index.module.css';
import Logo from '../Logo/Logo';
import Flex from '../Flex/Flex';
import { logout } from '../../../actions/auth';
import MobileNavbar from '../../Dashboard/MobileNavbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const AVATAR_URL =
  'https://previews.123rf.com/images/metelsky/metelsky1809/metelsky180900233/109815470-man-avatar-profile-male-face-icon-vector-illustration-.jpg';

const HeaderHolder = styled.div`
  width: 100%;
  max-width: 1500px;
`;

const HeaderWrapper = styled.div`
  max-width: 90%;
  padding: 0px 20px;
  margin: auto;
  @media (max-width: 991px) {
    padding: 0;
  }
`;

const Button = styled.button`
  margin: 0 10px;
  padding: 5px 10px;
  border: none !important;
  outline: none !important;
  background: ${(props) => (props.primary ? '#ffc342' : 'transparent')};
  color: #ffffff;
  font-size: 20px !important;
`;

const ROLE_TO_NAVS = {
  'patient': [
    { id: 'DASHBOARD', title: 'Dashboard' },
    { id: 'REPORTS', title: 'Reports' },
    { id: 'APPOINTMENTS', title: 'Appointments' },
    { id: 'LOG_OUT', title: 'Log out' }
  ],
  'doctor': [
    { id: 'APPOINTMENTS', title: 'Appointments' },
    { id: 'PATIENTS', title: 'Patients' },
    { id: 'LOG_OUT', title: 'Log out' }
  ],
  'unver_doctor': [{ id: 'LOG_OUT', title: 'Log out' }]
};

const AppHeader = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);

  const onClickLogin = useCallback(() => {
    history.push('/login');
    window.analytics.track('Plan Nav Login Click');
  }, [history]);

  const onClickRegister = useCallback(() => {
    history.push('/register');
    window.analytics.track('Plan Nav Register Click');
  }, [history]);

  const onClickNavItem = useCallback(
    (event) => {
      const option = event.target.dataset.option;

      if (option === 'DASHBOARD') {
        history.push('/auth/dashboard');
        window.analytics.track('Plan-Nav Dashboard Click');
        return;
      }

      if (option === 'REPORTS') {
        history.push('/auth/reports');
        window.analytics.track('Plan-Nav Reports Click');

        return;
      }

      if (option === 'APPOINTMENTS') {
        history.push('/auth/appointments');
        window.analytics.track('Plan-Nav Doc-Dash Click');
        return;
      }

      if (option === 'PATIENTS') {
        history.push('/auth/patients');
        window.analytics.track('Plan-Nav Doc-Patients Click');
        return;
      }

      if (option === 'LOG_OUT') {
        dispatch(logout());
        window.analytics.track('Plan-Nav Logout Click');
        return;
      }
    },
    [dispatch, history]
  );
  const isMobile = useMediaQuery('(max-width: 991px)');

  return (
    <header className='App-header App-header-white'>
      <HeaderHolder>
        <HeaderWrapper>
          <Flex style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Link to='/'>
              <Logo onWhite={false} />
            </Link>
            {isLoggedIn ? (
              <>
                {isMobile ? (
                  <MobileNavbar />
                ) : (
                  <Navbar className={styles.navbar} expand='lg'>
                    <Navbar.Collapse>
                      <Nav>
                        <Image className={styles.avatar} src={AVATAR_URL} roundedCircle />
                        <NavDropdown
                          className={styles.dropdown}
                          title={`${user.first_name} ${user.last_name}`}
                          alignRight
                        >
                          {ROLE_TO_NAVS[user.role].map(({ id, title }) => (
                            <NavDropdown.Item key={id} onClick={onClickNavItem} children={title} data-option={id} />
                          ))}
                        </NavDropdown>
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                )}
              </>
            ) : (
              <div>
                <Button onClick={onClickLogin} children='Login' type='button' />
                <Button onClick={onClickRegister} children='Register' type='button' primary />
              </div>
            )}
          </Flex>
        </HeaderWrapper>
      </HeaderHolder>
    </header>
  );
};

export default AppHeader;
