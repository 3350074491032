import React, { useState, useEffect, forwardRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Select, { components } from "react-select";
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import { isArray, capitalize } from "lodash";
import Flex from "../../../components/_styles/Flex";
import DatePicker from "react-datepicker";
import { DEFAULT_VALUES } from "../FollowUpList/FollowUpListFilter";
import { getDoctorsListDropdownAction, getDropdownValuesForCohort, getRadiologistForDropdown } from '../../../actions/radminUploads';
import {ReactComponent as RemoveRecoIcon } from '../../../assets/close-outline.svg';
import {ReactComponent as CloseIcon } from '../../../assets/cancel-outline.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/calendar-outline.svg';


export const DateClearButton = styled.div`
cursor: pointer;
display:flex;
`;

const DateRangeWrapper = styled.div`
width:227px;
font-size:12px;
  
`;



const useStyles = makeStyles({
  textField: {
      width: '100%',
      '& .MuiOutlinedInput-input': {
          fontSize: 14,
          borderRadius: "8px !important",
          padding: '13.5px 14px'
      }
  },
})

const Modal = styled.div`
  display: ${(props) => (props.showModal ? "flex" : "none")};
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  align-items:center;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  animation: changeWidth 8s ease-in-out infinite;
  background: rgba(12, 41, 69, 0.38);
  backdrop-filter: blur(10px);
  border-right: 1px solid whitesmoke;
`;

const ModalContentContainer = styled.div`
   width: 526px;
  margin: auto;
  border-radius: 4px;
  background-color: white;


`;

const ModalContent = styled.div`
  display: block;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  padding:0px 25px 0px 25px;
  height: 400px;
  &::-webkit-scrollbar { 
    display: none;
}
  border-radius: 4px;
  @media (max-width: 768px) {
    margin: 0;
  }
`;



const TitleText = styled.div`
  padding-top: ${(props) => props.pt || "0px"};
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #000000;
`;

const DateFilterContainer = styled(Flex)`
  width: 100%;
  margin-bottom: 10px;
  justify-content: center;
  display: ${props => props.isSelected !== undefined ? (props.isSelected ? 'block' : 'none') : 'block'};
`;

const SelectContainer = styled.div`
  margin-bottom: 0px;
  width: 227px;
`;

const SelectDropdownTitle = styled.div`
  margin-bottom: 4px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.6;
  text-transform: uppercase;
`;

const TimeframeContainer = styled(Flex)`
  width: 80%;
  flex-direction: column;
  gap:8px;
`;

const Timeframe = styled.p`
  margin: 0;
  padding: 0;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16.4px;
  letter-spacing: 0.2px;
  color: #6D6E6E;
  text-transform:uppercase;
`;

export const DatePickerContainer = styled(Flex)`
  width: 227px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

export const DatePickerButton = styled(Flex)`
padding:0px 10px;
  height:43.6px;
  width:227px;
  display:flex;
  justify-content:space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
`;

export const DateContainer = styled(Flex)`
  margin-right: 15px;
  font-size:12.5px;
`;

const ExportButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding-top:20px;
  padding-bottom:25px;
`;

const ExportButton = styled.button`
  width: 227px;
  height: 38px;
  background-color: #00AEEF;
  color: #ffffff;
  opacity: ${(props) => (props.disabled ? 0.65 : 1)};
  border-radius: 5px;
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  cursor:pointer;
`;

const AdditionalFiltersBtn = styled.button`
  display: ${(props) => (props.isOpen ? "none" : "block")};
  background: #7F8996;
  box-sizing: border-box;
  border: 1px solid #c2ccd9;
  border-radius: 8px;
  font-family: Open SansMedium;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #FFF;
  padding: 11px;
  min-width: 235px;
`;

const AdditionalFiltersSelect = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  background: #7F8996;
  box-sizing: border-box;
  border: 1px solid #c2ccd9;
  border-radius: 8px;
  padding: 0px 8px;
  min-width: 235px;
`;

const AdditionalFilterOption = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #FFF;
  padding: 8px;
  border-bottom: 1px solid #cbcccf;
  cursor: pointer;
`;

const RemoveContainer = styled.div`
`

const Remove = styled.div`
  float: right;
  margin-right: 30px;
  margin-top: -35px;
  display: flex;
  cursor: pointer;
`

const ModalHeader = styled.div`
padding:25px 25px 0px 25px;
`;

const ModalActions = styled.div`
  display: ${(props) => props.display};
`;

const unselectOp = {value: '', label: '-None-'}


function ExportModal({
  showModal,
  closeModal,
  datesRange,
  csvData,
  onNeedExport,
}) {
  const radiologists = useSelector(
    (state) => state.radAdmin.followUpList?.dropdownRadiologists.data
  );
  const modalities = useSelector(
    (state) => state.radAdmin.followUpList.modalities.data
  );
  const anatomies = useSelector(
    (state) => state.radAdmin.followUpList.anatomies.data
  );
  const timeframeStatuses = useSelector(
    (state) => state.radAdmin.followUpList.timeframeStatuses.data
  );
  const physicians = useSelector(
    (state) => state.radAdmin.doctorsOptions.data
  );
  const recoStatusData = useSelector(
    (state) => state.radAdmin.cohorts?.fixedValues?.data[0]?.values
  );

  const radiologistOptions = radiologists?.length
    ? radiologists
      .map((item) => ({ value: item?.id, label: capitalize(item?.first_name?.replace(/_/g," ") + " " + item?.last_name?.replace(/_/g," ")), category: "radiologist" }))
      .filter((item) => item.value)
    : [];
  const modalityOptions = modalities?.length
    ? modalities
      .map((item) => ({ value: item, label: capitalize(item), category: "modality" }))
      .filter((item) => item.value)
    : [];
  const anatomyOptions = anatomies?.length
    ? anatomies
      .map((item) => ({ value: item, label: capitalize(item), category: "anatomy" }))
      .filter((item) => item.value)
    : [];
  const timeframeStatusOptions = timeframeStatuses?.length
    ? timeframeStatuses
      .map((item) => ({ value: item, label: capitalize(item?.replace(/_/g," ")), category: "timeframe" }))
      .filter((item) => item.value)
    : [];
  const fixedValuesOptions = recoStatusData?.length
    ? recoStatusData
      .map((item) => ({ value: item, label: capitalize(item?.replace(/_/g," ")), category: "recommendation_status" }))
      .filter((item) => item.value)
  : [];

  const fixedValues = useSelector(state => state.radAdmin.cohorts.fixedValues?.data)
  
  const guidelineOptions =  fixedValues.find(val => val.attribute === 'guideline_type')?.values?.map((item) => ({ value: item, label: capitalize(item?.replace(/_/g," ")), category: "guideline_type" }))
  .filter((item) => item.value) ?? [];


  const careTypeOptions = [
    {value: 'emergency', label: 'Emergency', category: 'careType' },
    {value: 'inpatient', label: 'Inpatient', category: 'careType' },
    {value: 'outpatient', label: 'Outpatient', category: 'careType' },
    {value: 'preadmit', label: 'Pre-admit', category: 'careType' },
    {value: 'other', label: 'Other', category: 'careType' }
  ]

  const negatedOptions = [
    {value: true, label: 'True', category: 'negated' },
    {value: false, label: 'False', category: 'negated' }
  ]

  const conditionalOptions = [
    {value: true, label: 'True', category: 'conditional' },
    {value: false, label: 'False', category: 'conditional' }
  ]

  const classes = useStyles();
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [needExport, setNeedExport] = useState(false);
  const [selectedValues, setSelectedValues] = useState(DEFAULT_VALUES);
  const [showAFOptions, setAFOptions] = useState(false);
  const [docOptions, setDocOptions] = useState([]);
  const [primaryDocOptions, setPrimaryDocOptions] = useState([]);
  const additionFiltersOptions = [
    {key: "care_type", label: "Care Type"},
    {key: "department", label: "Department"},
    {key: "primary_care_physician", label: "Primary Care Physician"},
    {key: "referring_physician", label: "Referring Physician"},
    {key: "radiologist", label: "Radiologist"},
  ];

  const [filters, setSelectedFilters] = useState({
    radiologist: false,
    care_type: false,
    department: false,
    referring_physician: false,
    primary_care_physician: false
  });

  const onCloseModal = (e) => {
    e.stopPropagation();
    setStartDate(null);
    setEndDate(null);
    closeModal();
  };

  useEffect(() => {
    dispatch(getDoctorsListDropdownAction(1));
    dispatch(getDropdownValuesForCohort('Recommendation', 'guideline_type'));
    dispatch(getRadiologistForDropdown());
  }, [])

  useEffect(() => {
    let mappedDocs;
    if(physicians){
      mappedDocs = physicians.map(p => {
        return {
          value: p.id,
          label: capitalize(p.name?.replace(/_/g," ")),
          category: "referringPhysician"
        }
      })
      const primaryDocs = mappedDocs.map(p => ({...p, category: "primaryCarePhysician"}))
      setDocOptions(mappedDocs)
      setPrimaryDocOptions(primaryDocs)
    }
  }, [physicians])

  useEffect(() => {
    if (datesRange) {
      if (datesRange.length > 0) {
        setStartDate(Date.now());
        setEndDate(Date.now());
      } else {
        setStartDate(Date.now());
        setEndDate(Date.now());
      }
    }
  }, [datesRange]);

  useEffect(() => {
    if (startDate && endDate && needExport) {
      onNeedExport(
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD")
      );
      setNeedExport(false);
    }
  }, [startDate, endDate, needExport]);


  
  const DatePickerInput = forwardRef(({ onClick, startDate, endDate }, ref) => {
    const formatDate = (date) => {
      return date ? moment(date).format("MMM, DD, YYYY") : "No Date";
    };
    return (
      <DatePickerButton onClick={onClick} ref={ref}>
        <DateContainer>{ startDate && endDate ? `${formatDate(startDate)} - ${formatDate(
          endDate
        )}`:'Select...'}</DateContainer>
       {(!startDate || !endDate) && <CalendarIcon />}
              {(startDate &&endDate) && (
                <DateClearButton onClick={()=>{
                  setStartDate(null)
                  setEndDate(null)
                }}>
                  <RemoveRecoIcon style={{height:'13px'}} />
                </DateClearButton>
              )}
      </DatePickerButton>
    );
  });

  const onChange = (dates) => {
    if (isArray(dates)) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      setNeedExport(false);
    }
  };
  const onExportClick = (e, dates, history) => {
    const {modality, anatomy, careType, radiologist, timeframe, department, referringPhysician, primaryCarePhysician,negated, conditional, recommendation_status, guideline_type} = selectedValues;
  

    const filters = {
      modality: modality ? modality.value : "",
      anatomy: anatomy ? anatomy.value : "",
      careType: careType ? careType.value : "",
      radiologist: radiologist ? radiologist.value : "",
      timeframe: timeframe ? timeframe.value : "",
      referringPhysician: referringPhysician ? referringPhysician.value : "",
      primaryCarePhysician: primaryCarePhysician ? primaryCarePhysician.value : "",
      conditional: conditional === undefined ? "" : conditional.value,
      recommendation: recommendation_status ? recommendation_status.value : "",
      department,
      selectedOption,
      guidelineType: guideline_type ? guideline_type.value : ''
    };

    onNeedExport(
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      filters,
     
    );
    closeModal();
  };

  const handleFilterSelection = ({key, label}) => {
    filters[key] = !filters[key];
    setSelectedFilters({...filters})
    setAFOptions(!showAFOptions)
  }

  let debounceTimer;
  const handleDoctorsSearch = query => {
    clearTimeout(debounceTimer)

    debounceTimer = setTimeout(() => {
      dispatch(getDoctorsListDropdownAction(1, undefined, query))
    }, 500)
  }


  let initialSelectedOption = 'range start';

  const [selectedOption, setSelectedOption] = useState(initialSelectedOption);


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };



  return (
    <Modal showModal={showModal}>
      <ModalContentContainer>
        <ModalHeader>
          <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
          <TitleText style={{color:'#0C2945', fontWeight:700, fontSize:'24px', font:'Roboto Condensed'}}>Export Reports</TitleText>

          <CloseIcon  style={{cursor:'pointer'}} onClick={onCloseModal}/>

          </div>
          <TitleText style={{marginBottom:'22px', color:'#0C2945', fontWeight:700, fontSize:'20px', font:'Roboto Condensed'}} pt="15px">
          Please select a date range for when the study was created
          </TitleText>
        </ModalHeader>
        <ModalContent>
  
       
        <div style={{display:'flex', gap:'16px'}}>
        <DateFilterContainer>
            <TimeframeContainer>
              <Timeframe>Timeframe *</Timeframe>
              <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "5px",
                    height: "16px",
                    marginBottom: "5px",
                    whiteSpace: "nowrap",

                  }}
                >
                  <label
                    style={{
                      fontSize: "9px",
                      fontWeight: 400,
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                      marginBottom: "0px",
                    }}
                  >
                    <input
                      type="radio"
                      value="range start"
                      checked={selectedOption === "range start"}
                      onChange={handleOptionChange}
                      style={{
                        appearance: "none",
                        width: "16px",
                        height: "16px",
                        border: "2px solid",
                        borderRadius: "50%",
                        borderColor:
                          selectedOption === "range start"
                            ? "#00AEEF"
                            : "#D4D4D4",

                        backgroundColor:
                          selectedOption === "range start" ? "#00AEEF" : "#FFF",
                        padding: "1.5px",
                        backgroundClip: "content-box",
                      }}
                    />
                    RANGE START
                  </label>
                  <br />

                  <label
                    style={{
                      fontSize: "9px",
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                      marginBottom: "0px",
                    }}
                  >
                    <input
                      type="radio"
                      value="range end"
                      checked={selectedOption === "range end"}
                      onChange={handleOptionChange}
                      style={{
                        appearance: "none",
                        width: "16px",
                        height: "16px",
                        border: "2px solid",
                        borderRadius: "50%",
                        borderColor:
                          selectedOption === "range end"
                            ? "#00AEEF"
                            : "#D4D4D4",
                        backgroundColor:
                          selectedOption === "range end" ? "#00AEEF" : "#FFF",
                        padding: "1.5px",
                        backgroundClip: "content-box",
                      }}
                    />
                    RANGE END
                  </label>
                  <br/>
                  <label
                    style={{
                      fontSize: "9px",
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                      marginBottom: "0px",
                    }}
                  >
                    <input
                      type="radio"
                      value="exam date"
                      checked={selectedOption === "exam date"}
                      onChange={handleOptionChange}
                      style={{
                        appearance: "none",
                        width: "16px",
                        height: "16px",
                        border: "2px solid",
                        borderRadius: "50%",
                        borderColor:
                          selectedOption === "exam date"
                            ? "#00AEEF"
                            : "#D4D4D4",
                        backgroundColor:
                          selectedOption === "exam date" ? "#00AEEF" : "#FFF",
                        padding: "1.5px",
                        backgroundClip: "content-box",
                      }}
                    />
                    EXAM DATE
                  </label>
                </div>

              <DatePickerContainer>
                <DatePicker
                  popperPlacement={"top-end"}
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  customInput={
                    <DatePickerInput startDate={startDate} endDate={endDate} />
                  }
                  shouldCloseOnSelect={false}
                  selectsRange
                  dateFormat={"dd/MM/yyyy"}
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                />
              </DatePickerContainer>
            </TimeframeContainer>
           
          </DateFilterContainer>
          <DateFilterContainer>
            <TimeframeContainer>
              <Timeframe>Conditional</Timeframe>
              <DatePickerContainer>
                <SelectContainer key="Conditional">
                  <Select
                    components={{ IndicatorSeparator: () => null }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={[{...unselectOp, category: 'conditional'}, ...conditionalOptions]}
                    value={selectedValues["conditional"]}
                    onChange={(value) =>
                      setSelectedValues({ ...selectedValues, conditional: value })
                    }
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                      control: (styles) => ({ ...styles, borderRadius: "4px" }),
                    }}
                  />
                </SelectContainer>
              </DatePickerContainer>
            </TimeframeContainer>
           
          </DateFilterContainer>
        </div>
         <div style={{display:'flex', gap:'16px'}}>
         
          <DateFilterContainer>
            <TimeframeContainer>
              <Timeframe>Modality</Timeframe>
              <DatePickerContainer>
                <SelectContainer key="Modality">
                  <Select
                    components={{ IndicatorSeparator: () => null }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={[{...unselectOp, category: 'modality'}, ...modalityOptions]}
                    value={selectedValues["modality"]}
                    onChange={(value) =>
                      setSelectedValues({ ...selectedValues, modality: value })
                    }
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                      control: (styles) => ({ ...styles, borderRadius: "4px" }),
                    }}
                  />
                </SelectContainer>
              </DatePickerContainer>
            </TimeframeContainer>
           
          </DateFilterContainer>
          <DateFilterContainer>
            <TimeframeContainer>
              <Timeframe>Anatomy</Timeframe>
              <DatePickerContainer>
                <SelectContainer key="anatomy">
                  <Select
                    components={{ IndicatorSeparator: () => null }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={[{...unselectOp, category: 'anatomy'}, ...anatomyOptions]}
                    value={selectedValues["anatomy"]}
                    onChange={(value) =>
                      setSelectedValues({ ...selectedValues, anatomy: value })
                    }
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                      control: (styles) => ({ ...styles, borderRadius: "4px" }),
                    }}
                  />
                </SelectContainer>
              </DatePickerContainer>
            </TimeframeContainer>
           
          </DateFilterContainer>
         </div>
         <div style={{display:'flex', gap:'16px'}}>
         
          <DateFilterContainer>
            <TimeframeContainer>
              <Timeframe>Timeframe Extracted</Timeframe>
              <DatePickerContainer>
                <SelectContainer key="timeframe">
                  <Select
                    components={{ IndicatorSeparator: () => null }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={[{...unselectOp, category: 'timeframe'}, ...timeframeStatusOptions]}
                    value={selectedValues["timeframe"]}
                    onChange={(value) =>
                      setSelectedValues({ ...selectedValues, timeframe: value })
                    }
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                      control: (styles) => ({ ...styles, borderRadius: "4px" }),
                    }}
                  />
                </SelectContainer>
              </DatePickerContainer>
            </TimeframeContainer>
           
          </DateFilterContainer>
          <DateFilterContainer>
            <TimeframeContainer>
              <Timeframe>Status</Timeframe>
              <DatePickerContainer>
                <SelectContainer key="recommendation_status">
                  <Select
                    components={{ IndicatorSeparator: () => null }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={[{...unselectOp, category: 'recommendation_status'}, ...fixedValuesOptions]}
                    value={selectedValues["recommendation_status"]}
                    onChange={(value) =>
                      setSelectedValues({ ...selectedValues, recommendation_status: value })
                    }
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                      control: (styles) => ({ ...styles, borderRadius: "4px" }),
                    }}
                  />
                </SelectContainer>
              </DatePickerContainer>
            </TimeframeContainer>
           
          </DateFilterContainer>
         </div>
        
          <div style={{display:'flex', gap:'16px'}}>
         
          <DateFilterContainer >
            <TimeframeContainer>
              <Timeframe>Radiologist</Timeframe>
              <DatePickerContainer>
                <SelectContainer key="radiologist">
                  <Select
                    components={{ IndicatorSeparator: () => null }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={[{...unselectOp, category: 'radiologist'}, ...radiologistOptions]}
                    value={selectedValues["radiologist"]}
                    onChange={(value) =>
                      setSelectedValues({ ...selectedValues, radiologist: value })
                    }
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                      control: (styles) => ({ ...styles, borderRadius: "4px" }),
                    }}
                  />
                </SelectContainer>
              </DatePickerContainer>
            </TimeframeContainer>
           
          </DateFilterContainer>
          <DateFilterContainer>
            <TimeframeContainer>
              <Timeframe>Care Type</Timeframe>
              <DatePickerContainer>
                <SelectContainer key="careType">
                  <Select
                    components={{ IndicatorSeparator: () => null }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={[{...unselectOp, category: 'careType'}, ...careTypeOptions]}
                    value={selectedValues["careType"]}
                    onChange={(value) =>
                      setSelectedValues({ ...selectedValues, careType: value })
                    }
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                      control: (styles) => ({ ...styles, borderRadius: "4px" }),
                    }}
                  />
                </SelectContainer>
              </DatePickerContainer>
            </TimeframeContainer>
           
          </DateFilterContainer>
          </div>
         <div style={{display:'flex', gap:'16px'}}>
        
          <DateFilterContainer >
            <TimeframeContainer>
              <Timeframe>Primary Care Physician</Timeframe>
              <DatePickerContainer>
                <SelectContainer key="primary_care_physician">
                  <Select
                    components={{ IndicatorSeparator: () => null }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={[{...unselectOp, category: 'primaryCarePhysician'}, ...primaryDocOptions]}
                    value={selectedValues["primaryCarePhysician"]}
                    onInputChange={(value) =>
                      handleDoctorsSearch(value)
                    }
                    onChange={(value) =>
                      setSelectedValues({ ...selectedValues, primaryCarePhysician: value })
                    }
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                      control: (styles) => ({ ...styles, borderRadius: "4px" }),
                    }}
                  />
                </SelectContainer>
              </DatePickerContainer>
            </TimeframeContainer>
          
          </DateFilterContainer>
          <DateFilterContainer >
            <TimeframeContainer>
              <Timeframe>Referring Physician</Timeframe>
              <DatePickerContainer>
                <SelectContainer key="referring_physician">
                  <Select
                    components={{ IndicatorSeparator: () => null }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={[{...unselectOp, category: 'referringPhysician'}, ...docOptions]}
                    value={selectedValues["referringPhysician"]}
                    onInputChange={(value) =>
                      handleDoctorsSearch(value)
                    }
                    onChange={(value) =>
                      setSelectedValues({ ...selectedValues, referringPhysician: value })
                    }
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                      control: (styles) => ({ ...styles, borderRadius: "4px" }),
                    }}
                  />
                </SelectContainer>
              </DatePickerContainer>
            </TimeframeContainer>
         
          </DateFilterContainer>
         </div>
          <div style={{display:'flex', gap:'16px'}}>
       
          <DateFilterContainer >
            <TimeframeContainer>
              <Timeframe>Department</Timeframe>
              <DatePickerContainer>
                <SelectContainer key="department">
                  <TextField
                    id="outlined-uncontrolled"
                    variant="outlined"
                    value={selectedValues["department"]}
                    onChange={(e) => setSelectedValues({ ...selectedValues, department: e.target.value })}
                    className={classes.textField}
                    style={{borderRadius: "8px"}}
                    placeholder="Enter value"
                  />
                </SelectContainer>
              </DatePickerContainer>
            </TimeframeContainer>
          
          </DateFilterContainer>
          <DateFilterContainer >
            <TimeframeContainer>
              <Timeframe>GUIDELINE TYPE</Timeframe>
              <DatePickerContainer>
                <SelectContainer key="guideline_type">
                  <Select
                    components={{ IndicatorSeparator: () => null }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={[{...unselectOp, category: 'guideline_type'},...guidelineOptions]}
                    value={selectedValues["guideline_type"]}
                    onChange={(value) =>
                      setSelectedValues({ ...selectedValues, guideline_type: value })
                    }
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                      control: (styles) => ({ ...styles, borderRadius: "4px" }),
                    }}
                  />
                </SelectContainer>
              </DatePickerContainer>
            </TimeframeContainer>
         
          </DateFilterContainer>
          </div>
          
           
          
       
        </ModalContent>
        <ModalActions display="block">
          <ExportButtonContainer>
            <ExportButton
              disabled={!startDate || !endDate}
              onClick={onExportClick}
            >
              Export
            </ExportButton>
          </ExportButtonContainer>
        </ModalActions>
      </ModalContentContainer>
    </Modal>
  );
}
export default ExportModal;
