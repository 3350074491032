import React from 'react';

const logo = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 373.4 89.9"
    style={{ enableBackground: 'new 0 0 373.4 89.9' }}
    xmlSpace="preserve"
    width="100"
    height="100"
  >
    <style
      type="text/css"
      dangerouslySetInnerHTML={{
        __html: '\n\t.st0{fill:#73253E;}\n\t.st1{fill:#FFFFFF;}\n'
      }}
    />
    <polygon
      className="st0"
      points="-0.8,40 -0.8,89.9 373.4,89.9 373.4,40 -0.8,40 "
    />
    <path
      className="st1"
      d="M58.8,78v-8.8h8.1c3,0,4.5,1.3,4.5,4V78h5.6v-5.7c0-3.2-1.6-4.9-4.9-5V67c2.1-0.4,3.5-1,4.2-2
	c0.7-1,1-2.8,1-5.4c0-2.9-0.7-4.9-2.1-6c-1.4-1.1-3.9-1.7-7.5-1.7H53.2v26H58.8L58.8,78z M58.8,56.1h8.4c1.8,0,3,0.3,3.6,0.8
	c0.5,0.5,0.8,1.7,0.8,3.6c0,1.8-0.3,3-0.9,3.6c-0.6,0.6-1.9,0.9-3.8,0.9h-8V56.1L58.8,56.1z M106,78h5.8l-10-26h-8.4l-9.8,26h5.9
	l1.8-5h12.7L106,78L106,78z M92.7,69.4l5-13.5l5.1,13.5H92.7L92.7,69.4z M133.6,78c4.7,0,7.6-0.9,9-2.8c1.3-1.8,2-6,2-12.5
	c0-4-0.8-6.8-2.5-8.3c-1.7-1.6-4.5-2.4-8.7-2.4h-14.5v26H133.6L133.6,78z M124.5,56.1h8.3c2.6,0,4.3,0.5,5,1.6
	c0.7,1,1.1,3.5,1.1,7.3c0,3.7-0.4,6.1-1.2,7.2c-0.8,1.1-2.5,1.6-5.2,1.6h-8V56.1L124.5,56.1z M153.2,52v26h5.6V52H153.2L153.2,52z
	 M169.5,54.1c-1.5,1.6-2.3,5.5-2.3,11.7c0,5.2,0.8,8.6,2.4,10.1c1.6,1.5,5,2.3,10.4,2.3c5.9,0,9.7-0.8,11.2-2.3
	c1.5-1.5,2.3-5.3,2.3-11.3c0-5.4-0.8-9-2.3-10.5c-1.5-1.6-5-2.4-10.3-2.4C174.8,51.7,171,52.5,169.5,54.1L169.5,54.1z M186.8,57.2
	c0.7,0.8,1,3.4,1,7.7c0,4.3-0.3,6.9-1,7.8c-0.7,0.9-2.8,1.3-6.2,1.3c-3.6,0-5.8-0.4-6.6-1.1c-0.7-0.8-1.1-3-1.1-6.7c0-5,0.3-8,1-8.8
	c0.6-0.9,2.8-1.3,6.6-1.3C184,56,186.1,56.4,186.8,57.2L186.8,57.2z M201.8,52v26h19.4v-4.4h-13.8V52H201.8L201.8,52z M229.5,54.1
	c-1.5,1.6-2.3,5.5-2.3,11.7c0,5.2,0.8,8.6,2.4,10.1c1.6,1.5,5,2.3,10.4,2.3c5.9,0,9.7-0.8,11.2-2.3c1.5-1.5,2.3-5.3,2.3-11.3
	c0-5.4-0.8-9-2.3-10.5c-1.5-1.6-5-2.4-10.3-2.4C234.8,51.7,231.1,52.5,229.5,54.1L229.5,54.1z M246.9,57.2c0.7,0.8,1,3.4,1,7.7
	c0,4.3-0.3,6.9-1,7.8c-0.7,0.9-2.8,1.3-6.2,1.3c-3.6,0-5.8-0.4-6.6-1.1c-0.7-0.8-1.1-3-1.1-6.7c0-5,0.3-8,1-8.8
	c0.6-0.9,2.8-1.3,6.6-1.3C244.1,56,246.2,56.4,246.9,57.2L246.9,57.2z M273.5,67.8h7.4l0,1.1c0,2.2-0.4,3.6-1.2,4.2
	c-0.8,0.6-2.8,0.9-5.8,0.9c-3.1,0-5.1-0.4-6-1.1c-0.9-0.7-1.3-2.3-1.3-4.9l0-3.3l0-2.6c0-2.6,0.4-4.3,1.3-5c0.9-0.7,2.9-1.1,6.2-1.1
	c3,0,4.8,0.2,5.6,0.6c0.7,0.4,1.2,1.5,1.3,3.2h5.7c0-3.4-0.8-5.6-2.3-6.5c-1.5-1-4.9-1.5-10.2-1.5c-4,0-6.8,0.3-8.5,1
	c-1.7,0.6-2.9,1.9-3.7,3.7c-0.7,1.5-1,5.1-1,10.8c0,4.5,0.8,7.4,2.5,8.9c1.7,1.4,5,2.2,10.1,2.2c5.6,0,9.1-0.5,10.7-1.6
	c1.6-1.1,2.4-3.5,2.4-7.3l-0.1-5.2h-13V67.8L273.5,67.8z M312.9,52l-4.5,6.9c-0.3,0.5-0.7,1.2-1.3,2.1l-0.6,1l-0.6,1h-0.2l-0.6-1
	l-0.6-1c-0.6-1-1-1.7-1.3-2.1l-4.5-6.9h-6.5l10.6,16.1V78h5.6v-9.9L319.5,52H312.9L312.9,52z"
    />
    <path
      className="st1"
      d="M0,0v5h16.5v0.2L-0.8,26.4v5.1h25.2v-5H6.8v-0.2L24.2,5V0H0L0,0z M64.8,5.3l-3.9,14.4c-0.3,1-0.6,2.3-0.9,3.8
	l-0.4,1.9l-0.4,1.9H59l-0.5-1.9l-0.4-1.9c-0.3-1.3-0.6-2.6-1-3.8L53.2,5.3h-5.8l-4,14.4c-0.4,1.5-0.8,2.8-1,3.8l-0.5,1.9
	c-0.1,0.4-0.3,1.1-0.5,1.9h-0.2l-0.4-1.9l-0.4-1.9c-0.3-1.6-0.6-2.9-0.9-3.8L35.8,5.3h-5.1l6.8,26.2h7.3l3.7-13.4
	c0.3-1.1,0.6-2.2,0.9-3.3l0.4-1.6l0.4-1.6h0.2l0.4,1.6c0.4,2,0.8,3.7,1.2,4.9l3.6,13.4h7.4l7-26.2H64.8L64.8,5.3z M94.1,31.5h5.2
	L90.4,5.3h-7.5l-8.7,26.2h5.3l1.6-5h11.3L94.1,31.5L94.1,31.5z M82.2,22.8l4.5-13.7l4.5,13.7H82.2L82.2,22.8z M125.2,5.3v15.1l0,3.4
	l0.1,1.7l0.1,1.7h-0.2l-1-2.2l-1-2.1c-0.8-1.7-1.5-3.1-2.1-4.3l-6.8-13.3h-8.4v26.2h5v-15l0-3.4l-0.1-1.7l-0.1-1.7h0.2l0.8,1.9
	l0.8,1.9c0.8,1.8,1.4,3,1.7,3.8l7.5,14.4h8.4V5.3H125.2L125.2,5.3z M149.4,21.3h6.6l0,1.1c0,2.2-0.4,3.7-1.1,4.3
	c-0.7,0.6-2.5,0.9-5.2,0.9c-2.8,0-4.6-0.4-5.3-1.1c-0.8-0.7-1.2-2.4-1.2-5l0-3.4l0-2.6c0-2.6,0.4-4.3,1.2-5c0.8-0.7,2.6-1.1,5.5-1.1
	c2.7,0,4.3,0.2,5,0.6c0.7,0.4,1,1.5,1.1,3.2h5c0-3.4-0.7-5.6-2-6.6c-1.4-1-4.4-1.5-9-1.5c-3.6,0-6.1,0.3-7.6,1
	c-1.5,0.6-2.6,1.9-3.3,3.7c-0.6,1.5-0.9,5.1-0.9,10.9c0,4.5,0.7,7.5,2.2,8.9c1.5,1.5,4.5,2.2,9,2.2c4.9,0,8.1-0.5,9.6-1.6
	c1.4-1.1,2.1-3.5,2.1-7.3l-0.1-5.2h-11.5V21.3L149.4,21.3z M186.3,9.5V5.3h-17.9v26.2h18.1v-4.2h-13.1v-7.4h12.3v-3.7h-12.3V9.5
	H186.3L186.3,9.5z M199.4,31.5v-8.8h7.2c2.7,0,4,1.4,4,4.1v4.8h5v-5.8c0-3.2-1.5-4.9-4.4-5.1v-0.2c1.9-0.4,3.1-1,3.7-2
	c0.6-1,0.9-2.8,0.9-5.4c0-2.9-0.6-4.9-1.8-6c-1.2-1.1-3.4-1.7-6.6-1.7h-12.9v26.2H199.4L199.4,31.5z M199.4,9.5h7.5
	c1.6,0,2.7,0.3,3.2,0.8c0.5,0.5,0.7,1.8,0.7,3.6c0,1.8-0.3,3-0.8,3.7c-0.6,0.6-1.7,0.9-3.4,0.9h-7.1V9.5L199.4,9.5z M221.8,23.5h8.8
	v-4.2h-8.8V23.5L221.8,23.5z M243.6,31.5V21.4h7.1l1.4,0c4.3,0,7.2-0.7,8.6-2.1c1.4-1.4,2.1-4.2,2.1-8.4c0-4.3-0.7-7.1-2.2-8.6
	c-1.5-1.5-4.4-2.2-8.6-2.2h-1.6h-12.7v31.5H243.6L243.6,31.5z M243.6,5h7.4c2.6,0,4.1,0.3,4.8,1c0.6,0.6,0.9,2.3,0.9,5
	c0,2.3-0.4,3.8-1.1,4.4c-0.7,0.6-2.5,0.9-5.2,0.9h-6.8V5L243.6,5z M288.8,9.5V5.3h-17.9v26.2h18.1v-4.2h-13.1v-7.4h12.3v-3.7h-12.3
	V9.5H288.8L288.8,9.5z M316.4,12c0-2.7-0.7-4.5-2.1-5.5c-1.4-0.9-4-1.4-8-1.4c-4.2,0-6.9,0.5-8.3,1.5c-1.4,1-2.1,3.1-2.1,6.1
	c0,3,0.6,4.9,1.7,5.9c1.2,0.9,3.8,1.5,8,1.7l2.7,0.2c1.5,0.1,2.6,0.3,3,0.8c0.5,0.4,0.7,1.3,0.7,2.5c0,1.7-0.3,2.8-0.9,3.3
	c-0.6,0.5-2,0.7-4.3,0.7c-2.9,0-4.6-0.2-5.2-0.6c-0.6-0.4-1-1.5-1-3.4l0-0.6h-4.8l0,1c0,3,0.7,5.1,2,6.1c1.4,1,4.1,1.5,8.2,1.5
	c4.6,0,7.5-0.5,8.9-1.5c1.3-1,2-3.3,2-6.7c0-2.8-0.6-4.7-1.7-5.6c-1.1-0.9-3.7-1.5-7.6-1.7c-3.3-0.2-5.2-0.4-5.8-0.8
	c-0.6-0.3-0.9-1.3-0.9-3c0-1.4,0.4-2.3,1.1-2.7c0.7-0.4,2.3-0.7,4.6-0.7c2,0,3.3,0.2,3.8,0.6c0.5,0.4,0.9,1.3,1,2.8
	c0,0.1,0,0.3,0,0.5h4.9V12L316.4,12z M324.8,5.3v26.2h5V5.3H324.8L324.8,5.3z M343.7,31.5v-8.8h7.2c2.7,0,4,1.4,4,4.1v4.8h5v-5.8
	c0-3.2-1.5-4.9-4.4-5.1v-0.2c1.9-0.4,3.1-1,3.7-2c0.6-1,0.9-2.8,0.9-5.4c0-2.9-0.6-4.9-1.8-6c-1.2-1.1-3.4-1.7-6.6-1.7h-12.9v26.2
	H343.7L343.7,31.5z M343.7,9.5h7.5c1.6,0,2.7,0.3,3.2,0.8c0.5,0.5,0.7,1.8,0.7,3.6c0,1.8-0.3,3-0.8,3.7c-0.6,0.6-1.7,0.9-3.4,0.9
	h-7.1V9.5L343.7,9.5z M368.4,5.3v26.2h5V5.3H368.4L368.4,5.3z"
    />
  </svg>
);

export default logo;
