import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { SchedulingUIWrapper } from '../common';
import SchedulingLeftPanel from './SchedulingLeftPanel';
import SchedulingRightPanel from './SchedulingRightPanel';
import { setPlanId } from '../../../actions/checkout';

const Scheduling = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPlanId(Number(id)));
  }, [dispatch, id]);

  return (
    <SchedulingUIWrapper>
      <SchedulingLeftPanel />
      <SchedulingRightPanel />
    </SchedulingUIWrapper>
  );
};

export default Scheduling;
