import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Section, VFlex } from "./Common";
import HelloSign from "hellosign-embedded";
import styled from "styled-components";
import { useEffect } from "react";
import {
  getSignUrl,
  getPrescription,
} from "../../../../src/actions/patientProfile";
import CONSTANTS from "../../../utils/constants";
import { getHelloSignKey } from '../../../utils/keys';
import API from "../../../libs/api";
import PDFViewer from "./PDFViewer";
import { CheckoutNavigationButton } from "../../../components/Scheduling/common";
import { ReactComponent as IconArrowLeft } from "../../../assets/arrow_left.svg";

const Container = styled.div`
  width: 100%;
  min-height: 600px;
  padding-top: 10px;
  ${(props) => props.hideContainer && "display: none"};
`;

const StyledCheckoutNavigationButton = styled(CheckoutNavigationButton)`
  align-self: baseline;
`;

const StyledSection = styled(Section)`
  height: 600px;
  display: block;
  overflow: auto;
  .x-hellosign-embedded {
    height: 550px;
  }
`;

const HELLO_SIGN_CLIENT_ID = getHelloSignKey();

const PrescriptionsPDF = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const [pdf, setPdf] = useState(null);

  const client = new HelloSign({
    clientId: HELLO_SIGN_CLIENT_ID,
  });

  const containerRef = useRef();

  const signUrl = useSelector((state) => state.patientProfile.signUrl?.data);

  const prescription = useSelector(
    (state) => state.patientProfile.prescription
  );

  useEffect(() => {
    if (prescription && !isPrescriptionSigned())
      dispatch(getSignUrl(prescription?.id));
    if (prescription && isPrescriptionSigned()) {
      containerRef.current.innerHTML = null;

      API.getRequest(
        `/api/v1/prescription/${prescription?.id}/get_signed_file/`,
        {
          responseType: "blob",
        }
      ).then((response) => {
        setPdf(response.data);
      });
    }
  }, [prescription]);

  const isPrescriptionSigned = () =>
    prescription?.doctor_sign_status === "signed";

  const onReturnToProfile = () => dispatch(getPrescription(null));

  useEffect(() => {
    if (signUrl && !isPrescriptionSigned()) {
      setErrorMessage(null);
      containerRef.current.innerHTML = null;
      client.close();
      client.open(signUrl, {
        //testMode: true
        skipDomainVerification: true,
        allowCancel: true,
        container: containerRef.current,
      });
      client.on("sign", () => {
        client.close();
        window.history.go();
      });
      client.on("error", () => {
        setErrorMessage(
          "There was an issue loading your document, please try again later"
        );
      });
    } else {
      if (isPrescriptionSigned) {
        setErrorMessage(null);
      } else {
        setErrorMessage(
          "There was an issue loading your document, please try again later"
        );
      }
    }
  }, [signUrl]);
  return (
    <>
      <StyledCheckoutNavigationButton
        onClick={onReturnToProfile}
        type="button"
        previous
      >
        <IconArrowLeft style={{ marginRight: 5 }} />
        <span children="Back to patient profile" />
      </StyledCheckoutNavigationButton>
      <StyledSection title="Prescription">
        <VFlex
          height={"75%"}
          justifyContent={isPrescriptionSigned() ? "unset" : "center"}
          alignItems="center"
        >
          <Container
            ref={containerRef}
            hideContainer={errorMessage || isPrescriptionSigned()}
          />
          {isPrescriptionSigned() &&
            (pdf ? (
              <PDFViewer src={pdf} createLocalURL={true} />
            ) : (
              "Loading..."
            ))}
          {errorMessage}
        </VFlex>
      </StyledSection>
    </>
  );
};

export default PrescriptionsPDF;
