import React from 'react';

export default () => (
  <div>
    <p>
      <strong>
        THIS NOTICE DESCRIBES HOW WITHIN HEALTH ("WE") USE AND DISCLOSE MEDICAL
        INFORMATION ABOUT YOU THAT WE MAY RECEIVE FROM YOUR PHYSICIAN, OR FROM
        THE RADIOLOGISTS USING OUR PLATFORM. PLEASE REVIEW IT CAREFULLY BEFORE
        PROVIDING YOUR CONSENT BELOW.
      </strong>
    </p>
    <p>
      Our platform facilitates your communications with primary care physicians
      (PCP). The PCPs then can provide medical advice to you with respect to
      your securing a CT scan or X-Ray from one of the radiologists (RP) with
      whom we have contracted. We are not a medical care provider, and we do not
      serve as the provider of an Electronic Health Record (EHR) service. Either
      your PCP or your RP may make available to us the scans that are
      facilitated through our platform. The rights you grant to us below are in
      addition to any rights that may be set out in any Business Associate
      Agreement (BAA) that may be in effect between us and the PCP or RP.
    </p>
    <strong>How We Use or Disclose Medical Information For Operations.</strong>
    <p>
      We may use or disclose health information as necessary to operate and
      manage our business activities related to your use of our platform, and to
      improve our platform. As Required by Law. We may disclose information when
      required to do so by law.
    </p>
    <strong>To Create De-Identified Data.</strong>
    <p>
      We may also de-identify your health information. By{' '}
      <strong>"de-identify"</strong> we generally mean removing those data
      elements that directly identify you (name, file record, etc.), while
      retaining the scan image and generic information, such as (as
      non-exclusive exampes) the location where the image was taken, and the
      diagnosis of the underlying condition. After that information is so
      de-identified, it is no longer subject to this notice and we may
      perpetually use the de-identified data for any lawful purpose.
    </p>
  </div>
);
