import React from "react";
import { connect, useSelector } from "react-redux";
import Text from "../../components/_styles/Text";
import styled from "styled-components";
import {
  FormContainer,
  FormHeader,
  ModifiedFormRow,
  ModifiedFormItem,
} from "./common";
import { FormRow, FormItem, FormLabel } from "../../components/Design/form";

const ModifiedFormContainer = styled(FormContainer)`
  width: 100%;
  margin-top: 20px;
  height: 100%;
`;

function ShowMedicalHistoryInfo() {
  const { user } = useSelector((state) => state.auth);
  const { healthHistory, medicalConditions, lastAppointmentSymptoms } = user;

  return (
    <ModifiedFormContainer>
      <FormHeader>Medical History</FormHeader>
      <ModifiedFormRow>
        <ModifiedFormItem>
          <FormLabel>Date of Birth</FormLabel>
          <Text fontSize={1}>{healthHistory?.date_of_birth}</Text>
        </ModifiedFormItem>
        <ModifiedFormItem>
          <FormLabel>Gender</FormLabel>
          <Text fontSize={1}>
            {healthHistory?.gender === "M" ? "Male" : "Female"}
          </Text>
        </ModifiedFormItem>
      </ModifiedFormRow>

      <ModifiedFormRow>
        <ModifiedFormItem>
          <FormLabel>Medical Conditions</FormLabel>
          {medicalConditions?.map((mc) => (
            <Text fontSize={1} key={mc.id}>
              {mc.description}
            </Text>
          ))}
        </ModifiedFormItem>
        <ModifiedFormItem>
          <FormLabel>Experiencing Symptoms</FormLabel>
          {lastAppointmentSymptoms?.map((s) => (
            <Text fontSize={1} key={s.id}>
              {s.description}
            </Text>
          ))}
        </ModifiedFormItem>
      </ModifiedFormRow>

      <FormRow>
        <FormItem>
          <FormLabel> Metal in Body</FormLabel>
          <Text fontSize={1}>
            {healthHistory?.metal_present_in_body ? "Yes" : "No"}
          </Text>
        </FormItem>
      </FormRow>
    </ModifiedFormContainer>
  );
}
export default connect()(ShowMedicalHistoryInfo);
