import React from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, Field as ReduxField, Form as ReduxForm } from 'redux-form';
import styled from 'styled-components';

import Field from './Field';

const HGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  ${Field} {
    flex: 1;
  }

  ${Field}:first-child {
    margin-right: 20px;
  }
`;

const VGroup = styled.div`
  display: flex;
  flex-direction: column;

  ${Field} {
    margin-bottom: 20px;
  }

  ${Field}:last-child {
    margin-bottom: 0;
  }
`;

const BGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
`;

const Submit = styled.button.attrs({ type: 'submit' })`
  padding: 15px 30px;
  border: none;
  outline: none !important;
  border-radius: 5px;
  background: #00AEEF;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
`;

const Form = ({ className, onSubmit, handleSubmit }) => {
  const isSubmitDisabled = useSelector((state) => {
    const values = state.form['REGISTER_DOCTOR']?.values ?? {};
    return !(values.fname && values.lname && values.npi && values.phone);
  });

  return (
    <ReduxForm className={className} onSubmit={handleSubmit(onSubmit)}>
      <HGroup>
        <ReduxField
          component={Field}
          type='text'
          name='fname'
          label='First Name'
          placeholder='Enter your first name here'
        />
        <ReduxField
          component={Field}
          type='text'
          name='lname'
          label='Last Name'
          placeholder='Enter your last name here'
        />
      </HGroup>
      <VGroup>
        <ReduxField component={Field} type='number' name='npi' label='NPI #' placeholder='Enter your NPI # here' />

        <ReduxField component={Field} type='tel' name='phone' label='Phone Number' placeholder='+1-123-456-0164' />
      </VGroup>
      <BGroup>
        <Submit disabled={isSubmitDisabled} children='Continue' />
      </BGroup>
    </ReduxForm>
  );
};

export default reduxForm({ form: 'REGISTER_DOCTOR' })(styled(Form)``);
