import React, { useState, useEffect } from 'react';
import ColumnsMappingCard from './ColumnsMappingCard';
import Button from '../../../../components/UI/Button/Button';
import { uniq } from 'lodash';
import Flex from '../../../../components/_styles/Flex';
import styled from 'styled-components';
import { convertParsedData } from './Setup'

const Container = styled(Flex)`
  width: 100%;
  flex-direction: column;
`;

const Title = styled(Flex)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3F4857;
`;

const Text = styled(Flex)`
  width: 50%;
  font-size: 16px;
  line-height: 22px;
  color: #3F4857;
`;

const CardsContainer = styled(Flex)`
  flex-wrap: wrap;
  justify-content: center;
`;

export const Content = styled(Flex)`
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const CancelImport = styled(Button)`
  font-size: 14px;
  line-height: 18px;
  background: #FF5F65;
  border-radius: 5px;
  color: white;
  width: max-content;
  padding: 12px 16px;
  &:hover {
    color: white
  }
`;

export const NextButton = styled(Button)`
  width: fit-content
`;

export const ButtonsContainer = styled(Flex)`
  justify-content: space-between;
  align-items: flex-end;
`;

const CardsAndErrors = styled(Flex)`
  flex-direction: column;
`;

const Error = styled(Flex)`
  font-size: 16px;
  line-height: 22px;
  color: #F73B3B;
  margin-top: 15px;
`;

const ReservedAttributes = ({ userHeadersKeys, appHeaders, headersMapping, setHeadersMapping, cancelImportAll, moveToNextStep, files, cancelImportFile, setUserHeadersKeys, data, setData, setPreviewRows }) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    if (userHeadersKeys && appHeaders) {
      const initialMapping = userHeadersKeys.map((item) => item.reduce((acc, userHeader) => {
        if(typeof userHeader !== 'string') {
          return {...acc, fileName: userHeader.fileName};
        } else {
        const appHeader = appHeaders.find(appHeader => appHeader.key === userHeader);
        return  { ...acc, [userHeader]: appHeader ? appHeader.key : '' };
        }
      }, {}));
      setHeadersMapping(initialMapping);
    }
  }, [userHeadersKeys, appHeaders]);

  const setMapping = (userHeaderKey, appHeaderKey) => {
    // updating cards view
    let updatedHeadersMapping = headersMapping.map((item) => {
      if(item[userHeaderKey]){
       item[appHeaderKey] = item[userHeaderKey]
      }
      delete item[userHeaderKey]
      return item
    })
    let requiredObj = appHeaders.find(item => item.key === appHeaderKey)
    let updatedObj = updatedHeadersMapping[0]
    updatedObj = {
      ...updatedObj,
      [appHeaderKey]: requiredObj.key
    }
    updatedHeadersMapping[0] = updatedObj
    setHeadersMapping(updatedHeadersMapping);

    // updating the table view
    let headersRow = data[0].data[0]
    let indexToUpdate = headersRow.findIndex(x => x === userHeaderKey)
    headersRow[indexToUpdate] = appHeaderKey
    let updatedData = data
    updatedData[0].data[0] = headersRow
    setData(updatedData)
    const rows = convertParsedData(data);
    setPreviewRows(rows);
  }
  const skipUserHeader = (userHeaderKey, id = 0) => {
    const headersMappingCloneArr = [...headersMapping];
    delete headersMappingCloneArr[id][userHeaderKey];
    setHeadersMapping(headersMappingCloneArr);
  }

  const onCancelImportAll = () => {
    setError(null);
    cancelImportAll();
  }

  const onNextClick = () => {
    const usedAppHeaders = Object.keys(headersMapping[0]).filter(a => a);
    if (uniq(usedAppHeaders).length !== usedAppHeaders.length) {
      setError('Mapped duplicate columns');
      return;
    }
    const requiredAppHeadersKeys = appHeaders.filter(appHeader => appHeader.isRequired).map(appHeader => appHeader.key);
    const notMappedRequiredHeadersKeys = requiredAppHeadersKeys.reduce((acc, appHeader) => {
      if (!usedAppHeaders.includes(appHeader)) {
        return [...acc, appHeader];
      }
      return acc;
    }, []);
    if (notMappedRequiredHeadersKeys.length) {
      const notMappedRequiredHeadersNames = notMappedRequiredHeadersKeys.map(appHeaderKey => {
        const appHeader = appHeaders.find(a => a.key === appHeaderKey);
        return appHeader && appHeader.name;
      });
      setError(`Not all required columns are mapped: ${notMappedRequiredHeadersNames.join(', ')}`);
      return;
    }
    moveToNextStep();
  }
  if (!headersMapping) return null;
  return (

    <div>
    {
      headersMapping && Object.values(headersMapping).map((item, outerIdx) => {
        const {fileName, ...headersForPrint} = item;
       return (   
          <Container style={{marginBottom: '20px'}}>
            <Title>Reserved Attributes</Title>
            <Text>These are the attributes that we pulled from your {fileName} file. Please check to ensure we mapped your columns correctly.</Text>
            <CancelImport danger block onClick={() => cancelImportFile(fileName)}>Cancel Import File </CancelImport>
            <Content>
              <CardsAndErrors>
                <CardsContainer>
                  { Object.keys(headersForPrint).map(userHeaderKey => (
                   <ColumnsMappingCard
                     userHeaderKey={userHeaderKey}
                     mappedAppHeaderKey={item[userHeaderKey]}
                     appHeaders={appHeaders}
                     setMapping={setMapping}
                     skipUserHeader={skipUserHeader}
                     idx={outerIdx}
                   />
                  ))}
                </CardsContainer>
                <Error>{error}</Error>
              </CardsAndErrors>
            </Content>
          </Container>
      )})}
          <ButtonsContainer>
            <CancelImport danger block onClick={onCancelImportAll}>Cancel Import All</CancelImport>
            <NextButton primary block onClick={onNextClick}>Next</NextButton>
          </ButtonsContainer>
    </div>
  )
};

export default ReservedAttributes;
