import styled from 'styled-components';
import { flexbox, space } from 'styled-system';
import Box from './Box';

const Flex = styled(Box)`
  margin: ${(props) => props.margin };
  display: flex;
  ${flexbox}
`;

Flex.Col = styled(Box)`
  ${props => props.isFlex && 'display: flex'}
  ${flexbox}
  ${space}
`;

export default Flex;
