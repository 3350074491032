import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import Validator from 'email-validator';

import Modal from './Modal';
import { SubmitButton } from './common';
import FormGroup from './common/FormGroup';
import { Form, FormRow } from '../Design/form';
import { DisclaimerHolder, NoAccountText, DisclaimerText } from './styles';
import * as authActions from '../../actions/auth/actions';

function CreateAccountForm({ onSubmit, handleSubmit, signup, formErrors, formValues, pristine, history, apiErrors }) {
  const [checked, toggleChecked] = useState(false);
  const [modal, setModal] = useState(false);
  const [currentModal, setCurrentModal] = useState('');

  const isButtonDisabled = () => {
    const hasPassword = !pristine && (formValues.password || formValues.confirmPassword);

    if (formErrors || !checked || !hasPassword || pristine) {
      return true;
    }

    return false;
  };

  const onFormSubmit = ({ email, password }) => {
    signup({ email, password, history });
  };

  const openModal = (e) => {
    e.preventDefault();

    setModal(true);
    setCurrentModal(e.target.id);
  };

  const closeModal = () => {
    setModal(false);
    setCurrentModal('');
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit ?? onFormSubmit)}>
      <FormRow>
        <Field
          component={FormGroup}
          type='text'
          label='Your email'
          name='email'
          placeholder='Enter your email here'
          {...{ apiErrors }}
        />
      </FormRow>
      <FormRow>
        <Field
          component={FormGroup}
          type='password'
          label='Your password'
          name='password'
          placeholder='Enter your password here'
          {...{ apiErrors }}
        />
      </FormRow>
      <FormRow>
        <Field
          component={FormGroup}
          type='password'
          label='Confirm your password'
          name='confirmPassword'
          placeholder='Confirm password here'
        />
      </FormRow>
      <FormRow>
        <DisclaimerHolder>
          <input
            id='disclaimer-checkbox'
            name='disclaimer-checkbox'
            type='checkbox'
            checked={checked}
            onChange={() => (checked ? toggleChecked(false) : toggleChecked(true))}
          />
          <DisclaimerText htmlFor='disclaimer-checkbox'>
            I understand and agree to Within Health’s{' '}
            <a href='' id='terms-and-conditions' onClick={openModal}>
              Terms of Service
            </a>
            ,{' '}
            <a href='' id='privacy-policy' onClick={openModal}>
              Privacy Policy
            </a>
            , and{' '}
            <a href='' id='third-party-research-consent' onClick={openModal}>
              Third Party Research Consent
            </a>
          </DisclaimerText>
        </DisclaimerHolder>
      </FormRow>
      <FormRow>
        <SubmitButton type='submit' disabled={isButtonDisabled()}>
          Create new account
        </SubmitButton>
      </FormRow>
      <NoAccountText>
        Already have an account? <Link to='/login'>Login</Link>
      </NoAccountText>

      <Modal isOpen={modal} closeModal={closeModal} currentModal={currentModal} />
    </Form>
  );
}

function validate(formProps) {
  const { email, password, confirmPassword } = formProps;
  const errors = {};

  if (!email) {
    errors.email = 'Email required';
  }

  if (!Validator.validate(email)) {
    errors.email = 'Invalid email';
  }

  if (password !== confirmPassword) {
    errors.confirmPassword = "Passwords don't match";
  }

  return errors;
}

const form = reduxForm({
  form: 'createAccount',
  fields: ['email', 'password', 'confirmPassword'],
  validate
})(CreateAccountForm);

function mapStateToProps({ form, apiErrors }) {
  return {
    formValues: form.createAccount ? form.createAccount.values : {},
    formErrors: form.createAccount ? form.createAccount.syncErrors : {},
    pristine: form.createAccount && !form.createAccount.anyTouched,
    apiErrors
  };
}

export default withRouter(connect(mapStateToProps, { ...authActions })(form));
