import React from 'react';
import { AccountContentContainer, SubmitButton } from './common';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { SetupVerificationDevice } from '../../actions/auth'
import { Heading, Text, VerificationContainer } from './MultiFactorAuth';
import { reduxForm, Field } from 'redux-form';
import { Alert } from 'react-bootstrap';
import FormGroup from './common/FormGroup';
import { Form, FormRow } from '../Design/form';

function VerificationForDevice() {
    const dispatch = useDispatch();
    const history = useHistory();

    const [error, setError] = React.useState('')

    const submitHandle = (e) => {
        e.preventDefault()
        let phoneNumber = e.target[0].value
        if (!phoneNumber) return
        dispatch(SetupVerificationDevice(phoneNumber))
        .then(() => {
                 history.push('/multi-factor-authentication')
                 setError('')
        })
       .catch(() => {
            setError('Please check your phone number and try again!')
       })
    }

    return (
        <AccountContentContainer>
            <Heading>Set up your Verification Device</Heading>
            <Text>Thanks for logging into Within Health. Your organization has Multi-factor authentication set up. Please enter an SMS friendly phone number below to add as your verifcation device.</Text>
            {error && <Alert variant='danger'>{error}</Alert>}
            <VerificationContainer>
                <Form onSubmit={submitHandle}>
                    <FormRow>
                        <Field
                            component={FormGroup}
                            type='number'
                            name='phoneNumber'
                            placeholder='Please enter your phone number'
                        />
                    </FormRow>
                    <FormRow style={{ marginTop: 60 }}>
                        <SubmitButton type="submit">Verify</SubmitButton>
                    </FormRow>
                </Form>
            </VerificationContainer>
        </AccountContentContainer>
    )
}

const validate = ({ phoneNumber }) => {
    const errors = {};

    if (!phoneNumber) {
        errors.phoneNumber = 'Phone number is required.';
    }

    return errors;
};

export default reduxForm({ form: 'MFA_FORM', validate })(VerificationForDevice)
