import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

import { ReactComponent as RemoveRecoIcon } from "../../../assets/FollowUpDeleteIcon.svg";
import { ReactComponent as RemoveRecoIconHover } from "../../../assets/delete-hover.svg";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import RemoveConfirmation from "../Cohorts/RemoveConfirmation";
import { Spinner } from "react-bootstrap";
import {
  getApiGenerationToken,
  deleteApiGenerationToken,
} from "../../../actions/radminApiTokenGeneration";
import ApiTokenGenerationModal from "./ApiTokenGenerationModal";
import moment from "moment";
import { ReactComponent as EditRecoIcon } from "../../../assets/editRecoIcon.svg";
import { ReactComponent as EditRecoIconHover } from "../../../assets/edit-icon-hover.svg";
import { showAlert } from "../../../actions/radminUploads";
import { ReactComponent as DropdownUp } from "../../../assets/dropdown-outline-white-up.svg";
import { ReactComponent as DropdownDown } from "../../../assets/dropdown-outline-white.svg";
import Popover from "@material-ui/core/Popover";
import CONSTANTS from "../../../utils/constants";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const isDev =
  process.env.NODE_ENV === CONSTANTS.ENV.DEVELOPMENT ||
  window.location.host === CONSTANTS.ENV.STAGING_DEV;


const Container = styled.div`
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  background: white;
  width: 1024px;
  min-height: 145px;
`;

const HeaderLabel = styled.h2`
  font-size: 28px;
  font-weight: 700;
  color: #0c2945;
  font-family: Roboto Condensed;
`;

const TableHeaderLabel = styled.th`
  font-size: 12px;
  font-weight: 600;
  color: #9fa2b4;
`;

const IconContainer = styled.span`
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 20px;

  &:hover svg:not(.light) {
    display: none;
  }

  &:hover svg.light {
    display: block;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  svg.light {
    display: none;
  }
`;

const Table = styled.table`
  & tr {
    border-bottom: none;
  }
  & td,
  th {
    padding: 18px 30px !important;
  }
  & td {
    color: #252733;
    font-weight: 400;
    font-size: 14px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const CreateTokenButton = styled.button`
  border-radius: 5px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  border: 2px solid #00aeef;
  background-color: #00aeef;
  color: #ffffff;
  width: 169px;
  height: 38px;
  &:hover {
    background: transparent;
    color: #00aeef;
  }
`;

const CopyLinkButton = styled.div`
  border: 1px solid #c2ccd9;
  border-radius: 4px;
  height: 38px;
  padding: 10px;
  width: 200px;
  font-size: 14px;
  font-weight: 400px;
  position: relative;
`;

const CopyLabel = styled.span`
  position: absolute;
  color: #00aeef;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const TokenInput = styled.input`
  width: 70%;
  border: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const ExportButton = styled.button`
  height: 38px;
  border-radius: 5px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  border: 0;
  background-color: ${(props) => props.bg || "#00AEEF"};
  color: #ffffff;
`;

const useStyles = makeStyles({
  label: {
    padding: "5px 0px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  saveButton: {
    backgroundColor: "#00AEEF",
    textTransform: "none",
    width: "104px",
    height: "38px",
    color: "#FFFFFF",
    border: "2px solid #00AEEF",
    marginRight: "15px",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#00AEEF",
    },
  },
  downloadButton: {
    backgroundColor: "transparent",
    textTransform: "none",
    width: "233px",
    height: "38px",
    color: "#00AEEF",
    border: "2px solid #00AEEF",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#00AEEF",
    },
    "& a": {
      color: "#00AEEF",
      textDecoration: "none",
    },
  },
});

const ApiTokenGeneration = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({
    visibility: false,
    data: {},
  });

  const [selectedId, setSelectedId] = useState({ id: "" });
  const [isLoadingDeleteApiToken, setIsLoadingDeleteApiToken] = useState(false);
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleClickSwaggerOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSwaggerOptions = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "bulk-actions-popover" : undefined;

  const apiTokenGeneration = useSelector(
    (state) => state.radAdmin.apiTokenGeneration
  );

  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);
  const changePermission = rolePermissions
    ?.find((itm) => itm.name === "Settings")
    ?.permissions.find(
      (itm) => itm.codename === "change_settings"
    )?.is_authorized;
  const deletePermission = rolePermissions
    ?.find((itm) => itm.name === "Settings")
    ?.permissions.find(
      (itm) => itm.codename === "delete_settings"
    )?.is_authorized;
  const addPermission = rolePermissions
    ?.find((itm) => itm.name === "Settings")
    ?.permissions.find((itm) => itm.codename === "add_settings")?.is_authorized;

  useEffect(() => {
    dispatch(getApiGenerationToken());
  }, []);

  useEffect(() => {
    if (apiTokenGeneration?.data) {
      setRows(apiTokenGeneration.data);
    }
  }, [apiTokenGeneration]);

  const deleteApiTokenHandler = () => {
    if (selectedId.id) {
      setIsLoadingDeleteApiToken(true);

      dispatch(deleteApiGenerationToken(selectedId.id)).then(() => {
        setIsLoadingDeleteApiToken(false);
        setSelectedId({ id: "" });
        setRemoveDialogOpen(false);
      });
    }
  };

  const dialogHandler = (id) => {
    if (id) {
      setRemoveDialogOpen(!removeDialogOpen);
      setSelectedId({ id });
    }

    window.analytics.track("Generate-Token-Api-Delete-Button-Click");
  };

  const downloadSampleCollection = () => {
    fetch('/postman_collection.json')
      .then((response) => response.json())
      .then((postmanCollection) => {
        const blob = new Blob([JSON.stringify(postmanCollection, null, 2)], { type: 'application/json' });
        
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'postman_collection.json');

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error fetching the Postman collection:', error);
      });
  };

  return (
    <>
      <Row style={{ marginBottom: "40px" }}>
        <HeaderLabel> API Token Generation</HeaderLabel>
        <Row style={{gap:'10px'}}>
          <ExportButton
            style={{
              background: "#00AEEFs",
              color: "white",
              border: "2px solid #00AEEF",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "center",
              padding:'0px 10px',
              cursor:'pointer'
            }}
            type={"button"}
            onClick={handleClickSwaggerOptions}
          >
            Api Docs {open ? <DropdownUp /> : <DropdownDown />}
          </ExportButton>
          <CreateTokenButton
            onClick={() => {
              if ((permission && addPermission) || !permission) {
                setSelectedData({ visibility: true, data: {} });
              } else {
                dispatch(
                  showAlert(
                    "danger",
                    "Error",
                    `You don't have permission to create token`
                  )
                );
              }
            }}
          >
            Create New Token
          </CreateTokenButton>
        </Row>
      </Row>
      <Row>
        <Container>
          <RemoveConfirmation
            content="Are you sure you want to delete this token?"
            openDialog={removeDialogOpen}
            dialogHandler={dialogHandler}
            isLoading={isLoadingDeleteApiToken}
            deleteHandler={deleteApiTokenHandler}
            heading={"Delete Token"}
            removeHeader={"Yes, Delete Token"}
          />

          {apiTokenGeneration?.isLoading && !apiTokenGeneration.data ? (
            <LoadingContainer>
              <Spinner animation="border" role="status" size="sm" />
            </LoadingContainer>
          ) : (
            <>
              <Table>
                <thead style={{ borderBottom: "1px solid #DFE0EB" }}>
                  <tr>
                    <TableHeaderLabel>Token</TableHeaderLabel>
                    <TableHeaderLabel>Token Expiration Date</TableHeaderLabel>
                    <TableHeaderLabel>Token Last Used</TableHeaderLabel>
                    <TableHeaderLabel>Token Name</TableHeaderLabel>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row) => (
                    <tr key={row.id}>
                      <td>
                        <CopyLinkButton>
                          <TokenInput readOnly={true} value={row.token} />

                          <CopyLabel
                            onClick={() => {
                              navigator.clipboard.writeText(row.token);
                              dispatch(
                                showAlert(
                                  "success",
                                  "Success",
                                  "API token copied successfully!"
                                )
                              );
                            }}
                          >
                            Copy
                          </CopyLabel>
                        </CopyLinkButton>
                      </td>
                      <td
                        style={{
                          color: moment.utc(row.expire_at).isBefore(moment())
                            ? "#CF3131"
                            : "initial",
                        }}
                      >
                        {moment.utc(row.expire_at).format("YYYY-MM-DD")}
                      </td>
                      <td>{row.last_activity? moment.utc(row.last_activity).format("YYYY-MM-DD"): 'Never Used'}</td>
                      <td>{row.name}</td>
                      <td>
                        <IconContainer
                          onClick={() => {
                            if (
                              (permission && changePermission) ||
                              !permission
                            ) {
                              setSelectedData({
                                visibility: true,
                                data: row,
                              });
                            } else {
                              dispatch(
                                showAlert(
                                  "danger",
                                  "Error",
                                  `You don't have permission to update token details`
                                )
                              );
                            }
                          }}
                          style={{ marginRight: "16px" }}
                        >
                          <EditRecoIcon className="dark" />
                          <EditRecoIconHover className="light" />
                        </IconContainer>
                        <IconContainer
                          onClick={() => {
                            if (
                              (permission && deletePermission) ||
                              !permission
                            ) {
                              dialogHandler(row.id);
                            } else {
                              dispatch(
                                showAlert(
                                  "danger",
                                  "Error",
                                  `You don't have permission to delete token`
                                )
                              );
                            }
                          }}
                        >
                          <RemoveRecoIcon />
                          <RemoveRecoIconHover className="light" />
                        </IconContainer>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Container>
        {selectedData?.visibility && (
          <ApiTokenGenerationModal
            data={selectedData.data}
            showModal={selectedData.visibility}
            closeModal={() => setSelectedData({ visibility: false, data: {} })}
          />
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleSwaggerOptions}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: -10,
            horizontal: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "10px",
            }}
          >
              <Link style={{color:'white'}} to={{ pathname: isDev?`${CONSTANTS.API.STAGING}/docs/`:`${CONSTANTS.API.PRODUCTION}/docs/`}} target="_blank" >
              <ExportButton style={{width:'100%'}} type={"button"} bg={"#00AEEF"}>
                Swagger
               </ExportButton>
              </Link>
            <ExportButton type={"button"} bg={"#00AEEF"} onClick={downloadSampleCollection}>
               Postman Collection
            </ExportButton>
          </div>
        </Popover>
      </Row>
    </>
  );
};

export default ApiTokenGeneration;
