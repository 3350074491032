import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Nav, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import {
  SET_DOCTOR_SEARCH_VALUE,
  SET_RADIOLOGIST_SEARCH_VALUE,
  showAlert,
} from "../../../actions/radminUploads";
import {
  getDoctorsListAction,
  getRadiologistList,
  setDoctorForPatientsAction,
  getExportReportAction,
} from "../../../actions/radminUploads";
import {
  RefferedPatientsIcon,
  ImagingFollowUpsIcon,
  StarBlueIcon,
  TimerBlueIcon,
  CheckBoxBlueIcon,
  LikeBlueIcon,
} from "./Icons";
import { ClipboardIcon } from "../../../pages/RadiologySearchPage/Icons";
import TopDoctorCard from "./TopDoctorCard";
import TopLeaderBoardCard from "./TopLeaderboardCard";
import DoubleDataCell from "../PatientList/LastScanCell";
import Box from "../../../components/_styles/Box";
import Flex from "../../../components/_styles/Flex";
import Table, {
  AvatarContainer,
  UserAvatar,
  UserEmptyAvatar,
} from "../../../components/Table/Table";
import EnableFeature from "../../../components/EnableFeature/EnableFeature";
import { EmptyState } from "../../../components/EmptyState/EmptyState";
import LoadMoreButton from "../../../components/LoadMoreButton";
import SearchInput from "../../../components/SearchInput";
import { ReactComponent as Search } from "../../../assets/search.svg";
import { ReactComponent as EmptyStateImg } from "../../../assets/empty-state-search.svg";
import { ReactComponent as ChevronRightIcon } from "../../../assets/chevron_right.svg";
import { Card } from "../../../styles";
import ExportModal from "../DoctorList/ExportModal";
import DatePicker from "react-datepicker";
import moment from "moment";
import { ReactComponent as RemoveRecoIcon } from "../../../assets/close-outline.svg";
import { isArray } from "lodash";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar-outline.svg";
import { useLocation } from "react-router";

const Badge = styled(Flex)`
  width: fit-content;
  min-width: 54px;
  padding: 0 5px;
  height: 24px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 100px;
  color: white;
  background: ${(props) => props.bcolor};
`;

const StyledChevron = styled(ChevronRightIcon)`
  width: 26px;
  height: 26px;
  cursor: pointer;
  & > path {
    fill: #9fa2b4;
  }
  &:hover > path {
    fill: #3f4857;
    transition: all 0.3s ease;
  }
`;

const StyledLink = styled(Nav.Link)`
  padding: 0;
`;

const HeaderContainer = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-right: 0;
  }
`;

const SwitchContainer = styled.div`
  padding: 4px;
  display: flex;
  background: #e6e9f1;
  border-radius: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0c2945;
  width: max-content;
`;

const SwitchButton = styled.button`
  padding: 7px 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.active ? "#0C2945" : "#E6E9F1")};
  color: ${(props) => (props.active ? "#F9FAFC" : "#0C2945")};
  border: 0;
  outline: 0;
  border-radius: 6px;
  font-weight: 600;
`;

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
`;
export const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  margin-right: 10px;
`;
const FormContainer = styled(Flex)`
  justify-content: space-between;
`;
const InputContainer = styled.div`
  width: 450px;
  margin-bottom: 10px;
  // margin-right: 50px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    width: 350px;
  }
  @media (max-width: 957px) {
    margin-top: 5px;
  }
`;

const CustomPlaceholder = styled.span`
  position: absolute;
  margin-left: 100px;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  color: #000000;
  @media (max-width: 768px) {
    margin-left: 60px;
  }
`;
// const Input = styled.input`
//   width: 100%;
//   border-radius: 15px;
//   border: 1px dashed gray;
//   height: 50px;
//   text-align: center;
// `

const Input = styled.input`
  width: 100%;
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 5px;
  height: 33px;
  text-align: center;
  font: normal 16px "Open Sans", sans-serif;
`;
// const ClearButton = styled.button`
//   position: absolute;
//   margin-top: 2px;
//   margin-left: -40px;
//   border-radius: 5%;
//   z-index: 1;
//   border: none;
//   cursor: pointer;
//   color: white;
//   background-color: red;
//   transform: translateX(2px);
//   @media (max-width: 768px) {
//     margin-left: -30px;
//   }
// `
const ClearButton = styled.button`
  position: absolute;
  margin-left: 410px;
  border-radius: 5%;
  z-index: 1;
  border: none;
  cursor: pointer;
  color: #000000;
  background-color: transparent;
  transform: translateX(2px);
  @media (max-width: 768px) {
    margin-left: -30px;
  }
`;

const SearchIcon = styled.i`
  display: inline-block;
  position: absolute;
  margin-left: 50px;
  color: green;
  min-width: 45px;
  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;

const RefPhysicianName = styled.span``;

const TableItemNum = styled.span`
  margin-right: 30px;
  font-weight: 600;
  font-size: 16px;
  color: #9fa2b4;
`;

const ColumnNameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ColumnSortButton = styled(ChevronRightIcon)`
  transform: rotate(${(props) => props.sorted});
  width: 20px;
  height: 20px;
  cursor: pointer;
  & > path {
    fill: #9fa2b4;
  }
  &:hover > path {
    fill: #3f4857;
    transition: all 0.3s ease;
  }
`;

const ExportContainer = styled.button`
  border: 0px;
  float: right;
  margin-top: -10px;
  background-color: #00aeef;
  border-radius: 5px;
`;

const ExportButton = styled.button`
  padding: 12px 10px;
  background-color: #00aeef;
  font-family: Roboto Condensed;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  border: 0;
  float: right;
  min-width: max-content;
  background-color: #00aeef;
  color: #ffffff;
  @media (max-width: 947px) {
    margin-top: 5px;
  }
`;

const radiologistColumns = [
  {
    key: "name",
    name: "Radiologist Name",
    displayCell: (cellValue, row, idx) => (
      <UserDataBlock name={cellValue} num={idx+1} index={idx} row={row} />
    ),
  },
  {
    key: "num_reports",
    name: "# of Reports",
    displayCell: (cellValue) => (
      <ItemWrapper>
        <IconContainer>
          <ClipboardIcon />
        </IconContainer>
        <DoubleDataCell mainInfo={cellValue} />
      </ItemWrapper>
    ),
  },
  {
    key: "numberOfRecos",
    name: "# of imaging Recos",
    displayCell: (cellValue) => (
      <ItemWrapper>
        <IconContainer>
          <LikeBlueIcon />
        </IconContainer>
        <DoubleDataCell mainInfo={cellValue} />
      </ItemWrapper>
    ),
  },
  {
    key: "closedRecos",
    name: "# of imaging Recos Closed",
    displayCell: (cellValue) => (
      <ItemWrapper>
        <IconContainer>
          <CheckBoxBlueIcon />
        </IconContainer>
        <DoubleDataCell mainInfo={cellValue} />
      </ItemWrapper>
    ),
  },
  {
    key: "numberOfDetailedRecos",
    name: "# of imaging Recos (with Time.Modality.Anatomy)",
    displayCell: (cellValue, row) => (
      <ItemWrapper>
        <IconContainer>
          <TimerBlueIcon />
        </IconContainer>{" "}
        <DoubleDataCell mainInfo={cellValue} />
      </ItemWrapper>
    ),
  },
  {
    key: "highQuality",
    name: "High Quality Recomendations (%)",
    displayCell: (cellValue, row) => (
      <ItemWrapper>
        <IconContainer>
          <StarBlueIcon />
        </IconContainer>{" "}
        <DoubleDataCell mainInfo={cellValue} />
      </ItemWrapper>
    ),
  },
];

const backGroundColors = ["red", "yellow", "orange", "pink", "cyan"];

export const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 8px;
  font-size: 18px;
  width: max-content;
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
`;

const RecoBadge = ({ patientsCount, overduePatientsCount, bcolor }) => (
  <Badge
    percents={(overduePatientsCount / patientsCount) * 100}
    bcolor={bcolor}
  >
    {overduePatientsCount}
  </Badge>
);

const UserDataBlock = ({ row, name, avatar, num, index }) => {
  const initial = row.initials;
  return (
    <UserInfoContainer>
      <TableItemNum>{num}</TableItemNum>
      <AvatarContainer>
        {avatar ? (
          <UserAvatar src={avatar} />
        ) : (
          <UserEmptyAvatar>{initial}</UserEmptyAvatar>
        )}
      </AvatarContainer>
      <RefPhysicianName>{name}</RefPhysicianName>
    </UserInfoContainer>
  );
};

export const ColumnName = ({ title, withSortButton }) => {
  const [isSorted, setIsSorted] = useState(false);
  return (
    <ColumnNameWrapper>
      <span>{title}</span>
    </ColumnNameWrapper>
  );
};

const columns = [
  {
    key: "name",
    name: <ColumnName title="Referring Doctor Name" />,
    displayCell: (cellValue, row, idx) => (
      <UserDataBlock name={cellValue} num={idx+1} row={row} />
    ),
  },
  {
    key: "referredPatients",
    name: <ColumnName title="Referred Patients" withSortButton />,
    displayCell: (cellValue) => (
      <ItemWrapper>
        <IconContainer>
          <RefferedPatientsIcon />
        </IconContainer>
        <DoubleDataCell mainInfo={cellValue} />
      </ItemWrapper>
    ),
  },
  {
    key: "imagingRecos",
    name: <ColumnName title="Reports With Imaging Follow-Ups" withSortButton />,
    displayCell: (cellValue) => (
      <ItemWrapper>
        <IconContainer>
          <ImagingFollowUpsIcon />
        </IconContainer>
        <DoubleDataCell mainInfo={cellValue} />
      </ItemWrapper>
    ),
  },
  {
    key: "adherentPatients",
    name: <ColumnName title="Adherent Recos" withSortButton />,
    displayCell: (cellValue, row) => (
      <RecoBadge
        patientsCount={row.referredPatients}
        overduePatientsCount={cellValue}
        bcolor={"#64C67C"}
      />
    ),
  },
  {
    key: "comingDuePatients",
    name: <ColumnName title="Coming Due Recos" withSortButton />,
    displayCell: (cellValue, row) => (
      <RecoBadge
        patientsCount={row.referredPatients}
        overduePatientsCount={cellValue}
        bcolor={"#FEC400"}
      />
    ),
  },
  {
    key: "overduePatients",
    name: <ColumnName title="Overdue Recos" withSortButton />,
    displayCell: (cellValue, row) => (
      <RecoBadge
        patientsCount={row.referredPatients}
        overduePatientsCount={cellValue}
        bcolor={"#F12B2C"}
      />
    ),
  },
];

export const DatePickerContainer = styled(Flex)`
  min-width: 208px;
  margin-right: 6px;
  & > div > div > div {
    height: 38px;
    min-width: 208px;
  }
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

export const DateClearButton = styled.div`
  cursor: pointer;
  display: flex;
`;

export const DateContainer = styled(Flex)`
  margin-right: 15px;
  font-size: 12px;
`;

const DateRangeWrapper = styled.div`
  font-size: 12px;
  & > div > div > div {
    height: 38px;
    width: 208px;
  }
`;

const DoctorList = ({ history }) => {
  const doctors = useSelector((state) => state.radAdmin.doctors.data);
  const pageNumber = useSelector((state) => state.radAdmin.doctors.pageNumber);
  const totalItems = useSelector(
    (state) => state.radAdmin.doctors.hasMoreItems
  );
  const isLoadingDoctors = useSelector(
    (state) => state.radAdmin.doctors.isLoading
  );
  const radiologist = useSelector((state) => state.radAdmin.radiologist.data);
  const isLoadingRadiologist = useSelector(
    (state) => state.radAdmin.radiologist.isLoading
  );
  const totalItemsRad = useSelector(
    (state) => state.radAdmin.radiologist.hasMoreItems
  );
  const pageNumberRad = useSelector(
    (state) => state.radAdmin.radiologist.pageNumber
  );
  const csv = useSelector((state) => state.radAdmin.exportReport.data);
  const topDocs = doctors && doctors.slice(0, 3);
  const topRad = radiologist && radiologist.slice(0, 3);
  const itemsPortionSize = 10;
  const dispatch = useDispatch();
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [showPlaceholderRad, setShowPlaceholderRad] = useState(true);
  const [currPageNumber, setCurrPageNumber] = useState(1);
  const [needExport, setNeedExport] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredSort, setFilteredSort] = useState(null);
  const [isActiveFirst, setIsActiveFirst] = useState(
    history.location.pathname.split("/")[3] === "referringDocs"
  );
  const [displayItemsCount, setDisplayItemsCount] = useState(itemsPortionSize);
  const [displayItemsCountRad, setDisplayItemsCountRad] =
    useState(itemsPortionSize);
  const [isLoading, setIsLoading] = useState(false);
  const [radiologists, setRadiologits] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedExportFilters, setSelectedExportFilters] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);

  const inputRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);

  let initialDate = "";
  let initialSelectedOption = "range start";
  let initialSelectedConfiguration = "gte";
  let initRange = "";
  if (queryParams.has("rangeStartGte")) {
    initialSelectedOption = "range start";
    initialSelectedConfiguration = "gte";
    initialDate = new Date(queryParams.get("rangeStartGte"));
  }
  if (queryParams.has("rangeStartLte")) {
    initialSelectedOption = "range start";
    initialSelectedConfiguration = "lte";
    initialDate = new Date(queryParams.get("rangeStartLte"));
  }
  if (queryParams.has("rangeEndGte")) {
    initialSelectedOption = "range end";
    initialSelectedConfiguration = "gte";
    initialDate = new Date(queryParams.get("rangeEndGte"));
  }
  if (queryParams.has("rangeEndLte")) {
    initialSelectedOption = "range end";
    initialSelectedConfiguration = "lte";
    initialDate = new Date(queryParams.get("rangeEndLte"));
  }
  if (queryParams.has("rangeStartGte") && queryParams.has("rangeStartLte")) {
    initialSelectedOption = "range start";
    initialSelectedConfiguration = "gte and lte";
    initRange = {
      startDate: new Date(queryParams.get("rangeStartGte")),
      endDate: new Date(queryParams.get("rangeStartLte")),
    };
  }

  if (
    queryParams.has("rangeEndDateGte") &&
    queryParams.has("rangeEndDateLte")
  ) {
    initialSelectedOption = "range end";
    initialSelectedConfiguration = "gte and lte";

    initRange = {
      startDate: new Date(queryParams.get("rangeEndGte")),
      endDate: new Date(queryParams.get("rangeEndLte")),
    };
  }
  const [date, setDate] = useState(initialDate);

  let initExamStart = "";
  let initExamEnd = "";
  let initExamRange = {
    startDate: "",
    endDate: "",
  };
  if (queryParams.has("examStart") && queryParams.has("examEnd")) {
    initExamStart = new Date(queryParams.get("examStart"));
    initExamEnd = new Date(queryParams.get("examEnd"));
    initExamRange = {
      startDate: new Date(queryParams.get("examStart")),
      endDate: new Date(queryParams.get("examEnd")),
    };
  }

  const [initialRangeStartDate, setInitialRangeStartDate] = useState(
    initRange.startDate
  );
  const [initialRangeEndDate, setInitialRangeEndDate] = useState(
    initRange.endDate
  );
  const [initialRangeDatesFilter, setInitialRangeDatesFilter] = useState({
    startDate: initRange.startDate,
    endDate: initRange.endDate,
  });

  const [selectedOption, setSelectedOption] = useState(initialSelectedOption);
  const [selectedConfiguration, setSelectedConfiguration] = useState(
    initialSelectedConfiguration
  );
  const [initialExamStartDate, setInitialExamStartDate] =
    useState(initExamStart);
  const [initialExamEndDate, setInitialExamEndDate] = useState(initExamEnd);
  const [initialExamDatesFilter, setInitialExamDatesFilter] = useState({
    startDate: initExamRange.startDate,
    endDate: initExamRange.endDate,
  });
  const permission = useSelector((state) => state.auth.user.permission_level); 
  const rolePermissions = useSelector(
     (state) =>state.auth.user.permissions
   );
  const exportPermission = rolePermissions?.find(itm=>itm.name ==="Export")?.permissions.find(itm=>itm.codename === "add_export")?.is_authorized

  const formik = useFormik({
    initialValues: {
      searchValue: "",
      searchValueRad: "",
    },
    onSubmit: (values) => {
      if (isActiveFirst) {
        dispatch({
          type: SET_DOCTOR_SEARCH_VALUE,
          payload: values.searchValue,
        });
        dispatch(
          getDoctorsListAction(
            1,
            null,
            null,
            selectedOption,
            selectedConfiguration,
            date,
            initialRangeDatesFilter,
            initialExamDatesFilter
          )
        );
        window.analytics.track("Doc-List-Search-Submit", {
          search: values.searchValue,
        });
        return;
      }
      dispatch({
        type: SET_RADIOLOGIST_SEARCH_VALUE,
        payload: values.searchValueRad,
      });
      window.analytics.track("Radiologist-List-Search-Submit", {
        search: values.searchValueRad,
      });
      dispatch(
        getRadiologistList(
          1,
          filteredSort,
          selectedOption,
          selectedConfiguration,
          date,
          initialRangeDatesFilter,
          initialExamDatesFilter
        )
      );
    },
  });

  useEffect(() => {
    dispatch(
      getRadiologistList(
        1,
        filteredSort,
        selectedOption,
        selectedConfiguration,
        date,
        initialRangeDatesFilter,
        initialExamDatesFilter
      )
    );
    setRadiologits(radiologist);
  }, [radiologists]);

  useEffect(() => {
    if (doctors && filteredSort) {
      dispatch(
        getDoctorsListAction(
          currPageNumber,
          filteredSort,
          null,
          selectedOption,
          selectedConfiguration,
          date,
          initialRangeDatesFilter,
          initialExamDatesFilter
        )
      );
    }
    if (doctors && !filteredSort) {
      dispatch(
        getDoctorsListAction(
          currPageNumber,
          null,
          null,
          selectedOption,
          selectedConfiguration,
          date,
          initialRangeDatesFilter,
          initialExamDatesFilter
        )
      );
    }
  }, [filteredSort, currPageNumber]);

  useEffect(() => {
    setIsLoading(false);
  }, [doctors, radiologist]);

  useEffect(() => {
    const existingParams = new URLSearchParams(location.search);
    if (history.location.pathname.split("/")[3] === "referringDocs") {
      history.push(
        `/auth/radAdmin/referringDocs/${pageNumber}?${existingParams.toString()}`
      );
    }
    if (history.location.pathname.split("/")[3] === "radiologistLeaderboard") {
      history.push(
        `/auth/radAdmin/radiologistLeaderboard/${pageNumberRad}?${existingParams.toString()}`
      );
      setIsActiveFirst(false);
    }
  }, [pageNumber, pageNumberRad, history.location.pathname]);

  useEffect(() => {
    if (needExport && startDate && endDate) {
      dispatch(getExportReportAction(startDate, endDate));
      setNeedExport(false);
    }
  }, [needExport, startDate, endDate]);

  const onSetFilteredSort = (sort) => {
    setFilteredSort(sort);
    window.analytics.track("Referring-Doc-List-Set-Sort");
  };

  const onShowPlaceholder = () => {
    if (!inputRef.current.value) {
      setShowPlaceholder(true);
    }
  };

  const onHidePlaceholder = () => {
    if (isActiveFirst) {
      setShowPlaceholder(false);
      return;
    }
    setShowPlaceholderRad(false);
  };

  const onResetForm = () => {
    if (isActiveFirst) {
      formik.setFieldValue("searchValue", "");
      dispatch({ type: SET_DOCTOR_SEARCH_VALUE, payload: "" });
      dispatch(
        getDoctorsListAction(
          1,
          null,
          null,
          selectedOption,
          selectedConfiguration,
          date,
          initialRangeDatesFilter,
          initialExamDatesFilter
        )
      );
      setShowPlaceholder(true);
    } else {
      formik.setFieldValue("searchValueRad", "");
      dispatch({ type: SET_RADIOLOGIST_SEARCH_VALUE, payload: "" });
      dispatch(
        getRadiologistList(
          1,
          filteredSort,
          selectedOption,
          selectedConfiguration,
          date,
          initialRangeDatesFilter,
          initialExamDatesFilter
        )
      );
      setShowPlaceholderRad(true);
    }
  };

  const onNeedExport = (rangeStart, rangeEnd, filters) => {
    setNeedExport(true);
    setStartDate(rangeStart);
    setEndDate(rangeEnd);
    setSelectedExportFilters({ ...filters });
  };

  const onNeedDates = () => {
    if((permission && exportPermission) || !permission){
      setShowModal(true);
      window.analytics.track("Follow-Up-List-Export-Button-Click");
   }else{
     dispatch(
       showAlert('danger', 'Error', `You don't have permission to export physicians`)
     );
   }
  };

  const onLoadMore = () => {
    setIsLoading(true);
    if (isActiveFirst) {
      setDisplayItemsCount((prevCount) => prevCount + itemsPortionSize);
      dispatch(
        getDoctorsListAction(
          pageNumber + 1,
          filteredSort,
          null,
          selectedOption,
          selectedConfiguration,
          date,
          initialRangeDatesFilter,
          initialExamDatesFilter
        )
      );
      return;
    }
    setDisplayItemsCountRad(displayItemsCountRad + itemsPortionSize);
    dispatch(
      getRadiologistList(
        pageNumberRad + 1,
        filteredSort,
        selectedOption,
        selectedConfiguration,
        date,
        initialRangeDatesFilter,
        initialExamDatesFilter
      )
    );
    // setCurrPageNumber(pageNumber + 1);
  };

  const goToPatientList = (item) => {
    dispatch(setDoctorForPatientsAction(item));
    history.push(`/auth/radAdmin/referringDocs/${item.id}/patients/1`);
    window.analytics.track("Patient-List-Navigation-Click");
  };

  const renderButtonsColumn = (row) => {
    return (
      <StyledLink onClick={() => goToPatientList(row)}>
        <StyledChevron />
      </StyledLink>
    );
  };

  const checkboxHandler = (data) => {
    if (selectedIds.includes(data.id)) {
      const filteredItems = selectedIds.filter((item) => {
        return item !== data.id;
      });
      setSelectedIds([...filteredItems]);
    }
    if (!selectedIds.includes(data.id)) {
      selectedIds.push(data.id);
      setSelectedIds([...selectedIds]);
    }
  };

  const onChangeHandler = () => {
    if((permission && exportPermission) || !permission){
      dispatch(
        getExportReportAction(null, null, { selectedPhysicians: selectedIds })
      );
   }else{
     dispatch(
       showAlert('danger', 'Error', `You don't have permission to export physicians`)
     );
   }
  };

  const handleOptionChange = (event) => {
    resetRangeDateFilter();
    setSelectedOption(event.target.value);
  };

  const handleChangeConfiguration = (event) => {
    resetRangeDateFilter();
    setSelectedConfiguration(event.target.value);
  };

  const resetRangeDateFilter = () => {
    setDate("");
    setInitialRangeStartDate("");
    setInitialRangeEndDate("");
    setInitialRangeDatesFilter({
      startDate: "",
      endDate: "",
    });
  };

  const onInitialExamDatesChange = (dates) => {
    if (isArray(dates)) {
      const [start, end] = dates;
      setInitialExamStartDate(start);
      setInitialExamEndDate(end);
      if (start && end) {
        setInitialExamDatesFilter({
          startDate: start ? moment(start).format("YYYY-MM-DD") : "",
          endDate: end ? moment(end).format("YYYY-MM-DD") : "",
        });
      }
    }
  };

  const onInitialRangeDatesChange = (dates) => {
    if (isArray(dates)) {
      const [start, end] = dates;

      setInitialRangeStartDate(start);
      setInitialRangeEndDate(end);
      if (start && end) {
        setInitialRangeDatesFilter({
          startDate: start ? moment(start).format("YYYY-MM-DD") : "",
          endDate: end ? moment(end).format("YYYY-MM-DD") : "",
        });
      }
    }
  };

  const mountedRefRangeDates = useRef(false);

  useEffect(() => {
    if (mountedRefRangeDates.current) {
      if (isActiveFirst) {
        dispatch(
          getDoctorsListAction(
            currPageNumber,
            filteredSort,
            null,
            selectedOption,
            selectedConfiguration,
            date,
            initialRangeDatesFilter,
            initialExamDatesFilter
          )
        );
      } else {
        dispatch(
          getRadiologistList(
            currPageNumber,
            filteredSort,
            selectedOption,
            selectedConfiguration,
            date,
            initialRangeDatesFilter,
            initialExamDatesFilter
          )
        );
      }
      const searchParams = new URLSearchParams(location.search);
      let key = "";
      if (selectedOption === "range start") {
        key = "rangeStart";
      } else {
        key = "rangeEnd";
      }

      if (initialRangeDatesFilter.startDate) {
        searchParams.set(
          key + "Gte",
          moment(initialRangeDatesFilter.startDate).format("YYYY-MM-DD")
        );
      } else {
        searchParams.delete(key + "Gte");
      }
      if (initialRangeDatesFilter.endDate) {
        searchParams.set(
          key + "Lte",
          moment(initialRangeDatesFilter.endDate).format("YYYY-MM-DD")
        );
      } else {
        searchParams.delete(key + "Lte");
      }
      history.push({ search: `?${searchParams.toString()}` });
    }
    mountedRefRangeDates.current = true;
  }, [initialRangeDatesFilter]);

  const mountedRefRangeDatesCustom = useRef(false);

  useEffect(() => {
    if (mountedRefRangeDatesCustom.current) {
      if (isActiveFirst) {
        dispatch(
          getDoctorsListAction(
            currPageNumber,
            filteredSort,
            null,
            selectedOption,
            selectedConfiguration,
            date,
            initialRangeDatesFilter,
            initialExamDatesFilter
          )
        );
      } else {
        dispatch(
          getRadiologistList(
            currPageNumber,
            filteredSort,
            selectedOption,
            selectedConfiguration,
            date,
            initialRangeDatesFilter,
            initialExamDatesFilter
          )
        );
      }
      const searchParams = new URLSearchParams(location.search);
      let key = "";
      if (selectedOption === "range start") {
        key = "rangeStart";
      } else {
        key = "rangeEnd";
      }
      let config = "";

      if (selectedConfiguration === "gte") {
        config = "Gte";
      } else if (selectedConfiguration === "lte") {
        config = "Lte";
      }

      if (date) {
        searchParams.set(key + config, moment(date).format("YYYY-MM-DD"));
      } else {
        searchParams.delete("rangeStartGte");
        searchParams.delete("rangeEndGte");
        searchParams.delete("rangeStartLte");
        searchParams.delete("rangeEndLte");
      }

      history.push({ search: `?${searchParams.toString()}` });
    }

    mountedRefRangeDatesCustom.current = true;
  }, [date]);

  const mountedRefExamDates = useRef(false);

  useEffect(() => {
    if (mountedRefExamDates.current) {
      if (isActiveFirst) {
        dispatch(
          getDoctorsListAction(
            currPageNumber,
            filteredSort,
            null,
            selectedOption,
            selectedConfiguration,
            date,
            initialRangeDatesFilter,
            initialExamDatesFilter
          )
        );
      } else {
        dispatch(
          getRadiologistList(
            currPageNumber,
            filteredSort,
            selectedOption,
            selectedConfiguration,
            date,
            initialRangeDatesFilter,
            initialExamDatesFilter
          )
        );
      }
      const searchParams = new URLSearchParams(location.search);

      if (initialExamDatesFilter.startDate) {
        searchParams.set(
          "examStart",
          moment(initialExamDatesFilter.startDate).format("YYYY-MM-DD")
        );
      } else {
        searchParams.delete("examStart");
      }

      if (initialExamDatesFilter.endDate) {
        searchParams.set(
          "examEnd",
          moment(initialExamDatesFilter.endDate).format("YYYY-MM-DD")
        );
      } else {
        searchParams.delete("examEnd");
      }
      history.push({ search: `?${searchParams.toString()}` });
    }
    mountedRefExamDates.current = true;
  }, [initialExamDatesFilter]);

  const DatePickerInput = forwardRef(({ onClick, startDate, endDate }, ref) => {
    const formatDate = (date) => {
      return date ? moment(date).format("MMM, DD, YYYY") : "No Date";
    };

    return (
      <DatePickerButton onClick={onClick} ref={ref}>
        <DateContainer>
          {!startDate && !endDate
            ? "Select Exam Date Range"
            : `${formatDate(startDate)} - ${formatDate(endDate)}`}
        </DateContainer>
        {!initialExamStartDate && !initialExamEndDate && <CalendarIcon />}
        {(initialExamStartDate || initialExamEndDate) && (
          <DateClearButton onClick={resetExamDateFilter}>
            <RemoveRecoIcon style={{ height: "12px" }} />
          </DateClearButton>
        )}
      </DatePickerButton>
    );
  });

  const DatePickerInputField = React.forwardRef((props, ref) => {
    const { onClick, date } = props;
    const formatDate = (date, time) => {
      return `${moment(date).format("MMM, DD, YYYY")}`;
    };
    return (
      <DatePickerButton
        onClick={onClick}
        style={{ padding: "8px 24px", fontSize: "12px" }}
      >
        <DateContainer style={{ fontSize: 12 }}>
          {date ? `${formatDate(date)}` : "Select Reco Date"}
        </DateContainer>
        {!date && <CalendarIcon />}
        {date && (
          <DateClearButton onClick={resetRangeDateFilter}>
            <RemoveRecoIcon style={{ height: "12px" }} />
          </DateClearButton>
        )}
      </DatePickerButton>
    );
  });

  const resetExamDateFilter = () => {
    setInitialExamStartDate(null);
    setInitialExamEndDate(null);
    setInitialExamDatesFilter({
      startDate: null,
      endDate: null,
    });
  };

  const DatePickerInputRange = forwardRef(
    ({ onClick, startDate, endDate }, ref) => {
      const formatDate = (date) => {
        return date ? moment(date).format("MMM, DD, YYYY") : "No Date";
      };

      return (
        <DatePickerButton onClick={onClick} ref={ref}>
          <DateContainer>
            {!startDate && !endDate
              ? "Select Reco Date Range"
              : `${formatDate(startDate)} - ${formatDate(endDate)}`}
          </DateContainer>

          {!initialRangeStartDate && !initialRangeEndDate && <CalendarIcon />}
          {(initialRangeStartDate || initialRangeEndDate) && (
            <DateClearButton onClick={resetRangeDateFilter}>
              <RemoveRecoIcon />
            </DateClearButton>
          )}
        </DatePickerButton>
      );
    }
  );

  const onChange = (dates) => {
    setDate(dates);
    // setPageNumber(1)
  };

  return (
    <Box p={30}>
      <HeaderContainer>
        <Row style={{ flexGrow: 1 }}>
          <Col>
            <SwitchContainer>
              <SwitchButton
                active={isActiveFirst}
                onClick={() => {
                  resetExamDateFilter();
                  resetRangeDateFilter();
                  setSelectedConfiguration("gte");
                  setSelectedOption("range start");
                  history.push(`/auth/radAdmin/referringDocs/${pageNumber}`);
                  if (pageNumber === null) {
                    dispatch(
                      getDoctorsListAction(
                        1,
                        filteredSort,
                        null,
                        selectedOption,
                        selectedConfiguration,
                        date,
                        initialRangeDatesFilter,
                        initialExamDatesFilter
                      )
                    );
                  }
                  setIsActiveFirst(true);
                  window.analytics.track("Referring-Doctors-Button-Click");
                }}
              >
                Referring Doctors
              </SwitchButton>
              <SwitchButton
                active={!isActiveFirst}
                onClick={() => {
                  resetExamDateFilter();
                  resetRangeDateFilter();
                  setSelectedConfiguration("gte");
                  setSelectedOption("range start");
                  history.push(
                    `/auth/radAdmin/radiologistLeaderboard/${pageNumberRad}`
                  );
                  setIsActiveFirst(false);
                  window.analytics.track(
                    "Radiologist-Leaderboard-Button-Click"
                  );
                }}
              >
                Radiologist Leaderboard
              </SwitchButton>
            </SwitchContainer>
          </Col>
          <div>
            <DatePickerContainer>
              <DatePicker
                popperPlacement={"top-end"}
                selected={initialExamStartDate}
                onChange={onInitialExamDatesChange}
                startDate={initialExamStartDate}
                endDate={initialExamEndDate}
                customInput={
                  <DatePickerInput
                    startDate={initialExamStartDate}
                    endDate={initialExamEndDate}
                  />
                }
                shouldCloseOnSelect={false}
                selectsRange
                dateFormat={"dd/MM/yyyy"}
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: "5px, 10px",
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: "viewport",
                  },
                }}
              />
            </DatePickerContainer>
            <p
              style={{
                fontSize: "9.2px",
                fontWeight: 400,
                marginTop: "4.5px",
              }}
            >
              EXAM RANGE
            </p>
          </div>

          <div>
            {selectedConfiguration === "gte and lte" ? (
              <DatePickerContainer>
                <DatePicker
                  popperPlacement={"top-end"}
                  selected={initialRangeStartDate}
                  onChange={onInitialRangeDatesChange}
                  startDate={initialRangeStartDate}
                  endDate={initialRangeEndDate}
                  customInput={
                    <DatePickerInputRange
                      startDate={initialRangeStartDate}
                      endDate={initialRangeEndDate}
                    />
                  }
                  shouldCloseOnSelect={false}
                  selectsRange
                  dateFormat={"dd/MM/yyyy"}
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                />
              </DatePickerContainer>
            ) : (
              <DateRangeWrapper>
                <DatePicker
                  onChange={onChange}
                  dateFormat="MM/dd/yyyy"
                  selected={date}
                  customInput={<DatePickerInputField date={date} />}
                  // className={classes.datePicker}
                />
              </DateRangeWrapper>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "5px",
                marginTop: "4.5px",
                height: "16px",
                marginBottom: "5px",
              }}
            >
              <label
                style={{
                  fontSize: "9px",
                  fontWeight: 400,
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  marginBottom: "0px",
                }}
              >
                <input
                  type="radio"
                  value="range start"
                  checked={selectedOption === "range start"}
                  onChange={handleOptionChange}
                  style={{
                    appearance: "none",
                    width: "16px",
                    height: "16px",
                    border: "2px solid",
                    borderRadius: "50%",
                    borderColor:
                      selectedOption === "range start" ? "#00AEEF" : "#D4D4D4",

                    backgroundColor:
                      selectedOption === "range start" ? "#00AEEF" : "#FFF",
                    padding: "1.5px",
                    backgroundClip: "content-box",
                  }}
                />
                RECO RANGE START
              </label>
              <br />

              <label
                style={{
                  fontSize: "9px",
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  marginBottom: "0px",
                }}
              >
                <input
                  type="radio"
                  value="range end"
                  checked={selectedOption === "range end"}
                  onChange={handleOptionChange}
                  style={{
                    appearance: "none",
                    width: "16px",
                    height: "16px",
                    border: "2px solid",
                    borderRadius: "50%",
                    borderColor:
                      selectedOption === "range end" ? "#00AEEF" : "#D4D4D4",
                    backgroundColor:
                      selectedOption === "range end" ? "#00AEEF" : "#FFF",
                    padding: "1.5px",
                    backgroundClip: "content-box",
                  }}
                />
                RECO RANGE END
              </label>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "5px",
                flexWrap: "wrap",
                height: "16px",
              }}
            >
              <label
                style={{
                  fontSize: "9px",
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  marginBottom: "0px",
                }}
              >
                <input
                  type="radio"
                  value="gte"
                  checked={selectedConfiguration === "gte"}
                  onChange={handleChangeConfiguration}
                  style={{
                    appearance: "none",
                    width: "16px",
                    height: "16px",
                    border: "2px solid",
                    borderRadius: "50%",
                    borderColor:
                      selectedConfiguration === "gte" ? "#00AEEF" : "#D4D4D4",

                    backgroundColor:
                      selectedConfiguration === "gte" ? "#00AEEF" : "#FFF",
                    padding: "1.5px",
                    backgroundClip: "content-box",
                  }}
                />
                GREATER THAN EQUAL
              </label>
              <br />

              <label
                style={{
                  fontSize: "9px",
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  marginBottom: "0px",
                }}
              >
                <input
                  type="radio"
                  value="lte"
                  checked={selectedConfiguration === "lte"}
                  onChange={handleChangeConfiguration}
                  style={{
                    appearance: "none",
                    width: "16px",
                    height: "16px",
                    border: "2px solid",
                    borderRadius: "50%",
                    borderColor:
                      selectedConfiguration === "lte" ? "#00AEEF" : "#D4D4D4",

                    backgroundColor:
                      selectedConfiguration === "lte" ? "#00AEEF" : "#FFF",
                    padding: "1.5px",
                    backgroundClip: "content-box",
                  }}
                />
                LESS THAN EQUAL
              </label>
              <br />

              <label
                style={{
                  fontSize: "9px",
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  marginBottom: "0px",
                }}
              >
                <input
                  type="radio"
                  value="gte and lte"
                  checked={selectedConfiguration === "gte and lte"}
                  onChange={handleChangeConfiguration}
                  style={{
                    appearance: "none",
                    width: "16px",
                    height: "16px",
                    border: "2px solid",
                    borderRadius: "50%",
                    borderColor:
                      selectedConfiguration === "gte and lte"
                        ? "#00AEEF"
                        : "#D4D4D4",

                    backgroundColor:
                      selectedConfiguration === "gte and lte"
                        ? "#00AEEF"
                        : "#FFF",
                    padding: "1.5px",
                    backgroundClip: "content-box",
                  }}
                />
                CUSTOM
              </label>
            </div>
          </div>
          {isActiveFirst ? (
            <Col>
              <FormContainer>
                <form onSubmit={formik.handleSubmit}>
                  <InputContainer>
                    {showPlaceholder && (
                      <>
                        <SearchIcon>
                          <Search />
                        </SearchIcon>
                        <CustomPlaceholder onClick={onHidePlaceholder}>
                          {"Search by Name, Unique ID, Email Address"}
                        </CustomPlaceholder>
                      </>
                    )}
                    <Input
                      type="text"
                      id={"searchValue"}
                      name={"searchValue"}
                      onFocus={onHidePlaceholder}
                      onBlur={onShowPlaceholder}
                      onChange={formik.handleChange}
                      value={formik.values.searchValue}
                      ref={inputRef}
                    />
                    {!showPlaceholder && formik.values.searchValue && (
                      <ClearButton type={"reset"} onClick={onResetForm}>
                        X
                      </ClearButton>
                    )}
                  </InputContainer>
                </form>
              </FormContainer>
            </Col>
          ) : (
            <Col>
              <FormContainer>
                <form onSubmit={formik.handleSubmit}>
                  <InputContainer>
                    {showPlaceholderRad && (
                      <>
                        <SearchIcon>
                          <Search />
                        </SearchIcon>
                        <CustomPlaceholder onClick={onHidePlaceholder}>
                          {isActiveFirst
                            ? "Search by Name, Unique ID, Email Address"
                            : "Search by Name"}
                        </CustomPlaceholder>
                      </>
                    )}
                    <Input
                      type="text"
                      id={"searchValueRad"}
                      name={"searchValueRad"}
                      onFocus={onHidePlaceholder}
                      onBlur={onShowPlaceholder}
                      onChange={formik.handleChange}
                      value={formik.values.searchValueRad}
                      ref={inputRef}
                    />
                    {!showPlaceholderRad && formik.values.searchValueRad && (
                      <ClearButton type={"reset"} onClick={onResetForm}>
                        X
                      </ClearButton>
                    )}
                  </InputContainer>
                </form>
              </FormContainer>
            </Col>
          )}
          {isActiveFirst && (
            <Col>
              <ExportButton type={"button"} onClick={onNeedDates}>
                Export Referring Physicians
              </ExportButton>
              <ExportModal
                showModal={showModal}
                closeModal={() => setShowModal(false)}
                csvData={csv}
                onNeedExport={onNeedExport}
              />
            </Col>
          )}
        </Row>
      </HeaderContainer>
      <Row>
        {
          topDocs && isActiveFirst
            ? topDocs.map((item) => (
                <Col sm={12} lg={12} xl={4} key={JSON.stringify(item)}>
                  <TopDoctorCard
                    info={item}
                    goToPatientList={goToPatientList}
                  />
                </Col>
              ))
            : topRad &&
              !isActiveFirst &&
              topRad.map((item, index) => (
                <Col sm={12} lg={12} xl={4} key={index}>
                  <TopLeaderBoardCard
                    info={item}
                    goToPatientList={goToPatientList}
                    position={index === 0 ? "1st" : index === 1 ? "2nd" : "3rd"}
                  />
                </Col>
              ))
          //   <SearchInput placeholder='Search by Name, uniqueId, email address' handleResetForm={handleResetForm} handleSubmit={handleSubmit}/>
          // </HeaderContainer>
          // <Row>
          //   {
          //     topDocs && topDocs.map(item => <Col sm={12} lg={12} xl={4} key={JSON.stringify(item)}><TopDoctorCard info={item} goToPatientList={goToPatientList} /></Col>)
        }
      </Row>
      {isActiveFirst && !isLoadingDoctors ? (
        <ExportContainer>
          <ExportButton type={"button"} onClick={onChangeHandler}>
            Export Selected Physicians
          </ExportButton>
        </ExportContainer>
      ) : (
        ""
      )}
      <Card>
        <Table
          rows={
            !isActiveFirst
              ? radiologist && radiologist.slice(0, displayItemsCountRad)
              : doctors && doctors.slice(0, displayItemsCount)
          }
          isActiveFirst={isActiveFirst}
          columns={
            isActiveFirst
              ? [
                  {
                    key: "",
                    name: "",
                    displayCell: (cellValue, rowData) => (
                      <DoubleDataCell
                        mainInfo={"checkbox"}
                        checkboxHandler={() => checkboxHandler(rowData)}
                      />
                    ),
                  },
                ].concat(columns)
              : radiologistColumns
          }
          initialPageNumber={null}
          isLoadingRows={
            isActiveFirst ? isLoadingDoctors : isLoadingRadiologist
          }
          getRowsAction={
            isActiveFirst
              ? (pageNumber) =>
                  getDoctorsListAction(
                    pageNumber,
                    filteredSort,
                    null,
                    selectedOption,
                    selectedConfiguration,
                    date,
                    initialRangeDatesFilter,
                    initialExamDatesFilter
                  )
              : (pageNumber) =>
                  getRadiologistList(
                    pageNumber,
                    filteredSort,
                    selectedOption,
                    selectedConfiguration,
                    date,
                    initialRangeDatesFilter,
                    initialExamDatesFilter
                  )
          }
          renderButtonsColumn={renderButtonsColumn}
          checkboxHandler={checkboxHandler}
          noDataRender={() => (
            <EmptyState
              title={"No Results"}
              img={() => <EmptyStateImg />}
              buttonInfo={{
                link: "/auth/radAdmin/Import",
                text: "Import Data",
              }}
            >
              Sorry, there is no data yet <br /> Please import a csv to get
              started
            </EmptyState>
          )}
        />

        {((doctors && doctors.length !== 0) ||
          (radiologist && radiologist.length !== 0)) &&
          !(isLoadingDoctors && currPageNumber === 1) && (
            <LoadMoreButton
              title="Load More"
              handleClick={onLoadMore}
              isLoading={
                isActiveFirst
                  ? isLoadingDoctors || isLoading
                  : isLoadingRadiologist || isLoading
              }
              isDisabled={
                isActiveFirst
                  ? isLoadingDoctors || !totalItems
                  : isLoadingRadiologist || !totalItemsRad
              }
            />
          )}
      </Card>
    </Box>
  );
};

export default DoctorList;

export const DocListWithFeature = (props) => {
  return (
    <EnableFeature featureName={"ref-docs-list-view"} {...props}>
      <DoctorList />
    </EnableFeature>
  );
};
