import { SIGNUP_FAILED } from '../actions/auth';

const initialState = {
    email: [],
    password: [],
};

const apiErrorReducer = (state = initialState, action) => {
    switch (action.type) {
    case SIGNUP_FAILED:
        const errors = {};

        Object.entries(action.payload).forEach(([key, value]) => {
            errors[key] = value;
        });

        if (
            state.email.length &&
        !Object.keys(action.payload).includes('email')
        ) {
            errors.email = [];
        }

        return {
            ...state,
            ...errors,
        };
    default:
        return state;
    }
};

export default apiErrorReducer;
