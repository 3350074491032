import React, { useReducer } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import Validator from 'email-validator';
import { Alert } from 'react-bootstrap';

import { AccountContentContainer, HeadlineCTA } from './common';
import { Form, FormRow } from '../Design/form';
import FormGroup from './common/FormGroup';
import Box from '../_styles/Box';
import Button from '../UI/Button/Button';
import {
  resetPasswordRequest,
  resetPasswordReducer,
  resetPasswordInitialState,
} from './reducers/resetPasswordReducer';

function ForgotPassword({ handleSubmit }) {
  const [state, dispatch] = useReducer(
    resetPasswordReducer,
    resetPasswordInitialState
  );

  const onFormSubmit = ({ email }) => {
    resetPasswordRequest({ email, dispatch });
  };

  return (
    <AccountContentContainer>
      <HeadlineCTA
        headlineText="Forgot Password"
        subtext="Please enter your email to receive a password reset link."
      />

      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <FormRow>
          <Field
            component={FormGroup}
            type="text"
            label="Your email"
            name="email"
            placeholder="Enter your email here"
          />
        </FormRow>

        <FormRow>
          <Button type="submit" primary block loading={state.isSubmitting}>
            {state.hasSubmitted ? 'Done!' : 'Email Me'}
          </Button>
        </FormRow>
      </Form>
      {state.hasSubmitted && (
        <Box mt={30}>
          <Alert variant="success">
            Thank you! Please check your email for a password reset link.
          </Alert>
        </Box>
      )}

      {state.hasError && (
        <Box mt={30}>
          <Alert variant="danger">
            Something went wrong. Please try again later.
          </Alert>
        </Box>
      )}
    </AccountContentContainer>
  );
}

function validate({ email }) {
  const errors = {};

  if (!Validator.validate(email)) {
    errors.email = 'Invalid email';
  }

  return errors;
}

const form = reduxForm({
  form: 'forgot-password',
  fields: ['email'],
  validate,
})(ForgotPassword);

export default connect(null, null)(form);
