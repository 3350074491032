import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as RemoveIcon } from '../../../../assets/remove.svg';

export const FormItem = styled.div`
  width: 100%;
`;

const RoundButton = styled.button`
  background: #d9ecff;
  border-radius: 31px;
  width: 123px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  color: #1a6dff;
  border: none;
  display: block;
  margin: auto;

  ${(props) => props.isPreviewMode && 'cursor: default !important;'}
`;

const TransitionLine = styled.div`
  border-right: 2px solid #cbcccf;
  height: 100px;
  margin: auto;
`;

const PlusButton = styled.button`
  background: #ffffff;
  border: 1px solid #c2ccd9;
  border-radius: 8px;
  line-height: 0;
  padding: 10px;
  position: absolute;
  top: 30px;
  left: -15px;
`;

const TransitionLineWrapper = styled.div`
  margin: auto;
  position: relative;
`;

const MessageTypeStepWrapper = styled.div`
  margin: auto;
  position: relative;
`;

const ABTestingContainer = styled.div`
  background: #ffffff;
  border: 1px solid #f1f2f6;
  box-sizing: border-box;
  box-shadow: 0px 4px 26px rgba(122, 140, 169, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  height: 100px;
  width: 500px;
  margin: auto;
`;

const StepLabel = styled.label`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #c2ccd9;
  position: absolute;
  left: ${(props) => (props.left ? props.left : '-180px')};
  top: ${(props) => (props.top ? props.top : '10px')};
`;

const StepBtn = styled.button`
  background: ${(props) => (props.bg ? props.bg : '#e6ffec')};
  border-radius: 5px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${(props) => (props.color ? props.color : '#64c67c')};
  border: none;
  display: block;
  min-width: 180px;
  min-height: 40px;
  margin: auto;

  ${(props) => props.isPreviewMode && 'cursor: default !important;'}
`;

const TimespanText = styled.label`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #8692a4;
  position: absolute;
  top: 38px;
  left: -100px;
`;

const DeleteBtn = styled.div`
  position: absolute;
  right: -130px;
  top: 3px;
  cursor: pointer;
  margin: auto;
`;

const MAX_STEPS = 10;

const PlusSvg = () => {
  return (
    <svg
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.07202 0.506624L5.10524 4.69321L8.81513 4.65571L8.82358 5.7204L5.11369 5.7579L5.14691 9.94448L4.20345 9.95401L4.17022 5.76743L0.460331 5.80492L0.451883 4.74023L4.16178 4.70274L4.12855 0.516159L5.07202 0.506624Z"
        fill="#8692A4"
      />
    </svg>
  );
};

const EditSvg = () => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.55078 10.4809L2.55078 12.7596L5.2233 12.7596L13.1055 6.03893L10.4329 3.76024L2.55078 10.4809ZM15.1722 4.27674C15.4502 4.03976 15.4502 3.65694 15.1722 3.41996L13.5046 1.99805C13.2266 1.76107 12.7776 1.76107 12.4997 1.99805L11.1955 3.11005L13.868 5.38875L15.1722 4.27674Z"
        fill="#8692A4"
      />
    </svg>
  );
};

const ClockSvg = () => {
  return (
    <span style={{ paddingRight: '3px' }}>
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.9241 3.09865L8.76553 1.00781L8.1602 1.83656L10.3188 3.9274L10.9241 3.09865ZM4.29826 1.83656L3.69762 1.00781L1.53906 3.09323L2.1444 3.92198L4.29826 1.83656ZM6.4662 4.33365H5.76232V7.58365L7.99127 9.1274L8.34321 8.46115L6.4662 7.1774V4.33365ZM6.23158 2.16698C3.8994 2.16698 2.00831 4.3499 2.00831 7.04198C2.00831 9.73406 3.8947 11.917 6.23158 11.917C8.56375 11.917 10.4548 9.73406 10.4548 7.04198C10.4548 4.3499 8.56375 2.16698 6.23158 2.16698ZM6.23158 10.8336C4.41557 10.8336 2.94682 9.13823 2.94682 7.04198C2.94682 4.94573 4.41557 3.25031 6.23158 3.25031C8.04758 3.25031 9.51633 4.94573 9.51633 7.04198C9.51633 9.13823 8.04758 10.8336 6.23158 10.8336Z"
          fill="#8692A4"
        />
      </svg>
    </span>
  );
};

const getBG = (type) => {
  if (type === 'green') return '#e6ffec';
  if (type === 'blue') return '#6F52ED';
  if (type === 'yellow') return '#FFD57C';
  if (type === 'red') return '#FF0000';
};

const getColor = (type) => {
  if (type === 'green') return '#64c67c';
  if (type === 'blue') return '#F9FAFC';
  if (type === 'yellow') return '#00AEEF';
  if (type === 'red') return '#F9FAFC';
};

export function Sequences({
  modalHandler,
  setShowSMSModal,
  setCurrentNode,
  dialogHandler,
  emailAndSmsHandle,
  setShowTDModal,
  isPreviewMode,
}) {
  const campaigns = useSelector((state) => state.radAdmin.campaigns);
  const sequences = campaigns?.createdCampaign?.steps || [];
  const stepCounts = campaigns?.createdCampaign?.stepCount;

  return (
    <>
      <RoundButton isPreviewMode={isPreviewMode}>Trigger</RoundButton>

      {sequences.map((sequence, index) => {
        if (sequence.type === 'transition') {
          return (
            <TransitionLineWrapper key={sequence.id}>
              <TransitionLine></TransitionLine>
              {sequence.label && (
                <StepLabel top="38px" left="-225px">
                  {sequence.label}
                </StepLabel>
              )}
              {sequence.tsText && (
                <TimespanText>
                  <ClockSvg /> {sequence.tsText}
                </TimespanText>
              )}
              {!isPreviewMode && stepCounts < MAX_STEPS && (
                <PlusButton
                  onClick={() => {
                    if (modalHandler) {
                      modalHandler(index);
                    }
                  }}
                >
                  {PlusSvg()}
                </PlusButton>
              )}
            </TransitionLineWrapper>
          );
        }

        if (['email', 'sms'].includes(sequence.type) && !sequence.isAbTest) {
          return (
            <MessageTypeStepWrapper
              onClick={() => {
                if (setShowSMSModal) {
                  setShowSMSModal(true);
                }

                if (setCurrentNode) {
                  setCurrentNode(sequence);
                }
              }}
              key={sequence.id}
            >
              {sequence.stepLabel && (
                <StepLabel>{sequence.stepLabel}</StepLabel>
              )}
              <StepBtn
                bg={getBG(sequence.btn)}
                color={getColor(sequence.btn)}
                isPreviewMode={isPreviewMode}
              >
                {!isPreviewMode && <EditSvg />} {sequence.text}
              </StepBtn>
              {!isPreviewMode && (
                <DeleteBtn onClick={(event) => dialogHandler(event, index)}>
                  <RemoveIcon />
                </DeleteBtn>
              )}
            </MessageTypeStepWrapper>
          );
        }

        if (['remove_patient'].includes(sequence.type) && !sequence.isAbTest) {
          return (
            <MessageTypeStepWrapper key={sequence.id}>
              {sequence.stepLabel && (
                <StepLabel>{sequence.stepLabel}</StepLabel>
              )}
              <StepBtn
                bg={getBG(sequence.btn)}
                color={getColor(sequence.btn)}
                isPreviewMode={isPreviewMode}
              >
                {!isPreviewMode && <EditSvg />} {sequence.text}
              </StepBtn>
            </MessageTypeStepWrapper>
          );
        }

        if (['email'].includes(sequence.type) && sequence.isAbTest) {
          return (
            <MessageTypeStepWrapper
              onClick={() => {
                if (emailAndSmsHandle) {
                  emailAndSmsHandle(sequence, index);
                }
              }}
              key={sequence.id}
            >
              <ABTestingContainer>
                <StepBtn
                  bg={getBG(sequence.btn)}
                  color={getColor(sequence.btn)}
                  isPreviewMode={isPreviewMode}
                >
                  {!isPreviewMode && <EditSvg />} {sequence.textBtnA}
                </StepBtn>
                <StepBtn
                  bg={getBG(sequence.btn)}
                  color={getColor(sequence.btn)}
                  isPreviewMode={isPreviewMode}
                >
                  {!isPreviewMode && <EditSvg />} {sequence.textBtnB}
                </StepBtn>
              </ABTestingContainer>
              {!isPreviewMode && (
                <DeleteBtn onClick={(event) => dialogHandler(event, index)}>
                  <RemoveIcon />
                </DeleteBtn>
              )}
            </MessageTypeStepWrapper>
          );
        }

        if (sequence.type === 'timeDelay') {
          return (
            <MessageTypeStepWrapper key={sequence.id}>
              <RoundButton
                key={sequence.id}
                onClick={() => {
                  if (setShowTDModal) {
                    setShowTDModal(true);
                  }

                  if (setCurrentNode) {
                    setCurrentNode(sequence);
                  }
                }}
                isPreviewMode={isPreviewMode}
              >
                <span>Time Delay</span>

                {sequence.selectedNumber && sequence.selectedDelay && (
                  <div style={{ fontSize: '12px', color: '#768eb3' }}>
                    {sequence.selectedNumber} {sequence.selectedDelay}
                  </div>
                )}
              </RoundButton>
              {!isPreviewMode && (
                <DeleteBtn onClick={(event) => dialogHandler(event, index)}>
                  <RemoveIcon />
                </DeleteBtn>
              )}
            </MessageTypeStepWrapper>
          );
        }
      })}
    </>
  );
}

Sequences.defaultProps = {
  modalHandler: undefined,
  setShowSMSModal: undefined,
  setCurrentNode: undefined,
  dialogHandler: undefined,
  emailAndSmsHandle: undefined,
  setShowTDModal: undefined,
  isPreviewMode: false,
};
