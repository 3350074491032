import moment from "moment";
import API from "../../libs/api";
import { showAlert } from "../radminUploads";

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";

export const GET_INVITED_USERS_REQUEST = "GET_INVITED_USERS_REQUEST";
export const GET_INVITED_USERS_SUCCESS = "GET_INVITED_USERS_SUCCESS";
export const GET_INVITED_USERS_FAILURE = "GET_INVITED_USERS_FAILURE";

export const GET_ROLES_PERMISSIONS_REQUEST = "GET_ROLES_PERMISSIONS_REQUEST";
export const GET_ROLES_PERMISSIONS_SUCCESS = "GET_ROLES_PERMISSIONS_SUCCESS";
export const GET_ROLES_PERMISSIONS_FAILURE = "GET_ROLES_PERMISSIONS_FAILURE";

export const setEmailSubscriptionWeekly =
  ({ user, kpiEmailEnabled }) =>
  async (dispatch) => {
    try {
      await API.patchRequest(`/api/v1/user/${user.id}/`, {
        weekly_kpi_email_enabled: kpiEmailEnabled,
      });
      dispatch(
        showAlert(
          "success",
          "Success",
          kpiEmailEnabled
            ? "Weekly kpi stats email successfully subscribed!"
            : "Weelly kpi stats email successfully unsubscribed!"
        )
      );

      let payload = {
        ...user,
        weekly_kpi_email_enabled: kpiEmailEnabled,
      };

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
      dispatch({
        type: UPDATE_USER_FAILED,
        error,
      });
    }
  };

export const setEmailSubscriptionMonthly =
  ({ user, kpiEmailEnabled }) =>
  async (dispatch) => {
    try {
      await API.patchRequest(`/api/v1/user/${user.id}/`, {
        monthly_kpi_email_enabled: kpiEmailEnabled,
      });
      dispatch(
        showAlert(
          "success",
          "Success",
          kpiEmailEnabled
            ? "Monthly kpi stats email successfully subscribed!"
            : "Monthly kpi stats email successfully unsubscribed!"
        )
      );

      let payload = {
        ...user,
        monthly_kpi_email_enabled: kpiEmailEnabled,
      };

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
      dispatch({
        type: UPDATE_USER_FAILED,
        error,
      });
    }
  };

export const setQualityMetricsEmailSubscription =
  ({ user, qualityMetricsEmailEnabled }) =>
  async (dispatch) => {
    try {
      await API.patchRequest(`/api/v1/user/${user.id}/`, {
        quality_metrics_email_enabled: qualityMetricsEmailEnabled,
      });
      dispatch(
        showAlert(
          "success",
          "Success",
          qualityMetricsEmailEnabled
            ? "Quality metrics email successfully subscribed!"
            : "Quality metrics email successfully unsubscribed!"
        )
      );

      let payload = {
        ...user,
        quality_metrics_email_enabled: qualityMetricsEmailEnabled,
      };

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
      dispatch({
        type: UPDATE_USER_FAILED,
        error,
      });
    }
  };

  
export const setIncomingMessageSubscription =
({ user, incomingMessageEnabled, statusType }) =>
async (dispatch) => {
  try {
    await API.patchRequest(`/api/v1/user/${user.id}/`, {
      notify_incoming_sms: incomingMessageEnabled === false? 'unsubscribe': statusType,
    });
    dispatch(
      showAlert(
        "success",
        "Success",
        incomingMessageEnabled && statusType ==="daily_summary"?
        "Incoming message email daily summary successfully subscribed!"
        :incomingMessageEnabled && statusType ==="immediate"?
        "Incoming message email immediate successfully subscribed!"
        : "Incoming message email successfully unsubscribed!"
      )
    );

    let payload = {
      ...user,
      notify_incoming_sms: incomingMessageEnabled === false? 'unsubscribe': statusType,
    };

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload,
    });
  } catch (error) {
    dispatch(showAlert("danger", "Error", error.message));
    dispatch({
      type: UPDATE_USER_FAILED,
      error,
    });
  }
};

export const getInvitedUsers = (pageNumber,search) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_INVITED_USERS_REQUEST, payload: pageNumber });

    let responseData;
    let totalItems;
    let items;

    const { data } = await API.getRequest(
      `/api/v1/invited-users/?page=${pageNumber}&ordering=-created${search ? `&search=${search}`:""}`
    );
    responseData = data.results.map((itm) => ({
      ...itm,
      date_joined: itm?.date_joined
        ? moment(itm.date_joined).format("YYYY-MM-DD")
        : "",
      last_login: itm.last_login
        ? moment(itm.last_login).format("YYYY-MM-DD")
        : "",
    }));
    totalItems = data.count;

    dispatch({
      type: GET_INVITED_USERS_SUCCESS,
      payload: {
        items: responseData,
        totalItems: totalItems,
        next: responseData.next,
        pageNumber: pageNumber,
      },
    });
  } catch (error) {
    dispatch({ type: GET_INVITED_USERS_FAILURE });
    dispatch(showAlert("danger", "Error", error.message));
  }
};

export const getPermissions = (role) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ROLES_PERMISSIONS_REQUEST });

    let responseData;
    let totalItems;

    const { data } = await API.getRequest(
      `/api/v1/invited-users-access/?permission_level=${role}&page_size=100`
    );
    responseData = data.results;
    totalItems = data.count;

    dispatch({
      type: GET_ROLES_PERMISSIONS_SUCCESS,
      payload: {
        items: responseData,
        totalItems: totalItems,
        next: responseData.next,
      },
    });
  } catch (error) {
    dispatch({ type: GET_ROLES_PERMISSIONS_FAILURE });
    dispatch(showAlert("danger", "Error", error.message));
  }
};

export const updateRolePermission = (data) => async (dispatch, getState) => {
  try {
    await API.postRequest(`/api/v1/invited-users-permissions/`, data);

    dispatch(
      showAlert("success", "Success", "Permissions successfully updated!")
    );
  } catch (error) {
    dispatch(showAlert("danger", "Error", error.message));
  }
};

export const inviteUser = (data, id) => async (dispatch, getState) => {
  try {
    if (id) {
      await API.patchRequest(`/api/v1/invited-users/${id}`, data);
      await dispatch(getInvitedUsers(1));

      dispatch(showAlert("success", "Success", "User successfully updated!"));
    } else {
      let result = await API.postRequest(`/api/v1/invited-users/`, data);
      await dispatch(getInvitedUsers(1));

      dispatch(showAlert("success", "Success", "User successfully invited!"));
    }

    return true;
  } catch (error) {
    dispatch(showAlert("danger", "Error", error.message));
  }
};

export const resetPasswordInvitedUser = (id) => async (dispatch, getState) => {
  try {
    await API.postRequest(`/api/v1/invited-users/${id}/reset-password/`);

    dispatch(
      showAlert("success", "Success", "Reset password email successfully sent!")
    );

    return true;
  } catch (error) {
    dispatch(showAlert("danger", "Error", error.message));
  }
};

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    await API.patchRequest(`/api/v1/invited-users/${id}/`,{is_active:false});
    dispatch(getInvitedUsers(1));

    dispatch(showAlert("success", "Success", "User successfully deleted!"));
    return true;
  } catch (error) {
    dispatch(showAlert("danger", "Error", error.message));
  }
};

export const restoreUser = (id) => async (dispatch, getState) => {
  try {
    await API.patchRequest(`/api/v1/invited-users/${id}/`,{is_active:true});
    dispatch(getInvitedUsers(1));

    dispatch(showAlert("success", "Success", "User successfully resored!"));
    return true;
  } catch (error) {
    dispatch(showAlert("danger", "Error", error.message));
  }
};
