import styled, { css } from 'styled-components';

export const Relative = styled.div`
  position: relative;
`;

export const Layout = styled.div`
  display: flex;
  flex-grow: 1;
  background: #f9fafc;
  min-height: 100%;

  @media (max-width: 900px) {
    display: block;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

export const HeaderContainer = styled.div`
  position: fixed;
  width: 100%;
  z-index: 3;
  @media (max-width: 900px) and (min-width: 768px) {
   transform: translateY(-65px);
  }
`;

export const Header = styled.header`
  border-bottom: 1px solid #ced5df;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #0D2A44;
  box-sizing: border-box;
  font-size: 14px;
  color: #3f4857;
  font-weight: 600;
  min-height: ${({ theme }) => theme.header.height}px;
  z-index: 3;

  img {
    height: 40px;
  }

  a {
    font-size: 14px;
  }

  .nav-item {
    margin-right: 15px;

    &:last-of-type {
      margin: 0;
    }
  }
  .navbar-light .navbar-nav .nav-link {
    color: white;
    &:hover {
      color: white;
    }
  }
  .navbar-nav {
    flex-direction: row;
  }
  .navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: white;
  }
  a svg{
    margin-right: 5px;
  }
 
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{
    color: #00AEEF
  }
`;

export const Navbar = styled.nav`
  width: ${({ theme, size }) => (size === 'large' ? theme.sidebar.large : theme.sidebar.width)}px;

  ${({ anchor }) =>
    anchor === 'right'
      ? css`
          border-left: 1px solid #ced5df;
        `
      : css`
          border-right: 1px solid #ced5df;
        `}

  background: #fff;
  position: fixed;
  height: 100%;
  flex-grow: 1;

  ${(props) => (props.anchor === 'right' ? 'right: 0;' : null)}

  a.nav-link {
    display: block;
    text-decoration: none;
    font-size: 14px;
    color: #3f4857;
    font-weight: 600;
    padding: 1rem;

    &:hover {
      color: #00AEEF;
    }
  }
`;

export const Brand = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.header.height}px;
`;

export const Sidebar = styled.div`
  min-width: ${({ theme, size }) => (size === 'large' ? theme.sidebar.large : theme.sidebar.width)}px;
`;

export const Content = styled.div`
  margin-top: ${({ theme }) => theme.header.height}px;
  flex-grow: 1;
`;
