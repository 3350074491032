import React, { useState, useEffect, forwardRef } from 'react';
import styled from 'styled-components';
import Flex from '../../../components/_styles/Flex';
import { EmptyState } from '../../../components/EmptyState/EmptyState';
import { ReactComponent as EmptyStateImg } from '../../../assets/empty-state-generic.svg';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const Modal = styled.div`
  display: ${props => props.showModal ? 'block' : 'none'};
  position: fixed;
  z-index: 3; 
  top: 0;
  left: 0;
  padding-top: 80px;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  animation: changeWidth 8s ease-in-out infinite;
  background: rgba(12, 41, 69, 0.38);
  backdrop-filter: blur(10px);
  border-right: 1px solid whitesmoke;
`
const ModalContentContainer = styled.div`
  max-width: 940px;
  width: 70%;
  min-height: 500px;
  height: 60%;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 70%;
  max-width: 760px;
  height: 400px;
  margin: 0 auto;
  border-radius: 10px;
  @media (max-width: 768px) {
    margin: 0;
  }
`

const CloseBtn = styled.span`
  display: inline-block;
  float: left;
  margin-left: 40px;
  margin-top: 22px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #CE2030;
  &:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`

const TitleText = styled.div`
  padding-top: 23px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #000000;
`

export const DatePickerContainer = styled(Flex)`
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`

export const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D2D3D7;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
`

export const DateContainer = styled(Flex)`
  margin-right: 15px;
`
const useStyles = makeStyles({
  link: {
    color: 'black',
    textDecoration: 'underline'
  }
})

function ExportCohortModal({ showModal, closeModal, heading = 'Your export is currently processing.', description = '' }) {
  const classes = useStyles();
  
  const onCloseModal = (e) => {
    e.stopPropagation();
    closeModal();
  };

  return (
    <Modal
      showModal={showModal} 
    >
      <ModalContentContainer>
        <CloseBtn
          onClick={onCloseModal}>&times; Close
        </CloseBtn>
        <ModalContent>

          <div>
            <EmptyState height='20vh' title={''} img={() => <EmptyStateImg />}>
            </EmptyState>
            <TitleText>
              {heading}
            </TitleText>
            <TitleText>
              {description || 
                <>When it is ready, it will be available for download on the <Link to="/auth/radAdmin/exports" className={classes.link}> exports page </Link >.</>
              }
            </TitleText>
          </div>

        </ModalContent>
      </ModalContentContainer>
    </Modal>
  );
}
export default ExportCohortModal;
