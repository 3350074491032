import React from 'react';
import styled from 'styled-components';
import { media } from '../../../theme';

const Image = styled.img`
  height: ${(props) => props.height}em;
  display: inline !important;
  max-width: 50px;
  max-height: 50px;
  margin: 0 10px 40px 0;

  ${media.md`
    margin: ${(props) => props.margin}px;
  `}

  @media (max-width: 991px) {
    max-width: 50px;
  }
`;

export const OverdueIcon = () => (
    <Image
      id="Overdue"
      src={`${process.env.PUBLIC_URL}/${'Overdue.png'}`}
      alt="Within Health Overdue"
    />
);
export const FollowUpIcon = () => (
    <Image
      id="FollowUp"
      src={`${process.env.PUBLIC_URL}/${'Follow-up.png'}`}
      alt="Within Health FollowUp"
    />
);
export const PatientsIcon = () => (
    <Image
      id="Patients"
      src={`${process.env.PUBLIC_URL}/${'patients.png'}`}
      alt="Within Health PatientsIcon"
    />
);
export const RevenueIcon = () => (
    <Image
      id="RevenueIcon"
      src={`${process.env.PUBLIC_URL}/${'Revenue.png'}`}
      alt="Within Health RevenueIcon"
    />
);

export const RedPerson = () => (
  <svg width="14" height="25" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.2516 5.6674C10.7699 5.6674 11.2469 5.74167 11.6828 5.89027C12.9765 6.37346 13.7404 7.29177 13.7501 8.31577V15.3694C13.5074 16.4907 11.7777 16.1858 11.7358 15.3694V8.7091C11.5817 8.32929 10.5933 8.48677 10.5697 8.7091V23.9963C10.3184 25.5573 7.59999 25.0991 7.53067 23.9701V14.609C7.35685 14.0052 6.21289 14.2795 6.18782 14.6352C6.23633 17.7467 6.22317 20.8584 6.22317 23.9701C5.95206 25.5231 3.2469 25.1257 3.18412 23.9963L3.1488 8.7091C2.99037 8.33748 2.07453 8.48002 2.0533 8.7091V15.3694C1.81061 16.4907 0.0809521 16.1858 0.0390625 15.3694V8.31577C0.0635168 7.33544 0.547717 6.35333 1.69993 5.89027C2.07688 5.74167 2.53627 5.66738 3.07811 5.66738L10.2516 5.6674ZM10.216 2.4641C10.216 3.825 8.72921 4.92823 6.8952 4.92823C5.06121 4.92823 3.57444 3.825 3.57444 2.4641C3.57441 1.10323 5.06118 0 6.89514 0C8.72921 0 10.216 1.10323 10.216 2.4641Z" fill="#F1627F" />
  </svg>
);

export const GreenPerson = () => (
  <svg width="14" height="25" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.36 5.6674C10.8783 5.6674 11.3553 5.74167 11.7912 5.89027C13.0849 6.37346 13.8488 7.29177 13.8585 8.31577V15.3694C13.6158 16.4907 11.8861 16.1858 11.8442 15.3694V8.7091C11.6901 8.32929 10.7017 8.48677 10.6781 8.7091V23.9963C10.4268 25.5573 7.70839 25.0991 7.63907 23.9701V14.609C7.46525 14.0052 6.32129 14.2795 6.29622 14.6352C6.34473 17.7467 6.33156 20.8584 6.33156 23.9701C6.06046 25.5231 3.3553 25.1257 3.29252 23.9963L3.2572 8.7091C3.09877 8.33748 2.18292 8.48002 2.1617 8.7091V15.3694C1.91901 16.4907 0.189351 16.1858 0.147461 15.3694V8.31577C0.171915 7.33544 0.656115 6.35333 1.80833 5.89027C2.18528 5.74167 2.64466 5.66738 3.18651 5.66738L10.36 5.6674ZM10.3244 2.4641C10.3244 3.825 8.83761 4.92823 7.0036 4.92823C5.16961 4.92823 3.68284 3.825 3.68284 2.4641C3.68281 1.10323 5.16958 0 7.00354 0C8.83761 0 10.3244 1.10323 10.3244 2.4641Z" fill="#A0DBAF"/>
</svg>
);