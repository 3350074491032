import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { FormContainer, FormHeader } from './common';
import { ModifiedInput } from './common';
import { Form, FormRow, FormItem, FormLabel } from '../../components/Design/form';
import * as authActions from '../../actions/auth/actions';

const ModifiedFormContainer = styled(FormContainer)`
  width: 100%;
  margin-top: 20px;
  height: 100%;
`;

const BGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
`;

const Submit = styled.button.attrs({ type: 'submit' })`
  padding: 15px 30px;
  border: none;
  outline: none !important;
  border-radius: 5px;
  background: #00AEEF;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
`;

const UpdateDoctorInfo = ({ user, updateDoctorPracticeInfo }) => {
  const [npi, setNpi] = useState(user?.doctor?.npi_number);

  const handleSendUpdateDoctorPracticeInfo = (e) => {
    e.preventDefault();
    updateDoctorPracticeInfo({ userId: user?.id, id: user?.doctor?.id, npi });
  };

  return (
    <ModifiedFormContainer>
      <FormHeader>Practice Information</FormHeader>
      <Form>
        <FormRow>
          <FormItem>
            <FormLabel>NPI</FormLabel>
            <ModifiedInput
              name='npi'
              label='NPI #'
              placeholder='Enter your NPI # here'
              value={npi}
              onChange={(e) => setNpi(e.target.value)}
            />
          </FormItem>
        </FormRow>
        <BGroup>
          <Submit children='Update' primary onClick={(e) => handleSendUpdateDoctorPracticeInfo(e)} />
        </BGroup>
      </Form>
    </ModifiedFormContainer>
  );
};

export default withRouter(connect(null, authActions)(UpdateDoctorInfo));
