import React from 'react';
import styled from 'styled-components';

import Header from './Header';

const Container = styled.div`
  width: 650px;
  margin: 100px auto;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Roboto Condensed;
  font-style: normal;

  h1 {
    margin: 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #0c2945;
  }

  h2 {
    margin: 10px 0 0 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #3f4857;
  }
`;

const ViewConfirm = () => {
  return (
    <>
      <Header activeStep='CONFIRM' />
      <Container>
        <Text>
          <h1 children='Thank you!' />
          <h2 children='We have received your information and will be in touch with you shortly when we have reviewed it!' />
          <h2 children='If you have any questions in the meantime, please email us at support@seewithin.co' />
        </Text>
      </Container>
    </>
  );
};

export default ViewConfirm;
