import { omitBy, snakeCase, isNil } from 'lodash';

const stepTypes = {
  timeDelay: 'time_delay',
  sms: 'sms',
};

export function toSnakeCase(data) {
  return Object.keys(data).reduce((acc, curr) => {
    const key = snakeCase(curr);

    acc[key] = data[curr];

    return acc;
  }, {});
}

export function buildCampaignStepPayload({
  campaignId,
  selectedNumber,
  selectedDelay,
  type,
  title,
  sendFrom,
  content,
  stepNumber,
}) {
  const requestPayload = {
    campaign: campaignId,
    stepNumber,
    stepType: stepTypes[type],
    delayTimeInterval: selectedNumber,
    delayTimeUnit: selectedDelay,
    title,
    sendFrom,
    content,
  };

  return toSnakeCase(omitBy(requestPayload, isNil));
}
