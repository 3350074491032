import {
    GET_APPOINTMENTS_FETCH,
    GET_APPOINTMENTS_RESOLVE,
    GET_APPOINTMENTS_REJECT,
    UPDATE_APPOINTMENT_RESOLVE
} from '../actions/patientAppointments';

const initialState = {
    loading: false,
    items: []
};

export default (state = initialState, action) => {
    switch (action.type) {
    case GET_APPOINTMENTS_FETCH:
        return {
            loading: true,
            items: []
        };
    case GET_APPOINTMENTS_RESOLVE:
        return {
            loading: false,
            items: action.payload
        };
    case GET_APPOINTMENTS_REJECT:
        return initialState;
    case UPDATE_APPOINTMENT_RESOLVE:
        return {
            ...state,
            items: state.items.map((i) => (i.id === action.payload.id ? { ...i, date_time: action.payload.date_time } : i))
        };
    default:
        return state;
    }
};
