import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

const LoadMoreButtonStyled = styled.button`
  margin: 22px auto;
  height: 30px;
  padding: 0;
  border: 0;
  width: 80%;
  color: #ffffff;
  background-color: #0C2945;
  font: normal 14px 'Open Sans', sans-serif;
  border-radius: 5px;
  cursor: ${props => (props.disabled ? '' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.3' : '1')};
`;

const Loader = styled(CircularProgress)`
  vertical-align: middle;
  color: #ffffff !important;
  width: 25px !important;
  height: 25px !important;
`;

const LoadMoreButton = ({ title, handleClick, isLoading = false, isDisabled = false, style={} }) => {
  return (
    <LoadMoreButtonStyled type="button" onClick={handleClick} disabled={isDisabled} style={style}>
      {isLoading ? <Loader/> : title}
    </LoadMoreButtonStyled>
  );
};

export default LoadMoreButton;
