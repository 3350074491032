import React from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, Form as ReduxForm, Field as ReduxField } from 'redux-form';
import styled from 'styled-components';

import { HFlex, VFlex, Button } from '../../PatientProfile/components/Common';
import Section from './Section';
import { Text, Radio, Checkbox } from './Field';

const RadioGroup = styled.div`
  max-width: 200px;

  ${Radio} {
    margin-bottom: 10px;
  }

  ${Radio}:last-child {
    margin-bottom: 0;
  }
`;

const CheckboxGroup = styled.div`
  max-height: ${({ compact }) => (compact ? '130px' : 'unset')};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  ${Checkbox} {
    width: 50%;
    margin-bottom: 10px;
  }

  ${Checkbox}:last-child {
    margin-bottom: 0;
  }
`;

const Form = ({ className, onSubmit, handleSubmit, patient, doctor }) => {
  const isSubmitDisabled = useSelector((state) => {
    const values = state.form['PATIENT_PROFILE_NOTES']?.values ?? {};
    return !(
      Object.values(values.conditions ?? {}).some(Boolean) &&
      Object.values(values.symptoms ?? {}).some(Boolean) &&
      Object.values(values.metal ?? {}).some(Boolean) &&
      values.medicationAllergies
    );
  });

  return (
    <ReduxForm className={className} onSubmit={handleSubmit(onSubmit)}>
      <Section label='Patient Name' required>
        <HFlex>
          <VFlex flex='1' mr='30px'>
            <Text value={patient.fname} name='patient.fname' label='First Name' disabled />
          </VFlex>
          <VFlex flex='1'>
            <Text value={patient.lname} name='patient.lname' label='Last Name' disabled />
          </VFlex>
        </HFlex>
      </Section>
      <Section label='Do any of these conditions apply to the patient?' required>
        <CheckboxGroup compact>
          <ReduxField component={Checkbox} name='conditions["None"]' label='None' />
          <ReduxField component={Checkbox} name='conditions["Cancer"]' label='Cancer' />
          <ReduxField component={Checkbox} name='conditions["Diabetes"]' label='Diabetes' />
          <ReduxField component={Checkbox} name='conditions["Psychiatric disorder"]' label='Psychiatric disorder' />
          <ReduxField component={Checkbox} name='conditions["Asthma"]' label='Asthma' />
          <ReduxField component={Checkbox} name='conditions["Cardiac Disease"]' label='Cardiac disease' />
          <ReduxField component={Checkbox} name='conditions["Hypertension"]' label='Hypertension' />
          <ReduxField component={Checkbox} name='conditions["Epilepsy"]' label='Epilepsy' />
        </CheckboxGroup>
      </Section>
      <Section label='Is the patient experiencing any symptoms?' required>
        <CheckboxGroup compact>
          <ReduxField
            component={Checkbox}
            name='symptoms["Just preventive scan"]'
            label='None - Just a preventative scan'
          />
          <ReduxField component={Checkbox} name='symptoms["Respiratory"]' label='Respiratory' />
          <ReduxField component={Checkbox} name='symptoms["Cardiovascular"]' label='Cardiovascular' />
          <ReduxField component={Checkbox} name='symptoms["Neurological"]' label='Neurological' />
          <ReduxField component={Checkbox} name='symptoms["Muscoloskeletal"]' label='Musculoskeletal' />
          <ReduxField component={Checkbox} name='symptoms["Chest pain"]' label='Chest pain' />
          <ReduxField component={Checkbox} name='symptoms["Cardiac"]' label='Cardiac disease' />
          <ReduxField component={Checkbox} name='symptoms["Blood related"]' label='Hematological' />
          <ReduxField component={Checkbox} name='symptoms["Gastrointestinal"]' label='Gastrointestinal' />
        </CheckboxGroup>
      </Section>
      <Section label='Does the patient have any medication allergies?' required>
        <RadioGroup>
          <ReduxField component={Radio} name='medicationAllergies' label='Yes' />
          <ReduxField component={Radio} name='medicationAllergies' label='None' />
          <ReduxField component={Radio} name='medicationAllergies' label='Not Sure' />
        </RadioGroup>
      </Section>
      <Section label='Does the patient have any metal in their body?' required>
        <CheckboxGroup>
          <ReduxField component={Checkbox} name='metal.pacemaker' label='Pacemaker' />
          <ReduxField component={Checkbox} name='metal.aneurysmClips' label='Aneurysm clips' />
          <ReduxField component={Checkbox} name='metal.jointReplacement' label='Joint replacement' />
          <ReduxField component={Checkbox} name='metal.earImplants' label='Cochlear ear implants' />
          <ReduxField component={Checkbox} name='metal.piercings' label='Body piercings' />
          <ReduxField component={Checkbox} name='metal.icd' label='Implantable cardioverter defibrillator’s (ICD’s)' />
          <ReduxField component={Checkbox} name='metal.none' label='None' />
        </CheckboxGroup>
      </Section>
      <Section label='Physician Name' required>
        <HFlex>
          <VFlex flex='1' mr='30px'>
            <Text value={doctor.fname} name='doctor.fname' label='First Name' disabled />
          </VFlex>
          <VFlex flex='1'>
            <Text value={doctor.lname} name='doctor.lname' label='Last Name' disabled />
          </VFlex>
        </HFlex>
      </Section>
      <HFlex justifyContent='flex-end' alignItems='center'>
        <Button disabled={isSubmitDisabled} children='Save' type='submit' />
      </HFlex>
    </ReduxForm>
  );
};

export default reduxForm({ form: 'PATIENT_PROFILE_NOTES' })(
  styled(Form)`
    max-width: 650px;
  `
);
