import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ReactComponent as RemoveIcon } from "../../../../assets/remove.svg";
import { Heading, Text } from './ActionBasedTrigger';
import { Prompt } from 'react-router';
import { Button } from './common';
import {replace, capitalize, isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { Label } from '../../../../components/Dialog/EditFormCenter';
import { makeStyles } from '@material-ui/core/styles';
import { segmentConditionOptions } from './ActionBasedTrigger';
import Dropdown from '../../SharedComponents/Dropdown';
import { getCohorts, getCampaignAttributesChoices } from '../../../../actions/radminUploads';
import { SelectedItemContainer, IconContainer, Text as TitleText } from '../../../RadAdmin/SharedComponents/Dropdown';
import { DownArrow } from '../../../RadAdmin/SharedComponents/Icons';

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: '13px',
        color: '#3F4857'
    },
    formControlDropdown: {
        width: '35%',
        position: 'relative'
    },
    timeBasedInputContainer: {
        display: 'flex',
        marginBottom: '5rem',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    },
    frequencyText: {
        padding: 10,
        textAlign: 'center',
        width: '100%',
        fontSize: 16,
        '&:hover': {
            background: 'whitesmoke',
            cursor: 'pointer'
        }
    },
    activeFrequency: {
        background: 'whitesmoke'
    }
}))

const DateTriggerDropDown = styled.div`
    border: 1px solid #C2CCD9;
    border-radius: 5px;
    display: flex;
    width: 100%;
    position: absolute;
    background-color: white;
    z-index: 1;
    margin-top: 10px;
`
const DaysInput = styled.input`
    width: 100%;
    border: none;
    text-align: center;
    font-size: 16px;
    background: whitesmoke;

`
const FrequencyContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const DelteIconContainer = styled.span`
  margin-left: 10px;
  cursor: pointer;
`

function TimeBasedTrigger({cohorts, segment, segmentHandle, timeTrigger, timeTriggerHandle, additionalSegmentConditions, setAdditionalSegmentConditions, shouldBlockNavigation}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const daysOffsetRef = useRef();

    const totalCohorts = useSelector(state => state.radAdmin.cohorts.totalItems)
    const dateAttributes = useSelector(state => state.radAdmin.campaigns.attributeChoices.data?.dateAttributes);
    const offsets = useSelector(state => state.radAdmin.campaigns.attributeChoices.data?.offset)
    const frequency = useSelector(state => state.radAdmin.campaigns.attributeChoices.data?.frequency)

    const [page, setPage] = useState(1);
    const [trigger, setTrigger] = useState({
        isClickedOnCondition: false,
        isClickedCohortDropdown: false

    })

    useEffect(() => {
        dispatch(getCohorts(page))
        dispatch(getCampaignAttributesChoices())
    }, []);

    useEffect(() => {
        if(isEmpty(additionalSegmentConditions) && segment.cohortTitle){
            segmentHandle({...segment, showAddSegmentBtn: true})
        }
        else{
            if(!isEmpty(additionalSegmentConditions) && additionalSegmentConditions[additionalSegmentConditions.length - 1].cohortTitle){
                segmentHandle({...segment, showAddSegmentBtn: true})
            }
        } 

    }, [JSON.stringify(segment), JSON.stringify(additionalSegmentConditions)]);

    const toBottomHandle = () => {
        if(totalCohorts !== cohorts.length){
            let nextPage = page + 1
            dispatch(getCohorts(nextPage));
            setPage(nextPage)
        }
    }

    const addAdditionalSegmentsHandle = () => {
        let additionalSegments = additionalSegmentConditions
        let obj = {
            condition: 'Is Not',
            cohortTitle: '',
            cohortId: null,
            isClickedOnCondtition: false,
            isClickedOnCohort: false
        }
        additionalSegments = [...additionalSegments, obj]
        setAdditionalSegmentConditions(additionalSegments);
        segmentHandle({...segment, showAddSegmentBtn: false})
    }

    return (
        <>
           <Prompt
                when={shouldBlockNavigation}
                message='You have unsaved changes, are you sure you want to leave?'
            />
        <div>
            <Heading>Time Based Trigger Condition</Heading>
            <div className={classes.timeBasedInputContainer}>
                <div className={classes.formControlDropdown}>
                    <Label className={classes.title}>People will enter this campaign...</Label>
                    <SelectedItemContainer 
                        onClick={() => timeTriggerHandle({
                            ...timeTrigger, 
                            isClickedDaysOffset: !timeTrigger.isClickedDaysOffset
                        })}
                    >
                        <TitleText fontsize="16px">{`${timeTrigger.daysOffset || 'Only once'}, ${timeTrigger.frequency ||'on this date'}`}</TitleText>
                        <IconContainer><DownArrow /></IconContainer>
                    </SelectedItemContainer>
                 {timeTrigger.isClickedDaysOffset &&   
                    <DateTriggerDropDown>
                        <div style={{border: '1px solid #C2CCD9', borderRadius: 5, width: '100%'}}>
                            <Heading style={{textAlign: 'center', margin: '3px 0px'}}>Days</Heading>
                            <form onSubmitCapture={(e) => {
                                e.preventDefault();
                                if(daysOffsetRef.current.value && timeTrigger.frequency){
                                    timeTriggerHandle({...timeTrigger, isClickedDaysOffset: false})
                                }
                            }}>
                            <DaysInput 
                                type="number" 
                                placeholder="0-365" 
                                value={timeTrigger.daysOffset}
                                ref={daysOffsetRef}
                                onChange={(e) =>{ 
                                    e.preventDefault()
                                    timeTriggerHandle({
                                    ...timeTrigger,
                                    daysOffset: e.target.value,
                                })
                            }}/>
                            </form>
                        </div>
                        <FrequencyContainer>
                            {frequency && frequency.map((f, i) => (
                                <TitleText 
                                    key={i} 
                                    value={timeTrigger.frequency}
                                    className={`${classes.frequencyText} ${timeTrigger.frequency === f && classes.activeFrequency}`} 
                                    onClick={()=> {
                                        timeTriggerHandle({
                                            ...timeTrigger, 
                                            frequency: f, 
                                            isClickedDaysOffset: timeTrigger.daysOffset? false: true
                                        })
                                    }}>
                                {capitalize(replace(f, '_', ' '))}
                                </TitleText>
                            ))}
                        </FrequencyContainer>
                    </DateTriggerDropDown>}
                </div>
                <div style={{width: '20%'}}>
                  <Dropdown 
                    options={offsets}
                    open={timeTrigger.isClickedOffset}
                    value={timeTrigger.offset}
                    clickHandler={() => timeTriggerHandle({ 
                        ...timeTrigger, 
                        isClickedOffset: !timeTrigger.isClickedOffset
                    })}
                    onChange={(value) => {
                        timeTriggerHandle({...timeTrigger, isClickedOffset: false, offset: value})
                    }}
                />
                </div>
                <div style={{width: '35%'}}>
                <Dropdown 
                    options={dateAttributes && dateAttributes}
                    open={timeTrigger.isClickedDateAttribute}
                    value={timeTrigger.dateAttribute}
                    placeholder='Choose a attribute'
                    clickHandler={() => timeTriggerHandle({ 
                        ...timeTrigger, 
                        isClickedDateAttribute: !timeTrigger.isClickedDateAttribute
                    })}
                    onChange={(value) => {
                        timeTriggerHandle({...timeTrigger, isClickedDateAttribute: false, dateAttribute: value})
                    }}

                />
                </div>
            </div>


            <Heading>Segment Conditions</Heading>
            <Text marginBottom="0px">Check to make sure people match conditions</Text>
         
            <div style={{ width: '55%', display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                <div style={{ width: '20%'}}>
                <Dropdown 
                    options={segmentConditionOptions}
                    clickHandler={() => setTrigger({...trigger,isClickedOnCondition: !trigger.isClickedOnCondition})}
                    open={trigger.isClickedOnCondition}
                    value={segment.condition}
                    onChange={(value) => {
                        setTrigger({...trigger, isClickedOnCondition: false})
                        segmentHandle({...segment, condition: value})
                    }}
                />
                </div>
                <div style={{width: '70%'}}>
                <Dropdown 
                    options={cohorts.map(cohort => cohort.cohort_title)}
                    clickHandler={() => setTrigger({...trigger,isClickedCohortDropdown: !trigger.isClickedCohortDropdown})}
                    open={trigger.isClickedCohortDropdown}
                    value={segment.cohortTitle}
                    onChange={(value) => {
                        let { id } = cohorts.find(x => x.cohort_title === value)
                        setTrigger({...trigger, isClickedCohortDropdown: false})
                        segmentHandle({...segment, cohortTitle: value, cohortId: id})
                    }}
                    bottomHandle={toBottomHandle}
                    placeholder='Choose cohort'
                />
                </div>
              
            </div>
            {
                !isEmpty(additionalSegmentConditions) && additionalSegmentConditions.map((additionalSegment, i) => {
                    return (
                        <div style={{marginTop: '15px', display: 'flex', alignItems: 'center'}} key={i}>
                        <div
                            style={{ 
                                width: '55%',
                                display: 'flex', 
                                justifyContent: 'space-between'
                            }}
                        >
                        <div style={{ width: '20%'}}>
                            <Dropdown 
                                options={segmentConditionOptions}
                                clickHandler={() => {
                                    let arr = [...additionalSegmentConditions]
                                    arr[i] = {...additionalSegment, isClickedOnCondtition: !additionalSegmentConditions[i].isClickedOnCondtition}
                                    setAdditionalSegmentConditions(arr)
                                }}
                                open={additionalSegmentConditions[i].isClickedOnCondtition}
                                value={additionalSegment.condition}
                                onChange={(value) => {
                                    let arr = [...additionalSegmentConditions]
                                    arr[i] = {...additionalSegment, isClickedOnCondtition: false, condition: value}
                                    setAdditionalSegmentConditions(arr)
                                }}
                            />
                        </div>
                        <div style={{width: '70%'}}>
                            <Dropdown 
                                options={cohorts.map(cohort => cohort.cohort_title)}
                                clickHandler={() => {
                                    let arr = [...additionalSegmentConditions]
                                    arr[i] = {...additionalSegment, isClickedOnCohort: !additionalSegment.isClickedOnCohort}
                                    setAdditionalSegmentConditions(arr)
                                }}
                                open={additionalSegmentConditions[i].isClickedOnCohort}
                                value={additionalSegment.cohortTitle}
                                onChange={(value) => {
                                    let { id } = cohorts.find(x => x.cohort_title === value)
                                    let arr = [...additionalSegmentConditions]
                                    arr[i] = {...additionalSegment, isClickedOnCohort: false, cohortId: id, cohortTitle: value}
                                    setAdditionalSegmentConditions(arr)
                                }}
                                bottomHandle={toBottomHandle}
                                placeholder='Choose cohort'
                            />
                        </div>
                        
                    </div>
                    <DelteIconContainer 
                    onClick={() => { 
                        let arr = [...additionalSegmentConditions]
                        arr.splice(i, 1)
                        setAdditionalSegmentConditions(arr)
                    }}><RemoveIcon/>
                </DelteIconContainer>
                </div>
                )})
            }
                {segment.showAddSegmentBtn &&
                    <Button 
                        color="white"
                        bgColor="#7F8996" 
                        onClick={addAdditionalSegmentsHandle}
                        style={{
                        margin: '12px 0px'
                        }}
                    >
                    Add Additional Segment Conditions
                    </Button>
                }
             
        </div>
        </>
    )
}

export default TimeBasedTrigger
