import React from 'react'
const RoundedBar = (props) => {
    const { x, y, width, height, fill } = props;
  
    return (
      <g>
        <rect x={x} y={y} width={width} height={height} fill={fill} rx={2} ry={2} />
      </g>
    );
  };
  
  export default RoundedBar