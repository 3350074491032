import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { writePrescription } from '../../../actions/doctor/index';
import { getPrescription, toggleB2BPrescription, toggleB2BPrescriptionModal } from '../../../actions/patientProfile';
import ConfirmPrescription from './ConfirmPrescription';
import PrescriptionRow from './PrescriptionRow';
import {
  Section,
  Button,
  VFlex,
  StyledTable,
  StyledTableHeading,
  StyledTableBody,
  HeaderWrapperLeft,
  HeaderWrapperRight,
  HeaderWrapperCenter,
  StyledTableHead
} from './Common';

const StyleButton = styled(Button)`
  margin-top: 20px;
`;

const Prescriptions = () => {
  const dispatch = useDispatch();

  const appointmentId = useSelector((state) => state.patientProfile.appointments.scan?.id);
  const prescriptions = useSelector((state) => state.patientProfile.prescriptions);

  const isNotesSubmitted = useSelector(
    (state) =>
      state.patientProfile.healthHistory?.medical_conditions.length &&
      state.patientProfile.appointments.prescan?.symptoms.length
  );

  const isB2BPrescriptionEnabled = useSelector(
    (state) => state.constants.featureFlags.find((f) => f.name === 'Doctor-b2b-prescription-order')?.value
  );

  const isPrescriptionModalOpened = useSelector((state) => state.patientProfile.b2bPrescription.modalOpened);

  const onSubmit = useCallback(() => {
    dispatch(writePrescription(appointmentId));
    dispatch(toggleB2BPrescriptionModal(false));
  }, [dispatch, appointmentId]);

  const onCancel = useCallback(() => {
    dispatch(toggleB2BPrescriptionModal(false));
  }, [dispatch]);

  const onClickWrite = useCallback(() => {
    if (isB2BPrescriptionEnabled) {
      return dispatch(toggleB2BPrescription(true));
    }

    dispatch(toggleB2BPrescriptionModal(true));
  }, [dispatch, isB2BPrescriptionEnabled]);

  const onClickPrescription = useCallback(
    (prescription) => {
      dispatch(getPrescription(prescription.id));
    },
    [dispatch]
  );

  return (
    <Section title='prescription'>
      {
        <VFlex height='75%' justifyContent='center'>
          {prescriptions.length > 0 && (
            <StyledTable>
              <StyledTableHeading>
                <StyledTableHead>
                  <HeaderWrapperLeft children='Scan type' />
                </StyledTableHead>
                <StyledTableHead>
                  <HeaderWrapperCenter children='Date' />
                </StyledTableHead>
                <StyledTableHead>
                  <HeaderWrapperRight children='Status' />
                </StyledTableHead>
              </StyledTableHeading>
              <StyledTableBody>
                {prescriptions.map((p) => (
                  <PrescriptionRow key={p.id} prescription={p} onClick={onClickPrescription} />
                ))}
              </StyledTableBody>
            </StyledTable>
          )}
          <StyleButton disabled={!isNotesSubmitted} children='Write Prescription' onClick={onClickWrite} />
          <ConfirmPrescription isOpen={isPrescriptionModalOpened} onSubmit={onSubmit} onCancel={onCancel} />
        </VFlex>
      }
    </Section>
  );
};

export default Prescriptions;
