import { getPreciseDistance } from 'geolib';

export const GOOGLE_MAP_API_KEY = 'AIzaSyAcrC-r8zPne-jYn_QAAH6N8KGewzKUiLk';

export const findGeocodeByAddress = async (address) => {
    try {
        const data = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_MAP_API_KEY}&address=${encodeURIComponent(
                address
            )}`
        ).then((res) => res.json());

        if (data.status === 'OK') {
            return {
                latitude: data.results[0].geometry.location.lat,
                longitude: data.results[0].geometry.location.lng
            };
        }

        return null;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const filterGeocodesInRadius = (points, center, radius) => {
    return points.reduce((acc, point) => (getPreciseDistance(point, center) <= radius ? [...acc, point] : acc), []);
};
