import React, { useState } from "react";
import { Card } from "../../../styles";
import styled from "styled-components";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";
import moment from "moment";
import { isNull } from "lodash";
import Filter from "./Filter";
import { RemoveRecoIcon } from "../../../components/Dialog/Icon";
import { useHistory } from "react-router";

import Select, { components } from "react-select";
import { Spinner } from "react-bootstrap";

const Heading = styled.p`
  font-weight: 700;
  color: #0c2945;
  font-size: 21px;
  font-family: Roboto Condensed;
  line-height: 24px;
  margin: 0;
`;
const Header = styled.div`
// width: 90%;
display: flex;
align-items: center;
justify-content: space-between;
margin: 10px 0px;
margin-left: 36px;
`;
export const LegendItem = styled.span`
  width: 50px;
  height: 6px;
  background-color: ${(props) => (props.color ? props.color : "black")};
`;
export const StatsText = styled.p`
    margin: 0;
    font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")} 
    line-height: 13.62px;
    color: #3F4857;
    margin-left: 5%;
    font-weight: 600;
    white-space:nowrap;
`;
const StatsTextContainer = styled.div`
  display: flex;
  align-items: center;
  width: 600px;
  gap: 15px;
  flex-wrap: wrap;
`;
const BarContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;
const ChartContainer = styled.div`
  height: 335px;
`;
const LabelContainer = styled.div`
  background-color: #e6e9f1;
  border-radius: 3px;
  width: 120px;
  padding: 15px 10px;
`;
const LabelDate = styled.p`
  margin: 0 0 6px 0;
  color: #00aeef;
  font-size: 12px;
  line-height: 12.57px;
  font-weight: 700;
  font-family: Roboto Condensed;
  text-align: left;
`;
const StatsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 3px 0px;
`;
const Square = styled.span`
    width: 15px;
    height: 15px;
    border-radius: 5px;
    margin-right: 5px
    background-color: ${(props) => props.bgcolor}
`;
const Stats = styled.p`
    margin: 0px
    color: #000000;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
`;
const LeftContainer = styled.div`
display: flex;
align-items: center;
justify-content;
flex-wrap:wrap;
gap:30px;

`;
const ResetFilterIcon = styled.span`
  cursor: pointer;
  margin-right: 10px;
`;
const FilterContainer = styled.div`
display: flex;
width: 227px;
align-items: center;
flex-direction: column;
& > div {
  padding: 0px;
  width: 100%;
  & > div {
    width: 100%;
    height: 38px;
    & > span > div > .select__control {
      height: 38px;
    }
    & > span > div {
      height: 38px;
    }
  }
}
`;
const options = [
  { value: "Last 7 days", label: "Last 7 days" },
  { value: "Last 30 days", label: "Last 30 days" },
  { value: "Last 3 months", label: "Last 3 months" },
  { value: "Last 12 months", label: "Last 12 months" },
  { value: "Custom date range", label: "Custom date range" },
];

const directionOptions = [
  { value: "all", label: "All Emails" },
  { value: "sent", label: "Sent" },
  { value: "delivered", label: "Delivered" },
  { value: "opened", label: "Opened" },
  { value: "clicked", label: "Clicked" },
  { value: "spammed", label: "Spammed" },
  { value: "failed", label: "Failed" },
  { value: "dropped", label: "Dropped" },
  { value: "bounced", label: "Bounced" },
  { value: "unsubscribed", label: "Unsubscribed" },
  { value: "converted", label: "Converted" },
  { value: "attempted", label: "Attempted" },
];

const SelectContainer = styled.div`
  width: 227px;
`;

const SelectDropdownTitle = styled.div`
  margin-bottom: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6D6E6E;
  text-transform: uppercase;
  text-align:left;
`;


const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        <span style={{ marginRight: 5, paddingTop: 5 }}>
          <input
            type="radio"
            checked={props.isSelected}
            onChange={() => null}
            style={{ marginTop: 5 }}
          />{" "}
        </span>
        <label>{props.label}</label>
      </components.Option>
    </>
  );
};

export const CustomTooltip = ({ active, payload, label, ...props }) => {
  if (active && payload) {
    return (
      <LabelContainer>
        <LabelDate>{moment(new Date(label)).format("ll")}</LabelDate>
        {payload.map((val, i) => (
          <StatsContainer key={i}>
            <Square bgcolor={val.color} />
            <Stats>{`${val.dataKey}:${val.value}`}</Stats>
          </StatsContainer>
        ))}
      </LabelContainer>
    );
  }
  return null;
};

function EmailAnalytics({
  setSelectedDate,
  selectedDate,
  setPage,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  resetFilter,
  selectedDirection,
  setSelectedDirection,
  messages,
}) {
  const analyticsCounts = useSelector(
    (state) => state.radAdmin.emails.analytics?.counts
  );
  const analyticsData = useSelector(
    (state) => state.radAdmin.emails.analytics?.data
  );
  const loading = useSelector(state => state.radAdmin.emails.analytics?.isLoading);

  const history = useHistory();

  let params = new URLSearchParams(history.location.search);
  let view = params.get("view");

  let timeframe = view === "email" ? params.get("timeframe") || "Last 30 days" : "Last 30 days";
  let status = params.get("email-status");
  let timeframeItem = options.find((itm) => itm.value === timeframe);
  const statusItem = directionOptions.find((itm) => itm.value === status);
  if (timeframe && !timeframeItem) {
    timeframeItem = {
      value: "Custom date range",
      label: "Custom date range",
    };
  }
  const [filterValue, setFilterValue] = useState(timeframeItem || null);
  const [statusValue, setStatusValue] = useState(statusItem || null);

  React.useEffect(() => {
    if (filterValue) {
      var date = new Date();
      let requiredDate;
      let lastMoment;
      let params = new URLSearchParams(history.location.search);
      let status = params.get("email-status");
      let boundFilter = status ? `&email-status=${status}` : "";
      let initialSearchValue  = params.get('searchEmail');
      let searchFilter = initialSearchValue? `&searchEmail=${initialSearchValue}` : "";
      setPage(1);
      if (filterValue.value === "Custom date range") return;
      setStartDate("");
      setEndDate("");
      history.push({
        pathname: "/auth/radAdmin/messages/deliveries",
        search: `?timeframe=${filterValue.value}${boundFilter}&view=email${searchFilter}`,
      });
      if (filterValue.value === "Last 7 days") {
        lastMoment = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
      } else if (filterValue.value === "Last 30 days") {
        lastMoment = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000);
      } else if (filterValue.value === "Last 3 months") {
        lastMoment = new Date(date.getTime() - 90 * 24 * 60 * 60 * 1000);
      }
      if (filterValue.value === "Last 12 months") {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear() - 1;
        requiredDate = `${month}/${day}/${year}`;
        setSelectedDate(requiredDate);
        return;
      }
      let day = lastMoment.getDate();
      let month = lastMoment.getMonth() + 1;
      let year = lastMoment.getFullYear();
      requiredDate = `${month}/${day}/${year}`;
      setSelectedDate(requiredDate);
    }
  }, [filterValue, history]);

  React.useEffect(() => {
    if (statusValue) {
      setSelectedDirection(statusValue.value);
      setPage(1);
      let params = new URLSearchParams(history.location.search);
      let timeframe = params.get("timeframe");
      let durationFilter = timeframe ? `timeframe=${timeframe}&` : "";
      let initialSearchValue  = params.get('searchEmail');
      let searchFilter = initialSearchValue? `&searchEmail=${initialSearchValue}` : "";
      history.push({
        pathname: "/auth/radAdmin/messages/deliveries",
        search: `?${durationFilter}email-status=${statusValue.value}&view=email${searchFilter}`,
      });
    }
  }, [statusValue, history]);

  React.useEffect(() => {
    if (startDate && endDate) {
      let params = new URLSearchParams(history.location.search);
      let status = params.get("email-status");
      let boundFilter = status ? `&email-status=${status}` : "";
      let initialSearchValue  = params.get('searchEmail');
      let searchFilter = initialSearchValue? `&searchEmail=${initialSearchValue}` : "";

      let startingDate, endingDate;
      if (startDate) {
        startingDate = moment(startDate).format("MM/DD/YYYY");
      }
      if (endDate) {
        endingDate = moment(endDate).format("MM/DD/YYYY");
      }
      history.push({
        pathname: "/auth/radAdmin/messages/deliveries",
        search: `?timeframe=${startingDate}-${endingDate}${boundFilter}&view=email${searchFilter}`,
      });
    }
  }, [startDate, endDate, history]);

  const statusCount = (status) => {
    let objWithSent = analyticsCounts.find((x) => x.status === "sent");
    let countsWithSent = objWithSent?.id__count || 0;

    let objWithBounced = analyticsCounts.find((x) => x.status === "bounced");
    let countsWithBounced = objWithBounced?.id__count || 0;

    let objWithDelivered = analyticsCounts.find(
      (x) => x.status === "delivered"
    );
    let countsWithDelivered = objWithDelivered?.id__count || 0;

    let objWithSpammed = analyticsCounts.find((x) => x.status === "spammed");
    let countsWithSpammed = objWithSpammed?.id__count || 0;

    let objWithFailed = analyticsCounts.find((x) => x.status === "failed");
    let countsWithFailed = objWithFailed?.id__count || 0;

    let objWithDropped = analyticsCounts.find((x) => x.status === "dropped");
    let countsWithDropped = objWithDropped?.id__count || 0;

    let objWithUnsubscribed = analyticsCounts.find(
      (x) => x.status === "unsubscribed"
    );
    let countsWithUnsubscribed = objWithUnsubscribed?.id__count || 0;

    let objWithConverted = analyticsCounts.find(
      (x) => x.status === "converted"
    );
    let countsWithConverted = objWithConverted?.id__count || 0;

    let objWithOpened = analyticsCounts.find((x) => x.status === "opened");
    let countsWithOpened = objWithOpened?.id__count || 0;

    let objWithClicked = analyticsCounts.find((x) => x.status === "clicked");
    let countsWithClicked = objWithClicked?.id__count || 0;
    
    let objWithAttempted = analyticsCounts.find((x) => x.status === "attempted");
    let countsWithAttempted = objWithAttempted?.id__count || 0;

    if (status === "bounced") {
      return countsWithBounced;
    } else if (status === "sent") {
      return countsWithSent;
    } else if (status === "delivered") {
      return countsWithDelivered;
    } else if (status === "spammed") {
      return countsWithSpammed;
    } else if (status === "failed") {
      return countsWithFailed;
    } else if (status === "dropped") {
      return countsWithDropped;
    } else if (status === "unsubscribed") {
      return countsWithUnsubscribed;
    } else if (status === "converted") {
      return countsWithConverted;
    } else if (status === "opened") {
      return countsWithOpened;
    } else if (status === "clicked") {
      return countsWithClicked;
    } else if (status === "attempted") {
      return countsWithAttempted;
    }
  };

  return (
    <Card style={{marginTop:'27px'}}>
      <Header>
        <LeftContainer>
          <div style={{ display: "flex" }}>
            <Heading>Email Analytics as of:</Heading>
          </div>
          <div style={{display:'flex', gap:'16px'}}>
             <div>
             <FilterContainer>
            <SelectDropdownTitle>Timeframe</SelectDropdownTitle>
            <Filter
              options={options}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setSelectedDate={setSelectedDate}
              type="radio"
            />
            </FilterContainer>

             </div>
             <div>
              <SelectContainer key={"item"}>
                <SelectDropdownTitle>Status</SelectDropdownTitle>
                <Select
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={directionOptions}
                  value={statusValue}
                  onChange={(value) => setStatusValue(value)}
                  styles={{
                    option: (styles, { isSelected }) => ({
                      ...styles,
                      fontSize: "15px",
                      textAlign: "left",
                      height: "40px",
                      fontWeight: isSelected ? 600 : 100,
                      alignItems: "center",
                    }),
                  }}
                  isSearchable={false}
                  components={{
                    Option,
                  }}
                />
              </SelectContainer>
            </div>
          </div>
          
         
        </LeftContainer>
        <StatsTextContainer>
          <>
          {   ( statusValue?.value === 'all' || statusValue?.value ==='sent'  || !statusValue) &&  
          <BarContainer>
          <LegendItem color="#FDBB13" />
          <StatsText>{`Sent (${
            analyticsCounts ? statusCount("sent") : 0
          })`}</StatsText>
          </BarContainer>
          }
            
            {   ( statusValue?.value === 'all' || statusValue?.value ==='delivered'  || !statusValue) &&  
               <BarContainer>
               <LegendItem color="#64C67C" />
               <StatsText>{`Delivered (${
                 analyticsCounts ? statusCount("delivered") : 0
               })`}</StatsText>
             </BarContainer>
            }
          
            {   ( statusValue?.value === 'all' || statusValue?.value ==='opened'  || !statusValue) &&  
               <BarContainer>
               <LegendItem color="#00AEEF" />
               <StatsText>{`Opened (${
                 analyticsCounts ? statusCount("opened") : 0
               })`}</StatsText>
             </BarContainer>
            }
           
            {    ( statusValue?.value === 'all' || statusValue?.value ==='clicked'  || !statusValue) &&  
               <BarContainer>
               <LegendItem color="#4EAC9F" />
               <StatsText>{`Clicked (${
                 analyticsCounts ? statusCount("clicked") : 0
               })`}</StatsText>
             </BarContainer>
            }
           
            {     ( statusValue?.value === 'all' || statusValue?.value ==='spammed'  || !statusValue) &&  
                 <BarContainer>
                 <LegendItem color="#7C7C7C" />
                 <StatsText>{`Spammed (${
                   analyticsCounts ? statusCount("spammed") : 0
                 })`}</StatsText>
               </BarContainer>
            }
        
            {   ( statusValue?.value === 'all' || statusValue?.value ==='failed'  || !statusValue) &&  
               <BarContainer>
               <LegendItem color="#9B64C6" />
               <StatsText>{`Failed (${
                 analyticsCounts ? statusCount("failed") : 0
               })`}</StatsText>
             </BarContainer>
            }
          
            {   ( statusValue?.value === 'all' || statusValue?.value ==='dropped'  || !statusValue) &&  
               <BarContainer>
               <LegendItem color="#CF3131" />
               <StatsText>{`Dropped (${
                 analyticsCounts ? statusCount("dropped") : 0
               })`}</StatsText>
             </BarContainer>
            }
           
            {
                ( statusValue?.value === 'all' || statusValue?.value ==='bounced'  || !statusValue) &&  
               <BarContainer>
               <LegendItem color="#FF5F65" />
               <StatsText>{`Bounced (${
                 analyticsCounts ? statusCount("bounced") : 0
               })`}</StatsText>
             </BarContainer>
            }
           
            {   ( statusValue?.value === 'all' || statusValue?.value ==='unsubscribed'  || !statusValue) &&  
              <BarContainer>
              <LegendItem color="#ADADAD" />
              <StatsText>{`Unsubscribed (${
                analyticsCounts ? statusCount("unsubscribed") : 0
              })`}</StatsText>
            </BarContainer>
            }
           
            {  ( statusValue?.value === 'all' || statusValue?.value ==='conerted'  || !statusValue) &&  
               <BarContainer>
               <LegendItem color="#FF7A00" />
               <StatsText>{`Converted (${
                 analyticsCounts ? statusCount("converted") : 0
               })`}</StatsText>
             </BarContainer>
            }
           
            {
               ( statusValue?.value === 'all' || statusValue?.value ==='attempted'  || !statusValue) &&    <BarContainer>
               <LegendItem color="#DFE0EB" />
               <StatsText>{`Attempted (${
                 analyticsCounts ? statusCount("attempted") : 0
               })`}</StatsText>
             </BarContainer>
            }
         
          </>
        </StatsTextContainer>
      </Header>
      <ChartContainer>
        <ResponsiveContainer width="100%" height="80%">
          {  loading? <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
          <Spinner animation="border" role="status" size="sm" />
          </div> :  <LineChart
            width={730}
            height={250}
            data={analyticsData}
            margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="timestamp"
              domain={["dataMin", "dataMax"]}
              tickFormatter={(value) => {
                let d = new Date(value);
                return moment(d).format("MM/DD");
              }}
            />
            <YAxis dataKey="" allowDecimals={false} />
            <Tooltip content={<CustomTooltip />} />
           { [
             ( statusValue?.value === 'all' || statusValue?.value ==='sent'  || !statusValue) &&
            <Line
              type="monotone"
              dataKey="sent"
              stroke="#FDBB13"
              dot={{ stroke: "#FDBB13", strokeWidth: 6 }}
              strokeWidth={2}
            />
            ,
            ( statusValue?.value === 'all' || statusValue?.value ==='delivered'  || !statusValue) &&
            <Line
              type="natural"
              dataKey="delivered"
              stroke="#64C67C"
              dot={{ stroke: "#64C67C", strokeWidth: 6 }}
              strokeWidth={2}
              animationDuration={300}
            />
            ,
            ( statusValue?.value === 'all' || statusValue?.value ==='opened'  || !statusValue) &&
            <Line
              type="monotone"
              dataKey="opened"
              stroke="#00AEEF"
              dot={{ stroke: "#00AEEF", strokeWidth: 6 }}
              points={[{ x: 10, y: 12, value: 240 }]}
              strokeWidth={2}
            />
            ,
            ( statusValue?.value === 'all' || statusValue?.value ==='clicked'  || !statusValue) &&
            <Line
              type="monotone"
              dataKey="clicked"
              stroke="#4EAC9F"
              dot={{ stroke: "#4EAC9F", strokeWidth: 6 }}
              points={[{ x: 10, y: 12, value: 240 }]}
              strokeWidth={2}
            />
            ,
            ( statusValue?.value === 'all' || statusValue?.value ==='spammed'  || !statusValue) &&
            <Line
              type="monotone"
              dataKey="spammed"
              stroke="#7C7C7C"
              dot={{ stroke: "#7C7C7C", strokeWidth: 6 }}
              points={[{ x: 10, y: 12, value: 240 }]}
              strokeWidth={2}
            />
            ,
            ( statusValue?.value === 'all' || statusValue?.value ==='failed'  || !statusValue) &&
            <Line
              type="monotone"
              dataKey="failed"
              stroke="#9B64C6"
              dot={{ stroke: "#9B64C6", strokeWidth: 6 }}
              points={[{ x: 10, y: 12, value: 240 }]}
              strokeWidth={2}
            />
            ,
            ( statusValue?.value === 'all' || statusValue?.value ==='dropped'  || !statusValue) &&
            <Line
              type="monotone"
              dataKey="dropped"
              stroke="#CF3131"
              dot={{ stroke: "#CF3131", strokeWidth: 6 }}
              points={[{ x: 10, y: 12, value: 240 }]}
              strokeWidth={2}
            />
            ,
            ( statusValue?.value === 'all' || statusValue?.value ==='bounced'  || !statusValue) &&
            <Line
              type="monotone"
              dataKey="bounced"
              stroke="#FF5F65"
              dot={{ stroke: "#FF5F65", strokeWidth: 6 }}
              points={[{ x: 10, y: 12, value: 240 }]}
              strokeWidth={2}
            />
            ,
            ( statusValue?.value === 'all' || statusValue?.value ==='unsubscribed'  || !statusValue) &&
            <Line
              type="monotone"
              dataKey="unsubscribed"
              stroke="#ADADAD"
              dot={{ stroke: "#ADADAD", strokeWidth: 6 }}
              points={[{ x: 10, y: 12, value: 240 }]}
              strokeWidth={2}
            />
            ,
            ( statusValue?.value === 'all' || statusValue?.value ==='converted'  || !statusValue) &&
            <Line
              type="monotone"
              dataKey="converted"
              stroke="#FF7A00"
              dot={{ stroke: "#FF7A00", strokeWidth: 6 }}
              points={[{ x: 10, y: 12, value: 240 }]}
              strokeWidth={2}
            />
            , 
            ( statusValue?.value === 'all' || statusValue?.value ==='attempted'  || !statusValue) &&
            <Line
              type="monotone"
              dataKey="attempted"
              stroke="#DFE0EB"
              dot={{ stroke: "#DFE0EB", strokeWidth: 6 }}
              points={[{ x: 10, y: 12, value: 240 }]}
              strokeWidth={2}
            />
            ,]}
          </LineChart>
         }
        </ResponsiveContainer>
      </ChartContainer>
    </Card>
  );
}

export default EmailAnalytics;
