import React, { useState } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import {
  getSavedSearchesData,
  saveFilterRecommendations,
  saveReportSearchText,
} from "../../../actions/radminUploads";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { showAlert } from "../../../actions/radminUploads";

ReactModal.setAppElement("#root");

const Modal = styled(ReactModal)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 444px !important;
  padding: 20px 24px !important;
  height: 260px !important;

  margin-top: 15% !important;

  @media (max-width: 768px) {
    width: auto !important;
    margin: 30px !important;
    margin-top: calc(15% + 30px) !important;
  }
`;

const Label = styled.label`
  align-self: flex-start;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #3f4857;
`;

const Input = styled.input.attrs({ type: "text" })`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #c2ccd9;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #8692a4;
  height: 38px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
  justify-content: center;
`;

const Button = styled.button`
  width: 120px;
  height: 38px;
  background: ${({ isPrimary, disabled }) =>
    disabled ? "#00AEEF4D" : isPrimary ? "#00AEEF" : "transparent"};
  border-radius: 5px;
  border: ${({ isPrimary, disabled }) => (disabled?"2px solid transparent" : isPrimary ? "2px solid #00AEEF" : "2px solid #CFCFCF")};
  outline: none !important;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${({ isPrimary }) => (isPrimary ? "white" : "#000000")};
  &:hover {
    background: ${({ isPrimary }) => (isPrimary ? "transparent" : "#CFCFCF")};
    color: ${({ isPrimary }) => (isPrimary ? "#00aeef" : "white")};
    border: ${({ isPrimary, disabled }) => ( isPrimary ? "2px solid #00AEEF" : "2px solid #CFCFCF")};

  }
`;

const customStyle = {
  overlay: {
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(12, 41, 69, 0.38)",
    zIndex: 9,
  },
  content: {
    borderRadius: "4px",
  },
};

const SaveModal = ({
  opened,
  onClose,
  selectedFilters,
  setSelectedValues,
  setShowSaveFilter,
  selectedOperators,
  titleForSearch,
  excludeSearch,
  setShowSaveButton,
  setSavedSearches,
  setNextPage,
}) => {
  const [filterSetName, setFilterSetName] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const permission = useSelector((state) => state.auth.user.permission_level); 
  const rolePermissions = useSelector(
     (state) =>state.auth.user.permissions
   );
  const addPermission = rolePermissions?.find(itm=>itm.name ==="Search Report Text")?.permissions.find(itm=>itm.codename === "add_searchreporttext")?.is_authorized;

  const onChangeFilterName = (e) => {
    setFilterSetName(e.target.value);
  };

  const handleFilterSave = async (e) => {
    e.preventDefault();

    if((permission && addPermission) || !permission){
      let selectedFiltersObj = { ...selectedFilters };
      delete selectedFiltersObj.modalityNegation;
      delete selectedFiltersObj.examCodeNegation;
      let list = Object.values(selectedFiltersObj)
        .map((item, index) => {
          if (!item) return null;
          return item;
        })
        .filter(Boolean)
        .flat(10);
      window.analytics.track('Report-Search-Save-Filter-Set-Submit', {title: filterSetName, list: list});
      setLoading(true);
  
      const listData = [
        ...list.map((itm) =>
          itm.category === "exam_code" &&
          selectedOperators?.examCodeOperator?.value === "isNotEqual"
            ? { ...itm, negation: "isNotEqual" }
            : itm.category === "report_modality" &&
              selectedOperators?.reportModalityOperator.value === "isNotEqual"
            ? { ...itm, negation: "isNotEqual" }
            : itm
        ),
      ];
  
      dispatch(
        saveReportSearchText(
          titleForSearch,
          excludeSearch,
          filterSetName,
          listData
        )
      ).then((res) => {
        dispatch(getSavedSearchesData(1)).then((res) => {
          setShowSaveButton(false);
          setLoading(false);
          onClose();
          setSavedSearches(
            res?.results?.map((itm) => ({
              label: itm?.name || itm.search_text,
              value: itm.id,
              category: "search",
              id: itm.id,
              filters: itm.filters || [],
              exclude: itm.neg_search_text,
              search: itm.search_text,
            }))
          );
          if (res?.next) {
            setNextPage(2);
          }
        });
      });
    } else{
      dispatch(
        showAlert('danger', 'Error', `You don't have permission to add search report text`)
      );
    }
  };

  return (
    <Modal
      style={customStyle}
      isOpen={opened}
      onRequestClose={onClose}
      contentLabel="Save Filter Set"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h2
          style={{
            fontSize: "24px",
            color: "#0C2945",
            fontWeight: 700,
            fontFamily: "Roboto Condensed",
          }}
        >
          Save Search
        </h2>
        <p style={{ cursor: "pointer" }} onClick={onClose}>
          x
        </p>
      </div>
      <div style={{width:'100%'}}>
        <h4
          style={{
            fontSize: "18px",
            color: "#0C2945",
            fontWeight: 700,
            fontFamily: "Roboto Condensed",
          }}
        >
          Give your search a name so you can easily find it later
        </h4>
      </div>
      <form style={{ width: "100%" }} onSubmit={handleFilterSave}>
        <Label children="Saved Search Name" htmlFor="filterSet" />
        <Input
          onChange={onChangeFilterName}
          value={filterSetName}
          id="filterSet"
          placeholder="Filter name"
        />
        <ButtonContainer>
          <Button onClick={onClose} children="Cancel" />
          <Button
            type="submit"
            isPrimary={true}
            disabled={!filterSetName}
            children={
              loading ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : (
                "Save"
              )
            }
          />
        </ButtonContainer>
      </form>
    </Modal>
  );
};

export default SaveModal;
