import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { Alert, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

import { Form, FormRow } from '../Design/form';
import { AccountContentContainer, HeadlineCTA, SubmitButton } from './common';
import FormGroup from './common/FormGroup';
import { login } from '../../actions/auth/actions';

const ForgotPassword = styled.div`
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #8692a4;
  margin-top: 4px;
  margin-bottom: 20px;
`;

// const NoAccountText = styled.div`
//   margin-top: 16px;
//   line-height: 18px;
//   text-align: center;
//   font-size: 14px;
//   color: #3f4857;
// `;

const Login = ({ handleSubmit }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const role = useSelector((state) => state.auth.user.role);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const loginError = useSelector((state) => state.auth.loginError);
  const loading = useSelector((state) => state.auth.loading);

  useEffect(() => {
    if (isLoggedIn) {
      if (role === 'doctor') {
        history.push('/auth/appointments');
        return;
      }
      if (role === 'radiology_admin') {
        history.push('/auth/radAdmin/Overview');
        return;
      }

      history.push('/auth/dashboard');
    }
  }, []);

  const onFormSubmit = useCallback(
    ({ email, password }) => {
      dispatch(login({ email, password, history }));
    },
    [dispatch, history]
  );

  return (
    <AccountContentContainer>
      <HeadlineCTA headlineText='Log In' subtext='Welcome back, please login to your account' />
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        {loginError && <Alert variant='danger'>{loginError}</Alert>}
        <FormRow>
          <Field
            component={FormGroup}
            type='text'
            label='Your email'
            name='email'
            placeholder='Enter your email here'
          />
        </FormRow>
        <FormRow>
          <Field
            component={FormGroup}
            type='password'
            label='Your password'
            name='password'
            placeholder='Enter your password here'
          />
        </FormRow>
        <ForgotPassword>
          <Link to='/forgot-password'>Forgot your password?</Link>
        </ForgotPassword>
        <FormRow>
          <SubmitButton type="submit">
            {" "}
            {loading ? (
                <div style={{height:'20px'}}>
                  <Spinner animation="border" role="status" size="sm" />
                </div>
            ) : (
              "Log in"
            )}
          </SubmitButton>        
        </FormRow>
      </Form>
    </AccountContentContainer>
  );
};

const validate = ({ email, password }) => {
  const errors = {};

  if (!email) {
    errors.email = 'Email is required.';
  }

  if (!password) {
    errors.password = 'Password is required.';
  }

  return errors;
};

export default reduxForm({ form: 'LOGIN_FORM', validate })(Login);
