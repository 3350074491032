import API from '../../../libs/api';

async function resetPasswordRequest({ email, dispatch }) {
  try {
    dispatch({
      type: 'PASSWORD_REQUEST',
    });

    await API.postRequest(`/auth/users/reset_password/`, {
      email,
    });

    dispatch({
      type: 'PASSWORD_REQUEST_SUCCESS',
    });
  } catch (err) {
    dispatch({
      type: 'PASSWORD_REQUEST_FAILURE',
    });
  }
}

async function resetPasswordConfirm({
  uid,
  token,
  password,
  history,
  dispatch,
  isLoggedIn,
  logout,
}) {
  try {
    dispatch({
      type: 'PASSWORD_REQUEST',
    });

    await API.postRequest(`/auth/users/reset_password_confirm/`, {
      uid,
      token,
      new_password: password,
    });

    dispatch({
      type: 'PASSWORD_REQUEST_SUCCESS',
    });

    if (isLoggedIn) {
      await logout();
    }

    history.push('/login');
  } catch (err) {
    dispatch({
      type: 'PASSWORD_REQUEST_FAILURE',
      payload: err.response.data.new_password,
    });
  }
}

export const resetPasswordInitialState = {
  isSubmitting: false,
  hasSubmitted: false,
  hasError: false,
  errors: [],
};

function resetPasswordReducer(state, action) {
  switch (action.type) {
    case 'PASSWORD_REQUEST':
      return {
        ...state,
        isSubmitting: true,
      };
    case 'PASSWORD_REQUEST_SUCCESS':
      return {
        ...state,
        hasSubmitted: true,
        isSubmitting: false,
        hasError: false,
      };
    case 'PASSWORD_REQUEST_FAILURE':
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errors: action.payload || [],
      };
    default:
      return state;
  }
}

export { resetPasswordReducer, resetPasswordConfirm, resetPasswordRequest };
