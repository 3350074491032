import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "../../../styles";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import LoadMoreButton from "../../../components/LoadMoreButton";
import { EmptyState } from "../../../components/EmptyState/EmptyState";
import { ReactComponent as EmptyStateImg } from "../../../assets/empty-state-search.svg";
import Box from "../../../components/_styles/Box";
import {
  getRejectedRecommendations,
  getRejectedRecommendationsCount,
  restoreRejectedRecommendation,
} from "../../../actions/radminUploads";
import { makeStyles } from "@material-ui/core/styles";
import { showAlert } from "../../../actions/radminUploads";
import Table from "../../../components/Table/Table";
import DoubleDataCell from "../PatientList/LastScanCell";
import { useTable } from "../../../components/Table/useTable";
import { Link } from "react-router-dom";
import ColumnFilterDropdown from "../FollowUpList/ColumnFilterDropdown";

import { ReactComponent as RestoreIcon } from "../../../assets/restore-icon.svg";
import { ReactComponent as RestoreIconHover } from "../../../assets/restore-icon-hover.svg";

import { useFormik } from "formik";
import { ReactComponent as Search } from "../../../assets/searchIconGrey.svg";
import "react-datepicker/dist/react-datepicker.css";
import RestoreConfirmation from "./RestoreConfirmation";
const cancelIcon = `${process.env.PUBLIC_URL}/${"cancel-circled.svg"}`;

const StyledLink = styled(Link)`
 color:#00AEEF;
 font-size:14px;
 font-weight:400;
 text-decoration:underline;
`

const IconContainerBox = styled.span`
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;

  svg {
    position: absolute;
    top: 0px;
    left: 0;
    transition: opacity 0.1s ease;
  }

  svg.dark {
    opacity: 1;
  }

  svg.light {
    opacity: 0;
  }

  &:hover svg.dark {
    opacity: 0;
  }

  &:hover svg.light {
    opacity: 1;
  }

  .label {
    display: none;
    font-size: 9px;
    font-weight: 700;
    color: ${(props) => props.color};
  }

  &:hover .label {
    display: block;
    position: absolute;
    top: ${(props) => props.startGap || "-40%"};
    line-height: 12px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const useStyles = makeStyles({
  label: {
    padding: "5px 0px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  paper: {
    // Your existing styles for the Popover paper
  },
  arrow: {
    border: "1px solid #A764C6",
    backgroundColor: "#E9D8F1",
  },
});

const HeadingLabel = styled.h1`
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  color: #0c2945;
  margin-bottom: 53px;
`;

const InputContainer = styled.div`
  // width: 100vw;
  // padding: 0px 20px 0px 20px;
  position:relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const SearchIcon = styled.i`
  display: inline-block;
  position: absolute;
  left:10px;
  color: green;
  min-width: 40px;
  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;
const CustomPlaceholder = styled.span`
  position: absolute;
  // margin-left: 100px;
  left: 30px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #666666;
  @media (max-width: 768px) {
    margin-left: 60px;
  }
`;
const Input = styled.input`
  width: 484px;
  background: white;
  border: 1px solid #d2d3d7;
  border-radius: 5px;
  height: 38px;
  font: normal 16px "Roboto Condensed", sans-serif;
  padding-left: 35px;
`;

const ClearButton = styled.button`
  position: absolute;
  right: 10px;
  border-radius: 5%;
  z-index: 1;
  border: none;
  cursor: pointer;
  color: #000000;
  background-color: transparent;
  transform: translateX(2px);
  @media (max-width: 768px) {
    margin-left: -30px;
  }
`;

const toUpperCase = (str) => (str ? str.toUpperCase() : "");

function RestoreRecommendations() {
  const dispatch = useDispatch();
  const history = useHistory();

  const columnData = [
    {
      key: "patientName",
      name: "Patient",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "patientMRN",
      name: "Patient MRN",
      displayCell: (value, cellValue) => (
        <StyledLink
          to={`/auth/radAdmin/followUpList/patients/patientDetails/${cellValue.patientId}`}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
            window.analytics.track(
              "Restore-Recommendation-Task-List-Patient-MRN-Click",
              {
                patientId: cellValue.patientId,
              }
            );
          }}
        >
          {value}
        </StyledLink>
      ),
    },
    {
      key: "accessionNumber",
      name: "Accession Number",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },

    {
      key: "status",
      name: "Status",
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={toUpperCase(cellValue)} />
      ),
    },
    {
      key: "range_start",
      name: "Min Due Date",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "range_end",
      name: "Max Due Date",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "anatomy",
      name: "Reco Anatomy",
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={toUpperCase(cellValue)} />
      ),
    },
    {
      key: "modality",
      name: "Reco Modality",
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={toUpperCase(cellValue)} />
      ),
    },
  ];

  const {
    columnCheckbox,
    columnFilterDropdown,
    columns,
    handleColumnChange,
    setColumnFilterDropdown,
  } = useTable(columnData);


  const rejectedRecommendations = useSelector(
    (state) => state.radAdmin?.rejectedRecommendations?.recommendations
  );

  const [page, setPage] = useState(1);
  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);
  const updatePermission = rolePermissions
    ?.find((itm) => itm.name === "Rejected Recommendations")
    ?.permissions.find(
      (itm) => itm.codename === "change_rejectedrecommendations"
    )?.is_authorized;

  const location = useLocation();
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  let storedCols = JSON.parse(localStorage.getItem("col-order-rejected")) || [];
  const [colsOrder, setColsOrder] = useState(
    storedCols.length > 0 ? storedCols : columns.map((itm) => itm.key)
  );
  const columsData = colsOrder
    .map((itm) => columns.find((it) => it.key === itm))
    .filter(Boolean);

  const [countsList, setCountsList] = useState([]);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isLoadingRestoreRecommendation, setIsLoadingRestoreRecommendation] =
    useState(false);

  const inputRef = useRef(null);

  const dialogHandler = (data) => {
    setRemoveDialogOpen(!removeDialogOpen);
    setSelectedId(data.id);
    window.analytics.track("Recommendation-Restore-Button-Click");
  };

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    let search = query.get("search") ?? "";
    if (search) {
      formik.setFieldValue("searchValue", search);
      setShowPlaceholder(false);
    }
    dispatch(getRejectedRecommendationsCount(search)).then((res) => {
      if (res) {
        setCountsList(res);
      }
    });
    dispatch(getRejectedRecommendations(page, search));
  }, []);

  const onLoadMore = () => {
    const query = new URLSearchParams(history.location.search);
    let search = query.get("search") ?? "";
      dispatch(getRejectedRecommendations(page + 1, search));
      setPage(page + 1);
  };

  const restoreRecommendationHandler = () => {
    const query = new URLSearchParams(history.location.search);
    let search = query.get("search") ?? "";
    if (search) {
      formik.setFieldValue("searchValue", search);
      setShowPlaceholder(false);
    }
    setIsLoadingRestoreRecommendation(true);
    dispatch(restoreRejectedRecommendation(selectedId,page,search,rejectedRecommendations?.data?.length === countsList?.id__count)).then((res) => {
      setIsLoadingRestoreRecommendation(false);
      if (res) {
        setRemoveDialogOpen(false);
        dispatch(getRejectedRecommendationsCount(search)).then((res) => {
          if (res) {
            setCountsList(res);
          }
        });
      }
    });
  };

  const updateURLParams = (paramsToUpdate) => {
    const params = new URLSearchParams(location.search);

    Object.keys(paramsToUpdate).forEach((key) => {
      if (paramsToUpdate[key]) {
        params.set(key, paramsToUpdate[key]);
      } else {
        params.delete(key);
      }
    });

    history.replace({ search: params.toString() });
  };

  const onHidePlaceholder = () => {
    setShowPlaceholder(false);
  };

  const onShowPlaceholder = () => {
    if (!inputRef.current.value) {
      setShowPlaceholder(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      searchValue: "",
    },
    onSubmit: (values) => {
      dispatch(getRejectedRecommendationsCount(values.searchValue)).then(
        (res) => {
          if (res) {
            setCountsList(res);
          }
        }
      );
      dispatch(getRejectedRecommendations(1, values.searchValue));
      setPage(1);
      updateURLParams({ search: values.searchValue });

      return;
    },
  });

  const onResetForm = () => {
    formik.setFieldValue("searchValue", "");
    dispatch(getRejectedRecommendationsCount("")).then((res) => {
      if (res) {
        setCountsList(res);
      }
    });
    dispatch(getRejectedRecommendations(1, ""));

    history.push(
      `/auth/radAdmin/followUp/recommendations/rejected/`
    );
    setShowPlaceholder(true);
    setPage(1);
  };

  const [isLoadingDeleteRecommendation, setIsLoadingDeleteRecommendation] =
    useState(false);

  return (
    <Box p={30}>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
        <div>
          <form onSubmit={formik.handleSubmit}>
            <InputContainer>
              <SearchIcon>
                <Search />
              </SearchIcon>
              {showPlaceholder && (
                <>
                  <CustomPlaceholder onClick={onHidePlaceholder}>
                    {"Search by Accession Number"}
                  </CustomPlaceholder>
                </>
              )}
              <Input
                type="text"
                id={"searchValue"}
                name={"searchValue"}
                onFocus={onHidePlaceholder}
                onBlur={onShowPlaceholder}
                onChange={formik.handleChange}
                value={formik.values.searchValue}
                ref={inputRef}
              />
              {!showPlaceholder && formik.values.searchValue && (
                <ClearButton type={"reset"} onClick={onResetForm}>
                  <img src={cancelIcon} />
                </ClearButton>
              )}
            </InputContainer>
          </form>
        </div>
      </div>
      <HeadingLabel>Rejected Recommendations</HeadingLabel>

      <Card
        style={{
          overflowX: "auto",
          overflowY: "hidden",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          marginTop: 0,
          left:30,
          right:30,
          position:'absolute'
        }}
      >
        <>
          <Table
            rows={rejectedRecommendations?.data}
            columns={[
              {
                key: "",
                name: "",
                displayCell: (cellValue, rowData) => (
                  <div style={{ display: "flex", gap: "20px" }}>
                    <IconContainerBox color="#643268">
                      <RestoreIcon
                        className="dark"
                        onClick={(e) => {
                          e.stopPropagation();
                          if ((permission && updatePermission) || !permission) {
                            dialogHandler(rowData);
                          } else {
                            dispatch(
                              showAlert(
                                "danger",
                                "Error",
                                `You don't have permission to update rejected recommendations`
                              )
                            );
                          }
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      <RestoreIconHover
                        onClick={(e) => {
                          e.stopPropagation();
                          if ((permission && updatePermission) || !permission) {
                            dialogHandler(rowData);
                          } else {
                            dispatch(
                              showAlert(
                                "danger",
                                "Error",
                                `You don't have permission to update rejected recommendations`
                              )
                            );
                          }
                        }}
                        className="light"
                        style={{ cursor: "pointer" }}
                      />
                    </IconContainerBox>
                  </div>
                ),
                enableCheckBox: false,
                align: "left",
              },
            ].concat(columsData, {
              key: "columnFilter",
              displayName: () => (
                <ColumnFilterDropdown
                  columnCheckbox={columnCheckbox}
                  columnData={columnData}
                  isOpen={columnFilterDropdown}
                  handleColumnChange={handleColumnChange}
                  handleColumnFilterDropdown={setColumnFilterDropdown}
                  setColsOrder={setColsOrder}
                  colOrderKey="col-order-rejected"

                />
              ),
            })}
            initialPageNumber={rejectedRecommendations?.pageNumber}
            totalItems={countsList?.id__count}
            isLoadingRows={
              rejectedRecommendations?.isLoading &&
              rejectedRecommendations?.pageNumber < 2
            }
            getRowsAction={(pageNumber) => {
              const query = new URLSearchParams(history.location.search);

              let search = query.get("search") ?? "";
              return getRejectedRecommendations(1, search);
            }}
            shouldGetRowActions={false}
            noDataRender={() => (
              <EmptyState title={"No Results"} img={() => <EmptyStateImg />}>
                Sorry, there are no results
              </EmptyState>
            )}
          />
          {rejectedRecommendations?.data &&
            rejectedRecommendations?.data?.length !== 0 && (
              <LoadMoreButton
                title="Load more reports"
                handleClick={onLoadMore}
                isDisabled={rejectedRecommendations?.data?.length === countsList?.id__count}
                isLoading={rejectedRecommendations?.isLoading}
                style={{
                  backgroundColor: "#0c2945",
                  fontWeight: 600,
                }}
              />
            )}
        </>
      </Card>

      <RestoreConfirmation
        content="Are you sure you want to restore this recommendation?"
        openDialog={removeDialogOpen}
        dialogHandler={dialogHandler}
        isLoading={isLoadingRestoreRecommendation}
        restoreHandler={restoreRecommendationHandler}
        heading={"Restore Recommendation"}
        removeHeader={"Yes, Restore Recommendation"}
      />
    </Box>
  );
}

export default RestoreRecommendations;
