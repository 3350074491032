import React, { useState } from "react";
import styled from "styled-components";
import { ResponsiveContainer, PieChart, Pie, Cell, Sector, Legend} from "recharts";
import Flex from '../../../components/_styles/Flex';
import RecomendationFilter from './RecomendationFilter';
import { Spinner } from "react-bootstrap";

export const Card = styled.div`
  background: #FFFFFF;
  border: 1px solid #C2CCD9;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  border-radius: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
`

export const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin: 15px 15px 50px 0px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const TitleAndLegend = styled(Flex)`
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Title = styled.div`
  font-family: Roboto Condensed;
  padding-left: 20px;
  padding-right: ${(props) => props.right };
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #000000;
  align-items: center;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`

export const PieChartContainer = styled.div`
  margin-top: -26px;
  @media (max-width: 768px) {
    margin: 10px 5px;
  }
`

export const LegendListContainer = styled.div`
  height: 220px;
  width: 130px;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -20px;
    display: block;
    width: 20px;
    height: 1px;
    background-color: #C3C7CA;
  }
`

export const LegendList = styled.ul`
  height: 100%
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  font-weight: normal;
  color: #000000;
  &:first-child {
    position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -10px;
        display: block;
        width: 7px;
        height: 1px;
        background-color: #C3C7CA;
      }
  }
  &:after {
    content: '';
    position: absolute;
    left: -10px;
    bottom: 0;
    display: block;
    width: 7px;
    height: 1px;
    background-color: #C3C7CA;
`

export const LegendListItem = styled.li`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 7px;
    left: -17px;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${props => props.color ? props.color: 'black'};
  }
`

export const RecoPercent = styled.span`
  font-weight: 900;
  font-size: 20px;
  line-height: 25px;
  color: #0C2945;
`

export const FilterContainer = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #666666;
  margin-right:9px;
`

export const NoDataContainer = styled.div`
  margin: 0 auto;
  height: 320px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NoDataText = styled.span`
  color: black;
`


const COLORS = ["#2D64A5", "#407ADB", " #6FA9F3", "#94D6FB", "#186EF0","#BBD6FF"];

export const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <g>
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={"middle"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </g>
  );
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <g>
  
      <text
        x={cx}
        y={cy-30}
        dy={8}
        fill="#0C2945"
        textAnchor={"middle"}
        dominantBaseline="central"
        style={{fontSize: '20px', lineHeight: '25px', fontWeight: 900}}
      >
       {`${(percent * 100).toFixed(0)}%`}
      </text>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={'#A6ACBE'}
        style={{fontSize: '10px', lineHeight: '13px', fontWeight: 600}}
      >
        {payload?.modalityAndAnatomy}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius}
        outerRadius={outerRadius}
        fill={fill}
       

      />
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={"middle"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </g>
  );
};

const renderLegend = (props) => {
  const { payload } = props;
  return (
    <LegendListContainer>
      <LegendList>
        {
          payload.sort((a, b) => b.payload.id__count - a.payload.id__count).map((entry, index) => (
            <LegendListItem key={index} color={COLORS[index % COLORS.length]}>{`${entry.payload?.modalityAndAnatomy} - ${entry.payload.id__count}`}</LegendListItem>
          ))
        }
      </LegendList>
    </LegendListContainer>
  );
}

const style = {
  top: 22,
  right: -110,
  height: '220px',
  width: '130px',
  lineHeight: "24px",
  borderLeft: '1px solid #C3C7CA',
  paddingLeft: '10px',
};

const RecomendedAnatomiesAndModalities = ({ data, onSetTimeframe, recommendedOptions, currentValue, isLoading, setNeedRerender }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };



  return (
    <Card>
      <Header>
        <TitleAndLegend>
          <Title>Recommended Modalities and Anatomies</Title>
        </TitleAndLegend>
        <FilterContainer>
          <RecomendationFilter onSetSelected={onSetTimeframe} optionsTitle={'Timeframe'} options={recommendedOptions} currentValue={currentValue} />
        </FilterContainer>
      </Header>
      <PieChartContainer>
        { isLoading ? <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "320px",
                }}
              >
                <Spinner animation="border" role="status" size="sm"  style={{ color: "black", fontSize:'20px' }} /> 
              </div>:  !data?.length ? <NoDataContainer><NoDataText>{ !isLoading ? 'No data' : null}</NoDataText></NoDataContainer> :
           data?.length ? <ResponsiveContainer width={'80%'} height={320}>
            <PieChart width={550} height={350}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              dataKey='id__count'
              startAngle={180}
              endAngle={0}
              data={data}
              cy={240}
              outerRadius={220}
              innerRadius={50}
              fill="#8884d8"
              label={renderCustomizedLabel}
              labelLine={false}
              onMouseEnter={onPieEnter}
              animationDuration={0}
              strokeWidth={0} 

            >
              {data.map((entry, index) => (
                <Cell  key={`cell-${index}-${activeIndex}`} fill={COLORS[index % COLORS.length]} 
                />
              ))}
            </Pie>
            <Legend
              iconSize={10}
              width={120}
              height={140}
              layout="vertical"
              verticalAlign="middle"
              wrapperStyle={style}
              iconType={"circle"}
              content={renderLegend}
            />
          </PieChart> 
        
       </ResponsiveContainer> : null
       }
     </PieChartContainer>
    </Card>
  );
}

export default RecomendedAnatomiesAndModalities;
