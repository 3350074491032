import styled from 'styled-components';
import { space, typography } from 'styled-system';
import { media } from '../../theme';

const Heading = styled.div`
  ${typography}
  ${space}

  font-size: ${({ theme }) => theme.fontSizes[4]}px;

  ${media.sm`
    font-size: ${({ theme }) => theme.fontSizes[5]}px;
  `}

  color: #0C2945;
  font-weight: bold;
`;

export default Heading;
