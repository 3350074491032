import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import * as Sentry from "@sentry/react";

import Button from '../../../../components/UI/Button/Button';
import Text from '../../../../components/_styles/Text';
import Flex from '../../../../components/_styles/Flex';
import * as Papa from 'papaparse';
import JSZip from 'jszip';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { DropDownField } from '../../Cohorts/Create';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from '../../../../actions/radminUploads';


const SpinnerContainer = styled(Flex)`
  justify-content: center;
  margin: 30px 0;
  align-items: center;
`

const ButtonTitle = styled.span`
  color: #3F4857;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

// const ChooseFileButton = styled(Button)`
//   background: #64C67C;
//   border-radius: 5px;
//   width: 100%;
//   height: 32px;
//   line-height: 0px;
//   color: black;
//   &:hover {
//     color: white;
//   }
// `;

const Hint = styled(Text)`
  font-size: 14px;
  line-height: 18px;
`;

const List = styled.div`
  margin-top: 10px;
  font-size: 14px;
  line-height: 18px;
`;

const FileNameContainer = styled.div`
  break-word: all;
`;

const ListItem = styled.div`
  margin-bottom: 5px;
`;

const BtnRow = styled.div`
  width: 100%;
  display: flex
`;

const BtnCol = styled(Col)`
  display: flex;
  justify-content: left;
`;

const NextButton = styled(Button)`
  width: fit-content
`;

const TemplateButton = styled(Button)`
  height: 32px;
  background: #E6E9F1;
  border-radius: 5px;
  line-height: 0;
  margin-left: 30px;
  & > a {
    color: #3E3F41 !important;
  }
  & > a:hover {
    outline: none;
    text-decoration: none;
  }
`;

const Container = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const ChooseFileLabel = styled.label`
  background: #64C67C;
  border-radius: 5px;
  width: 100%;
  height: 32px;
  line-height: 0px;
  color: black;
  text-align: center;
  padding-top: 15px;
  opacity: ${props => props.file? '0.5' : '1'};
  cursor: ${props => props.file? 'default' : 'pointer'};
  &:hover {
    color: ${props => props.file? 'black' : 'white'};
  }
`;

const ButtonsContainer = styled.div`
  width: 370px;
`;

const ErrorContainer = styled.div`
  width: 47%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorMessage = styled.span`
  color: red;
  textAlign: center;
  display: ${props => props.isDisplay? 'block': 'none'}
`

const Input = styled.input`
  display: none;
`;

const FileNamesList = styled.ul`
  list-style: none;
  width: 100%;
`;

const FileNameListItem = styled.li`
  display: flex;
  justify-content: space-between;
`;

const uploadTypesArray = [
    'Reports',
    'Appointments',
    'Patients',
    'Physicians'
]

export const convertParsedData = (data) => {
  const headersArr = data.map(item => item.data[0]);
  const infoArr = data.map(item => item.data.filter((innerItem, innerIdx) => innerIdx !== 0));

  return infoArr.map((item, outerIdx) => {
    return item.reduce((acc, valuesArr) => {
      const oneRow = headersArr[outerIdx]
        .filter((h) => typeof h !== 'object')
        .reduce((acc, header, index) => {
          acc[header] = typeof valuesArr[index] === 'string' ? valuesArr[index] : null;

          return acc;
        }, {});

      oneRow.fileName = headersArr[outerIdx].find(
        (item) => typeof item === 'object'
      ).fileName;

      return [...acc, oneRow];
    }, []);
  });
};

const isValidDate = (value) => {
    let splitedValArr = value.split(" ")
    for(let i=0; i<splitedValArr.length; i++){
      let value = splitedValArr[i]
      if(new Date(value) !== "Invalid Date" && !isNaN(new Date(value))){
        return true
      }
    }
    return false
}

const Setup = ({ file, appHeaders, setFile, setUserHeadersKeys, setPreviewRows, moveToNextStep, setHeadersMapping, data, setData, uploadType, setUploadType, patients }) => {
  const [zip, setZip] = useState(null);
  const [zipFileNames, setZipFileNames] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const [isNotValidCSV, setIsNotValidCSV] = useState(false)
  const [isClickedUpload, setIsClickedUpload] = useState(false);
  const permission = useSelector((state) => state.auth.user.permission_level); 
  const rolePermissions = useSelector(
     (state) =>state.auth.user.permissions
   );
  const addPermission = rolePermissions?.find(itm=>itm.name ==="Import")?.permissions.find(itm=>itm.codename === "add_import")?.is_authorized;
  const dispatch = useDispatch()
 

  const onNextClick = () => {
    try {

      if((permission && addPermission) || !permission){
      
    
        var userHeadersKeysArr = data.map((item) => item.data[0]);
        if (data && data[0].data.length === 1) {
          setIsNotValidCSV(true);
          return;
        }
        let isDateStringExist = false;
        for (let i = 0; i < userHeadersKeysArr[0].length - 1; i++) {
          let currentVal = userHeadersKeysArr[0][i];
          let isDate = isValidDate(currentVal);
          if (isDate) {
            isDateStringExist = true;
          }
        }
        if (isDateStringExist) {
          setIsNotValidCSV(true);
          return;
        }
        setUserHeadersKeys(userHeadersKeysArr);
        if (userHeadersKeysArr && appHeaders) {
          const initialMapping = userHeadersKeysArr.map((item) =>
            item.reduce((acc, userHeader) => {
              if (typeof userHeader !== 'string') {
                return { ...acc, fileName: userHeader.fileName };
              } else {
                const appHeader = appHeaders.find(
                  (appHeader) => appHeader.key === userHeader
                );
                return { ...acc, [userHeader]: appHeader ? appHeader.key : '' };
              }
            }, {})
          );
          setHeadersMapping(initialMapping);
        }
        const rows = convertParsedData(data);
        setPreviewRows(rows);
        moveToNextStep();
     }else{
       dispatch(
         showAlert('danger', 'Error', `You don't have permission to create import`)
       );
     }

    } catch(err) {
      Sentry.withScope((scope) => {
        scope.setExtras(err);
        Sentry.captureException(err);
      });
    }
  };



  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleRemoveFile = (name) => {
    setIsNotValidCSV(false)
    if(zip) {
      const newZip = zip.filter(z => z.name !== name);
      const fileNamesForDel = zipFileNames.find(({zipName}) => zipName === name);
      const newFiles = file.filter(f => fileNamesForDel && !fileNamesForDel.fileNames.includes(f.name));
      const newData = data.filter(item => {
        const el = item.data[0].find(r => typeof r === 'object');
        return fileNamesForDel && !fileNamesForDel.fileNames.includes(el.fileName);
      });
      setFile(newFiles.length > 0 ? newFiles : null);
      setData(newData.length > 0 ? newData : null);
      setZip(newZip.length > 0 ? newZip : null);
      return;
    }
    const newFiles = file.filter(f => f.name !== name);
    const newData = data.filter(item => {
      const el = item.data[0].find(r => typeof r === 'object');
      return el && el.fileName !== name;
    });
    setData(newData.length > 0 ? newData : null);
    setFile(newFiles.length > 0 ? newFiles : null);
  }

  const waitForPapaParse = async (file) => {
    Papa.parsePromise = function (file) {
      return new Promise(function (complete, error) {
        Papa.parse(file, {
          complete,
          error: handleOnError,
        });
      });
    }

    let results = [];
    await Papa.parsePromise(file)
        .then(function (parsedData) {
            results = parsedData
        });

    return results;
  }

  const cleanCsvData = (response) => {
    const header = response[0].data[0];
    const isLastElementAnEmptyString = ['', ' '].includes(header[header.length - 1]);

    // Remove the last element in the header if it is an empty string
    if (isLastElementAnEmptyString) {
      header.pop();
    }

    for (let i = 1; i < response[0].data.length; i++) {
      const current = response[0].data[i];

      while (current.length > header.length) {
        current.pop();
      }
    }
  };

  const asyncParser = async (filesForParse, filesNames) => {
    const response = await Promise.all(
      filesForParse.map((file) => waitForPapaParse(file))
    );

    cleanCsvData(response);

    const dataForDisplay = response.map((item, index) => {
      return {
        ...item,
        data: item.data
          .slice(0, 11)
          .filter((row) => {
            const hasSameLengthAsHeader = row.length === item.data[0].length;
            const hasNoEmptyFields = row.every(field => field.length > 0);

            return hasSameLengthAsHeader && hasNoEmptyFields;
          })
          .map((row) => row.concat({ fileName: filesNames[index] })),
      };
    });

    setData(dataForDisplay);
    setIsLoading(false);
  };

  const onFileChange = (e) => {
    setIsLoading(true)
    setIsNotValidCSV(false)
    const filesForPapa = [];
    const selectedFiles = [...e.target.files];
    const filesToUnzip = selectedFiles.filter(file => file.name.includes('.zip'));
    if(filesToUnzip.length) {
      setZip(filesToUnzip);
    }
    const filesToParse = selectedFiles.filter(file => file.name.includes('.csv'));

    const handleFile = (f) => {
      JSZip.loadAsync(f)
      .then(async (zip) => {
        const fileNames = Object.keys(zip.files);
        setZipFileNames(prevState => {
          return [...prevState, {zipName: f.name, fileNames}];
        });
        const files = await Promise.all(fileNames.map(fileName => zip.files[fileName].async('arraybuffer')));

        files.forEach((value, index) => {
          const file = new File([value], fileNames[index], {
            type: 'text/csv'
          });
          filesForPapa.push(file);
          setFile(filesForPapa);
        });
        asyncParser(filesForPapa, fileNames)
      });
    }

    if(filesToUnzip.length > 0) {
      filesToUnzip.forEach(file => handleFile(file));
    } else {
      setFile(filesToParse);
      asyncParser(filesToParse, filesToParse.map(item => item.name));
    }

    e.target.value = null;
  }
  const csvFileSelectAnalytic = () => {
    window.analytics.track('csv-FileSelect-Click');
  }
  const zipFileSelectAnalytic = () => {
    window.analytics.track('ZIP-FileSelect-Click');
  }

  const isFile = file && file.length;

  return (
    <Container>
              { isLoading ? <SpinnerContainer>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </SpinnerContainer> :
      <Row>
        <Col>
          <Flex mt={10}>
            <ButtonsContainer>
              <ButtonTitle>Upload your CSV files</ButtonTitle>
              <ChooseFileLabel htmlFor={'csvFiles'} file={isFile}>
                Choose CSV files
                <Input type="file" id={'csvFiles'} name={'files'} multiple accept=".csv" onChange={onFileChange} disabled={isFile} onClick={csvFileSelectAnalytic}/>
              </ChooseFileLabel>
              <Hint>** Maximum allowed file size is 100 MB</Hint>
              <FileNameContainer>
                {!zip && isFile && <FileNamesList>{
                  file.map(({name}) => <FileNameListItem key={name}>{name}<Button onClick={() => handleRemoveFile(name)}>X</Button></FileNameListItem>) }
                      </FileNamesList>}
              </FileNameContainer>
              <ButtonTitle>Upload your zip files</ButtonTitle>
              <ChooseFileLabel htmlFor={'zipFiles'} file={isFile}>
                Choose ZIP file
                <Input type="file" id={'zipFiles'} name={'files'} multiple accept=".zip" onChange={onFileChange} disabled={isFile} onClick={zipFileSelectAnalytic} />
              </ChooseFileLabel>
              <Hint>** Maximum allowed file size is 100 MB per CSV</Hint>
              <FileNameContainer>
                {zip && <FileNamesList>{
                  zip.map(({name}) => <FileNameListItem key={name}>{name}<Button onClick={() => handleRemoveFile(name)}>X</Button></FileNameListItem>) }
                      </FileNamesList>}
              </FileNameContainer>

                <div style={{ marginTop: "25px" }}>
                  <ButtonTitle>What type of data do you want to import?</ButtonTitle>
                  <DropDownField
                    id="2"
                    selectedValue={uploadType}
                    isClicked={isClickedUpload}
                    items={uploadTypesArray}
                    dropdownValues={uploadTypesArray}
                    clickHandler={() => {
                      setIsClickedUpload(!isClickedUpload)
                      if (uploadType !== "Reports") {
                        setUploadType("Reports")
                      }
                    }}
                    onChange={(value) => {
                      setUploadType(value);
                      setIsClickedUpload(false)
                    }}
                    width="75%"
                    isLoading={false}
                    specified=""
                  />
                </div>

            </ButtonsContainer>
          </Flex>
        </Col>
        <Col>
          <Hint m={30}>
          Please use the pipe | character to separate your columns as the report text will more than likely include commas within its body and will be difficult for us to parse.
          You can upload multiple CSV files or a zip file containing multiple CSV files. Please include the column headers with the following fields:
            <List>
              {appHeaders && appHeaders.map((appHeader, index) => (
                <ListItem key={index}>{`${index+1} - ${appHeader.name} (${appHeader.key})${appHeader.key === 'PatientMRN' ? '' : ''}`}</ListItem>
              ))}
            </List>
          </Hint>
          { uploadType === 'Physicians'? 
           <TemplateButton>
          <a href="/physicians-csv-template.csv" download>Download a CSV Template</a>
          </TemplateButton>
          :  (patients ?
          <TemplateButton>
          <a href="/patients-csv-template.csv" download>Download a CSV Template</a>
          </TemplateButton>
          :
          <TemplateButton>
            <a href="/csv-template.csv" download>Download a CSV Template</a>
          </TemplateButton>)
         }
        </Col>
      </Row> }
      <BtnRow>
        <ErrorContainer>
          <ErrorMessage isDisplay={isNotValidCSV}>Not a valid CSV, please choose another!</ErrorMessage>
        </ErrorContainer>
        <BtnCol>
          <NextButton primary block onClick={onNextClick} disabled={!file || !data}>Next</NextButton>
        </BtnCol>
      </BtnRow>
    </Container>
  );
};

export default Setup;
