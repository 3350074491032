import { css } from 'styled-components';

const color = (props) => {
  switch (true) {
    case props.primary:
    case props.success:
    case props.warning:
    case props.danger:
    case props.info:
      return css`
        color: #fff;
        transition: background 0.15s ease;

        &:hover {
          color: #00AEEF;
        }
      `;
    case props.secondary:
      return css`
        color: #00AEEF;

        &:hover {
          color: #fff;
        }
      `;
    case props.variant === 'link':
      return css`
        color: #00AEEF;
      `;
    default:
      return css`
        color: rgba(70, 90, 110, 0.85) !important;
      `;
  }
};

const backgroundColor = (props) => {
  switch (true) {
    case props.primary:
      return css`
        background: #00AEEF;

        &:hover {
          background: #d9ecff;
        }

        &:active {
          background: #d9ecff;
        }
      `;
    case props.secondary:
      return css`
        background: #d9ecff;

        &:hover {
          background: #00AEEF;
        }

        &:active {
          background: #00AEEF;
        }
      `;
    case props.success:
      return css`
        background: #22b66e;
      `;
    case props.warning:
      return css`
        background: #fdab29;
      `;
    case props.danger:
      return css`
        background: #ea2e49;
      `;
    case props.info:
      return css`
        background: #14baa8;
      `;
    default:
      return css`
        background-color: #fff;
        border: 1px solid rgba(120, 130, 140, 0.2);
      `;
  }
};

const border = (props) => {
  switch (true) {
    case props.variant === 'outline':
      return css`
        background-color: transparent;
        border: 2px solid #00AEEF;
        color: #00AEEF;
      `;
    case props.variant === 'link':
      return css`
        border: none;
      `;
    default:
      return css``;
  }
};

const btnSize = (props) => {
  const { size } = props;

  switch (size) {
    case 'small':
    case 'sm':
      return css`
        padding: 8px 24px;
      `;
    case 'xl':
      return css`
        padding: 28px 32px;
      `;
    default:
      return css`
        padding: 16px 32px;
      `;
  }
};

const borderRadius = (props) => {
  switch (true) {
    case props.rounded:
      return css`
        border-radius: 30px;
      `;
    default:
      return css``;
  }
};

const buttonStyles = (props) => css`
  display: inline-flex;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 700;
  line-height: 16px;
  text-decoration: none !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  /* white-space: nowrap; */
  width: ${(props) => (props.block ? '100%' : null)};
  position: relative;

  @media (max-width: 767px) {
    width: ${(props) => (props.responsive ? '100%' : null)};
    margin-right: 0;
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &:disabled {
    background: #dcecfe;
    color: #fff !important;
    cursor: default;
  }

  &:disabled:hover {
    background: #dcecfe;
  }

  &::before {
    border-radius: 2px;
  }

  ${btnSize(props)}
  ${color(props)}
  ${backgroundColor(props)}
  ${border(props)}
  ${borderRadius(props)}
`;

export default buttonStyles;
