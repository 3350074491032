import styled from 'styled-components';
import Box from './Box';

const Text = styled(Box)`
  ${(props) => (props.muted ? 'color: #8692A4' : null)}
  ${(props) => (props.bright ? 'color: #fff' : null)}
  ${(props) => (props.uppercase ? 'text-transform: uppercase;' : null)}
`;

Text.defaultProps = {
  fontSize: 2,
};

export const Small = styled(Text)`
  font-size: 12px;
  font-weight: bold;

  ${(props) => (props.uppercase ? 'text-transform: uppercase;' : null)}
`;

Text.Small = Small;

export default Text;
