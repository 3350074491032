import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as CloseIcon } from "../../../assets/cancel-outline.svg";
import { TextField, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { Spinner } from "react-bootstrap/esm";
import {
  deleteApiGenerationToken,
  generateApiToken,
} from "../../../actions/radminApiTokenGeneration";
import moment from "moment";
import Flex from "../../../components/_styles/Flex";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar-outline.svg";
import { ReactComponent as RemoveRecoIcon } from "../../../assets/close-outline.svg";

import DatePicker from "react-datepicker";
import RemoveConfirmation from "../Cohorts/RemoveConfirmation";
import { showAlert } from "../../../actions/radminUploads";

import { ReactComponent as DeleteIconFilled } from "../../../assets/delete-icon-filled.svg";

const DateRangeWrapper = styled.div`
  font-size: 12px;
  & > div > div > div {
    height: 38px;
    width: 226px;
  }
`;

const DateClearButton = styled.div`
  cursor: pointer;
  display: flex;
`;

const Modal = styled.div`
  display: ${(props) => (props.showModal ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(12, 41, 69, 0.38);
  backdrop-filter: blur(10px);
`;

const ModalContentContainer = styled.div`
  position: relative;
  min-height: 292px;
  width: 520px;
  margin: 0 auto;
  background-color: white;
  cursor: default;
  font-style: normal;
  border-radius: 4px;
  padding: 25px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

const ModalTitle = styled.p`
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 24px;
  color: #0c2945;
  font-family: Roboto Condensed;
  font-weight: 700;
`;

const ModalSubTitle = styled.h4`
  margin-bottom: 15px;
  padding: 0;
  text-align: left;
  font-size: 18px;
  color: #0c2945;
  font-family: Roboto Condensed;
  font-weight: 700;
`;

const CloseButton = styled.button`
  border: 0;
  outline: none !important;
  background-color: #ffffff;
`;

const SelectContainer = styled.div`
  width: 227px;
`;

const SelectDropdownTitle = styled.div`
  margin-bottom: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-transform: uppercase;
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SubmitButton = styled.button`
  width: 227px;
  height: 38px;
  background-color: #00aeef;
  color: #ffffff;
  opacity: ${(props) => (props.disabled ? 0.65 : 1)};
  border-radius: 5px;
  border: 2px solid #00aeef;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    background: transparent;
    color: #00aeef;
  }
`;

const DeleteButton = styled.button`
  width: 139px;
  height: 38px;
  background-color: transparent;
  color: #cf3131;
  border-radius: 5px;
  border: 2px solid #cf3131;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  display:flex;
  justify-content:center;
  align-items:center;
  gap:8px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
`;

const CopyLinkButton = styled.div`
  border: 1px solid #D2D3D7;
  border-radius: 4px;
  height: 38px;
  padding: 10px;
  width: 227px;
  font-size: 14px;
  font-weight: 400px;
  position: relative;
`;

const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 4px;
  font-size: 18px;
  width: max-content;
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
`;

const DateContainer = styled(Flex)`
  margin-right: 15px;
  font-size: 14px;
  font-weight:400;
`;

const Divider = styled.div`
  border-bottom: 1px solid #cecece;
  margin-bottom: 20px;
`;

const CopyLabel = styled.span`
  position: absolute;
  color: #00aeef;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const TokenInput = styled.input`
  width: 70%;
  border: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const useStyles = makeStyles({
  textField: {
    width: "227px",
    height: "38px",
    fontWeight:400,
    "& .MuiOutlinedInput-input": {
      fontSize: 14,
      borderRadius: "4px !important",
      padding: "13.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      height: "38px",
    },
  },
});

const ApiTokenGenerationModal = ({ showModal, closeModal, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const expiryDate = new Date(data?.expire_at)
  const localExpiryDate = new Date(expiryDate.getTime() + expiryDate.getTimezoneOffset()*60000)

  const [selectedValues, setSelectedValues] = useState({
    name: data?.name || "",
    expire_at: data?.expire_at ? localExpiryDate : "",
  });

  const [loading, setLoading] = useState(false);
  const [
    isLoadingDeleteApiToken,
    setIsLoadingDeleteApiToken,
  ] = useState(false);

  const onSaveClick = () => {
    setLoading(true);
    dispatch(
      generateApiToken(
        {
          name: selectedValues?.name,
          expire_at:
            moment(selectedValues?.expire_at).format("YYYY-MM-DD") +
            "T23:59:59Z",
        },
        data?.id
      )
    ).then((res) => {
      setLoading(false);
      if (res) {
        closeModal();
      }
    });
  };

  const handleDeleteToken = () => {
    dialogHandler();
  };

  const onChange = (dates) => {
    setSelectedValues({
      ...selectedValues,
      expire_at: dates,
    });
  };

  const resetRangeDateFilter = () => {
    setSelectedValues({
      ...selectedValues,
      expire_at: "",
    });
  };

  const deleteIntegrationHandler = () => {
    if (data.id) {
      setIsLoadingDeleteApiToken(true);

      dispatch(deleteApiGenerationToken(data.id)).then(() => {
        setIsLoadingDeleteApiToken(false);
        setRemoveDialogOpen(false);
        closeModal();
      });
    }
  };

  const DatePickerInputField = React.forwardRef((props, ref) => {
    const { onClick, date } = props;
    const formatDate = (date, time) => {
      return `${moment(date).format("MMM, DD, YYYY")}`;
    };
    return (
      <DatePickerButton
        onClick={onClick}
        style={{ padding: "9.5px 12px", fontSize: "12px" }}
      >
        <DateContainer style={{ fontSize: 12, color:date?"#252525":"#828282" }}>
          {date ? `${formatDate(date)}` : "Select date..."}
        </DateContainer>
        {!date && <CalendarIcon />}
        {date && (
          <DateClearButton onClick={resetRangeDateFilter}>
            <RemoveRecoIcon style={{ height: "12px" }} />
          </DateClearButton>
        )}
      </DatePickerButton>
    );
  });

  const dialogHandler = () => {
    setRemoveDialogOpen(!removeDialogOpen);

    window.analytics.track("Generate-Api-Token-Delete-Button-Click");
  };

  return (
    <>
      <Modal showModal={showModal}>
        <ModalContentContainer>
          <Header>
            <ModalTitle>
              {" "}
              {data?.id ? "Edit Token" : "Create New Token"}{" "}
            </ModalTitle>
            <CloseButton type={"button"} onClick={closeModal}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <Divider />
          <ModalSubTitle>Token Details</ModalSubTitle>
          <FieldContainer>
            <Row>
              <div>
                <SelectDropdownTitle>Token Name *</SelectDropdownTitle>
                <TextField
                  id="outlined-uncontrolled"
                  variant="outlined"
                  value={selectedValues["name"]}
                  onChange={(e) =>
                    setSelectedValues({
                      ...selectedValues,
                      name: e.target.value,
                    })
                  }
                  style={{ borderRadius: "4px", height: "38px" }}
                  placeholder="Enter value"
                  className={classes.textField}
                />
              </div>
              <div>
                <SelectContainer>
                  <SelectDropdownTitle>Expiration Date *</SelectDropdownTitle>
                  <DateRangeWrapper>
                    <DatePicker
                     popperPlacement="auto"
                      onChange={onChange}
                      dateFormat="MM/dd/yyyy"
                      selected={selectedValues.expire_at}
                      minDate={new Date()}
                      customInput={
                        <DatePickerInputField date={selectedValues.expire_at} />
                      }
                    />
                  </DateRangeWrapper>
                </SelectContainer>
              </div>
            </Row>
            {data.id && (
              <Row>
                <div>
                  <CopyLinkButton>
                    <TokenInput readOnly={true} value={data?.token} />

                    <CopyLabel
                      onClick={() => {
                        navigator.clipboard.writeText(data?.token);
                        dispatch(
                          showAlert(
                            "success",
                            "Success",
                            "API token copied successfully!"
                          )
                        );
                      }}
                    >
                      Copy
                    </CopyLabel>
                  </CopyLinkButton>
                </div>
                <div>
                  <DeleteButton onClick={handleDeleteToken}>
                    <DeleteIconFilled
                      style={{
                        cursor: "pointer",
                        marginBottom: "0px",
                        textAlign: "right",
                        height: "18px",
                        display: "inline-block",
                      }}
                    />{" "}
                    Delete Token
                  </DeleteButton>
                </div>
              </Row>
            )}
          </FieldContainer>
          <Divider />

          <SubmitButtonContainer>
            <SubmitButton
              disabled={!selectedValues.name || !selectedValues.expire_at}
              onClick={onSaveClick}
            >
              {loading ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : data?.id ? (
                "Save Changes"
              ) : (
                "Create Token"
              )}
            </SubmitButton>
          </SubmitButtonContainer>
        </ModalContentContainer>
      </Modal>
      <RemoveConfirmation
        content="Are you sure you want to delete this token?"
        openDialog={removeDialogOpen}
        dialogHandler={dialogHandler}
        isLoading={isLoadingDeleteApiToken}
        deleteHandler={deleteIntegrationHandler}
        heading={"Delete Token"}
        removeHeader={"Yes, Delete Token"}
      />
    </>
  );
};

export default ApiTokenGenerationModal;
