import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import BaseGrid from '../../_styles/Grid';
import { NavigationButton } from '../../Design/button';
import Logo from '../../UI/Logo/Logo';
import AccountShowcaseImg from '../../../assets/account/APP-LOGIN-2.jpg';
import { media } from '../../../theme';

const Grid = styled(BaseGrid)`
  grid-gap: 0px;
  height: inherit;
  width: 100%;
  overflow: auto;

  align-items: center;

  ${media.lg`
    align-items: normal;
  `}
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;

  ${media.md`
    justify-content: flex-end;
  `}
`;

const ContentHolder = styled.div`
  padding-right: 0;
  margin-right: 0;
  width: 100%;

  ${media.sm`
    width: 50%;
  `}

  ${media.md`
    width: 75%;
  `}

  ${media.lg`
    width: 75%;
    margin-top: 100px;
    padding-right: 5%;
    margin-right: 5%;
  `}
`;

const Headline = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
  color: #0c2945;
`;

const HeadlineSubtext = styled.div`
  margin-top: 20px;
  font-size: 16px;
  line-height: 22px;
  color: #3f4857;
`;

const LeftPanel = styled.div`
  padding: 30px;
  width: 100%;
`;

const ShowcaseImg = styled.img`
  display: none;

  ${media.md`
    display: block;
  `}

  ${media.lg`
    object-fit: cover;
    width: 100%;
    height: 100%;
  `}
`;

const LogoWrapper = styled.div`
  top: 30px;
  left: 30px;
  position: absolute;

  @media (max-width: 375px) {
    top: 0;
    left: 0;
    position: static;
    display: flex;
    margin: 30px 0 0 30px;
  }
`;

export function AccountContentContainer({ children }) {
  return (
    <Grid>
      <Grid.Col col={5}>
        <LogoWrapper>
          <Link to='/'>
            <Logo onWhite />
          </Link>
        </LogoWrapper>
        <LeftPanel className='left'>
          <ContentContainer>
            <ContentHolder>{children}</ContentHolder>
          </ContentContainer>
        </LeftPanel>
      </Grid.Col>
      <Grid.Col col={7}>
        <ShowcaseImg src={AccountShowcaseImg} alt='account-showcase' />
      </Grid.Col>
    </Grid>
  );
}

export function HeadlineCTA({ headlineText, subtext }) {
  return (
    <div>
      <Headline>{headlineText}</Headline>
      <HeadlineSubtext>{subtext}</HeadlineSubtext>
    </div>
  );
}

export const SubmitButton = styled(NavigationButton)`
  width: 100%;
  opacity: ${props => props.isDisabled? '0.5': '1'}
`;
