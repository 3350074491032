import React from 'react';
import cs from 'classnames';

import './grid.css';

class Grid extends React.Component {
  styles() {
    const { gap, gridTemplateColumns } = this.props;

    return {
      gridGap: gap,
      gridTemplateColumns
    };
  }

  classNames() {
    return cs('grid', this.props.className);
  }

  render() {
    return (
      <div className={this.classNames()} style={this.styles()}>
        {this.props.children}
      </div>
    );
  }
}

export default Grid;
