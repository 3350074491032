import { GET_SCAN_FETCH, GET_SCAN_RESOLVE, GET_SCAN_REJECT } from '../actions/scan';

const initialState = {
    loading: false,
    items: []
};

export default (state = initialState, action) => {
    switch (action.type) {
    case GET_SCAN_FETCH:
        return {
            loading: true,
            items: []
        };
    case GET_SCAN_RESOLVE:
        return {
            loading: false,
            items: action.payload
        };
    case GET_SCAN_REJECT:
        return initialState;
    default:
        return state;
    }
};
