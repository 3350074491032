import { css } from 'styled-components';

const breakpoints = [375, 768, 1024, 1280, 1600].map((b) => `${b}px`);
const fontSizes = [12, 14, 16, 20, 24, 36, 48, 80, 96];

breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];

fontSizes.xs = fontSizes[0];
fontSizes.sm = fontSizes[1];
fontSizes.md = fontSizes[2];
fontSizes.lg = fontSizes[3];
fontSizes.xl = fontSizes[4];

export const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export default {
  breakpoints,
  fontSizes,
  header: { height: 65 },
  sidebar: { width: 240, large: 350 },
};
