import React, { useState, useRef, useEffect, memo, forwardRef } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Flex from "../../../components/_styles/Flex";
import filterOutline from "../../../assets/IO-icons/filter-outline.svg";
import Select, { components } from "react-select";

import moment from "moment";
// import { RemoveRecoIcon } from "../../../components/Dialog/Icon";
import DatePicker from "react-datepicker";

import { ReactComponent as TriangleDownIcon } from "../../../assets/triangle-down.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar-outline.svg";
import { ReactComponent as RemoveRecoIcon } from "../../../assets/close-outline.svg";
import Filter from "../Messages/Filter";
import { frequencyOptions } from "../CustomCharts/Constants";
import { useSelector } from "react-redux";

export const DateClearButton = styled.div`
  cursor: pointer;
  display: flex;
`;

export const DateContainer = styled(Flex)`
  margin-right: 15px;
  font-size: 13px;
`;
export const DatePickerContainer = styled(Flex)`
  max-width: 370px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

export const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 8px;
  font-size: 18px;
  width: max-content;
  &:hover {
    cursor: pointer;
  }
`;

export const Card = styled(Flex)`
  max-width: 370px;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 22px 22px 19px;
`;

export const FormContainer = styled.div`
  width: 230px;
`;

export const SelectContainer = styled.div`
  margin-bottom: 30px;
`;

export const FilterContainer = styled(Flex)`
  padding: 10px;
  height: 38px;
  justify-content: space-around;
  align-items: center;
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-top: 15px;
  }
`;

export const SettingsContainer = styled.div`
  position: relative;
  z-index: 1;
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  border-radius: 8px;

  position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: 200px;
  z-index: 2;
`;
export const Image = styled.img`
  display: block;
  width: 20px;
`;

export const FilterButton = styled(Flex)`
  width: 207px;
  justify-content: space-between;
  align-items: center;
`;

export const FilterButtonTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;

export const FilterMenuTitle = styled.div`
  margin-bottom: 14px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #0c2945;
`;

export const SelectDropdownTitle = styled.div`
  margin-bottom: 4px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.6;
  text-transform: uppercase;
`;

export const Button = styled.button`
  width: 226px;
  height: 50px;
  background-color: #00aeef;
  border-radius: 4px;
  border: 0;
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
`;

export const SelectedItemContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 83px;
  height: 32px;
  background: rgba(43, 113, 255, 0.15);
  border-radius: 4px;
  @media (max-width: 768px) {
    width: 120px;
    margin: 0 auto;
  }
`;

export const SelectedItem = styled.div`
  margin: 7px 3px 6px 8px;
  min-width: 50px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`;

export const CloseSign = styled.div`
  width: 14px;
  height: 14px;
  color: #00aeef;
  margin-right: 7px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

const DateFilterContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
  display: ${(props) =>
    props.isSelected !== undefined
      ? props.isSelected
        ? "block"
        : "none"
      : "block"};
`;

const TimeframeContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    padding: 0px;
    & > div {
        height:38px;
        & >span > div > .select__control {
            height:38px;
        }
        & > span> div {
            height: 38px;
        }
       
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const options = [
  { value: "Last 7 days", label: "Last 7 days" },
  { value: "Last 30 days", label: "Last 30 days" },
  { value: "Last 3 months", label: "Last 3 months" },
  { value: "Last 6 months", label: "Last 6 months" },
  { value: "Last 12 months", label: "Last 12 months" },
  { value: "Custom date range", label: "Custom date range" },
];

const Option = ({ type, ...props }) => {
  return (
    <>
      <components.Option {...props}>
        <span style={{ marginRight: 5, paddingTop: 4 }}>
          <input
            type={"radio"}
            checked={props.isSelected}
            onChange={() => null}
            style={{ marginTop: 5 }}
          />{" "}
        </span>
        <label>{props.label}</label>
      </components.Option>
    </>
  );
};

const IndicatorsContainer = (props) => {
  return (
    <div>
      <components.IndicatorsContainer {...props} />
    </div>
  );
};

const DropdownTitle = styled.div`
  margin-bottom: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-transform: uppercase;
`;

const SelectContainerBox = styled.div`
  width: 100%;
  margin-bottom:20px;
  & div.select__control,
  div.select__value-container {
    height: 38px;
    cursor: pointer;
  }
`;


export const AdherenceTimeFilter = memo(
  ({ dateRangeChange, graphStartDate, graphEndDate,showGroupBy=false, frequency="", setFrequency=()=>{} }) => {
    const [showMenu, setShowMenu] = useState(false);


    const recoConfigData = useSelector((state) => state.radAdmin.recoConfig.data);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState( );
    const [filterValue, setFilterValue] = useState({ value: "Custom date range", label: "Custom date range" });
    const [selectedDate, setSelectedDate] = useState("");
    const node = useRef();

    useEffect(()=>{
      setStartDate( new Date(moment().subtract(recoConfigData?.defaultRangeStart, "days")));
     setEndDate( new Date( moment().subtract(recoConfigData?.defaultRangeEnd, "days")));
    },[recoConfigData?.defaultRangeStart, recoConfigData?.defaultRangeEnd])

    useEffect(() => {
      if (showMenu) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [showMenu]);

    React.useEffect(() => {
        if(filterValue){
            var date = new Date();
            let requiredDate
            let lastMoment
            if(filterValue.value === 'Custom date range') return
            setStartDate("")
            setEndDate("")
            if(filterValue.value ===  'Last 7 days'){
                lastMoment = new Date(moment().subtract(7, "days"));
            }else if(filterValue.value ===  'Last 30 days'){
                lastMoment = new Date(moment().subtract(30, "days"));
            }else if(filterValue.value ===  'Last 3 months'){
                lastMoment = new Date(moment().subtract(3, "months"));
            }
            else if(filterValue.value ===  'Last 6 months'){
                lastMoment = new Date(moment().subtract(6, "months"));
            }
            
            
            if(filterValue.value ===  'Last 12 months'){
                let day =date.getDate();
                let month = date.getMonth() + 1
                let year = date.getFullYear() - 1
                if(day<10){
                  day = '0' + day
                }
                if (month< 10){
                  month = '0' + month
                }
                requiredDate = `${year}-${month}-${day}`
                setSelectedDate(requiredDate)
                return
            } else{
              let day =lastMoment.getDate();
              let month = lastMoment.getMonth() + 1
              let year = lastMoment.getFullYear()
              requiredDate = `${year}-${month}-${day}`
              setSelectedDate(requiredDate)
            }
           
        }
    }, [filterValue])

    const handleClickOutside = (e) => {
      if (node.current && node.current.contains(e.target)) {
        return;
      }
      setShowMenu(false);
    };

    const handleClick = () => {
      let day = new Date().getDate();
      let month = new Date().getMonth() + 1;
      let year = new Date().getFullYear();
      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }

      let requiredDate = `${year}-${month}-${day}`;
      const dateRange = {
        startDate:
          filterValue.value === "Custom date range"
            ? moment(startDate).format("YYYY-MM-DD")
            : selectedDate,
        endDate:
          filterValue.value === "Custom date range"
            ? moment(endDate).format("YYYY-MM-DD")
            : requiredDate,
      };
      dateRangeChange(dateRange.startDate, dateRange.endDate, filterValue.value, frequency);
      setShowMenu(false);
    };

    return (
      <>
        <FilterContainer>
          {
            <FilterButton onClick={() => setShowMenu(true)}>
              <FilterButtonTitle>
                {" "}
                {graphStartDate && graphEndDate
                  ? `${moment(graphStartDate).format("MMM D, YYYY")} - ${moment(
                      graphEndDate
                    ).format("MMM D, YYYY")}`
                  : "Select Timeframe"}{" "}
              </FilterButtonTitle>
              <CalendarIcon />
            </FilterButton>
          }
          {showMenu && (
            <SettingsContainer ref={node}>
              <Row>
                <Col>
                  <Card>
                    <FilterMenuTitle>Filters</FilterMenuTitle>
                    {
                      showGroupBy && <>
                          <DropdownTitle>Frequency </DropdownTitle>
                            <SelectContainerBox>
                              <Select
                              components={{ IndicatorsContainer, Option }}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={frequencyOptions}
                              value={frequency}
                              onChange={(value) => {
                              setFrequency(value)
                              }}
                             />
                            </SelectContainerBox>
                      </>
                    }
                
                    <DropdownTitle> Timeframe </DropdownTitle>
                    <DateFilterContainer>
                      <TimeframeContainer>
                        <Filter
                          options={options}
                          filterValue={filterValue}
                          setFilterValue={setFilterValue}
                          startDate={startDate}
                          setStartDate={setStartDate}
                          endDate={endDate}
                          setEndDate={setEndDate}
                          setSelectedDate={setSelectedDate}
                          type="radio"
                        />
                      </TimeframeContainer>
                    </DateFilterContainer>
                 
                    <button
                      disabled={!filterValue}
                      style={{
                        margin: "20px 0px 0px 0px",
                        color: "white",
                        background: "#00AEEF",
                        borderRadius: "5px",
                        border: "none",
                        width: "100%",
                        height: "38px",
                      }}
                      onClick={handleClick}
                    >
                      Apply Filters
                    </button>
                  </Card>
                </Col>
              </Row>
            </SettingsContainer>
          )}
        </FilterContainer>
      </>
    );
  }
);
