import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import TextField from '@material-ui/core/TextField';
import LoadMoreButton from '../../../components/LoadMoreButton';
import loadMoreIcon from "../../../assets/load-more.svg";
import { Spinner } from 'react-bootstrap';
import { isArray, isEmpty, isNull } from 'lodash';
import styled from 'styled-components';
import { makeStyles, styled as materialStyle } from '@material-ui/core/styles';
import { ReactComponent as ArrowDropDownIcon } from "../../../assets/chevron_down.svg";
import { ReactComponent as ArrowDropUpIcon } from "../../../assets/chevron_up.svg";
import { Title } from '../Overview/RecomendationModalities';
import { Dialog, Typography, Grid, TextareaAutosize } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { TickIcon } from './Icons';
import { ReactComponent as DeleteIcon } from "../../../assets/FollowUpDeleteIcon.svg";
import { CalendarIcon } from '../../../components/Dialog/Icon';
import { useSelector, useDispatch } from 'react-redux';
import {
  createOutgoingMessage,
  createTemplate,
  showAlert,
  getTemplates,
  deleteTemplate,
  getTemplateToPreview,
  setTemplateIdToPreview,
  setTemplateToPreview,
  updateTemplate,
  getTwilioConfig
} from '../../../actions/radminUploads';
import { ReactComponent as CloseIcon } from "../../../assets/cancel-outline.svg";
import Editor from "./Editor";
import Flex from '../../../components/_styles/Flex';
import PhoneInput from 'react-phone-input-2';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  datePicker: {
    paddingLeft: 36,
  },
  dialogContent: {
    minWidth: 577,
    maxWidth: 800,
    maxHeight: 600,
    minHeight: 188,
  },
  leftTabHead: {
    paddingBottom: 10,
  },
  tabLabel: {
    cursor: 'pointer',
    color: '#9FA2B4',
    textAlign: 'center',
  },
  rightTabHead: {
    textAlign: 'right',
    paddingBottom: 10,
  },
  tabBorderBottom: {
    borderBottom: '3px solid #4dabf5',
  },
  formControlDropdownMessage: {
    width: '95%',
  },
  formControlDropdownTemplate: {
    width: '95%',
    marginLeft: '5%',
  },
  selectInput: {
    padding: 10,
    fontSize: 16,
    '& .MuiOutlinedInput-input': {
      padding: 0,
    },
  },
  chooseMessageTypeSec: {
    display: 'flex',
  },
  labelColor: {
    color: '#0C2945',
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      fontSize: 14,
      padding: '10.5px 14px',
    },
  },
  centerSec: {
    // border: '1px solid #D7D8DC',
    // borderRadius: 5,
    height: 'auto',
    maxHeight: 410,
    // overflow: 'scroll',
    // width: '85%',
    margin: '0 auto',
  },
  smsFromSec: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  smsToSec: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const SmsContent = materialStyle(Typography)({
  color: '#000000',
  fontSize: 14,
  fontWeight: 400,
  padding: '0',
  fontFamily:'Roboto Condensed'
});

const PersionalizedText = materialStyle(Typography)({
  color: "black",
  fontSize: 16,
  fontWeight: 400,
  padding: "8px 0",
  width: "100%",
  paddingLeft: 10,
});

const AddButton = styled.button`
color: white;
background-color: #00aeef;
border: none;
border-radius: 4px;
padding: 0 12px;
font-size: 15px;
font-weight: 600;
height: 38px;
`;


const NewDateLabel = styled.p`
font-weight: 600;
font-size:14px;
color:#00AEEF;
cursor:pointer;
text-align:center;
margin-top:7px;
margin-bottom:0px;
`

export const DatePickerButton = styled(Flex)`
  padding: 13px;
  align-items: center;
  background:#00AEEF; 
  height:38px;
  border: none;
  width:210px;
  border-radius: 4px;
  justify-content:center;
  &:hover {
    cursor: pointer;
  }
`

export const DateContainer = styled(Flex)`
  font-size:14px;
  color:#ffffff;
  font-weight:600;
`

const InputField = styled.input`
height: 38px;
width: 255px;
font-size: 16px;
border: 1px solid #d2d3d7;
border-radius: 4px;
padding-left: 12px;
padding-right: 12px;
`;
const Button = styled.button`
  width: 172px;
  height: 38px;
  background-color: ${(props) => (props.bg ? props.bg : "#00AEEF")};
  border-radius: 4px;
  border: ${(props) => (props.border ? "2px solid #00AEEF" : 0)};
  font: bold 14px "Open Sans", sans-serif;
  text-align: center;
  color: ${(props) => (props.color ? props.color : "#ffffff")};
  display: block;
  cursor: ${(props) => (props.disabled ? "" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
`;

const CtaLabel = styled.h4`
color:#0C2945;
font-size:18px;
font-weight: 500;
font-family:Roboto Condensed;
margin-bottom:16px;
`

const SentTestLabel = styled.span`
color: #00AEEF;
font-weight: 600;
font-size: 14px;
margin-top: 19px;
display:inline-block;
text-align:center;
width:100%;
font-family:Open Sans;
`

export const NormalText = materialStyle(Typography)({
  fontSize: 14,
  color: '#3F4857',
  fontFamily: 'Roboto Condensed',
  display: 'inline',
});
export const PersionalizedTextContainer = materialStyle(Grid)({
  border: "1px solid #CBCCCF",
  borderRadius: "4px",
});
const PersonalizedFieldSection = materialStyle(Grid)({
  display: "flex",
  alignItems: "center",
  width: "100%",
  gap: "21px",
  justifyContent: "flex-end",
  marginRight: "21px",
});

export const PersonalizedInnerContainer = materialStyle(Grid)({
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: 38,
});
const InsertPersonalizedSection = materialStyle(Grid)({
  backgroundColor: "#F5F5F5",
  height: "200px",
  overflowY: "auto",
  padding: "10px 0px",
});
const PersonalizedRow = materialStyle(Grid)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "8px",
});
const PeronalizedText = materialStyle(Typography)({
  fontSize: 14,
  fontWeight: 400,
  margin: 0,
  marginLeft: 10,
  color: "#8F8F8F",
});
export const ParagraphContainer = materialStyle(Grid)({
  padding: '5px 10px',
  cursor: 'auto',
});
export const SmsInfoContainer = materialStyle(Grid)({
  padding: '0px 10px',
  display: 'flex',
});

const Heading = materialStyle(Typography)({
  fontSize: 19,
  padding: '15px 0 15px 32px',
  fontWeight: 700,
  color: '#252733',
  fontFamily: 'Roboto Condensed',
});
const BottomHeading = materialStyle(Typography)({
  fontSize: 22,
  padding: '8px 0 ',
  fontWeight: 700,
  color: '#0C2945',
  fontFamily: 'Roboto Condensed',
});
const TemplateRowContainer = materialStyle(Grid)({
  display: 'flex',
  alignItems: 'center',
  margin: '0 auto',
  justifyContent: 'space-between',
  padding: '10px',
});
const TemplateTitle = materialStyle(Typography)({
  fontSize: 16,
  color: "#000000",
});
const ButtonContainer = materialStyle(Grid)({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "6px 6px",
  width: (props) => props.width,
  borderRadius: 3,
  border: (props) => `2px solid ${props.color}`,
  // background: (props) => props.color,
});
const IconContainer = materialStyle(Grid)({
  display: 'flex',
  padding: '0 3px 0 5px',
});
const BorderWraper = materialStyle(Grid)({
  border: "1px solid #BFBFBF",
  background: "#F6F6F6",
  marginBottom: "10px",
  borderRadius: "5px",
});
const DatePickerContainer = materialStyle(Grid)({
  width: '100%',
  '& .react-datepicker': {
    paddingLeft: 36,
  },
});
const BottomContainer = materialStyle(Grid)({
  margin: '0 auto',
});
const FlexContainer = materialStyle(Grid)({
  display: 'flex',
});
export const DateLabel = materialStyle(Typography)({
  color: '#AAAEBD',
  fontSize: 12,
});

const Text = materialStyle(Typography)({
  color: (props) => props.textcolor,
  fontSize: 14,
  fontWeight: 600,
  fontFamily: 'Roboto Condensed',
});

const SendContainer = materialStyle(Grid)({
  display: 'flex',
  width: '100%',
 gap:'30px',
 marginBottom:'30px'
});

const CenterSecContainer = materialStyle(Grid)({
  height: 298,
});

const TopContainer = materialStyle(Grid)({
  width: '85%',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  marginTop: 40,
  height: '13%',
});

const PreviewButtonContainer = materialStyle(Grid)({
  display: "flex",
  justifyContent: "flex-end",
  // marginTop: "5%",
  marginBottom: "28px",
});

const CustomButton = materialStyle(Grid)({
  backgroundColor: (prop) => prop.bg,
  display: "flex",
  fontFamily: "Open Sans",
  width: (prop) => (prop.width ? prop.width : 135),
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  fontSize: 14,
  fontWeight: 600,
  borderRadius: 4,
  height: "38px",
  padding: "0 10px",
  cursor: (props) => (props.disabled ? "default" : "pointer"),
  // marginTop: "30px",
});
const AddActionBtn = materialStyle(Grid)({
  backgroundColor: '#00AEEF',
  display: 'flex',
  fontFamily: 'Roboto Condensed',
  cursor: 'pointer',
  width: 200,
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  fontSize: 13,
  fontWeight: 600,
  borderRadius: 5,
  height: '55px',
  padding: '0 10px',
  marginLeft: '45%',
});
export const CreateTemplateContainer = materialStyle(Grid)({
  maxHeight: '290px',
});

const TemplateBtnContainer = materialStyle(Grid)({
  display: "flex",
  gap: "15px",
  alignItems:'center'
});

export default function SmsCreationDialog({
  isContactSequence,
  openMessage,
  openMessageHandler,
  onSave,
  patientDetails,
  currentNode,
}) {
  const messageContentText = `This is placeholder text - Change ME!`;
  const classes = useStyles();
  const dispatch = useDispatch();
  const providerPhoneNumber = useSelector(
    (state) => state.radAdmin.messages.twilioConfig.data?.phone_number
  );
  const templateIdToPreview = useSelector(
    (state) => state.radAdmin.templates.templateIdToPreview
  );
  const templateToPreview = useSelector(
    (state) => state.radAdmin.templates.templateToPreview
  );
  const isLoading = useSelector(
    (state) => state.radAdmin.messages.outgoingMessageFromDialog.isLoading
  );
  const isLoadingTemplate = useSelector(
    (state) => state.radAdmin.templates.isLoading
  );
  const usersInfo = useSelector(
    (state) => state.radAdmin.messages.usersInfo?.data
  );
  const totalTemplates = useSelector(
    (state) => state.radAdmin.templates?.totalItems
  );

  
  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector(
    (state) =>state.auth.user.permissions
  );
  const addPermission = rolePermissions?.find(itm=>itm.name ==="Follow-Ups")?.permissions.find(itm=>itm.codename === "add_follow-ups")?.is_authorized
  const updatePermission = rolePermissions?.find(itm=>itm.name ==="Follow-Ups")?.permissions.find(itm=>itm.codename === "change_follow-ups")?.is_authorized



  const [selectedPatientPhoneNumber, setSelectedPatientPhoneNumber] = useState();
  const [sentTestMessage, setSentTestMessage] = useState(false);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [selectedTab, setTab] = useState('1');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [clickedButton, setClickedButton] = useState('');
  const [sendTo, setSendTo] = useState('-');
  const [receiverName, setReciverName] = useState('unknown');
  const [templateTitle, setTemplateTitle] = useState(currentNode?.title || '');
  const [templateContent, setTemplateContent] = useState(
    currentNode?.content || ''
  );
  const [messageContent, setMessageContent] = useState(
    currentNode?.content || ''
  );
  const [templatePageNo, setTemplatePageNo] = useState(1);
  const [isClickedPersionalized, setIsClickedPersionalized] = useState(false);
  const [isSaveTemplateClicked, setIsSaveTemplateClicked] = useState(false);
  const [isPreviewClicked, setIsPreviewClicked] = useState(false);
  const [defaultValue, setDefaultValue] = useState({
    patientNameDefaultValue: '',
    referringPhysicianDefaultValue: '',
    referringPhysicianDefaultNumber: '',
    pcpDefaultName: '',
    pcpDefaultNumber: '',
    providerDefaultName: '',
  });
  const [disableSaveButton, setDisableSaveButton] = useState(true);

  useEffect(() => {
    dispatch(getTemplates(templatePageNo));
    dispatch(getTwilioConfig());
  }, []);

  useEffect(() => {
    if (!isNull(selectedTemplate)) {
      setTemplateTitle(selectedTemplate.title);
      setTemplateContent(selectedTemplate.message_content);
      setMessageContent(selectedTemplate.message_content);
      setDisableSaveButton(false);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (templateToPreview) {
      setTemplateContent(templateToPreview);
    }
  }, [templateToPreview]);
  useEffect(() => {
    if (patientDetails?.patient?.phone_number) {
      setSendTo(patientDetails.patient.phone_number);
      setReciverName(patientDetails?.patient?.first_name);
    }
  }, [patientDetails]);

  const onChange = (dates) => {
    if (isArray(dates)) {
      const [start] = dates;
      setDate(start);
      return;
    }
    setTime(dates);
  };

  const createTemplateHandler = () => {
    window.analytics.track('Save-As-Template-Button-Click');
    if (isEmpty(templateTitle)) {
      alert('Please enter title for template');
      return;
    }
    if (isEmpty(templateContent)) {
      alert('Please enter content for template');
      return;
    }
    setIsSaveTemplateClicked(true);
    let dataForTemplate = {
      title: templateTitle,
      message_content: templateContent,
    };
    dispatch(createTemplate(dataForTemplate)).then((data) => {
      setIsSaveTemplateClicked(false);
      if(data?.response){
        setSelectedTemplate(data?.response);
        dispatch(setTemplateIdToPreview(data?.response?.id));
      }
    });
  };

  const updateTemplateHandler = () => {
    if (isSaveTemplateClicked) {
      return;
    }
    window.analytics.track("Edit-Template-Button-Click");

    setIsSaveTemplateClicked(true);
    let dataForTemplate = {
      message_content: templateContent,
    };

    dispatch(updateTemplate(dataForTemplate, selectedTemplate.id)).then(
      () => {
        setIsSaveTemplateClicked(false);
      }
    );
  };

  const previewTemplateHandler = () => {
    setIsPreviewClicked(true);
    dispatch(getTemplateToPreview(patientDetails.patient.id)).then(() =>
      setIsPreviewClicked(false)
    );
  };

  const sendMessageHandler = (type) => {
    if (!selectedTemplate) {
      alert('Please select template');
      return;
    }

    const currentTime = moment().toISOString();
    let requiredDate = moment(date).format('YYYY-MM-DD');
    let requiredTime = moment(time).format('HH:mm:ss');
    const dateTime = moment(
      `${requiredDate} ${requiredTime}`,
      'YYYY-MM-DD HH:mm:ss'
    ).toISOString();

    if(type === "send_test"){
      window.analytics.track('Send-Test-Button-Click');
      setClickedButton('send_test');
        dispatch(
          createOutgoingMessage({
            patientPhoneNumber: selectedPatientPhoneNumber,
            twilioPhoneNumber: providerPhoneNumber,
            message: selectedTemplate.id,
            sendAt: time ? dateTime : currentTime,
            receiverName,
          })
        ).then((res) => {
          if (res) {
            dispatch(
              showAlert(
                'success',
                'Success',
                `Successfully sent to ${selectedPatientPhoneNumber}!`
              )
            );
            setSentTestMessage(true);
          }
        });
      
      return;
    }

    if (type === 'add_future_action') {
      window.analytics.track('Add-Future-Action-Button-Click');
      setClickedButton('add_future_action');
      let scheduledDate = new Date(dateTime);
      let scheduledTime = new Date(dateTime).getTime();
      let today = new Date();
      let incrementedTime = today.setMinutes(today.getMinutes() + 30); // adding 30 min into current time
      let todayTime = new Date(incrementedTime).getTime();
      if (time === '') {
        alert('Please select time for this action');
        return;
      }
      if (scheduledDate >= today && scheduledTime >= todayTime) {
        dispatch(
          createOutgoingMessage({
            patientPhoneNumber: sendTo,
            twilioPhoneNumber: providerPhoneNumber,
            message: selectedTemplate.id,
            sendAt: dateTime,
            receiverName,
          })
        ).then((res) => {
          if (res) {
            openMessageHandler(false);
            dispatch(
              showAlert(
                'success',
                'Success',
                `Successfully scheduled to ${patientDetails?.patient?.first_name}!`
              )
            );
            setTemplateTitle('');
            setSendTo('-');
            setSelectedTemplate(null);
            setTime('');
          }
        });
      } else {
        alert('Invalid time for this action');
      }
      return;
    }
    window.analytics.track('Follow-Up-List-Send-Now-Button-Click');
    setClickedButton('send_now');
    dispatch(
      createOutgoingMessage({
        patientPhoneNumber: sendTo,
        twilioPhoneNumber: providerPhoneNumber,
        message: selectedTemplate.id,
        sendAt: time ? dateTime : currentTime,
        receiverName,
      })
    ).then((res) => {
      if (res) {
        openMessageHandler(false);
        dispatch(
          showAlert(
            'success',
            'Sent',
            `Successfully sent to ${patientDetails?.patient?.first_name}!`
          )
        );
        setTemplateTitle('');
        setSendTo('-');
        setSelectedTemplate(null);
        setTime('');
      }
    });
  };

  const addActionHandler = () => {
    alert('Action not available for now');
    if (sendTo === '-') {
      alert('Please select recipient');
      return;
    }
    setClickedButton('add_action');
  };

  const updateTemplatePageNum = (value) => {
    setTemplatePageNo(value);
  };

  const onCloseDialogHandler = () => {
    openMessageHandler(false);
    setTemplateContent('');
    setTemplateTitle('');
    setTab('1');
    setSendTo('-');
    setSelectedTemplate(null);
    setTime('');
    setIsClickedPersionalized(false);
    setDefaultValue({
      ...defaultValue,
      patientNameDefaultValue: '',
      referringPhysicianDefaultValue: '',
      referringPhysicianDefaultNumber: '',
      pcpDefaultName: '',
      pcpDefaultNumber: '',
      providerDefaultName: '',
    });
    dispatch(setTemplateIdToPreview(null));
    dispatch(setTemplateToPreview(null));
  };

  const personalizedClickHandler = () => {
    setIsClickedPersionalized(!isClickedPersionalized);
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    setDefaultValue({ ...defaultValue, [name]: value });
  };

  const checkforTemplatedContent = (value, key) => {
    let content = templateContent;
    let newValue = `${key} | default("${value}")`;
    let replacedValue = content.replace(key, newValue);
    let valueToSearch = `${key} | default`;
    let requiredIndex = content.indexOf(valueToSearch);
    if (replacedValue === content) {
      let concatedValue = content + ' ' + `{{${newValue}}}`;
      setTemplateContent(concatedValue);
    } else if (requiredIndex !== -1) {
      let splited = content.split(`{{${key}`);
      let requiredValue = splited[splited.length - 1].split('"')[1];
      let replacedVal = content.replace(requiredValue, value);
      setTemplateContent(replacedVal);
    } else {
      setTemplateContent(replacedValue);
    }
    setIsClickedPersionalized(false);
  };

  const addDefaultHandler = (from) => {
    if (from === 'row1') {
      checkforTemplatedContent(
        defaultValue.patientNameDefaultValue,
        'first_name'
      );
    } else if (from === 'row2') {
      checkforTemplatedContent(
        defaultValue.referringPhysicianDefaultValue,
        'referring_physician_first_name'
      );
    } else if (from === 'row3') {
      checkforTemplatedContent(
        defaultValue.referringPhysicianDefaultNumber,
        'referring_physician_phone_number'
      );
    } else if (from === 'row4') {
      checkforTemplatedContent(
        defaultValue.pcpDefaultName,
        'primary_care_physician_first_name'
      );
    } else if (from === 'row5') {
      checkforTemplatedContent(
        defaultValue.pcpDefaultNumber,
        'primary_care_physician_phone_number'
      );
    } else {
      checkforTemplatedContent(
        defaultValue.providerDefaultName,
        'provider_name'
      );
    }
  };

  return (
    <div>
      <Dialog
        open={openMessage}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        // style={{ height: 800 }}
        style={{
          backdropFilter: "blur(5px)",
        }}
        onClose={() => onCloseDialogHandler()}
      >
        <DialogContent style={{ width: selectedTab==='3' ?'790px': selectedTab ==='2'? '597px' : 'initial', height: selectedTab ==='2'? '547px':'initial', paddingTop:'30px' }} className={classes.dialogContent}>
      
        {selectedTab === "2" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "26px",
              }}
            >
              <h2
                style={{
                  color: "#0C2945",
                  fontSize: "28px",
                  fontWeight: 700,
                  fontFamily: "Roboto Condensed",
                  marginBottom: 0,
                }}
              >
                Saved Templates
              </h2>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => openMessageHandler(false)}
              />
            </div>
          )}
          {selectedTab === "3" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2
                style={{
                  color: "#0C2945",
                  fontSize: "28px",
                  fontWeight: 700,
                  fontFamily: "Roboto Condensed",
                  marginBottom: 0,
                }}
              >
                {selectedTemplate ? "Send Message" : "Create New Template"}
              </h2>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => openMessageHandler(false)}
              />
            </div>
          )}

          {selectedTab === "3" && !selectedTemplate && (
            <>
              <h4
                style={{
                  fontFamily: "Roboto Condensed",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#6D6E6E",
                  marginTop: "35px",
                }}
              >
                TEMPLATE TITLE
              </h4>
              <TextField
                id="outlined-uncontrolled"
                variant="outlined"
                className={classes.textField}
                value={templateTitle}
                onChange={(e) => setTemplateTitle(e.target.value)}
                fullWidth={true}
                placeholder="Create title"
              />
            </>
          )}
          {selectedTab === "3" && (
            <>
                <>
                  <h4
                    style={{
                      fontFamily: "Roboto Condensed",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#6D6E6E",
                      marginTop: "30px",
                    }}
                  >
                    PERSONALIZED CONTENT
                  </h4>
                  <CreateTemplate
                    patientDetails={patientDetails}
                    messageContentText={messageContentText}
                    templateContent={templateContent}
                    setTemplateContent={setTemplateContent}
                    providerPhoneNumber={providerPhoneNumber}
                    isClickedPersionalized={isClickedPersionalized}
                    personalizedClickHandler={personalizedClickHandler}
                    defaultValue={defaultValue}
                    handleChange={handleChange}
                    addDefaultHandler={addDefaultHandler}
                  />
                </>
              
              <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'30px', marginBottom:'8px'}}>
              <h4
                style={{
                  fontFamily: "Roboto Condensed",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#6D6E6E",
                  marginBottom:'0px'
                }}
              >
                SMS CONTENT
              </h4>
              {
                selectedTemplate &&   <div style={{display:'flex', gap:'37px'}}>
                <SmsContent>
                  To: {patientDetails?.patient?.phone_number}
                </SmsContent>
                <SmsContent>From: {providerPhoneNumber}</SmsContent>
                </div>
               
              }
            
              </div>
            
              <TextareaAutosize
                maxRows={8}
                aria-label="maximum height"
                value={templateContent}
                onChange={(e) => setTemplateContent(e.target.value)}
                style={{
                  width: '100%',
                  fontSize: 14,
                  color: '#000000',
                  fontFamily: 'Open Sans',
                  display: 'inline',
                  lineHeight: 2,
                  resize: 'none',
                  height:'150px',
                  padding:'9px 12px',
                  border:'1px solid #D2D3D7',
                  borderRadius:4
                }}
                placeholder={"This is placeholder text - Change me!"}
              ></TextareaAutosize>
            </>
          )}
          {selectedTab === "1" ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "47px",
                  alignItems: "center",
                }}
              >
                <h2
                  style={{
                    fontSize: "28px",
                    fontWeight: 700,
                    color: "#0C2945",
                    marginBottom: "0px",
                    fontFamily: "Roboto Condensed",
                  }}
                >
                  New Message
                </h2>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => openMessageHandler(false)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Button onClick={() => setTab("2")}>Saved Templates</Button>
                <Button
                  bg="white"
                  color="#00AEEF"
                  border
                  onClick={() => {
                    if((permission && addPermission) || !permission){
                      setTab("3");
                    }else{
                      dispatch(
                        showAlert('danger', 'Error', `You don't have permission to create message template`)
                      );
                    }                  
                  }}
                >
                  Create New Template
                </Button>
              </div>
            </>
          ) : (
            <>
              {selectedTab === "2" && (
                <CenterSecContainer>
                  <Grid className={classes.centerSec}>
                    {selectedTab === "2" ? (
                      <>
                        <SaveTemplateSec
                          setSelectedTemplate={setSelectedTemplate}
                          setIsSecondTab={setTab}
                          totalTemplates={totalTemplates}
                          templatePageNo={templatePageNo}
                          setTemplatePageNo={updateTemplatePageNum}
                          setDefaultValue={setDefaultValue}
                        />
                      </>
                    ) : null}
                  </Grid>
                </CenterSecContainer>
              )}

              {selectedTab === "3" && (
                <BottomContainer>
                  <PreviewButtonContainer>
                    {selectedTemplate ? (
                      <span
                      onClick={()=>{
                        if((permission && updatePermission) || !permission){
                          updateTemplateHandler()
                        }else{
                          dispatch(
                            showAlert('danger', 'Error', `You don't have permission to update message template`)
                          );
                        }                  
                      }}
                        style={{
                          color: "#00AEEF",
                          fontWeight: 600,
                          fontSize: "14px",
                          cursor: isSaveTemplateClicked? "not-allowed" : "pointer",
                          marginTop: "5px",
                        }}
                      >
                        Save Changes to Template
                      </span>
                    ) : (
                      <CustomButton
                        bg="#00AEEF"
                        width="104px"
                        onClick={createTemplateHandler}
                        style={{marginTop:'30px'}}
                      >
                        { isSaveTemplateClicked ? (
                          <Spinner animation="border" role="status" size="sm" />
                        ) : (
                          "Save"
                        )}
                      </CustomButton>
                    )}
                  </PreviewButtonContainer>
                </BottomContainer>
              )}
            </>
          )}
         
         {
          selectedTemplate && selectedTab === '3' && <BottomContainer>
          {selectedTab === '3' && !isContactSequence && (
            <BottomHeading>When should this get delivered? </BottomHeading>
          )}
          <FlexContainer>
              <CallToActions
                addActionHandler={addActionHandler}
                clickedButton={clickedButton}
                isContactSequence={isContactSequence}
                isLoading={isLoading}
                onSave={() => {
                  onSave({
                    content: templateContent,
                    title: templateTitle,
                    sendFrom: providerPhoneNumber,
                    type: 'sms',
                    btn: 'green',
                    text: 'SMS/Text Message',
                  });
                }}
                selectedTab={selectedTab}
                sendMessageHandler={sendMessageHandler}
                disabled={disableSaveButton}
                date={date}
                time={time}
                setTime={setTime}
                setDate={setDate}
                onChange={onChange}
                selectedPatientPhoneNumber={selectedPatientPhoneNumber}
                setSelectedPatientPhoneNumber={setSelectedPatientPhoneNumber}
                sentTestMessage={sentTestMessage}
                setSentTestMessage={setSentTestMessage}
              />
          </FlexContainer>
        </BottomContainer>
         } 
        </DialogContent>
      </Dialog>
    </div>
  );
}

const CallToActions = ({
  isContactSequence,
  selectedTab,
  sendMessageHandler,
  isLoading,
  onSave,
  clickedButton,
  addActionHandler,
  disabled,
  date,
  time,
  setDate,
  setTime,
  onChange,
  selectedPatientPhoneNumber,
  setSelectedPatientPhoneNumber,
  sentTestMessage,
  setSentTestMessage
}) => {
  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector(
    (state) =>state.auth.user.permissions
  );
  const addPermission = rolePermissions?.find(itm=>itm.name ==="Follow-Ups")?.permissions.find(itm=>itm.codename === "add_follow-ups")?.is_authorized
  const dispatch = useDispatch()

  if (isContactSequence && selectedTab === '1') {
    return (
      <div style={{ marginTop: '30px' }}>
        <CustomButton
          bg={!disabled ? '#00AEEF' : '#ccc'}
          width="100%"
          onClick={!disabled ? onSave : () => null}
          disabled={disabled}
        >
          Save
        </CustomButton>
      </div>
    );
  }

  return (
    <>
      {selectedTab === '3' ? (
        <SendContainer>
          <div>
           <CtaLabel>Now</CtaLabel>
          <CustomButton width="104px" bg="#00AEEF" onClick={()=>{
             if((permission && addPermission) || !permission){
              if(!isLoading){
                sendMessageHandler()
                }
            }else{
              dispatch(
                showAlert('danger', 'Error', `You don't have permission to create send message`)
              );
            }    
           
          }
          }>
            {isLoading && clickedButton === 'send_now' ? (
              <Spinner animation="border" role="status" size="sm" />
            ) : (
              'Send Now'
            )}
          </CustomButton>

          </div>
          <div>
           <CtaLabel>Later</CtaLabel>
           <div className="datePick">
                    <DatePickerContainer  onClick={()=> {
                       if((permission && addPermission) || !permission){
                        if(date && time && !isLoading){
                          sendMessageHandler('add_future_action')
                        }
                      }else{
                        dispatch(
                          showAlert('danger', 'Error', `You don't have permission to create send message`)
                        );
                      }    
                    }
                    }>
                      <DatePicker
                      disabled={ (date && time) || isLoading}
                        onChange={onChange}
                        dateFormat="MM/dd/yyyy"
                        customInput={
                          <DatePickerInput date={date} time={time} isLoading={isLoading} clickedButton={clickedButton} />
                        }
                        style={{ width: 300 }}
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        showTimeSelect
                        selectsRange
                      />
                    </DatePickerContainer>
                    { date && time && <NewDateLabel onClick={()=>{
                      setDate('');
                      setTime('');
                    }}>Choose new date & time</NewDateLabel>}
                  </div>
          </div>
          <div style={{marginLeft:'auto'}}>
           <CtaLabel>Send Test</CtaLabel>
           <div style={{display:'flex', gap:'8px'}}>
           <PhoneInput
                  country={'us'}
                  value={selectedPatientPhoneNumber}
                  onChange={(value) =>{
                    setSelectedPatientPhoneNumber(value)
                  }
                  }
                  onlyCountries={['us']}
                  countryCodeEditable={true}
                  disableDropdown={false}
                  inputStyle={{ height: '38px', width: '183px' }} 
                  containerStyle={{ width: '183px' }} 
                  />
           <CustomButton width="104px" bg="#FFFFFF" style={{color:'#00AEEF', border:'2px solid #00AEEF'}}  onClick={()=>{
              if((permission && addPermission) || !permission){
                if(!isLoading){
                  sendMessageHandler('send_test');
                  setSentTestMessage(false);
                  }
              }else{
                dispatch(
                  showAlert('danger', 'Error', `You don't have permission to create test message`)
                );
              }     
         
          }
          }>
            {isLoading && clickedButton === 'send_test' ? (
              <Spinner animation="border" role="status" size="sm" />
            ) : (
              'Send Test'
            )}
          </CustomButton>
           </div>
           { sentTestMessage &&  <SentTestLabel>Test Successfully Sent!</SentTestLabel> }
          </div>
        
        </SendContainer>
      ) : (
        <SendContainer>
          {selectedTab === '1' && (
            <AddActionBtn onClick={() => addActionHandler()}>
              {isLoading && clickedButton === 'add_action' ? (
                <Spinner animation="border" role="status" />
              ) : (
                'Add Action'
              )}
            </AddActionBtn>
          )}
        </SendContainer>
      )}
    </>
  );
};

const CreateMessageSec = ({
  patientDetails,
  messageContent,
  providerPhoneNumber,
  selectedTemplate,
  isClickedPersionalized,
  personalizedClickHandler,
  isContactSequence,
  currentNode,
}) => {
  const classes = useStyles();

  return (
    <>
      <CreateTemplateContainer>
        {/* <SmsInfoContainer>
          <Grid className={classes.smsFromSec}>
            <SmsContent>SMS Content</SmsContent>
            <SmsContent>From: {providerPhoneNumber}</SmsContent>
          </Grid>
          {!isContactSequence && (
            <Grid className={classes.smsToSec}>
              <SmsContent style={{ marginLeft: '20%' }}>
                To: {patientDetails?.patient?.phone_number}
              </SmsContent>
              <div>
                <ArrowDropDownIcon style={{ cursor: 'pointer' }} />
              </div>
            </Grid>
          )}
        </SmsInfoContainer> */}

        <PersionalizedTextContainer>
          <PersonalizedInnerContainer bgnone="true"></PersonalizedInnerContainer>
        </PersionalizedTextContainer>
        <ParagraphContainer>
          {!selectedTemplate && !currentNode ? (
            <NormalText>
              Template is missing - please Use Saved Template or Create a New
              Template to send a message.
            </NormalText>
          ) : (
            <TextareaAutosize
              maxRows={8}
              aria-label="maximum height"
              placeholder=""
              value={messageContent}
              // onChange={(e) => setTemplateContent(e.target.value)}
              style={{
                width: '100%',
                border: 'none',
                fontSize: 13,
                color: '#3F4857',
                fontFamily: 'Roboto Condensed',
                display: 'inline',
                lineHeight: 2,
                resize: 'none',
              }}
              defaultValue={messageContent}
              disabled={selectedTemplate}
            ></TextareaAutosize>
          )}
        </ParagraphContainer>
      </CreateTemplateContainer>
    </>
  );
};

const SaveTemplateSec = ({
  setSelectedTemplate,
  setIsSecondTab,
  totalTemplates,
  templatePageNo,
  setTemplatePageNo,
  setDefaultValue
}) => {
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.radAdmin.templates.data);
  const isLoading = useSelector((state) => state.radAdmin.templates.isLoading);

  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector(
    (state) =>state.auth.user.permissions
  );
  const deletePermission = rolePermissions?.find(itm=>itm.name ==="Follow-Ups")?.permissions.find(itm=>itm.codename === "delete_follow-ups")?.is_authorized



  const onLoadMore = () => {
    dispatch(getTemplates(templatePageNo + 1));
    setTemplatePageNo(templatePageNo + 1);
  };

  const deleteTemplateHandle = (id) => {
    dispatch(deleteTemplate(id, templatePageNo));
    window.analytics.track('Delete-Template-Button-Click');
  };

  const onSelectTemplate = (template) => {
    setSelectedTemplate(template);
    setIsSecondTab('3');
    function parseString(inputString) {
      const pattern = /{{\s*(.*?)\s*\|\s*default\("(.*?)"\)\s*}}/g;
      const parsedData = {};
      let match;
  
      while ((match = pattern.exec(inputString)) !== null) {
          const key = match[1];
          const value = match[2];
          parsedData[key] = value;
      }
  
      return parsedData;
  }
  
  const parsedData = parseString(template?.message_content);
   setDefaultValue({
    patientNameDefaultValue: parsedData?.first_name || '',
    referringPhysicianDefaultValue: parsedData?.referring_physician_first_name || '',
    referringPhysicianDefaultNumber: parsedData?.referring_physician_phone_number || '',
    pcpDefaultName: parsedData?.primary_care_physician_first_name || '',
    pcpDefaultNumber: parsedData?.primary_care_physician_phone_number || '',
    providerDefaultName: parsedData?.provider_name ||  '',
   })
    dispatch(setTemplateIdToPreview(template.id));
    window.analytics.track('Use-Template-Button-Click');
  };
  return (
    <>
      {templates &&
        templates.map((t, i) => {
          return (
            <BorderWraper key={i}>
              <TemplateRowContainer>
                <Grid style={{ width: '70%' }}>
                  <TemplateTitle>{t.title}</TemplateTitle>
                </Grid>
                <TemplateBtnContainer>
                <TemplateButton
                    color="#00AEEF"
                    title="Select"
                    textColor="#FFFFFF"
                    clickHandler={() => onSelectTemplate(t)}
                    style={{width:'82px', background:'#00AEEF'}}
                    textStyles={{color:'white'}}
                  ></TemplateButton>
                  <DeleteIcon
                    style={{
                      color: "rgb(241, 43, 44)",
                      fontSize: 21,
                      cursor: "pointer",
                    }}
                    onClick={() =>{
                      if((permission && deletePermission) || !permission){
                        deleteTemplateHandle(t.id)
                      }else{
                        dispatch(
                          showAlert('danger', 'Error', `You don't have permission to delete message template`)
                        );
                      }                          
                    }}
                  />
                </TemplateBtnContainer>
              </TemplateRowContainer>
            </BorderWraper>
          );
        })}
    
    <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          disabled={templates && templates.length >= totalTemplates}
          onClick={onLoadMore}
          style={{
            border: "2px solid #00AEEF",
            color: "#00AEEF",
            width: "100px",
            height: "38px",
            background: "white",
            borderRadius: "5px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            marginBottom: "20px",
            alignItems: "center",
            cursor:
              templates && templates.length >= totalTemplates
                ? "not-allowed"
                : "pointer",
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              fontSize: "14px",
              fontWeight: 600,
              fontFamily: "Roboto Condensed",
            }}
          >
            {isLoading ? (
              <>Loading...</>
            ) : (
              <>
                Load More
                <img src={loadMoreIcon} />
              </>
            )}
          </span>
        </button>
      </div>
    </>
  );
};

const CreateTemplate = ({
  patientDetails,
  providerPhoneNumber,
  messageContentText,
  templateContent,
  setTemplateContent,
  isClickedPersionalized,
  personalizedClickHandler,
  defaultValue,
  handleChange,
  addDefaultHandler,
}) => {
  const classes = useStyles();

  return (
    <>
      <CreateTemplateContainer>
        {/* <SmsInfoContainer>
          <Grid className={classes.smsFromSec}>
            <SmsContent>SMS Content</SmsContent>
            <SmsContent>From: {providerPhoneNumber}</SmsContent>
          </Grid>
          <Grid className={classes.smsToSec}>
            <SmsContent style={{ marginLeft: '20%' }}>
              To: {patientDetails?.patient?.phone_number}
            </SmsContent>
            <div>
              <ArrowDropDownIcon style={{ cursor: 'pointer' }} />
            </div>
          </Grid>
        </SmsInfoContainer> */}

        <PersionalizedTextContainer>
          <PersonalizedInnerContainer
            isClicked={isClickedPersionalized}
            onClick={personalizedClickHandler}
          >
            <PeronalizedText>Select...</PeronalizedText>
            {isClickedPersionalized ? (
              <ArrowDropUpIcon
                style={{ cursor: "pointer", marginRight: 10 }}
              />
            ) : (
              <ArrowDropDownIcon
                style={{ cursor: "pointer", marginRight: 10 }}
              />
            )}
          </PersonalizedInnerContainer>
        </PersionalizedTextContainer>
        {isClickedPersionalized ? (
          <InsertPersonalizedSection>
            <PersonalizedRow noBorder>
              <BottomContainer>
                <PersionalizedText>{'{{first_name}}'}</PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="patientNameDefaultValue"
                  value={defaultValue.patientNameDefaultValue}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"

                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.patientNameDefaultValue)
                      ? alert('Value required!')
                      : addDefaultHandler('row1')
                  }
                >
                  Add
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>
                  {'{{referring_physician_first_name}}'}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="referringPhysicianDefaultValue"
                  value={defaultValue.referringPhysicianDefaultValue}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"

                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.referringPhysicianDefaultValue)
                      ? alert('Value required!')
                      : addDefaultHandler('row2')
                  }
                >
                  Add
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>
                  {'{{referring_physician_phone_number}}'}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="referringPhysicianDefaultNumber"
                  value={defaultValue.referringPhysicianDefaultNumber}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"

                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.referringPhysicianDefaultNumber)
                      ? alert('Value required!')
                      : addDefaultHandler('row3')
                  }
                >
                  Add
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>

            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>
                  {'{{primary_care_physician_first_name}}'}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="pcpDefaultName"
                  value={defaultValue.pcpDefaultName}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"

                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.pcpDefaultName)
                      ? alert('Value required!')
                      : addDefaultHandler('row4')
                  }
                >
                  Add
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>

            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>
                  {'{{primary_care_physician_phone_number}}'}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="pcpDefaultNumber"
                  value={defaultValue.pcpDefaultNumber}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"

                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.pcpDefaultNumber)
                      ? alert('Value required!')
                      : addDefaultHandler('row5')
                  }
                >
                  Add
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>{'{{provider_name}}'}</PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="providerDefaultName"
                  value={defaultValue.providerDefaultName}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"

                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.providerDefaultName)
                      ? alert('Value required!')
                      : addDefaultHandler('row6')
                  }
                >
                  Add
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
          </InsertPersonalizedSection>
        ) : (
          null
        )}
      </CreateTemplateContainer>
    </>
  );
};

export const TemplateButton = ({ icon, title, color,  clickHandler, width, style={}, iconContainerStyles={}, textStyles={} }) => {
  return (
    <>
      <ButtonContainer
        color={color}
        onClick={clickHandler}
        width={width? 125: 120}
        style={style}
      >
        {
          icon &&  <IconContainer style={iconContainerStyles}>{icon}</IconContainer>

        }
        <Text textcolor={color} style={textStyles}>{title}</Text>
      </ButtonContainer>
    </>
  );
};

const DatePickerInput = ({ onClick, date, time, isLoading,clickedButton }) => {
  const year = new Date().getFullYear();
  const formatDate = (date, time) => {
    return date
      ? `Send ${moment(date).format(
          'MMM DD, YYYY'
        )} at ${!time ? '--:--' : moment(time).format('HH:mm')} `
      : 'Choose Date & Time';
  };
  return (
    <DatePickerButton onClick={onClick}>
      <DateContainer>
      {
        isLoading && clickedButton==='add_future_action' ?   <Spinner animation="border" role="status" size="sm" /> :`${formatDate(date, time)}`
      }
      </DateContainer>
    </DatePickerButton>
  );
};
