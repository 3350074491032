import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectDateTime from '../SelectDateTime';
import RadiologySearchPage from '../../../pages/RadiologySearchPage/RadiologySearchPage';
import { SchedulingUIWrapper, CheckoutNavigationHolder, CheckoutNavigationButton } from '../common';
import {
  incrementCheckoutStep,
  createAppointmentForCommunicationType,
  setCurrentStep
} from '../../../actions/checkout';

const PreScanRadiologySearchPage = () => {
  const dispatch = useDispatch();
  const communicationOption = useSelector((state) => state.checkout.communicationOption);

  const [isSelectDateTimeActive, setIsSelectDateTimeActive] = useState(false);

  const handleNext = useCallback(async () => {
    if (!isSelectDateTimeActive) {
      setIsSelectDateTimeActive(true);
      return;
    }

    await dispatch(createAppointmentForCommunicationType());

    dispatch(incrementCheckoutStep(1));
    dispatch(setCurrentStep('CHECKOUT_PAYMENT'));
  }, [dispatch, isSelectDateTimeActive]);

  const handlePrevious = useCallback(() => {
    const optionToStep = {
      'VOICE': 'CHECKOUT_SELECT_TIME',
      'ONLINE': 'CHECKOUT_MEDICAL_HISTORY'
    };

    if (isSelectDateTimeActive) {
      setIsSelectDateTimeActive(false);
      return;
    }

    dispatch(incrementCheckoutStep(-1));
    dispatch(setCurrentStep(optionToStep[communicationOption]));
  }, [dispatch, isSelectDateTimeActive, communicationOption]);

  if (isSelectDateTimeActive) {
    return (
      <SchedulingUIWrapper>
        <div className='twelve-cols'>
          <SelectDateTime onPrevious={handlePrevious} onNext={handleNext} />
        </div>
      </SchedulingUIWrapper>
    );
  }

  return (
    <SchedulingUIWrapper>
      <div className='twelve-cols'>
        <RadiologySearchPage next={handleNext} title='Pick a location' />
      </div>
      <CheckoutNavigationHolder>
        <CheckoutNavigationButton onClick={handlePrevious} children='Previous' previous />
      </CheckoutNavigationHolder>
    </SchedulingUIWrapper>
  );
};

export default PreScanRadiologySearchPage;
