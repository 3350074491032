export const serializeState = (obj, key = 'data') => {
    const params = new URLSearchParams();
    params.set(key, JSON.stringify(obj));
    return params.toString();
};

export const deserializeState = (str, key = 'data') => {
    const params = new URLSearchParams(str);
    return JSON.parse(params.get(key));
};
