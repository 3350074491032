import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import Box from "../../../components/_styles/Box";
import { useState } from "react";
import { useEffect } from "react";
import {
  setEmailSubscriptionWeekly,
  setQualityMetricsEmailSubscription,
  setIncomingMessageSubscription,
  setEmailSubscriptionMonthly,
} from "../../../actions/radminSettings";
import { useRef } from "react";
import styled from "styled-components";
import { showAlert } from "../../../actions/radminUploads";

const StyledButton = styled.button`
  border: 2px solid ${({ subscriptionStatus }) => (!subscriptionStatus ? "#00AEEF" : "#B5B5B5")};
  color: ${({ subscriptionStatus }) =>
    !subscriptionStatus ? "#00AEEF" : "#B5B5B5"};
  background: ${({ subscriptionStatus }) =>
    !subscriptionStatus ? "#EFFBFF" : "#FFFFFF"};
  width: 123.88px;
  font-size: 14px;
  font-weight: ${({ subscriptionStatus }) => (!subscriptionStatus ? 700 : 400)};
  height: 26px;
  cursor: pointer;
  position: relative;

  &:first-child {
    border-radius: 4px 0 0 4px;
    border-right: 1px solid #00aeef;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
    border-left: 1px solid #00aeef;
  }
`;

const StyledSubButton = styled.button`
  border: 2px solid
    ${({ subscriptionStatus, subscriptionStatusEnabled }) =>
      !subscriptionStatusEnabled
        ? "#B5B5B5"
        : subscriptionStatus
        ? "#00AEEF"
        : "#B5B5B5"};
  color: ${({ subscriptionStatus, subscriptionStatusEnabled }) =>
    !subscriptionStatusEnabled
      ? "#B5B5B5"
      : subscriptionStatus
      ? "#00AEEF"
      : "#B5B5B5"};
  background: ${({ subscriptionStatus, subscriptionStatusEnabled }) =>
    !subscriptionStatusEnabled
      ? "#F1F1F1"
      : subscriptionStatus
      ? "#EFFBFF"
      : "#FFFFFF"};
  width: 123.88px;
  font-size: 14px;
  font-weight: ${({ subscriptionStatus, subscriptionStatusEnabled }) =>
    !subscriptionStatusEnabled ? 700 : subscriptionStatus ? 700 : 400};
  height: 26px;
  cursor: pointer;
  position: relative;
  cursor: ${({disabled})=>disabled?'not-allowed':'pointer'};

  &:first-child {
    border-radius: 4px 0 0 4px;
    border-right: ${({ subscriptionStatusEnabled }) =>
      !subscriptionStatusEnabled ? "1px solid #B5B5B5" : "1px solid #00AEEF"};
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
    border-left: ${({ subscriptionStatusEnabled }) =>
      !subscriptionStatusEnabled ? "1px solid #B5B5B5" : "1px solid #00AEEF"};
  }
`;

const Card = styled.div`
  border: 1px solid #dfe0eb;
  border-radius: 0px 0px 4px 4px;
  display: flex;
  padding: 30px;
  flex-wrap: wrap;
  background: #ffffff;
  max-width: 831px;
  justify-content: space-between;
  gap: 44px;
`;

const Header = styled.h1`
  color: #0c2945;
  font-size: 28px;
  margin-bottom: 13px;
  font-weight: 700;
  font-family: Roboto Condensed;
`;

const SubHeader = styled.h4`
  color: #000000;
  font-size: 16px;
  margin-bottom: 31px;
  font-weight: 600;
`;

const CardHeader = styled.h2`
  color: #252733;
  font-size: 22px;
  font-weight: 700;
  align-items: center;
  font-family: Roboto Condensed;
  margin-bottom: 21px;
  line-height: 22px;
`;

const CardContent = styled.p`
  color: #252733;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 20px;
`;

const CardLeftContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const ColumnContainer = styled.div`
display: flex;
flex-direction: column;
gap: 15px;
`

function Settings() {
  const user = useSelector((state) => state.auth.user);
  const [weeklySubscriptionStatus, setWeeklySubscriptionStatus] = useState(
    user.weekly_kpi_email_enabled
  );
  const [
    qualityMetricsEmailSubscriptionStatus,
    setQualityMetricsEmailSubscriptionStatus,
  ] = useState(user.quality_metrics_email_enabled);
  const [
    incomingSmsCountSubscriptionStatus,
    setIncomingSmsCountSubscriptionStatus,
  ] = useState(user.notify_incoming_sms === "unsubscribe" ? false : true);
  const [
    incomingSmsCountSubscriptionStatusType,
    setIncomingSmsCountSubscriptionStatusType,
  ] = useState(
    user.notify_incoming_sms === "immediate"
      ? "immediate"
      : user.notify_incoming_sms === "daily_summary"
      ? "daily_summary"
      : ""
  );
  const [monthlySubscriptionStatus, setMonthlySubscriptionStatus] = useState(
    user.monthly_kpi_email_enabled
  );

  const mounted = useRef();
  const qualityMetricsMounted = useRef();
  const incomingMessageMounted = useRef();
  const montlyEmailMounted = useRef();
  const dispatch = useDispatch();

  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);
  const changePermission = rolePermissions
    ?.find((itm) => itm.name === "Settings")
    ?.permissions.find(
      (itm) => itm.codename === "change_settings"
    )?.is_authorized;

  useEffect(() => {
    if (mounted.current) {
      dispatch(
        setEmailSubscriptionWeekly({
          user,
          kpiEmailEnabled: weeklySubscriptionStatus,
        })
      );
    }
    mounted.current = true;
  }, [weeklySubscriptionStatus]);

  useEffect(() => {
    if (montlyEmailMounted.current) {
      dispatch(
        setEmailSubscriptionMonthly({
          user,
          kpiEmailEnabled: monthlySubscriptionStatus,
        })
      );
    }
    montlyEmailMounted.current = true;
  }, [monthlySubscriptionStatus]);

  useEffect(() => {
    if (qualityMetricsMounted.current) {
      dispatch(
        setQualityMetricsEmailSubscription({
          user,
          qualityMetricsEmailEnabled: qualityMetricsEmailSubscriptionStatus,
        })
      );
    }
    qualityMetricsMounted.current = true;
  }, [qualityMetricsEmailSubscriptionStatus]);

  useEffect(() => {
    if (incomingMessageMounted.current) {
      dispatch(
        setIncomingMessageSubscription({
          user,
          incomingMessageEnabled: incomingSmsCountSubscriptionStatus,
          statusType: incomingSmsCountSubscriptionStatusType,
        })
      );
    }
    incomingMessageMounted.current = true;
  }, [
    incomingSmsCountSubscriptionStatus,
    incomingSmsCountSubscriptionStatusType,
  ]);

  return (
    <Box>
      <Row>
        <Col md={12} lg={12} xl={10}>
          <Header>Within Health Subscription Center</Header>
          <SubHeader>Let us know what topics you'd like to receive</SubHeader>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12} xl={10}>
          <Card>
            <CardLeftContainer>
              <CardHeader>Weekly KPI Stats email </CardHeader>
              <CardContent>
                This email is sent on a weekly basis and has the metrics around
                <br />
                recommendations and messages for your organization
              </CardContent>
            </CardLeftContainer>

            <ButtonsContainer>
              <StyledButton
                subscriptionStatus={weeklySubscriptionStatus}
                onClick={() => {
                  if ((permission && changePermission) || !permission) {
                    window.analytics.track(
                      "Weekly-KPI-Stats-Email-Unsubscription-Click"
                    );
                    setWeeklySubscriptionStatus(false);
                  } else {
                    dispatch(
                      showAlert(
                        "danger",
                        "Error",
                        `You don't have permission to update subscriptions`
                      )
                    );
                  }
                }}
              >
                Unsubscribe
              </StyledButton>
              <StyledButton
                subscriptionStatus={!weeklySubscriptionStatus}
                onClick={() => {
                  if ((permission && changePermission) || !permission) {
                    window.analytics.track(
                      "Weekly-KPI-Stats-Email-Subscription-Click"
                    );
                    setWeeklySubscriptionStatus(true);
                  } else {
                    dispatch(
                      showAlert(
                        "danger",
                        "Error",
                        `You don't have permission to update subscriptions`
                      )
                    );
                  }
                }}
              >
                Subscribe
              </StyledButton>
            </ButtonsContainer>
          </Card>
        </Col>
        <Col md={12} lg={12} xl={10}>
          <Card>
            <CardLeftContainer>
              <CardHeader>Monthly KPI Stats email </CardHeader>
              <CardContent>
                This email is sent on a monthly basis and has the metrics around
                <br />
                recommendations and messages for your organization
              </CardContent>
            </CardLeftContainer>

            <ButtonsContainer>
              <StyledButton
                subscriptionStatus={monthlySubscriptionStatus}
                onClick={() => {
                  if ((permission && changePermission) || !permission) {
                    window.analytics.track(
                      "Monthly-KPI-Stats-Email-Unsubscription-Click"
                    );
                    setMonthlySubscriptionStatus(false);
                  } else {
                    dispatch(
                      showAlert(
                        "danger",
                        "Error",
                        `You don't have permission to update subscriptions`
                      )
                    );
                  }
                }}
              >
                Unsubscribe
              </StyledButton>
              <StyledButton
                subscriptionStatus={!monthlySubscriptionStatus}
                onClick={() => {
                  if ((permission && changePermission) || !permission) {
                    window.analytics.track(
                      "Monthly-KPI-Stats-Email-Subscription-Click"
                    );
                    setMonthlySubscriptionStatus(true);
                  } else {
                    dispatch(
                      showAlert(
                        "danger",
                        "Error",
                        `You don't have permission to update subscriptions`
                      )
                    );
                  }
                }}
              >
                Subscribe
              </StyledButton>
            </ButtonsContainer>
          </Card>
        </Col>
        <Col md={12} lg={12} xl={10}>
          <Card>
            <CardLeftContainer>
              <CardHeader>Weekly Quality Metrics email </CardHeader>
              <CardContent>
                This email is sent on a weekly basis and has the metrics around
                <br />
                recommendations and messages for your organization{" "}
              </CardContent>
            </CardLeftContainer>

            <ButtonsContainer>
              <StyledButton
                subscriptionStatus={qualityMetricsEmailSubscriptionStatus}
                onClick={() => {
                  if ((permission && changePermission) || !permission) {
                    window.analytics.track(
                      "Weekly-Quality-Metrics-Email-Unsubscription-Click"
                    );
                    setQualityMetricsEmailSubscriptionStatus(false);
                  } else {
                    dispatch(
                      showAlert(
                        "danger",
                        "Error",
                        `You don't have permission to update subscriptions`
                      )
                    );
                  }
                }}
              >
                Unsubscribe
              </StyledButton>
              <StyledButton
                subscriptionStatus={!qualityMetricsEmailSubscriptionStatus}
                onClick={() => {
                  if ((permission && changePermission) || !permission) {
                    window.analytics.track(
                      "Weekly-Quality-Metrics-Email-Subscription-Click"
                    );
                    setQualityMetricsEmailSubscriptionStatus(true);
                  } else {
                    dispatch(
                      showAlert(
                        "danger",
                        "Error",
                        `You don't have permission to update subscriptions`
                      )
                    );
                  }
                }}
              >
                Subscribe
              </StyledButton>
            </ButtonsContainer>
          </Card>
        </Col>
        <Col md={12} lg={12} xl={10}>
          <Card>
            <CardLeftContainer>
              <CardHeader>Daily Incoming SMS Count email </CardHeader>
              <CardContent>
                This email is sent on a daily basis or immediately <br /> to notify you of the{" "}
                number of new text messages.{" "}
              </CardContent>
            </CardLeftContainer>

            <ColumnContainer>
              <ButtonsContainer>
                <StyledButton
                  subscriptionStatus={incomingSmsCountSubscriptionStatus}
                  onClick={() => {
                    if ((permission && changePermission) || !permission) {
                      window.analytics.track(
                        "Daily-Incoming-Sms-Count-Unsubscription-Click"
                      );
                      setIncomingSmsCountSubscriptionStatus(false);
                    } else {
                      dispatch(
                        showAlert(
                          "danger",
                          "Error",
                          `You don't have permission to update subscriptions`
                        )
                      );
                    }
                  }}
                >
                  Unsubscribe
                </StyledButton>
                <StyledButton
                  subscriptionStatus={!incomingSmsCountSubscriptionStatus}
                  onClick={() => {
                    if ((permission && changePermission) || !permission) {
                      window.analytics.track(
                        "Daily-Incoming-Sms-Count-Subscription-Click"
                      );
                      setIncomingSmsCountSubscriptionStatus(true);
                      setIncomingSmsCountSubscriptionStatusType(
                        "daily_summary"
                      );
                    } else {
                      dispatch(
                        showAlert(
                          "danger",
                          "Error",
                          `You don't have permission to update subscriptions`
                        )
                      );
                    }
                  }}
                >
                  Subscribe
                </StyledButton>
              </ButtonsContainer>
              <ButtonsContainer>
                <StyledSubButton
                  subscriptionStatus={
                    incomingSmsCountSubscriptionStatusType === "daily_summary"
                  }
                  subscriptionStatusEnabled={incomingSmsCountSubscriptionStatus}
                  onClick={() => {
                    if ((permission && changePermission) || !permission) {
                      window.analytics.track(
                        "Daily-Incoming-Sms-Count-Daily-Summary-Click"
                      );
                      setIncomingSmsCountSubscriptionStatusType(
                        "daily_summary"
                      );
                    } else {
                      dispatch(
                        showAlert(
                          "danger",
                          "Error",
                          `You don't have permission to update subscriptions`
                        )
                      );
                    }
                  }}
                  disabled={!incomingSmsCountSubscriptionStatus}
                >
                  Daily Summary
                </StyledSubButton>
                <StyledSubButton
                  subscriptionStatus={
                    incomingSmsCountSubscriptionStatusType === "immediate"
                  }
                  subscriptionStatusEnabled={incomingSmsCountSubscriptionStatus}
                  onClick={() => {
                    if ((permission && changePermission) || !permission) {
                      window.analytics.track(
                        "Daily-Incoming-Sms-Count-Immediate-Click"
                      );
                      setIncomingSmsCountSubscriptionStatusType("immediate");
                    } else {
                      dispatch(
                        showAlert(
                          "danger",
                          "Error",
                          `You don't have permission to update subscriptions`
                        )
                      );
                    }
                  }}
                  disabled={!incomingSmsCountSubscriptionStatus}
                >
                  Immediate
                </StyledSubButton>
              </ButtonsContainer>
            </ColumnContainer>
          </Card>
        </Col>
      </Row>
    </Box>
  );
}

export default Settings;
