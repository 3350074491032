import API from '../../libs/api';

export const GET_RADMIN_UPLOAD_FETCH = 'GET_RADMIN_UPLOAD_FETCH';
export const GET_RADMIN_UPLOAD_RESOLVE = 'GET_RADMIN_UPLOAD_RESOLVE';
export const GET_RADMIN_UPLOAD_REJECT = 'GET_RADMIN_UPLOAD_REJECT';

export const getRadminUploads = (userId, page = 1) => async (
  dispatch
) => {
  try {
    dispatch({ type: GET_RADMIN_UPLOAD_FETCH });

    const uploadHistory = await API.getRequest('/api/v1/upload/?page=1');

    console.log("THIS IS UPLOAD DATA", uploadHistory);

    dispatch({ type: GET_RADMIN_UPLOAD_RESOLVE});

  } catch (error) {
    console.error(error);
    dispatch({ type: GET_RADMIN_UPLOAD_REJECT });
  }
};
