import React, { useMemo, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import ImageUploader from "quill-image-uploader";
import { uploadFaxImage } from "../../../actions/radminUploads";
import { useDispatch } from "react-redux";
import "quill-image-uploader/dist/quill.imageUploader.min.css";
import './style.css';
import moment from "moment";
const fontSizeArr = ['8px','9px','10px','12px','14px','16px','18px','20px','24px','26px','28px','36px','48px','72px'];

let Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

let Font = Quill.import("formats/font");
Font.whitelist = ["Roboto", "Raleway", "Montserrat", "Lato", "Rubik","Roman"];
Quill.register(Font, true);

Quill.register("modules/imageUploader", ImageUploader);

const Container = styled.div`
  & .ql-editor.ql-blank::before {
    font-style: normal;
  }
  & .ql-container {
    height: 150px;
  }
`;

const Button = styled.button`
cursor:pointer;
color:#00AEEF;
background:transparent;
border:2px solid #00AEEF;
margin-bottom:10px;
font: bold 14px "Open Sans", sans-serif;
height:38px;
border-radius: 4px;
`

const Editor = ({
  placeholder,
  templateContent,
  setTemplateContent,
  quillRef,
  disableImage = false,
  customImageUpload = true,
}) => {
  const dispatch = useDispatch();

 

  const handleChange = (html) => {
    setTemplateContent(html);
  };

  const disableImageConfig = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: Font.whitelist }],
      [{ 'size': fontSizeArr }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const customImageConfig = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: Font.whitelist }],
      [{ 'size': fontSizeArr }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link", "image"],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageUploader: {
      upload: (file) => {
        return new Promise((resolve) => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("destination", "mfax_drive");
          dispatch(uploadFaxImage(formData)).then((result) => {
            resolve(result?.response?.file);
          });
        });
      },
    },
  };

  const defaultImageConfig = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: Font.whitelist }],
      [{ 'size': fontSizeArr }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link", "image"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const insertCurrentDate = () => {
    const currentDate = moment().format("MMMM DD, YYYY");
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();
    if (selection) {
      const { index } = selection;
      editor.insertText(index, currentDate, "bold");
      editor.setSelection(index + currentDate.length);
    } else {
      editor.insertText(editor.getLength(), currentDate, "bold");
    }
  };


  const modules = useMemo(
    () =>
      disableImage
        ? disableImageConfig
        : customImageUpload
        ? customImageConfig
        : defaultImageConfig,
    [disableImage]
  );

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const CustomToolbar = () => (
    <div id="toolbar">
      <Button onClick={()=>{
        insertCurrentDate();
      }} className="ql-currentDate">Insert Timestamp</Button>
    </div>
  );

 
  return (
    <Container>
      <CustomToolbar />
      <ReactQuill
        theme={"snow"}
        onChange={handleChange}
        value={templateContent}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        ref={quillRef}
      />
    </Container>
  );
};

export default Editor;
