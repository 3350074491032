import API from '../../libs/api';

export const GET_SCAN_FETCH = 'GET_SCAN_FETCH';
export const GET_SCAN_RESOLVE = 'GET_SCAN_RESOLVE';
export const GET_SCAN_REJECT = 'GET_SCAN_REJECT';

export const getScan = () => async (dispatch) => {
  try {
    dispatch({ type: GET_SCAN_FETCH });
    const response = await API.getRequest('/api/v1/scan/');
    dispatch({ type: GET_SCAN_RESOLVE, payload: response.data.results });

    return response.data.results;
  } catch (error) {
    console.error(error);
    dispatch({ type: GET_SCAN_REJECT });
  }
};
