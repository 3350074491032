import React from "react";
import styled from "styled-components";
import { ReactComponent as NoAccessIcon } from "../../assets/no-access-icon.svg";

const Container = styled.div`
  height: ${({ theme }) => `calc(100vh - ${theme.header.height}px)`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 290px;
  text-align: center;
`;

const Header = styled.h2`
  font-weight: 500;
  font-size: 32px;
  font-family: Roboto Condensed;
  color: #0c2945;
  margin-top:9px;
`;

const SubHeader = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #0c2945;
`;

const NoAccessRoute = ({ viewPermissionGroup }) => {
  return (
    <Container>
      <Row>
        <NoAccessIcon />
        <Header>No Access</Header>
        <SubHeader>
          You don’t have access to view {viewPermissionGroup}. If access is
          needed, please ask your radmin to update your permissions.
        </SubHeader>
      </Row>
    </Container>
  );
};

export default NoAccessRoute;
