import styled from 'styled-components';

export const DisclaimerHolder = styled.div`
  margin: 20px 0px;
  display: flex;

  input {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    input {
      margin-left: 5px;
      transform: scale(1.5);
    }

    label {
      margin: -5px 0 0 15px;
    }
  }
`;

export const NoAccountText = styled.div`
  margin-top: 16px;
  line-height: 18px;
  text-align: center;
  font-size: 14px;
  color: #3f4857;
`;

export const DisclaimerText = styled.label`
  font-size: 14px;
  line-height: 18px;
  margin-left: 8px;
  color: #2c2c2c;
  cursor: pointer;
`;
