import moment from 'moment';

export function isValidDob(dob) {
    const age = moment(dob);
    const now = moment();
    if (!age.isValid() || now.diff(age, 'years') < 18) return false;
    return true;
}

export const sortByAsc = (arr, propertyForSort) => {
    return arr && arr.sort((a, b) => {
        if (!a[propertyForSort] && b[propertyForSort]) return -1;
        if (a[propertyForSort] && !b[propertyForSort]) return 1;
        return a[propertyForSort] > b[propertyForSort] ? 1 : -1;
    });
};

export const setModalityCase = (name) => {
    switch (name) {
    case 'ct scan':
        return 'CT';
    case 'ct':
        return 'CT';  
    case 'xray':
        return 'X-ray';
    case 'mri':
        return 'MRI';
    case 'pet':
        return 'PET';
    case 'mr':
        return 'MR';
    default:
        return `${name.slice(0,1).toUpperCase()}${name.slice(1)}`;
    }
};

export const prepareFollowUpStatusReq = (status) => {
    if(!status) {
        return '';
    }
    const makeStatusParam = (status) => `status=${status.trim().replace(' ', '_').toLowerCase()}&`;
    return typeof status === 'string' ? makeStatusParam(status) :
        status.map(({status}) => makeStatusParam(status));
};

export const generateColor = () => {
    const userColors = ['#D6CFFF', '#FFCFCF', '#FFCFFA', '#EDFFCF', '#CFFCFF'];
    return userColors[Math.floor(Math.random() * userColors.length)];
};

export const formatDate = (date) => {
    const _date = moment(date);
    if(!_date.isValid()) {
        return "";
    }
    return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
}
