import React, { useState, useCallback, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import AccountInfo from './AccountInfo';
import PhysicalAddress from './PhysicalAddress';
import MedicalHistory from './MedicalHistory';
import ShowMedicalHistoryInfo from './ShowMedicalHistoryInfo';
import UpdateDoctorInfo from './UpdateDoctorInfo';
import { NavigationButton } from '../../components/Design/button';
import { FormRow, FormItem } from '../../components/Design/form';
import { updateMedicalHistory, updateLastAppointment } from '../../actions/checkout';

const InnerContainer = styled.div`
  padding: 40px 25px;
  background: #e5e5e5;
  height: 100%;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeadlineText = styled.div`
  font-weight: bold;
  font-size: 1.556rem;
  line-height: 120%;
  color: #2e2f30;
`;

const TopRowForms = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;

  @media (max-width: 991px) {
    display: block;
  }
`;

const EditButton = styled(NavigationButton)`
  padding: 15px 30px;
  width: 200px;
  margin-left: auto;
`;

const SaveButton = styled(NavigationButton)`
  padding: 15px 30px;
  width: 200px;
  margin: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const SecondRowForms = styled.div``;

const checkData = (healthHistory) => {
  if (!healthHistory.gender) return true;

  if (!healthHistory.dob) return true;

  const conditions = Object.values(healthHistory.medicalConditions);
  if (conditions.length < 1) return true;

  const symps = Object.values(healthHistory.symptoms);
  if (symps.length < 1) return true;

  if (!healthHistory.allergyOption) return true;

  const metals = Object.values(healthHistory.metalItems);
  if (metals.length < 1) return true;

  return false;
};

const MainSectionForms = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const healthHistory = useSelector((state) => state.checkout);

  const [editingMedicalHistory, setEditingMedicalHistory] = useState(false);

  const handleSaveMedicalHistory = useCallback(
    (event) => {
      event.preventDefault();
      setEditingMedicalHistory(false);

      dispatch(
        updateMedicalHistory({
          id: user.id,
          gender: healthHistory.gender,
          dob: healthHistory.dob,
          medicalConditions: healthHistory.medicalConditions,
          currentMeds: healthHistory.currentMeds,
          allergyOption: healthHistory.allergyOption,
          metalItems: healthHistory.metalItems
        })
      );

      dispatch(updateLastAppointment(healthHistory.symptoms));
    },
    [dispatch, user, healthHistory]
  );

  return (
    <Fragment>
      <InnerContainer>
        <TopRow>
          <HeadlineText>Your personal information</HeadlineText>
        </TopRow>
        <TopRowForms>
          <AccountInfo />
          <PhysicalAddress />
        </TopRowForms>
        {user?.role === 'doctor' && (
          <SecondRowForms>
            <UpdateDoctorInfo user={user} />
          </SecondRowForms>
        )}
        {user?.role === 'patient' && (
          <SecondRowForms>
            {editingMedicalHistory ? <MedicalHistory /> : <ShowMedicalHistoryInfo user={user} />}
            <FormRow>
              <FormItem>
                {!editingMedicalHistory ? (
                  <EditButton primary onClick={() => setEditingMedicalHistory(!editingMedicalHistory)}>
                    Edit Medical History
                  </EditButton>
                ) : (
                  <ButtonContainer>
                    <SaveButton primary onClick={() => setEditingMedicalHistory(!editingMedicalHistory)}>
                      Cancel
                    </SaveButton>
                    <SaveButton primary onClick={handleSaveMedicalHistory} disabled={checkData(healthHistory)}>
                      Save Medical History
                    </SaveButton>
                  </ButtonContainer>
                )}
              </FormItem>
            </FormRow>
          </SecondRowForms>
        )}
      </InnerContainer>
    </Fragment>
  );
};

export default MainSectionForms;
