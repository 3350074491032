import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components';
import moment from 'moment';

import { ReactComponent as IconArrowLeft } from '../../../assets/arrow_left.svg';
import { CheckoutNavigationButton } from '../common';
import { setDateTime } from '../../../actions/checkout';
import { OrderSummaryPanel } from '../PaymentInfo/PaymentRightPanel';
import Flex from '../../_styles/Flex';
import Calendar from './Calendar';

const Container = styled.div``;

const Heading = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  color: #0c2945;
`;

const SubHeading = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #3f4857;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
`;

const SelectDateTime = ({ onPrevious, onNext, ...props }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const dispatch = useDispatch();
  const preScanDate = useSelector((state) => state.checkout.dateTime['pre-scan']);
  const plan = useSelector((state) => state.scan.items.find((i) => i.id === state.checkout.planId));
  const location = useSelector((state) => state.provider.locations.find((l) => l.id === state.checkout.locationId));

  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  const onCalendarChange = useCallback((date, time) => {
    setDate(date);
    setTime(time);
  }, []);

  const onScheduleClick = useCallback(async () => {
    const [hh, mm] = time.split(':');
    const dateTime = moment(date).hour(hh).minute(mm).toISOString();

    dispatch(setDateTime({ type: 'scan', data: dateTime }));
    onNext();

    window.analytics.track('Scan Appt Submit-2', { dateTime, locationId: location.id, address: location.address });
  }, [dispatch, onNext, date, time, location]);

  return (
    <Container {...props}>
      <Flex flexDirection={isMobile ? 'column' : 'row'}>
        <Flex.Col flex={3}>
          <Heading children='Schedule scan' />
          <SubHeading children='Please select the date and time for this scan.' />
          <Calendar date={date} time={time} onChange={onCalendarChange} disabledBeforeDate={preScanDate} />
        </Flex.Col>
        <Flex.Col flex={2} justifyContent='center' alignItems='center' isFlex>
          <OrderSummaryPanel plan={plan} style={isMobile ? { flex: '1' } : undefined} />
        </Flex.Col>
      </Flex>
      <ButtonGroup>
        <CheckoutNavigationButton onClick={onPrevious} type='button' previous>
          <IconArrowLeft style={{ marginRight: 5 }} />
          <span children='Previous step' />
        </CheckoutNavigationButton>
        <CheckoutNavigationButton
          disabled={!date || !time}
          onClick={onScheduleClick}
          children='Schedule scan'
          type='button'
        />
      </ButtonGroup>
    </Container>
  );
};

export default SelectDateTime;
