import React, { useState } from 'react'
import styled from 'styled-components';
import { ReactComponent as Search } from '../../../assets/search.svg';
const cancelIcon = `${process.env.PUBLIC_URL}/${'cancel-circled.svg'}`;



const InputContainer = styled.div`
  width: 450px;
  margin-bottom: 10px;
  margin-right: 50px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    width: 350px;
  }
  @media (max-width: 957px) {
    margin-top: 5px;
  }
`
const SearchIcon = styled.i`
  display: inline-block;
  position: absolute;
  margin-left: 50px;
  color: green;
  min-width: 45px;
  @media (max-width: 768px) {
    margin-left: 10px;
  }
`
const CustomPlaceholder = styled.span`
  position: absolute;
  margin-left: 100px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #000000;
  @media (max-width: 768px) {
    margin-left: 60px;
  }
`
const Input = styled.input`
  width: 100%;
  background: #F7F8FF;
  border: 1px solid #DFE0EB;
  border-radius: 5px;
  height: 33px;
  text-align: center;
  font: normal 16px 'Roboto Condensed', sans-serif;
`;

const ClearButton = styled.button`
  position: absolute;
  margin-left: 410px;
  border-radius: 5%;
  z-index: 1;
  border: none;
  cursor: pointer;
  color: #000000;
  background-color: transparent;
  transform: translateX(2px);
  @media (max-width: 768px) {
    margin-left: -30px;
  }
`;

function SearchField({ placeholder, value, onChangeHandle, onReset, onSearchEnter, styles={}, placeholderStyles={}, inputStyles={}, searchIconStyles={}, clearIconStyle={}, SearchIconCustom="", disabled=false }) {
    const [showPlaceholder, setShowPlaceholder] = useState(true)

    const keyDownHandler = (e) => {
        if (e.keyCode === 13) {
            onSearchEnter()
        }
    }

    const onResetHandle = () => {
        setShowPlaceholder(true)
        onReset()
    }

    return (
        <InputContainer style={styles}>
            {showPlaceholder && !value &&
             <>
                <SearchIcon style={searchIconStyles}>
                   {SearchIconCustom? SearchIconCustom : <Search /> }  
                </SearchIcon>
                <CustomPlaceholder style={placeholderStyles}>{placeholder}</CustomPlaceholder>
            </>
            }
            <Input
                type="text"
                onFocus={() => setShowPlaceholder(false)}
                onKeyDown={keyDownHandler}
                onChange={onChangeHandle}
                value={value}
                style={inputStyles}
                disabled={disabled}
            />
            {(!showPlaceholder ||  value) && <ClearButton style={clearIconStyle} onClick={onResetHandle}><img src={cancelIcon}/></ClearButton>}
        </InputContainer>
    )
}

export default SearchField
