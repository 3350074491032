import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import moment from 'moment';
import { Title } from './RecomendationModalities';
import { isArray } from 'lodash';
import DatePicker from 'react-datepicker';
import Flex from '../../../components/_styles/Flex';
import { ReactComponent as TriangleDownIcon } from '../../../assets/triangle-down.svg';
import { Funnel, ResponsiveContainer, Tooltip, LabelList, FunnelChart, Legend } from 'recharts';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const Card = styled.div`
  background: #FFFFFF;
  border: 1px solid #C2CCD9;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  border-radius: 8px;
`

export const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin: 16px 16px 22px 0;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 55px;
  }
`

export const DatePickerContainer = styled(Flex)`
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`

export const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D2D3D7;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
`

export const DateContainer = styled(Flex)`
  margin-right: 15px;
`

export const TitleAndLegend = styled(Flex)`
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const FunnelContainer = styled(Flex)`
  justify-content: center;
  margin-left: 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
      margin: 20px 5px;
  }
`

const PercentContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
  font-size: 50px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 400;
  color: #0C2945
  line-height: 73px;
  text-decoration: underline;
`

export class DatePickerInput extends React.Component {
  formatDate = (date) => {
    return date ? moment(date).format('MMM, YY') : 'No Date';
  }
  render(props) {
    const {startDate, endDate} = this.props
    return (
      <DatePickerButton onClick={this.props.onClick}>
        <DateContainer>{`${this.formatDate(startDate)} - ${this.formatDate(endDate)}`}</DateContainer>
        <TriangleDownIcon />
      </DatePickerButton>
    )
  }
}

// const DatePickerInput = ({ onClick, startDate, endDate }) => {
//   const formatDate = (date) => {
//     return date ? moment(date).format('MMM, YY') : 'No Date';
//   }
//   return (
//     <DatePickerButton onClick={onClick}>
//       <DateContainer>{`${formatDate(startDate)} - ${formatDate(endDate)}`}</DateContainer>
//       <TriangleDownIcon />
//     </DatePickerButton>
//   )
// };

const AdherenceFunnelChart = ({ data }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [percent, setPercent] = useState(null);
  const [totalExams, setTotalExams] = useState(null);
  const [totalRecs, setTotalRec] = useState(null);

  const minDate = data && data?.length && new Date(data[0].date);
  const maxDate = data && data?.length && new Date(data[data.length - 1].date);
  const loading = useSelector(state => state.radAdmin.funnelAdherence.isLoading);


  useEffect(() => {
    if (data?.length) {
      if (data.length > 0) {
        if (data.length > 6) {
          setStartDate(moment(data[data.length - 6].date, "MM/DD/YYYY").toDate());
          setEndDate(moment(data[data.length - 1].date, "MM/DD/YYYY").toDate());
          return
        }
        setStartDate(moment(data[0].date, "MM/DD/YYYY").toDate());
        setEndDate(moment(data[data.length - 1].date, "MM/DD/YYYY").toDate());
      } else {
        setStartDate(Date.now());
        setEndDate(Date.now());
      }
    }
  }, [data]);

  useEffect(() => {
    if (startDate && endDate && data?.length) {
      const sortedData = data.filter(d => (
        d.timestamp >= startDate.getTime() && d.timestamp <= endDate.getTime()
      ));
      const totalExamSum = sortedData.reduce((acc, item) => {
        return {
          totalExams: acc.totalExams + item.imagingExamsCount,
          totalRec: acc.totalRec + item.followUpsMadeCount,
        }
      }, { totalExams: 0, totalRec: 0 });
      setFilteredData([{
        value: totalExamSum.totalExams,
        name: `${totalExamSum.totalExams} Total Reports`,
        fill: '#8884d8',
        fontFamily: 'Roboto Condensed',
        fontStyle: 'normal',
        fontSize: '18px'
      },
      {
        value: totalExamSum.totalRec,
        name: `${totalExamSum.totalRec} Total Reports with Recommendations`,
        fill: 'rgb(206, 32, 48)',
        fontFamily: 'Roboto Condensed',
        fontStyle: 'normal',
        fontSize: '18px'
      },
      ]);
      setPercent(totalExamSum.totalRec / totalExamSum.totalExams * 100);
      setTotalExams(totalExamSum.totalExams);
      setTotalRec(totalExamSum.totalRec);
    }
  }, [startDate, endDate]);

  const onChange = dates => {
    if (isArray(dates)) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      window.analytics.track('Funnel-Chart-Datetime-Selection');
    }
  };

  // if (!filteredData) return null;
  return (
    <Card>
      <Header>
        <TitleAndLegend>
          <Title>Follow-Ups Reco Rate</Title>
        </TitleAndLegend>
        <DatePickerContainer>
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            dateFormat="MM/yyyy"
            customInput={<DatePickerInput startDate={startDate} endDate={endDate} />}
            shouldCloseOnSelect={false}
            selectsRange
            showMonthYearPicker
            minDate={minDate}
            maxDate={maxDate}
          />
        </DatePickerContainer>
      </Header>
      <Row style={{marginBottom: '.5%'}}>
      
      {
        loading?'':<>
          <Col  md={{ span: 3, offset: 1 }} style={{color:'rgb(136, 132, 216)'}}>
          <Row>
          <div style={{width: '20px', height: '20px', marginRight: '8px',borderRadius: '5px', background: 'rgb(136, 132, 216)'}}></div>
          {`${totalExams}`}
          <div> - Reports</div>
          </Row>
        </Col>
        <Col style={{color:'rgb(206, 32, 48)'}}>
          <Row>
          <div style={{width: '20px', height: '20px', marginRight: '8px',borderRadius: '5px', background: 'rgb(206, 32, 48)'}}></div>
          {`${totalRecs}`}
          <div> - Reports with Recs</div>
          </Row>
        </Col>
        </>
      }
      </Row>
      <PercentContainer> {loading? '' : `${percent && percent.toFixed(2) + '%'}`}</PercentContainer>
      <FunnelContainer>
        <ResponsiveContainer width='80%' height={220}>
       { loading ? <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "320px",
                }}
              >
                <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{color:'black'}}
              /> 
              </div>:
          <FunnelChart width={420} height={255}>
            <Tooltip />
            <Funnel
              dataKey="value"
              data={filteredData}
              isAnimationActive
            >
              <LabelList position="center" fill="#000" stroke="none" dataKey="value" />
            </Funnel>
          </FunnelChart>
}
        </ResponsiveContainer>
      </FunnelContainer>
      
    </Card>
  );
}

export default AdherenceFunnelChart;
