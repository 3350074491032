import React from 'react';
import { connect, useSelector } from 'react-redux';
import {
  SectionHolder,
  SubsectionHeader,
  CheckboxOptionHolder,
  CheckboxOptionItem,
} from './common';
import * as checkoutActions from '../../../actions/checkout';

const OPTIONS = [
  'None',
  'Asthma',
  'Cancer',
  'Cardiac Disease',
  'Diabetes',
  'Hypertension',
  'Psychiatric disorder',
  'Epilepsy',
];

function MedicalCondition({ toggleMedicalCondition }) {
  const medicalConditions = useSelector(state=>state.checkout.medicalConditions);
  return (
    <SectionHolder>
      <SubsectionHeader>
        Do any of the conditions apply to you or to any members of your
        immediate relatives?
      </SubsectionHeader>
      <CheckboxOptionHolder>
        {OPTIONS.map((opt, i) => {
          return (
            <CheckboxOptionItem
              toggleFunction={toggleMedicalCondition}
              text={opt}
              key={`MedicalCondition-${i}`}
              checked={medicalConditions[opt]}
            />
          );
        })}
      </CheckboxOptionHolder>
    </SectionHolder>
  );
}

export default connect(null, checkoutActions)(MedicalCondition);
