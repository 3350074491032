import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { assignAppointment, unassignAppointment } from '../../../actions/doctor';

const SRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #000000;

  & > * {
    margin-right: 10px;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

const SCell = styled.div`
  flex: ${({ flex }) => flex};
`;

const SButton = styled.button.attrs({ type: 'button' })`
  padding: 10px 20px;
  border-radius: 8px;
  outline: none !important;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  background: ${({ mode }) => (mode === 'green' ? '#E6FFEC' : mode === 'blue' ? '#D9ECFF' : 'transparent')};
  color: ${({ mode }) => (mode === 'green' ? '#64C67C' : mode === 'blue' ? '#00AEEF' : '#EB5757')};
  border: ${({ mode }) => (mode === 'red' ? '2px solid #EB5757' : '2px solid transparent')};
`;

const Row = ({ type, appointment }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.auth.user.id);

  const onClickAccept = useCallback(() => {
    dispatch(assignAppointment(appointment.scan_purchase, userId));
  }, [dispatch, appointment, userId]);

  const onClickCancel = useCallback(() => {
    dispatch(unassignAppointment(appointment.scan_purchase));
  }, [dispatch, appointment]);

  const onClickView = useCallback(() => {
    history.push(`/auth/patients/${appointment.patient.id}?scan_purchase=${appointment.scan_purchase}`);
  }, [history, appointment]);

  if (type === 'new') {
    return (
      <SRow>
        <SCell children={moment(appointment.date_time).format('dddd, MMMM Do h:mm A')} flex={1} />
        <SButton onClick={onClickAccept} mode='green' children='Accept' />
      </SRow>
    );
  }

  if (type === 'upcoming') {
    return (
      <SRow>
        <SCell children={`${appointment.patient.first_name} ${appointment.patient.last_name}`} flex={2} />
        <SCell children={moment(appointment.date_time).format('h:mm A')} flex={1} />
        <SCell children={appointment.appointment_type} flex={1} />
        <SButton onClick={onClickView} mode='blue' children='View Profile' />
        <SButton onClick={onClickCancel} mode='red' children='Cancel' />
      </SRow>
    );
  }

  return null;
};

export default Row;
