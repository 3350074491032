import React from "react";
import WizardWrapper from "../../_styles/WizardWrapper";
import Panel from "../../_styles/Panel";

const WizardLayout = ({ children }) => {
  return (
    <WizardWrapper className="">
      <div className="site-header" />

        <Panel className="panel-wrapper">{children}</Panel>
    </WizardWrapper>
  );
};

export default WizardLayout;
