import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Text from '../../../components/_styles/Text';
import { ExploreButton, ShowMoreInfoModal } from '../common';

const ItemHolder = styled.div`
  display: flex;
  flex-direction: column;
  background: #e3e6eb;
  border-radius: 12px;
  padding: 40px;
  color: black;
  text-align: center;
  align-items: center;
`;

const ProductName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 128%;
  margin-top: 20px;
`;

const Price = styled.div`
  font-weight: 900;
  font-size: 28px;
  line-height: 128%;
  margin-top: 10px;
  margin-left: -5px;
`;

const LinkText = styled(Text)`
  cursor: pointer;
  padding: 10px;
  color: #00AEEF;
`;

const PlanItem = ({ id, name, image, price, description }) => {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);

  const onSelect = useCallback(() => {
    history.push(`${history.location.pathname}/${id}`);
    window.analytics.track('Scan Select', { id, name, price, description });
  }, [history, id, name, price, description]);

  const onShowModal = useCallback(() => {
    setShowModal((value) => !value);
    window.analytics.track('Learn More Modal Select');
  }, []);

  return (
    <div className='four-cols'>
      <ItemHolder>
        <img src={image} alt='body-cutout' />
        <ProductName>{name}</ProductName>
        <Price>{`$${price / 100}`}</Price>
        <ExploreButton onClick={onSelect} lessMargin>
          Select this plan
        </ExploreButton>
        <LinkText onClick={onShowModal}>Learn More</LinkText>
      </ItemHolder>
      <ShowMoreInfoModal id={id} showModal={showModal} handleShowModal={onShowModal} />
    </div>
  );
};

export default PlanItem;
