import React, { useEffect } from 'react';
import { getCannyAppId } from '../../../utils/keys';

const appId = getCannyAppId()

const CannyIdentification = ({ user }) => {
  useEffect(() => {
    if (window.Canny) {
      window.Canny('identify', {
        appID: appId,
        user: {
          email: user.email,
          name: (user?.first_name && user?.last_name ) ? (user.first_name + ' ' + user.last_name) : user.email,
          id: user.id,
        },
      });
    }
  }, [user]);

  return null; 
};

export default CannyIdentification;
