import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Flex from '../../components/_styles/Flex';
import Text, { Small } from '../../components/_styles/Text';
import Heading from '../../components/_styles/Heading';
import Box from '../../components/_styles/Box';
import { ArrowDownward } from '../RadiologySearchPage/Icons';
import { Accordion, AccordionBody, AccordionHeader } from './Accordion';

const BulletPoint = styled.div`
  background: ${(props) => props.backgroundColor};
  width: 10px;
  height: 10px;
  border-radius: 50px;
  display: inline-block;
  margin-right: 5px;
`;

const HealthIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    display: block;
  }
`;

const DescriptionWrapper = styled(Flex)`
  @media (max-width: 991px) {
    display: block;
  }
`;

const HealthIndicatorDetailWrapper = styled.div`
  display: flex;

  @media (max-width: 991px) {
    display: block;
  }
`;

const StyledCol = styled(Flex.Col)`
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const StyledSmall = styled(Small)`
  @media (max-width: 991px) {
    margin-top: 20px;
    width: 100%;
  }
`;

const IconPlaceholder = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 12px;
  background: #00AEEF;
  opacity: 0.6;
`;

const RecommendationsAccordion = () => {
  const types = useSelector((state) => state.reports.reportPage.noteTypes);
  const risksMap = useSelector((state) =>
    state.reports.reportPage.risks.reduce((acc, r) => ({ ...acc, [r.recommendation_type]: r }), {})
  );
  const recommendationsMap = useSelector((state) =>
    state.reports.reportPage.recommendations.reduce((acc, r) => ({ ...acc, [r.recommendation_type]: r }), {})
  );

  return (
    <Box mb={30}>
      <Heading children='Recommendations for you' as='h6' />
      {types.map(({ id, name }) => (
        <Accordion key={id}>
          <AccordionHeader>
            <HealthIndicatorWrapper>
              <Flex.Col mr={10}>
                <IconPlaceholder />
              </Flex.Col>
              <StyledCol width={250}>
                <Text children={name} />
              </StyledCol>
              <Flex.Col flex='1' />
              <Flex.Col className='arrow-downward'>
                <ArrowDownward />
              </Flex.Col>
            </HealthIndicatorWrapper>
          </AccordionHeader>

          <AccordionBody>
            <HealthIndicatorWrapper>
              <Flex.Col p={20} flex='1'>
                <Text>
                  <BulletPoint backgroundColor='#f73b3b' />
                  Found Risks
                </Text>
                <DescriptionWrapper>
                  <Flex.Col flex='2'>
                    <StyledSmall children={risksMap[id]?.text ?? 'None'} pl={15} pr={30} />
                  </Flex.Col>
                </DescriptionWrapper>
              </Flex.Col>
              <Flex.Col p={20} borderLeft={1} borderLeftColor='#c2ccd9' borderLeftStyle='solid' flex='1'>
                <Text>
                  <BulletPoint backgroundColor='#FF9743' />
                  Recommendations
                </Text>
                <HealthIndicatorDetailWrapper>
                  <Flex.Col flex='2'>
                    <StyledSmall children={recommendationsMap[id]?.text ?? 'None'} pl={15} pr={30} />
                  </Flex.Col>
                </HealthIndicatorDetailWrapper>
              </Flex.Col>
            </HealthIndicatorWrapper>
          </AccordionBody>
        </Accordion>
      ))}
    </Box>
  );
};

export default RecommendationsAccordion;
