import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AccountForm from './CreateAccountForm';
import { AccountContentContainer, HeadlineCTA } from './common';

const CreateAccount = ({ onSubmit }) => {
  const history = useHistory();

  const role = useSelector((state) => state.auth.user.role);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      if (role === 'doctor') {
        history.push('/auth/appointments');
        return;
      }
      if (role === 'radiology_admin') {
        history.push('/auth/radAdmin/Dashboard');
        return;
      }

      history.push('/auth/dashboard');
    }
  }, []);

  return (
    <AccountContentContainer>
      <HeadlineCTA headlineText='Create New Account' subtext='' />
      <AccountForm onSubmit={onSubmit} />
    </AccountContentContainer>
  );
};

export default CreateAccount;
