import React, {
  useState,
  useRef,
  forwardRef,
} from "react";
import Select, { components } from "react-select";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Flex from "../../../components/_styles/Flex";
import filterOutline from "../../../assets/IO-icons/filter-outline.svg";
import moment from "moment";
import { isArray } from "lodash";
import DatePicker from "react-datepicker";
import { ReactComponent as RemoveRecoIcon } from "../../../assets/close-outline.svg";
import { ClickAwayListener, Tooltip, withStyles } from "@material-ui/core";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar-outline.svg";

const Card = styled(Flex)`
  width: 270px;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 22px 22px 19px;
`;

const FormContainer = styled.div`
  width: 230px;
`;

const SelectContainer = styled.div`
  margin-bottom: 20px;
`;

const FilterContainer = styled(Flex)`
  width: 120px;
  height: 38px;
  justify-content: space-around;
  align-items: center;
  margin: 0 10px;
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  border-radius: 8px;
  @media (max-width: 768px) {
    margin-top: 15px;
  }
`;

const SettingsContainer = styled.div`
  position: relative;
  z-index: 1;
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  margin: 0 0 20px -211px;
  border-radius: 8px;
  margin-right: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 75px;
  right: 50px;
  z-index: 2;
`;
const Image = styled.img`
  display: block;
  width: 20px;
`;

const FilterButton = styled(Flex)`
  width: 100%;
  justify-content: space-around;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #00aeef;
  height: 38px;
  cursor: pointer;
`;

const FilterMenuTitle = styled.div`
  margin-bottom: 14px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
`;

const SelectDropdownTitle = styled.div`
  margin-bottom: 4px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.6;
  text-transform: uppercase;
`;

const Button = styled.button`
  width: 100%;
  height: 50px;
  background-color: #00aeef;
  border-radius: 4px;
  border: 0;
  font: bold 16px "Open Sans", sans-serif;
  text-align: center;
  color: #ffffff;
  cursor: ${(props) => (props.disabled ? "" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
`;

const RadioBtn = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 50%;
  border-color: ${(props) => props.borderColor};
  background-color: ${(props) => props.bg};
  padding: 1% !important;
  background-clip: content-box;
  margin-right: 7px;
  cursor: pointer;
`;

const RadioLabel = styled.label`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 0px;
`;

const RadioContainer = styled.div`
  border: 1px solid #c3c3c3;
  width: 230px;
  display: flex;
  flex-direction: column;
  background: white;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 12px;
  border-radius: 4px;
`;

const InitialSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 43.5px;
  padding: 9.5px 12px;
  width: 230px;
  background: white;
  border: 1px solid #dfe0eb;
  cursor: pointer;
  border-radius: 5px;
`;

const DateContainer = styled(Flex)`
  margin-right: 15px;
  font-size: 12px;
`;


const DatePickerContainer = styled(Flex)`
  min-width: 230px;
  & > div > div > div {
    height: 43.5px;
    min-width: 230px;
  }
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 5px;
  font-size: 18px;
  width: max-content;
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
`;

const DateClearButton = styled.div`
  cursor: pointer;
  display: flex;
`;

const DateFieldLabel = styled.p`
  font-size: 9.2px;
  font-weight: 400;
  margin-bottom: 5px;
  backdrop-filter: blur(1px);
`;

const RecoOptions = [
  {
    value: "last7days",
    label: "Last 7 days",
    start: new Date(moment().subtract(7, "days")),
    end: new Date(moment()),
  },
  {
    value: "last30days",
    label: "Last 30 days",
    start: new Date(moment().subtract(30, "days")),
    end: new Date(moment()),
  },
  {
    value: "last3months",
    label: "Last 3 months",
    start: new Date(moment().subtract(3, "months")),
    end: new Date(moment()),
  },
  {
    value: "last6months",
    label: "Last 6 months",
    start: new Date(moment().subtract(6, "months")),
    end: new Date(moment()),
  },
  {
    value: "last12months",
    label: "Last 12 months",
    start: new Date(moment().subtract(12, "months")),
    end: new Date(moment()),
  },
  { value: "custom", label: "Custom date range", start: null, end: null },
];

export const DEFAULT_VALUES = {
  modality: "",
  anatomy: "",
  initialExamStartDate: "",
  initialExamEndDate: "",
  initialExamDatesFilter: "",
  date: "",
  selectedConfiguration: "",
  selectedOption: "",
  initialRangeStartDate: "",
  initialRangeEndDate: "",
  initialRangeDatesFilter: "",
  selectedSubOptionExamDate: "",
  selectedSubOptionReco: "",
  recoRangeDateView: true,
  examDateView: true,
};

export const MarketingAttributionFilter = (props) => {
  const { filterValuesForRequest, setFilterValuesForRequest } = props;

  const modalities = useSelector(
    (state) => state.radAdmin.followUpList.modalities.data
  );
  const anatomies = useSelector(
    (state) => state.radAdmin.followUpList.anatomies.data
  );

  const node = useRef();

  const [filterStep, setFilterStep] = useState(0);

  const [selectedValues, setSelectedValues] = useState(filterValuesForRequest);

  const modalityOptions = modalities?.length
    ? [{ value: "empty", label: "--Empty--", category: "modality" }].concat(
        modalities
          .map((item) => ({ value: item, label: item, category: "modality" }))
          .filter((item) => item.value)
      )
    : [];
  const anatomyOptions = anatomies?.length
    ? [{ value: "empty", label: "--Empty--", category: "anatomy" }].concat(
        anatomies
          .map((item) => ({ value: item, label: item, category: "anatomy" }))
          .filter((item) => item.value)
      )
    : [];

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const mergedFilters = {
      modality: selectedValues["modality"],
      anatomy: selectedValues["anatomy"],
      initialExamStartDate: selectedValues["initialExamStartDate"],
      initialExamEndDate: selectedValues["initialExamEndDate"],
      initialExamDatesFilter: selectedValues["initialExamDatesFilter"],
      date: selectedValues["date"],
      selectedConfiguration: selectedValues["selectedConfiguration"],
      selectedOption: selectedValues["selectedOption"],
      initialRangeStartDate: selectedValues["initialRangeStartDate"],
      initialRangeEndDate: selectedValues["initialRangeEndDate"],
      initialRangeDatesFilter: selectedValues["initialRangeDatesFilter"],
      selectedSubOptionExamDate: selectedValues["selectedSubOptionExamDate"],
      selectedSubOptionReco: selectedValues["selectedSubOptionReco"],
      recoRangeDateView: selectedValues["recoRangeDateView"],
      examDateView: selectedValues["examDateView"],
    };
    setFilterStep(0);
    setFilterValuesForRequest(mergedFilters);
  };

  const IndicatorsContainer = (props) => {
    return (
      <div style={{ backgroundColor: "#FFF" }}>
        <components.IndicatorsContainer {...props} />
      </div>
    );
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            style={{ margin: "0 5px", verticalAlign: "middle" }}
          />{" "}
          <label style={{ margin: "0" }}>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      cursor: "pointer",
    }),
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        fontSize: "14px",
        color: "black",
        cursor: isDisabled ? "not-allowed" : "pointer",
        backgroundColor: isSelected ? "#D9ECFF" : "white",
        display: "flex",
      };
    },
    multiValue: (styles, { data }) => ({
      ...styles,
      borderRadius: "25px",
      backgroundColor: "#EEEEEE",
      color: "#A1A1A1",
      padding: "4px 10px",
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#A1A1A1",
      fontSize: "10px",
    }),
  };

  const handleFilter = () => {
    setFilterStep(1);
    setSelectedValues(filterValuesForRequest);
  };

  const DatePickerInput = forwardRef(({ onClick, startDate, endDate }, ref) => {
    const formatDate = (date) => {
      return date ? moment(date).format("MMM, DD, YYYY") : "No Date";
    };

    return (
      <DatePickerButton ref={ref}>
        <DateContainer>
          {!startDate && !endDate
            ? "Select Exam Date Range"
            : `${formatDate(startDate)} - ${formatDate(endDate)}`}
        </DateContainer>
        {!selectedValues?.initialExamStartDate &&
          !selectedValues?.initialExamEndDate && <CalendarIcon />}
        {(selectedValues?.initialExamStartDate ||
          selectedValues?.initialExamEndDate) && (
          <DateClearButton
            onClick={() => {
              resetExamDateFilter();
              setSelectedValues((prev) => ({
                ...prev,
                selectedSubOptionExamDate: null,
                examDateView: true,
              }));
            }}
          >
            <RemoveRecoIcon />
          </DateClearButton>
        )}
      </DatePickerButton>
    );
  });

  const DatePickerInputField = React.forwardRef((props, ref) => {
    const { onClick, date } = props;
    const formatDate = (date, time) => {
      return `${moment(date).format("MMM, DD, YYYY")}`;
    };
    return (
      <DatePickerButton
        onClick={onClick}
        style={{ padding: "8px 24px", fontSize: "12px", width: "230px" }}
      >
        <DateContainer style={{ fontSize: 12 }}>
          {selectedValues?.date ? `${formatDate(date)}` : "Select Reco Date"}
        </DateContainer>
        {!selectedValues?.date && <CalendarIcon />}
        {selectedValues?.date && (
          <DateClearButton
            onClick={() => {
              resetRangeDateFilter();
              setSelectedValues((prev) => ({
                ...prev,
                selectedOption: null,
                selectedConfiguration: null,
                selectedSubOptionReco: null,
                recoRangeDateView: true,
              }));
            }}
          >
            <RemoveRecoIcon />
          </DateClearButton>
        )}
      </DatePickerButton>
    );
  });

  const DatePickerInputRange = forwardRef(
    ({ onClick, startDate, endDate }, ref) => {
      const formatDate = (date) => {
        return date ? moment(date).format("MMM, DD, YYYY") : "No Date";
      };

      return (
        <DatePickerButton ref={ref}>
          <DateContainer>
            {!startDate && !endDate
              ? "Select Reco Date Range"
              : `${formatDate(startDate)} - ${formatDate(endDate)}`}
          </DateContainer>

          {!selectedValues?.initialRangeStartDate &&
            !selectedValues?.initialRangeEndDate && <CalendarIcon />}
          {(selectedValues?.initialRangeStartDate ||
            selectedValues?.initialRangeEndDate) && (
            <DateClearButton
              onClick={() => {
                resetRangeDateFilter();
                setSelectedValues((prev) => ({
                  ...prev,
                  selectedOption: null,
                  selectedConfiguration: null,
                  selectedSubOptionReco: null,
                  recoRangeDateView: true,
                }));
              }}
            >
              <RemoveRecoIcon />
            </DateClearButton>
          )}
        </DatePickerButton>
      );
    }
  );

  const resetRangeDateFilter = () => {
    setSelectedValues((prev) => ({
      ...prev,
      date: "",
      initialRangeStartDate: "",
      initialRangeEndDate: "",
      initialRangeDatesFilter: {
        startDate: "",
        endDate: "",
      },
    }));
  };


  const resetExamDateFilter = () => {
    setSelectedValues((prev) => ({
      ...prev,
      initialExamStartDate: "",
      initialExamEndDate: "",
      initialExamDatesFilter: {
        startDate: "",
        endDate: "",
      },
    }));
  };

  const onChange = (dates) => {
    setSelectedValues((prev) => ({ ...prev, date: dates }));
  };

  const onInitialExamDatesChange = (dates) => {
    if (isArray(dates)) {
      const [start, end] = dates;
      setSelectedValues((prev) => ({
        ...prev,
        initialExamStartDate: start,
        initialExamEndDate: end,
        initialExamDatesFilter: {
          startDate: start ? moment(start).format("YYYY-MM-DD") : "",
          endDate: end ? moment(end).format("YYYY-MM-DD") : "",
        },
      }));
    }
  };

  const onInitialRangeDatesChange = (dates) => {
    if (isArray(dates)) {
      const [start, end] = dates;
      setSelectedValues((prev) => ({
        ...prev,
        initialRangeStartDate: start,
        initialRangeEndDate: end,
        initialRangeDatesFilter: {
          startDate: start ? moment(start).format("YYYY-MM-DD") : "",
          endDate: end ? moment(end).format("YYYY-MM-DD") : "",
        },
      }));
    }
  };
  return (
    <>
      <FilterContainer showMenu={filterStep}>
        <FilterButton onClick={() => handleFilter()}>
          Filter
          <Image src={filterOutline} alt={"settings icon"} />
        </FilterButton>

        <SettingsContainer>
          {filterStep === 1 && (
            <ClickAwayListener
              onClickAway={() => {
                setFilterStep(0);
                setSelectedValues(filterValuesForRequest);
              }}
            >
              <Card>
                <FilterMenuTitle> Add filters </FilterMenuTitle>
                <FormContainer>
                  <form onSubmit={handleFormSubmit}>
                    <SelectContainer style={{}}>
                      <SelectDropdownTitle>EXAM DATE RANGE</SelectDropdownTitle>

                      {selectedValues?.examDateView &&
                      !selectedValues?.initialExamStartDate &&
                      !selectedValues?.initialExamEndDate ? (
                        <InitialSelect
                          onClick={() =>
                            setSelectedValues((prev) => ({
                              ...prev,
                              examDateView: false,
                            }))
                          }
                        >
                          <span>Select...</span>
                          <CalendarIcon />
                        </InitialSelect>
                      ) : selectedValues?.initialExamStartDate &&
                        selectedValues?.initialExamEndDate ? (
                        <DatePickerContainer>
                          <DatePicker
                            popperPlacement={"auto"}
                            selected={selectedValues?.initialExamStartDate}
                            startDate={selectedValues?.initialExamStartDate}
                            endDate={selectedValues?.initialExamEndDate}
                            customInput={
                              <DatePickerInput
                                startDate={selectedValues?.initialExamStartDate}
                                endDate={selectedValues?.initialExamEndDate}
                              />
                            }
                            shouldCloseOnSelect={false}
                            selectsRange
                            dateFormat={"dd/MM/yyyy"}
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: "5px, 10px",
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: "viewport",
                              },
                            }}
                          />
                        </DatePickerContainer>
                      ) : (
                        <ClickAwayListener
                          onClickAway={() => {
                            setSelectedValues((prev) => ({
                              ...prev,
                              selectedSubOptionExamDate: null,
                              examDateView: true,
                            }));
                          }}
                        >
                          <div>
                            <RadioContainer>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "6px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "6px",
                                  }}
                                >
                                  <RadioLabel>
                                    <RadioBtn
                                      type="radio"
                                      value="custom"
                                      checked={
                                        selectedValues?.selectedSubOptionExamDate ===
                                        "custom"
                                      }
                                      onChange={() => {
                                        setSelectedValues((prev) => ({
                                          ...prev,
                                          selectedSubOptionExamDate: "custom",
                                        }));
                                      }}
                                      borderColor={
                                        selectedValues?.selectedSubOptionExamDate ===
                                        "custom"
                                          ? "#00AEEF"
                                          : "#D4D4D4"
                                      }
                                      bg={
                                        selectedValues?.selectedSubOptionExamDate ===
                                        "custom"
                                          ? "#00AEEF"
                                          : "#FFF"
                                      }
                                    />
                                    Custom Range
                                  </RadioLabel>
                                  <RadioLabel>
                                    <RadioBtn
                                      type="radio"
                                      value="last7days"
                                      checked={
                                        selectedValues?.selectedSubOptionExamDate ===
                                        "last7days"
                                      }
                                      onChange={() => {
                                        let obj = RecoOptions.find(
                                          (itm) => itm.value === "last7days"
                                        );
                                        setSelectedValues((prev) => ({
                                          ...prev,
                                          selectedSubOptionExamDate:
                                            "last7days",
                                          initialExamStartDate: obj.start,
                                          initialExamEndDate: obj.end,
                                          initialExamDatesFilter: {
                                            startDate: obj.start
                                              ? moment(obj.start).format(
                                                  "YYYY-MM-DD"
                                                )
                                              : "",
                                            endDate: obj.end
                                              ? moment(obj.end).format(
                                                  "YYYY-MM-DD"
                                                )
                                              : "",
                                          },
                                        }));
                                      }}
                                      borderColor={
                                        selectedValues?.selectedSubOptionExamDate ===
                                        "last7days"
                                          ? "#00AEEF"
                                          : "#D4D4D4"
                                      }
                                      bg={
                                        selectedValues?.selectedSubOptionExamDate ===
                                        "last7days"
                                          ? "#00AEEF"
                                          : "#FFF"
                                      }
                                    />
                                    Last 7 Days
                                  </RadioLabel>
                                  <RadioLabel>
                                    <RadioBtn
                                      type="radio"
                                      value="last30days"
                                      checked={
                                        selectedValues?.selectedSubOptionExamDate ===
                                        "last30days"
                                      }
                                      onChange={() => {
                                        let obj = RecoOptions.find(
                                          (itm) => itm.value === "last30days"
                                        );
                                        setSelectedValues((prev) => ({
                                          ...prev,
                                          selectedSubOptionExamDate:
                                            "last30days",
                                          initialExamStartDate: obj.start,
                                          initialExamEndDate: obj.end,
                                          initialExamDatesFilter: {
                                            startDate: obj.start
                                              ? moment(obj.start).format(
                                                  "YYYY-MM-DD"
                                                )
                                              : "",
                                            endDate: obj.end
                                              ? moment(obj.end).format(
                                                  "YYYY-MM-DD"
                                                )
                                              : "",
                                          },
                                        }));
                                      }}
                                      borderColor={
                                        selectedValues?.selectedSubOptionExamDate ===
                                        "last30days"
                                          ? "#00AEEF"
                                          : "#D4D4D4"
                                      }
                                      bg={
                                        selectedValues?.selectedSubOptionExamDate ===
                                        "last30days"
                                          ? "#00AEEF"
                                          : "#FFF"
                                      }
                                    />
                                    Last 30 Days
                                  </RadioLabel>
                                  <RadioLabel>
                                    <RadioBtn
                                      type="radio"
                                      value="last6months"
                                      checked={
                                        selectedValues?.selectedSubOptionExamDate ===
                                        "last6months"
                                      }
                                      onChange={() => {
                                        let obj = RecoOptions.find(
                                          (itm) => itm.value === "last6months"
                                        );
                                        setSelectedValues((prev) => ({
                                          ...prev,
                                          selectedSubOptionExamDate:
                                            "last6months",
                                          initialExamStartDate: obj.start,
                                          initialExamEndDate: obj.end,
                                          initialExamDatesFilter: {
                                            startDate: obj.start
                                              ? moment(obj.start).format(
                                                  "YYYY-MM-DD"
                                                )
                                              : "",
                                            endDate: obj.end
                                              ? moment(obj.end).format(
                                                  "YYYY-MM-DD"
                                                )
                                              : "",
                                          },
                                        }));
                                      }}
                                      borderColor={
                                        selectedValues?.selectedSubOptionExamDate ===
                                        "last6months"
                                          ? "#00AEEF"
                                          : "#D4D4D4"
                                      }
                                      bg={
                                        selectedValues?.selectedSubOptionExamDate ===
                                        "last6months"
                                          ? "#00AEEF"
                                          : "#FFF"
                                      }
                                    />
                                    Last 6 Months
                                  </RadioLabel>
                                  <RadioLabel>
                                    <RadioBtn
                                      type="radio"
                                      value="last12months"
                                      checked={
                                        selectedValues?.selectedSubOptionExamDate ===
                                        "last12months"
                                      }
                                      onChange={() => {
                                        let obj = RecoOptions.find(
                                          (itm) => itm.value === "last12months"
                                        );
                                        setSelectedValues((prev) => ({
                                          ...prev,
                                          selectedSubOptionExamDate:
                                            "last12months",
                                          initialExamStartDate: obj.start,
                                          initialExamEndDate: obj.end,
                                          initialExamDatesFilter: {
                                            startDate: obj.start
                                              ? moment(obj.start).format(
                                                  "YYYY-MM-DD"
                                                )
                                              : "",
                                            endDate: obj.end
                                              ? moment(obj.end).format(
                                                  "YYYY-MM-DD"
                                                )
                                              : "",
                                          },
                                        }));
                                      }}
                                      borderColor={
                                        selectedValues?.selectedSubOptionExamDate ===
                                        "last12months"
                                          ? "#00AEEF"
                                          : "#D4D4D4"
                                      }
                                      bg={
                                        selectedValues?.selectedSubOptionExamDate ===
                                        "last12months"
                                          ? "#00AEEF"
                                          : "#FFF"
                                      }
                                    />
                                    Last 12 Months
                                  </RadioLabel>
                                </div>
                              </div>
                            </RadioContainer>

                            {selectedValues?.selectedSubOptionExamDate ===
                              "custom" && (
                              <DatePicker
                                selected={selectedValues?.initialExamStartDate}
                                onChange={onInitialExamDatesChange}
                                startDate={selectedValues?.initialExamStartDate}
                                endDate={selectedValues?.initialExamEndDate}
                                selectsRange
                                inline
                                shouldCloseOnSelect
                              />
                            )}
                          </div>
                        </ClickAwayListener>
                      )}
                    </SelectContainer>
                    <SelectContainer style={{}}>
                      <SelectDropdownTitle>
                        RECO DUE DATE RANGE
                      </SelectDropdownTitle>

                      {selectedValues?.recoRangeDateView &&
                      !selectedValues?.date &&
                      !selectedValues?.initialRangeStartDate &&
                      !selectedValues?.initialRangeEndDate ? (
                        <InitialSelect
                          onClick={() =>
                            setSelectedValues((prev) => ({
                              ...prev,
                              recoRangeDateView: false,
                            }))
                          }
                        >
                          <span>Select...</span>
                          <CalendarIcon />
                        </InitialSelect>
                      ) : selectedValues?.date ? (
                        <DatePickerContainer>
                          <DatePicker
                            popperPlacement={"auto"}
                            onChange={onChange}
                            dateFormat="MM/dd/yyyy"
                            selected={selectedValues?.date}
                            customInput={
                              <DatePickerInputField
                                date={selectedValues?.date}
                              />
                            }
                          />
                        </DatePickerContainer>
                      ) : selectedValues?.initialRangeStartDate &&
                        selectedValues?.initialRangeEndDate ? (
                        <DatePickerContainer>
                          <DatePicker
                            popperPlacement={"auto"}
                            selected={selectedValues?.initialRangeStartDate}
                            startDate={selectedValues?.initialRangeStartDate}
                            endDate={selectedValues?.initialRangeEndDate}
                            customInput={
                              <DatePickerInputRange
                                startDate={
                                  selectedValues?.initialRangeStartDate
                                }
                                endDate={selectedValues?.initialRangeEndDate}
                              />
                            }
                            shouldCloseOnSelect={false}
                            selectsRange
                            dateFormat={"dd/MM/yyyy"}
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: "5px, 10px",
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: "viewport",
                              },
                            }}
                          />
                        </DatePickerContainer>
                      ) : (
                        <ClickAwayListener
                          onClickAway={() => {
                            setSelectedValues((prev) => ({
                              ...prev,
                              selectedConfiguration: null,
                              selectedOption: null,
                              recoRangeDateView: true,
                              selectedSubOptionReco: null,
                            }));
                          }}
                        >
                          <div>
                            <RadioContainer>
                              <RadioLabel>
                                <RadioBtn
                                  type="radio"
                                  value="range start"
                                  borderColor={
                                    selectedValues?.selectedOption ===
                                    "range start"
                                      ? "#00AEEF"
                                      : "#D4D4D4"
                                  }
                                  bg={
                                    selectedValues?.selectedOption ===
                                    "range start"
                                      ? "#00AEEF"
                                      : "#FFF"
                                  }
                                  checked={
                                    selectedValues?.selectedOption ===
                                    "range start"
                                  }
                                  onChange={(e) => {
                                    console.log("aaaaa", e);
                                    resetRangeDateFilter();
                                    setSelectedValues((prev) => ({
                                      ...prev,
                                      selectedOption: "range start",
                                      selectedConfiguration: null,
                                      selectedSubOptionReco: null,
                                    }));
                                  }}
                                />
                                Min Due Date
                              </RadioLabel>
                              {selectedValues?.selectedOption ===
                                "range start" && (
                                <div
                                  style={{
                                    marginLeft: "34px",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "6px",
                                  }}
                                >
                                  <RadioLabel>
                                    <RadioBtn
                                      type="radio"
                                      value="lte"
                                      checked={
                                        selectedValues?.selectedConfiguration ===
                                        "lte"
                                      }
                                      onChange={() => {
                                        resetRangeDateFilter();
                                        setSelectedValues((prev) => ({
                                          ...prev,
                                          selectedConfiguration: "lte",
                                        }));
                                      }}
                                      borderColor={
                                        selectedValues?.selectedConfiguration ===
                                        "lte"
                                          ? "#00AEEF"
                                          : "#D4D4D4"
                                      }
                                      bg={
                                        selectedValues?.selectedConfiguration ===
                                        "lte"
                                          ? "#00AEEF"
                                          : "#FFF"
                                      }
                                    />
                                    Before
                                  </RadioLabel>
                                  <RadioLabel>
                                    <RadioBtn
                                      type="radio"
                                      value="gte"
                                      checked={
                                        selectedValues?.selectedConfiguration ===
                                        "gte"
                                      }
                                      onChange={() => {
                                        resetRangeDateFilter();
                                        setSelectedValues((prev) => ({
                                          ...prev,
                                          selectedConfiguration: "gte",
                                        }));
                                      }}
                                      borderColor={
                                        selectedValues?.selectedConfiguration ===
                                        "gte"
                                          ? "#00AEEF"
                                          : "#D4D4D4"
                                      }
                                      bg={
                                        selectedValues?.selectedConfiguration ===
                                        "gte"
                                          ? "#00AEEF"
                                          : "#FFF"
                                      }
                                    />
                                    After
                                  </RadioLabel>
                                  <RadioLabel>
                                    <RadioBtn
                                      type="radio"
                                      value="gte and lte"
                                      checked={
                                        selectedValues?.selectedConfiguration ===
                                        "gte and lte"
                                      }
                                      onChange={() => {
                                        resetRangeDateFilter();
                                        setSelectedValues((prev) => ({
                                          ...prev,
                                          selectedConfiguration: "gte and lte",
                                        }));
                                      }}
                                      borderColor={
                                        selectedValues?.selectedConfiguration ===
                                        "gte and lte"
                                          ? "#00AEEF"
                                          : "#D4D4D4"
                                      }
                                      bg={
                                        selectedValues?.selectedConfiguration ===
                                        "gte and lte"
                                          ? "#00AEEF"
                                          : "#FFF"
                                      }
                                    />
                                    Between
                                  </RadioLabel>
                                  {selectedValues?.selectedConfiguration ===
                                    "gte and lte" && (
                                    <div
                                      style={{
                                        marginLeft: "23px",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "6px",
                                      }}
                                    >
                                      <RadioLabel>
                                        <RadioBtn
                                          type="radio"
                                          value="custom"
                                          checked={
                                            selectedValues?.selectedSubOptionReco ===
                                            "custom"
                                          }
                                          onChange={() =>
                                            setSelectedValues((prev) => ({
                                              ...prev,
                                              selectedSubOptionReco: "custom",
                                            }))
                                          }
                                          borderColor={
                                            selectedValues?.selectedSubOptionReco ===
                                            "custom"
                                              ? "#00AEEF"
                                              : "#D4D4D4"
                                          }
                                          bg={
                                            selectedValues?.selectedSubOptionReco ===
                                            "custom"
                                              ? "#00AEEF"
                                              : "#FFF"
                                          }
                                        />
                                        Custom Range
                                      </RadioLabel>
                                      <RadioLabel>
                                        <RadioBtn
                                          type="radio"
                                          value="last7days"
                                          checked={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last7days"
                                          }
                                          onChange={() => {
                                            let obj = RecoOptions.find(
                                              (itm) => itm.value === "last7days"
                                            );
                                            setSelectedValues((prev) => ({
                                              ...prev,
                                              selectedSubOptionReco:
                                                "last7days",
                                              initialRangeStartDate: obj.start,
                                              initialRangeEndDate: obj.end,
                                              initialRangeDatesFilter: {
                                                startDate: obj.start
                                                  ? moment(obj.start).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : "",
                                                endDate: obj.end
                                                  ? moment(obj.end).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : "",
                                              },
                                            }));
                                          }}
                                          borderColor={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last7days"
                                              ? "#00AEEF"
                                              : "#D4D4D4"
                                          }
                                          bg={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last7days"
                                              ? "#00AEEF"
                                              : "#FFF"
                                          }
                                        />
                                        Last 7 Days
                                      </RadioLabel>
                                      <RadioLabel>
                                        <RadioBtn
                                          type="radio"
                                          value="last30days"
                                          checked={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last30days"
                                          }
                                          onChange={() => {
                                            let obj = RecoOptions.find(
                                              (itm) =>
                                                itm.value === "last30days"
                                            );
                                            setSelectedValues((prev) => ({
                                              ...prev,
                                              selectedSubOptionReco:
                                                "last30days",
                                              initialRangeStartDate: obj.start,
                                              initialRangeEndDate: obj.end,
                                              initialRangeDatesFilter: {
                                                startDate: obj.start
                                                  ? moment(obj.start).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : "",
                                                endDate: obj.end
                                                  ? moment(obj.end).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : "",
                                              },
                                            }));
                                          }}
                                          borderColor={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last30days"
                                              ? "#00AEEF"
                                              : "#D4D4D4"
                                          }
                                          bg={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last30days"
                                              ? "#00AEEF"
                                              : "#FFF"
                                          }
                                        />
                                        Last 30 Days
                                      </RadioLabel>
                                      <RadioLabel>
                                        <RadioBtn
                                          type="radio"
                                          value="last6months"
                                          checked={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last6months"
                                          }
                                          onChange={() => {
                                            let obj = RecoOptions.find(
                                              (itm) =>
                                                itm.value === "last6months"
                                            );
                                            setSelectedValues((prev) => ({
                                              ...prev,
                                              selectedSubOptionReco:
                                                "last6months",
                                              initialRangeStartDate: obj.start,
                                              initialRangeEndDate: obj.end,
                                              initialRangeDatesFilter: {
                                                startDate: obj.start
                                                  ? moment(obj.start).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : "",
                                                endDate: obj.end
                                                  ? moment(obj.end).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : "",
                                              },
                                            }));
                                          }}
                                          borderColor={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last6months"
                                              ? "#00AEEF"
                                              : "#D4D4D4"
                                          }
                                          bg={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last6months"
                                              ? "#00AEEF"
                                              : "#FFF"
                                          }
                                        />
                                        Last 6 Months
                                      </RadioLabel>
                                      <RadioLabel>
                                        <RadioBtn
                                          type="radio"
                                          value="last12months"
                                          checked={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last12months"
                                          }
                                          onChange={() => {
                                            let obj = RecoOptions.find(
                                              (itm) =>
                                                itm.value === "last12months"
                                            );
                                            setSelectedValues((prev) => ({
                                              ...prev,
                                              selectedSubOptionReco:
                                                "last12months",
                                              initialRangeStartDate: obj.start,
                                              initialRangeEndDate: obj.end,
                                              initialRangeDatesFilter: {
                                                startDate: obj.start
                                                  ? moment(obj.start).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : "",
                                                endDate: obj.end
                                                  ? moment(obj.end).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : "",
                                              },
                                            }));
                                          }}
                                          borderColor={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last12months"
                                              ? "#00AEEF"
                                              : "#D4D4D4"
                                          }
                                          bg={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last12months"
                                              ? "#00AEEF"
                                              : "#FFF"
                                          }
                                        />
                                        Last 12 Months
                                      </RadioLabel>
                                    </div>
                                  )}
                                </div>
                              )}
                              <RadioLabel>
                                <RadioBtn
                                  type="radio"
                                  value="range end"
                                  borderColor={
                                    selectedValues?.selectedOption ===
                                    "range end"
                                      ? "#00AEEF"
                                      : "#D4D4D4"
                                  }
                                  bg={
                                    selectedValues?.selectedOption ===
                                    "range end"
                                      ? "#00AEEF"
                                      : "#FFF"
                                  }
                                  checked={
                                    selectedValues?.selectedOption ===
                                    "range end"
                                  }
                                  onChange={(e) => {
                                    resetRangeDateFilter();
                                    setSelectedValues((prev) => ({
                                      ...prev,
                                      selectedOption: "range end",
                                      selectedConfiguration: null,
                                      selectedSubOptionReco: null,
                                    }));
                                  }}
                                />
                                Max Due Date
                              </RadioLabel>
                              {selectedValues?.selectedOption ===
                                "range end" && (
                                <div
                                  style={{
                                    marginLeft: "34px",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "6px",
                                  }}
                                >
                                  <RadioLabel>
                                    <RadioBtn
                                      type="radio"
                                      value="lte"
                                      checked={
                                        selectedValues?.selectedConfiguration ===
                                        "lte"
                                      }
                                      onChange={() => {
                                        resetRangeDateFilter();
                                        setSelectedValues((prev) => ({
                                          ...prev,
                                          selectedConfiguration: "lte",
                                        }));
                                      }}
                                      borderColor={
                                        selectedValues?.selectedConfiguration ===
                                        "lte"
                                          ? "#00AEEF"
                                          : "#D4D4D4"
                                      }
                                      bg={
                                        selectedValues?.selectedConfiguration ===
                                        "lte"
                                          ? "#00AEEF"
                                          : "#FFF"
                                      }
                                    />
                                    Before
                                  </RadioLabel>
                                  <RadioLabel>
                                    <RadioBtn
                                      type="radio"
                                      value="gte"
                                      checked={
                                        selectedValues?.selectedConfiguration ===
                                        "gte"
                                      }
                                      onChange={() => {
                                        resetRangeDateFilter();
                                        setSelectedValues((prev) => ({
                                          ...prev,
                                          selectedConfiguration: "gte",
                                        }));
                                      }}
                                      borderColor={
                                        selectedValues?.selectedConfiguration ===
                                        "gte"
                                          ? "#00AEEF"
                                          : "#D4D4D4"
                                      }
                                      bg={
                                        selectedValues?.selectedConfiguration ===
                                        "gte"
                                          ? "#00AEEF"
                                          : "#FFF"
                                      }
                                    />
                                    After
                                  </RadioLabel>
                                  <RadioLabel>
                                    <RadioBtn
                                      type="radio"
                                      value="gte and lte"
                                      checked={
                                        selectedValues?.selectedConfiguration ===
                                        "gte and lte"
                                      }
                                      onChange={() => {
                                        resetRangeDateFilter();
                                        setSelectedValues((prev) => ({
                                          ...prev,
                                          selectedConfiguration: "gte and lte",
                                        }));
                                      }}
                                      borderColor={
                                        selectedValues?.selectedConfiguration ===
                                        "gte and lte"
                                          ? "#00AEEF"
                                          : "#D4D4D4"
                                      }
                                      bg={
                                        selectedValues?.selectedConfiguration ===
                                        "gte and lte"
                                          ? "#00AEEF"
                                          : "#FFF"
                                      }
                                    />
                                    Between
                                  </RadioLabel>

                                  {selectedValues?.selectedConfiguration ===
                                    "gte and lte" && (
                                    <div
                                      style={{
                                        marginLeft: "23px",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "6px",
                                      }}
                                    >
                                      <RadioLabel>
                                        <RadioBtn
                                          type="radio"
                                          value="custom"
                                          checked={
                                            selectedValues?.selectedSubOptionReco ===
                                            "custom"
                                          }
                                          onChange={() =>
                                            setSelectedValues((prev) => ({
                                              ...prev,
                                              selectedSubOptionReco: "custom",
                                            }))
                                          }
                                          borderColor={
                                            selectedValues?.selectedSubOptionReco ===
                                            "custom"
                                              ? "#00AEEF"
                                              : "#D4D4D4"
                                          }
                                          bg={
                                            selectedValues?.selectedSubOptionReco ===
                                            "custom"
                                              ? "#00AEEF"
                                              : "#FFF"
                                          }
                                        />
                                        Custom Range
                                      </RadioLabel>
                                      <RadioLabel>
                                        <RadioBtn
                                          type="radio"
                                          value="last7days"
                                          checked={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last7days"
                                          }
                                          onChange={() => {
                                            let obj = RecoOptions.find(
                                              (itm) => itm.value === "last7days"
                                            );
                                            setSelectedValues((prev) => ({
                                              ...prev,
                                              selectedSubOptionReco:
                                                "last7days",
                                              initialRangeStartDate: obj.start,
                                              initialRangeEndDate: obj.end,
                                              initialRangeDatesFilter: {
                                                startDate: obj.start
                                                  ? moment(obj.start).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : "",
                                                endDate: obj.end
                                                  ? moment(obj.end).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : "",
                                              },
                                            }));
                                          }}
                                          borderColor={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last7days"
                                              ? "#00AEEF"
                                              : "#D4D4D4"
                                          }
                                          bg={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last7days"
                                              ? "#00AEEF"
                                              : "#FFF"
                                          }
                                        />
                                        Last 7 Days
                                      </RadioLabel>
                                      <RadioLabel>
                                        <RadioBtn
                                          type="radio"
                                          value="last30days"
                                          checked={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last30days"
                                          }
                                          onChange={() => {
                                            let obj = RecoOptions.find(
                                              (itm) =>
                                                itm.value === "last30days"
                                            );
                                            setSelectedValues((prev) => ({
                                              ...prev,
                                              selectedSubOptionReco:
                                                "last30days",
                                              initialRangeStartDate: obj.start,
                                              initialRangeEndDate: obj.end,
                                              initialRangeDatesFilter: {
                                                startDate: obj.start
                                                  ? moment(obj.start).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : "",
                                                endDate: obj.end
                                                  ? moment(obj.end).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : "",
                                              },
                                            }));
                                          }}
                                          borderColor={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last30days"
                                              ? "#00AEEF"
                                              : "#D4D4D4"
                                          }
                                          bg={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last30days"
                                              ? "#00AEEF"
                                              : "#FFF"
                                          }
                                        />
                                        Last 30 Days
                                      </RadioLabel>
                                      <RadioLabel>
                                        <RadioBtn
                                          type="radio"
                                          value="last6months"
                                          checked={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last6months"
                                          }
                                          onChange={() => {
                                            let obj = RecoOptions.find(
                                              (itm) =>
                                                itm.value === "last6months"
                                            );
                                            setSelectedValues((prev) => ({
                                              ...prev,
                                              selectedSubOptionReco:
                                                "last6months",
                                              initialRangeStartDate: obj.start,
                                              initialRangeEndDate: obj.end,
                                              initialRangeDatesFilter: {
                                                startDate: obj.start
                                                  ? moment(obj.start).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : "",
                                                endDate: obj.end
                                                  ? moment(obj.end).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : "",
                                              },
                                            }));
                                          }}
                                          borderColor={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last6months"
                                              ? "#00AEEF"
                                              : "#D4D4D4"
                                          }
                                          bg={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last6months"
                                              ? "#00AEEF"
                                              : "#FFF"
                                          }
                                        />
                                        Last 6 Months
                                      </RadioLabel>
                                      <RadioLabel>
                                        <RadioBtn
                                          type="radio"
                                          value="last12months"
                                          checked={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last12months"
                                          }
                                          onChange={() => {
                                            let obj = RecoOptions.find(
                                              (itm) =>
                                                itm.value === "last12months"
                                            );
                                            setSelectedValues((prev) => ({
                                              ...prev,
                                              selectedSubOptionReco:
                                                "last12months",
                                              initialRangeStartDate: obj.start,
                                              initialRangeEndDate: obj.end,
                                              initialRangeDatesFilter: {
                                                startDate: obj.start
                                                  ? moment(obj.start).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : "",
                                                endDate: obj.end
                                                  ? moment(obj.end).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : "",
                                              },
                                            }));
                                          }}
                                          borderColor={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last12months"
                                              ? "#00AEEF"
                                              : "#D4D4D4"
                                          }
                                          bg={
                                            selectedValues?.selectedSubOptionReco ===
                                            "last12months"
                                              ? "#00AEEF"
                                              : "#FFF"
                                          }
                                        />
                                        Last 12 Months
                                      </RadioLabel>
                                    </div>
                                  )}
                                </div>
                              )}
                            </RadioContainer>
                            {!selectedValues?.initialRangeStartDate &&
                            (selectedValues?.selectedConfiguration === "gte" ||
                              selectedValues?.selectedConfiguration ===
                                "lte") ? (
                              <DatePicker
                                selected={selectedValues?.initialRangeStartDate}
                                onChange={onChange}
                                inline
                              />
                            ) : null}
                            {selectedValues?.selectedConfiguration ===
                              "gte and lte" &&
                              selectedValues?.selectedSubOptionReco ===
                                "custom" && (
                                <DatePicker
                                  selected={
                                    selectedValues?.initialRangeStartDate
                                  }
                                  onChange={onInitialRangeDatesChange}
                                  startDate={
                                    selectedValues?.initialRangeStartDate
                                  }
                                  endDate={selectedValues?.initialRangeEndDate}
                                  selectsRange
                                  inline
                                  shouldCloseOnSelect
                                />
                              )}
                          </div>
                        </ClickAwayListener>
                      )}
                    </SelectContainer>
                    <SelectContainer>
                      <SelectDropdownTitle>Modality</SelectDropdownTitle>
                      <Select
                        styles={colourStyles}
                        options={modalityOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                          IndicatorsContainer,
                        }}
                        onChange={(value) =>
                          setSelectedValues({
                            ...selectedValues,
                            modality: value,
                          })
                        }
                        allowSelectAll={true}
                        value={selectedValues["modality"]}
                        isClearable={true}
                      />
                    </SelectContainer>
                    <SelectContainer>
                      <SelectDropdownTitle>Anatomy</SelectDropdownTitle>
                      <Select
                        styles={colourStyles}
                        options={anatomyOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                          IndicatorsContainer,
                        }}
                        onChange={(value) =>
                          setSelectedValues({
                            ...selectedValues,
                            anatomy: value,
                          })
                        }
                        allowSelectAll={true}
                        value={selectedValues["anatomy"]}
                        isClearable={true}
                      />
                    </SelectContainer>

                    <Button>Apply Filters</Button>
                  </form>
                </FormContainer>
              </Card>
            </ClickAwayListener>
          )}
        </SettingsContainer>
      </FilterContainer>
    </>
  );
};
