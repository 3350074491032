import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import ViewBasicInfo from '../../components/Account/CreateAccount';
import ViewPracticeInfo from './components/ViewPracticeInformation';
import ViewConfirm from './components/ViewConfirm';
import { processStep } from '../../actions/registerDoctor';

const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const view = useSelector((state) => state.registerDoctor.view);

  const onSubmit = useCallback(() => {
    dispatch(processStep(history));
  }, [dispatch, history]);

  if (view === 'BASIC_INFO') {
    return <ViewBasicInfo onSubmit={onSubmit} />;
  }

  if (view === 'PRACTICE_INFO') {
    return <ViewPracticeInfo onSubmit={onSubmit} />;
  }

  if (view === 'CONFIRM') {
    return <ViewConfirm />;
  }

  return null;
};

export default Index;
