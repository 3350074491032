import styled from 'styled-components';

export const HeaderContainer = styled.header`
  border-bottom: 2px solid #e6e9f1;
`;

export const HeaderHolder = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 10px 0px;
`;

export const HeaderWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
`;

export const StepsHolder = styled.div`
  display: flex;
  padding: 0px 15%;
  @media(max-width: 991px){
    display: none;
  }
`;

export const StepHolder = styled.div`
  display: flex;
  margin: auto 30px;
`;

export const SvgHolder = styled.div`
  height: 22px;
`;

export const StepText = styled.div`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${(props) => (props.activeStep ? '#00AEEF' : '#77797b')};
  font-weight: ${(props) => (props.activeStep ? 'bold' : 'normal')};
  margin-left: 6px;
`;
