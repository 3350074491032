import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import Calendar from '../../../components/Scheduling/SelectDateTime/Calendar';
import { updateAppointment } from '../../../actions/patientAppointments';
import { Button } from './Common';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const EditAppointment = ({ appointment, onSubmit }) => {
  const dispatch = useDispatch();

  const [date, setDate] = useState(new Date(appointment.date_time));
  const [time, setTime] = useState(moment(appointment.date_time).format('HH:mm'));

  const onChangeCalendar = useCallback((date, time) => {
    setDate(date);
    setTime(time);
  }, []);

  const onClickSave = useCallback(async () => {
    const [hh, mm] = time.split(':');
    const dateTime = moment(date).hour(hh).minute(mm).toISOString();

    await dispatch(
      updateAppointment(appointment.id, {
        doctor: appointment.doctor?.id,
        symptoms: appointment.symptoms,
        date_time: dateTime
      })
    );

    onSubmit();
  }, [onSubmit, dispatch, appointment, date, time]);

  return (
    <Container>
      <Calendar onChange={onChangeCalendar} date={date} time={time} />
      <Button onClick={onClickSave} style={{ width: '180px' }} children='Save' mode='primary' />
    </Container>
  );
};

export default EditAppointment;
