import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 125px);
  display: flex;
  flex-direction: row;
  margin: 30px;
  padding: 20px 20px;
  border-radius: 8px;
  border: 1px solid #c2ccd9;
  background: #ffffff;
`;

export const Section = styled.div`
  flex: ${({ flex }) => flex};

  &:first-child {
    margin-right: 20px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  background: ${({ type }) => (type === 'primary' ? '#00AEEF' : type === 'secondary' ? '#E6E9F1' : 'transparent')};
  color: ${({ type }) => (type === 'primary' ? '#F2F2F2' : type === 'secondary' ? '#3F4857' : 'transparent')};
`;

export const Content = styled.div`
  max-height: 540px;
  height: calc(100% - 58px);
  border-radius: 8px;
  background: #f9fafc;
  overflow-y: auto;
`;
