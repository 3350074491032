import styled from 'styled-components';
import {
  flexbox,
  color,
  space,
  typography,
  border,
  layout
} from 'styled-system';

const Box = styled.div`
  color: #3F4857;

  ${color}
  ${space}
  ${border}
  ${layout}
  ${typography}
  ${flexbox}

  box-sizing: border-box;
`;

export default Box;
