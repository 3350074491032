import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import AppHeader from '../../UI/Layout/AppHeader';
import Container from '../../UI/Container/Container';
import Grid from '../../UI/Grid/Grid';
import PlanItem from './PlanItem';
import { getScan } from '../../../actions/scan';
import { resetState } from '../../../actions/checkout';

const WidthWrapper = styled.div`
  max-width: 90%;
  margin: 0 auto;
`;
const CTAHeadline = styled.div`
  font-weight: 900;
  font-size: 2rem;
  margin-top: 2%;
  text-align: center;
`;
const CTAText = styled.div`
  margin-top: 10px;
  font-size: 0.75rem;
  text-align: center;
`;

const Plans = () => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.scan.items);

  useEffect(() => {
    dispatch(getScan());
    dispatch(resetState());
  }, []);

  return (
    <Fragment>
      <AppHeader />
      <WidthWrapper>
        <CTAHeadline>Comprehensive health screenings</CTAHeadline>
        <CTAText>
          We provide access to the latest in medical imaging technology and subspecialty trained radiologists so you can
          get a complete picture of your health
        </CTAText>
        <Container>
          <Grid>
            {items.map((item) => {
              return <PlanItem key={item.id} {...item} />;
            })}
          </Grid>
        </Container>
      </WidthWrapper>
    </Fragment>
  );
};

export default Plans;
