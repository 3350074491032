import Box from './Box';
import styled from 'styled-components';
import { position } from 'styled-system';

const Relative = styled(Box)`
  position: relative;
  ${position}
`;

export const Absolute = styled(Box)`
  position: absolute;
  ${position}
`;

export default Relative;
