import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getReports } from '../../actions/reports';
import ViewConfirm from '../../pages/RegisterDoctor/components/ViewConfirm';
import AuthRoutes from '../../routes/authRoutes';
import HorizontalNavbar from './HorizontalNavbar';
import MobileNavbar from './MobileNavbar';
import { Layout, Content } from './styles';

import 'bootstrap/dist/css/bootstrap.min.css';

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const role = useSelector((state) => state.auth.user.role);
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    dispatch(getReports());
  }, []);

  if (role === 'unver_doctor') {
    return <ViewConfirm />;
  }

  if (isMobile) {
    return (
      <Layout>
        <MobileNavbar />
        <Content>
          <AuthRoutes {...props} />
        </Content>
      </Layout>
    );
  }

  return (
    <Layout>
      <Layout>
        <HorizontalNavbar />
        <Content>
          <AuthRoutes {...props} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
