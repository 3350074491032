import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { LineChart, CartesianGrid, XAxis, YAxis, Line, ResponsiveContainer, Tooltip, Customized } from 'recharts'
import Filter from '../Messages/Filter'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { CustomTooltip } from '../Messages/MessageAnalytics'
import { getNotesAnalytics } from '../../../actions/radminUploads'
import { RemoveRecoIcon } from '../../../components/Dialog/Icon';
import { LegendItem, StatsText } from '../Messages/MessageAnalytics'
import { Spinner } from 'react-bootstrap'


const Container = styled.div`
    border: 1px solid #DFE0EB;
    border-radius: 8px;
    margin: 50px 0px;
    background: #FFFFFF;
`
const Header = styled.div`
    display: flex;
    align-items: center;
    width: 95%;
    margin: 0 auto;
`
const Title = styled.p`
    margin: 0px;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
`
const ChartContainer = styled.div`
height: 335px
background: #FFFFFF;
`

const ResetFilterIconContainer = styled.span`
      cursor: pointer;
`
const LegendContainer = styled.div`
    width: 47%
    display: flex;
`
const BarContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`
const RemoveIconContainer = styled.div`
    width: 40px
`

const options = [
    { value: 'Last 7 days', label: 'Last 7 days' },
    { value: 'Last 30 days', label: 'Last 30 days' },
    { value: 'Last 3 months', label: 'Last 3 months' },
    { value: 'Last 12 months', label: 'Last 12 months' },
]

function RecoNotesAnalytics({containerStyles={}}) {
    const dispatch = useDispatch()

    const notesAnalytics = useSelector(state => state.radAdmin.notes.analytics.data);
    const loading = useSelector(state => state.radAdmin.notes.analytics.isLoading);


    const [filterValue, setFilterValue] = useState(null);
    const [ selectedDate, setSelectedDate ] = useState("")

    useEffect(() => {
        if(filterValue){
            var date = new Date();
            let requiredDate
            let lastMoment
            if(filterValue.value ===  'Last 7 days'){
                lastMoment = new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000));
            }else if(filterValue.value ===  'Last 30 days'){
                lastMoment = new Date(date.getTime() - (30 * 24 * 60 * 60 * 1000));
            }else if(filterValue.value ===  'Last 3 months'){
                lastMoment = new Date(date.getTime() - (90 * 24 * 60 * 60 * 1000));
            }else if(filterValue.value ===  'Last 12 months'){
                lastMoment = new Date(date.getTime() - (365 * 24 * 60 * 60 * 1000));
            }
            let day =lastMoment.getDate();
            let month = lastMoment.getMonth() + 1
            let year = lastMoment.getFullYear()
            requiredDate = `${month}/${day}/${year}`
            setSelectedDate(requiredDate)
            dispatch(getNotesAnalytics(requiredDate))
        }
    }, [JSON.stringify(filterValue)])

    const resetHandle = () => {
        dispatch(getNotesAnalytics())
        setSelectedDate("")
        setFilterValue(null)
    }
    const getStats = (status) => {
        let lowest = 0
        let highest = 0
        let total = 0
        if(!isEmpty(notesAnalytics)){
           let numbersArray = notesAnalytics.map(x => x.notes)
           highest = Math.max(...numbersArray)
           lowest = Math.min(...numbersArray)
           total = numbersArray.reduce((partialSum, a) => partialSum + a, 0);
        }
        switch (status) {
            case 'lowest':
                return lowest
            case 'highest':
                return highest
            case 'total':
                return total
            default:
                return 0;
        }
    }
    const CustomizedDot = (props) => {
        const { cx, cy, r, value } = props;
        let color =  value === getStats('lowest') ? '#F73B3B': value === getStats('highest') ? '#64C67C': '#FDBB13'
        return <circle cx={cx} cy={cy} r={r} stroke={color} strokeWidth={4} fill={color} />
    }
    return (
        <Container style={containerStyles}>
            <Header>
                <Title>Notes as of:</Title>
                <Filter
                    options={options}
                    filterValue={filterValue}
                    setFilterValue={setFilterValue}
                />
                <RemoveIconContainer>
                {selectedDate &&
                    <ResetFilterIconContainer onClick={resetHandle}>
                        <RemoveRecoIcon />
                    </ResetFilterIconContainer>
                }
                </RemoveIconContainer>
                <LegendContainer>
                    <BarContainer>
                        <LegendItem color='#F73B3B'/>
                        <StatsText fontsize='12px'>{`Lowest (${getStats('lowest')})`}</StatsText>
                    </BarContainer>
                    <BarContainer>
                        <LegendItem color='#64C67C'/>
                        <StatsText fontsize='12px'>{`Highest (${getStats('highest')})`}</StatsText>
                    </BarContainer>
                    <BarContainer>
                        <LegendItem color='#00AEEF'/>
                        <StatsText fontsize='12px'>{`Total (${getStats('total')})`}</StatsText>
                    </BarContainer>
                </LegendContainer>
            </Header>
            <ChartContainer>
                <ResponsiveContainer width="100%" height="80%">
                    {
                        loading? <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
                            <Spinner animation="border" role="status" size="sm" />
                        </div>
                       :
                        <LineChart
                        width={500}
                        height={250}
                        data={notesAnalytics}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 10,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="timeStamp" tickFormatter={(value) => {
                            let d = new Date(value)
                           return moment(d).format('MM/DD')
                        }}/>
                        <YAxis allowDecimals={false}/>
                        <Tooltip content={<CustomTooltip />} />
                        <Line
                            type="monotone"
                            dataKey="notes"
                            stroke="#00AEEF"
                            dot={<CustomizedDot />}
                            strokeWidth={2}
                        />
                    </LineChart>
                    }
                  
                </ResponsiveContainer>
            </ChartContainer>
        </Container>
    )
}

export default RecoNotesAnalytics
