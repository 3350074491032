import React from 'react';
import { useSelector } from 'react-redux';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import moment from 'moment';

import { CheckoutNavigationButton } from '../../../components/Scheduling/common';
import { ReactComponent as IconArrowLeft } from '../../../assets/arrow_left.svg';
import { Section, Button, VFlex, HFlex } from './Common';
import { Field, Separator } from './Information';

const ResponsiveReactModal = styled(ReactModal)`
  width: 50%;
  padding: 20px;
  height: 80% !important;
  margin-top: 10% !important;

  @media (max-width: 768px) {
    width: auto;
    height: auto;
    margin: 0;
    margin-top: 15% !important;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 10px;
`;

const StyledButton = styled(Button)`
  margin: 0 5px 0 5px;
`;

const ConfirmPrescription = ({ isOpen, onSubmit, onCancel }) => {
  const appointment = useSelector((state) => state.patientProfile.appointments.scan ?? {});
  const scan = useSelector((state) => state.scan.items.find((i) => i.id === appointment.scan) ?? {});

  const doctor = useSelector((state) => ({
    fname: state.auth.user.first_name,
    lname: state.auth.user.last_name
  }));
  const patient = useSelector((state) => ({
    fname: state.patientProfile.user?.first_name,
    lname: state.patientProfile.user?.last_name
  }));

  return (
    <ResponsiveReactModal isOpen={isOpen} height='100%'>
      Prescription summary
      <Separator />
      <VFlex mr='20px' flex='1' />
      <Section>
        <HFlex height='100%'>
          <VFlex mr='20px' flex='1'>
            <Field label='Scan type' value={scan.name} />
            <Separator />
            <Field label='Patient' value={`${patient.fname} ${patient.lname}`} />
            <Separator />
            <Field label='Location' value={`${appointment.location?.title}: ${appointment.location?.address}`} />
            <Separator />
            <Field label='Doctor' value={`Dr. ${doctor.fname} ${doctor.lname}`} />
            <Separator />
            <Field label='Time' value={moment(appointment.date_time).format('LLLL')} />
          </VFlex>
        </HFlex>
      </Section>
      <ButtonContainer>
        <StyledButton onClick={onSubmit} children='Confirm prescription' />
        <CheckoutNavigationButton onClick={onCancel} type='button' previous>
          <IconArrowLeft style={{ marginRight: 5 }} />
          <span children='Previous step' />
        </CheckoutNavigationButton>
      </ButtonContainer>
    </ResponsiveReactModal>
  );
};

export default ConfirmPrescription;
