import React, { useState } from "react";
import styled from "styled-components";
import { FormContainer, FormHeader } from "./common";
import { FormRow, FormItem, FormLabel } from "../../components/Design/form";
import { ModifiedInput, ModifiedFormRow, ModifiedFormItem } from "./common";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import * as authActions from "../../actions/auth/actions";
import { NavigationButton } from "../../components/Design/button";

// const statesOptions = [
//   {
//       label: "Alabama",
//       value: "AL"
//   },
//   {
//       label: "Alaska",
//       value: "AK"
//   },
//   {
//       label: "American Samoa",
//       value: "AS"
//   },
//   {
//       label: "Arizona",
//       value: "AZ"
//   },
//   {
//       label: "Arkansas",
//       value: "AR"
//   },
//   {
//       label: "California",
//       value: "CA"
//   },
//   {
//       label: "Colorado",
//       value: "CO"
//   },
//   {
//       label: "Connecticut",
//       value: "CT"
//   },
//   {
//       label: "Delaware",
//       value: "DE"
//   },
//   {
//       label: "District Of Columbia",
//       value: "DC"
//   },
//   {
//       label: "Federated States Of Micronesia",
//       value: "FM"
//   },
//   {
//       label: "Florida",
//       value: "FL"
//   },
//   {
//       label: "Georgia",
//       value: "GA"
//   },
//   {
//       label: "Guam",
//       value: "GU"
//   },
//   {
//       label: "Hawaii",
//       value: "HI"
//   },
//   {
//       label: "Idaho",
//       value: "ID"
//   },
//   {
//       label: "Illinois",
//       value: "IL"
//   },
//   {
//       label: "Indiana",
//       value: "IN"
//   },
//   {
//       label: "Iowa",
//       value: "IA"
//   },
//   {
//       label: "Kansas",
//       value: "KS"
//   },
//   {
//       label: "Kentucky",
//       value: "KY"
//   },
//   {
//       label: "Louisiana",
//       value: "LA"
//   },
//   {
//       label: "Maine",
//       value: "ME"
//   },
//   {
//       label: "Marshall Islands",
//       value: "MH"
//   },
//   {
//       label: "Maryland",
//       value: "MD"
//   },
//   {
//       label: "Massachusetts",
//       value: "MA"
//   },
//   {
//       label: "Michigan",
//       value: "MI"
//   },
//   {
//       label: "Minnesota",
//       value: "MN"
//   },
//   {
//       label: "Mississippi",
//       value: "MS"
//   },
//   {
//       label: "Missouri",
//       value: "MO"
//   },
//   {
//       label: "Montana",
//       value: "MT"
//   },
//   {
//       label: "Nebraska",
//       value: "NE"
//   },
//   {
//       label: "Nevada",
//       value: "NV"
//   },
//   {
//       label: "New Hampshire",
//       value: "NH"
//   },
//   {
//       label: "New Jersey",
//       value: "NJ"
//   },
//   {
//       label: "New Mexico",
//       value: "NM"
//   },
//   {
//       label: "New York",
//       value: "NY"
//   },
//   {
//       label: "North Carolina",
//       value: "NC"
//   },
//   {
//       label: "North Dakota",
//       value: "ND"
//   },
//   {
//       label: "Northern Mariana Islands",
//       value: "MP"
//   },
//   {
//       label: "Ohio",
//       value: "OH"
//   },
//   {
//       label: "Oklahoma",
//       value: "OK"
//   },
//   {
//       label: "Oregon",
//       value: "OR"
//   },
//   {
//       label: "Palau",
//       value: "PW"
//   },
//   {
//       label: "Pennsylvania",
//       value: "PA"
//   },
//   {
//       label: "Puerto Rico",
//       value: "PR"
//   },
//   {
//       label: "Rhode Island",
//       value: "RI"
//   },
//   {
//       label: "South Carolina",
//       value: "SC"
//   },
//   {
//       label: "South Dakota",
//       value: "SD"
//   },
//   {
//       label: "Tennessee",
//       value: "TN"
//   },
//   {
//       label: "Texas",
//       value: "TX"
//   },
//   {
//       label: "Utah",
//       value: "UT"
//   },
//   {
//       label: "Vermont",
//       value: "VT"
//   },
//   {
//       label: "Virgin Islands",
//       value: "VI"
//   },
//   {
//       label: "Virginia",
//       value: "VA"
//   },
//   {
//       label: "Washington",
//       value: "WA"
//   },
//   {
//       label: "West Virginia",
//       value: "WV"
//   },
//   {
//       label: "Wisconsin",
//       value: "WI"
//   },
//   {
//       label: "Wyoming",
//       value: "WY"
//   }
// ];

const SaveButton = styled(NavigationButton)`
  padding: 15px 30px;
  width: 250px;
  float: right;
  margin: 30px 5px 5px 5px;
`;

// const StyledSelect = styled(Select)`
//   box-sizing: border-box;
//   border-radius: 5px;
//   color: #8692a4;
//   font-size: 14px;
//   line-height: 18px;
//   margin: 0;
// `;

function PhysicalAddress({ updateContactInfo }) {
  const user = useSelector((state) => state.auth.user);
  const [street, setStreet] = useState(user.contactInfo.address1 || "");
  const [city, setCity] = useState(user.contactInfo.city || "");
  /*const [state, setState] = useState({
    label: user.contactInfo.state,
    value: user.contactInfo.state,
  });*/
  const [state, setState] = useState({
    label: 'New York',
    value: 'NY',
  });
  const [zipCode, setZipCode] = useState(user.contactInfo.zip_code || "");

//   const selectStyles = {
//     control: (styles) => ({
//       ...styles,
//       color: "#8692a4",
//       fontSize: "14px",
//       lineHeight: "18px",
//       padding: '3px',
//       border: '1px solid #c2ccd9'
//     }),
//     singleValue: (styles) => ({
//       ...styles,
//       color: "#8692a4",
//       fontSize: "14px",
//       lineHeight: "18px"
//     }),
//     option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
//         ...styles,
//         color: "#8692a4",
//         fontSize: "14px",
//         lineHeight: "18px",
//       })
//   };

  const handleSendPhysicalAddress = (e) => {
    e.preventDefault();
    updateContactInfo({
      userId: user.id,
      contactInfoId: user.contactInfo.id,
      street,
      city,
      state,
      zipCode,
    });
  };

  return (
    <FormContainer>
      <FormHeader>Physical Address</FormHeader>
      <form>
        <FormRow>
          <FormItem>
            <FormLabel>Street</FormLabel>
            <ModifiedInput
              placeholder="Enter your Address"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
            />
          </FormItem>
        </FormRow>
        <ModifiedFormRow>
          <ModifiedFormItem>
            <FormLabel>City</FormLabel>
            <ModifiedInput
              placeholder="Enter your City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </ModifiedFormItem>
          <ModifiedFormItem>
            <FormLabel>State</FormLabel>
            <ModifiedInput
              placeholder="Enter your City"
              value={state.value}
              disabled={true}
              //onChange={(e) => setCity(e.target.value)}
            />
            {/*<StyledSelect
              value={state}
              //options={statesOptions}
              onChange={(e) => setState({ label: e.value, value: e.label })}
              styles={selectStyles}
            />*/}
          </ModifiedFormItem>
        </ModifiedFormRow>
        <FormRow>
          <FormItem>
            <FormLabel>ZIP Code</FormLabel>
            <ModifiedInput
              placeholder="Enter your ZIP"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </FormItem>
        </FormRow>
        <SaveButton primary onClick={(e) => handleSendPhysicalAddress(e)}>
          Save Physical Address
        </SaveButton>
      </form>
    </FormContainer>
  );
}
export default withRouter(connect(null, authActions)(PhysicalAddress));
