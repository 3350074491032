import React, { forwardRef, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import {
  getCustomChartData,
  getSavedCustomChartDataWithId,
  saveCustomChartData,
} from "../../../actions/radminCustomChartData";
import {
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Tooltip,
  Legend,
  Rectangle,
  Sector,
} from "recharts";
import moment from "moment";
import Filter from "../Messages/Filter";
import { useHistory, useParams } from "react-router";
import { Spinner } from "react-bootstrap";
import {
  getAnatomiesListAction,
  getLocations,
  getModalitiesListAction,
  getRadiologistForDropdown,
  getReportsModality,
  getAppointmentsModality,
  getPrescriptionsModality,
} from "../../../actions/radminUploads";
import {
  DEFAULT_VALUES,
  dataSubsetAppointmentsOptions,
  dataSubsetPrescriptionsOptions,
  dataSubsetRecoHistoryOptions,
  dataSubsetEmailsOptions,
  dataSubsetPatientsOptions,
  dataSubsetRadiologistsOptions,
  dataSubsetRecommendationsOptions,
  dataSubsetReferrersOptions,
  dataSubsetReportsOptions,
  dataSubsetSmsOptions,
  dataTypeOptions,
  emailsStatusOptions,
  frequencyOptions,
  messageSentOptions,
  nonTimeSeriesChartTypeOptions,
  noteOptions,
  recommendationTimeframeStatusOptions,
  smsDirectionOptions,
  timeseriesChartTypeOptions,
  timeframeOptions,
  anatomyTypeOptions,
  reportModalityTypeOptions,
  modalityTypeOptions,
  recommendationStatusColors,
  recoHistoryStatusColors,
  smsColors,
  emailColors,
  taskColors,
  SelectDropdownTitle,
  SelectContainer,
  COLORS,
  LegendListContainer,
  LegendList,
  LegendListItem,
  SidebarContainer,
  Input,
  TimeframeContainer,
  DateFilterContainer,
  legendWrapperStyle,
  recommendationsGroupOptions,
  notesGroupOptions,
  emailGroupOptions,
  smsGroupOptions,
  recoHistoryGroupOptions,
  appointmentsGroupOptions,
  prescriptionsGroupOptions,
  dataSubsetNotesOptions,
  randomColor,
  Container,
  flowTypeOptions,
  dataTypeComparisonOptions,
  smsStatusOptions,
  recoStatusOptions,
  timeframeOptionsPrecedingDay,
  dataSubsetRecommendationsPieOptions,
  taskOptions,
  examTypeOptions,
} from "./Constants";
import * as Sentry from "@sentry/react";
import { capitalize, groupBy } from "lodash";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import Flex from "../../../components/_styles/Flex";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar-outline.svg";
import { ReactComponent as RemoveRecoIcon } from "../../../assets/close-outline.svg";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { getUsers } from "../../../actions/radminActivityData";
import {
  flattenCompareArray,
  flattenIndividualArray,
  groupStatusesByCategory,
} from "./util";

const ChartHeader = styled.h1`
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 24px;
  color: #0c2945;
  font-family: Roboto Condensed;
`;

const MultiOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: "10px",
          }}
        >
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            style={{ margin: "0 5px", verticalAlign: "middle" }}
          />{" "}
          <label style={{ margin: "0" }}>{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};

export const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  let percentage =
    (percent * 100).toFixed(0) > 0 ? `${(percent * 100).toFixed(0)}%` : "";
  return (
    <g>
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={"middle"}
        dominantBaseline="central"
      >
        {percentage}
      </text>
    </g>
  );
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  let percentage =
    (percent * 100).toFixed(0) > 0 ? `${(percent * 100).toFixed(0)}%` : "";

  return (
    <g>
      <text
        x={cx}
        y={cy - 30}
        dy={8}
        fill="#0C2945"
        textAnchor={"middle"}
        dominantBaseline="central"
        style={{ fontSize: "20px", lineHeight: "25px", fontWeight: 900 }}
      >
        {percentage}
      </text>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={"#A6ACBE"}
        style={{ fontSize: "10px", lineHeight: "13px", fontWeight: 600 }}
      >
        {payload?.status || payload?.gender}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius}
        outerRadius={outerRadius}
        fill={fill}
      />
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={"middle"}
        dominantBaseline="central"
      >
        {percentage}
      </text>
    </g>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "white",
    boxShadow: "none",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  rectangle: {
    width: "80%",
    height: 200,
    backgroundColor: "blue",
    marginTop: 20,
  },
  accordionRoot: {
    boxShadow: "none",
    border: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
    },
  },
}));

const CustomChartsCreate = () => {
  const [selectedValues, setSelectedValues] = useState(DEFAULT_VALUES);
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getSavedCustomChartDataWithId(id)).then((res) => {
        if (res) {
          let obj = JSON.parse(res?.filters);
          setSelectedValues(obj);
          setFilterValue(obj?.timeframe);
          setTitle(res?.title);
          setStartDate(obj?.startDate);
          setEndDate(obj?.endDate);

          if (selectedValues?.flow_type?.value !== "compare") {
            let recKey =
              selectedValues["data_type"]?.value === "recommendations-pie"
                ? "recommendations-pie"
                : "recommendations";

            setFilterValueNlpRangeEnd(
              obj?.additional?.[recKey]?.nlp_range_end_timeframe
            );
            setFilterValueNlpRangeStart(
              obj?.additional?.[recKey]?.nlp_range_start_timeframe
            );
            setFilterValueExamDate(
              obj?.additional?.[recKey]?.initial_exam_date_timeframe
            );
            setStartDateNlpRangeStart(
              obj?.additional?.[recKey]?.nlp_range_start_gte?.value
            );
            setEndDateNlpRangeStart(
              obj?.additional?.[recKey]?.nlp_range_start_lte?.value
            );

            setStartDateNlpRangeEnd(
              obj?.additional?.[recKey]?.nlp_range_end_gte?.value
            );
            setEndDateNlpRangeEnd(
              obj?.additional?.[recKey]?.nlp_range_end_lte?.value
            );
            setStartDateExamDate(
              obj?.additional?.[recKey]?.initial_exam_date_gte?.value
            );
            setEndDateExamDate(
              obj?.additional?.[recKey]?.initial_exam_date_lte?.value
            );
          }
        }
      });
    }
  }, [id]);

  const [activeIndex, setActiveIndex] = useState(null);
  const [loader, setLoader] = useState(false);
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterValue, setFilterValue] = useState();
  const [selectedDate, setSelectedDate] = useState("");

  const [startDateNlpRangeStart, setStartDateNlpRangeStart] = useState(null);
  const [endDateNlpRangeStart, setEndDateNlpRangeStart] = useState(null);
  const [filterValueNlpRangeStart, setFilterValueNlpRangeStart] = useState();
  const [selectedDateNlpRangeStart, setSelectedDateNlpRangeStart] =
    useState("");

  const [startDateNlpRangeEnd, setStartDateNlpRangeEnd] = useState(null);
  const [endDateNlpRangeEnd, setEndDateNlpRangeEnd] = useState(null);
  const [filterValueNlpRangeEnd, setFilterValueNlpRangeEnd] = useState();
  const [selectedDateNlpRangeEnd, setSelectedDateNlpRangeEnd] = useState("");

  const [startDateExamDate, setStartDateExamDate] = useState(null);
  const [endDateExamDate, setEndDateExamDate] = useState(null);
  const [filterValueExamDate, setFilterValueExamDate] = useState();
  const [selectedDateExamDate, setSelectedDateExamDate] = useState("");
  const handleResetDates = () => {
    setStartDateNlpRangeStart(null);
    setEndDateNlpRangeStart(null);
    setFilterValueNlpRangeStart(null);
    setSelectedDateNlpRangeStart(null);
    setStartDateNlpRangeEnd(null);
    setEndDateNlpRangeEnd(null);
    setFilterValueNlpRangeEnd(null);
    setSelectedDateNlpRangeEnd(null);
    setStartDateExamDate(null);
    setEndDateExamDate(null);
    setFilterValueExamDate(null);
    setSelectedDateExamDate(null);
  };

  const classes = useStyles();

  const radiologists = useSelector(
    (state) => state.radAdmin.followUpList?.dropdownRadiologists.data
  );
  const modalities = useSelector(
    (state) => state.radAdmin.followUpList.modalities.data
  );
  const anatomies = useSelector(
    (state) => state.radAdmin.followUpList.anatomies.data
  );

  const recoConfigData = useSelector((state) => state.radAdmin.recoConfig.data);

  const [ageOptions, setAgeOptions] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [dynamicStatuses, setDynamicStatuses] = useState([]);
  const [statusColors, setStatusColors] = useState({});

  const [loadingMoreLocations, setLoadingMoreLocations] = useState(false);
  const [locationOptions, setLocationOptions] = useState();
  const [nextLocationPage, setNextLocationPage] = useState();
  const [usersOptions, setUsersOptions] = useState();
  const [validationError, setValidationError] = useState("");
  const [modalityReportOptions, setModalityReportOptions] = useState([]);
  const [modalityAppointmentsOptions, setModalityAppointmentsOptions] =
    useState([]);

  const [modalityPrescriptionsOptions, setModalityPrescriptionsOptions] =
    useState([]);


  const selectedLineChart = selectedValues["chart_type"]?.value === "line";
  const selectedBarChart = selectedValues["chart_type"]?.value === "bar";
  const selectedPieChart = selectedValues["chart_type"].value === "pie";
  const selectedGaugeChart = selectedValues["chart_type"].value === "gauge";
  const noGroupBySelected =
    selectedValues["group_by"]?.length === 0 ||
    selectedValues["group_by"] === null;
  const selectedCompareFlowType =
    selectedValues["flow_type"]?.value === "compare";
  const selectedIndividualFlowType =
    selectedValues["flow_type"]?.value === "individual";
  const selectedAppointmentsDataType =
    selectedValues["data_type"]?.value === "appointments";
  const selectedPrescriptionsDataType =
    selectedValues["data_type"]?.value === "prescriptions";
  const selectedNotesDataType = selectedValues["data_type"]?.value === "notes";
  const selectedSmsDataType = selectedValues["data_type"]?.value === "sms";
  const selectedEmailsDataType =
    selectedValues["data_type"]?.value === "emails";
  const selectedReportsDataType =
    selectedValues["data_type"]?.value === "reports";
  const selectedRecommendationsDataType =
    selectedValues["data_type"]?.value === "recommendations";
  const selectedRecoHistoryDataType =
    selectedValues["data_type"]?.value === "reco-history";
  const selectedPatientsDataType =
    selectedValues["data_type"]?.value === "patients";
  const selectedRadiologistsDataType =
    selectedValues["data_type"]?.value === "radiologists";
  const selectedRecommendationsPieDataType =
    selectedValues["data_type"]?.value === "recommendations-pie";

  const generateOptions = (interval) => {
    const maxAge = 100;
    const newOptions = [];
    let start = 1;

    const numGroups = Math.floor(maxAge / interval);
    let end = 0;

    for (let i = 0; i < numGroups; i++) {
      end = start + interval - 1;

      newOptions.push({
        value: `${start}-${end}`,
        label: `${start}-${end}`,
        category: "age",
      });

      start = end + 1;
    }

    if (
      interval === 5 ||
      interval === 10 ||
      interval === 20 ||
      interval === 25 ||
      interval === 50
    ) {
      newOptions.push({
        value: `101-150`,
        label: `101+`,
        category: "age",
      });
    } else if (interval === 15 || interval === 30) {
      newOptions.push({
        value: `91-150`,
        label: `91+`,
        category: "age",
      });
    } else if (interval === 35) {
      newOptions.push({
        value: `71-150`,
        label: `71+`,
        category: "age",
      });
    } else if (interval === 40) {
      newOptions.push({
        value: `81-150`,
        label: `81+`,
        category: "age",
      });
    } else if (interval === 45) {
      newOptions.push({
        value: `91-150`,
        label: `91+`,
        category: "age",
      });
    }

    setAgeOptions(newOptions);
  };

  useEffect(() => {
    generateOptions(recoConfigData?.patientAgeInterval ?? 5);
  }, [recoConfigData]);

  const radiologistOptions = radiologists?.length
    ? radiologists
        .map((item) => ({
          value: item.id,
          label: `${item?.first_name} ${item?.last_name}`,
          category: "radiologist",
        }))
        .filter((item) => item.value)
    : [];
  const modalityOptions = modalities?.length
    ? [{ value: "empty", label: "--Empty--", category: "modality" }].concat(
        modalities
          .map((item) => ({ value: item, label: item, category: "modality" }))
          .filter((item) => item.value)
      )
    : [];
  const anatomyOptions = anatomies?.length
    ? [{ value: "empty", label: "--Empty--", category: "anatomy" }].concat(
        anatomies
          .map((item) => ({ value: item, label: item, category: "anatomy" }))
          .filter((item) => item.value)
      )
    : [];

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    let modifiedObj = {
      ...selectedValues,
      additional: {
        ...selectedValues.additional,
        recommendations: {
          ...selectedValues.additional.recommendations,
          initial_exam_date_timeframe: filterValueExamDate,
          nlp_range_start_timeframe: filterValueNlpRangeStart,
          nlp_range_end_timeframe: filterValueNlpRangeEnd,
        },
      },
    };
    const chartData = {
      title,
      filters: JSON.stringify({
        ...modifiedObj,
        timeframe: filterValue,
        startDate,
        endDate,
        selectedDate,
      }),
    };

    dispatch(saveCustomChartData(chartData, id))
      .then((res) => {
        setIsLoading(false);
        window.analytics.track("Custom-Charts-Save-Click", { ...chartData });
        history.push(`/auth/radAdmin/custom/charts/view`);
      })
      .catch((err) => Sentry.captureException(err));
  };

  const Option = ({ type, ...props }) => {
    return (
      <>
        <components.Option {...props}>
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <span>
              <input
                type={"radio"}
                checked={props.isSelected}
                onChange={() => null}
                style={{ margin: 0, padding: 0 }}
              />{" "}
            </span>
            <label style={{ margin: 0, padding: 0 }}>{props.label}</label>
          </div>
        </components.Option>
      </>
    );
  };

  const IndicatorsContainer = (props) => {
    return (
      <div style={{ backgroundColor: isLoading ? "#eeeeee" : "#FFF" }}>
        <components.IndicatorsContainer {...props} />
      </div>
    );
  };

  React.useEffect(() => {
    if (filterValue) {
      var date = new Date();
      let requiredDate;
      let lastMoment;
      if (filterValue.value === "Custom date range") return;
      setStartDate("");
      setEndDate("");
      if (filterValue.value === "Last 7 days") {
        lastMoment = new Date(moment().subtract(7, "days"));
      } else if (filterValue.value === "Last 30 days") {
        lastMoment = new Date(moment().subtract(30, "days"));
      } else if (filterValue.value === "Last 3 months") {
        lastMoment = new Date(moment().subtract(3, "months"));
      } else if (filterValue.value === "Last 6 months") {
        lastMoment = new Date(moment().subtract(6, "months"));
      }

      if (filterValue.value === "Last 12 months") {
        let dateObj = new Date(moment().subtract(1, "year"));
        requiredDate = moment(dateObj).format("YYYY-MM-DD");
        setSelectedDate(requiredDate);
        return;
      } else {
        requiredDate = moment(lastMoment).format("YYYY-MM-DD");
        setSelectedDate(requiredDate);
      }
    }
  }, [filterValue]);

  React.useEffect(() => {
    if (filterValueNlpRangeStart) {
      var date = new Date();
      let requiredDate;
      let lastMoment;
      if (filterValueNlpRangeStart.value === "Custom date range") return;
      setStartDateNlpRangeStart("");
      setEndDateNlpRangeStart("");
      if (filterValueNlpRangeStart.value === "Last 7 days") {
        lastMoment = new Date(moment().subtract(7, "days"));
      } else if (filterValueNlpRangeStart.value === "Last 30 days") {
        lastMoment = new Date(moment().subtract(30, "days"));
      } else if (filterValueNlpRangeStart.value === "Last 3 months") {
        lastMoment = new Date(moment().subtract(3, "months"));
      } else if (filterValueNlpRangeStart.value === "Last 6 months") {
        lastMoment = new Date(moment().subtract(6, "months"));
      }

      if (filterValueNlpRangeStart.value === "Last 12 months") {
        let dateObj = new Date(moment().subtract(1, "year"));
        requiredDate = moment(dateObj).format("YYYY-MM-DD");
        setSelectedDateNlpRangeStart(requiredDate);
        return;
      } else {
        requiredDate = moment(lastMoment).format("YYYY-MM-DD");
        setSelectedDateNlpRangeStart(requiredDate);
      }
    } else {
      setStartDateNlpRangeStart(null);
      setEndDateNlpRangeStart(null);
      setSelectedDateNlpRangeStart(null);
    }
  }, [filterValueNlpRangeStart]);

  useEffect(() => {
    let recKey =
      selectedValues["data_type"]?.value === "recommendations-pie"
        ? "recommendations-pie"
        : "recommendations";
    if (filterValueNlpRangeStart?.value) {
      if (filterValueNlpRangeStart.value === "Custom date range") {
        setSelectedValues({
          ...selectedValues,
          additional: {
            ...selectedValues.additional,
            [recKey]: {
              ...selectedValues.additional[recKey],
              nlp_range_start_gte: startDateNlpRangeStart
                ? { value: startDateNlpRangeStart }
                : "",
              nlp_range_start_lte: endDateNlpRangeStart
                ? { value: endDateNlpRangeStart }
                : "",
            },
          },
        });
      } else {
        setSelectedValues({
          ...selectedValues,
          additional: {
            ...selectedValues.additional,
            [recKey]: {
              ...selectedValues.additional[recKey],
              nlp_range_start_gte: selectedDateNlpRangeStart
                ? { value: selectedDateNlpRangeStart }
                : "",
              nlp_range_start_lte: {
                value: moment(new Date()).format("YYYY-MM-DD"),
              },
            },
          },
        });
      }
    } else {
      setSelectedValues({
        ...selectedValues,
        additional: {
          ...selectedValues.additional,
          [recKey]: {
            ...selectedValues.additional[recKey],
            nlp_range_start_gte: "",
            nlp_range_start_lte: "",
          },
        },
      });
    }
  }, [selectedDateNlpRangeStart, startDateNlpRangeStart, endDateNlpRangeStart]);

  React.useEffect(() => {
    if (filterValueNlpRangeEnd) {
      var date = new Date();
      let requiredDate;
      let lastMoment;
      if (filterValueNlpRangeEnd.value === "Custom date range") return;
      setStartDateNlpRangeEnd("");
      setEndDateNlpRangeEnd("");
      if (filterValueNlpRangeEnd.value === "Last 7 days") {
        lastMoment = new Date(moment().subtract(7, "days"));
      } else if (filterValueNlpRangeEnd.value === "Last 30 days") {
        lastMoment = new Date(moment().subtract(30, "days"));
      } else if (filterValueNlpRangeEnd.value === "Last 3 months") {
        lastMoment = new Date(moment().subtract(3, "months"));
      } else if (filterValueNlpRangeEnd.value === "Last 6 months") {
        lastMoment = new Date(moment().subtract(6, "months"));
      }

      if (filterValueNlpRangeEnd.value === "Last 12 months") {
        let dateObj = new Date(moment().subtract(1, "year"));
        requiredDate = moment(dateObj).format("YYYY-MM-DD");
        setSelectedDateNlpRangeEnd(requiredDate);
        return;
      } else {
        requiredDate = moment(lastMoment).format("YYYY-MM-DD");
        setSelectedDateNlpRangeEnd(requiredDate);
      }
    } else {
      setStartDateNlpRangeEnd(null);
      setEndDateNlpRangeEnd(null);
      setSelectedDateNlpRangeEnd(null);
    }
  }, [filterValueNlpRangeEnd]);

  useEffect(() => {
    let recKey =
      selectedValues["data_type"]?.value === "recommendations-pie"
        ? "recommendations-pie"
        : "recommendations";
    if (filterValueNlpRangeEnd?.value) {
      if (filterValueNlpRangeEnd.value === "Custom date range") {
        setSelectedValues({
          ...selectedValues,
          additional: {
            ...selectedValues.additional,
            [recKey]: {
              ...selectedValues.additional[recKey],
              nlp_range_end_gte: startDateNlpRangeEnd
                ? { value: startDateNlpRangeEnd }
                : "",
              nlp_range_end_lte: endDateNlpRangeEnd
                ? { value: endDateNlpRangeEnd }
                : "",
            },
          },
        });
      } else {
        setSelectedValues({
          ...selectedValues,
          additional: {
            ...selectedValues.additional,
            [recKey]: {
              ...selectedValues.additional[recKey],
              nlp_range_end_gte: selectedDateNlpRangeEnd
                ? { value: selectedDateNlpRangeEnd }
                : "",
              nlp_range_end_lte: {
                value: moment(new Date()).format("YYYY-MM-DD"),
              },
            },
          },
        });
      }
    } else {
      setSelectedValues({
        ...selectedValues,
        additional: {
          ...selectedValues.additional,
          [recKey]: {
            ...selectedValues.additional[recKey],
            nlp_range_end_gte: "",
            nlp_range_end_lte: "",
          },
        },
      });
    }
  }, [selectedDateNlpRangeEnd, startDateNlpRangeEnd, endDateNlpRangeEnd]);

  React.useEffect(() => {
    if (filterValueExamDate) {
      var date = new Date();
      let requiredDate;
      let lastMoment;
      if (filterValueExamDate.value === "Custom date range") return;
      setStartDateExamDate("");
      setEndDateExamDate("");
      if (filterValueExamDate.value === "Last 7 days") {
        lastMoment = new Date(moment().subtract(7, "days"));
      } else if (filterValueExamDate.value === "Last 30 days") {
        lastMoment = new Date(moment().subtract(30, "days"));
      } else if (filterValueExamDate.value === "Last 3 months") {
        lastMoment = new Date(moment().subtract(3, "months"));
      } else if (filterValueExamDate.value === "Last 6 months") {
        lastMoment = new Date(moment().subtract(6, "months"));
      }

      if (filterValueExamDate.value === "Last 12 months") {
        let dateObj = new Date(moment().subtract(1, "year"));
        requiredDate = moment(dateObj).format("YYYY-MM-DD");
        setSelectedDateExamDate(requiredDate);
        return;
      } else {
        requiredDate = moment(lastMoment).format("YYYY-MM-DD");
        setSelectedDateExamDate(requiredDate);
      }
    } else {
      setStartDateExamDate(null);
      setEndDateExamDate(null);
      setSelectedDateExamDate(null);
    }
  }, [filterValueExamDate]);

  useEffect(() => {
    let recKey =
      selectedValues["data_type"]?.value === "recommendations-pie"
        ? "recommendations-pie"
        : "recommendations";
    if (filterValueExamDate?.value) {
      if (filterValueExamDate.value === "Custom date range") {
        setSelectedValues({
          ...selectedValues,
          additional: {
            ...selectedValues.additional,
            [recKey]: {
              ...selectedValues.additional[recKey],

              initial_exam_date_gte: startDateExamDate
                ? { value: startDateExamDate }
                : "",
              initial_exam_date_lte: endDateExamDate
                ? { value: endDateExamDate }
                : "",
            },
          },
        });
      } else {
        setSelectedValues({
          ...selectedValues,
          additional: {
            ...selectedValues.additional,
            [recKey]: {
              ...selectedValues.additional[recKey],

              initial_exam_date_gte: selectedDateExamDate
                ? { value: selectedDateExamDate }
                : "",
              initial_exam_date_lte: {
                value: moment(new Date()).format("YYYY-MM-DD"),
              },
            },
          },
        });
      }
    } else {
      setSelectedValues({
        ...selectedValues,
        additional: {
          ...selectedValues.additional,
          [recKey]: {
            ...selectedValues.additional[recKey],
            initial_exam_date_gte: "",
            initial_exam_date_lte: "",
          },
        },
      });
    }
  }, [selectedDateExamDate, startDateExamDate, endDateExamDate]);

  useEffect(() => {
    if (
      selectedLineChart || selectedBarChart
        ? selectedCompareFlowType
          ? selectedValues["data_type"] &&
            selectedValues["data_subset"] &&
            selectedValues["chart_type"] &&
            selectedValues["frequency"] &&
            filterValue &&
            !validationError
          : selectedValues["data_type"] &&
            selectedValues["data_subset"] &&
            selectedValues["chart_type"] &&
            selectedValues["frequency"] &&
            filterValue &&
            !validationError
        : selectedValues["data_type"] &&
          selectedValues["data_subset"] &&
          selectedValues["chart_type"] &&
          !validationError
    ) {
      setData([]);
      if (filterValue?.value === "Custom date range" && startDate && endDate) {
        setLoader(true);

        dispatch(
          getCustomChartData(selectedValues, startDate, endDate, filterValue)
        );

        dispatch(
          getCustomChartData(selectedValues, startDate, endDate, filterValue)
        )
          .then((res) => {
            setData(res);
          })
          .catch((err) => Sentry.captureException(err))
          .finally(() => setLoader(false));
      } else if (filterValue?.value !== "Custom date range") {
        setLoader(true);

        dispatch(
          getCustomChartData(
            selectedValues,
            selectedDate,
            moment(new Date()).format("YYYY-MM-DD"),
            filterValue
          )
        )
          .then((res) => {
            setData(res);
          })
          .catch((err) => Sentry.captureException(err))
          .finally(() => setLoader(false));
      }
    }
  }, [selectedValues, selectedDate, startDate, endDate]);

  useEffect(() => {
    dispatch(getRadiologistForDropdown());
    dispatch(getModalitiesListAction());
    dispatch(getAnatomiesListAction());
  }, [dispatch]);

  useEffect(() => {
    if (!validationError && selectedCompareFlowType) {
      let flattenedData = [];
      let aggregatedData = [];
      let finalData = [];
      let dynamicStatuses = [];

      setFinalData([]);
      setDynamicStatuses([]);

      if (
        selectedCompareFlowType &&
        data?.length > 0 &&
        Array.isArray(selectedValues["data_type"])
      ) {
        const dataObj = flattenCompareArray(data, flattenedData);
        flattenedData = dataObj.flattenedData;

        aggregatedData = flattenedData.reduce((acc, item) => {
          if (!acc[item.date]) {
            acc[item.date] = { date: item.date, category: item.category };
          }

          Object.keys(item).forEach((key) => {
            if (key !== "date" && key !== "category") {
              acc[item.date][key] = (acc[item.date][key] || 0) + item[key];
            }
          });
          return acc;
        }, {});

        finalData = Object.values(aggregatedData);

        dynamicStatuses = Array.from(
          new Set(
            finalData.flatMap((item) =>
              Object.keys(item).filter(
                (key) => key !== "date" && key !== "category"
              )
            )
          )
        );

        setFinalData(finalData);
        setDynamicStatuses(dynamicStatuses);
      }
    }
  }, [data.length, selectedValues, validationError]);

  useEffect(() => {
    const getRandomColor = () => {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    if (dynamicStatuses.length > 0) {
      const generatedColors = dynamicStatuses.reduce((acc, status) => {
        acc[status] = getRandomColor();
        return acc;
      }, {});
      setStatusColors(generatedColors);
    }
  }, [dynamicStatuses]);

  useEffect(() => {
    dispatch(getLocations()).then((res) => {
      if (res) {
        if (res.next) {
          setNextLocationPage(2);
        }
        setLocationOptions(
          res.results.map((itm) => ({
            category: "location",
            label: itm.location_address.replace(/\^/g, ","),
            value: itm.location_address,
          }))
        );
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getUsers()).then((res) => {
      if (res) {
        setUsersOptions(
          res?.results?.map((itm) => ({
            label: itm.email,
            value: itm.id,
            category: "user",
          }))
        );
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getReportsModality())
      .then((res) => {
        if (res) {
          setModalityReportOptions(
            [
              {
                value: "empty",
                label: "--Empty--",
                category: "report_modality",
              },
            ].concat(
              res
                ?.map((itm) => ({
                  label: itm.report__modality,
                  value: itm.report__modality,
                  category: "report_modality",
                }))
                .filter((itm) => itm.value)
            )
          );
        }
      })
      .catch((err) => Sentry.captureException(err));
  }, []);

  useEffect(() => {
    dispatch(getPrescriptionsModality())
      .then((res) => {
        if (res) {
          setModalityPrescriptionsOptions(
            [
              {
                value: "empty",
                label: "--Empty--",
                category: "modality",
              },
            ].concat(
              res
                ?.map((itm) => ({
                  label: itm.modality,
                  value: itm.modality,
                  category: "modality",
                }))
                .filter((itm) => itm.value)
            )
          );
        }
      })
      .catch((err) => Sentry.captureException(err));
  }, []);

  useEffect(() => {
    dispatch(getAppointmentsModality())
      .then((res) => {
        if (res) {
          setModalityAppointmentsOptions(
            [
              {
                value: "empty",
                label: "--Empty--",
                category: "modality",
              },
            ].concat(
              res
                ?.map((itm) => ({
                  label: itm.modality,
                  value: itm.modality,
                  category: "modality",
                }))
                .filter((itm) => itm.value)
            )
          );
        }
      })
      .catch((err) => Sentry.captureException(err));
  }, []);


  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <LegendListContainer>
        <LegendList>
          {payload
            .sort((a, b) => b.payload.id__count - a.payload.id__count)
            .map((entry, index) => (
              <LegendListItem
                key={index}
                color={COLORS[index % COLORS.length]}
              >{`${
                selectedValues["data_type"]?.value === "radiologists" ||
                selectedValues["data_type"]?.value === "referrers"
                  ? entry.payload["status"].replaceAll("_", " ")
                  : entry.payload[selectedValues["data_subset"].value]
              } - ${entry.payload.id__count}`}</LegendListItem>
            ))}
        </LegendList>
      </LegendListContainer>
    );
  };
  let flattenData = [];
  let uniqueStatuses = [];

  if (
    selectedValues["group_by"]?.length > 0 &&
    selectedIndividualFlowType &&
    data?.length > 0
  ) {
    const dataObj = flattenIndividualArray(
      selectedValues,
      data,
      flattenData,
      uniqueStatuses
    );
    flattenData = dataObj.flattenData;
    uniqueStatuses = dataObj.uniqueStatuses;
  }

  const groupedStatuses = groupStatusesByCategory(
    selectedValues,
    dynamicStatuses,
    statusColors
  );

  const loadMoreLocationOptions = async () => {
    if (nextLocationPage) {
      setLoadingMoreLocations(true);
      dispatch(getLocations(nextLocationPage)).then((res) => {
        setLoadingMoreLocations(false);
        if (res) {
          setLocationOptions((prev) => [
            ...prev,
            ...res.results.map((itm) => ({
              category: "location",
              label: itm.location_address.replace(/\^/g, ","),
              value: itm.location_address,
            })),
          ]);
          if (res.next) {
            setNextLocationPage((prev) => prev + 1);
          } else {
            setNextLocationPage();
          }
        }
      });
    }
  };

  const styles = {
    option: (styles) => ({
      ...styles,
      fontSize: "14px",
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: isLoading ? "#eeeeee" : "white",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: isLoading ? "#eeeeee" : "white",
    }),
    multiValue: (styles, { data }) => ({
      ...styles,
      borderRadius: "25px",
      backgroundColor: "#EEEEEE",
      color: "#A1A1A1",
      padding: "4px 10px",
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#A1A1A1",
      fontSize: "10px",
    }),
  };

  const DatePickerInput = forwardRef(
    ({ onClick, startDate, endDate, startKey, endKey }, ref) => {
      const formatDate = (date) => {
        return date ? moment(date).format("YYYY-MM-DD") : "No Date";
      };

      return (
        <DatePickerButton onClick={onClick} ref={ref}>
          <DateContainer>
            {!startDate && !endDate
              ? "Select..."
              : `${formatDate(startDate)} - ${formatDate(endDate)}`}
          </DateContainer>
          {!startDate && !endDate && <CalendarIcon />}
          {(startDate || endDate) && (
            <DateClearButton onClick={() => resetDateFilter(startKey, endKey)}>
              <RemoveRecoIcon />
            </DateClearButton>
          )}
        </DatePickerButton>
      );
    }
  );

  const resetDateFilter = (startKey, endKey) => {
    setSelectedValues({
      ...selectedValues,
      additional: {
        ...selectedValues.additional,
        recommendations: {
          ...selectedValues.additional.recommendations,
          [startKey]: "",
          [endKey]: "",
        },
      },
    });
  };

  const DatePickerButton = styled(Flex)`
    padding: 10px 15px;
    align-items: center;
    background: #ffffff;
    border: 1px solid #d2d3d7;
    border-radius: 4px;
    font-size: 18px;
    width: 227px;
    display: flex;
    justify-content: space-between;
    &:hover {
      cursor: pointer;
    }
  `;
  const DateContainer = styled(Flex)`
    margin-right: 15px;
    font-size: 14px;
    color: #828282;
    font-family: Open Sans;
  `;

  const DateClearButton = styled.div`
    cursor: pointer;
    display: flex;
  `;

  const handleInputChange = (event) => {
    const inputValue = event?.target?.value?.split(",");
    let recKey =
      selectedValues["data_type"]?.value === "recommendations-pie"
        ? "recommendations-pie"
        : "recommendations";

    setSelectedValues({
      ...selectedValues,
      additional: {
        ...selectedValues.additional,
        [recKey]: {
          ...selectedValues.additional[recKey],
          exam_code: inputValue.map((itm) => ({
            value: itm,
            category: "exam_code",
            label: "Exam Code",
          })),
        },
      },
    });

    const codes = inputValue.map((code) => code.trim());

    let error = "";

    let hasInvalidLength = false;
    let hasInvalidSeparator = false;
    let hasInvalidLastElement = false;

    for (let i = 0; i < codes.length; i++) {
      const code = codes[i];
      if (code && !code.match(/^\w{5}$/)) {
        hasInvalidLength = true;
      }
    }

    if (codes.some((code) => code.includes(" "))) {
      hasInvalidSeparator = true;
    }
    if (codes.length > 1 && codes.some((code) => code === "")) {
      hasInvalidLastElement = true;
    }

    if (hasInvalidLength && hasInvalidSeparator) {
      error =
        "<span>Exam codes must be five digits long.</span><br/><span>Commas must be used to separate multiple exam codes (e.g. 00000,00000).</span>";
    } else if (hasInvalidLength) {
      error = "<span>Exam codes must be five digits long.</span>";
    } else if (hasInvalidSeparator) {
      error =
        "<span>Commas must be used to separate multiple exam codes (e.g. 00000,00000).</span>";
    } else if (hasInvalidLastElement) {
      error = "<span>Enter value after last comma or remove last comma.</span>";
    }

    setValidationError(error);
  };

  return (
    <Container
      isTimeGraph={
        selectedValues["chart_type"]?.value === "bar" ||
        selectedValues["chart_type"]?.value === "line"
      }
      style={{ display: "flex", width: "100%", height: "100%" }}
    >
      <SidebarContainer>
        <ChartHeader>{id ? "Edit Chart" : "New Chart"}</ChartHeader>

        <SelectContainer>
          <SelectDropdownTitle>Flow Type</SelectDropdownTitle>
          <Select
            components={{ IndicatorsContainer, Option }}
            className="basic-multi-select"
            isDisabled={isLoading}
            classNamePrefix="select"
            options={flowTypeOptions}
            value={selectedValues["flow_type"]}
            onChange={(value) => {
              setSelectedValues({
                ...selectedValues,
                flow_type: value,
                data_type: "",
                data_subset: "",
                chart_type: "",
                frequency: "",
                group_by: [
                  { value: "status", label: "Status", category: "status" },
                ],
                compare_preceeding: false,
                additional: {
                  recommendations: {
                    status: "",
                    timeframe_status_extracted: "",
                    modality: "",
                    anatomy: "",
                    age: "",
                    message_sent: "",
                    has_note: "",
                    location_address: "",
                    report__radiologist__id: "",
                    report__modality: "",
                    modality_type: {
                      value: true,
                      label: "Include",
                      category: "modality_type",
                    },
                    anatomy_type: {
                      value: true,
                      label: "Include",
                      category: "anatomy_type",
                    },
                    report__modality_type: {
                      value: true,
                      label: "Include",
                      category: "report__modality_type",
                    },
                    initial_exam_date_gte: "",
                    initial_exam_date_lte: "",
                    nlp_range_start_gte: "",
                    nlp_range_start_lte: "",
                    nlp_range_end_gte: "",
                    nlp_range_end_lte: "",
                    exam_code_type: {
                      value: true,
                      label: "Include",
                      category: "exam_code_type",
                    },
                  },
                  reports: {
                    modality: "",
                    radiologist: "",
                    message_sent: "",
                    modality_type: {
                      value: true,
                      label: "Include",
                      category: "modality_type",
                    },
                  },
                  patients: {},
                  referrers: {},
                  radiologists: {},
                  emails: {
                    status: "",
                  },
                  sms: {
                    direction: "",
                    status: "",
                  },
                  notes: {
                    task: "",
                  },
                  "recommendations-pie": {
                    status: "",
                    timeframe_status_extracted: "",
                    modality: "",
                    anatomy: "",
                    age: "",
                    message_sent: "",
                    has_note: "",
                    location_address: "",
                    report__radiologist__id: "",
                    report__modality: "",
                    modality_type: {
                      value: true,
                      label: "Include",
                      category: "modality_type",
                    },
                    anatomy_type: {
                      value: true,
                      label: "Include",
                      category: "anatomy_type",
                    },
                    report__modality_type: {
                      value: true,
                      label: "Include",
                      category: "report__modality_type",
                    },
                    initial_exam_date_gte: "",
                    initial_exam_date_lte: "",
                    nlp_range_start_gte: "",
                    nlp_range_start_lte: "",
                    nlp_range_end_gte: "",
                    nlp_range_end_lte: "",
                    exam_code_type: {
                      value: true,
                      label: "Include",
                      category: "exam_code_type",
                    },
                  },
                  "reco-history": {
                    user: "",
                    status_override_from: "",
                    status_override_to: "",
                  },
                  appointments: {
                    modality: "",
                  },
                  prescriptions: {
                    modality: "",
                  },
                },
              });
              setFilterValue();
              setSelectedDate();
              setStartDate();
              setEndDate();
              handleResetDates();
              setData([]);
            }}
            styles={styles}
          />
        </SelectContainer>
        {selectedIndividualFlowType ? (
          <form onSubmit={handleFormSubmit}>
            <SelectContainer>
              <SelectDropdownTitle>Data Type *</SelectDropdownTitle>
              <Select
                components={{ IndicatorsContainer, Option }}
                className="basic-multi-select"
                isDisabled={isLoading}
                classNamePrefix="select"
                options={dataTypeOptions}
                value={selectedValues["data_type"]}
                onChange={(value) => {
                  setSelectedValues({
                    ...selectedValues,
                    data_type: value,
                    data_subset: "",
                    chart_type: "",
                    frequency: "",
                    group_by:
                      value?.value === "appointments"
                        ? [
                            {
                              value: "modality",
                              label: "Modality",
                              category: "status",
                            },
                          ]
                        : value?.value === "prescriptions"
                        ? [
                            {
                              value: "modality",
                              label: "Modality",
                              category: "status",
                            },
                          ]
                        : value?.value === "reco-history"
                        ? [
                            {
                              value: "user__email",
                              label: "User",
                              category: "status",
                            },
                          ]
                        : value?.value === "notes"
                        ? [
                            {
                              value: "task",
                              label: "Task",
                              category: "status",
                            },
                          ]
                        : value?.value === "sms" ||
                          value?.value === "emails" ||
                          value?.value === "recommendations"
                        ? [
                            {
                              value: "status",
                              label: "Status",
                              category: "status",
                            },
                          ]
                        : [],
                    additional: {
                      recommendations: {
                        status: "",
                        timeframe_status_extracted: "",
                        modality: "",
                        anatomy: "",
                        age: "",
                        message_sent: "",
                        has_note: "",
                        location_address: "",
                        report__radiologist__id: "",
                        report__modality: "",
                        modality_type: {
                          value: true,
                          label: "Include",
                          category: "modality_type",
                        },
                        anatomy_type: {
                          value: true,
                          label: "Include",
                          category: "anatomy_type",
                        },
                        report__modality_type: {
                          value: true,
                          label: "Include",
                          category: "report__modality_type",
                        },
                        exam_code_type: {
                          value: true,
                          label: "Include",
                          category: "exam_code_type",
                        },
                        initial_exam_date_gte: "",
                        initial_exam_date_lte: "",
                        nlp_range_start_gte: "",
                        nlp_range_start_lte: "",
                        nlp_range_end_gte: "",
                        nlp_range_end_lte: "",
                      },
                      reports: {
                        modality: "",
                        radiologist: "",
                        message_sent: "",
                        modality_type: {
                          value: true,
                          label: "Include",
                          category: "modality_type",
                        },
                      },
                      patients: {},
                      referrers: {},
                      radiologists: {},
                      emails: {
                        status: "",
                      },
                      sms: {
                        direction: "",
                        status: "",
                      },
                      notes: {
                        task: "",
                      },
                      "recommendations-pie": {
                        status: "",
                        timeframe_status_extracted: "",
                        modality: "",
                        anatomy: "",
                        age: "",
                        message_sent: "",
                        has_note: "",
                        location_address: "",
                        report__radiologist__id: "",
                        report__modality: "",
                        modality_type: {
                          value: true,
                          label: "Include",
                          category: "modality_type",
                        },
                        anatomy_type: {
                          value: true,
                          label: "Include",
                          category: "anatomy_type",
                        },
                        report__modality_type: {
                          value: true,
                          label: "Include",
                          category: "report__modality_type",
                        },
                        exam_code_type: {
                          value: true,
                          label: "Include",
                          category: "exam_code_type",
                        },
                        initial_exam_date_gte: "",
                        initial_exam_date_lte: "",
                        nlp_range_start_gte: "",
                        nlp_range_start_lte: "",
                        nlp_range_end_gte: "",
                        nlp_range_end_lte: "",
                      },
                      "reco-history": {
                        user: "",
                        status_override_from: "",
                        status_override_to: "",
                      },
                      appointments: {
                        modality: "",
                      },
                      prescriptions: {
                        modality: "",
                      },
                    },
                  });
                  setFilterValue();
                  setSelectedDate();
                  setStartDate();
                  setEndDate();
                  handleResetDates();
                  setData([]);
                }}
                styles={styles}
              />
            </SelectContainer>

            <SelectContainer>
              <SelectDropdownTitle>Data Subset *</SelectDropdownTitle>
              <Select
                components={{ IndicatorsContainer, Option }}
                className="basic-multi-select"
                isDisabled={isLoading || !selectedValues["data_type"]}
                classNamePrefix="select"
                options={
                  selectedAppointmentsDataType
                    ? dataSubsetAppointmentsOptions
                    : selectedPrescriptionsDataType
                    ? dataSubsetPrescriptionsOptions
                    : selectedRecoHistoryDataType
                    ? dataSubsetRecoHistoryOptions
                    : selectedRecommendationsDataType
                    ? dataSubsetRecommendationsOptions
                    : selectedReportsDataType
                    ? dataSubsetReportsOptions
                    : selectedEmailsDataType
                    ? dataSubsetEmailsOptions
                    : selectedSmsDataType
                    ? dataSubsetSmsOptions
                    : selectedRadiologistsDataType
                    ? dataSubsetRadiologistsOptions
                    : selectedRecommendationsPieDataType
                    ? dataSubsetRecommendationsPieOptions
                    : selectedPatientsDataType
                    ? dataSubsetPatientsOptions
                    : selectedNotesDataType
                    ? dataSubsetNotesOptions
                    : dataSubsetReferrersOptions
                }
                value={selectedValues["data_subset"]}
                onChange={(value) => {
                  setSelectedValues({
                    ...selectedValues,
                    data_subset: value,
                    additional: {
                      ...selectedValues.additional,
                      recommendations: {
                        ...selectedValues.additional.recommendations,
                        initial_exam_date_gte: "",
                        initial_exam_date_lte: "",
                        nlp_range_start_gte: "",
                        nlp_range_start_lte: "",
                        nlp_range_end_gte: "",
                        nlp_range_end_lte: "",
                      },
                      "recommendations-pie": {
                        ...selectedValues.additional["recommendations-pie"],
                        initial_exam_date_gte: "",
                        initial_exam_date_lte: "",
                        nlp_range_start_gte: "",
                        nlp_range_start_lte: "",
                        nlp_range_end_gte: "",
                        nlp_range_end_lte: "",
                      },
                    },
                  });
                  handleResetDates();
                }}
                styles={styles}
              />
            </SelectContainer>

            <SelectContainer>
              <SelectDropdownTitle>Chart Type *</SelectDropdownTitle>
              <Select
                isDisabled={isLoading || !selectedValues["data_type"]}
                components={{ IndicatorsContainer, Option }}
                className="basic-multi-select"
                classNamePrefix="select"
                options={
                  selectedRecommendationsDataType ||
                  selectedReportsDataType ||
                  selectedEmailsDataType ||
                  selectedSmsDataType ||
                  selectedNotesDataType ||
                  selectedRecoHistoryDataType ||
                  selectedAppointmentsDataType ||
                  selectedPrescriptionsDataType
                    ? timeseriesChartTypeOptions
                    : nonTimeSeriesChartTypeOptions
                }
                value={selectedValues["chart_type"]}
                onChange={(value) =>
                  setSelectedValues({
                    ...selectedValues,
                    chart_type: value,
                  })
                }
                styles={styles}
              />
            </SelectContainer>
            {(selectedLineChart || selectedBarChart) && (
              <SelectContainer>
                <SelectDropdownTitle>Frequency *</SelectDropdownTitle>
                <Select
                  isDisabled={isLoading}
                  components={{ IndicatorsContainer, Option }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={frequencyOptions}
                  value={selectedValues["frequency"]}
                  onChange={(value) => {
                    setSelectedValues({
                      ...selectedValues,
                      frequency: "",
                    });
                    setFilterValue();
                    setSelectedDate();
                    setStartDate();
                    setEndDate();
                    setTimeout(() => {
                      setSelectedValues({
                        ...selectedValues,
                        frequency: value,
                      });
                    }, 0);
                  }}
                  styles={styles}
                />
              </SelectContainer>
            )}
            {(selectedLineChart || selectedBarChart) &&
              selectedValues["frequency"] && (
                <SelectContainer>
                  <SelectDropdownTitle>Timeframe *</SelectDropdownTitle>
                  <DateFilterContainer>
                    <TimeframeContainer>
                      <Filter
                        selectContainerStyles={{ width: "227px" }}
                        options={timeframeOptions}
                        filterValue={filterValue}
                        setFilterValue={setFilterValue}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setSelectedDate={setSelectedDate}
                        type="radio"
                        isDisabled={isLoading || !selectedValues["frequency"]}
                        hasPortal={true}
                        displayFormat="YYYY-MM-DD"
                      />
                    </TimeframeContainer>
                  </DateFilterContainer>
                </SelectContainer>
              )}
            {selectedReportsDataType && (
              <>
                <SelectContainer>
                  <SelectDropdownTitle>Message Sent</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={messageSentOptions}
                    value={
                      selectedValues["additional"].reports?.["message_sent"]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          reports: {
                            ...selectedValues.additional.reports,
                            message_sent: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Modality</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityReportOptions}
                    value={selectedValues["additional"].reports?.["modality"]}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          reports: {
                            ...selectedValues.additional.reports,
                            modality: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                    isMulti
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Modality Type</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityTypeOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].reports?.["modality_type"]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          reports: {
                            ...selectedValues.additional.reports,
                            modality_type: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Radiologist</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={radiologistOptions}
                    value={
                      selectedValues["additional"].reports?.["radiologist"]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          reports: {
                            ...selectedValues.additional.reports,
                            radiologist: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
              </>
            )}
            {selectedRecommendationsDataType && (
              <>
                <SelectContainer>
                  <SelectDropdownTitle>Group By</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={recommendationsGroupOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={selectedValues?.["group_by"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        group_by: value,
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>

                {selectedValues["data_subset"]?.value &&
                  selectedValues["data_subset"]?.value !== "range_start" && (
                    <SelectContainer>
                      <SelectDropdownTitle>Nlp Range Start</SelectDropdownTitle>
                      <DateFilterContainer>
                        <TimeframeContainer>
                          <Filter
                            selectContainerStyles={{ width: "227px" }}
                            options={timeframeOptions}
                            filterValue={filterValueNlpRangeStart}
                            setFilterValue={setFilterValueNlpRangeStart}
                            startDate={startDateNlpRangeStart}
                            setStartDate={setStartDateNlpRangeStart}
                            endDate={endDateNlpRangeStart}
                            setEndDate={setEndDateNlpRangeStart}
                            setSelectedDate={setSelectedDateNlpRangeStart}
                            type="radio"
                            isDisabled={isLoading}
                            hasPortal={true}
                            displayFormat="YYYY-MM-DD"
                            isClearable={true}
                          />
                        </TimeframeContainer>
                      </DateFilterContainer>
                    </SelectContainer>
                  )}

                {selectedValues["data_subset"]?.value &&
                  selectedValues["data_subset"]?.value !== "range_end" && (
                    <SelectContainer>
                      <SelectDropdownTitle>Nlp Range End</SelectDropdownTitle>
                      <DateFilterContainer>
                        <TimeframeContainer>
                          <Filter
                            selectContainerStyles={{ width: "227px" }}
                            options={timeframeOptions}
                            filterValue={filterValueNlpRangeEnd}
                            setFilterValue={setFilterValueNlpRangeEnd}
                            startDate={startDateNlpRangeEnd}
                            setStartDate={setStartDateNlpRangeEnd}
                            endDate={endDateNlpRangeEnd}
                            setEndDate={setEndDateNlpRangeEnd}
                            setSelectedDate={setSelectedDateNlpRangeEnd}
                            type="radio"
                            isDisabled={isLoading}
                            hasPortal={true}
                            displayFormat="YYYY-MM-DD"
                            isClearable={true}
                          />
                        </TimeframeContainer>
                      </DateFilterContainer>
                    </SelectContainer>
                  )}
                {selectedValues["data_subset"]?.value &&
                  selectedValues["data_subset"]?.value !==
                    "initial_exam_date" && (
                    <SelectContainer>
                      <SelectDropdownTitle>Exam Range</SelectDropdownTitle>
                      <DateFilterContainer>
                        <TimeframeContainer>
                          <Filter
                            selectContainerStyles={{ width: "227px" }}
                            options={timeframeOptions}
                            filterValue={filterValueExamDate}
                            setFilterValue={setFilterValueExamDate}
                            startDate={startDateExamDate}
                            setStartDate={setStartDateExamDate}
                            endDate={endDateExamDate}
                            setEndDate={setEndDateExamDate}
                            setSelectedDate={setSelectedDateExamDate}
                            type="radio"
                            isDisabled={isLoading}
                            hasPortal={true}
                            displayFormat="YYYY-MM-DD"
                            isClearable={true}
                          />
                        </TimeframeContainer>
                      </DateFilterContainer>
                    </SelectContainer>
                  )}

                <SelectContainer>
                  <SelectDropdownTitle>Timeframe Status</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={recommendationTimeframeStatusOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "timeframe_status_extracted"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            timeframe_status_extracted: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Modality</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].recommendations?.["modality"]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            modality: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Modality Type</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityTypeOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "modality_type"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            modality_type: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Anatomy</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={anatomyOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].recommendations?.["anatomy"]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            anatomy: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Anatomy Type</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={anatomyTypeOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "anatomy_type"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            anatomy_type: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Report Modality</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityReportOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "report__modality"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            report__modality: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>
                    Report Modality Type
                  </SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={reportModalityTypeOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "report__modality_type"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            report__modality_type: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Radiologist</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={radiologistOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "report__radiologist__id"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            report__radiologist__id: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Location</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={locationOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onMenuScrollToBottom={loadMoreLocationOptions}
                    isLoading={loadingMoreLocations}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "location_address"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            location_address: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Age</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={ageOptions}
                    value={
                      selectedValues["additional"].recommendations?.["age"]
                    }
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            age: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Contains Note</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={noteOptions}
                    value={
                      selectedValues["additional"].recommendations?.["has_note"]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            has_note: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Message Sent</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={messageSentOptions}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "message_sent"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            message_sent: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Report Exam Code</SelectDropdownTitle>

                  <input
                    style={{
                      resize: "none",
                      border: validationError
                        ? "1px solid #B40000"
                        : "1px solid #D2D3D7",
                      borderRadius: "4px",
                      padding: "10px",
                      height: "43.5px",
                      width: "227px",
                    }}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "exam_code"
                      ]?.length > 0
                        ? selectedValues["additional"].recommendations?.[
                            "exam_code"
                          ]
                            ?.map((itm) => itm.value)
                            ?.join(",")
                        : ""
                    }
                    onChange={handleInputChange}
                    placeholder="Enter 5 digit code"
                  />

                  {selectedValues["additional"].recommendations?.["exam_code"]
                    ?.length > 0 &&
                    validationError && (
                      <div
                        style={{
                          color: "#B40000",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: validationError,
                        }}
                      ></div>
                    )}
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Exam Code Type</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={examTypeOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "exam_code_type"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            exam_code_type: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
              </>
            )}

            {selectedEmailsDataType && (
              <>
                <SelectContainer>
                  <SelectDropdownTitle>Group By</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={emailGroupOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={selectedValues?.["group_by"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        group_by: value,
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Email Status</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={emailsStatusOptions}
                    value={selectedValues["additional"].emails?.["status"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          emails: {
                            ...selectedValues.additional.emails,
                            status: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
              </>
            )}

            {selectedSmsDataType && (
              <>
                <SelectContainer>
                  <SelectDropdownTitle>Group By</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={smsGroupOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={selectedValues?.["group_by"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        group_by: value,
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Sms Direction</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={smsDirectionOptions}
                    value={selectedValues["additional"].sms?.["status"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          sms: {
                            ...selectedValues.additional.sms,
                            direction: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
              </>
            )}

            {selectedRecommendationsPieDataType && (
              <>
                <SelectContainer>
                  <SelectDropdownTitle>Nlp Range Start</SelectDropdownTitle>
                  <DateFilterContainer>
                    <TimeframeContainer>
                      <Filter
                        selectContainerStyles={{ width: "227px" }}
                        options={timeframeOptions}
                        filterValue={filterValueNlpRangeStart}
                        setFilterValue={setFilterValueNlpRangeStart}
                        startDate={startDateNlpRangeStart}
                        setStartDate={setStartDateNlpRangeStart}
                        endDate={endDateNlpRangeStart}
                        setEndDate={setEndDateNlpRangeStart}
                        setSelectedDate={setSelectedDateNlpRangeStart}
                        type="radio"
                        isDisabled={isLoading}
                        hasPortal={true}
                        displayFormat="YYYY-MM-DD"
                        isClearable={true}
                      />
                    </TimeframeContainer>
                  </DateFilterContainer>
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Nlp Range End</SelectDropdownTitle>
                  <DateFilterContainer>
                    <TimeframeContainer>
                      <Filter
                        selectContainerStyles={{ width: "227px" }}
                        options={timeframeOptions}
                        filterValue={filterValueNlpRangeEnd}
                        setFilterValue={setFilterValueNlpRangeEnd}
                        startDate={startDateNlpRangeEnd}
                        setStartDate={setStartDateNlpRangeEnd}
                        endDate={endDateNlpRangeEnd}
                        setEndDate={setEndDateNlpRangeEnd}
                        setSelectedDate={setSelectedDateNlpRangeEnd}
                        type="radio"
                        isDisabled={isLoading}
                        hasPortal={true}
                        displayFormat="YYYY-MM-DD"
                        isClearable={true}
                      />
                    </TimeframeContainer>
                  </DateFilterContainer>
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Exam Range</SelectDropdownTitle>
                  <DateFilterContainer>
                    <TimeframeContainer>
                      <Filter
                        selectContainerStyles={{ width: "227px" }}
                        options={timeframeOptions}
                        filterValue={filterValueExamDate}
                        setFilterValue={setFilterValueExamDate}
                        startDate={startDateExamDate}
                        setStartDate={setStartDateExamDate}
                        endDate={endDateExamDate}
                        setEndDate={setEndDateExamDate}
                        setSelectedDate={setSelectedDateExamDate}
                        type="radio"
                        isDisabled={isLoading}
                        hasPortal={true}
                        displayFormat="YYYY-MM-DD"
                        isClearable={true}
                      />
                    </TimeframeContainer>
                  </DateFilterContainer>
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Timeframe Status</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={recommendationTimeframeStatusOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]?.["recommendations-pie"]?.[
                        "timeframe_status_extracted"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          "recommendations-pie": {
                            ...selectedValues.additional["recommendations-pie"],
                            timeframe_status_extracted: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Modality</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "modality"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          "recommendations-pie": {
                            ...selectedValues.additional["recommendations-pie"],
                            modality: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Modality Type</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityTypeOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "modality_type"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            modality_type: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Anatomy</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={anatomyOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "anatomy"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            anatomy: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Anatomy Type</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={anatomyTypeOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "anatomy_type"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            anatomy_type: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Report Modality</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityReportOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "report__modality"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            report__modality: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>
                    Report Modality Type
                  </SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={reportModalityTypeOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "report__modality_type"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            report__modality_type: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Radiologist</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={radiologistOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "report__radiologist__id"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            report__radiologist__id: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Location</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={locationOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onMenuScrollToBottom={loadMoreLocationOptions}
                    isLoading={loadingMoreLocations}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "location_address"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            location_address: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Age</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={ageOptions}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "age"
                      ]
                    }
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            age: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Contains Note</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={noteOptions}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "has_note"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            has_note: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Message Sent</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={messageSentOptions}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "message_sent"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            message_sent: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Report Exam Code</SelectDropdownTitle>

                  <input
                    style={{
                      resize: "none",
                      border: validationError
                        ? "1px solid #B40000"
                        : "1px solid #D2D3D7",
                      borderRadius: "4px",
                      padding: "10px",
                      height: "43.5px",
                      width: "227px",
                    }}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "exam_code"
                      ]?.length > 0
                        ? selectedValues["additional"]["recommendations-pie"]?.[
                            "exam_code"
                          ]
                            ?.map((itm) => itm.value)
                            ?.join(",")
                        : ""
                    }
                    onChange={handleInputChange}
                    placeholder="Enter 5 digit code"
                  />

                  {selectedValues["additional"]["recommendations-pie"]?.[
                    "exam_code"
                  ]?.length > 0 &&
                    validationError && (
                      <div
                        style={{
                          color: "#B40000",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: validationError,
                        }}
                      ></div>
                    )}
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Exam Code Type</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={examTypeOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "exam_code_type"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            exam_code_type: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
              </>
            )}
            {selectedNotesDataType && (
              <>
                <SelectContainer>
                  <SelectDropdownTitle>Group By</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={notesGroupOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={selectedValues?.["group_by"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        group_by: value,
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
              </>
            )}
            {selectedRecoHistoryDataType && (
              <>
                <SelectContainer>
                  <SelectDropdownTitle>Group By</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={recoHistoryGroupOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={selectedValues?.["group_by"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        group_by: value,
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Users</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={usersOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]?.["reco-history"]?.["user"]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          "reco-history": {
                            ...selectedValues.additional?.["reco-history"],
                            user: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                    isClearable={true}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>
                    Status Override From
                  </SelectDropdownTitle>
                  <Select
                    components={{
                      IndicatorsContainer,
                      Option: MultiOption,
                    }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={recoStatusOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]?.["reco-history"]?.[
                        "status_override_from"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["reco-history"]: {
                            ...selectedValues.additional?.["reco-history"],
                            status_override_from: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Status Override To</SelectDropdownTitle>
                  <Select
                    components={{
                      IndicatorsContainer,
                      Option: MultiOption,
                    }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={recoStatusOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]?.["reco-history"]?.[
                        "status_override_to"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["reco-history"]: {
                            ...selectedValues.additional?.["reco-history"],
                            status_override_to: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
              </>
            )}
            {selectedPrescriptionsDataType && (
              <>
                <SelectContainer>
                  <SelectDropdownTitle>Group By</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={prescriptionsGroupOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={selectedValues?.["group_by"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        group_by: value,
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>


                <SelectContainer>
                  <SelectDropdownTitle>Modality</SelectDropdownTitle>
                  <Select
                    components={{
                      IndicatorsContainer,
                      Option: MultiOption,
                    }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityPrescriptionsOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]?.["prescriptions"]?.[
                        "modality"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["prescriptions"]: {
                            ...selectedValues.additional?.["prescriptions"],
                            modality: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
              </>
            )}

            {selectedAppointmentsDataType && (
              <>
                <SelectContainer>
                  <SelectDropdownTitle>Group By</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={appointmentsGroupOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={selectedValues?.["group_by"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        group_by: value,
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>


                <SelectContainer>
                  <SelectDropdownTitle>Modality</SelectDropdownTitle>
                  <Select
                    components={{
                      IndicatorsContainer,
                      Option: MultiOption,
                    }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityAppointmentsOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]?.["appointments"]?.[
                        "modality"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["appointments"]: {
                            ...selectedValues.additional?.["appointments"],
                            modality: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
              </>
            )}

            <div>
              <SelectDropdownTitle>Chart Title *</SelectDropdownTitle>
              <Input
                disabled={isLoading}
                type="text"
                value={title}
                minLength={5}
                onChange={(e) => setTitle(e.target.value)}
                style={{
                  border: "1px solid #C2CCD9",
                  width: "227px",
                  height: "39px",
                  borderRadius: "4px",
                  background: isLoading ? "#eeeeee" : "#ffffff",
                }}
              />
            </div>
          </form>
        ) : selectedCompareFlowType ? (
          <form onSubmit={handleFormSubmit}>
            <SelectContainer>
              <SelectDropdownTitle>Data Type *</SelectDropdownTitle>
              <Select
                components={{ IndicatorsContainer, Option: MultiOption }}
                className="basic-multi-select"
                isDisabled={isLoading}
                classNamePrefix="select"
                options={dataTypeComparisonOptions}
                value={selectedValues["data_type"]}
                onChange={(value) => {
                  setSelectedValues({
                    ...selectedValues,
                    data_type: value,
                    group_by: {
                      recommendations: [
                        {
                          value: "status",
                          label: "Status",
                          category: "status",
                        },
                      ],
                      notes: [
                        { value: "task", label: "Task", category: "status" },
                      ],
                      sms: [
                        {
                          value: "status",
                          label: "Status",
                          category: "status",
                        },
                      ],
                      emails: [
                        {
                          value: "status",
                          label: "Status",
                          category: "status",
                        },
                      ],
                      "reco-history": [
                        {
                          value: "user__email",
                          label: "User",
                          category: "status",
                        },
                      ],
                      prescriptions: [
                        {
                          value: "modality",
                          label: "Modality",
                          category: "status",
                        },
                      ],
                      appointments: [
                        {
                          value: "modality",
                          label: "Modality",
                          category: "status",
                        },
                      ],
                    },
                  });
                }}
                styles={styles}
                isMulti
              />
            </SelectContainer>

            <SelectContainer>
              <SelectDropdownTitle>Chart Type *</SelectDropdownTitle>
              <Select
                isDisabled={isLoading || !selectedValues["data_type"]}
                components={{ IndicatorsContainer, Option }}
                className="basic-multi-select"
                classNamePrefix="select"
                options={timeseriesChartTypeOptions}
                value={selectedValues["chart_type"]}
                onChange={(value) =>
                  setSelectedValues({
                    ...selectedValues,
                    chart_type: value,
                  })
                }
                styles={styles}
              />
            </SelectContainer>
            {(selectedLineChart || selectedBarChart) && (
              <SelectContainer>
                <SelectDropdownTitle>Frequency *</SelectDropdownTitle>
                <Select
                  isDisabled={isLoading}
                  components={{ IndicatorsContainer, Option }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={frequencyOptions}
                  value={selectedValues["frequency"]}
                  onChange={(value) => {
                    setSelectedValues({
                      ...selectedValues,
                      frequency: "",
                    });
                    setFilterValue();
                    setSelectedDate();
                    setStartDate();
                    setEndDate();
                    setTimeout(() => {
                      setSelectedValues({
                        ...selectedValues,
                        frequency: value,
                      });
                    }, 0);
                  }}
                  styles={styles}
                />
              </SelectContainer>
            )}
            {(selectedLineChart || selectedBarChart) &&
              selectedValues["frequency"] && (
                <SelectContainer>
                  <SelectDropdownTitle>Timeframe *</SelectDropdownTitle>
                  <DateFilterContainer>
                    <TimeframeContainer>
                      <Filter
                        selectContainerStyles={{ width: "227px" }}
                        options={
                          selectedValues["frequency"]?.value === "days"
                            ? timeframeOptionsPrecedingDay
                            : timeframeOptions
                        }
                        filterValue={filterValue}
                        setFilterValue={setFilterValue}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setSelectedDate={setSelectedDate}
                        type="radio"
                        isDisabled={isLoading || !selectedValues["frequency"]}
                        hasPortal={true}
                        displayFormat="YYYY-MM-DD"
                      />
                    </TimeframeContainer>
                  </DateFilterContainer>
                </SelectContainer>
              )}

            <div>
              <SelectDropdownTitle>Chart Title *</SelectDropdownTitle>
              <Input
                disabled={isLoading}
                type="text"
                value={title}
                minLength={5}
                onChange={(e) => setTitle(e.target.value)}
                style={{
                  border: "1px solid #C2CCD9",
                  width: "227px",
                  height: "39px",
                  borderRadius: "4px",
                  background: isLoading ? "#eeeeee" : "#ffffff",
                }}
              />
            </div>

            <label
              style={{
                fontWeight: 400,
                fontSize: "12px",
                color: "#6D6E6E",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <input
                type="checkbox"
                checked={selectedValues["compare_preceeding"]}
                onChange={() => {
                  setSelectedValues({
                    ...selectedValues,
                    compare_preceeding: !selectedValues["compare_preceeding"],
                  });
                }}
              />
              Compare to preceding period
            </label>

            <div
              style={{ border: "1px solid #D2D3D7", marginBottom: "30px" }}
            />
            {Array.isArray(selectedValues["data_type"]) &&
              selectedValues["data_type"].map((itm, index) => (
                <div key={index} className={classes.root}>
                  <Accordion classes={{ root: classes.accordionRoot }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <p
                        style={{
                          color: "#0C2945",
                          fontFamily: "Roboto Condensed",
                          fontSize: "20px",
                          fontWeight: 500,
                          marginBottom: 0,
                        }}
                      >
                        {capitalize(itm.value)}
                      </p>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <SelectContainer>
                        <SelectDropdownTitle>Data Subset *</SelectDropdownTitle>
                        <Select
                          components={{ IndicatorsContainer, Option }}
                          className="basic-multi-select"
                          isDisabled={isLoading || !selectedValues["data_type"]}
                          classNamePrefix="select"
                          options={
                            itm.value === "prescriptions"
                              ? dataSubsetPrescriptionsOptions
                              : itm.value === "appointments"
                              ? dataSubsetAppointmentsOptions
                              : itm.value === "reco-history"
                              ? dataSubsetRecoHistoryOptions
                              : itm.value === "recommendations"
                              ? dataSubsetRecommendationsOptions
                              : itm.value === "reports"
                              ? dataSubsetReportsOptions
                              : itm.value === "emails"
                              ? dataSubsetEmailsOptions
                              : itm.value === "sms"
                              ? dataSubsetSmsOptions
                              : itm.value === "radiologists"
                              ? dataSubsetRadiologistsOptions
                              : itm.value === "recommendations-pie"
                              ? dataSubsetRecommendationsPieOptions
                              : itm.value === "patients"
                              ? dataSubsetPatientsOptions
                              : itm.value === "notes"
                              ? dataSubsetNotesOptions
                              : dataSubsetReferrersOptions
                          }
                          value={selectedValues["data_subset"]?.[itm.value]}
                          onChange={(value) => {
                            setSelectedValues({
                              ...selectedValues,
                              data_subset: {
                                ...selectedValues.data_subset,
                                [itm.value]: value,
                              },
                              additional: {
                                ...selectedValues.additional,
                                recommendations: {
                                  ...selectedValues.additional.recommendations,
                                  initial_exam_date_gte: "",
                                  initial_exam_date_lte: "",
                                  nlp_range_start_gte: "",
                                  nlp_range_start_lte: "",
                                  nlp_range_end_gte: "",
                                  nlp_range_end_lte: "",
                                },
                              },
                            });
                            handleResetDates();
                          }}
                          styles={styles}
                        />
                      </SelectContainer>

                      {itm?.value === "reports" && (
                        <>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Message Sent
                            </SelectDropdownTitle>
                            <Select
                              components={{ IndicatorsContainer, Option }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={messageSentOptions}
                              value={
                                selectedValues["additional"].reports?.[
                                  "message_sent"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    reports: {
                                      ...selectedValues.additional.reports,
                                      message_sent: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>Modality</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={modalityReportOptions}
                              value={
                                selectedValues["additional"].reports?.[
                                  "modality"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    reports: {
                                      ...selectedValues.additional.reports,
                                      modality: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              isMulti
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Modality Type
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: Option,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={modalityTypeOptions}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].reports?.[
                                  "modality_type"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    reports: {
                                      ...selectedValues.additional.reports,
                                      modality_type: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Radiologist
                            </SelectDropdownTitle>
                            <Select
                              components={{ IndicatorsContainer, Option }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={radiologistOptions}
                              value={
                                selectedValues["additional"].reports?.[
                                  "radiologist"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    reports: {
                                      ...selectedValues.additional.reports,
                                      radiologist: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                        </>
                      )}

                      {itm?.value === "recommendations" && (
                        <>
                          <SelectContainer>
                            <SelectDropdownTitle>Group By</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={
                                isLoading || !selectedValues["group_by"]
                              }
                              classNamePrefix="select"
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              options={recommendationsGroupOptions}
                              value={selectedValues["group_by"]?.[itm.value]}
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  group_by: {
                                    ...selectedValues.group_by,
                                    [itm.value]: value,
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Recommendation Status
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={recoStatusOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "status"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      status: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>

                          {selectedValues["data_subset"]?.[itm.value]?.value &&
                            selectedValues["data_subset"]?.[itm.value]
                              ?.value !== "range_start" && (
                              <SelectContainer>
                                <SelectDropdownTitle>
                                  Nlp Range Start
                                </SelectDropdownTitle>
                                <TimeframeContainer>
                                  <Filter
                                    selectContainerStyles={{ width: "227px" }}
                                    options={timeframeOptions}
                                    filterValue={filterValueNlpRangeStart}
                                    setFilterValue={setFilterValueNlpRangeStart}
                                    startDate={startDateNlpRangeStart}
                                    setStartDate={setStartDateNlpRangeStart}
                                    endDate={endDateNlpRangeStart}
                                    setEndDate={setEndDateNlpRangeStart}
                                    setSelectedDate={
                                      setSelectedDateNlpRangeStart
                                    }
                                    type="radio"
                                    isDisabled={isLoading}
                                    hasPortal={true}
                                    displayFormat="YYYY-MM-DD"
                                    isClearable={true}
                                  />
                                </TimeframeContainer>
                              </SelectContainer>
                            )}

                          {selectedValues["data_subset"]?.[itm.value]?.value &&
                            selectedValues["data_subset"]?.[itm.value]
                              ?.value !== "range_end" && (
                              <SelectContainer>
                                <SelectDropdownTitle>
                                  Nlp Range End
                                </SelectDropdownTitle>
                                <TimeframeContainer>
                                  <Filter
                                    selectContainerStyles={{ width: "227px" }}
                                    options={timeframeOptions}
                                    filterValue={filterValueNlpRangeEnd}
                                    setFilterValue={setFilterValueNlpRangeEnd}
                                    startDate={startDateNlpRangeEnd}
                                    setStartDate={setStartDateNlpRangeEnd}
                                    endDate={endDateNlpRangeEnd}
                                    setEndDate={setEndDateNlpRangeEnd}
                                    setSelectedDate={setSelectedDateNlpRangeEnd}
                                    type="radio"
                                    isDisabled={isLoading}
                                    hasPortal={true}
                                    displayFormat="YYYY-MM-DD"
                                    isClearable={true}
                                  />
                                </TimeframeContainer>
                              </SelectContainer>
                            )}
                          {selectedValues["data_subset"]?.[itm.value]?.value &&
                            selectedValues["data_subset"]?.[itm.value]
                              ?.value !== "initial_exam_date" && (
                              <SelectContainer>
                                <SelectDropdownTitle>
                                  Exam Range
                                </SelectDropdownTitle>
                                <TimeframeContainer>
                                  <Filter
                                    selectContainerStyles={{ width: "227px" }}
                                    options={timeframeOptions}
                                    filterValue={filterValueExamDate}
                                    setFilterValue={setFilterValueExamDate}
                                    startDate={startDateExamDate}
                                    setStartDate={setStartDateExamDate}
                                    endDate={endDateExamDate}
                                    setEndDate={setEndDateExamDate}
                                    setSelectedDate={setSelectedDateExamDate}
                                    type="radio"
                                    isDisabled={isLoading}
                                    hasPortal={true}
                                    displayFormat="YYYY-MM-DD"
                                    isClearable={true}
                                  />
                                </TimeframeContainer>
                              </SelectContainer>
                            )}

                          <SelectContainer>
                            <SelectDropdownTitle>
                              Timeframe Status
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={recommendationTimeframeStatusOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "timeframe_status_extracted"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      timeframe_status_extracted: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>Modality</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={modalityOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "modality"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      modality: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Modality Type
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: Option,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={modalityTypeOptions}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "modality_type"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      modality_type: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>

                          <SelectContainer>
                            <SelectDropdownTitle>Anatomy</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={anatomyOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "anatomy"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      anatomy: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Anatomy Type
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: Option,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={anatomyTypeOptions}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "anatomy_type"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      anatomy_type: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>

                          <SelectContainer>
                            <SelectDropdownTitle>
                              Report Modality
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={modalityReportOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "report__modality"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      report__modality: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Report Modality Type
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: Option,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={reportModalityTypeOptions}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "report__modality_type"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      report__modality_type: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>

                          <SelectContainer>
                            <SelectDropdownTitle>
                              Radiologist
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={radiologistOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "report__radiologist__id"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      report__radiologist__id: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>Location</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={locationOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              onMenuScrollToBottom={loadMoreLocationOptions}
                              isLoading={loadingMoreLocations}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "location_address"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      location_address: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>Age</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={ageOptions}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "age"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      age: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Contains Note
                            </SelectDropdownTitle>
                            <Select
                              components={{ IndicatorsContainer, Option }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={noteOptions}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "has_note"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      has_note: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Message Sent
                            </SelectDropdownTitle>
                            <Select
                              components={{ IndicatorsContainer, Option }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={messageSentOptions}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "message_sent"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      message_sent: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>

                          <SelectContainer>
                            <SelectDropdownTitle>
                              Report Exam Code
                            </SelectDropdownTitle>

                            <input
                              style={{
                                resize: "none",
                                border: validationError
                                  ? "1px solid #B40000"
                                  : "1px solid #D2D3D7",
                                borderRadius: "4px",
                                padding: "10px",
                                height: "43.5px",
                                width: "227px",
                              }}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "exam_code"
                                ]?.length > 0
                                  ? selectedValues[
                                      "additional"
                                    ].recommendations?.["exam_code"]
                                      ?.map((itm) => itm.value)
                                      ?.join(",")
                                  : ""
                              }
                              onChange={handleInputChange}
                              placeholder="Enter 5 digit code"
                            />

                            {selectedValues["additional"].recommendations?.[
                              "exam_code"
                            ]?.length > 0 &&
                              validationError && (
                                <div
                                  style={{
                                    color: "#B40000",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: validationError,
                                  }}
                                ></div>
                              )}
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Exam Code Type
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: Option,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={examTypeOptions}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "exam_code_type"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      exam_code_type: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                        </>
                      )}

                      {itm?.value === "emails" && (
                        <>
                          <SelectContainer>
                            <SelectDropdownTitle>Group By</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={
                                isLoading || !selectedValues["group_by"]
                              }
                              classNamePrefix="select"
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              options={emailGroupOptions}
                              value={selectedValues["group_by"]?.[itm.value]}
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  group_by: {
                                    ...selectedValues.group_by,
                                    [itm.value]: value,
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Email Status
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={emailsStatusOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].emails?.["status"]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    emails: {
                                      ...selectedValues.additional.emails,
                                      status: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                        </>
                      )}

                      {itm?.value === "sms" && (
                        <>
                          <SelectContainer>
                            <SelectDropdownTitle>Group By</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={
                                isLoading || !selectedValues["group_by"]
                              }
                              classNamePrefix="select"
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              options={smsGroupOptions}
                              value={selectedValues["group_by"]?.[itm.value]}
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  group_by: {
                                    ...selectedValues.group_by,
                                    [itm.value]: value,
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Sms Status
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={smsStatusOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].sms?.["status"]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    sms: {
                                      ...selectedValues.additional.sms,
                                      status: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                          {/* <SelectContainer>
                  <SelectDropdownTitle>Sms Direction</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={smsDirectionOptions}
                    value={selectedValues["additional"].sms?.["status"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          sms: {
                            ...selectedValues.additional.sms,
                            direction: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer> */}
                        </>
                      )}
                      {itm?.value === "notes" && (
                        <>
                          <SelectContainer>
                            <SelectDropdownTitle>Group By</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={
                                isLoading || !selectedValues["group_by"]
                              }
                              classNamePrefix="select"
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              options={notesGroupOptions}
                              value={selectedValues["group_by"]?.[itm.value]}
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  group_by: {
                                    ...selectedValues.group_by,
                                    [itm.value]: value,
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Notes Task
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={taskOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].notes?.["task"]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    notes: {
                                      ...selectedValues.additional.notes,
                                      task: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                          {/* <SelectContainer>
                  <SelectDropdownTitle>Sms Direction</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={smsDirectionOptions}
                    value={selectedValues["additional"].sms?.["status"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          sms: {
                            ...selectedValues.additional.sms,
                            direction: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer> */}
                        </>
                      )}
                      {itm?.value === "reco-history" && (
                        <>
                          <SelectContainer>
                            <SelectDropdownTitle>Group By</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={
                                isLoading || !selectedValues["group_by"]
                              }
                              classNamePrefix="select"
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              options={recoHistoryGroupOptions}
                              value={selectedValues["group_by"]?.[itm.value]}
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  group_by: {
                                    ...selectedValues.group_by,
                                    [itm.value]: value,
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>

                          <SelectContainer>
                            <SelectDropdownTitle>Users</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={usersOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"]?.[
                                  "reco-history"
                                ]?.["user"]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    "reco-history": {
                                      ...selectedValues.additional?.[
                                        "reco-history"
                                      ],
                                      user: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                              isClearable={true}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Status Override From
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={recoStatusOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"]?.[
                                  "reco-history"
                                ]?.["status_override_from"]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    ["reco-history"]: {
                                      ...selectedValues.additional?.[
                                        "reco-history"
                                      ],
                                      status_override_from: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Status Override To
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={recoStatusOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"]?.[
                                  "reco-history"
                                ]?.["status_override_to"]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    ["reco-history"]: {
                                      ...selectedValues.additional?.[
                                        "reco-history"
                                      ],
                                      status_override_to: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                        </>
                      )}
                      {itm?.value === "prescriptions" && (
                        <>
                          <SelectContainer>
                            <SelectDropdownTitle>Group By</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={
                                isLoading || !selectedValues["group_by"]
                              }
                              classNamePrefix="select"
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              options={prescriptionsGroupOptions}
                              value={selectedValues["group_by"]?.[itm.value]}
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  group_by: {
                                    ...selectedValues.group_by,
                                    [itm.value]: value,
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>


                          <SelectContainer>
                            <SelectDropdownTitle>Modality</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={modalityPrescriptionsOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"]?.[
                                  "prescriptions"
                                ]?.["modality"]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    ["prescriptions"]: {
                                      ...selectedValues.additional?.[
                                        "prescriptions"
                                      ],
                                      modality: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                        </>
                      )}
                      {itm?.value === "appointments" && (
                        <>
                          <SelectContainer>
                            <SelectDropdownTitle>Group By</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={
                                isLoading || !selectedValues["group_by"]
                              }
                              classNamePrefix="select"
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              options={appointmentsGroupOptions}
                              value={selectedValues["group_by"]?.[itm.value]}
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  group_by: {
                                    ...selectedValues.group_by,
                                    [itm.value]: value,
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>

                          <SelectContainer>
                            <SelectDropdownTitle>Modality</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={modalityAppointmentsOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"]?.[
                                  "appointments"
                                ]?.["modality"]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    ["appointments"]: {
                                      ...selectedValues.additional?.[
                                        "appointments"
                                      ],
                                      modality: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
          </form>
        ) : null}
      </SidebarContainer>
      <div style={{ width: "90%", flex: 1, padding: "30px" }}>
        <div
          style={{
            height: "511px",
            border: "1px solid #DFDFDF",
            background: "white",
            marginBottom: "30px",
            borderRadius: "8px",
            padding: "56px",
            overflow: "scroll",
          }}
        >
          {loader ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {" "}
              <Spinner animation="border" role="status" size="sm" />
            </div>
          ) : !selectedValues["chart_type"] ? null : selectedBarChart ? (
            selectedCompareFlowType ? (
              <>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={finalData}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    {dynamicStatuses.map((status) => (
                      <Bar
                        key={status}
                        dataKey={status}
                        fill={statusColors[status]}
                        barSize={40}
                        activeBar={<Rectangle fill="pink" stroke="blue" />}
                      />
                    ))}
                  </BarChart>
                </ResponsiveContainer>

                {selectedValues?.["flow_type"]?.value === "compare" &&
                  data?.length > 0 &&
                  Array.isArray(selectedValues?.["data_type"]) &&
                  !isLoading &&
                  Object.keys(groupedStatuses) && (
                    <div style={{ marginBottom: "20px" }}>
                      <table>
                        <thead>
                          <tr>
                            <th>Category</th>
                            <th>Legend</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(groupedStatuses).map((category) => (
                            <tr key={category}>
                              <td>{category}</td>
                              <td>
                                {groupedStatuses[category]
                                  .filter((itm) => itm.status)
                                  .map((statusInfo, index) => (
                                    <span
                                      key={index}
                                      style={{
                                        display: "inline-block",
                                        margin: "4px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          backgroundColor: statusInfo.color,
                                          width: "12px",
                                          height: "12px",
                                          display: "inline-block",
                                          marginRight: "6px",
                                        }}
                                      />
                                      {statusInfo.status}
                                    </span>
                                  ))}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
              </>
            ) : noGroupBySelected ? (
              <ResponsiveContainer width="100%" height="100%">
                {selectedValues["data_subset"]?.value === "charge" ? (
                  <BarChart
                    width={500}
                    height={300}
                    data={data?.map((item) => ({
                      date: item.date,
                      charge_amount_sum:
                        item.data[0]?.imagine_metadata__charge_amount__sum,
                    }))}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip
                      formatter={(value) =>
                        `$${value?.toLocaleString("en-US")}`
                      }
                    />
                    <Legend />
                    <Bar
                      dataKey="charge_amount_sum"
                      fill="#8884d8"
                      activeBar={<Rectangle fill="pink" stroke="blue" />}
                      barSize={40}
                    />
                  </BarChart>
                ) : (
                  <BarChart
                    width={500}
                    height={300}
                    data={data?.map((item) => ({
                      date: item.date,
                      id__count: item.data[0].id__count,
                    }))}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="id__count"
                      fill="#8884d8"
                      activeBar={<Rectangle fill="pink" stroke="blue" />}
                      barSize={40}
                    />
                  </BarChart>
                )}
              </ResponsiveContainer>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={flattenData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  {uniqueStatuses.map((status, index) => (
                    <Bar
                      key={index}
                      dataKey={status}
                      name={status}
                      barSize={40}
                      fill={
                        selectedAppointmentsDataType
                          ? randomColor(index)
                          : selectedPrescriptionsDataType
                          ? randomColor(index)
                          : selectedRecoHistoryDataType
                          ? randomColor(index)
                          : selectedRecommendationsDataType &&
                            selectedValues["group_by"]
                              ?.map((itm) => itm.value)
                              .join(",") === "status"
                          ? recommendationStatusColors(status)
                          : selectedRecommendationsDataType
                          ? randomColor(index)
                          : selectedNotesDataType &&
                            selectedValues["group_by"]
                              ?.map((itm) => itm.value)
                              .join(",") === "task"
                          ? taskColors(status)
                          : selectedNotesDataType
                          ? randomColor(index)
                          : selectedSmsDataType
                          ? smsColors(status)
                          : emailColors(status)
                      }
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            )
          ) : selectedLineChart ? (
            selectedCompareFlowType ? (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={500}
                  height={300}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" allowDuplicatedCategory={false} />
                  <YAxis />
                  <Tooltip />
                  {dynamicStatuses.map((status) => (
                    <Line
                      dataKey={status}
                      stroke={statusColors[status]}
                      data={finalData}
                      strokeDasharray="10 10"
                      dot={false}
                      type="monotone"
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            ) : noGroupBySelected ? (
              <ResponsiveContainer width="100%" height="100%">
                {selectedValues["data_subset"]?.value === "charge" ? (
                  <LineChart
                    width={500}
                    height={300}
                    data={data.map((item) => ({
                      date: item.date,
                      charge_amount_sum:
                        item.data[0].imagine_metadata__charge_amount__sum,
                    }))}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip
                      formatter={(value) =>
                        `$${value?.toLocaleString("en-US")}`
                      }
                    />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="charge_amount_sum"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                ) : (
                  <LineChart
                    width={500}
                    height={300}
                    data={data.map((item) => ({
                      date: item.date,
                      id__count: item.data[0].id__count,
                    }))}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="id__count"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                )}
              </ResponsiveContainer>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={500}
                  height={300}
                  data={flattenData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />

                  {uniqueStatuses.map((status, index) => (
                    <Line
                      key={index}
                      dataKey={status}
                      name={status}
                      stroke={
                        selectedAppointmentsDataType
                          ? randomColor(index)
                          : selectedPrescriptionsDataType
                          ? randomColor(index)
                          : selectedRecoHistoryDataType
                          ? randomColor[index]
                          : selectedRecommendationsDataType &&
                            selectedValues["group_by"]
                              ?.map((itm) => itm.value)
                              .join(",") === "status"
                          ? recommendationStatusColors(status)
                          : selectedRecommendationsDataType
                          ? randomColor(index)
                          : selectedNotesDataType &&
                            selectedValues["group_by"]
                              ?.map((itm) => itm.value)
                              .join(",") === "task"
                          ? taskColors(status)
                          : selectedNotesDataType
                          ? randomColor(index)
                          : selectedSmsDataType
                          ? smsColors(status)
                          : emailColors(status)
                      }
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            )
          ) : selectedPieChart ? (
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={600} height={600}>
                <Pie
                  data={data}
                  cx="35%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={180}
                  fill="#8884d8"
                  dataKey="id__count"
                >
                  {data?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Legend
                  iconSize={10}
                  width={120}
                  height={140}
                  layout="vertical"
                  verticalAlign="middle"
                  wrapperStyle={legendWrapperStyle}
                  iconType={"circle"}
                  content={renderLegend}
                />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={600} height={600}>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  dataKey="id__count"
                  startAngle={180}
                  endAngle={0}
                  data={data}
                  cy={240}
                  cx={"35%"}
                  outerRadius={220}
                  innerRadius={50}
                  fill="#8884d8"
                  label={renderCustomizedLabel}
                  labelLine={false}
                  onMouseEnter={onPieEnter}
                  animationDuration={0}
                  strokeWidth={0}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}-${activeIndex}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Legend
                  iconSize={10}
                  width={120}
                  height={140}
                  layout="vertical"
                  verticalAlign="middle"
                  wrapperStyle={legendWrapperStyle}
                  iconType={"circle"}
                  content={renderLegend}
                />
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            style={{
              background:
                selectedLineChart || selectedBarChart
                  ? selectedCompareFlowType
                    ? !title ||
                      !selectedValues["chart_type"] ||
                      !filterValue ||
                      !selectedValues["data_type"] ||
                      !selectedValues["frequency"] ||
                      validationError
                      ? "#eeeeee"
                      : "#00AEEF"
                    : !title ||
                      !selectedValues["chart_type"] ||
                      !filterValue ||
                      !selectedValues["data_type"] ||
                      !selectedValues["frequency"] ||
                      validationError
                    ? "#eeeeee"
                    : "#00AEEF"
                  : !title ||
                    !selectedValues["chart_type"] ||
                    !selectedValues["data_type"] ||
                    validationError
                  ? "#eeeeee"
                  : "#00AEEF",

              color: "white",
              borderRadius: "4px",
              border: "none",
              height: "38px",
              fontWeight: 600,
              fontSize: "14px",
              width: "283px",
              cursor: isLoading ? "not-allowed" : "pointer",
            }}
            disabled={
              selectedLineChart || selectedBarChart
                ? selectedCompareFlowType
                  ? !title ||
                    !selectedValues["chart_type"] ||
                    !selectedValues["data_type"] ||
                    !selectedValues["frequency"] ||
                    !filterValue ||
                    validationError ||
                    isLoading
                  : !title ||
                    !selectedValues["chart_type"] ||
                    !selectedValues["data_type"] ||
                    !selectedValues["frequency"] ||
                    !filterValue ||
                    validationError ||
                    isLoading
                : !title ||
                  !selectedValues["chart_type"] ||
                  !selectedValues["data_type"] ||
                  validationError ||
                  isLoading
            }
            onClick={handleFormSubmit}
          >
            {isLoading ? "Saving..." : id ? "Save & Edit" : "Save & Create"}
          </button>
        </div>
      </div>
    </Container>
  );
};

export default CustomChartsCreate;
