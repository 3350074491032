import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { isEmpty, lowerCase } from 'lodash';
import { connect, useSelector, useDispatch } from 'react-redux';
import { PreviousPageLink } from '../../Dashboard/PatientImport/index';
import { withRouter } from 'react-router-dom';
import * as authActions from '../../../../actions/auth/actions';
import {
  createCampaigns,
  createSegment,
  updateCampaigns,
  fetchCampaign,
  setAdditionalSegmentConditionsField,
  setSegmentField,
  setTimeTriggerField,
} from '../../../../actions/radminUploads';
import Flex from '../../../../components/_styles/Flex';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/arrow_left.svg';
import Step from '../../Dashboard/PatientImport/Step';
import Setup from './Setup';
import Triggers from './Triggers';
import CampaignEmailAndMessage from './CampaignEmailAndMessage';
import PreviewEmail from './PreviewEmail';
import PreviewSMS from './PreviewSMS';
import CampaignType from './CampaignType';
import { segmentConditionOptions } from './ActionBasedTrigger';
import PreviewCampaignOverview from './PreviewCampaignOverview';

const Container = styled.div`
  background: #ffffff;
  box-sizing: border-box;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 20px;
`;

const MainContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  border: 1px solid #c2ccd9;
  border-radius: 8px;
`;
export const FormItem = styled.div`
  width: 100%;
`;

const CardTitle = styled.p`
  font-family: Roboto Condensed;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #0c2945;
  margin: 0px;
  margin-left: 2%;
`;

const StepsContainer = styled.div``;

const CardHeader = styled.div`
  display: flex;
  background: #ffffff;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-top: 20px;
  align-items: center;
`;
const HeaderSection = styled.div`
  border-bottom: 3px solid #00AEEF;
  height: 45px;
`;

const NextButtonContainer = styled.div`
  display: flex;
  justify-content: right;
`;

const BackButtonContainer = styled.div`
  display: flex;
  padding-top: 4%;
  padding-bottom: 10px;
  width: 60%;
  margin: 0 auto;
  align-items: center;
`;

const SaveButton = styled.button`
  padding: 15px 30px;
  width: 200px;
  margin: 30px 5px 5px 5px;
  background: #00AEEF;
  color: white;
  border-radius: 5px;
  border: none;
`;

const PreviewButton = styled.div`
  font-family: Roboto Condensed;
  color: white;
  background-color: ${(props) => props.bgColor || '#00AEEF'};
  border: none;
  border-radius: 5px;
  font-size: 14px;
  line-height: 17.6px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  width: ${(props) => props.width || '100%'};
  margin: 0 auto;
`;

const PreviewButtonContainer = styled.div`
  display: flex;
  max-width: 60%;
  margin: 10px auto;
`;

const STEPS = [
  { order: 1, name: 'Setup' },
  { order: 2, name: 'Triggers' },
  { order: 3, name: 'Design' },
  { order: 4, name: 'Preview' },
];

function CreateCampaign(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: createdCampaignId, step: currentStep } = useParams();

  const createdCampaign = useSelector(
    (state) => state.radAdmin.campaigns.createdCampaign
  );

  const { additionalSegmentConditions, segment, timeTrigger } = useSelector(
    (state) => state.radAdmin.timeTriggerForm
  );

  const [currentStepOrder, setCurrentStepOrder] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);
  const [selectedStep, setSelectedContactSeqIndex] = useState(0);
  const [campaignTypeView, setCampaignTypeView] = useState(false);
  const [selectedActionType, setSelectedActionType] = useState('');
  const [selectedCampaignType, setSelectedCampaignType] = useState('');
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState('');
  const [preview, setPreview] = useState(false);
  const [setup, setSetup] = useState({
    campaignName: '',
    description: '',
    goal: '',
  });

  const setAdditionalSegmentConditions = (payload) => {
    dispatch(setAdditionalSegmentConditionsField(payload))
  }
  const setSegment = (payload) => {
    dispatch(setSegmentField(payload))
  }
  const setTimeTrigger = (payload) => {
    dispatch(setTimeTriggerField(payload))
  }

  useEffect(() => {
    if (
      segment.cohortTitle ||
      segment.cohortId ||
      timeTrigger.daysOffset ||
      timeTrigger.frequency ||
      timeTrigger.dateAttribute
    ) {
      setShouldBlockNavigation(true);
    }
  }, [JSON.stringify(timeTrigger), JSON.stringify(segment)]);

  // updating state for setup and triggers to update
  useEffect(() => {
    if (createdCampaign) {
      setSetup({
        ...setup,
        campaignName: createdCampaign.title,
        description: createdCampaign.description,
      });
      if (createdCampaign.offset && createdCampaign.offset_days) {
        setTimeTrigger({
          dateAttribute: createdCampaign.date_attribute,
          offset: createdCampaign.offset,
          daysOffset: createdCampaign.offset_days,
          frequency: createdCampaign.frequency,
        });
      }
    }
  }, [JSON.stringify(createdCampaign)]);

  useEffect(() => {
    if (currentStep === 'design') {
      if (history.location.search === '?type=email') {
        setSelectedSequence('email');
      } else if (history.location.search === '?type=sms') {
        setSelectedSequence('sms');
      }
    }
  }, [history.location]);

  useEffect(() => {
    let params = new URLSearchParams(history.location.search);
    let type = params.get('type');
    setSelectedCampaignType(type);
  }, [history.location]);

  useEffect(() => {
    if (currentStep === 'setup') {
      setCompletedSteps([1]);
    } else if (currentStep === 'triggers') {
      setCompletedSteps([1, 2]);
    } else if (currentStep === 'design') {
      setCompletedSteps([1, 2, 3]);
    } else if (currentStep === 'preview') {
      setCompletedSteps([1, 2, 3, 4]);
    }
  }, [history.location]);

  const previousPage = () => {
    history.goBack();
  };

  const navigateToContactSequence = () => {
    history.push({
      pathname: `/auth/radAdmin/Campaigns/${createdCampaignId}/create/contact`,
      state: { currentStepOrder, setup, selectedActionType, selectedStep },
    });
  };

  const nextHandler = (selectType) => {
    setShouldBlockNavigation(false);
    if (selectType) {
      setSelectedActionType(selectType);
      setCurrentStepOrder(2);
    }
    if (currentStep === 'setup') {
      if (isEmpty(setup.campaignName)) {
        alert('Campaign Name required!');
        return;
      } else if (isEmpty(setup.description)) {
        alert('Campaign description required!');
        return;
      }

      if (createdCampaign) {
        let dataForUpdateSetup = {
          title: setup.campaignName,
          description: setup.description,
        };
        dispatch(updateCampaigns(dataForUpdateSetup, 'setup'));
        history.push(
          `/auth/radAdmin/Campaigns/${createdCampaign.id}/create/triggers`
        );
      } else {
        let dataToSend = {
          title: setup.campaignName,
          description: setup.description,
          // trigger_type: selectType,
          active: true,
          archived: true,
          exit_when_no_longer_match: true,
          exit_when_meet_conversion: true,
          patients: [],
        };
        dispatch(createCampaigns(dataToSend, history));
      }
    } else if (currentStep === 'triggers' && !history.location.search) {
      if (!createdCampaign) {
        alert('Campaign setup is missing!');
        return;
      }

      history.push(
        `/auth/radAdmin/Campaigns/${createdCampaignId}/create/triggers?type=${selectType}`
      );
    } else if (history.location.search) {
      let { condition, cohortTitle, cohortId } = segment;
      let { dateAttribute, offset, daysOffset, frequency } = timeTrigger;
      if (
        !condition ||
        !cohortTitle ||
        !cohortId ||
        !dateAttribute ||
        !offset ||
        !daysOffset ||
        !frequency
      ) {
        alert('All fields are required!');
        return;
      }
      if (additionalSegmentConditions.some((x) => !x.cohortId)) {
        alert('Cohort is missing in segment conditions!');
        return;
      }
      if (!createdCampaign) {
        alert('Campaign setup is missing!');
        return;
      }

      let dataForTrigger = {
        title: setup.campaignName,
        date_attribute: dateAttribute,
        frequency,
        offset,
        trigger_type: selectedActionType,
        offset_days: daysOffset,
      };
      let dataForSegment = {
        campaign: createdCampaign.id,
        cohort: segment.cohortId,
        exclude: segment.condition === 'Is' ? false : true,
      };
      let additionalConditionsForSegment = additionalSegmentConditions.map(
        (segment) => {
          return {
            campaign: createdCampaign.id,
            cohort: segment.cohortId,
            exclude: segment.condition === 'Is' ? false : true,
          };
        }
      );
      let segmentCondition = [
        ...additionalConditionsForSegment,
        dataForSegment,
      ];
      dispatch(updateCampaigns(dataForTrigger, 'triggers'));
      dispatch(createSegment(segmentCondition)).then((res) => {
        if (res && res.success) {
          navigateToContactSequence();
        }
      });
    } else if (currentStep === 'preview') {
      setPreview(true);

      history.push({
        pathname: '/auth/radAdmin/Campaigns',
      });
    }
  };

  const onChange = (name, value) => {
    setSetup({ ...setup, [name]: value });
  };

  const handlePreview = () => {
    setPreview(true);
  };

  const stepsClickHandle = (step) => {
    if (step === 'Design' || step === 'Preview') return;
    history.push(
      `/auth/radAdmin/Campaigns/${
        createdCampaign?.id || createdCampaignId
      }/create/${lowerCase(step)}`
    );
  };

  const renderStep = () => {
    switch (currentStep) {
      case 'setup':
        return (
          <Setup
            campaignName={setup.campaignName}
            description={setup.description}
            goal={setup.goal}
            onChange={onChange}
            campaignTypeView={campaignTypeView}
            nextHandler={nextHandler}
          />
        );
      case 'triggers':
        return !history.location.search ? (
          <CampaignType nextHandler={nextHandler} />
        ) : (
          <Triggers
            selectedCampaignType={selectedCampaignType}
            segment={segment}
            segmentHandle={setSegment}
            timeTrigger={timeTrigger}
            timeTriggerHandle={setTimeTrigger}
            additionalSegmentConditions={additionalSegmentConditions}
            setAdditionalSegmentConditions={setAdditionalSegmentConditions}
            shouldBlockNavigation={shouldBlockNavigation}
          />
        );
      case 'design':
        return (
          <CampaignEmailAndMessage
            selectedSequence={history.location.search.split('=')[1]}
          />
        );
      case 'preview':
        return <PreviewCampaignOverview />;
      default:
        return null;
    }
  };

  const shouldShowNexButton = () => {
    if (currentStep === 'triggers') {
      return history.location.search.length > 0;
    }

    return currentStep !== 'design';
  };

  return (
    <div>
      {preview ? (
        <div>
          <BackButtonContainer>
            <span style={{ cursor: 'pointer', display: 'flex' }}>
              <ArrowLeftIcon />
            </span>
            <PreviousPageLink>Previous Page</PreviousPageLink>
          </BackButtonContainer>
          <MainContainer>
            {selectedSequence === 'sms' ? <PreviewSMS /> : <PreviewEmail />}
          </MainContainer>
        </div>
      ) : (
        <div>
          <BackButtonContainer>
            <div
              onClick={() => previousPage()}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ cursor: 'pointer', display: 'flex' }}>
                <ArrowLeftIcon />
              </span>
              <PreviousPageLink>Previous Page</PreviousPageLink>
            </div>
          </BackButtonContainer>
          <MainContainer>
            <CardHeader>
              <div style={{ width: '30%' }}>
                <HeaderSection>
                  <CardTitle>
                    {currentStep === 'setup' || currentStep === 'design'
                      ? 'Create a Campaign'
                      : currentStep === 'triggers' && !history.location.search
                      ? 'What type of Campaign'
                      : currentStep === 'triggers' &&
                        history.location.search &&
                        history.location.search.split('=')[0] === '?type'
                      ? 'Setup a Trigger'
                      : ''}
                  </CardTitle>
                </HeaderSection>
              </div>
              <StepsContainer>
                <Flex>
                  {STEPS.map((step, i) => (
                    <Step
                      key={i}
                      name={step.name}
                      isCompleted={completedSteps.includes(step.order)}
                      callBack={stepsClickHandle}
                    />
                  ))}
                </Flex>
              </StepsContainer>
            </CardHeader>
            <Container>
              {renderStep()}

              {shouldShowNexButton() && (
                <NextButtonContainer>
                  <SaveButton primary onClick={(e) => nextHandler()}>
                    {currentStep === 'preview' ? 'Publish' : 'Next'}
                  </SaveButton>
                </NextButtonContainer>
              )}
            </Container>
          </MainContainer>
          {currentStep === 'design' && selectedSequence && (
            <PreviewButtonContainer>
              <PreviewButton
                onClick={() => handlePreview()}
                width={selectedSequence === 'email' ? '49%' : '100%'}
                bgColor={selectedSequence === 'email' && '#64C67C'}
              >
                Preview {selectedSequence === 'email' && 'Message'}
              </PreviewButton>
              {selectedSequence === 'email' && (
                <PreviewButton
                  onClick={() => navigateToContactSequence()}
                  width="49%"
                >
                  Preview Campaign
                </PreviewButton>
              )}
            </PreviewButtonContainer>
          )}
        </div>
      )}
    </div>
  );
}

export default withRouter(connect(null, authActions)(CreateCampaign));
