import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { media } from '../../../theme';

const Image = styled.img`
  height: ${(props) => props.height}em;
  display: inline !important;

  ${media.md`
    margin: ${(props) => props.margin}px;
  `}

  @media (max-width: 991px) {
    max-width: inherit;
  }
`;

const Cerner = ({ height, margin }) => {
  const renderCerner = `${process.env.PUBLIC_URL}/${'Cerner.png'}`;

  return (
    <Image
      id="Cerner"
      src={renderCerner}
      alt="Within Health Cerner"
      height={height}
      margin={margin}
    />
  );
};

Cerner.propTypes = {
  onWhite: PropTypes.bool,
  height: PropTypes.number,
  margin: PropTypes.number,
  display: PropTypes.string,
};

Cerner.defaultProps = {
  onWhite: true,
  height: 10,
  margin: 2,
};

export default Cerner;
