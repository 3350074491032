import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import UserStatusPanel from './UserStatus';
import NextStepPanel from './NextStepPanel';
import ReportsPanel from './ReportsPanel';
import Box from '../../components/_styles/Box';
import USER_STEPS from './userSteps';

function isAppointmentOver(date) {
  return new Date() > new Date(date);
}

const calculateStep = ({ healthHistory, appointments }) => {
  let step = '';
  let appointmentType;
  let dateTime;
  let isScan;
  let isPreScan;

  const appointment = appointments[0];

  if (appointments.length) {
    appointmentType = appointment.appointment_type;
    dateTime = appointment.date_time;
    isScan = appointmentType === 'scan';
    isPreScan = appointmentType === 'pre-scan';
  }

  function isSelectPlan() {
    // return localStorage.getItem('SelectPlan').length > 0;
    return true;
  }

  function isMedicalHistory() {
    return healthHistory.gender && isSelectPlan();
  }

  function isTelehealthVisit() {
    return (
      isMedicalHistory() &&
      (isScan || (isPreScan && isAppointmentOver(dateTime)))
    );
  }

  function isPrescription() {
    return isScan && isAppointmentOver(dateTime) && isTelehealthVisit();
  }

  function isComplete() {
    return false && isPrescription();
  }

  switch (true) {
    case isComplete():
      step = 'Review Results';
      break;
    case isPrescription():
      step = 'Scanning';
      break;
    case isTelehealthVisit():
      step = 'Prescription';
      break;
    case isMedicalHistory():
      step = 'Telehealth Visit';
      break;
    case isSelectPlan():
      step = 'Medical History';
      break;
    default:
      step = 'Select Plan';
  }

  return step;
};

const Dashboard = ({ user }) => {
  const step = calculateStep(user);
  let currentStep = USER_STEPS.find((userStep) => userStep.label === step);

  if (!user.mostRecentAppointment) {
    currentStep = {
      ...currentStep,
      tooltipContent: 'Please setup an appointment',
      action: {
        link: '/plans',
        hasModal: false,
      },
    };
  }

  return (
    <Box p={30}>
      <UserStatusPanel currentStep={currentStep} />
      <Row>
        <Col lg={12} xl={4}>
          <NextStepPanel currentStep={currentStep} user={user} />
        </Col>
        <Col lg={12} xl={8}>
          <ReportsPanel />
        </Col>
      </Row>
    </Box>
  );
};

function mapStateToProps(state) {
  const { user } = state.auth;

  return {
    user,
  };
}

export default connect(mapStateToProps)(Dashboard);
