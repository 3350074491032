import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

// import { Button } from '../../components/UI/Button';
// import ImageCarousel from './ImageCarousel';
import Flex from '../../components/_styles/Flex';
import Heading from '../../components/_styles/Heading';
import RecommendationsAccordion from './RecommendationsAccordion';
import { PulseIcon, MetabolismIcon, IntellectIcon, RibbonIcon, NeurovascularIcon } from '../RadiologySearchPage/Icons';
import SummaryHealth from './SummaryHealth';
import { getReport } from '../../actions/reports';

const summaryHealth = [
  {
    title: 'Cardiovascular',
    subTitle: '1 risk found',
    description: 'A calcium score is calculated based on the amount of plaque observed in the CT scan.',
    IconComponent: PulseIcon,
    reports: [
      { bg: 'blue', width: 50 },
      { bg: 'light-blue', width: 20 },
      { bg: 'light-gray', width: 20 },
      { bg: 'red', width: 10 }
    ]
  },
  {
    title: 'Neurovascular',
    subTitle: '2 recommendations',
    description: 'A calcium score is calculated based on the amount of plaque observed in the CT scan.',
    IconComponent: NeurovascularIcon,
    reports: [
      { bg: 'blue', width: 90 },
      { bg: 'light-gray', width: 10 }
    ]
  },
  {
    title: 'Neurodegenerative',
    subTitle: '1 low-risk found',
    description: 'A calcium score is calculated based on the amount of plaque observed in the CT scan.',
    IconComponent: IntellectIcon,
    reports: [
      { bg: 'blue', width: 90 },
      { bg: 'light-gray', width: 5 },
      { bg: 'red', width: 5 }
    ]
  },
  {
    title: 'Metabolic',
    subTitle: '1 risk found',
    description: 'A calcium score is calculated based on the amount of plaque observed in the CT scan.',
    IconComponent: MetabolismIcon,
    reports: [
      { bg: 'blue', width: 70 },
      { bg: 'light-blue', width: 10 },
      { bg: 'light-gray', width: 10 },
      { bg: 'orange', width: 5 },
      { bg: 'orange', width: 5 }
    ]
  },
  {
    title: 'Oncologic',
    subTitle: '1 risk found',
    description: 'A calcium score is calculated based on the amount of plaque observed in the CT scan.',
    IconComponent: RibbonIcon,
    reports: [
      { bg: 'blue', width: 90 },
      { bg: 'light-gray', width: 5 },
      { bg: 'orange', width: 5 }
    ]
  }
];

const ReportWrapper = styled.div`
  color: #3f4857;
  box-sizing: border-box;
  display: flex;

  @media (max-width: 991px) {
    display: block;
  }
`;

// const ButtonWrapper = styled.div`
//   color: #3f4857;
//   flex: 1;
//   box-sizing: border-box;

//   @media (max-width: 991px) {
//     margin-top: 20px;
//   }
// `;

const HeadingWrapper = styled.div`
  color: #3f4857;
  margin-bottom: 30px;
  box-sizing: border-box;
  display: flex;

  @media (max-width: 991px) {
    display: block;
  }
`;

const SingleRadiologyReportPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const report = useSelector((state) => state.reports.reportPage.report);

  useEffect(() => {
    dispatch(getReport(id));
  }, []);

  if (!report) {
    return null;
  }

  return (
    <ReportWrapper>
      <Flex.Col flexGrow={1} p={30}>
        <HeadingWrapper>
          <Flex.Col flex='5'>
            <Heading as='h2' children={report.scan} />
          </Flex.Col>
        </HeadingWrapper>
        <SummaryHealth data={summaryHealth} text={report.explanation_text} />
        <RecommendationsAccordion />
        {/* <ImageCarousel /> */}
      </Flex.Col>
    </ReportWrapper>
  );
};

export default SingleRadiologyReportPage;
