import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CommunicationOption from './CommunicationOption';
import Headphones from '../../../assets/checkout/headphones.svg';
import Notepad from '../../../assets/checkout/notepad.svg';
import { setCurrentStep } from '../../../actions/checkout';

const RightSection = styled.section`
  padding: 10px 35px;
  @media (max-width: 991px) {
    padding: 10px 0px;
  }
`;

const InnerRightSection = styled.div`
  margin-top: -20px;
  max-width: 500px;
  padding-left: 40px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }
`;

const SectionHeadline = styled.h2`
  color: black;
  text-align: start;
  font-size: 28px;
  text-shadow: 0px 0.1px, 0.1px 0px, 0.1px 0px;
`;

const ExplanationText = styled.div`
  color: #3f4857;
  font-size: 18px;
  line-height: 137%;
  text-align: start;
`;

const COMMUNICATION_OPTIONS = ['VOICE', 'ONLINE'];

const SchedulingStatusRightPanel = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onNext = useCallback(() => {
    dispatch(setCurrentStep('CHECKOUT_PERSONAL_INFO'));
    history.push(`${history.location.pathname}/checkout`);
  }, [history, dispatch]);

  return (
    <div className='seven-cols'>
      <RightSection>
        <InnerRightSection>
          <SectionHeadline>Start online visit</SectionHeadline>
          <ExplanationText>
            Every condition we treat has its own specific online visit. This removes unnecessary, time-consuming
            questions and focuses solely on the symptoms and medical history that help your doctor evaluate the best
            treatment option for you. If the doctor needs more details or has a question, they will contact you to set
            up a time to talk.
          </ExplanationText>
          <CommunicationOption
            imgSrc={Notepad}
            contentHeadline='Start online visit'
            infoText='We will walk you through specific online visit questions.'
            option={COMMUNICATION_OPTIONS[1]}
            next={onNext}
          />
          <CommunicationOption
            imgSrc={Headphones}
            contentHeadline='Prefer to talk to a human doctor?'
            infoText='We have amazing doctors ready to answer your questions and walk you through the process'
            option={COMMUNICATION_OPTIONS[0]}
            next={onNext}
          />
        </InnerRightSection>
      </RightSection>
    </div>
  );
};

export default SchedulingStatusRightPanel;
