// Higher Order Component

import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as flashActions from '../../actions/flash';
import * as authActions from '../../actions/auth';

export default function (ComposedComponent) {
  function Authentication(props) {
    if (!props.isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: '/login'
          }}
        />
      );
    }

    return <ComposedComponent {...props} />;
  }

  function mapStateToProps(state) {
    const { isLoggedIn } = state.auth;

    return { isLoggedIn };
  }

  return connect(mapStateToProps, { ...flashActions, ...authActions })(Authentication);
}
