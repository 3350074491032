import React from 'react';
import styled from 'styled-components';

import Flex from '../../../components/_styles/Flex';
import Form from './Form';
import Header from './Header';

const Container = styled.div`
  width: 650px;
  margin: 50px auto;
`;

const Heading = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;

  h1 {
    margin: 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #0c2945;
  }

  h2 {
    margin: 10px 0 0 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #3f4857;
  }
`;

const ViewPracticeInformation = ({ onSubmit }) => {
  return (
    <>
      <Header activeStep='PRACTICE_INFO' />
      <Container>
        <Flex alignItems='center' mb='40px'>
          <Heading>
            <h1 children='Practice Information' />
            <h2 children='Please enter information about your practice so we can onboard you to the Within Health Platform!' />
          </Heading>
        </Flex>
        <Form onSubmit={onSubmit} />
      </Container>
    </>
  );
};

export default ViewPracticeInformation;
