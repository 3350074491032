import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MUISlider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import Flex from '../_styles/Flex';
import Text from '../_styles/Text';

import './MUISliderOverride.css';

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const Slider = withStyles({
  root: {
    color: '#00AEEF',
    height: 2,
    padding: '15px 0'
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    marginTop: -14,
    marginLeft: -14,
    '&:focus,&:hover,&$active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow
      }
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 11px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000'
    },
    width: 150
  },
  track: {
    height: 3
  },
  rail: {
    height: 3,
    opacity: 0.5,
    backgroundColor: '#bfbfbf'
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor'
  }
})(MUISlider);

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

function valueLabelFormat(value) {
  return `within ${value} miles`;
}

export default function CustomizedSlider() {
  return (
    <Flex>
      <Flex.Col flex={2}>
        <Slider
          ValueLabelComponent={ValueLabelComponent}
          defaultValue={60}
          valueLabelDisplay="on"
          getAriaValueText={valueLabelFormat}
          valueLabelFormat={valueLabelFormat}
        />
      </Flex.Col>
      <Flex.Col flex={1}>
        <Flex justifyContent="flex-end">
          <Text fontWeight="bold"> centers found</Text>
        </Flex>
      </Flex.Col>
    </Flex>
  );
}
