import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Spinner } from 'react-bootstrap';
import { Divider, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux'
import { toggleConversation } from '../../../actions/radminUploads';

const useStyles = makeStyles({
    dialogContent: {
        fontSize: "16px",
        width: 500,
        padding: "80px 0 80px 24px",
        textAlign: "left"
    },
    title: {
        fontSize: "20px",
        marginBottom: "0px",
        fontWeight: "bold"
    },
    actionSection: {
        display: "flex",
        justifyContent: "space-around",
        width: "70%",
        margin: "0 auto",
        paddingBottom: "20px"
    },
    label: {
        padding: "5px 0px",
        fontSize: "11px",
        fontWeight: "bold"
    },
    labelCancel: {
        padding: "5px 42px",
        fontSize: "11px",
        fontWeight: "bold"
    },
    rootButton: {
        backgroundColor: " #E55A54"
    },
    rootCancelButton: {
        backgroundColor: "#00AEEF"
    },
    divider: {
        outerHeight: "5px",
        innerHeight: "5px",
        backgroundColor: "blue",
        width: "100%"
    }
})

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function ResponsiveDialog({showCloseConvDialog, clickCloseConversation, currentChatNumber, usersInfo, cb, searchQuery,currentTab,filters,onLastPage,page}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const deleteHandler = () => {
        if(currentChatNumber){
            setIsLoading(true)
          
            dispatch(toggleConversation(currentChatNumber?.id, {phone_closed: true}, searchQuery, false, currentTab, filters,onLastPage,page ))
            .then(res => {
                if(res.updated){
                    clickCloseConversation()
                    setIsLoading(false)
                    cb()
                }
            })
        }
    }

    return (
        <div>
            <Dialog
                open={showCloseConvDialog}
                aria-labelledby="customized-dialog-title"
                // maxWidth={20}
                style={{ OverflowY: 'hidden', width: '100%', height: '100%'}}
            >
                <DialogTitle id="customized-dialog-title" onClose={clickCloseConversation}  style={{ borderBottom: '1px solid #DFE0EB' }}>
                    <p className={classes.title}>Are you sure you want to close this converation?</p>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <p className={classes.dialogContent}>Once you close this conversation, you won’t be able <br/> to reopen it until the patient responds. </p>
                </DialogContent>
                <div className={classes.actionSection}>
                    <Button
                        classes={{ label: classes.label, root: classes.rootButton }}
                        onClick={deleteHandler}
                        variant="contained"
                        color="secondary"
                    >
                        {isLoading ? <Spinner animation="border" role="status" size="sm" style={{ margin: '0 50px' }} /> : "Close Conversation"}
                    </Button>
                    <Button
                        classes={{ label: classes.labelCancel, root: classes.rootCancelButton }}
                        onClickCapture={clickCloseConversation}
                        variant="contained"
                        color="primary">
                        Cancel
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}