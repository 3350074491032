import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Timeline from 'react-calendar-timeline';
import { TimelineHeaders, DateHeader, CustomMarker, TodayMarker, TimelineMarkers } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';

import { ReactComponent as TriangleIcon } from '../../../assets/triangle-small.svg';
import RecoHistoryMarker from './RecoHistoryMarker';

const StyledTimeLineContainer = styled.div`
  & .rct-calendar-header {
     height :54px;
    display : flex;
    align-items : center;
    background-color: #FFFFFF;
    border :none;
    }
  & .rct-outer {
    overflow :visible;
    height:100% !important;
  }

  & .rct-outer .rct-scroll {
      height : 200px !important;
      width : 100% !important;
      overflow : scroll;
    }

  & .rct-dateHeader {
     border : 0;
      background-color : #FFFFFF;
      font-family  : Roboto Condensed;
      font-size : 14px;
      font-weigth : 600;
      font-style : normal;
      color :#9FA2B4;
      letter-spacing : 0.2px;
    }
`

const TriangleMarker = styled(TriangleIcon)`
  & > path {
    fill: #00AEEF;
  }
`
const TEN_DAYS_IN_MS = 864000000;

const groups = [
  { id: 1, title: 'group 1', style: { background: '#FFFFFF'}, height: 125, canResize: false },
];

const onTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
  updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
}

const createSignature = (name, backgroundColor, color = '#FFFFFF') => {
 return <div
      style={{
        backgroundColor,
        position: 'absolute',
        left:0,
        right:0,
        width: '126px',
        height: '25px',
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'center',
        color,
        fontFamily: 'Roboto Condensed',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '15px',
        textAlign: 'center',
        letterSpacing: '0.2px',
        justifyContent: 'center',
        marginBottom: '-61px',
      }}
    >
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        style={{ marginRight: '8px' }}
      >
        <path
          d="M11.8 0C7.7 0 4.3 3.2 3.9 7.2H1C0.4 7.2 0 7.6 0 8.2C0 8.8 0.4 9.2 1 9.2H8.6C8.9 9.2 9.2 9.1 9.4 8.8L10 8.1L11.1 10.9C11.2 11.3 11.6 11.5 11.9 11.5H12C12.3 11.5 12.7 11.3 12.8 11L14.2 8.9H15.3C15.9 8.9 16.3 8.5 16.3 7.9C16.3 7.3 15.9 6.9 15.3 6.9H13.7C13.4 6.9 13 7.1 12.9 7.4L12.3 8.4L11.3 5.8C11.2 5.5 10.9 5.2 10.5 5.2C10.1 5 9.7 5.2 9.5 5.5L8.1 7.2H6C6.4 3.9 9.6 1.4 13.1 2.1C15.3 2.6 17.1 4.4 17.6 6.6C18.4 10.4 15.5 13.8 11.8 13.8C10.3 13.8 8.9 13.2 7.8 12.3C7.4 12 6.9 12 6.5 12.3C6 12.7 6 13.4 6.4 13.8C8.2 15.4 10.7 16.3 13.4 15.7C16.4 15.1 18.9 12.6 19.5 9.6C20.5 4.4 16.7 0 11.8 0Z"
          fill="white"
        />
      </svg>
      {name}
    </div>
}

const CustomTimeline = ({ start, end, status, accessionNumber, initialExamDate, date }) => {
  const recoHistoryArray = useSelector(state => state.radAdmin.followUpList.recoHistory?.data);

  const sortedRecoHistoryArray = recoHistoryArray && recoHistoryArray.sort((a, b) => moment(a.changed_at) - moment(b.changed_at));
  const timeOfLastRecoStatus = sortedRecoHistoryArray && sortedRecoHistoryArray[sortedRecoHistoryArray.length - 1]?.changed_at;

  const isExpired = status === 'expired';
  const timeStart = isExpired ? moment(start).toDate() : moment().subtract(2, 'months').toDate();
  const timeEnd = isExpired ? moment().add(30, 'days').toDate() : moment(end).add(3, 'months').toDate();
  const itemRenderer = ({ item, itemContext, getItemProps }) => {
    return (
      <div
        {...getItemProps({
          style: {
            backgroundColor: item.bgColor,
            color: item.color,
            border: 0,
            width: 450,
          },
        })}
      >
        <div
          style={{
            height: item?.itemProps.style?.height,
            backgroundColor: item?.itemProps.style?.backgroundColor,
            overflow: "hidden",
            paddingLeft: 3,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          title={item?.title?.props?.children?.[1]}

        >
          {itemContext.title}
        </div>
      </div>
    );
  };
  const items = [
    {
      id: 1,
      group: 1,
      title:  createSignature('Non-Mature', '#FFFFFF', '#6F52ED'),
      start_time: moment(initialExamDate),
      end_time: moment(start).subtract(31, 'days'),
      canMove: false,
      itemProps: {
        style: {
          backgroundColor: 'rgba(111, 82, 237, 0.2)',
          border: 0,
          height: 126,
        }
      }
    },
    {
      id: 2,
      group: 1,
      title: createSignature('Coming Due', '#FFD57C'),
      start_time:moment(start).subtract(30, 'days'),
      end_time: moment(end),
      canMove: false,
      itemProps: {
        style: {
          backgroundColor: 'rgba(255, 213, 124, 0.2)',
          border: 0,
          height: 126,
        }
      }
    },
    {
      id: 3,
      group: 1,
      title: createSignature('Overdue', '#F73B3B'),
      start_time: moment(end),
      end_time: moment(end).add(60, 'days'),
      canMove: false,
      itemProps: {
        style: {
          backgroundColor: 'rgba(253, 114, 113, 0.33)',
          border: 0,
          height: 126,
        }
      }
    },
    {
      id: 4,
      group: 1,
      title: createSignature('Non-Compliant', '#6F52ED'),
      start_time: moment(end).add(61, 'days').toDate(),
      end_time: moment(date),
      canMove: false,
      itemProps: {
        style: {
          backgroundColor: 'rgba(111, 82, 237, 0.2)',
          border: 0,
          height: 126,
        }
      }
    },
    {
      id: 5,
      group: 1,
      title: '',
      start_time: moment().subtract(5, 'years').toDate(),
      end_time: moment(timeOfLastRecoStatus).add(7, 'days').toDate(),
      canMove: false,
      itemProps: {
        className: 'reco-history-timeline',
        style: {
          backgroundColor: '#C2CCD9',
          border: 0,
          height: !!sortedRecoHistoryArray?.length ? 1 : 0,
          zIndex: 99,
        }
      }
    },
  ];

  
  return (
    <StyledTimeLineContainer style={{position: 'relative'}}>
      <Timeline
        groups={groups}
        items={items}
        defaultTimeStart={timeStart}
        defaultTimeEnd={timeEnd}
        sidebarContent={null}
        timeSteps={{
          second: 0,
          minute: 0,
          hour: 0,
          day: 0,
          month: 1,
          year: 0,
          }}
          canResize={false}
          scroll={false}
          stackItems={true}
          itemRenderer={itemRenderer}
          sidebarWidth={0}
          onTimeChange={onTimeChange}
      >
        <TimelineHeaders className="sticky">
          <DateHeader
            unit="month"
            labelFormat={'MMM YYYY'}
            />
        </TimelineHeaders>
        <TimelineMarkers>
          <TodayMarker>
            {({ styles, date }) =>
              <div>
                <div style={{ ...styles, width: 1, height: 125, backgroundColor: '#00AEEF' }} />
                <TriangleMarker
                  style={{
                    ...styles,
                    top: 126,
                    width: 'auto',
                    backgroundColor: 'transparent',
                    marginLeft: '-3px',
                }} />
                <span style={{
                  ...styles,
                  top: 126,
                  display: 'flex',
                  justifyContent: 'center',
                  height: 'fit-content',
                  color: '#00AEEF',
                  fontFamily: 'Roboto Condensed',
                  fontWeight: 600,
                  fontSize: '8px',
                  backgroundColor: 'transparent',
                }}>
                 Today {moment(date).format('MM.DD')}
                </span>
              </div>
            }
          </TodayMarker>
            {sortedRecoHistoryArray?.map((item, idx) => {
              let difference = TEN_DAYS_IN_MS;
              if (sortedRecoHistoryArray.length - 1 !== idx) {
                difference = Math.abs(moment(sortedRecoHistoryArray[idx + 1].changed_at) - moment(item.changed_at));
              }
              const isDatesTooClose = TEN_DAYS_IN_MS > difference;
              return (<CustomMarker key={idx + item.changed_at} date={moment(item.changed_at).toDate()}>
                {({ styles, date }) =>
                  <RecoHistoryMarker
                    styles={styles}
                    date={date}
                    status={item.status_override_to}
                    message={item?.message}
                    messageDate={item.changed_at}
                    isDatesTooClose={isDatesTooClose}
                    accessionNumber={accessionNumber}
                    initialExamDate={initialExamDate} />}
            </CustomMarker>)
          } )}
        </TimelineMarkers>
      </Timeline>
    </StyledTimeLineContainer>
  )
}

export default CustomTimeline;
