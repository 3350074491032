import styled from "styled-components";
import { FormRow, FormItem, Input } from "../../../../../components/Design/form";

export const FormContainer = styled.div`
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 550px;
  padding: 20px;
  width: 75%;
  margin: 0 auto;
  margin-top:5%;
  @media (max-width:991px){
    width: 100%;
    &:first-child {
      margin-bottom: 20px;
    }
  }
`;

export const FormHeader = styled.div`
  font-weight: 600;
  font-size: 0.778rem;
  line-height: 120%;
  text-transform: uppercase;
  color: #8692a4;
`;

export const ModifiedFormRow = styled(FormRow)`
  justify-content: space-between;
`;

export const ModifiedFormItem = styled(FormItem)`
  width: 48%;
`;

export const ModifiedInput = styled(Input)`
  border: 1px solid #c2ccd9;
`;
export const ModifiedInputLarge = styled(Input)`
  border: 1px solid #c2ccd9;
  min-height: 100px;
  min-width: 100px;
`;

export const Button = styled.button`
  color: ${prop => prop.color ? prop.color: "white"}
  background-color: ${prop => prop.bgColor}
  border: none;
  border-radius: 5px;
  font-size: 14px;
  line-height: 17.6px;
  font-weight: 600;
  padding:${prop => prop.padding? prop.padding: "10px 25px"}
`
