import React, { useState, useRef, useEffect, memo } from 'react';
import { useFormik } from 'formik';
import Select,  { components } from 'react-select';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Flex from '../../../components/_styles/Flex';
import equalizer from '../../../assets/equalizer.png';
import filterOutline from '../../../assets/IO-icons/filter-outline.svg';
import close from '../../../assets/close.svg';

export const Card = styled(Flex)`
  width: 270px;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 22px 22px 19px;
`

export const FormContainer = styled.div`
  width: 230px;
`

export const SelectContainer = styled.div`
  margin-bottom: 30px;
`

export const FilterContainer = styled(Flex)`
  width: 120px;
  height: 38px;
  justify-content: space-around;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #C2CCD9;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-top: 15px;
  }
`

export const SettingsContainer = styled.div`
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  border: 1px solid #C2CCD9;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  border-radius: 8px;
  
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: 200px;
  z-index: 2;
`
export const Image = styled.img`
  display: block;
  width: 20px;
`;

export const FilterButton = styled(Flex)`
  width: 100%;
  justify-content: space-around;
  align-items: center;
  height:38px;
`

export const FilterButtonTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #00AEEF;
`

export const FilterMenuTitle = styled.div`
  margin-bottom: 14px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
`

export const SelectDropdownTitle = styled.div`
  margin-bottom: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.6;
  text-transform: uppercase;
`

export const Button = styled.button`
  width: 226px;
  height: 50px;
  background-color: #00AEEF;
  border-radius: 4px;
  border: 0;
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF; 
`

export const SelectedItemContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 83px;
  height: 32px;
  background: rgba(43, 113, 255, 0.15);
  border-radius: 4px;
  @media (max-width: 768px) {
    width: 120px;
    margin: 0 auto;
  }
`

export const SelectedItem = styled.div`
  margin: 7px 3px 6px 8px;
  min-width: 50px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`

export const CloseSign = styled.div`
  width: 14px;
  height: 14px;
  color: #00AEEF;
  margin-right: 7px;
  opacity: 0.5;
    &:hover {
      opacity: 1;
    }
`

const Filter = memo(({ onSetSelected, optionsTitle, options }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);

    const node = useRef();

    useEffect(() => {
        if (showMenu) {
          document.addEventListener('mousedown', handleClickOutside);
        } else {
          document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [showMenu]);

      const handleClickOutside = (e) => {
        if (node.current && node.current.contains(e.target)) {
          return;
        }
        setShowMenu(false);
      };
   
    const formik = useFormik({
            initialValues: {
               input: ''
             },
             onSubmit: (values, {resetForm}) => {
                 if (!values.input) {
                    setShowSubmit(false);
                    showMenu(false);
                 } else {
                      setShowMenu(false);
                      setSelectedValue(values.input);
                      setShowSubmit(true)
                      onSetSelected(values.input);
                      resetForm();
                 }
             },
           });

          const defaultValue = (options, value) => {
            return options ? options.find(option => option.value === value) : "";
        };

        const closeSelected = () => {
            setShowMenu(false);
            setShowSubmit(false);
            setSelectedValue(null);
            onSetSelected(null);
        }
        const IndicatorsContainer = props => {
            return (
              <div style={{ backgroundColor: '#FFF' }}>
                <components.IndicatorsContainer {...props} />
              </div>
            );
          };
  return (
    <>
      {
        !showMenu && showSubmit && 
          <SelectedItemContainer>
            <SelectedItem> {selectedValue}</SelectedItem>
            <CloseSign onClick={closeSelected}><Image src={close} alt={'close sign'}/></CloseSign>
          </SelectedItemContainer>
      }
      { 
        !showSubmit && 
        <FilterContainer onClick={() => setShowMenu(true)}> 
          { 
            !showSubmit && !showMenu && 
              <FilterButton >
                <FilterButtonTitle>Filter</FilterButtonTitle>
                <Image src={filterOutline} alt={'settings icon'} />
              </FilterButton>
          }
          {
            showMenu && !showSubmit? (
              <SettingsContainer ref={node}>
                <Row>
                  <Col sm={3} lg={3} xl={3}>
                    <Card>
                      <FilterMenuTitle> Add filters </FilterMenuTitle>
                      <SelectDropdownTitle>{optionsTitle}</SelectDropdownTitle>
                      <FormContainer>
                        <form onSubmit={formik.handleSubmit}>
                          <SelectContainer>
                            <Select 
                              components={{ IndicatorsContainer }}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={options} 
                              value={defaultValue(options, formik.values.input)}
                              onChange={value => formik.setFieldValue('input', value.value)}
                            />
                          </SelectContainer>
                          <Button>Apply Filters</Button>
                        </form>
                      </FormContainer>
                    </Card>
                  </Col>
                </Row>
              </SettingsContainer>
            )
            : (
              null
            )
          }
        </FilterContainer> 
      }
    </>
)
});

export default Filter;
