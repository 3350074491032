import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Spinner } from 'react-bootstrap';
import { ReactComponent as PhoneIcon } from "../../../assets/phone-filled.svg";
import { updateTwilioConfig, getSmsResponse, updateSmsResponse, deleteTwilioConfig, showAlert} from "../../../actions/radminUploads";
import { CloseIcon } from "./Icons";
import Flex from '../../../components/_styles/Flex';
import { Grid, makeStyles,  Popover,  Typography, } from "@material-ui/core";
import RemoveResponse from '../../../components/Dialog/RemoveResponse';
import { ReactComponent as RemoveRecoIcon} from '../../../assets/FollowUpDeleteIcon.svg'
import { ReactComponent as MessageIcon} from '../../../assets/email-filled.svg'
import Select, { components } from 'react-select';
import {  styled as materialStyle } from "@material-ui/core/styles";
import { isEmpty } from "lodash";

const AddButton = styled.button`
  color: white;
  background-color: #00aeef;
  border: none;
  border-radius: 4px;
  padding: 0 12px;
  font-size: 15px;
  font-weight: 600;
  height: 38px;
`;
const InputField = styled.input`
  height: 38px;
  width: 255px;
  font-size: 16px;
  border: 1px solid #d2d3d7;
  border-radius: 4px;
`;

const SubHeader = styled.h4`
font-size: 14px;
font-weight: 600;
color: #00AEEF;
margin-right:16px;
margin-bottom:0px;
height:38px;
display:flex;
align-items:center;
cursor:pointer;
font-family:Open Sans;
`


const BottomContainer = materialStyle(Grid)({
  margin: "0 auto",
});


const PersionalizedTextContainer = materialStyle(Grid)({
  border: "1px solid #CBCCCF",
  borderRadius: "4px",
});
const PersonalizedFieldSection = materialStyle(Grid)({
  display: "flex",
  alignItems: "center",
  width: "100%",
  gap: "21px",
  justifyContent: "flex-end",
  marginRight: "21px",
});

 const PersonalizedInnerContainer = materialStyle(Grid)({
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: 38,
});
const InsertPersonalizedSection = materialStyle(Grid)({
  backgroundColor: "#F5F5F5",
  height: "200px",
  overflowY: "auto",
  padding: "10px 0px",
});
const PersonalizedRow = materialStyle(Grid)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "8px",
  alignItems:'center',
  gap:'10px'
});
const PersionalizedText = materialStyle(Typography)({
  fontSize: 14,
  fontWeight: 400,
  margin: 0,
  marginLeft: 10,
  color: "#8F8F8F",
});

 const CreateTemplateContainer = materialStyle(Grid)({
  maxHeight: "290px",
});


const Modal = styled.div`
  display: ${(props) => (props.showModal ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(12, 41, 69, 0.38);
  backdrop-filter: blur(10px);
`;

const ModalContentContainer = styled.div`
  position: relative;
  min-height: 400px;
  width: 95%;
  height: 80%;
  overflow-y: scroll;
  margin: 0 auto;
  background-color: white;
  cursor: default;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  border-radius: 8px;
  padding-bottom: 5px;
`;

const Header = styled.div`
  width: 100%;
  padding: 30px 24px 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 30px;
`;

const ModalTitle = styled.p`
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 24px;
  color: #0c2945;
  font-family:Roboto Condensed;
  font-weight:700;
`;

const HorizontalLine = styled.hr`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 1px;
  background-color: #dfe0eb;
`;

const CloseButton = styled.button`
  border: 0;
  outline: none !important;
  background-color: #ffffff;
`;

const SaveButton = styled.button`
  width: 106px;
  height: 38px;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00AEEF;
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  border: 0;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 32px 0 0 0;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 38px;
  padding: ${props => props.padding ? props.padding : '9px 10px'}
  border: 1px solid #c2ccd9;
  border-radius: 4px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: ${(props) => (props.font ? props.font : 'normal')};
  font-size:  ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: 18px;
  text-align: ${(props) => (props.align ? props.align : '')};
  color: #3f4857;
  resize: none;
  &:focus {
    border: 1px solid #00AEEF;
  }
  &::placeholder {
    color: #8692a4;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
  color: #3f4857;
  svg {
    margin-right: 6px;
  }
`;

const SpinnerContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
  margin: 30px 0;
  align-items: center;
`

const MessageRemove = styled.div`
  border: none;
  cursor: pointer;
`
const AddResponseButton = styled.div`
  type: button;
  padding: 10px;
  font-weight: 600;
  font-size: 14px;
  color: #00AEEF;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top:27px;
  margin-bottom:27px;
  font-family:Open Sans;
`

export const SelectDropdownTitle = styled.div`
  margin-bottom: 4px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6D6E6E;
  text-transform: uppercase;
`;

const recoStatusOptions = [
  { value: 'navigator_closed', label: 'Navigator Closed' },
  { value: 'patient_deceased', label: 'Patient Deceased' },
  { value: 'unsubscribed', label: 'Unsubscribed' },
  { value: 'acknowledged', label: 'Acknowledged' },
  { value: 'not_clinically_necessary', label: 'Not Clinically Necessary' },
  { value: 'completed_elsewhere', label: 'Completed Elsewhere' },
  { value: 'scheduled_not_completed', label: 'Scheduled Not Completed' },
  { value: 'completed_before_timeframe', label: 'Completed Before Timeframe' },
  { value: 'completed_after_timeframe', label: 'Completed After Timeframe' },
  { value: 'needs_prior_auth', label: 'Needs Prior Authorization' },
  { value: 'needs_prescription', label: 'Needs Prescription' },
  { value: 'adherent', label: 'Adherent' },
  { value: 'rx_received', label: 'RX Received' },
  { value: 'non_mature', label: 'Non Mature' },
  { value: 'coming_due', label: 'Coming Due' },
  { value: 'overdue', label: 'Overdue' },
  { value: 'expired', label: 'Non-Compliant' },
  { value: 'unknown', label: 'Unknown' }
];

const useStyles = makeStyles({
  rowStyling: {
    width: "100%",
    margin: "0px 0px 12px 0px",
  },
  addCircle: {
    cursor: 'pointer',
    color: "#4dabf5"
},
});

const IndicatorsContainer = props => {
  return (
    <div style={{ backgroundColor: '#FFF' }}>
      <components.IndicatorsContainer {...props} />
    </div>
  );
};


const SelectContainer = styled.div`
 width: 250px;
.mySelect__value-container{
 height: 38px;
}
`;

const DefaultResponsesModal = ({ showModal, closeModal }) => {
  const responseConfig = useSelector((state) => state.radAdmin.messages.twilioConfig.data);
  const recoConfigData = useSelector((state) => state.radAdmin.recoConfig.data);
  const providerName = useSelector((state) => state.auth.user.provider_name);
  const voiceMessage = responseConfig?.response_voice_message
    .replace("{{provider_name}}", recoConfigData?.name)
    .replace("{{provider_phone_number}}", responseConfig.phone_number);
  const textMessage = responseConfig?.response_sms_message;
  const [voiceMessageValue, setVoiceMessageValue] = useState(voiceMessage);
  const [smsMessageValue, setSmsMessageValue] = useState(responseConfig?.response_sms_message);
  const smsResponseData = useSelector((state) => state.radAdmin.messages?.smsResponse?.data);
  const isLoadingOptions = useSelector((state) => state.radAdmin.messages?.smsResponse?.isLoading);
  const [smsResponseConfig, setSmsResponseConfig] = useState([]);
  const [openRemoveResponse, setOpenRemoveResponse] = useState(false)
  const [selectedData, setSelectedData] = useState(null);
  const [selectedRemovedIndex, setSelectedRemovedIndex] = useState(null)
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [clickedEntity, setClickedEntity] = useState('');
  
  const [isClickedPersionalized, setIsClickedPersionalized] = useState(false);
  const [defaultValue, setDefaultValue] = useState([]);
    
 const permission = useSelector((state) => state.auth.user.permission_level); 
 const rolePermissions = useSelector(
    (state) =>state.auth.user.permissions
  );
 const updatePermission = rolePermissions?.find(itm=>itm.name ==="Incoming")?.permissions.find(itm=>itm.codename === "change_incoming")?.is_authorized;
 const addPermission = rolePermissions?.find(itm=>itm.name ==="Incoming")?.permissions.find(itm=>itm.codename === "add_incoming")?.is_authorized;
 const deletePermission = rolePermissions?.find(itm=>itm.name ==="Incoming")?.permissions.find(itm=>itm.codename === "add_incoming")?.is_authorized;

 const rowRef = useRef(null);

 const newResponseConfig = {
    phone_number: responseConfig?.phone_number,
    provider: responseConfig?.provider,
    response_sms_message: smsMessageValue,
    response_voice_message: voiceMessageValue,
  };

  useEffect(() => {
    setSmsResponseConfig(smsResponseData)

        function parseString(inputString) {
      const pattern = /{{\s*(.*?)\s*\|\s*default\("(.*?)"\)\s*}}/g;
      const parsedData = {};
      let match;
  
      while ((match = pattern.exec(inputString)) !== null) {
          const key = match[1];
          const value = match[2];
          parsedData[key] = value;
      }
  
      return parsedData;
  }
  
 
    setDefaultValue(smsResponseData?.map((itm,index)=>{
      const parsedData = parseString(itm.value);
      

      return {
     reportPhysicianFirstName: parsedData?.report_physician_first_name || "",
      reportPhysicianLastName:parsedData?.report_physician_last_name || "",
     reportPhysicianPhoneNumber:parsedData?.report_physician_phone || "",
     reportModality: parsedData?.modality ||"",
     reportAnatomy:parsedData?.anatomy ||"",
     reportExamDate: parsedData?.report_exam_date ||"",
     rangeStart: parsedData?.nlp_range_start ||  "",
     rangeEnd: parsedData?.nlp_range_end || "",
    }}
  ))
  },[smsResponseData])

  useEffect(() => {
    dispatch(getSmsResponse())
  },[]);

  const onCloseModal = (e) => {
    e.stopPropagation();
    closeModal();
  };

  const prevDef = (e) => {
    e.stopPropagation();
  };

  const handleChangeVoiceMessage = (e) => {
    setVoiceMessageValue(e.target.value);
  };

  
  const handleChangeSMSMessage = (e) => {
    setSmsMessageValue(e.target.value);
  };


  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if((permission && updatePermission) || !permission){
      setLoading(true);
      setClickedEntity(e.target.id);
      dispatch(updateTwilioConfig(responseConfig.id, newResponseConfig)).then(res=>setLoading(false));
     }else{
       dispatch(
         showAlert('danger', 'Error', `You don't have permission to update response`)
       );
     }
  
  };

  const saveResponseHandler = (e, data, currentNumber) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(updateSmsResponse(responseConfig.id, data));
  };


  useEffect(()=>{
  if(smsResponseConfig){
    smsResponseConfig.map((itm, index)=>{
      const textareaSms = rowRef.current.querySelector(`textarea[id="${`${index}_smsTextLabel`}"]`); 

      const textareaDesc = rowRef.current.querySelector(`textarea[id="${`${index + 1}_description`}"]`); 

      if (textareaSms) {
        textareaSms.style.height = '1px';
        textareaSms.style.height = `${textareaSms.scrollHeight}px` || 38;
      }

      if (textareaDesc) {
        textareaDesc.style.height = '1px';
        textareaDesc.style.height = `${textareaDesc.scrollHeight}px` || 38;
      }
    })


}
  },[smsResponseConfig])



  const handleChangeMsgResponse = ({ target }, index) => {
    const item = {...smsResponseConfig[index]}
    item.value = target.value;
    smsResponseConfig[index] = {...item}
    setSmsResponseConfig([...smsResponseConfig])
  };
  
    const handleChangeMsgResponseName = ({ target }, index) => {  
    const item = {...smsResponseConfig[index]}
    item.name = target.value
    smsResponseConfig[index] = {...item}
    setSmsResponseConfig([...smsResponseConfig])
  };

  const handleChangeMsgRecoStatus = (value, index) => {  
    const item = {...smsResponseConfig[index]}
    item.reco_status = value
    smsResponseConfig[index] = {...item}
    setSmsResponseConfig([...smsResponseConfig])
  };

  const handleAddRow = () => {
    if((permission && addPermission) || !permission){
      const newOption = {
        number: smsResponseConfig && smsResponseConfig[smsResponseConfig?.length - 1]?.number + 1,
        name: '',
        value: '',
        reco_status:''
      };
      const newResponseConfig = [...smsResponseConfig, newOption]
      setSmsResponseConfig(newResponseConfig);
      setDefaultValue(prev=>[...prev, {
        reportPhysicianFirstName: "",
        reportPhysicianLastName: "",
        reportPhysicianPhoneNumber: "",
        reportModality:"",
        reportAnatomy:"",
        reportExamDate: "",
        rangeStart:  "",
        rangeEnd: "",
      }])
   }else{
     dispatch(
       showAlert('danger', 'Error', `You don't have permission to create response`)
     );
   }
  }

  const handleColumnNumberChange = ({ target }, index) => {
    const item = {...smsResponseConfig[index]}
    item.number = target.value
    smsResponseConfig[index] = {...item}
    setSmsResponseConfig([...smsResponseConfig])
  }

  const handleSMSRemove = () => {
    if(selectedRemovedIndex) {
      const allSmsResponseConfig = smsResponseConfig;
      var removed = allSmsResponseConfig.splice(selectedRemovedIndex,1)
      setSmsResponseConfig(allSmsResponseConfig)
      setOpenRemoveResponse(!openRemoveResponse)
    }
    if(selectedData?.id) {
      const filteredItems = smsResponseConfig.filter(item => {return item.id !== selectedData?.id})
      setSmsResponseConfig(filteredItems)
      selectedData?.id && dispatch(deleteTwilioConfig(selectedData.id))
      setOpenRemoveResponse(!openRemoveResponse)
    }
  }

  const handleDeleteDialogue = (data, index) => {
    setSelectedData(data)
    setSelectedRemovedIndex(index)
    setOpenRemoveResponse(!openRemoveResponse)
  }

  const removeResponseHandler = () => {
    setOpenRemoveResponse(!openRemoveResponse)
  }


  const personalizedClickHandler = () => {
    setIsClickedPersionalized(!isClickedPersionalized);
  };
  const handleChange = (e,index) => {
    let { name, value } = e.target;
    setDefaultValue(  prev=> prev.map(((itm,idx)=>idx === index?( { ...itm, [name]: value })  : itm)) );

  };

  const checkforTemplatedContent = (value, key, index) => {
    let content = smsResponseConfig[index]?.value;
    let valueToSearch = `${key} | default`;
  
    let requiredIndex = content?.indexOf(valueToSearch);
  
    if (requiredIndex === -1) {
      let concatedValue = content + `{{${key} | default("${value}")}}`;
      setSmsResponseConfig(prev=> prev.map(((itm,idx)=>idx === index?({...itm, value:concatedValue})  : itm)) )
    

    } else {
      let replacedValue = content.replace(
        new RegExp(`${key} \\| default\\("[^"]*"\\)`),
        `${key} | default("${value}")`
      );
      setSmsResponseConfig(prev=> prev.map(((itm,idx)=>idx === index?({...itm, value:replacedValue})  : itm)) )
    }
    setIsClickedPersionalized(false);
  };
  
  
  const addDefaultHandler = (from, index) => {
    if (from === "row1") {
      checkforTemplatedContent(
        defaultValue[index].reportPhysicianFirstName,
        "report_physician_first_name",
        index
      );
    } else if (from === "row2") {
      checkforTemplatedContent(
        defaultValue[index].reportPhysicianLastName,
        "report_physician_last_name",
        index
      );
    } else if (from === "row3") {
      checkforTemplatedContent(
        defaultValue[index].reportPhysicianPhoneNumber,
        "report_physician_phone",
        index
      );
    } else if (from === "row4") {
      checkforTemplatedContent(
        defaultValue[index].reportModality,
        "modality",
        index
      );
     
    } else if (from === "row5") {
      checkforTemplatedContent(
        defaultValue[index].reportAnatomy,
        "anatomy",
        index
      );
    
    } else if (from === "row6") {
      checkforTemplatedContent(
        defaultValue[index].reportExamDate,
        "report_exam_date",
        index
      );
    
    } else if (from === "row7") {
      checkforTemplatedContent(
        defaultValue[index].rangeStart,
        "nlp_range_start",
        index
      );
    } else if (from === "row8") {
      checkforTemplatedContent(
        defaultValue[index].rangeEnd,
        "nlp_range_end",
        index
      );
    } 
  }


  const [anchorEl, setAnchorEl] = React.useState(null);
  const [itmIndex, setItmIndex] = useState()

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setItmIndex(index)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  return (
    <>
      {openRemoveResponse && (
        <RemoveResponse
          openDialog={openRemoveResponse}
          removeResponseHandler={removeResponseHandler}
          deleteHandler={handleSMSRemove}
        />
      )}
      <Modal showModal={showModal} onClick={prevDef}>
        <ModalContentContainer>
          <Header>
            <ModalTitle>Default Responses</ModalTitle>
            <CloseButton type={"button"} onClick={onCloseModal}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <Form>
            <Field>
              <Label htmlFor="voiceMessageText">
                <PhoneIcon />
                Voice Message Text
              </Label>
              <div style={{ display: "flex", gap: "16px", padding:'10px', border:'1px solid #BFBFBF', width:'100%', background:'#F6F6F6', borderRadius:'4px' }}>
                <TextArea
                  id="voiceMessageText"
                  name="voiceMessageText"
                  value={voiceMessageValue}
                  onChange={handleChangeVoiceMessage}
                />

                <SaveButton style={{marginRight:'55px'}} id="voiceMessageSave" type="save" disabled={false} onClick={handleSubmit}>
                  {
                   loading && clickedEntity === 'voiceMessageSave' ? <Spinner animation="border" role="status" size="sm" /> : 'Save'
                  }
                </SaveButton>
              </div>
            </Field>
            <Field>
              <Label htmlFor="textMessageText">
                <MessageIcon />
                 Default SMS Response
                </Label>
          
              
              <div style={{ display: "flex", gap: "16px", padding:'10px', border:'1px solid #BFBFBF', width:'100%', background:'#F6F6F6', borderRadius:'4px' }}>   
              <TextArea
              id="textMessageText"
              name="textMessageText"
              value={smsMessageValue}
              onChange={handleChangeSMSMessage}
              />
              <SaveButton style={{marginRight:'55px'}} id="textMessageSave"  type="save" disabled={false} onClick={handleSubmit}>
              {
              loading && clickedEntity ==='textMessageSave' ? <Spinner animation="border" role="status" size="sm" /> : 'Save'
              }
              </SaveButton>
              </div>
            
            </Field>
            <Field>
              <Label htmlFor="SmsResponses">
                <MessageIcon />
                SMS Responses - If a patient responds with this number, they
                will receive the corresponding response.
              </Label>
              <div
                style={{
                  border: "1px solid #BFBFBF",
                  borderRadius: "4px",
                  width: "100%",
                  background: "#F6F6F6",
                  padding: "10px",
                }}
              >
                <div style={{ display: "flex", gap: "13.5px", marginBottom:'8px' }}>
                  <span
                    style={{
                      color: "#6D6E6E",
                      fontWeight: 700,
                      fontSize: "14px",
                      fontFamily: "Roboto Condensed",
                      width: "45px",
                      height: "16px",
                    }}
                  >
                    #
                  </span>

                  <span
                    style={{
                      color: "#6D6E6E",
                      fontWeight: 700,
                      fontSize: "14px",
                      fontFamily: "Roboto Condensed",
                      width: "200px",
                      height: "16px",
                      textTransform: "uppercase",
                    }}
                  >
                    Meaning
                  </span>

                  <span
                    style={{
                      color: "#6D6E6E",
                      fontWeight: 700,
                      fontSize: "14px",
                      fontFamily: "Roboto Condensed",
                      width: "564px",
                      height: "16px",
                      textTransform: "uppercase",
                    }}
                  >
                    corresponding response
                  </span>

                  <span
                    style={{
                      color: "#6D6E6E",
                      fontWeight: 700,
                      fontSize: "14px",
                      fontFamily: "Roboto Condensed",
                      textTransform: "uppercase",
                      height: "16px",
                    }}
                  >
                    reco status
                  </span>
                </div>
                <div ref={rowRef}>
                {smsResponseConfig &&
                  smsResponseConfig.map((config, index) => (
                    <>
                      <div style={{ display: "flex", gap: "15px", marginBottom:index + 1===smsResponseConfig.length?0:"10px"}}>
                        <TextArea
                          font="400"
                          fontSize="16px"
                          align="center"
                          padding="9px 0px"
                          id={`${index}_smsNumberLabel`}
                          name={`${index}_smsNumberLabel`}
                          key={`${index}_smsNumberLabel`}
                          placeholder=" "
                          value={config.number}
                          onChange={(e) => handleColumnNumberChange(e, index)}
                          style={{ width: "45px", height: "38px" }}
                        />

                        <TextArea
                          font="400"
                          fontSize="16px"
                          align="center"
                          id={`${index}_smsTextLabel`}
                          name={`${index}_smsTextLabel`}
                          key={`${index}_smsTextLabel`}
                          placeholder="Enter response title"
                          value={config.name}
                          onChange={(e) =>
                            handleChangeMsgResponseName(e, index)
                          }
                          style={{ width: "200px" }}
                        />
                      <TextArea
                          id={`${index + 1}_description`}
                          name={`${index + 1}_description`}
                          key={`${index + 1}_description`}
                          placeholder="Enter response text"
                          value={config.value}
                          onChange={(e) => handleChangeMsgResponse(e, index)}
                          style={{ width: "350px" }}
                          contentEditable={true}
                        />
                    
                        <SubHeader onClick={(e)=>handleClick(e,index)}>
                        Insert Personalized Content
                        </SubHeader>
                                      
                        <SelectContainer>
                          <Select
                            classNamePrefix="mySelect"
                            components={{ IndicatorsContainer }}
                            options={recoStatusOptions}
                            value={recoStatusOptions.find(
                              (itm) => itm.value === config.reco_status
                            )}
                            onChange={(value) =>
                              handleChangeMsgRecoStatus(value, index)
                            }
                            styles={{
                              option: (provided) => ({
                                ...provided,
                                fontSize: "16px",
                                fontWeight: 400,
                                fontFamily: "Open Sans",
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                fontSize: "16px",
                                fontWeight: 400,
                                fontFamily: "Open Sans",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                fontSize: "16px",
                                fontWeight: 400,
                                fontFamily: "Open Sans",
                              }),
                            }}
                          />
                        </SelectContainer>

                        <SaveButton
                          type="save"
                          key={`button_${index}`}
                          disabled={!config.name || !config.value }
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if((permission && updatePermission) || !permission){
                            saveResponseHandler(e, config, index)
                           }else{
                             dispatch(
                               showAlert('danger', 'Error', `You don't have permission to update response`)
                             );
                           }                          
                          }}
                        >
                          Save
                        </SaveButton>

                        <MessageRemove
                          onClick={() =>{
                            if( (permission && deletePermission) || !permission){
                              handleDeleteDialogue(config, index)
                           }else{
                             dispatch(
                               showAlert('danger', 'Error', `You don't have permission to delete response`)
                             );
                           }
                          }        
                        }
                        >
                          {" "}
                          <RemoveRecoIcon />{" "}
                        </MessageRemove>
                      </div>
                    </>
                  ))}
                </div>
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: 'center',
        }}
      >
                 <CreateTemplate
                  isClickedPersionalized={isClickedPersionalized}
                  personalizedClickHandler={personalizedClickHandler}
                  defaultValue={defaultValue}
                  handleChange={handleChange}
                  addDefaultHandler={addDefaultHandler}
                  setDefaultValue={setDefaultValue}
                  index={itmIndex}
                />

      </Popover>
              </div>

              { !isLoadingOptions && smsResponseConfig?.length !== 10 && (
                <AddResponseButton onClick={handleAddRow}>
                  Add New Response
                </AddResponseButton>
              )}
              {isLoadingOptions && (
                <SpinnerContainer>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </SpinnerContainer>
              )}
            </Field>
          </Form>
        </ModalContentContainer>
      </Modal>
    </>
  );
};


const CreateTemplate = ({defaultValue,handleChange,addDefaultHandler,index}) => {
  return (
    <>
      <CreateTemplateContainer>
        <PersionalizedTextContainer>
        <InsertPersonalizedSection>
            <PersonalizedRow noBoder>
              <BottomContainer>
                <PersionalizedText>
                  {"{{report_physician_first_name}}"}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="reportPhysicianFirstName"
                  value={defaultValue[index].reportPhysicianFirstName}
                  onChange={(e) => handleChange(e,index)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={(e) =>{
                    e.stopPropagation()
                    e.preventDefault();
                    isEmpty(defaultValue[index].reportPhysicianFirstName)
                    ? alert("Value required!")
                    : addDefaultHandler("row1", index)
                  }
                  }
                >
                  Add
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>
                  {"{{report_physician_last_name}}"}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="reportPhysicianLastName"
                  value={defaultValue[index].reportPhysicianLastName}
                  onChange={(e) => handleChange(e,index)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={(e) =>{
                    e.stopPropagation()
                    e.preventDefault();

                    isEmpty(defaultValue[index].reportPhysicianLastName)
                      ? alert("Value required!")
                      : addDefaultHandler("row2", index)
                  }
                }
                >
                  Add
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>
                  {"{{report_physician_phone}}"}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="reportPhysicianPhoneNumber"
                  value={defaultValue[index].reportPhysicianPhoneNumber}
                  onChange={(e) => handleChange(e,index)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={(e) =>{
                    e.stopPropagation()
                    e.preventDefault();

                    isEmpty(defaultValue[index].reportPhysicianPhoneNumber)
                      ? alert("Value required!")
                      : addDefaultHandler("row3", index)
                  }
                }
                >
                  Add
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>{"{{modality}}"}</PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="reportModality"
                  value={defaultValue[index].reportModality}
                  onChange={(e) => handleChange(e,index)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={(e) =>{
                    e.stopPropagation()
                    e.preventDefault();

                    isEmpty(defaultValue[index].reportModality)
                      ? alert("Value required!")
                      : addDefaultHandler("row4", index)
                  }
                }
                >
                  Add
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>{"{{anatomy}}"}</PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="reportAnatomy"
                  value={defaultValue[index].reportAnatomy}
                  onChange={(e) => handleChange(e,index)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={(e) =>{
                    e.stopPropagation()
                    e.preventDefault();

                    isEmpty(defaultValue[index].reportAnatomy)
                      ? alert("Value required!")
                      : addDefaultHandler("row5", index)
                  }
                }
                >
                  Add
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>{"{{report_exam_date}}"}</PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="reportExamDate"
                  value={defaultValue[index].reportExamDate}
                  onChange={(e) => handleChange(e,index)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={(e) =>{
                    e.stopPropagation()
                    e.preventDefault();

                    isEmpty(defaultValue[index].reportExamDate)
                      ? alert("Value required!")
                      : addDefaultHandler("row6", index)
                  }
                }
                >
                  Add
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>
                  {"{{nlp_range_start}}"}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="rangeStart"
                  value={defaultValue[index].rangeStart}
                  onChange={(e) => handleChange(e,index)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={(e) =>{
                    e.stopPropagation()
                    e.preventDefault();

                    isEmpty(defaultValue[index].rangeStart)
                      ? alert("Value required!")
                      : addDefaultHandler("row7", index)
                  }
                }
                >
                  Add
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>
                  {"{{nlp_range_end}}"}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="rangeEnd"
                  value={defaultValue[index].rangeEnd}
                  onChange={(e) => handleChange(e,index)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={(e) =>{
                    e.stopPropagation()
                    e.preventDefault();

                    isEmpty(defaultValue[index].rangeEnd)
                      ? alert("Value required!")
                      : addDefaultHandler("row8", index)
                  }
                }
                >
                  Add
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
          </InsertPersonalizedSection>
        </PersionalizedTextContainer>

      </CreateTemplateContainer>
    </>
  );
};


export default DefaultResponsesModal;
