import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { connect, useSelector } from "react-redux";
import { TextareaAutosize, TextField, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PreviousPageLink } from "../../Dashboard/PatientImport/index";
import { withRouter } from "react-router-dom";
import * as authActions from "../../../../actions/auth/actions";
import Flex from "../../../../components/_styles/Flex";
import { ReactComponent as ArrowLeftIcon } from "../../../../assets/arrow_left.svg";
import Step from "../../Dashboard/PatientImport/Step";
import { Col, Row, Container } from "react-bootstrap";
import CustomSlider from "../../../../components/CustomSlider";
import { TrackingMessage } from '../Icons';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "13px",
    color: "#3F4857",
  },
  marginTopInput: {
    marginTop: "55px !important",
  },
  selectInput: {
    width: 300,
    padding: 10,
    fontSize: 16,
    "& .MuiOutlinedInput-input": {
      padding: 0,
    },
  },
  textField: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      fontSize: 14,
      padding: "13.5px 14px",
    },
  },
  formControlDropdown: {
    width: "100%",
  },
  messageTitle: {
    fontSize: "14px",
    color: "#3F4857",
    lineHeight: "17.6px",
    fontWeight: 600,
    margin: "35px 0 3px 0",
  },
  contentContainer: {
    border: "1px solid #C2CCD9",
    borderRadius: 6,
    height: 200,
    overflow: "scroll",
    cursor: "text",
  },
}));

const ContainerCustom = styled.div`
  background: #ffffff;
  box-sizing: border-box;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 20px;
`;

const MainContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  border: 1px solid #c2ccd9;
  border-radius: 8px;
`;

const AreaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  margin: 0 auto;
`;

export const FormItem = styled.div`
  width: 100%;
`;

const CardTitle = styled.p`
  font-familty: Roboto Condensed;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #0C2945;
  margin: 0px;
  margin-left: 2%;
`;

const StepsContainer = styled.div``;

const CardHeader = styled.div`
  display: flex;
  background: #ffffff;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-top: 20px;
  align-items: center;
`;

const HeaderSection = styled.div`
  border-bottom: 3px solid #00AEEF;
  height: 45px;
`;

const BackButtonContainer = styled.div`
  display: flex;
  padding-top: 4%;
  padding-bottom: 10px;
  width: 60%;
  margin: 0 auto;
  align-items: center;
`;

const VariantButton = styled.div`
  background-color: ${(props) => props.bgcolor};
`;

const VariantHeader = styled.div`
  display: flex;
  justify-content: Center;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 7px;
  color: ${(props) => props.color};
`;

const AddButton = styled.button`
  color: white;
  background-color: ${(props) => props.color};
  border: none;
  border-radius: 5px;
  font-size: 14px;
  line-height: 17.6px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  width: 49%;
`;

const Tracking = styled.div`
  margin-top: 10px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #3f4857;
`;

const SliderPercentage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PercentageValue = styled.div`
  border: 1px solid #d2d3d7;
  padding: 8px 5px;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
`;

const TrackingContainer = styled.div`
    margin-top: 0px
`

const STEPS = [
  { order: 1, name: "Setup" },
  { order: 2, name: "Triggers" },
  { order: 3, name: "Design" },
  { order: 4, name: "Preview" },
];

function ABTestingCampaign(props) {
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();
  const classes = useStyles();
  const emailContentRef = useRef(null);
  const [campaignType, setCampaignType] = useState("Email");
  const [emailContent, setEmailContent] = useState(
    "Hey {{First_name}} - We saw that you had an exam on {{ Last_appointment_date}} and there were some health items to follow up on. Click below to schedule an appointment to follow your doctor’s recommendations {{ appointment_link }}"
  );

  const [defaultValue, setDefaultValue] = useState(40);
  const [remainingValue, setRemainingValue] = useState(0);
  const [currentStepOrder, setCurrentStepOrder] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);
  const [campaignTypeView, setCampaignTypeView] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState("");
  const [setup, setSetup] = useState({
    campaignName: "",
    description: "",
    goal: "",
  });

  useEffect(() => {
    if(props.selectedSequence) {
      setSelectedSequence(props.selectedSequence);
    }
    setRemainingValue(100 - defaultValue);
  }, []);

  const handleSliderChange = (e, newValue) => {
    setDefaultValue(newValue);
    setRemainingValue(100 - defaultValue);
  };

  return (
    <div>

        <ContainerCustom className="pb-0">
          <Container style={{ padding: "0px" }}>
            <Row>
              <Col sm="6">
                <VariantButton bgcolor="#D0F9DA">
                  <VariantHeader color="#000000 ">
                    Variant A
                  </VariantHeader>
                </VariantButton>
                <>
                  {selectedSequence === "email" && <><InputLabel className={classes.messageTitle}>
                    Email Subject
                  </InputLabel>
                  <TextField
                    id="outlined-uncontrolled"
                    variant="outlined"
                    value={`Hi there  {{ First Name}}`}
                    className={classes.textField}
                    // onChange={(e) => setTitle(e.target.value)}
                  />
                  <InputLabel className={classes.messageTitle}>
                    Email Content
                  </InputLabel></>}
                  <div
                    className={classes.contentContainer}
                    onClick={() => emailContentRef.current.focus()}
                  >
                    <TextareaAutosize
                      // maxRows={8}
                      aria-label="maximum height"
                      ref={emailContentRef}
                      value={emailContent}
                      onChange={(e) => setEmailContent(e.target.value)}
                      style={{
                        width: "100%",
                        border: "none",
                        fontSize: 13,
                        padding: "10px",
                        color: "#3F4857",
                        fontFamily: "Roboto Condensed",
                        display: "inline",
                        lineHeight: 2,
                        resize: "none",
                      }}
                      placeholder="Type here..."
                    ></TextareaAutosize>
                  </div>
                </>
              </Col>
              <Col sm="6">
                <VariantButton bgcolor="#6F52ED">
                  <VariantHeader color="#FFFFFF">
                    Variant B
                  </VariantHeader>
                </VariantButton>
                <>
                  {selectedSequence === "email" && <><InputLabel className={classes.messageTitle}>
                    Email Subject
                  </InputLabel>
                  <TextField
                    id="outlined-uncontrolled"
                    variant="outlined"
                    className={classes.textField}
                    value={`Hi there  {{ First Name}}`}
                    // onChange={(e) => setTitle(e.target.value)}
                  />
                  <InputLabel
                    className={`${classes.messageTitle} ${classes.marginTopInput}`}
                  >
                    {/* Email Content */}
                  </InputLabel></>}
                  <div
                    className={classes.contentContainer}
                    onClick={() => emailContentRef.current.focus()}
                  >
                    <TextareaAutosize
                      // maxRows={8}
                      aria-label="maximum height"
                      ref={emailContentRef}
                      value={`Check out this cool Email A/B Test!!`}
                      onChange={(e) => setEmailContent(e.target.value)}
                      style={{
                        width: "100%",
                        border: "none",
                        fontSize: 13,
                        padding: "10px",
                        color: "#3F4857",
                        fontFamily: "Roboto Condensed",
                        display: "inline",
                        lineHeight: 2,
                        resize: "none",
                      }}
                      placeholder="Type here..."
                    ></TextareaAutosize>
                  </div>
                </>
              </Col>
            </Row>
          </Container>

          <CustomSlider
            onChange={(e, newValue) => handleSliderChange(e, newValue)}
            defaultValue={defaultValue}
            remainingValue={remainingValue}
          />
          <Container className="p-0">
            <SliderPercentage>
              <PercentageValue>{defaultValue}%</PercentageValue>
              <PercentageValue>{remainingValue}%</PercentageValue>
            </SliderPercentage>
          </Container>
        </ContainerCustom>
        {
          selectedSequence === "email" && <><ContainerCustom>
          <InputLabel className={classes.messageTitle}>Tracking</InputLabel>
                <TrackingContainer>
                    <TrackingMessage />
                </TrackingContainer>
        </ContainerCustom></>
        }
      
    </div>
  );
}
export default withRouter(connect(null, authActions)(ABTestingCampaign));
