import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/UI/Button/Button';
import Text from '../../../components/_styles/Text';
import Flex from '../../../components/_styles/Flex';
import { Panel } from './styles';

function RadOnboarding(){
  const ImportCSVClick = useCallback(() =>  {
    window.analytics.track('ImportCSVClick');
  });
  // const ConnectHL7Click = useCallback(() =>  {
  //   window.analytics.track('ConnectHL7Click');
  // });

  return (
    <Panel p={30} minHeight={300}>
      <Flex textAlign='left' flexDirection='column' flex='1'>
        <Text fontSize='3' uppercase bright>Hi Rad Admin, Let's get you started using Within Health !!</Text>
          <Flex.Col flex='1'>
            <Text fontSize='2' bright>1 - Upload a CSV file in Settings  -> Integrations</Text>
            <Text fontSize='2' bright>2 - For up to date documentation see here:  https://within-health.readme.io/docs/getting-started</Text>
            <Text fontSize='2' bright>3 - Monitor the status of your upload in Settings -> Previous Uploads</Text>
            <Text fontSize='2' bright>4 - If you have any questions send us a note to support@seewithin.co</Text>
          </Flex.Col>
          <Flex.Col flex='1' m={20}>
            <Button success onClick={ImportCSVClick} as={Link} to={'/auth/radAdmin/Import'}> Get Started</Button>
          </Flex.Col>
      </Flex>
    </Panel>
  );
};

export default RadOnboarding;
