import API from '../../libs/api';
import { showAlert } from '../radminUploads'


export const GET_OUTBOUND_INTEGRATIONS_REQUEST = 'GET_OUTBOUND_INTEGRATIONS_REQUEST';
export const GET_OUTBOUND_INTEGRATIONS_SUCCESS = 'GET_OUTBOUND_INTEGRATIONS_SUCCESS';
export const GET_OUTBOUND_INTEGRATIONS_FAILURE = 'GET_OUTBOUND_INTEGRATIONS_FAILURE';


export const getOutboundIntegrations= (pageNumber=1) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_OUTBOUND_INTEGRATIONS_REQUEST, payload : pageNumber });

    let responseData;
    let totalItems;
   
    
    const { data } = await API.getRequest(
      `/api/v1/provider_integration/?page=${pageNumber}&page_size=100`
    );
    responseData = data.results.map(itm=>({...itm, remote_host: itm?.integration_details? JSON.parse((itm?.integration_details.replace(/'/g, '"')))?.remote_host : "", remote_port:  itm?.integration_details? JSON.parse((itm?.integration_details.replace(/'/g, '"')))?.remote_port  : ""}));
    totalItems = data.count;
    
    dispatch({
      type: GET_OUTBOUND_INTEGRATIONS_SUCCESS,
      payload: {
        items: responseData,
        totalItems: totalItems,
        next: responseData.next,
        pageNumber: pageNumber,
      },
    });
  } catch (error) {
    dispatch({ type: GET_OUTBOUND_INTEGRATIONS_FAILURE });
    dispatch(showAlert('danger', 'Error'));

  }
};

  

  export const handleSaveOutboundIntegration = (data) => async (dispatch) => {
    try {
      if(data?.id){
        await API.patchRequest(
          `/api/v1/provider_integration/${data.id}/`,  {
            "remote_host": data.remote_host,
            "remote_port": data.remote_port
        }
        );
        await dispatch(getOutboundIntegrations())
        dispatch(showAlert('success', 'Success', 'Outbound integration updated successfully!'));

      }else{
       await API.postRequest(
        `/api/v1/provider_integration/`, data
      );
      await dispatch(getOutboundIntegrations())

      dispatch(showAlert('success', 'Success', 'Outbound integration saved successfully!'));

       }
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };

  export const deleteOutboundIntegration = (id) => async (dispatch) => {
    try {
      if(id){
        await API.deleteRequest(
          `/api/v1/provider_integration/${id}/`
        );
       await dispatch(getOutboundIntegrations())
       dispatch(showAlert('success', 'Success', 'Outbound integration deleted successfully!'));
      }
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };