import { HIDE_UPLOAD_REMINDER } from '../actions/app';

const initialState = {
    uploadReminderShown: false
};

export default (state = initialState, action) => {
    switch (action.type) {
    case HIDE_UPLOAD_REMINDER: {
        return {
            ...state,
            uploadReminderShown: true
        };
    }
    default: 
        return state;
    }
};
