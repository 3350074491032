import styled from "styled-components";
import React, { useState, useEffect } from "react";
import Container from "../../UI/Container/Container";
import Grid from "../../UI/Grid/Grid";
import AppHeader from "../../UI/Layout/AppHeaderCheckout";
import ReactModal from "react-modal";
import Flex from "../../_styles/Flex";
import Button from "../../UI/Button/Button";
import API from "../../../libs/api";

const HeaderText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 33px;
  color: #0c2945;
  margin-left: 20px;
  text-shadow: 0px 0.5px, 0.5px 0px, 0.5px 0px;
  letter-spacing: 0.4px;
`;

const HeaderHolder = styled.div`
  display: flex;
`;

const SvgHolder = styled.div`
  height: 40px;
  width: 40px;
`;

export function Header({ imgSrc, text }) {
  return (
    <HeaderHolder>
      <SvgHolder>
        <img src={imgSrc} alt="header-img" />
      </SvgHolder>
      <HeaderText>{text}</HeaderText>
    </HeaderHolder>
  );
}

export const CheckoutNavigationHolder = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
`;

export const CheckoutNavigationButton = styled.button`
  padding: 12px 16px;
  background: ${(props) => (props.previous ? "none" : "#00AEEF")};
  padding-left: ${(props) => (props.previous ? "0px !important" : "none")};
  color: ${(props) => (props.previous ? "#3F4857;" : "white")};
  border-radius: 5px;
  border: ${(props) => (props.previous ? "none" : "#00AEEF")};
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  text-align: center;
  cursor: ${(props) => (props.disabled ? "" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.2" : "1")};
  outline: none !important;
`;

const InnerWrapper = styled.div`
  text-align: start;
  margin-top: 30px;
`;

const ContainedGrid = styled(Grid)`
  max-width: 95%;
  margin: 0 auto;
`;

export function SchedulingUIWrapper({ children }) {
  return (
    <div className="App animated fadeIn">
      <AppHeader />
      <InnerWrapper>
        <Container>
          <ContainedGrid>{children}</ContainedGrid>
        </Container>
      </InnerWrapper>
    </div>
  );
}

export const ExploreButton = styled.button`
  background: #ffc107;
  border-radius: 5px;
  padding: 16px 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin-top: ${(props) => (props.lessMargin ? "25px" : "50px")};
  cursor: pointer;
  max-width: 180px;
`;

const CheckoutSectionHeaderHolder = styled.div`
  display: flex;
`;

const ImgHolder = styled.img`
  height: 40px;
  width: auto;
`;

const TextHolder = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  max-width: 60%;
  margin-top: -5px;
`;

const HeadlineText = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #0c2945;
`;

const Subtext = styled.div`
  font-size: 14px;
  line-height: 150%;
  color: #3f4857;
  margin-top: 10px;
`;

export function CheckoutSectionHeader({ imgSrc, headlineText, subtext }) {
  return (
    <CheckoutSectionHeaderHolder>
      <ImgHolder src={imgSrc} />
      <TextHolder>
        <HeadlineText>{headlineText}</HeadlineText>
        <Subtext>{subtext}</Subtext>
      </TextHolder>
    </CheckoutSectionHeaderHolder>
  );
}

const ModalContent = styled.div`
  padding: 15px;
`;

const DescriptionText = styled.div`
  font-size: 0.778rem;
  line-height: 1rem;
  color: #8692a4;
  margin-top: 35px;
  white-space: pre-line;
`;

export function ShowMoreInfoModal({ showModal, handleShowModal, id }) {
  const [scanInfo, setScanInfo] = useState(null);

  useEffect(() => {
    const getScanInfo = async () => {
      const response = await API.getRequest(`/api/v1/scan/${id}/`);
      setScanInfo(response.data);
    };
    getScanInfo();
  }, [id, showModal]);

  return (
    <ReactModal isOpen={showModal} onRequestClose={handleShowModal}>
      <ModalContent>
        <DescriptionText>
          <p>
            {scanInfo ? scanInfo.description : "No description available"}
          </p>
        </DescriptionText>
        <Flex justifyContent="flex-end">
          <Button primary onClick={handleShowModal}>
            Close
          </Button>
        </Flex>
      </ModalContent>
    </ReactModal>
  );
}
