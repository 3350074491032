import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

import { ReactComponent as RemoveRecoIcon } from "../../../assets/FollowUpDeleteIcon.svg";
import { ReactComponent as RemoveRecoIconHover } from "../../../assets/delete-hover.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  getOutboundIntegrations,
  handleSaveOutboundIntegration,
  deleteOutboundIntegration,
} from "../../../actions/radminOutboundIntegrations";
import { useSelector } from "react-redux";
import RemoveConfirmation from "../Cohorts/RemoveConfirmation";
import { Spinner } from "react-bootstrap";

const Container = styled.div`
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  background: white;
  width: 717px;
  padding: 30px;
`;

const HeaderLabel = styled.h2`
  font-size: 28px;
  font-weight: 700;
  color: #0c2945;
  font-family: Roboto Condensed;
  margin-bottom: 12px;
`;

const SubHeaderLabel = styled.h4`
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 34px;
`;

const TableHeaderLabel = styled.th`
  font-size: 14px;
  font-weight: 700;
  color: #6d6e6e;
  font-family: Roboto Condensed;
`;

const IconContainer = styled.span`
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 20px;

  &:hover svg:not(.light) {
    display: none;
  }

  &:hover svg.light {
    display: block;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  svg.light {
    display: none;
  }
`;

const InputContainer = styled.input`
  border: 1px solid #c2ccd9;
  border-radius: 4px;
  height: 38px;
  padding: 10px;
  width: 200px;
  font-size: 14px;
  font-weight: 400px;
`;

const SerialNumber = styled.div`
  border: 1px solid #c2ccd9;
  border-radius: 4px;
  height: 38px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400px;
  color: #252733;
`;

const Table = styled.table`
  & tr {
    border-bottom: none;
  }
  & td,
  th {
    padding: 10px;
  }
`;

const AddMoreLabel = styled.span`
  color: #00aeef;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AddMoreRow = styled.div`
  display: flex;
  justify-content: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const useStyles = makeStyles({
  label: {
    padding: "5px 0px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  saveButton: {
    backgroundColor: "#00AEEF",
    textTransform: "none",
    width: "104px",
    height: "38px",
    color: "#FFFFFF",
    border: "2px solid #00AEEF",
    marginRight: "15px",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#00AEEF",
    },
  },
  downloadButton: {
    backgroundColor: "transparent",
    textTransform: "none",
    width: "233px",
    height: "38px",
    color: "#00AEEF",
    border: "2px solid #00AEEF",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#00AEEF",
    },
    "& a": {
      color: "#00AEEF",
      textDecoration: "none",
    },
  },
});

const OutboundIntegrations = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState({ id: "", index: "" });
  const [
    isLoadingDeleteOutboundIntegration,
    setIsLoadingDeleteOutboundIntegration,
  ] = useState(false);
  const [
    isLoadingSaveOutboundIntegration,
    setIsLoadingSaveOutboundIntegration,
  ] = useState(false);

  const outboundIntegrations = useSelector(
    (state) => state.radAdmin.outboundIntegrations
  );

  const [rows, setRows] = useState([]);

  useEffect(() => {
    dispatch(getOutboundIntegrations());
  }, []);

  useEffect(() => {
    if (outboundIntegrations.data) {
      setRows(outboundIntegrations.data);
    }
  }, [outboundIntegrations]);

  const handleAddRow = () => {
    if (rows.length === 0) {
      const newRow = {
        remote_host: "",
        remote_port: "",
      };
      setRows([newRow]);
    } else {
      const allFieldsFilled = rows.every(
        (row) => row.remote_host && row.remote_port
      );

      if (allFieldsFilled) {
        setRows((prevRows) => {
          const newRow = {
            remote_host: "",
            remote_port: "",
          };
          return [...prevRows, newRow];
        });
      } else {
        alert(
          "Please fill all fields in the existing rows before adding a new row."
        );
      }
    }
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleSave = (index) => {
    setIsLoadingSaveOutboundIntegration(true);
    setSelectedId({ id: "", index });
    dispatch(
      handleSaveOutboundIntegration({
        ...rows[index],
        integration_type: "HL7_OUTBOUND",
        remote_port: +rows[index]?.remote_port,
      })
    ).then((res) => {
      setIsLoadingSaveOutboundIntegration(false);
      setSelectedId({ id: "", index: "" });
    });
  };

  const handleDelete = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const deleteIntegrationHandler = () => {
    if (selectedId.id) {
      setIsLoadingDeleteOutboundIntegration(true);

      dispatch(deleteOutboundIntegration(selectedId.id)).then(() => {
        setIsLoadingDeleteOutboundIntegration(false);
        setSelectedId({ id: "", index: "" });
        setRemoveDialogOpen(false);
      });
    } else {
      handleDelete(selectedId.index);
      setSelectedId({ id: "", index: "" });
      setRemoveDialogOpen(false);
    }
  };

  const dialogHandler = (id, index) => {
    if (id) {
      setRemoveDialogOpen(!removeDialogOpen);
      setSelectedId({ id, index: "" });
    } else {
      setRemoveDialogOpen(!removeDialogOpen);
      setSelectedId({ id: "", index });
    }

    window.analytics.track("Outbound-Integrations-Delete-Button-Click");
  };

  return (
    <Row>
      <Container>
        <RemoveConfirmation
          content="Are you sure you want to remove this integration?"
          openDialog={removeDialogOpen}
          dialogHandler={dialogHandler}
          isLoading={isLoadingDeleteOutboundIntegration}
          deleteHandler={deleteIntegrationHandler}
          heading={"Remove Integration"}
          removeHeader={"Yes, Remove Integration"}
        />
        <HeaderLabel> Outbound Integrations</HeaderLabel>
        <SubHeaderLabel>
          This integration sends an outbound ORU HL7 message to the IP and port
          specified on the creation and update of every recommendation
        </SubHeaderLabel>
        {outboundIntegrations.isLoading && !outboundIntegrations.data ? (
          <LoadingContainer>
            <Spinner animation="border" role="status" size="sm" />
          </LoadingContainer>
        ) : (
          <>
            <Table>
              <thead>
                <tr>
                  <TableHeaderLabel>#</TableHeaderLabel>
                  <TableHeaderLabel>REMOTE HOST</TableHeaderLabel>
                  <TableHeaderLabel>REMOTE PORT</TableHeaderLabel>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => (
                  <tr key={row.id}>
                    <td>
                      <SerialNumber>{index + 1}</SerialNumber>
                    </td>
                    <td>
                      <InputContainer
                        type="text"
                        value={row.remote_host}
                        onChange={(e) =>
                          handleChange(index, "remote_host", e.target.value)
                        }
                        placeholder="Enter host value..."
                      />
                    </td>
                    <td>
                      <InputContainer
                        type="number"
                        value={row.remote_port}
                        onChange={(e) =>
                          handleChange(index, "remote_port", e.target.value)
                        }
                        placeholder="Enter port value..."
                      />
                    </td>
                    <td>
                      <Button
                        classes={{
                          label: classes.label,
                          root: classes.saveButton,
                        }}
                        onClick={() => handleSave(index)}
                      >
                        {isLoadingSaveOutboundIntegration &&
                        selectedId.index === index ? (
                          <Spinner
                            animation="border"
                            role="status"
                            size="sm"
                            style={{ margin: "0 10px" }}
                          />
                        ) : (
                          "Save"
                        )}
                      </Button>
                      <IconContainer
                        onClick={() => {
                          dialogHandler(row.id, index);
                        }}
                      >
                        <RemoveRecoIcon />
                        <RemoveRecoIconHover className="light" />
                      </IconContainer>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <AddMoreRow>
              <AddMoreLabel
                onClick={() => {
                  handleAddRow();
                }}
              >
                Add New Integration
              </AddMoreLabel>
            </AddMoreRow>
          </>
        )}
      </Container>

      <Button
        classes={{
          label: classes.label,
          root: classes.downloadButton,
        }}
      >
        <a href="/sample_outbound_hl7.txt" download>
          Download Sample HL7 Message
        </a>
      </Button>
    </Row>
  );
};

export default OutboundIntegrations;
