import { uniq } from 'lodash';

import API from '../libs/api';

const scoped = (name) => `PATIENT_APPOINTMENTS/${name}`;

export const GET_APPOINTMENTS_FETCH = scoped('GET_APPOINTMENTS_FETCH');
export const GET_APPOINTMENTS_RESOLVE = scoped('GET_APPOINTMENTS_RESOLVE');
export const GET_APPOINTMENTS_REJECT = scoped('GET_APPOINTMENTS_REJECT');

export const UPDATE_APPOINTMENT_FETCH = scoped('UPDATE_APPOINTMENT_FETCH');
export const UPDATE_APPOINTMENT_RESOLVE = scoped('UPDATE_APPOINTMENT_RESOLVE');
export const UPDATE_APPOINTMENT_REJECT = scoped('UPDATE_APPOINTMENT_REJECT');

export const GET_APPOINTMENT_PRESCRIPTION_FETCH = scoped('GET_APPOINTMENT_PRESCRIPTION_FETCH');
export const GET_APPOINTMENT_PRESCRIPTION_RESOLVE = scoped('GET_APPOINTMENT_PRESCRIPTION_RESOLVE');
export const GET_APPOINTMENT_PRESCRIPTION_REJECT = scoped('GET_APPOINTMENT_PRESCRIPTION_REJECT');

export const getAppointments = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_APPOINTMENTS_FETCH });

    const userId = getState().auth.user.id;

    const resAppointments = await API.getRequest(
      `/api/v1/appointment/?patient=${userId}&appointment_type=scan&scan_purchase__purchase_status=Succeeded`
    );

    const scans = uniq(resAppointments.data.results.map((r) => r.scan).filter(Boolean));
    const doctors = uniq(resAppointments.data.results.map((r) => r.doctor).filter(Boolean));
    const locations = uniq(resAppointments.data.results.map((r) => r.location).filter(Boolean));

    const [resScans, resDoctors, resLocations] = await Promise.all([
      Promise.all(scans.map((id) => API.getRequest(`/api/v1/scan/${id}/`))),
      Promise.all(doctors.map((id) => API.getRequest(`/api/v1/user/${id}/`))),
      Promise.all(locations.map((id) => API.getRequest(`/api/v1/location/${id}/`)))
    ]);

    const providers = uniq(resLocations.map((r) => r.data.provider));
    const resProviders = await Promise.all(providers.map((id) => API.getRequest(`/api/v1/provider/${id}/`)));

    const mapScans = resScans.reduce((acc, r) => ({ ...acc, [r.data.id]: r.data }), {});
    const mapDoctors = resDoctors.reduce((acc, r) => ({ ...acc, [r.data.id]: r.data }), {});
    const mapLocations = resLocations.reduce((acc, r) => ({ ...acc, [r.data.id]: r.data }), {});
    const mapProviders = resProviders.reduce((acc, r) => ({ ...acc, [r.data.id]: r.data }), {});

    const appointments = resAppointments.data.results.map((r) => ({
      ...r,
      scan: mapScans[r.scan],
      doctor: mapDoctors[r.doctor],
      location: mapLocations[r.location]
        ? {
            ...mapLocations[r.location],
            provider: mapProviders[mapLocations[r.location].provider]
          }
        : null
    }));

    dispatch({ type: GET_APPOINTMENTS_RESOLVE, payload: appointments });
  } catch (error) {
    console.error(error);
    dispatch({ type: GET_APPOINTMENTS_REJECT });
  }
};

export const updateAppointment = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_APPOINTMENT_FETCH });

    const res = await API.putRequest(`/api/v1/appointment/${id}/`, data);

    dispatch({ type: UPDATE_APPOINTMENT_RESOLVE, payload: res.data });
  } catch (error) {
    console.error(error);
    dispatch({ type: UPDATE_APPOINTMENT_REJECT });
  }
};

export const getAppointmentPrescription = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_APPOINTMENT_PRESCRIPTION_FETCH });

    const resPrescription = await API.getRequest(`/api/v1/prescription/?appointment=${id}`);
    const prescriptionId = resPrescription.data.results[0]?.id;

    const resFile = await (prescriptionId
      ? API.getRequest(`/api/v1/prescription/${prescriptionId}/get_signed_file/`, { responseType: 'blob' })
      : Promise.resolve({ data: null }));

    dispatch({ type: GET_APPOINTMENT_PRESCRIPTION_RESOLVE, payload: resFile.data });

    return resFile.data;
  } catch (error) {
    console.error(error);
    dispatch({ type: GET_APPOINTMENT_PRESCRIPTION_REJECT });
  }
};
