import React from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';

import PrivacyPolicyContent from './legal/PrivacyPolicyContent';
import TermsAndConditionsContent from './legal/TermsAndConditionsContent';
import ThirdPartyResearchContent from './legal/ThirdPartyResearchContent';
import Button from '../UI/Button/Button';
import Flex from '../_styles/Flex';

const content = {
  'terms-and-conditions': {
    Content: TermsAndConditionsContent,
    title: 'Terms And Conditions',
  },
  'privacy-policy': {
    Content: PrivacyPolicyContent,
    title: 'Privacy Policy',
  },
  'third-party-research-consent': {
    Content: ThirdPartyResearchContent,
    title: 'Third Party Research Consent',
  },
};

const ModalContent = styled.div`
  padding: 15px;
`;

const SuccessText = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #0c2945;
`;

const DescriptionText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #8692a4;
  margin-top: 35px;
`;

export default function Modal({ isOpen, closeModal, currentModal }) {
  const Content = content[currentModal]?.Content;

  return (
    <ReactModal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalContent>
        <SuccessText>{content[currentModal]?.title}</SuccessText>
        <DescriptionText>{currentModal && <Content />}</DescriptionText>
        <Flex justifyContent="flex-end">
          <Button primary onClick={closeModal}>
            Close
          </Button>
        </Flex>
      </ModalContent>
    </ReactModal>
  );
}
