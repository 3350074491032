import React from 'react';
import { useSelector } from 'react-redux';

const EnableFeature = (props) => {
  const {children, featureName, ...rest} = props;  
  const feature = useSelector(state => state.constants.featureFlags.find(({name}) => name === featureName));
  const Component = feature && feature.value ? React.cloneElement(children, {...rest}) : null;
  if(Component) {
    return (
      <>
        {Component}
      </>
    )
  }
  return null;
};

export default EnableFeature;
