import React from 'react'
import { Dialog, DialogContent } from '@material-ui/core';
import { DialogTitle } from '../../../components/Dialog/NewNote';
import styled from 'styled-components'

const Title = styled.p`
    margin: 0px;
    font-size: 20px;
    font-weight: bold;
    min-height: 30px; 
    height: auto;
`

function Modal({ isOpen, onClose, title, borderBottom, coponent }) {
    return (
        <Dialog
            open={isOpen}
            aria-labelledby="customized-dialog-title"
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="customized-dialog-title" onClose={onClose} style={{ borderBottom: borderBottom ? borderBottom : '1px solid black' }}>
                <Title>{title}</Title>
            </DialogTitle>
            <DialogContent>
                {coponent}
            </DialogContent>
        </Dialog>
    )
}

export default Modal
