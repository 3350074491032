import React, { useState } from 'react';
import moment from 'moment';

import { ReactComponent as TriangleIcon } from '../../../assets/triangle-small.svg';
import { ReactComponent as AcknowlegedIcon } from '../../../assets/statuses/acknowleged-icon.svg';
import { ReactComponent as EmailIcon } from '../../../assets/statuses/email-icon.svg';
import { ReactComponent as LocationIcon } from '../../../assets/statuses/location-icon.svg';
import { ReactComponent as NotNessesaryIcon } from '../../../assets/statuses/not-nessesary-icon.svg';
import { ReactComponent as ClosedIcon } from '../../../assets/statuses/closed-icon.svg';
import { ReactComponent as PacientDeceasedIcon } from '../../../assets/statuses/patient-deceased-icon.svg';
import { ReactComponent as SmsIcon } from '../../../assets/statuses/sms-icon.svg';
import { ReactComponent as ScheduleIcon } from '../../../assets/statuses/schedule-icon.svg';
import { ReactComponent as StatusIcon } from '../../../assets/statuses/status-icon.svg';
import { ReactComponent as UnsubscribedIcon } from '../../../assets/statuses/unsubscribed-icon.svg'
import styled from 'styled-components';

const MessageContainer = styled.div`

`

const RecoHistoryMarker = ({ styles, date, messageDate, status, isDatesTooClose, accessionNumber, initialExamDate, message }) => {
  const [hover, setHover] = useState(false);
  const [isShowMessage, setIsShowMessage] = useState(false)
  const isAdherent = status.toLowerCase().includes('adherent');

  const iconStyle = {zIndex: hover ? 110 : 102, margin: hover ? '0px 0 1px 0' : '0px 0 1px 0', width: 46}

  let recoStatusStyle;
  switch (status) {
    case 'acknowledged':
      recoStatusStyle = { bgColor: '#00AEEF', icon: <AcknowlegedIcon style={{ ...iconStyle}}/>, name: 'Acknowledged' };
      break;
    case 'adherent':
      recoStatusStyle = { bgColor: '#64C67C', icon: <StatusIcon style={{ ...iconStyle}}/>, name: 'Adherent' };
      break;
    case 'coming_due':
      recoStatusStyle = { bgColor: '#FFD57C', icon: <StatusIcon style={{ ...iconStyle}}/>, name: 'Coming Due' };
      break;
    case 'completed_elsewhere':
      recoStatusStyle = { bgColor: '#6F52ED', icon: <LocationIcon style={{ ...iconStyle}}/>, name: 'Completed Elsewhere' };
      break;
    case 'email_sent':
      recoStatusStyle = { bgColor: '#FF7A00', icon: <EmailIcon style={{ ...iconStyle}}/>, name: 'Email Sent' };
      break;
    case 'navigator_closed':
      recoStatusStyle = { bgColor: '#A7A9AD', icon: <ClosedIcon style={{ ...iconStyle}}/>, name: 'Navigator Closed' };
      break;
    case 'not_clinically_necessary':
      recoStatusStyle = { bgColor: '#FFD57C', icon: <NotNessesaryIcon style={{ ...iconStyle}}/>, name: 'Not Clinically Necessary' };
      break;
    case 'overdue':
      recoStatusStyle = { bgColor: '#F73B3B', icon: <StatusIcon style={{ ...iconStyle}}/>, name: 'Overdue' };
      break;
    case 'patient_deceased':
      recoStatusStyle = { bgColor: '#2E2F30', icon: <PacientDeceasedIcon style={{ ...iconStyle}}/>, name: 'Patient Deceased' };
      break;
    case 'sms_delivered':
      recoStatusStyle = { bgColor: '#64C67C', icon: <SmsIcon style={{ ...iconStyle}}/>, name: 'SMS Delivered' };
      break;
    case 'sms_received':
      recoStatusStyle = { bgColor: '#FF7A00', icon: <SmsIcon style={{ ...iconStyle}}/>, name: 'SMS Received' };
      break;
    case 'scheduled_not_completed':
      recoStatusStyle = { bgColor: '#FF7A00', icon: <ScheduleIcon style={{ ...iconStyle}}/>, name: 'Scheduled Not Complete' };
      break;
    case 'unsubscribed':
      recoStatusStyle = { bgColor: '#F73B3B', icon: <UnsubscribedIcon style={{ ...iconStyle}}/>, name: 'Unsubscribed' };
      break;
    default:
      recoStatusStyle = { bgColor: '#00AEEF', icon: <StatusIcon style={{ ...iconStyle}}/>, name: 'Status Changed' };
  }

  return (
    <div className='container'>
      <div className='dot'
        style={{
          ...styles,
          zIndex: 103,
          top: 60,
          marginLeft: -2,
          width: 5,
          backgroundColor: '#C2CCD9',
          height: 5,
          borderRadius: '50%',
        }}
      />
      <div className='line-to-time'
        style={{
          ...styles,
          zIndex: 100,
          width: 1,
          backgroundColor: '#DFE0EB',
          height: 125,
        }}
      />
      <div className='line-to-marker'
        style={{
          ...styles,
          zIndex: 103,
          top: isDatesTooClose ? 65 : 40,
          width: 18,
          height: 20,
          borderRadius: isDatesTooClose ? '0 0 6px 6px' : '6px 6px 0 0',
          backgroundColor: 'transparent',
          borderTop: isDatesTooClose ? 'none' : '1px solid #C2CCD9',
          borderRight: 'none',
          borderBottom: isDatesTooClose ? '1px solid #C2CCD9' : 'none',
          borderLeft: '1px solid #C2CCD9',
        }}
      />
      <div className='marker'
        style={{
          ...styles,
          zIndex: hover ? 110 : 105,
          top: isDatesTooClose ? 72 : 30,
          display: 'flex',
          flexDirection: 'column',
          alignItems: (isAdherent && hover) ? 'flex-start' : 'center',
          // justifyContent: 'center',
          width: 'auto',
          height: (isAdherent && hover) ? 70 :( isShowMessage && (status === 'sms_delivered' || status === 'sms_received'))? 124 : 25,
          borderRadius: 12,
          backgroundColor: recoStatusStyle.bgColor,
          marginLeft: 15,
          cursor: 'pointer',
          pointerEvents: 'auto',
          fontWeight: 600,
          fontSize: '12px',
          fontFamily: 'Roboto Condensed',
          color: '#FFFFFF',
        }}
        onMouseEnter={() => {
          setHover(true);
          setIsShowMessage(true);
        }}
        onMouseLeave={() => {
          setHover(false);
          setIsShowMessage(false)
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 3 }} onClick={status === 'sms_delivered' || status === 'sms_received' ? () => setIsShowMessage(true): null}>
          {!(isAdherent && hover) && <span>{recoStatusStyle.icon}</span>}
          <span style={{
            zIndex: hover ? 110 : 102,
            display: hover ? 'flex' : 'none',
            margin: (isAdherent && hover) ? '0 0 2px 10px' : '0 15px 0 0',
            whiteSpace: 'nowrap'
          }}>
          {recoStatusStyle.name}
        </span>
      </div>
      { isShowMessage && (status === 'sms_delivered' || status === 'sms_received') &&
      <MessageContainer style={{margin: '0px 10px', marginTop: 3,minWidth:300, height: 500, maxHeight: 500, width: 'auto',overflowY:'auto' }}>
        <div style={{ overflow: 'auto', borderTop: '0.5px solid rgba(255, 255, 255, 0.5)', borderBottom: '0.5px solid rgba(255, 255, 255, 0.5)', marginTop: 3 }}>
          <p style={{textAlign: 'left', fontSize: 12}}>{message && message}</p>
        </div>
        <p style={{textAlign: 'right', paddingLeft: 5}}>{moment(messageDate).format('MM.DD.YYYY')}</p>
      </MessageContainer>
      }
        {isAdherent && hover && <div style={{display: 'flex' , flexDirection: 'column', alignItems: 'flex-start', fontWeight: 400, fontSize: '10px', minWidth: 165, lineHeight: '16px'}}>
          <div style={{ borderTop: '0.5px solid rgba(255, 255, 255, 0.5)',  width: 150, margin: '0 auto'}}></div>
          <div style={{ marginLeft: 10 }}>{moment(initialExamDate).format('MM.DD.YYYY')}</div>
          <div style={{ marginLeft: 10, whiteSpace: 'nowrap', marginRight:10 }}>Accession Number - {accessionNumber}</div>
        </div>}
      </div>
      <div className='time-marker'
        style={{
          ...styles,
          zIndex: 100,
          top: 126,
          color: '#9FA2B4',
          backgroundColor: 'transparent',
          fontFamily: 'Roboto Condensed',
          fontWeight: 600,
          fontSize: '8px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <TriangleIcon
          style={{
            top: 0,
            position: 'absolute',
          }}
        />
        <span>{moment(date).format('MM.DD')}</span>
      </div>
    </div>
  );
};

export default RecoHistoryMarker;
