import React from "react";
import MainSectionForms from "./MainSectionForms";

function MainSection() {
  return (
    <div className="twelve-cols">
      <MainSectionForms />
    </div>
  );
}

export default MainSection;
