import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { media } from '../../../theme';

const Image = styled.img`
  height: ${(props) => props.height}em;
  display: inline !important;

  ${media.md`
    margin: ${(props) => props.margin}px;
  `}

  @media (max-width: 991px) {
    max-width: inherit;
  }
`;

const CSVUpload = ({ height, margin }) => {
  const renderCSVUpload = `${process.env.PUBLIC_URL}/${'CSV-Upload.png'}`;

  return (
    <Image
      id="CSVUpload"
      src={renderCSVUpload}
      alt="Within Health CSVUpload"
      height={height}
      margin={margin}
    />
  );
};

CSVUpload.propTypes = {
  onWhite: PropTypes.bool,
  height: PropTypes.number,
  margin: PropTypes.number,
  display: PropTypes.string,
};

CSVUpload.defaultProps = {
  onWhite: true,
  height: 10,
  margin: 2,
};

export default CSVUpload;
