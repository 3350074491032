import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Spinner } from 'react-bootstrap';
import { Divider, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const cancelOutline = `${process.env.PUBLIC_URL}/${"cancel-outline.svg"}`;


const useStyles = makeStyles({
    dialogContent: {
        fontSize: "14px",
        fontWeight: 400
    },
    title: {
        fontSize: "24px",
        marginBottom: "0px",
        fontWeight: 700,
        fontFamily: "Roboto Condensed",
        color:'#0C2945'
    },
    actionSection: {
        display: "flex",
        gap:'30px',
        paddingBottom: "40px",
        paddingLeft:'24px',
        paddingRight:'24px'

    },
    label: {
        padding: "0px 10px",
        fontSize: "12px",
        fontWeight: "bold"
    },
    rootButton: {
        color: "#FFFFFF",
        height: '38px',
        textTransform: "none",
        background: "#CF3131",
        border: "2px solid #CF3131",
        "&:hover": {
            backgroundColor: "transparent",
            color:'#CF3131'
          },
    },
    rootCancelButton: {
        height: '38px',
        textTransform: "none",
        background: "transparent",
        border: "2px solid #CFCFCF",
        "&:hover": {
            backgroundColor: "#CFCFCF",
            color:'#FFFFFF'
          },
    },
    divider: {
        outerHeight: "5px",
        innerHeight: "5px",
        backgroundColor: "blue",
        width: "100%"
    }
})

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: '20px',
        top: '25px',
        color: theme.palette.grey[500],
        cursor:'pointer'
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle style={{paddingLeft:'24px', paddingRight:'24px'}} disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
              
                  <img src={cancelOutline} className={classes.closeButton}  onClick={onClose} />
               
            ) : null}
        </MuiDialogTitle>
    );
});

export default function RemoveConfirmation({ openDialog, dialogHandler, selectedData, content, isLoading, deleteHandler, heading, removeHeader="Yes, Remove Recommendation" }) {
    const classes = useStyles();

    return (
        <div>
            <Dialog
                open={openDialog}
                aria-labelledby="customized-dialog-title"
                // maxWidth={20}
            >
                <DialogTitle id="customized-dialog-title" onClose={dialogHandler} >
                    <p className={classes.title}>{heading || 'Remove Recommendation'}</p>
                  
                </DialogTitle>
                <DialogContent>
                    <p className={classes.dialogContent}>{content}</p>
                </DialogContent>
                <div className={classes.actionSection}>
                <Button
                        classes={{ label: classes.label, root: classes.rootCancelButton }}
                        onClick={dialogHandler}
                        >
                        No, Keep It
                    </Button>
                    <Button
                        classes={{ label: classes.label, root: classes.rootButton }}
                        onClick={deleteHandler}
                       
                    >
                        {isLoading ? <Spinner animation="border" role="status" size="sm" style={{ margin: '0 10px' }} /> : removeHeader }
                    </Button>
                  
                </div>
            </Dialog>
        </div>
    );
}