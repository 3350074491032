import React from 'react';
import { connect, useSelector } from 'react-redux';
import {
  SubsectionHeader,
  SectionHolder,
  CheckboxOptionItem,
  CheckboxOptionHolder,
} from './common';
import * as checkoutActions from '../../../actions/checkout';

const OPTIONS = [
  'None',
  'Pacemaker',
  'Aneurysm Clips',
  'Joint Replacement',
  'Cochlear ear implants',
  'Body Piercings',
  'Implantable cardioverter defribiliators (ICDs)',
];

function Metal({toggleMetalItem}) {
  const metalItems = useSelector(state=>state.checkout.metalItems);
  return (
    <SectionHolder>
      <SubsectionHeader>Do you have any metal in your body?</SubsectionHeader>
      <CheckboxOptionHolder>
        {OPTIONS.map((opt, i) => {
          return (
            <CheckboxOptionItem
              toggleFunction={toggleMetalItem}
              text={opt}
              key={`Metal-${i}`}
              longText={i === OPTIONS.length - 1}
              checked={metalItems[opt]}
            />
          );
        })}
      </CheckboxOptionHolder>
    </SectionHolder>
  );
}

export default connect(null, checkoutActions)(Metal);
