import React from 'react';

const ProgressIcon = () => (
  <svg
    width="180"
    height="34"
    viewBox="0 0 235 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M235 17.5C235 18.3284 234.328 19 233.5 19L1.49999 19C0.671568 19 -2.03556e-07 18.3284 -1.31133e-07 17.5C-5.87104e-08 16.6716 0.67157 16 1.49999 16L233.5 16C234.328 16 235 16.6716 235 17.5Z"
      fill="url(#paint0_radial)"
    />
    <circle
      cx="118"
      cy="17"
      r="16"
      fill="white"
      stroke="#00AEEF"
      strokeWidth="2"
    />
    <circle cx="118" cy="17" r="3" fill="#00AEEF" />
  </svg>
);

export default ProgressIcon;
