import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Panel } from '../../Dashboard/styles';
import MultiStepTracker, { Step } from '../../Dashboard/MultiStepTracker';

import { ReactComponent as Status1 } from '../../../assets/patient-status-1.svg';
import { ReactComponent as Status2 } from '../../../assets/patient-status-2.svg';
import { ReactComponent as Status3 } from '../../../assets/patient-status-3.svg';
import { ReactComponent as Status4 } from '../../../assets/patient-status-4.svg';
import { ReactComponent as Status5 } from '../../../assets/patient-status-5.svg';
import { ReactComponent as Status6 } from '../../../assets/patient-status-6.svg';

const STEPS = [
  {
    id: 1,
    label: 'Medical History',
    tooltipContent: 'HX needs a few extra data point',
    icon: Status1
  },
  {
    id: 2,
    label: 'Telemedicine Consult',
    tooltipContent: 'Your consult is coming up',
    icon: Status2
  },
  {
    id: 3,
    label: 'Prescription',
    tooltipContent: 'The patient didn`t get your recommended scan yet',
    icon: Status3
  },
  {
    id: 4,
    label: 'Scan Scheduled',
    tooltipContent: 'The patient`s scan is all booked',
    icon: Status4
  },
  {
    id: 5,
    label: 'Pending Results',
    tooltipContent: 'We are waiting for the scan results',
    icon: Status5
  },
  {
    id: 6,
    label: 'Results are In',
    tooltipContent: 'The report is available now',
    icon: Status6
  }
];

const calculateCurrentStep = (profile) => {
  if (!(profile.healthHistory?.medical_conditions.length && profile.appointments.prescan?.symptoms.length)) {
    return STEPS[0];
  }

  if (moment().isBefore(profile.appointments.prescan.date_time)) {
    return STEPS[1];
  }

  if (!profile.prescriptions.length) {
    return STEPS[2];
  }

  if (moment().isBefore(profile.appointments.scan.date_time)) {
    return STEPS[3];
  }

  if (!profile.report) {
    return STEPS[4];
  }

  return STEPS[5];
};

const Progress = () => {
  const profile = useSelector((state) => state.patientProfile);

  return (
    <Panel p={30} nomargin>
      <MultiStepTracker currentStep={calculateCurrentStep(profile)}>
        {STEPS.map((s, i) => (
          <Step key={s.id} label={s.label} IconComponent={s.icon} last={i === STEPS.length - 1} />
        ))}
      </MultiStepTracker>
    </Panel>
  );
};

export default Progress;
