import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import PDFViewer from '../PatientProfile/components/PDFViewer';
import Appointment from './components/Appointment';
import EditAppointment from './components/EditAppointment';
import { Button, ButtonGroup } from './components/Common';
import { getAppointments, getAppointmentPrescription } from '../../actions/patientAppointments';

const Container = styled.div`
  padding: 30px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  ${Button} {
    min-width: 180px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Title = styled.div`
  display: none;
  margin-bottom: 30px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  color: #000000;

  @media (max-width: 768px) {
    display: block;
  }
`;

const ButtonNewScan = styled.button.attrs({ type: 'button' })`
  width: 100%;
  min-height: 150px;
  padding: 0;
  border-radius: 10px;
  border: 1px dashed #cecece;
  background: #fffff;
  outline: none !important;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000000;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const PlusIcon = styled.div.attrs({ children: '+' })`
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  background: #00AEEF;
  margin-top: 25%;
  user-select: none;
  font-size: 24px;
`;

const PatientAppointments = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const upcomingAppts = useSelector((state) =>
    state.patientAppointments.items.filter((i) => moment().isBefore(i.date_time))
  );
  const pastAppts = useSelector((state) =>
    state.patientAppointments.items.filter((i) => moment().isAfter(i.date_time))
  );

  const [view, setView] = useState('VIEW_UPCOMING');
  const [editAppt, setEditAppt] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);

  const onClickViewButton = useCallback((event) => {
    setView(event.target.dataset.view);
  }, []);

  const onClickNewScan = useCallback(() => {
    history.push('/plans');
  }, [history]);

  const onClickEditAppt = useCallback((appt) => {
    setEditAppt(appt);
    setView('VIEW_EDIT');
  }, []);

  const onClickViewPrescription = useCallback(async (appt) => {
    const file = await dispatch(getAppointmentPrescription(appt.id));

    if (file) {
      setPdfFile(file);
      setView('VIEW_PRESCRIPTION');
    }
  }, []);

  const onSubmitEditAppt = useCallback(() => {
    setView('VIEW_UPCOMING');
  }, []);

  useEffect(() => {
    dispatch(getAppointments());
  }, []);

  return (
    <Container>
      <Title children='Appointments' />

      <Header>
        <ButtonGroup>
          <Button
            onClick={onClickViewButton}
            mode={view === 'VIEW_UPCOMING' ? 'primary' : 'tertiary'}
            children='Upcoming Appointments'
            data-view='VIEW_UPCOMING'
          />
          <Button
            onClick={onClickViewButton}
            mode={view === 'VIEW_PAST' ? 'primary' : 'tertiary'}
            children='Past Appointments'
            data-view='VIEW_PAST'
          />
        </ButtonGroup>
        <Button onClick={onClickNewScan} mode='primary' children='Schedule new scan' />
      </Header>


      {view === 'VIEW_UPCOMING' && (
        <>
          {upcomingAppts.map((appt) => (
            <Appointment
              key={appt.id}
              appointment={appt}
              onViewPrescription={onClickViewPrescription}
              onEdit={onClickEditAppt}
            />
          ))}

        </>
      )}

      {view === 'VIEW_PAST' && (
        <>
          {pastAppts.map((appt) => (
            <Appointment key={appt.id} appointment={appt} onViewPrescription={onClickViewPrescription} />
          ))}
        </>
      )}

      {view === 'VIEW_EDIT' && <EditAppointment appointment={editAppt} onSubmit={onSubmitEditAppt} />}

      {view === 'VIEW_PRESCRIPTION' && <PDFViewer src={pdfFile} createLocalURL />}

      <ButtonNewScan onClick={onClickNewScan}>
        <PlusIcon /> Schedule a new scan
      </ButtonNewScan>
    </Container>
  );
};

export default PatientAppointments;
