import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

import Flex from '../../../../components/_styles/Flex';
import PatientInfoCard from './PatientInfoCard';

const SpinnerContainer = styled(Flex)`
  justify-content: center;
  margin: 30px 0;
  align-items: center;
`;

const NoDataContainer = styled(Flex)`
  justify-content: center;
  padding: 15px 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 24px 0 20px;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 61px;
  padding: 0 0 25px 20px;
  font-family: Roboto Condensed;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #9fa2b4;
`;

const TableHeaderItem = styled.div`
  flex: ${props => (props.flex ? props.flex : 1)};
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
`;

const PatientTable = ({ patientsData, isLoading, resetDataAction, noDataRender, needMoreDetails }) => {
  const NoDataRender = noDataRender;
  const dispatch = useDispatch();

  useEffect(() => {
    if (resetDataAction) return () => dispatch(resetDataAction());
  }, [dispatch, resetDataAction]);

  if (!patientsData?.length) return null;
  return (
    <div>
      <Table>
        <TableHeader>
          <TableHeaderItem flex={2} textAlign="left">Patient</TableHeaderItem>
          <TableHeaderItem>Last Scan</TableHeaderItem>
          <TableHeaderItem>Accession #</TableHeaderItem>
          <TableHeaderItem>Count of Reports</TableHeaderItem>
          <TableHeaderItem flex={0.5}></TableHeaderItem>
        </TableHeader>
        {!isLoading &&
          patientsData &&
          patientsData.map((patient, id) => (
            <PatientInfoCard key={id} patientItem={patient} needMoreDetails={needMoreDetails} />
          ))}
      </Table>
      {!isLoading &&
        patientsData &&
        patientsData.length === 0 &&
        (noDataRender ? <NoDataRender /> : <NoDataContainer>No data</NoDataContainer>)}
      {isLoading && (
        <SpinnerContainer>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </SpinnerContainer>
      )}
    </div>
  );
};

export default PatientTable;
