import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
  * {
    outline: none;
  }
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    line-height: 1.428571429;
  }
  body {
    margin: 0;
    /* font-family: Roboto Condensed !important; */
  }
  article,
  aside,
  footer,
  header,
  nav,
  section {
    display: block;
  }
  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  figcaption,
  figure,
  main {
    display: block;
  }
  figure {
    margin: 1em 40px;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  pre {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
  }
  b,
  strong {
    font-weight: inherit;
  }
  b,
  strong {
    font-weight: bolder;
  }
  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  dfn {
    font-style: italic;
  }
  mark {
    background-color: #ff0;
    color: #000;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  audio,
  video {
    display: inline-block;
  }
  audio:not([controls]) {
    display: none;
    height: 0;
  }
  img {
    border-style: none;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }
  legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
  }
  progress {
    display: inline-block;
    vertical-align: baseline;
  }
  textarea {
    overflow: auto;
  }
  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  details,
  menu {
    display: block;
  }
  summary {
    display: list-item;
  }
  canvas {
    display: inline-block;
  }
  template {
    display: none;
  }
  [hidden] {
    display: none;
  }
  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  body {
    background: #fff;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  hr {
    border: 0;
    display: block;
    height: 1px;
    background: #e3e7eb;
    background: linear-gradient(
      to right,
      rgba(227, 231, 235, 0.1) 0,
      rgba(227, 231, 235, 0.6) 50%,
      rgba(227, 231, 235, 0.1) 100%
    );
    margin-top: 24px;
    margin-bottom: 24px;
  }
  ul,
  ol {
    margin-top: 0;
    margin-bottom: 24px;
    padding-left: 24px;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  li > ul,
  li > ol {
    margin-bottom: 0;
  }
  dl {
    margin-top: 0;
    margin-bottom: 24px;
  }
  dt {
    font-weight: 700;
  }
  dd {
    margin-left: 24px;
    margin-bottom: 24px;
  }
  img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
  }
  figure {
    margin: 24px 0;
  }
  figcaption {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 0;
  }
  img {
    display: block;
  }
  table {
    border-collapse: collapse;
    margin-bottom: 24px;
    width: 100%;
  }
  tr {
    border-bottom: 1px solid #e3e7eb;
  }
  th {
    text-align: left;
  }
  th,
  td {
    padding: 10px 16px;
  }
  th:first-child,
  td:first-child {
    padding-left: 0;
  }
  th:last-child,
  td:last-child {
    padding-right: 0;
  }
  html {
    font-size: 18px;
  }
  @media (min-width: 641px) {
    html {
      font-size: 20px;
      letter-spacing: -0.1px;
    }
  }
  body {
    color: #5b6f82;
    font-size: 1rem;
  }
  body,
  button,
  input,
  select,
  textarea {
    font-family: "Open Sans", sans-serif !important;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  a:hover,
  a:active {
    outline: 0;
    text-decoration: none;
  }
  .form-group {
    margin-bottom: 15px;
  }

  .alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
  }

  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
  .dropdown-menu {
    font-size: 14px !important;
    z-index: 10000 !important;
  }

  .btn-outline-custom-light {
    color: #6c757d !important;
    border-color: #ced5df !important;
  }

  .btn-outline-primary-custom {
    color: #00AEEF !important;
    border: 2px solid #00AEEF !important;
  }

  .avatar {
    height: 40px;
  }

  .alert {
    font-size: 14px;
  }
`;

export default GlobalStyles;
