import React, { useCallback, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Button from '../../components/UI/Button/Button';
import Text from '../../components/_styles/Text';
import Box from '../../components/_styles/Box';
import Flex from '../../components/_styles/Flex';
import { Panel, ReportsHead, ReportsBody } from './styles';

const TableRow = ({ path, title, date, location }) => {
  const onClickLink = useCallback(() => {
    window.analytics.track('Reports Table Click Report');
  }, []);

  return (
    <Link to={path} onClick={onClickLink}>
      <Flex alignItems='center'>
        <Flex.Col flex='1' children={title} />
        <Flex.Col flex='1' children={moment(date).format('MM/DD/YYYY')} />
        <Flex.Col flex='1' children={location} />
      </Flex>
    </Link>
  );
};

const ReportsTable = ({ reports }) => {
  return (
    <Fragment>
      <ReportsHead>
        <Flex.Col flex='1' children='Scan Type' />
        <Flex.Col flex='1' children='Date' />
        <Flex.Col flex='1' children='Location' />
      </ReportsHead>
      <ReportsBody>
        {reports.length ? (
          reports.map((report) => (
            <TableRow
              path='/auth/reports'
              title={report.scan}
              date={report.date}
              location={`${report.office_location.provider.name} ${report.office_location.title}`}
            />
          ))
        ) : (
          <TableRow path='/auth/reports/1' title='Sample Report' date={new Date()} location='New York, NY' />
        )}
      </ReportsBody>
      {Boolean(reports.length) && (
        <Box textAlign='center'>
          <Button as={Link} children='Show All Reports' size='xl' variant='link' to='/auth/reports/' />
        </Box>
      )}
    </Fragment>
  );
};

const ReportsPanel = () => {
  const reports = useSelector((state) => state.reports.items.slice(0, 3));

  return (
    <Panel p={20} pb={0} minHeight={300}>
      <Text children='Radiology Reports' mb={15} uppercase />
      <ReportsTable reports={reports} />
    </Panel>
  );
};

export default ReportsPanel;
