import React from 'react';
import { connect, useSelector } from 'react-redux';
import {
  SubsectionHeader,
  SectionHolder,
  RadioOptionItem,
  RadioOptionsHolder,
} from './common';
import * as checkoutActions from '../../../actions/checkout';

const OPTIONS = ['Yes', 'None', 'Not Sure'];

function Allergies({ toggleAllergyOption}) {
  const onChange = (e) => {
    toggleAllergyOption(e.target.value);
  };
  const allergyOption = useSelector(state=>state.checkout.allergyOption);

  return (
    <SectionHolder>
      <SubsectionHeader>Do you have any medication allergies?</SubsectionHeader>
      <RadioOptionsHolder>
        {OPTIONS.map((opt, i) => {
          return (
            <RadioOptionItem
              key={`Allergies-${i}`}
              id={`allergies${i}`}
              name="allergies"
              text={opt}
              onChange={onChange}
              checked={opt === allergyOption}
            />
          );
        })}
      </RadioOptionsHolder>
    </SectionHolder>
  );
}

export default connect(null, checkoutActions)(Allergies);
