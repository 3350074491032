import React, { useState, useEffect, useCallback } from 'react';
import { Card } from './ImagingAdherenceChart';
import { Title } from './RecomendationModalities';
import styled from "styled-components";
import { Grid } from '@material-ui/core';
import Flex from '../../../components/_styles/Flex';
import { FollowUpListFilter, DEFAULT_VALUES } from '../../RadAdmin/FollowUpList/FollowUpListFilter';
import { filterTypes, restStatusesObj } from '../../RadAdmin/FollowUpList/FollowUpList';
import {
    getTotalStatusesAction,
    getModalitiesListAction,
    getAnatomiesListAction,
    getTimeframeStatusesListAction,
    getRadiologistsListAction,
    getAdherenceOfToday,
    getRadiologistForDropdown
} from '../../../actions/radminUploads'
import { RedPerson, GreenPerson } from './icons';
import { useSelector, useDispatch } from 'react-redux';
import close from '../../../assets/cancel-outline-chip.svg';
import { SelectedItemContainer, SelectedItem, CloseSign, Image, getRadiologistNameById,getReferrerNameById } from '../FollowUpList/FollowUpList'
import { FilterButton, FilterContainer } from './Filter';
import filterOutline from "../../../assets/IO-icons/filter-outline.svg";


const Header = styled(Grid)`
  justify-content: space-between;
  display: flex;
  min-height: 60px;
  align-items: center;
  margin-top: 10px;
`;

const FilterCard = styled(Flex)`
  justify-content: space-around;
  flex-wrap:wrap;
  gap:10px;
`;
export const PercentageText = styled.p`
  font-size: 50px;
  font-family: Roboto Condensed;
  font-style: normal;
  color: #0C2945;
  font-weight: 400;
  text-align: center;
  margin: 0px
`;

export const Text = styled.p`
  font-size: 16px;
  color: #000000;
  font-style: normal;
  font-weight: bold;
  font-family: Roboto Condensed;
  text-align: center;
  margin-bottom: 8px
`;

export const BottomSection = styled.div`
  padding-bottom: 30px
`;

export const ShowPerson = styled.div`
  width: 234px;
  margin: 0 auto;
  padding-left: 8px
`;
export const IconContainer = styled.span`
  margin: 0 4px
`;

function AdherenceOfToday() {
    const [filterBy, setFilterBy] = useState([]);
    const dispatch = useDispatch();
    const [filterValuesForRequest, setFilterValuesForRequest] = useState(DEFAULT_VALUES);
    const [searchValue, setSearchValue] = useState(null);
    const statuses = useSelector(state => state.radAdmin.followUpList.adherenceOfToday?.data)
   
    const [pageNumber, setPageNumber] = useState(1);
    const [activeStatus, setActiveStatus] = useState('Coming Due');
    const [modalityOperator, setModalityOperator] = useState({ value: "none", label: "none", category: "modality" });
    const [anatomyOperator, setAnatomyOperator] = useState({ value: "none", label: "none", category: "anatomy" });
    const [reportModalityOperator, setReportModalityOperator] = useState({ value: "none", label: "none", category: "report_modality" });
    const [examCodeOperator, setExamCodeOperator] = useState({ value: "none", label: "none", category: "exam_code" })
    const [filterOpen, setFilterOpen] = useState(false);

    useEffect(() => {
        dispatch(getTotalStatusesAction({...filterValuesForRequest, modalityOperator, anatomyOperator,reportModalityOperator}, searchValue))
    }, [modalityOperator, anatomyOperator, reportModalityOperator])

    useEffect(() => {
        dispatch(getRadiologistForDropdown())
    }, [dispatch])

    const resolveStatusNameForReq = useCallback((status, isDefaultFilterState = false) => {
        if (isDefaultFilterState && status.indexOf('Needs') !== -1) {
            return restStatusesObj;
        }
        return status.indexOf('Adherent') !== -1
            ? 'adherent'
            : status.indexOf('Needs') !== -1
                ? restStatusesObj || 'empty'
                : status.replace('-', '_');
    }, []);


    useEffect(() => {
        const statusName = resolveStatusNameForReq(activeStatus);
        dispatch(getRadiologistsListAction(statusName));
        dispatch(getModalitiesListAction(statusName));
        dispatch(getAnatomiesListAction(statusName));
        dispatch(getTimeframeStatusesListAction(statusName));
        let filterValuesForRequestData = {...filterValuesForRequest} 
        for(let key in filterValuesForRequest){
            if(filterValuesForRequestData[key] && !Array.isArray(filterValuesForRequestData[key])){
                filterValuesForRequestData[key] = [filterValuesForRequestData[key]]
            }
        }

        dispatch(getAdherenceOfToday({...filterValuesForRequestData, modalityOperator, anatomyOperator,reportModalityOperator, examCodeOperator}, searchValue))
    }, [filterValuesForRequest, searchValue, dispatch, modalityOperator, anatomyOperator, reportModalityOperator, examCodeOperator])

    const closeSelected = (category,selectedItem) => {
        setPageNumber(1);
        let filterValuesForRequestData = {...filterValuesForRequest} 
        for(let key in filterValuesForRequestData){
            if(filterValuesForRequestData[key] && !Array.isArray(filterValuesForRequestData[key])){
                filterValuesForRequestData[key] = [filterValuesForRequestData[key]]
            }
        }
        
    const filters = filterValuesForRequestData[category].filter(item => {return item !== selectedItem});
    setFilterValuesForRequest({ ...filterValuesForRequestData, [category]: filters.length>0?filters:"" });
    };

    const getPersonToShow = () => {
        if (statuses) {
            var adherent = statuses.find(x => x.status_category === "adherent");
            var non_adherent = statuses.find(x => x.status_category === "non_adherent");
        }
        let personIconsArray = []
        let countOfAdherent = adherent?.id__count || 0
        let countOfNonAdherent = non_adherent?.id__count || 0
        let dividedBy = (countOfAdherent + countOfNonAdherent ) ;
        let number = dividedBy === 0? 0 : (countOfAdherent / dividedBy) * 100;

        number = Number((number).toFixed());

        let numberToShow = dividedBy === 0? 0 : (countOfAdherent / dividedBy) * 100;

        for (let i = 0; i < 10; i++) {
            if (number >= 5 && number <= 14 && i === 0) {
                personIconsArray.push(<GreenPerson />)
            } else if (number >= 15 && number <= 24 && i <= 1) {
                personIconsArray.push(<GreenPerson />)
            } else if (number >= 25 && number <= 34 && i <= 2) {
                personIconsArray.push(<GreenPerson />)
            } else if (number >= 35 && number <= 44 && i <= 3) {
                personIconsArray.push(<GreenPerson />)
            } else if (number >= 45 && number <= 54 && i <= 4) {
                personIconsArray.push(<GreenPerson />)
            } else if (number >= 55 && number <= 64 && i <= 5) {
                personIconsArray.push(<GreenPerson />)
            }
            else if (number >= 65 && number <= 74 && i <= 6) {
                personIconsArray.push(<GreenPerson />)
            }
            else if (number >= 75 && number <= 84 && i <= 7) {
                personIconsArray.push(<GreenPerson />)
            }
            else if (number >= 85 && number <= 94 && i <= 8) {
                personIconsArray.push(<GreenPerson />)
            }
            else if (number >= 95 && i <= 9) {
                personIconsArray.push(<GreenPerson />)
            } else {
                personIconsArray.push(<RedPerson />)
            }
        }
        return {
            personIconsArray,
            numberToShow: numberToShow || 0
        }
    }

    const handleOperatorsValue = (madality, anatomy,reportModality,examCodeOperator) => {
        setModalityOperator(madality)
        setAnatomyOperator(anatomy )
        setReportModalityOperator(reportModality)
        setExamCodeOperator(examCodeOperator)
    };

    let filterValuesForRequestData = {...filterValuesForRequest} 
    for(let key in filterValuesForRequestData){
        if(filterValuesForRequestData[key] && !Array.isArray(filterValuesForRequestData[key])){
            filterValuesForRequestData[key] = [filterValuesForRequestData[key]]
        }
    }

    
const handleFilter = () => {
  setFilterOpen(true);
  window.analytics.track("Overview-Filter-Button-Click");
};

    return (
        <Card>
            <Header>
                <Title>Closed-Loops</Title>
                <FilterCard>
                    {Object.values(filterValuesForRequestData)?.map((item,index) => {
                        if (!item || !Array.isArray(item)) return null;
                        return item?.map((itm, idx) => (
                            <SelectedItemContainer key={index+idx}>
                                 <SelectedItem>
                    {" "}
                    {itm.category === "radiologist"
                      ? itm.label
                        ? itm.category.replace(/_/g, " ") + ": " + itm.label
                        : getRadiologistNameById(itm.value)
                        ? itm.category.replace(/_/g, " ") +
                          ": " +
                          getRadiologistNameById(itm.value)
                        : itm.category.replace(/_/g, " ") + ": "
                      : itm.value.replace(/_/g, " ") ||
                        itm.category === "referrers"
                      ? itm.label
                        ? itm.category.replace(/_/g, " ") +
                          ": " +
                          ((modalityOperator?.value === "isNotEqual" &&
                            itm.category === "modality") || (reportModalityOperator?.value === "isNotEqual" &&
                            itm.category === "report_modality") ||
                          (anatomyOperator?.value === "isNotEqual" &&
                            itm.category === "anatomy")
                            ? `is not `
                            : "") +
                          itm.label.replace(/\^/g, ", ")
                        : getReferrerNameById(itm.value)
                        ? itm.category.replace(/_/g, " ") +
                          ": " +
                          getReferrerNameById(itm.value)
                        : itm.category.replace(/_/g, " ") + ": "
                      : itm.value.replace(/_/g, " ")}
                  </SelectedItem>
                            <CloseSign onClick={() => closeSelected(itm.category,itm)}>
                                    <Image src={close} alt={'close sign'} />
                                </CloseSign>
                            </SelectedItemContainer>
                        ))
                         
                    })}
                    <div style={{marginRight:'18px'}}>
                    {filterOpen === false && (
                    <FilterContainer>
                     <FilterButton onClick={() => handleFilter()}>
                     <Image src={filterOutline} alt={"settings icon"} />
                     </FilterButton>
                    </FilterContainer>
                    )}
                    {
                    filterOpen === true &&  
                    <FollowUpListFilter
                        filterBy={filterBy}
                        setFilter={setFilterBy}
                        filterTypes={filterTypes}
                        setFilterBy={setFilterBy}
                        setPageNumber={setPageNumber}
                        filterValuesForRequest={filterValuesForRequest}
                        setFilterValuesForRequest={setFilterValuesForRequest}
                        marginRight="true"
                        operatorValues={handleOperatorsValue}
                        filterOpen={filterOpen}
                        setFilterOpen={setFilterOpen}
                        anatomyOperator={anatomyOperator}
                        modalityOperator={modalityOperator}
                        reportModalityOperator={reportModalityOperator}
                        examCodeOperator={examCodeOperator}
                    />
                    }
                    </div>
                   
                </FilterCard>
            </Header>
            <PercentageText>{Number((getPersonToShow().numberToShow).toFixed(2))}%</PercentageText>
            <Text>Closed-loop rate as of today</Text>
            <BottomSection>
                <ShowPerson>
                    {
                        getPersonToShow().personIconsArray?.map((x, i) => <IconContainer key={i}>{x}</IconContainer>)
                    }
                </ShowPerson>
            </BottomSection>
        </Card>
    )
}

export default AdherenceOfToday
