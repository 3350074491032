import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '../../../styles';
import {useHistory, useParams} from 'react-router-dom';
import {Button} from '@material-ui/core';
import styled from 'styled-components';
import LoadMoreButton from '../../../components/LoadMoreButton';
import { EmptyState } from '../../../components/EmptyState/EmptyState';
import { ReactComponent as EmptyStateImg } from '../../../assets/empty-state-search.svg';
import Box from '../../../components/_styles/Box';
import ExportCohortModal from './ExportCohortModal';
import {
    getPatientsInCohort,
    getCohortWithId,
    createCohortExport,
    updateCohortPatients,
} from '../../../actions/radminUploads';
import {
    BackNavigateIcon, 
    PersonIcon, 
    DownArrow, 
    EditButton
} from './Icons';
import { makeStyles } from '@material-ui/core/styles';
import PatientsTable from './PatientsTable';
import { showAlert } from "../../../actions/radminUploads";
import moment from 'moment';


export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const LeftContainer = styled.div`
    display: flex;
    align-items: center;
    width: 70%;
`
const RightContainer = styled.div`
    width: 30%;
    display: flex;
    justify-content: flex-end;
`
const HeadingWrapper = styled.div`
    display: flex;
    margin: 15px;
`

const Heading = styled.div`
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    margin-left: 10px;
    display: flex;
    line-height: 120%;
    text-align: initial;
    color: #00000;
`

const Description = styled.div`
    font-family: Roboto Condensed;
    font-style: normal;
    font-size: 14px;
    text-align: initial;
    color: #000000;
    margin: 0px 50px;
`

const PeopleCount = styled.div`
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    color: #0C2945;
    justify-content: center;
    margin-left: 10px;
`
const CountLabel = styled.div`
    margin-top: 13px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    margin-left: 5px;
    color: #9FA2B4;
`

const EditContainer = styled.div`
    width: 14%;
    display: flex;
    justify-content: space-between;
`
const CohortDescriptionContainer = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.isClicked ? '' : '3'};;
    font-size: 13px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 21px;
    font-weight:100px;
`
const PersonIconWrapper = styled.div`
    margin-left: auto;
`
const RecalculateContainer = styled.div`
    margin-left:20px;
    display:flex;
    flex-direction:column;
`
const RecalculateCohortLabel = styled.span`
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #00AEEF;
    cursor:pointer;
    text-decoration:underline;

` 
const LoadingLabel = styled.span`
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #00AEEF;
` 
const LastUpdatedLabel = styled.span`
    font-size:14px;
    font-family:Roboto Condensed;
`

const useStyles = makeStyles({
    label: {
        padding: "5px 0px",
        fontSize: "12px",
        fontWeight: "bold"
    },
    exportButton: {
        color: "#FFFFFF",
        backgroundColor: "#00AEEF",
        textTransform: 'none',
        border: "2px solid transparent",
        width: '8rem',
        "&:hover": {
            backgroundColor: "transparent",
            border: "2px solid #00AEEF",
            color: "#00AEEF",
          },
    },
    campaignButton: {
        backgroundColor: "#7879F1",
        textTransform: 'none',
        width: '8rem',
        '&:hover':{
            backgroundColor:'#7879F1'
        },
        marginRight: '7%'
    },
    editCohort: {
        color: "#00AEEF",
        textTransform: 'none',
        width: '8rem',
        background: "transparent",
        border: "2px solid #00AEEF",
        "&:hover": {
          backgroundColor: "transparent",
        },
        marginRight: '7%'
    },
    cardStyling: {
        margin: 'auto'
    },
})

const getOperator = (filter) => { 
    let readAbleOperator = ""
    if (filter.operator === "__gt") {
        readAbleOperator = "Is a timestamp after"
    } else if (filter.operator === "__lt") {
        readAbleOperator = "Is a timestamp before"
    }
    else if (filter.operator === "__lte") {
        readAbleOperator = "Is a timestamp less than"
    }
    else if (filter.operator === "__gte") {
        readAbleOperator = "Is a timestamp greater than"
    } else if (filter.operator === "__icontains" && filter.exclude ===0) {
        readAbleOperator = "Contains"
    }else if (filter.operator === "__icontains" && filter.exclude ===1) {
        readAbleOperator = "Does not contain"
    }  
    else if(filter.exclude){
        readAbleOperator = "Is not equal to"
    } else {
        readAbleOperator = "Is equal to"
    }
    return readAbleOperator;
}

function ViewPatients () { 
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams();

    const patientsList = useSelector(state => state.radAdmin.cohorts.patients.data);
    const cohortWithId = useSelector(state => state.radAdmin.cohorts.cohortWithId.data);
    const isLoading = useSelector(state => state.radAdmin.cohorts.patients.isLoading);
    const [status, setStatus] = useState();
    const [loading, setLoading] = useState(false);
    const [lastUpdated, setLastUpdated] = useState(() => moment(cohortWithId?.last_updated).fromNow());
    const [page, setPage] = useState(1)
    const [showCohortModal, setShowCohortModal] = useState(false);
    const [showMore, setShowMore] = useState(false)
    const permission = useSelector((state) => state.auth.user.permission_level);
    const rolePermissions = useSelector(
    (state) =>state.auth.user.permissions
    );
    const updatePermission = rolePermissions?.find(itm=>itm.name ==="Cohorts")?.permissions.find(itm=>itm.codename === "change_cohorts")?.is_authorized
    const exportPermission = rolePermissions?.find(itm=>itm.name ==="Export")?.permissions.find(itm=>itm.codename === "add_export")?.is_authorized

    useEffect(() => {
        if(id){
            dispatch(getCohortWithId(id))
            dispatch(getPatientsInCohort(id, page ));
        }
    }, [])

    const onLoadMore = () => {
        if(id && !loading){
            dispatch(getPatientsInCohort(id, page + 1));
            setPage(page + 1)
        }
    }
       
    const handleExportCohort = () => {   
        if(!loading){
            if((permission && exportPermission) || !permission){
                if(id) {
                    dispatch(createCohortExport(id))
                        .then(res => {
                            if(res) setShowCohortModal(true)
                        })
                }    
              }else{
                dispatch(
                  showAlert('danger', 'Error', `You don't have permission to export cohort`)
                );
              }   
        }
    }

   useEffect(()=>{
    if((cohortWithId?.status === "in_progress" || cohortWithId?.status === "created") && !loading){
     setLoading(true);
     setStatus('in_progress');
     startPolling();
    }
   },[cohortWithId])
  
    const handleRecalculatePatients = async () => {
        if(!loading){
            if((permission && updatePermission) || !permission){
                setLoading(true);
                try {
                  const response = await dispatch(updateCohortPatients(id));
                  if (response?.data?.status === 'in_progress') {
                    setStatus('in_progress');
                    startPolling();
                  } else if (response?.data?.status === 'errored') {
                    setStatus('errored');
                    setLoading(false);
                  }
                } catch (error) {
                  setStatus('errored');
                  setLoading(false);
                }
              }else{
                dispatch(
                  showAlert('danger', 'Error', `You don't have permission to update cohort`)
                );
              }
        }
    
    };
    const intervalRef = useRef(null);

    const startPolling = () => {
        intervalRef.current = setInterval(checkStatus, 10000); 
      };
    
      const stopPolling = () => {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      };
  
      const checkStatus = async () => {
        try {
          const response = await dispatch(getCohortWithId(id));
          if (response && response.payload?.status === 'completed') {
            stopPolling();
            setStatus('completed');
            setLoading(false);
            setPage(1);
            dispatch(getPatientsInCohort(id, 1));
          } else if (response && response.payload?.status === 'errored') {
            stopPolling();
            setStatus('errored');
            setLoading(false);
          }
        } catch (error) {
          stopPolling();
          setStatus('errored');
          setLoading(false);
        }
      };
    useEffect(() => {
        return () => {
          stopPolling();
        };
      }, []);
    

    useEffect(() => {
        const intervalId = setInterval(() => {
          setLastUpdated(moment(cohortWithId?.last_updated).fromNow());
        }, 1000); 
    
        return () => clearInterval(intervalId);
      }, [cohortWithId?.last_updated]);


    return (
        <Box p={30}>
            <HeaderContainer>
                <LeftContainer>
                    <EditContainer>
                     <span onClick={() => history.push('/auth/radAdmin/messages/cohorts')} style={{cursor: 'pointer'}}><BackNavigateIcon /> Previous</span>
                    </EditContainer>
                    <PersonIconWrapper>
                        <PersonIcon />
                    </PersonIconWrapper>
                    <PeopleCount>{cohortWithId?.total_patients || 0}</PeopleCount>
                    <CountLabel>People</CountLabel>
                    <RecalculateContainer>
                    {
                    !loading && <RecalculateCohortLabel onClick={handleRecalculatePatients}>Recalculate Patients</RecalculateCohortLabel>
                    }
                    {
                     loading && <LoadingLabel>Refreshing...</LoadingLabel>
                    }
                    {
                     lastUpdated && !loading && <LastUpdatedLabel> Last Updated : {lastUpdated}</LastUpdatedLabel>
                    }

                    </RecalculateContainer>
               
                    <Card className={classes.cardStyling}>
                        <HeadingWrapper >
                            <span 
                                onClick={() => {
                                    if(!loading){
                                        if((permission && updatePermission) || !permission){
                                            history.push({
                                                pathname: '/auth/radAdmin/messages/cohorts/create',
                                                state: {data: cohortWithId}
                                            })
                                          }else{
                                            dispatch(
                                              showAlert('danger', 'Error', `You don't have permission to update cohort`)
                                            );
                                          }
                                    }
                                }}
                                style={{cursor:loading?'not-allowed': 'pointer'}}
                            >
                            <EditButton/>
                            </span>
                            <Heading>Cohort Description:</Heading>
                        </HeadingWrapper>
                        <CohortDescriptionContainer isClicked={showMore}>
                            {
                                cohortWithId?.cohort_filters && cohortWithId?.cohort_filters.map((item, i) => {
                                    return (
                                        <>
                                        <Description> {(item?.model ==='Report2' ? 'Report' : item?.model)} {item.attribute} {getOperator(item)} {item.value} </Description>
                                       {
                                        i !== cohortWithId.cohort_filters.length - 1 && <Description style={{textTransform:'uppercase', textAlign:'center'}} >{cohortWithId?.raw_filters?.condition}</Description>
                                       } 
                                        </>
                                    );
                                })
                            }
                        </CohortDescriptionContainer>
                        <span
                            onClick={() => cohortWithId?.cohort_filters.length > 2 &&  setShowMore(!showMore)} 
                            style = {{cursor: 'pointer'}}
                        >
                        <DownArrow/>
                        </span>                           
                    </Card>
                </LeftContainer>
                <RightContainer>
                    <Button
                        classes={{ label: classes.label, root: classes.editCohort }}
                        onClick={() => {
                            if(!loading){
                                if((permission && updatePermission) || !permission){
                                    history.push({
                                        pathname: '/auth/radAdmin/messages/cohorts/create',
                                        state: {data: cohortWithId}
                                    })
                                  }else{
                                    dispatch(
                                      showAlert('danger', 'Error', `You don't have permission to update cohort`)
                                    );
                                  }
                            }
                        }}
                        style={{cursor:loading?'not-allowed': 'pointer'}}
                    >
                    Edit Cohort
                    </Button>
                
                    <Button
                        classes={{ label: classes.label, root: classes.exportButton }}
                        onClick={handleExportCohort}
                        style={{cursor:loading?'not-allowed': 'pointer'}}
                   
                    >
                    Export Cohort
                    </Button>
                    <ExportCohortModal
                        showModal={showCohortModal}
                        closeModal={() => setShowCohortModal(false)}
                    />
                </RightContainer>
            </HeaderContainer>
           
          
            <Card> 
                <PatientsTable
                    patientsData={patientsList}
                    isLoading={isLoading}
                    needMoreDetails={false}
                    noDataRender={() => (
                        <EmptyState title={'No Results'} img={() => <EmptyStateImg />}>
                            Sorry, there are no results
                        </EmptyState>
                    )}
                />
                {patientsList && patientsList.length !== 0 && (
                    <LoadMoreButton
                        title="Load more"
                        isLoading={isLoading}
                        handleClick={onLoadMore}
                        isDisabled={(cohortWithId?.total_patients === patientsList.length) || loading}
                    />
                )}
            </Card>
        </Box>
    )
}

export default ViewPatients