import React, { useState } from "react";
import styled from "styled-components";
import Flex from "../../../components/_styles/Flex";
import moment from "moment";
import { ReactComponent as CancelOutline } from "../../../assets/cancel-outline.svg";
import { getExportEmails } from "../../../actions/radminUploads";
import { useDispatch } from "react-redux";
import Select, { components } from "react-select";
import Filter from "./Filter";
import RecoSwitcher from "../../../components/UI/ToggleSwitch/Switcher";
import { Spinner } from "react-bootstrap";

const Modal = styled.div`
  display: ${(props) => (props.showModal ? "flex" : "none")};
  justify-content: center; 
  align-items: center;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  animation: changeWidth 8s ease-in-out infinite;
  background: rgba(12, 41, 69, 0.38);
  backdrop-filter: blur(10px);
  border-right: 1px solid whitesmoke;
`;

const ModalContentContainer = styled.div`
  max-width: 526px;
  width: 70%;
  min-height: 293px;
  margin: auto;
  padding: 25px;
  border-radius: 10px;
  background-color: white;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const ModalTitle = styled.p`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #0c2945;
  margin: 0px;
`;

const ModalInfo = styled.p`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #0c2945;
`;

const DateFilterContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
  display: ${(props) =>
    props.isSelected !== undefined
      ? props.isSelected
        ? "block"
        : "none"
      : "block"};
`;

const TimeframeContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    padding: 0px;
    width: 100%;
    & > div {
      width: 100%;
      height: 46px;
      & > span > div > .select__control {
        height: 46px;
      }
      & > span > div {
        height: 46px;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Timeframe = styled.p`
  margin: 0;
  padding: 0;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  letter-spacing: 0.2px;
  color: #6d6e6e;
  margin-bottom: 8px;
`;

export const DatePickerContainer = styled(Flex)`
  width: 235px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

export const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
`;

export const DateContainer = styled(Flex)`
  margin-right: 15px;
`;

const SelectContainer = styled.div`
  width: 100%;
  & div.select__control {
    height: 46px;
  }
`;

const statusOptions = [
  { value: "all", label: "All Emails" },
  { value: "sent", label: "Sent" },
  { value: "delivered", label: "Delivered" },
  { value: "opened", label: "Opened" },
  { value: "clicked", label: "Clicked" },
  { value: "spammed", label: "Spammed" },
  { value: "failed", label: "Failed" },
  { value: "dropped", label: "Dropped" },
  { value: "bounced", label: "Bounced" },
  { value: "unsubscribed", label: "Unsubscribed" },
  { value: "converted", label: "Converted" },
];

const options = [
  { value: "Last 7 days", label: "Last 7 days" },
  { value: "Last 30 days", label: "Last 30 days" },
  { value: "Last 3 months", label: "Last 3 months" },
  { value: "Last 12 months", label: "Last 12 months" },
  { value: "Custom date range", label: "Custom date range" },
];


const TextSwitch = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  color: #0c2945;
`;


const ToggleSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom:20px;
  .range-text {
    margin-left: 20px;
  }
`;


const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        <span style={{ marginRight: 5, paddingTop: 4 }}>
          <input
            type="radio"
            checked={props.isSelected}
            onChange={() => null}
            style={{ marginTop: 5 }}
          />{" "}
        </span>
        <label>{props.label}</label>
      </components.Option>
    </>
  );
};

const ExportEmailModal = ({ showModal, closeModal }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [statusValue, setStatusValue] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleConditionalChange = () => {
    setIncludeEmailBody(!includeEmailBody);
  };
  const [includeEmailBody, setIncludeEmailBody] = useState(false);


  const onCloseModal = (e) => {
    e.stopPropagation();
    closeModal();
  };

  React.useEffect(() => {
    if (filterValue) {
      var date = new Date();
      let requiredDate;
      let lastMoment;
      if (filterValue.value === "Custom date range") return;
      setStartDate("");
      setEndDate("");
      if (filterValue.value === "Last 7 days") {
        lastMoment = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
      } else if (filterValue.value === "Last 30 days") {
        lastMoment = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000);
      } else if (filterValue.value === "Last 3 months") {
        lastMoment = new Date(date.getTime() - 90 * 24 * 60 * 60 * 1000);
      }
      if (filterValue.value === "Last 12 months") {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear() - 1;
        if (day < 10) {
          day = "0" + day;
        }
        if (month < 10) {
          month = "0" + month;
        }
        requiredDate = `${year}-${month}-${day}`;
        setSelectedDate(requiredDate);
        return;
      } else {
        let day = lastMoment.getDate();
        let month = lastMoment.getMonth() + 1;
        let year = lastMoment.getFullYear();
        requiredDate = `${year}-${month}-${day}`;
        setSelectedDate(requiredDate);
      }
    }
  }, [filterValue]);

  const handleExportClick = () => {
    let day = new Date().getDate();
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    let requiredDate = `${year}-${month}-${day}`;
    const dateRange = {
      startDate:
        filterValue.value === "Custom date range"
          ? moment(startDate).format("YYYY-MM-DD")
          : selectedDate,
      endDate:
        filterValue.value === "Custom date range"
          ? moment(endDate).format("YYYY-MM-DD")
          : requiredDate,
      statusValue: statusValue?.value || "",
      includeEmailBody: includeEmailBody
    };
    setLoading(true);
    window.analytics.track("Message-Deliveries-Emails-Export-Submit",{...dateRange})
    dispatch(getExportEmails(dateRange)).then(() =>{
    setLoading(false);
    closeModal()
  });
  };

  return (
    <Modal showModal={showModal}>
      <ModalContentContainer>
        <ModalActions display="inline-block">
          <ModalTitle>Export Emails</ModalTitle>
          <CancelOutline
            style={{ cursor: "pointer" }}
            onClick={(e) => onCloseModal(e)}
          />
        </ModalActions>
        <ModalInfo>
          Please select a date range of Email Delivery date that you would like
          to export
        </ModalInfo>

        <div
          style={{
            display: "flex",
            gap: "22px",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              flexBasis: "50%",
            }}
          >
            <DateFilterContainer>
              <TimeframeContainer>
                <Timeframe>CREATED TIMEFRAME*</Timeframe>
                <Filter
                  options={options}
                  filterValue={filterValue}
                  setFilterValue={setFilterValue}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  setSelectedDate={setSelectedDate}
                  type="radio"
                />
              </TimeframeContainer>
            </DateFilterContainer>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              flexBasis: "50%",
            }}
          >
            <Timeframe>STATUS</Timeframe>
            <SelectContainer key={"item"}>
              <Select
                className="basic-multi-select"
                classNamePrefix="select"
                options={statusOptions}
                value={statusValue}
                onChange={(value) => setStatusValue(value)}
                styles={{
                  option: (styles, { isSelected }) => ({
                    ...styles,
                    fontSize: "15px",
                    textAlign: "left",
                    height: "46px",
                    fontWeight: isSelected ? 600 : 100,
                    alignItems: "center",
                  }),
                }}
                isSearchable={false}
                components={{
                  Option,
                }}
              />
            </SelectContainer>
          </div>
        </div>
        <ToggleSwitchWrapper>
              <TextSwitch>Hide Body Content </TextSwitch>
                <RecoSwitcher key="includeEmailBody" value={includeEmailBody} checked={includeEmailBody} onChange={() => handleConditionalChange()} />
                <TextSwitch>
                  Show Body Content
                </TextSwitch>
              </ToggleSwitchWrapper>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            disabled={!filterValue}
            style={{
              background: !filterValue ? "#00AEEF4D" : "#00AEEF",
              color: "white",
              border: "none",
              borderRadius: "4px",
              width: "227px",
              height: "38px",
            }}
            onClick={handleExportClick}
          >
            {loading ? <Spinner animation="border" role="status" size="sm" /> :  'Export'}
          </button>
        </div>
      </ModalContentContainer>
    </Modal>
  );
};
export default ExportEmailModal;
