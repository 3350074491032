import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { updateUser } from '../../../actions/auth/actions';
import { incrementCheckoutStep, initMedHistForm, setCurrentStep, resetState } from '../../../actions/checkout';
import { SchedulingUIWrapper, CheckoutNavigationHolder, CheckoutNavigationButton } from '../common';
import PersonalInfoRightPanel from '../SchedulingStatusRightPanel';
import PersonalInfoLeftPanel from './PersonalInfoLeftPanel';

const EnterPersonalInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.auth.user.id);
  const values = useSelector((state) => state.form['PERSONAL_INFO']?.values ?? {});
  const isContinueDisabled = useSelector((state) => state.form['PERSONAL_INFO']?.syncErrors);
  const communicationOption = useSelector((state) => state.checkout.communicationOption);

  const onPrev = useCallback(() => {
    dispatch(resetState());
    history.goBack();
  }, [dispatch, history]);

  const onNext = useCallback(async () => {
    const optionToStep = {
      'VOICE': 'CHECKOUT_SELECT_TIME',
      'ONLINE': 'CHECKOUT_MEDICAL_HISTORY'
    };

    await dispatch(
      updateUser({
        userId: userId,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber
      })
    );

    if (communicationOption === 'ONLINE') {
      dispatch(initMedHistForm());
    }

    dispatch(incrementCheckoutStep(1));
    dispatch(setCurrentStep(optionToStep[communicationOption]));

    window.analytics.track('PersonalInfo Submit');
    window.analytics.identify(userId, { firstName: values.firstName, phoneNumber: values.phoneNumber });
  }, [dispatch, userId, values, communicationOption]);

  return (
    <SchedulingUIWrapper>
      <PersonalInfoLeftPanel />
      <PersonalInfoRightPanel />
      <div className='twelve-cols'>
        <CheckoutNavigationHolder>
          <CheckoutNavigationButton onClick={onPrev} children='Previous' previous />
          <CheckoutNavigationButton onClick={onNext} disabled={isContinueDisabled} children='Continue' />
        </CheckoutNavigationHolder>
      </div>
    </SchedulingUIWrapper>
  );
};

export default EnterPersonalInfo;
