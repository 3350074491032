import React from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import Flex from "../../../../components/_styles/Flex";
import PatientInfoCard from "./PatientListInfocard";

const SpinnerContainer = styled(Flex)`
  justify-content: center;
  margin: 30px 0;
  align-items: center;
`;

const NoDataContainer = styled(Flex)`
  justify-content: center;
  padding: 15px 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 20px 46px;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  color: #9fa2b4;
`;

const TableHeaderItem = styled.div`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
`;

const PatientTable = ({
  patientsData,
  isLoading,
  resetDataAction,
  noDataRender,
  needMoreDetails,
}) => {
  const NoDataRender = noDataRender;

  return (
    <div>
      <Table>
        <TableHeader>
          <TableHeaderItem flex={2} textAlign="left">
            Patient Name
          </TableHeaderItem>
          <TableHeaderItem  textAlign="left">Patient MRN</TableHeaderItem>
          <TableHeaderItem  textAlign="left">Email</TableHeaderItem>
          <TableHeaderItem  textAlign="left">Phone Number</TableHeaderItem>
        </TableHeader>
        {!isLoading &&
          patientsData &&
          patientsData.map((patient, id) => (
            <PatientInfoCard
              key={id}
              patientItem={patient}
              bgLine={patientsData.length - 1 === id ? "0px" : "2px"}
              needMoreDetails={needMoreDetails}
            />
          ))}
      </Table>
      {!isLoading &&
        patientsData &&
        patientsData.length === 0 &&
        (noDataRender ? (
          <NoDataRender />
        ) : (
          <NoDataContainer>No data</NoDataContainer>
        ))}
      {isLoading && (
        <SpinnerContainer>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </SpinnerContainer>
      )}
    </div>
  );
};

export default PatientTable;
