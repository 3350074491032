import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as CloseIcon } from "../../../assets/cancel-outline.svg";
import { TextField, makeStyles } from "@material-ui/core";
import Select, { components } from "react-select";
import { style } from "styled-system";
import { useDispatch } from "react-redux";
import {
  inviteUser,
  resetPasswordInvitedUser,
} from "../../../actions/radminSettings";
import { Spinner } from "react-bootstrap/esm";

const Modal = styled.div`
  display: ${(props) => (props.showModal ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(12, 41, 69, 0.38);
  backdrop-filter: blur(10px);
`;

const ModalContentContainer = styled.div`
  position: relative;
  min-height: 301px;
  width: 520px;
  margin: 0 auto;
  background-color: white;
  cursor: default;
  font-style: normal;
  border-radius: 4px;
  padding: 25px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

const ModalTitle = styled.p`
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 24px;
  color: #0c2945;
  font-family: Roboto Condensed;
  font-weight: 700;
`;

const CloseButton = styled.button`
  border: 0;
  outline: none !important;
  background-color: #ffffff;
`;

const SelectContainer = styled.div`
  width: 227px;
  //   margin-bottom: 14px;
`;

const SelectDropdownTitle = styled.div`
  margin-bottom: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-transform: uppercase;
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
`;

const ExportButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ExportButton = styled.button`
  width: 227px;
  height: 38px;
  background-color: #00aeef;
  color: #ffffff;
  opacity: ${(props) => (props.disabled ? 0.65 : 1)};
  border-radius: 5px;
  border: 2px solid #00aeef;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background:transparent;
    color:#00aeef;
  }
`;

const ResetButton = styled.button`
  width: 183px;
  height: 38px;
  background-color: transparent;
  color: #00aeef;
  border-radius: 5px;
  border: 2px solid #00aeef;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
`;

const useStyles = makeStyles({
  textField: {
    width: "227px",
    height: "38px",
    "& .MuiOutlinedInput-input": {
      fontSize: 14,
      borderRadius: "4px !important",
      padding: "13.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      height: "38px",
    },
  },
});

const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        <span style={{ marginRight: 5, paddingTop: 5 }}>
          <input
            type="radio"
            checked={props.isSelected}
            onChange={() => null}
            style={{ marginTop: 5 }}
          />{" "}
        </span>
        <label>{props.label}</label>
      </components.Option>
    </>
  );
};
const roleOptions = [
  { value: "editor", label: "Editor", category: "role" },
  { value: "viewer", label: "Viewer", category: "role" },
  { value: "admin", label: "Admin", category: "role" },
];

const InviteUsersModal = ({ showModal, closeModal, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedValues, setSelectedValues] = useState({
    email: data?.email || "",
    role: data?.permission_level
      ? roleOptions.find((opt) => opt.value === data?.permission_level)
      : "",
    name:
      data?.first_name && data?.last_name
        ? data?.first_name + " " + data?.last_name
        : data?.first_name
        ? data?.first_name
        : "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingResetPasswordSent, setLoadingResetPasswordSent] =
    useState(false);

  const onSaveClick = () => {
    setLoading(true);
    dispatch(
      inviteUser(
        {
          email: selectedValues?.email,
          permission_level: selectedValues?.role?.value,
          first_name: selectedValues?.name?.split(" ")[0],
          last_name: selectedValues?.name?.split(" ")[1],
        },
        data?.id
      )
    ).then((res) => {
      setLoading(false);
      if (res) {
        closeModal();
      }
    });
  };

  const handleResetPassword = ()=>{
    setLoadingResetPasswordSent(true)
    dispatch(resetPasswordInvitedUser(data?.id)).then(res=>{
      setLoadingResetPasswordSent(false)
    })
  }

  return (
    <>
      <Modal showModal={showModal}>
        <ModalContentContainer>
          <Header>
            <ModalTitle> {data?.id ? 'Edit User' :'Invite New User'} </ModalTitle>
            <CloseButton type={"button"} onClick={closeModal}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <FieldContainer>
            <Row>
              <div>
                <SelectDropdownTitle>Name *</SelectDropdownTitle>
                <TextField
                  id="outlined-uncontrolled"
                  variant="outlined"
                  value={selectedValues["name"]}
                  onChange={(e) =>
                    setSelectedValues({
                      ...selectedValues,
                      name: e.target.value,
                    })
                  }
                  style={{ borderRadius: "4px", height: "38px" }}
                  placeholder="Enter value"
                  className={classes.textField}
                />
              </div>
              <div>
                <SelectContainer>
                  <SelectDropdownTitle>Role *</SelectDropdownTitle>
                  <Select
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={roleOptions}
                    value={selectedValues["role"]}
                    onChange={(value) =>
                      setSelectedValues({
                        ...selectedValues,
                        role: value,
                      })
                    }
                    styles={{
                      option: (styles, { isSelected }) => ({
                        ...styles,
                        fontSize: "15px",
                        textAlign: "left",
                        height: "38px",
                        fontWeight: isSelected ? 600 : 100,
                        alignItems: "center",
                      }),
                    }}
                    isSearchable={false}
                    components={{
                      Option,
                    }}
                  />
                </SelectContainer>
              </div>
            </Row>

            <Row>
              <div>
                <SelectDropdownTitle>Email *</SelectDropdownTitle>
                <TextField
                  id="outlined-uncontrolled"
                  variant="outlined"
                  value={selectedValues["email"]}
                  onChange={(e) =>
                    setSelectedValues({
                      ...selectedValues,
                      email: e.target.value,
                    })
                  }
                  className={classes.textField}
                  style={{ borderRadius: "4px", height: "38px" }}
                  placeholder="Enter value"
                />
              </div>
              <div>
                {data?.id && (
                  <>
                    <SelectDropdownTitle style={{ visibility: "hidden" }}>
                      reset password
                    </SelectDropdownTitle>

                    <ResetButton onClick={handleResetPassword}>
                      {loadingResetPasswordSent ? (
                        <Spinner animation="border" role="status" size="sm" />
                      ) : (
                        "Reset Password"
                      )}
                    </ResetButton>
                  </>
                )}
              </div>
            </Row>
          </FieldContainer>

          <ExportButtonContainer>
            <ExportButton
              disabled={
                !selectedValues.role ||
                !selectedValues.name ||
                !selectedValues.email
              }
              onClick={onSaveClick}
            >
              {loading ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : data?.id ? (
                "Save Changes"
              ) : (
                "Invite"
              )}
            </ExportButton>
          </ExportButtonContainer>
        </ModalContentContainer>
      </Modal>
    </>
  );
};

export default InviteUsersModal;
