import React from "react";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const StyledTooltip = (props) => {
  const { className, ...other } = props;

  return (
    <Tooltip
      {...other}
      PopperProps={{
        className: className,
        style: {
          backgroundColor: "#D0EED8",
          marginLeft: "20px",
          borderRadius: "4px",
        },
      }}
    />
  );
};

const StyledTooltipWrapper = withStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    color: "black",
    width: "196px",
    border: "1px solid #64C67C",
    padding: "0px",
    margin: "0px",
    "&::before": {
        content: "''",
        position: "absolute",
        top: "calc(50%)", 
        left: "-13px",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: "12px",
        borderRightWidth: "12px",
        borderBottomWidth: "22px", 
        borderColor: "transparent transparent #D0EED8 transparent",
        transform: "translateY(-50%) rotate(270deg)",
        backgroundClip: "padding-box",
        zIndex: "1",
      },
      "&::after": {
        content: "''",
        position: "absolute",
        top: "calc(50%)", 
        left: "-14.5px",
        borderStyle: "solid",
        borderWidth: "0 8px 15px 8px",
        borderColor: "transparent transparent #64C67C transparent",
        zIndex: "0", 
        transform: "translateY(-50%) rotate(270deg)",
      },
  },
 
}))(StyledTooltip);

const CardList = styled.ul`
  list-style: none;
  width: 196px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 0px;
`;

const Container = styled.div`
  margin-bottom: 0px;
`;

const Title = styled.p`
  padding: 0px;
  margin: 0px;
  font-size: 12px;
  font-weight: 600;
  color: #252733;
  line-height: 18px;
`;

const Description = styled.p`
  padding: 0px;
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  color: #252733;
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NotesHeader = styled.p`
color:#328847;
font-size:16px;
font-weight:700;
padding:12px;
padding-bottom:0px;
margin-bottom:0px;
`

const NotesDescriptionCard = ({ title, description }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
};

const taskOptions = [
  { value: "call", label: "Call" },
  { value: "fax", label: "Fax" },
  { value: "email", label: "Email" },
  { value: "sms", label: "SMS" },
  { value: "letter", label: "Letter" },
  { value:'general',label:'General'}
];


export const NotesTooltip = ({ children, notes }) => {
  const header =  notes.slice(0,3).find(itm=> itm.task)?.task;
  const headerLabel = taskOptions.find(itm => itm.value === header)?.label;

  return (
    <>
      <StyledTooltipWrapper
        title={
          <>
            {
              headerLabel &&  <NotesHeader>{ headerLabel}</NotesHeader>
            }  
             <CardList>
            {notes.slice(0, 3).map((i) => (
              <li key={JSON.stringify(i)}>
                <NotesDescriptionCard
                  title={i.title}
                  description={i.note_text}
                />
              </li>
            ))}
          </CardList>
          </>
         
        }
        placement={"right"}
      >
        {children}
      </StyledTooltipWrapper>
    </>
  );
};
