import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { StyledTableRow, StyledTableCell } from './Common';

const Signed = styled.div`
  color: green;
`;

const AwaitingSignature = styled.div`
  color: red;
`;

const statusToLabel = (status) => {
  switch (status) {
    case 'signed':
      return <Signed children='Signed' />;
    case 'awaiting_signature':
      return <AwaitingSignature children='Awaiting signature' />;
    default:
      return <AwaitingSignature children='N/A' />;
  }
};

const PrescriptionRow = ({ prescription, onClick }) => {
  const scan = useSelector((state) => state.scan.items.find((i) => i.id === prescription.appointment.scan));

  return (
    <StyledTableRow onClick={() => onClick(prescription)}>
      <StyledTableCell children={scan?.name} />
      <StyledTableCell children={moment(prescription.appointment.date_time).format('L')} />
      <StyledTableCell children={statusToLabel(prescription.doctor_sign_status)} />
    </StyledTableRow>
  );
};

export default PrescriptionRow;
