import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector, useDispatch, connect } from 'react-redux';
import { Button } from '@material-ui/core';
import Box from '../../../components/_styles/Box';
import Table from '../../../components/Table/Table';
import LoadMoreButton from '../../../components/LoadMoreButton';
import { ReactComponent as ChevronRightIcon } from '../../../assets/chevron_right.svg';
import { ReactComponent as ChevronLeftIcon } from '../../../assets/chevron_left.svg';
import DoubleDataCell from '../PatientList/LastScanCell';
import { Card, PageTitle } from '../../../styles';
import { makeStyles } from '@material-ui/core/styles';
import { EmptyState } from '../../../components/EmptyState/EmptyState';
import { ReactComponent as EmptyStateImg } from '../../../assets/empty-state-search.svg';
import { getCampaigns, deleteCampaigns, createCampaigns, resetCampaign, getTwilioConfig, resetTimeTriggerForm, showAlert } from '../../../actions/radminUploads';
import EnableFeature from '../../../components/EnableFeature/EnableFeature';
import { HeaderContainer } from '../Cohorts/ViewPatients';
import { TimeBased, EventBased } from './Icons';
import RemoveConfirmation from '../../../components/Dialog/RemoveConfirmation';

const useStyles = makeStyles({
  label: {
    padding: '5px 0px',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  campaignButton: {
    backgroundColor: '#7879F1',
    textTransform: 'none',
    width: '8rem',
    '&:hover': {
      backgroundColor: '#7879F1',
    },
  },
});

const StyledRightChevron = styled(ChevronRightIcon)`
  & > path {
    fill: #9fa2b4;
  }
  &:hover {
    cursor: ${(props) => (props.isdetails === 'true' ? 'pointer' : 'default')};
  }
`;
const StyledLeftChevron = styled(ChevronLeftIcon)`
  & > path {
    fill: #9fa2b4;
  }
  &:hover {
    cursor: ${(props) => (props.isdetails === 'true' ? 'pointer' : 'default')};
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
`;
const PaginationPages = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActiveText = styled.p`
  color: #252733;
  margin: 0px;
  font-family: Roboto Condensed;
  line-height: 23.88px;
  font-size: 22px;
  font-weight: 700;
`;
const CountContainer = styled.div`
  background: #00AEEF;
  width: 60px;
  color: white;
  border-radius: 10px;
  margin-left: 10px;
`;
const ActiveTabContainer = styled.div`
  display: flex;
  width: 55%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid #00AEEF;
  padding: 10px 0px;
}
`;
const ChevronContainer = styled.div``;
const pageNumber = 1;

function Campaigns() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const campaignsData = useSelector((state) => state.radAdmin.campaigns?.data);
  const totalItems = useSelector(
    (state) => state.radAdmin.campaigns?.totalItems
  );
  const isLoading = useSelector((state) => state.radAdmin.campaigns.isLoading);
  const isDeletingCampaign = useSelector(
    (state) => state.radAdmin.campaigns.isDeletingCampaign
  );
  const [page, setPage] = useState(1);
  const [isDeleteCampaignClicked, setIsDeleteCampaignClicked] = useState(false);
  const [seletedCampignId, setSeletedCampignId] = useState(null);
  const permission = useSelector((state) => state.auth.user.permission_level); 
  const rolePermissions = useSelector(
     (state) =>state.auth.user.permissions
   );
  const updatePermission = rolePermissions?.find(itm=>itm.name ==="Campaigns")?.permissions.find(itm=>itm.codename === "change_campaigns")?.is_authorized
  const deletePermission = rolePermissions?.find(itm=>itm.name ==="Campaigns")?.permissions.find(itm=>itm.codename === "delete_campaigns")?.is_authorized
  const addPermission = rolePermissions?.find(itm=>itm.name ==="Campaigns")?.permissions.find(itm=>itm.codename === "add_campaigns")?.is_authorized


  useEffect(() => {
    dispatch(getCampaigns(page));
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(getTwilioConfig());
    dispatch(resetTimeTriggerForm())
  }, []);

  const dialogHandler = () => {
    setIsDeleteCampaignClicked(!isDeleteCampaignClicked);
  };

  const deleteCampaignHandle = () => {
    if (seletedCampignId) {
      dispatch(deleteCampaigns(seletedCampignId)).then((res) => {
        if (res.success) {
          dialogHandler();
        }
      });
    }
  };

  const onLoadMore = () => {
    let nextPage = page + 1;
    setPage(nextPage);
  };

  const editCampaignHandler = (rowData) => {
    dispatch( dispatch({ type: 'CREATE_CAMPAIGNS_REQUEST_SUCCESS', payload: rowData}))
    if(!rowData.trigger_type){
      history.push('/auth/radAdmin/Campaigns/create/triggers')
      return
    }
    history.push(`/auth/radAdmin/Campaigns/${rowData.id}/create/contact`)
  }

  return (
    <Box p={30}>
      <RemoveConfirmation
        content="Are you sure you want to delete this campaign?"
        openDialog={isDeleteCampaignClicked}
        dialogHandler={dialogHandler}
        deleteHandler={deleteCampaignHandle}
        isLoading={isDeletingCampaign}
      />
      <HeaderContainer>
        <PageTitle>Campaigns</PageTitle>
        <Button
          classes={{ label: classes.label, root: classes.campaignButton }}
          onClick={() => {
            if((permission && addPermission) || !permission){
              dispatch(resetCampaign());
              history.push('/auth/radAdmin/Campaigns/create/setup');
             }else{
               dispatch(
                 showAlert('danger', 'Error', `You don't have permission to create campaign`)
               );
             }
          }}
          variant="contained"
          color="secondary"
        >
          Create Campaign
        </Button>
      </HeaderContainer>
      <Card>
        <Table
          rows={campaignsData && campaignsData}
          columns={[
            {
              key: 'title',
              displayName: () => (
                <ActiveTabContainer>
                  <ActiveText>Active</ActiveText>
                  <CountContainer>{totalItems}</CountContainer>
                </ActiveTabContainer>
              ),
            },
            {
              key: 'trigger_type',
              name: 'Type',
              displayCell: (type) =>
                type === 'date' ? <TimeBased /> : <EventBased />,
            },
            { key: 'provider', name: 'Sent' },
            { key: 'provider', name: 'Delivered' },
            { key: 'provider', name: 'Bounced' },
            {
              key: 'pagination',
              displayName: () => (
                <PaginationPages>
                  {totalItems && `1 - ${totalItems}`}{' '}
                </PaginationPages>
              ),
              width: '20px',
              displayCell: (cellValue, rowData) => (
               <DoubleDataCell
                  mainInfo="edit"
                  RemoveRecoHandler={() => {
                    if((permission && deletePermission) || !permission){
                      setSeletedCampignId(rowData.id);
                      dialogHandler();
                     }else{
                       dispatch(
                         showAlert('danger', 'Error', `You don't have permission to delete campaign`)
                       );
                     }
                  }}
                  EditRecoHandler={() => {
                    if((permission && updatePermission) || !permission){
                      editCampaignHandler(rowData)
                     }else{
                       dispatch(
                         showAlert('danger', 'Error', `You don't have permission to edit campaign`)
                       );
                     }
                  }}
                />
              ),
            },
            
          ]}
          // //   initialPageNumber={previousUploads.pageNumber}
          // //   totalItems={previousUploads.totalItems}
          isLoadingRows={isLoading && campaignsData.length === 0}
          // // clickHandler={(value) => ClickHandler(value)}
          getRowsAction={(pageNumber) => getCampaigns(pageNumber)}
          noDataRender={() => (
            <EmptyState title={'No Results'} img={() => <EmptyStateImg />}>
              Sorry, there are no results
            </EmptyState>
          )}
        />
        {campaignsData && campaignsData.length !== 0 && (
          <LoadMoreButton
            title="Load more"
            isLoading={isLoading}
            handleClick={onLoadMore}
            isDisabled={totalItems === campaignsData.length}
          />
        )}
      </Card>
    </Box>
  );
}

function mapStateToProps(state) {
  const { user } = state.auth;

  return {
    user,
  };
}

const ConnectedCampaigns = connect(mapStateToProps)(Campaigns);

export default ConnectedCampaigns;

export const CampaignPanelWithFeature = (props) => {
  return (
    <EnableFeature featureName={'campaign-view'} {...props}>
      <ConnectedCampaigns />
    </EnableFeature>
  );
};
