import React from 'react';
import { Card, PageTitle } from '../../../styles';
import Table from '../../../components/Table/Table';
import {
  getPreviousUploadsListAction,
} from '../../../actions/radminUploads';
import Box from '../../../components/_styles/Box';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { EmptyState } from '../../../components/EmptyState/EmptyState';
import { ReactComponent as EmptyStateImg } from '../../../assets/empty-state-search.svg';

const DownloadFileLink = styled.a`
  padding: 10px;
  background: #00AEEF;
  color: white;
  border-radius: 5px;
  text-align: center;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`
const downloadClickAnalytic = () => {
  window.analytics.track('Previous-Uploads-Download-Click');
}
const columns = [
  { key: 'id', name: 'File ID' },
  { key: 'status', name: 'Status'},
  { key: 'error', name: 'Error'},
  { key: 'upload_file', name: 'Link To Download', displayCell: (cellValue) => <DownloadFileLink href={cellValue} download onClick={downloadClickAnalytic}>Download</DownloadFileLink> },
];

const PreviousUploadsList = () => {
  const previousUploads = useSelector(state => state.radAdmin.previousUploads);

  return (
    <Box p={30}>
      <PageTitle>Previous Uploads</PageTitle>
      <Card>
        <Table
          rows={previousUploads.data}
          columns={columns}
          initialPageNumber={previousUploads.pageNumber}
          totalItems={previousUploads.totalItems}
          isLoadingRows={previousUploads.isLoading}
          getRowsAction={(pageNumber) => getPreviousUploadsListAction(pageNumber)}
          noDataRender={() => <EmptyState title={'No Results'} img={() => <EmptyStateImg />} buttonInfo={{link: '/auth/radAdmin/Import', text: 'Import Data'}}>Sorry, there are no results</EmptyState>}
        />
      </Card>
    </Box>
  )
};

export default PreviousUploadsList;
