import API from '../../libs/api';
import { showAlert } from '../radminUploads'


export const GET_API_TOKEN_GENERATION_REQUEST = 'GET_API_TOKEN_GENERATION_REQUEST';
export const GET_API_TOKEN_GENERATION_SUCCESS = 'GET_API_TOKEN_GENERATION_SUCCESS';
export const GET_API_TOKEN_GENERATION_FAILURE = 'GET_API_TOKEN_GENERATION_FAILURE';


export const getApiGenerationToken= (pageNumber=1) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_API_TOKEN_GENERATION_REQUEST, payload : pageNumber });

    let responseData;
    let totalItems;
   
    
    const { data } = await API.getRequest(
      `/api/v1/api-keys/?page_size=100`
    );
    responseData = data.results;
    totalItems = data.count;
    
    dispatch({
      type: GET_API_TOKEN_GENERATION_SUCCESS,
      payload: {
        items: responseData,
        totalItems: totalItems,
        next: responseData.next,
        pageNumber: pageNumber,
      },
    });
  } catch (error) {
    dispatch({ type: GET_API_TOKEN_GENERATION_FAILURE });
    dispatch(showAlert('danger', 'Error'));

  }
};

  

  export const deleteApiGenerationToken = (id) => async (dispatch) => {
    try {
      if(id){
        await API.deleteRequest(
          `/api/v1/api-keys/${id}/`
        );
       await dispatch(getApiGenerationToken())
       dispatch(showAlert('success', 'Success', 'Token deleted successfully!'));
      }
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };


export const generateApiToken = (data, id) => async (dispatch, getState) => {
    try {
      if (id) {
        await API.patchRequest(`/api/v1/api-keys/${id}`, data);
        await dispatch(getApiGenerationToken(1));
  
        dispatch(showAlert("success", "Success", "Token details successfully updated!"));
      } else {
        let result = await API.postRequest(`/api/v1/api-keys/`, data);
        await dispatch(getApiGenerationToken(1));
  
        dispatch(showAlert("success", "Success", "Token successfully created!"));
      }
  
      return true;
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };