import React from 'react';
import { useSelector } from 'react-redux';
import TimeBasedTrigger from './TimeBasedTrigger';
import ActionBasedTrigger from './ActionBasedTrigger';

function Triggers({ selectedCampaignType, segment, segmentHandle, timeTrigger, timeTriggerHandle, additionalSegmentConditions, setAdditionalSegmentConditions, shouldBlockNavigation }) {
    const cohortsList = useSelector(state => state.radAdmin.cohorts.data);

    return (
        <div>
            {
                selectedCampaignType === "action" ?
                    <ActionBasedTrigger 
                        cohorts={cohortsList} 
                        segment={segment} 
                        segmentHandle={segmentHandle}
                        shouldBlockNavigation={shouldBlockNavigation}
                    /> :
                    <TimeBasedTrigger
                        cohorts={cohortsList} 
                        segment={segment} 
                        segmentHandle={segmentHandle}
                        timeTrigger={timeTrigger}
                        timeTriggerHandle={timeTriggerHandle}
                        additionalSegmentConditions={additionalSegmentConditions}
                        setAdditionalSegmentConditions={setAdditionalSegmentConditions}
                        shouldBlockNavigation={shouldBlockNavigation}
                    />
            }
        </div>
    )
}

export default Triggers
