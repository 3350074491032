import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import Box from '../../components/_styles/Box';
import Text, { Small } from '../../components/_styles/Text';
import { Panel } from './Accordion';

// const HealthIndicatorWrapper = styled.div`
//   color: #3f4857;
//   margin-bottom: 25px;
//   align-items: center;
//   box-sizing: border-box;
//   display: flex;
//   align-items: center;

//   @media (max-width: 991px) {
//     display: block;
//   }
// `;

const SummaryHealth = ({ data, text }) => {
  return (
    <Box mb={30}>
      <Row>
        {/* <Col xl='8'>
          <Panel p={30}>
            <Box mb={15}>
              <Small children='Summary Health' uppercase />
            </Box>
            {data.map(({ title, subTitle, IconComponent, reports }) => (
              <HealthIndicatorWrapper>
                <Flex.Col mr={15}>
                  <IconComponent />
                </Flex.Col>
                <Flex.Col flex='4' mr={15}>
                  <Text children={title} />
                  <ProgressBar>
                    {reports.map((report) => (
                      <ProgressBar bg={report.bg} now={report.width} />
                    ))}
                  </ProgressBar>
                </Flex.Col>
                <Flex.Col flex='1' alignSelf='flex-end'>
                  <Text children={subTitle} />
                </Flex.Col>
              </HealthIndicatorWrapper>
            ))}
          </Panel>
        </Col> */}
        <Col>
          <Panel p={30}>
            <Small children='Explanation of Report' uppercase />
            <Text children={text} fontSize={1} fontWeight='normal' />
          </Panel>
        </Col>
      </Row>
    </Box>
  );
};

export default SummaryHealth;
