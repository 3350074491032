import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.div`
  margin-bottom: 15px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #3f4857;

  sup {
    color: #ff0000;
  }
`;

const Section = ({ className, label, children, required }) => {
  return (
    <Container className={className}>
      <Label>
        {label} {required && <sup>*</sup>}
      </Label>
      {children}
    </Container>
  );
};

export default styled(Section)``;
