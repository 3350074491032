import { useState, useEffect } from "react";

export const useTable = (columnData) => {
  const [columns, setColumns] = useState(columnData);
  const [columnFilterDropdown, setColumnFilterDropdown] = useState(false);
  const [columnCheckbox, setColumnCheckbox] = useState(() =>
    columnData.reduce((acc, curr) => {
      const savedColumns = JSON.parse(localStorage.getItem("columns"));

      acc[curr.key] = savedColumns ? (savedColumns[curr.key] ?? true) : true;

      return acc;
    }, {})
  );

  const handleColumnChange = (e) => {
    setColumnCheckbox({
      ...columnCheckbox,
      [e.target.value]: !columnCheckbox[e.target.value],
    });
  };

  useEffect(() => {
    setColumns(
      columnData.filter((column) => {
        if (columnCheckbox[column.key]  || columnCheckbox[column.key] === undefined) {
          return column;
        }
      })
    );

    localStorage.setItem("columns", JSON.stringify(columnCheckbox));
  }, [columnCheckbox]);

  return {
    columnCheckbox,
    columnFilterDropdown,
    columns,
    handleColumnChange,
    setColumnFilterDropdown,
  };
};
