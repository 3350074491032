import React, { createContext, useContext } from 'react';

import ProgressIcon from './ProgressIcon';
import Tooltip from '../../components/Tooltip/Tooltip';
import Flex from '../../components/_styles/Flex';
import Box from '../../components/_styles/Box';
import Text from '../../components/_styles/Text';
import Relative from '../../components/_styles/Relative';
import { Progress, ProgressIconWrapper } from './styles';
import USER_STEPS from './userSteps';

const UserStatusContext = createContext(null);

export const Step = ({ label, IconComponent, single, last }) => {
  const { currentStep } = useContext(UserStatusContext);
  const isCurrentStep = currentStep.label === label;

  return (
    <Relative>
      {isCurrentStep && (
        <div>
          <Tooltip step={currentStep.label} tooltipContent={currentStep.tooltipContent} last={last} />
          <ProgressIconWrapper>
            <ProgressIcon />
          </ProgressIconWrapper>
        </div>
      )}
      <Box textAlign='center'>
        <IconComponent style={single ? { width: '30px', height: '30px' } : {}} />
        <Text.Small style={single ? { fontSize: '14px' } : {}}>{label}</Text.Small>
      </Box>
    </Relative>
  );
};

const MultiStepTracker = ({ children, currentStep, single }) => {
  const steps = USER_STEPS.map((userStep) => userStep.id);
  const step = (steps.indexOf(currentStep.id) / (steps.length - 1)) * 100;

  return (
    <UserStatusContext.Provider value={{ currentStep }}>
      <Flex style={{ height: '108px', position: 'relative' }} flexDirection='column' justifyContent='flex-end' p={30}>
        {!single && (
          <Progress>
            <Progress.Fill step={step} />
          </Progress>
        )}
      </Flex>
      <Flex justifyContent={single ? 'center' : 'space-between'}>{children}</Flex>
    </UserStatusContext.Provider>
  );
};

export default MultiStepTracker;
