import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import DoB from './DoB';
import Metal from './Metal';
import Symptoms from './Symptoms';
import Allergies from './Allergies';
import ChooseGender from './ChooseGender';
import MedicalCondition from './MedicalCondition';
import MedHistoryRightPanel from './MedHistoryRightPanel';
import MedicalIcon from '../../../assets/checkout/medical-icon.svg';
import { SchedulingUIWrapper, CheckoutSectionHeader } from '../common';
import { CheckoutNavigationButton, CheckoutNavigationHolder } from '../common';
import { updateMedicalHistory, incrementCheckoutStep, setCurrentStep } from '../../../actions/checkout';
import { isValidDob } from '../../../utils/utils';

const MedicalHistory = () => {
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.auth.user.id);
  const { gender, dob, medicalConditions, symptoms, currentMeds, allergyOption, metalItems } = useSelector(
    (state) => state.checkout
  );

  const isContinueDisabled = useMemo(() => {
    if (!gender) return true;

    if (!dob) {
      return true;
    } else {
      if (!isValidDob(dob)) return true;
    }

    const conditions = Object.values(medicalConditions);
    if (conditions.length < 1) return true;

    const symps = Object.values(symptoms);
    if (symps.length < 1) return true;

    if (!allergyOption) return true;

    const metals = Object.values(metalItems);
    if (metals.length < 1) return true;

    return false;
  }, [gender, dob, medicalConditions, symptoms, metalItems, allergyOption]);

  const onNext = useCallback(() => {
    dispatch(
      updateMedicalHistory({
        id: userId,
        gender,
        dob,
        medicalConditions,
        currentMeds,
        allergyOption,
        metalItems
      })
    );

    dispatch(incrementCheckoutStep(1));
    dispatch(setCurrentStep('CHECKOUT_SELECT_LOCATION'));

    window.analytics.track('MedHistory Submit', { dateTime: moment().toISOString() });
  }, [dispatch, userId, gender, dob, medicalConditions, currentMeds, allergyOption, metalItems]);

  const onPrev = useCallback(() => {
    dispatch(incrementCheckoutStep(-1));
    dispatch(setCurrentStep('CHECKOUT_PERSONAL_INFO'));
  }, [dispatch]);

  return (
    <SchedulingUIWrapper>
      <div className='seven-cols'>
        <CheckoutSectionHeader
          imgSrc={MedicalIcon}
          headlineText='Medical History'
          subtext='Please fill out this short medical history so we can get a full
                picture of your health.'
        />
        <ChooseGender />
        <DoB />
        <MedicalCondition />
        <Symptoms />
        <Allergies />
        <Metal />
        <CheckoutNavigationHolder>
          <CheckoutNavigationButton onClick={onPrev} children='Previous Step' previous />
          <CheckoutNavigationButton onClick={onNext} children='Continue' disabled={isContinueDisabled} />
        </CheckoutNavigationHolder>
      </div>
      <MedHistoryRightPanel />
    </SchedulingUIWrapper>
  );
};

export default MedicalHistory;
