import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';

import Button from '../../components/UI/Button/Button';
import Text from '../../components/_styles/Text';
import Flex from '../../components/_styles/Flex';

const Close = styled.div`
  cursor: pointer;
`;

const AddToCalendarModal = ({ onClose, isOpen, action, mostRecentAppointment }) => {
  const originalDate = new Date(mostRecentAppointment.date_time);
  const startDate = originalDate
    .toISOString()
    .replace(/\.[0-9]{3}/, '')
    .match(/[a-z0-9]/gi)
    .join('');

  const addOneHour = new Date(originalDate).setHours(
    originalDate.getHours() + 1
  );
  const endDate = new Date(addOneHour)
    .toISOString()
    .replace(/\.[0-9]{3}/, '')
    .match(/[a-z0-9]/gi)
    .join('');

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onClose}>
      <Flex flexDirection="column" height="100%">
        <Flex.Col mb={30} isFlex justifyContent="space-between">
          <Text fontSize={24} fontWeight="bold">
            Add to Calendar
          </Text>
          <Close
            onClick={onClose}
            dangerouslySetInnerHTML={{ __html: '&times' }}
          />
        </Flex.Col>
        <Flex.Col flex="1">
          <Button
            as="a"
            block
            primary
            href={`https://calendar.google.com/calendar/r/eventedit?text=Within Health ${action.title}&dates=${startDate}/${endDate}`}
            target="_blank"
          >
            Add to Google Calendar
          </Button>
        </Flex.Col>
      </Flex>
    </ReactModal>
  );
};

export default AddToCalendarModal;
