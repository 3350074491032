import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  height: 50px;
  padding: 15px;
  border: 1px solid #c2ccd9;
  border-radius: 5px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #3f4857;

  &:focus {
    border: 1px solid #00AEEF;
  }

  &::placeholder {
    color: #8692a4;
  }
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3f4857;
`;

const Field = ({ className, name, label, input, ...props }) => {
  const fieldName = input ? input.name : name;
  const fieldProps = input ? { ...input, ...props } : props;

  return (
    <div className={className}>
      <Label htmlFor={fieldName} children={label} />
      <Input id={fieldName} name={fieldName} {...fieldProps} />
    </div>
  );
};

export default styled(Field)``;
