import { FLASH_SUCCESS, FLASH_CLOSE, FLASH_ERROR } from '../actions/flash';

const initialState = {
    open: false,
    message: '',
    className: '',
    error: null,
    persist: false
};

export default (state = initialState, action) => {
    switch (action.type) {
    case FLASH_SUCCESS:
        return {
            ...state,
            message: action.payload,
            className: 'flash-success',
            open: true,
            persist: action.persist
        };
    case FLASH_ERROR:
        return {
            ...state,
            message: action.payload,
            className: 'flash-error',
            open: true,
            persist: action.persist
        };
    case FLASH_CLOSE:
        return {
            ...state,
            className: '',
            message: '',
            open: false,
            persist: action.persist
        };
    default:
        return state;
    }
};
