import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const Modal = styled.div`
  display: ${(props) => (props.showModal ? 'block' : 'none')};
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  padding-top: 80px;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  animation: changeWidth 8s ease-in-out infinite;
  background: rgba(12, 41, 69, 0.38);
  backdrop-filter: blur(10px);
  border-right: 1px solid whitesmoke;
`;
const ModalContentContainer = styled.div`
  /* max-width: 1100px; */
  width: 80%;
  min-height: 500px;
  height: 62%;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
`;

const ModalContent = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  border-radius: 10px;
  @media (max-width: 768px) {
    margin: 0;
  }
`;

const CloseBtn = styled.span`
  display: inline-block;
  float: left;
  margin-right: 40px;
  margin-top: 15px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ce2030;
  &:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`;

const TitleText = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  padding-top: 20px;
  padding-left: 20px;
  color: #0c2945;
`;

const ActionsWrapper = styled.div`
  position: absolute;
  bottom: -25px;
  right: 20px;
  @media (max-width: 768px) {
    position: static;
    margin: 0 auto;
    justify-content: center;
  }
`;

const AddButton = styled.button`
  min-width: 150px;
  height: 50px;
  background-color: #00AEEF;
  color: #ffffff;
  /* opacity: ${(props) => (props.disabled ? 0.65 : 1)}; */
  border-radius: 5px;
  border: 0;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`;

const WrapperBox = styled.div`
  padding: 10px;
`;

const BorderBox = styled.div`
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 45px 21px;
  margin: 10px;
  display: inline-block;
  cursor: pointer;
`;

const StepBtn = styled.button`
  background: ${(props) => (props.bg ? props.bg : '#e6ffec')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '5px'};
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${(props) => (props.color ? props.color : '#64c67c')};
  border: none;
  display: block;
  min-width: 180px;
  min-height: 40px;
  margin: auto;
`;

function NewSequenceModal({
  showModal,
  closeModal,
  selectTimeDelay,
  addMessageStep,
}) {
  const [steps, setSteps] = useState([
    { type: 'green', text: 'SMS/Text Message', btn: 'sms' },
    // { type: "blue", text: "Email", btn: 'email' },
    // { type: "yellow", text: "E-Fax", btn: 'email' },
    // { type: "yellow", text: "HL7", btn: 'email' },
    { type: 'timeDelay', text: 'Time Delay' },
    // { type: "red", text: "Exit/Remove", btn: 'sms' },
  ]);

  const onCloseModal = (e) => {
    e.stopPropagation();
    closeModal();
  };

  const onAddToSequence = (e, dates, history) => {
    closeModal();
  };

  const onAddTimeDelay = (e) => {
    selectTimeDelay();
  };

  const getBG = (type) => {
    if (type === 'green') return '#e6ffec';
    if (type === 'blue') return '#6F52ED';
    if (type === 'yellow') return '#FFD57C';
    if (type === 'red') return '#FF0000';
    if (type === 'timeDelay') return '#d9ecff';
  };

  const getColor = (type) => {
    if (type === 'green') return '#64c67c';
    if (type === 'blue') return '#F9FAFC';
    if (type === 'yellow') return '#00AEEF';
    if (type === 'red') return '#F9FAFC';
    if (type === 'timeDelay') return '#1a6dff';
  };

  return (
    <Modal showModal={showModal}>
      <ModalContentContainer>
        <ModalContent>
          <TitleText>Select a Action Type</TitleText>
          <WrapperBox>
            {steps &&
              steps.map((s, i) => {
                return (
                  <BorderBox
                    key={i}
                    onClick={() =>
                      s.type === 'timeDelay'
                        ? onAddTimeDelay(s)
                        : addMessageStep(s)
                    }
                  >
                    <StepBtn
                      bg={getBG(s.type)}
                      color={getColor(s.type)}
                      borderRadius={s.type === 'timeDelay' ? '31px' : ''}
                    >
                      {s.text}
                    </StepBtn>
                  </BorderBox>
                );
              })}
          </WrapperBox>

          <ActionsWrapper>
            <CloseBtn onClick={onCloseModal}>Cancel</CloseBtn>
            <AddButton onClick={onAddToSequence}>Add To Sequence</AddButton>
          </ActionsWrapper>
        </ModalContent>
      </ModalContentContainer>
    </Modal>
  );
}
export default NewSequenceModal;
