import {
    GET_PROVIDERS_AND_LOCATIONS_FETCH,
    GET_PROVIDERS_AND_LOCATIONS_RESOLVE,
    GET_PROVIDERS_AND_LOCATIONS_REJECT,
    GET_PROVIDERS_RESOLVE,
    GET_LOCATIONS_RESOLVE,
    SET_LOCATIONS_LAST_PAGE
} from '../actions/provider';

const initialState = {
    loading: false,
    providers: [],
    locations: [],
    locationsPagination: {
        lastPage: 1,
        hasNextPage: false
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
    case GET_PROVIDERS_AND_LOCATIONS_FETCH:
        return {
            ...initialState,
            loading: true
        };
    case GET_PROVIDERS_AND_LOCATIONS_RESOLVE:
        return {
            ...state,
            loading: false
        };
    case GET_PROVIDERS_AND_LOCATIONS_REJECT:
        return {
            ...initialState,
            loading: false
        };
    case GET_PROVIDERS_RESOLVE:
        return {
            ...state,
            providers: [...state.providers, ...action.payload.results]
        };
    case GET_LOCATIONS_RESOLVE:
        return {
            ...state,
            locations: [...state.locations, ...action.payload.results],
            locationsPagination: {
                ...state.locationsPagination,
                hasNextPage: Boolean(action.payload.next)
            }
        };
    case SET_LOCATIONS_LAST_PAGE:
        return {
            ...state,
            locationsPagination: {
                ...state.locationsPagination,
                lastPage: action.payload
            }
        };
    default:
        return state;
    }
};
