import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Woman from '../../../assets/checkout/woman.png';

const PanelHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Panel = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 30px;
  }
`;

const CommandHolder = styled.div`
  background: #f2f4f9;
  border-radius: 12px 12px 12px 0px;
  padding: 24px;
  font-size: 0.889rem;
  line-height: 1rem;
  color: #3f4857;
`;

const ImgHolder = styled.div`
  margin-top: 20px;
`;

const WomanImg = styled.img`
  height: 36px;
  width: auto;
`;

const Headline = styled.div`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.278rem;
  color: #0c2945;
`;

const OrderSummaryHolder = styled.div`
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 15px;
`;

const OrderSummary = styled.div`
  padding: 30px;
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopContainer = styled.div`
  display: flex;
`;

const BodyImg = styled.img`
  width: 40px;
  height: auto;
`;

const TopTextHolder = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

const BoldText = styled.div`
  font-weight: bold;
  font-size: 0.889rem;
  line-height: 1rem;
  color: #0c2945;
`;

const ProductDescription = styled.div`
  font-size: 0.778rem;
  line-height: 1rem;
  color: #3f4857;
  margin-top: 5px;
`;

const Divider = styled.div`
  border: 0.5px solid #c2ccd9;
  margin-top: 25px;
  margin-bottom: 15px;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OrderSummaryPanel = ({ plan, ...props }) => {
  return (
    <div {...props}>
      <Headline>Order summary</Headline>
      <OrderSummaryHolder>
        <OrderSummary>
          <ContentHolder>
            <TopContainer>
              <BodyImg src={plan.image} />
              <TopTextHolder>
                <BoldText>{plan.name}</BoldText>
                <ProductDescription></ProductDescription>
              </TopTextHolder>
            </TopContainer>
            <Divider />
            <BottomContainer>
              <BoldText>Total price</BoldText>
              <BoldText>{`$${plan.price / 100}`}</BoldText>
            </BottomContainer>
          </ContentHolder>
        </OrderSummary>
      </OrderSummaryHolder>
    </div>
  );
};

export const PaymentRightPanel = ({ style }) => {
  const plan = useSelector((state) => state.scan.items.find((i) => i.id === state.checkout.planId) ?? {});

  return (
    <PanelHolder className='five-cols' style={style}>
      <Panel>
        <div>
          <CommandHolder>
            Please enter your payment information and confirm your order summary for <b>{plan.name}</b>
          </CommandHolder>
          <ImgHolder>
            <WomanImg src={Woman} />
          </ImgHolder>
          <OrderSummaryPanel style={{ marginTop: 40 }} plan={plan} />
        </div>
      </Panel>
    </PanelHolder>
  );
};

export default PaymentRightPanel;
