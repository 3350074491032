import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { Title } from "./RecomendationModalities";
import { Row, Col, Spinner } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Cell,
} from "recharts";
import Flex from "../../../components/_styles/Flex";
import  { Square } from "./LegendItem";
import { ReactComponent as TriangleDownIcon } from "../../../assets/triangle-down.svg";
import { AdherenceTimeFilter } from "./AdherenceTimeFilter";
import Filter from "./RecommendationFilter";
import {
  CloseSign,
  Image,
  SelectedItemContainer,
} from "./../FollowUpList/FollowUpList";
import {
  filterTypes,
} from "../../RadAdmin/FollowUpList/FollowUpList";
import { DEFAULT_VALUES } from "./HistoricalAdheranceFilter";
import close from "../../../assets/cancel-outline-chip.svg";
import RoundedBar from "./RoundedBar";
import { useSelector } from "react-redux";

const SelectedFilters = styled.div`
    display:'flex',
    flex-wrap:'wrap',
    width:'100%',
    justify-content: 'right'
`;

const FilterCard = styled(Flex)`
  justify-content: flex-end;
  flex-grow: 1;
  margin-right:14px;
`;

export const Card = styled.div`
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  border-radius: 8px;
`;

export const TitleAndLegend = styled(Flex)`
  //   padding-bottom: 10px;
  //   border-bottom: 1px solid #EEF0F7;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  display: block;
  margin: 16px 16px 50px 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const DatePickerContainer = styled(Flex)`
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

export const TooltipContainer = styled.div`
  padding: 10px;
  background: #e3e3e3;
  border-radius: 8px;
`;

export const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
`;

export const DateContainer = styled(Flex)`
  margin-right: 15px;
`;

export const TooltipRow = styled(Flex)`
  align-items: center;
`;

const COLORS = [
  { label: "<3 months", value: "#186EF0" },
  { label: "3-6 months", value: "#4A92FE" },
  { label: "6-12 months", value: "#87B7FF" },
  { label: ">12 months", value: "#BBD6FF" },
];


const CustomTooltip = ({ active, payload, label }) => {
  let requiredObj = COLORS.find((x) => x.label === label);
  if (active && payload) {
    return (
      <TooltipContainer>
        <div>{label}</div>
        <TooltipRow>
          <Square color={requiredObj?.value || ""} />
          <div>{`Recommendations: ${payload[0]?.payload?.count}`}</div>
        </TooltipRow>
      </TooltipContainer>
    );
  }

  return null;
};

const RecommendationsTimeframe = ({
  data,
  handleFiltersChange,
  dateRangeChange,
  graphStartDate = "",
  graphEndDate = "",
}) => {
  const [filterValuesForRequest, setFilterValuesForRequest] =
    useState(DEFAULT_VALUES);
  const [filterBy, setFilterBy] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const loading = useSelector(state => state.radAdmin?.recommendationTimeframe.isLoading);


  let graphData = [
    { interval: "<3 months", count: data?.lt_three_months || 0 },
    { interval: "3-6 months", count: data?.three_to_six_months || 0 },
    { interval: "6-12 months", count: data?.six_to_twelve_months || 0 },
    { interval: ">12 months", count: data?.gt_twelve_months || 0 },
  ];

  useEffect(() => {
    handleFiltersChange(filterValuesForRequest);
  }, [filterValuesForRequest]);

  const closeSelected = (category) => {
    setPageNumber(1);
    setFilterValuesForRequest((prev) => ({ ...prev, [category]: "" }));
  };

  return (
    <Card>
      <Header>
        <Row>
          <Col md={12} sm={12} lg={12} xl={12}>
            <Row>
              <Col>
                <TitleAndLegend>
                  <Title right="50px">Recommendation Timeframe Distribution</Title>
                  <AdherenceTimeFilter
                    dateRangeChange={dateRangeChange}
                    graphStartDate={graphStartDate}
                    graphEndDate={graphEndDate}
                  />
                </TitleAndLegend>
              </Col>
              <div>
                
                  <div style={{ display: "flex" }}>
                    <SelectedFilters
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "right",
                        alignItems: "center",
                        fontSize: "10px",
                      }}
                    >
                      {Object.values(filterValuesForRequest)?.map(
                        (item, idx) => {
                          if (!item) return null;
                          return (
                            <SelectedItemContainer
                              style={{ display: "flex", alignItems: "center" }}
                              key={idx}
                            >
                              {Number.isInteger(item.value)
                                ? item.label
                                : item.value.replace(/_/g, " ")}
                              <CloseSign
                                onClick={() =>
                                  closeSelected(item.category)
                                }
                              >
                                <Image src={close} alt={"close sign"} />
                              </CloseSign>
                            </SelectedItemContainer>
                          );
                        }
                      )}
                    </SelectedFilters>
                    <FilterCard>
                      <Filter
                        filterBy={filterBy}
                        setFilter={setFilterBy}
                        filterTypes={filterTypes}
                        setFilterBy={setFilterBy}
                        setPageNumber={setPageNumber}
                        filterValuesForRequest={filterValuesForRequest}
                        setFilterValuesForRequest={setFilterValuesForRequest}
                        marginRight="true"
                      />
                    </FilterCard>
                  </div>
                
              </div>
            </Row>
          </Col>
        </Row>
      </Header>

      <ResponsiveContainer width="100%" height={320}>
      {
                loading?  
                <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner animation="border" role="status" size="sm" /> 
              </div>:  <BarChart
          data={graphData}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid strokeDasharray="5 5" vertical={false} />
          <XAxis
            fontWeight={700}
            stroke="#A6ACBE"
            fontSize="14px"
            dataKey="interval"
            axisLine={false}
            tickLine={false}
            scale="point"
            padding={{ left: 112, right: 243 }}
          />
          <YAxis
            label={{
              value: "Recommendations",
              angle: -90,
              position: "insideLeft",
              fontSize: "14px",
              fill: "#A6ACBE",
              textAnchor: "middle", 
              dy: 60, 
            }}
            fontWeight={700}
            stroke="#A6ACBE"
            fontSize="14px"
            axisLine={false}
            tickLine={false}
          />

          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="count" barSize={30} shape={<RoundedBar />}>
            {COLORS.map((item, index) => (
              <Cell key={`cell-${index}`} fill={item.value} />
            ))}
          </Bar>
        </BarChart>
         }
       
      </ResponsiveContainer>
    </Card>
  );
};

export default RecommendationsTimeframe;
