import React from 'react'
import {
    ModifiedFormRow,
} from "./common";
import { FormItem } from './CreateCampaign';
import {TextField, TextareaAutosize } from '@material-ui/core';
import { FormLabel } from "../../../../components/Design/form";
import { makeStyles } from '@material-ui/core/styles';
import CampaignType from './CampaignType';

const useStyles = makeStyles({
    textField: {
        '& .MuiOutlinedInput-input': {
            fontSize: 14,
            padding: '13.5px 14px'
        }
    },
})

function Setup({ campaignName, description, goal, onChange, campaignTypeView, nextHandler }) {
    const classes = useStyles();

    return (
        <div>
            {
                !campaignTypeView ?
                    <form>
                        <ModifiedFormRow>
                            <FormItem>
                                <FormLabel>Campaign Name</FormLabel>
                                <TextField
                                    id="outlined-uncontrolled"
                                    variant="outlined"
                                    className={classes.textField}
                                    fullWidth={true}
                                    value={campaignName}
                                    name="campaignName"
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                />
                            </FormItem>
                        </ModifiedFormRow>
                        <ModifiedFormRow>
                            <FormItem>
                                <FormLabel>Description</FormLabel>
                                <TextareaAutosize
                                    maxRows={6}
                                    aria-label="maximum height"
                                    value={description}
                                    name="description"
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                    style={{
                                        width: "100%",
                                        fontSize: 13,
                                        color: '#3F4857',
                                        fontFamily: 'Roboto Condensed',
                                        display: 'inline',
                                        height: 170,
                                        lineHeight: 2,
                                        resize: "none",
                                        border: '1px solid #c2ccd9',
                                        borderRadius: 8,
                                        padding: 10
                                    }}
                                    placeholder='Add an optional description for your campaign'
                                >
                                </TextareaAutosize>
                            </FormItem>
                        </ModifiedFormRow>
                        <ModifiedFormRow>
                            <FormItem>
                                <FormLabel>Campaign Goal</FormLabel>
                                <TextField
                                    id="outlined-uncontrolled"
                                    variant="outlined"
                                    className={classes.textField}
                                    fullWidth={true}
                                    value={goal}
                                    name="goal"
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                />
                            </FormItem>
                        </ModifiedFormRow>
                    </form> :
                    <CampaignType nextHandler={nextHandler}/>
            }
        </div>
    )
}

export default Setup
