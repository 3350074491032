import API from '../../libs/api';
import { showAlert } from '../radminUploads';

export const LOGIN = 'login';
export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_FAILED = 'login_failed';
export const LOGIN_COMPLETED = 'LOGIN_COMPLETED';

export const SIGNUP = 'signup';
export const SIGNUP_SUCCESS = 'signup_success';
export const SIGNUP_FAILED = 'signup_failed';

export const LOGGED_IN = 'logged_in';
export const LOGOUT = 'logout';
export const LOGGED_OUT = 'logged_out';

export const GET_USER_INFO = 'get_user_info';
export const GET_USER_INFO_SUCCESS = 'get_user_info_success';

export const RESET_AUTH_ERROR = 'RESET_AUTH_ERROR';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const UPDATE_CONTACT_INFO = 'UPDATE_CONTACT_INFO';
export const UPDATE_CONTACT_INFO_SUCCESS = 'UPDATE_CONTACT_INFO_SUCCESS';
export const UPDATE_CONTACT_INFO_FAILED = 'UPDATE_CONTACT_INFO_FAILED';

export const PATCH_USER_DATA = 'PATCH_USER_DATA';

export const UPDATE_DOCTOR_PRACTICE = 'UPDATE_DOCTOR_PRACTICE';
export const UPDATE_DOCTOR_PRACTICE_SUCCESS = 'UPDATE_DOCTOR_PRACTICE_SUCCESS';
export const UPDATE_DOCTOR_PRACTICE_FAILED = 'UPDATE_DOCTOR_PRACTICE_FAILED';

export const CREATE_VERIFICATION_DEVICE_REQUEST = 'CREATE_VERIFICATION_DEVICE_REQUEST';
export const CREATE_VERIFICATION_DEVICE_SUCCESS = 'CREATE_VERIFICATION_DEVICE_SUCCESS';

export const SET_IS_HAVE_UPLOADS = 'SET_IS_HAVE_UPLOADS';

export const patchUserData = (payload) => ({ type: PATCH_USER_DATA, payload });

export const updateContactInfo = ({ userId, contactInfoId, street, city, state, zipCode }) => {
  return async (dispatch) => {
    try {
      const updateContactInfo = await API.putRequest(`/api/v1/user/${userId}/contact_info/${contactInfoId}/`, {
        address1: street,
        address2: '',
        city,
        state: state.value,
        zip_code: zipCode
      });
      dispatch({
        type: UPDATE_CONTACT_INFO_SUCCESS,
        payload: updateContactInfo.data
      });
    } catch (err) {
      dispatch({
        type: UPDATE_CONTACT_INFO_FAILED,
        err
      });
      console.log(err.message);
    }
  };
};

export const updateUser = ({ firstName, lastName, email, phoneNumber, userId, contactInfoId }) => {
  return async (dispatch) => {
    try {
      const [contactInfo, user] = await Promise.all([
        API.getRequest(`/api/v1/user/${userId}/contact_info/`),
        API.patchRequest(`/api/v1/user/${userId}/`, {
          first_name: firstName,
          last_name: lastName,
          email: email
        })
      ]);
      const contactInfoId = contactInfo.data.results[0].id;
      const updateContactInfo = await API.patchRequest(`/api/v1/user/${userId}/contact_info/${contactInfoId}/`, {
        phone_number: phoneNumber
      });

      let payload;
      if (phoneNumber) {
        payload = {
          ...user.data,
          contactInfo: updateContactInfo.data
        };
      } else {
        payload = {
          ...user.data,
          contactInfo: contactInfo.data
        };
      }

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload
      });
    } catch (err) {
      dispatch({
        type: UPDATE_USER_FAILED,
        err
      });
    }
  };
};

export const login = ({ email, password, history }) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: LOGIN });

      const response = await API.postRequest('/auth/token/login/', { email, password });

      dispatch({ type: LOGIN_SUCCESS, payload: { token: response.data.auth_token, user: { email } } });

      const user = await dispatch(loadUserInfo());
      if (user.mfa_enabled && user.verification_device === null) {
        history.push('/verification-for-device');
        return;
      }
      if (user.mfa_enabled && user.verification_device && !user.is_verified) {
        history.push('/multi-factor-authentication');
        return;
      }

      if(user.is_verified){
        dispatch({ type: LOGIN_SUCCESS, payload: { token: getState().auth.token, user: { email: getState().auth.user.email }, isVerifiedUser: true } });
        window.analytics.track('Login Success', { email });

        if (user.role === 'doctor') {
          history.push('/auth/appointments');
          return;
        }
        const isUserHaveUploads = getState().auth.user.isHaveUploads;
  
        if (user.role === 'radiology_admin' && isUserHaveUploads) {
          history.push('/auth/radAdmin/Overview');
          return;
        }
        if (user.role === 'radiology_admin' && !isUserHaveUploads) {
          history.push('/auth/radAdmin/Dashboard');
          return;
        }
  
        const planId = getState().checkout.planId;
  
        if (planId) {
          history.push(`/plans/${planId}/checkout`);
          return;
        }
  
        history.push('/auth/dashboard');
      }
   
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGIN_FAILED, payload: { error: 'Incorrect email and/or password.' } });
      window.analytics.track('Login Error', { email });
    } finally{
      dispatch({ type: LOGIN_COMPLETED });
    }
  };
};

export const signup = ({ email, password, history }, isDoctor) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SIGNUP
      });

      await API.postRequest('/auth/users/', {
        email,
        password
      });

      dispatch({
        type: SIGNUP_SUCCESS
      });

      const response = await API.postRequest('/auth/token/login/', {
        email,
        password
      });

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token: response.data.auth_token,
          user: {
            email
          },
          isVerifiedUser: true
        }
      });

      await dispatch(loadUserInfo());

      if (!isDoctor) {
        history.push('/login');
      }

      window.analytics.track('New User Success', { email });
    } catch (err) {
      dispatch({
        type: SIGNUP_FAILED,
        payload: err.response.data || 'Something went wrong'
      });

      window.analytics.track('New User Error', { email });
    }
  };
};

export const loadUserInfo = () => {
  return async (dispatch) => {
    try {
      const me = await API.getRequest(`/auth/users/me/`);
      const user = await API.getRequest(`/api/v1/user/${me.data.id}/`);
      let roles;
      if(user.data.permission_level){
       roles = await API.getRequest(`/api/v1/invited-users-access/?permission_level=${user.data.permission_level}&page_size=${100}`);
      }
      if(user.data.mfa_enabled && !user.data.is_verified){
        return user.data
      }

      window.analytics.identify(me.data.id, { email: me.data.email, role: user.data.role });
      // const [doctor, contactInfo, healthHistory, uploads, report2s] = await Promise.all([
      //   API.getRequest(`/api/v1/user/${me.data.id}/doctor/`),
      //   API.getRequest(`/api/v1/user/${me.data.id}/contact_info/`),
      //   API.getRequest(`/api/v1/user/${me.data.id}/health_history/`),
      //   API.getRequest(`/api/v1/upload/`),
      //   API.getRequest(`/api/v1/report2/aggregate/?agg_func=count&distinct=false&field=id`)
      // ]);

      // const medicalConditions =
      //   healthHistory.data.results[0]?.medical_conditions &&
      //   (await Promise.all(
      //     healthHistory.data.results[0]?.medical_conditions.map(async (mc) => {
      //       const description = await API.getRequest(`/api/v1/medical_condition/${mc}`);
      //       return {
      //         id: mc,
      //         description: description.data.name
      //       };
      //     })
      //   ));

      // const provider = await API.getRequest(`/api/v1/provider/${user.data.provider}/`);

      // const isHaveUploads = report2s.data.count !== 0;
      const isHaveUploads = true;

      const payload = {
        ...user.data,
        // provider_name: provider.data.name,
        // doctor: doctor.data.results[0],
        // contactInfo: contactInfo.data.results[0],
        // healthHistory: healthHistory.data.results.length ? healthHistory.data.results[0] : {},
        isHaveUploads,
        permissions:roles?.data?.results || []
      };

      dispatch({
        type: GET_USER_INFO_SUCCESS,
        payload: {
          user: payload
        }
      });

      return payload;
    } catch (error) {
      console.error(error);
      dispatch(logout());
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    await API.postRequest('/auth/token/logout/').catch(console.error);
    dispatch({ type: LOGOUT });
    window.analytics.reset();
  };
};

export const resetAuthError = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: RESET_AUTH_ERROR
      });
    } catch (err) {}
  };
};

export const updateDoctorPracticeInfo = ({ userId, id, npi }) => {
  return async (dispatch) => {
    try {
      const updateDoctorInfo = await API.putRequest(`/api/v1/user/${userId}/doctor/${id}/`, {
        id: id,
        npi_number: npi
      });
      dispatch({
        type: UPDATE_DOCTOR_PRACTICE_SUCCESS,
        payload: updateDoctorInfo.data
      });
    } catch (err) {
      dispatch({
        type: UPDATE_DOCTOR_PRACTICE_FAILED,
        err
      });
      console.log(err.message);
    }
  };
};

export const SetupVerificationDevice = ( phoneNumber ) => {
  return async (dispatch) => {
      dispatch({ type: CREATE_VERIFICATION_DEVICE_REQUEST })
      await API.postRequest(`/api/v1/verification_device/`, {
        phone_number: `+${phoneNumber}`
      })
  };
};

export const MultiFactorAuthentication = ( code ) => {
  return async (dispatch, getState) => {
    try {
      await API.postRequest(`/api/v1/verification_device/verify_token/`, {
        token: code
      });
      const user = await dispatch(loadUserInfo())
      if(user.is_verified){
        dispatch({ type: LOGIN_SUCCESS, payload: { token: getState().auth.token, user: { email: getState().auth.user.email }, isVerifiedUser: true } });
        return {success: true, user: getState().auth.user }
      }
    } catch (err) {
      return err
    }
  };
};

export const resendVerificationToken = () => {
  return async (dispatch) => {
    try {
      await API.getRequest(`/api/v1/verification_device/resend_token`);
      return {
        success: true
      }
    } catch (err) {
      dispatch(showAlert('danger', 'Error', err.message));
    }
  };
};
