import styled from "styled-components";

const FormRow = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  color: black;
`;

const FormGroup = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Form = styled.form`
  margin-top: 40px;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormLabel = styled.div`
  font-weight: 600;
  font-size: 0.778rem;
  line-height: 1rem;
  color: #3f4857;
  margin: 12px 0px;
`;

const Input = styled.input`
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 5px;
  color: #8692a4;
  font-size: 0.778rem;
  line-height: 1rem;
  padding: 12px;

  :focus {
    border: 1px solid #00AEEF;
  }
`;

const InputHolder = styled.div`
  border: 1px solid #c2ccd9;
  border-radius: 5px;
  padding: 10px;
`;

const InputSeparator = styled.div`
  padding: 0px 10px;
`;

export { FormRow, Form, FormGroup, FormItem, FormLabel, InputHolder, InputSeparator, Input };
