import React, { useEffect } from 'react';
import { LinearProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Progress from './components/Progress';
import PDFViewer from './components/PDFViewer';
import Information from './components/Information';
import Prescriptions from './components/Prescriptions';
import PrescriptionB2B from './components/PrescriptionB2B';
import PrescriptionsPDF from './components/PrescriptionsPDF';
import RisksAndRecommendations from './components/RisksAndRecommendations';
import { HFlex, VFlex } from './components/Common';
import { getPatientProfile } from '../../actions/patientProfile';

const ReponsiveHFlex = styled(HFlex)`
  @media (max-width: 1160px) {
    display: block;
  }
`;

const ReponsiveVFlex = styled(VFlex)`
  @media (max-width: 1160px) {
    margin-right: 0;
    padding-top: 10px;
  }
`;

const Index = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const report = useSelector((state) => state.patientProfile.report);
  const isPrescriptionViewActive = useSelector((state) => state.patientProfile.prescription);
  const isB2BPrescriptionViewActive = useSelector((state) => state.patientProfile.b2bPrescription.active);
  const isLoading = useSelector((state) => state.patientProfile.loading);

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const scanPurchaseId = Number(search.get('scan_purchase'));

    dispatch(getPatientProfile(id, scanPurchaseId));
  }, []);

  if (isPrescriptionViewActive) {
    return (
      <HFlex p='30px'>
        <VFlex flex='1'>
          <PrescriptionsPDF />
        </VFlex>
      </HFlex>
    );
  }

  if (isB2BPrescriptionViewActive) {
    return <PrescriptionB2B />;
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <ReponsiveHFlex p='30px'>
        <ReponsiveVFlex mr='30px' flex='3'>
          <Information />
        </ReponsiveVFlex>
        <ReponsiveVFlex flex='2'>
          <Prescriptions />
        </ReponsiveVFlex>
      </ReponsiveHFlex>
      <VFlex p='0 30px 30px 30px'>
        <Progress />
      </VFlex>
      {report?.pdf_report && (
        <VFlex p='0 30px 30px 30px'>
          <h4>Risks & Recommendations</h4>
          <RisksAndRecommendations />
        </VFlex>
      )}
      {report?.pdf_report && (
        <VFlex p='0 30px 30px 30px'>
          <h4>Report</h4>
          <PDFViewer src={report.pdf_report} />
        </VFlex>
      )}
    </>
  );
};

export default Index;
