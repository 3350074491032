import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as ChevronRightIcon } from "../../../../assets/chevron_right.svg";
import Flex from "../../../../components/_styles/Flex";
import DoubleDataCell from "../LastScanCell";
import { generateColor } from "../../../../utils/utils";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const TableRow = styled.div`
  display: flex;
  position: relative;
`;

const TableRowItem = styled.div`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
`;

const PatientCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #DFE0EB;
`;

const PatientCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 46px;
  height: 92px;
`;

const StyledChevron = styled(ChevronRightIcon)`
  transform: rotate(${(props) => props.expanded});
  width: 26px;
  height: 26px;
  cursor: pointer;
  & > path {
    fill: #9fa2b4;
  }
  &:hover > path {
    fill: #3f4857;
    transition: all 0.3s ease;
  }
`;

export const AvatarContainer = styled(Flex)`
  margin: 25px 21px 0 0;
`;

export const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
`;

export const UserEmptyAvatar = styled(Flex)`
  background-color: ${(props) => props.bgcolor};
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: #666b86;
  border: 2px solid #ffffff;
  border-radius: 50%;
`;

const StyledLink = styled(Link)`
 color:#00AEEF;
 font-size:14px;
 font-weight:400;
 text-decoration:underline;
` 

export const UserIcon = ({ name, avatar }) => {
  const splitName = name.split(" ");
  const initials = (
    (splitName[0] ? splitName[0].charAt(0) : "") +
    (splitName[1] ? splitName[1].charAt(0) : "")
  ).toUpperCase();
  return (
    <Flex>
      <AvatarContainer>
        {avatar ? (
          <UserAvatar src={avatar} />
        ) : (
          <UserEmptyAvatar bgcolor={generateColor()}>
            {initials}
          </UserEmptyAvatar>
        )}
      </AvatarContainer>
    </Flex>
  );
};

const PatientInfoCard = ({ patientItem, needMoreDetails, bgLine }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const history = useHistory();

  const getPatientDetail = patient => {
    setIsExpanded(!isExpanded);
    window.analytics.track('Patient-Detail-Click');
    patient.id &&
      history.push(
        `/auth/radAdmin/followUpList/patients/patientDetails/${patient?.id}`
      );
  };

  return (
    <TableRow bgLine={bgLine}>
      <PatientCard>
        <PatientCardHeader>
          <TableRowItem flex={2}>
            <DoubleDataCell
              textAlign="left"
              fontWeight="400"
              textSize="14px"
              mainInfo={`${patientItem.first_name} ${patientItem.last_name}`}
            />
          </TableRowItem>
          <TableRowItem>
          <StyledLink
            to={`/auth/radAdmin/followUpList/patients/patientDetails/${patientItem.id}`}
            onClick={(e)=>{
            e.stopPropagation();
            window.analytics.track('Follow-Up-List-Patient-MRN-Click',{patientId: patientItem.id})
           }}
           >
           {patientItem.mrn}
          </StyledLink>
            {/* <DoubleDataCell fontWeight="400" mainInfo={patientItem.mrn} /> */}
          </TableRowItem>
          <TableRowItem>
            <DoubleDataCell textSize="14px"  textAlign="left" fontWeight="400" mainInfo={patientItem.email} />
          </TableRowItem>
          <TableRowItem>
            <DoubleDataCell textSize="14px"  textAlign="left" fontWeight="400" mainInfo={patientItem.phone_number} />
          </TableRowItem>
          {/* <TableRowItem flex={0.5}>
            <StyledChevron
              expanded={isExpanded ? "90deg" : "0deg"}
              onClick={() => getPatientDetail(patientItem)}
            />
          </TableRowItem> */}
        </PatientCardHeader>
      </PatientCard>
    </TableRow>
  );
};

export default PatientInfoCard;
