import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { grid, space } from 'styled-system';
import Box from './Box';
import { media } from '../../theme';

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px;
  grid-template-rows: 1fr;

  ${grid}
`;

const Col = styled(Box)`
  ${grid}
  ${space}

  ${(props) =>
    props.col &&
    css`
      grid-column: auto / span 12;

      ${media.md`
        grid-column: auto / span ${props.col};
      `}
    `}
`;

Col.propTypes = {
  col: PropTypes.number,
};

Grid.Col = Col;

export default Grid;
