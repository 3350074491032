import styled from 'styled-components';
import Flex from '../../../components/_styles/Flex';

export const Panel = styled(Flex)`
  border: 1px solid #c2ccd9;
  border-radius: 8px;
  background-color: #4E67FE;
  color: #fff !important;
  margin-bottom: ${({ nomargin }) => (nomargin ? 0 : '30px')};
  flex-direction: column;
`;
export const MetricsPanel = styled(Flex)`
  flex-direction: row;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
  @media (max-width: 992px) {
    width: 100%;
    flex-direction: column;

  }
`;

export const IntegrationsPanel = styled(Flex)`
  border: 1px solid #c2ccd9;
  border-radius: 8px;
  background-color: #FFF;
  color: #000000;
  margin-bottom: ${({ nomargin }) => (nomargin ? 0 : '30px')};
  flex-direction: column;

  p {
    background-color: #3751FF;
    border-radius: 5px;
    color: #fff;
    text-align: center;
  }
  input {
    border: 0;
    border-radius: 0.25rem;
    background: #22b66e;
    color: white;
    font-family: system-ui, sans-serif;
    font-size: 1rem;
    line-height: 1.2;
    white-space: nowrap;
    text-decoration: none;
    padding: 0.25rem 0.5rem;
    margin: 0.25rem;
    cursor: pointer;
  }
`;

export const OverviewPanel = styled(Flex)`
  border: 1px solid #c2ccd9;
  display: flex;
  border-radius: 8px;
  background-color: #FFF;
  color: #000000;
  margin: 10px 20px ${({ nomargin }) => (nomargin ? 0 : '30px')} 0;
  flex-direction: column;

  strong {
    font-size: 1.5rem;
  }
  
  &:last-child {
    margin-right: 0;
  }
`;

export const ProgressIconWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 62px;
  transform: translateX(-50%);
`;

export const Button = styled.div`
  background: ##FFC343;
  margin: 1%;
`;

export const FileSelectButton = styled.input`
  background: ##FFC343;
`;

export const Progress = styled.div`
  height: 3px;
  background: #e6e9f1;
`;

Progress.Fill = styled.div`
  height: inherit;
  background: #47a0f7;
  width: ${(props) => props.step}%;
`;

export const ReportsHead = styled(Flex)`
  background: #e6e9f1;
  font-size: 14px;
  color: #3f4857;
  padding: 10px;
  border-radius: 8px;
`;

export const ReportsBody = styled.div`
  font-size: 14px;

  a {
    &:hover {
      text-decoration: none;
    }

    > div {
      border-bottom: 1px solid #c2ccd9;
      padding: 10px;

      cursor: pointer;

      &:hover {
        background: #f3f3f3;
      }
    }
  }
`;
