import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Switch } from "@material-ui/core";

const RecoSwitcher = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#00AEEF",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "white",
        border: "6px solid #fff",
      },
      "&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track": {
        backgroundColor: "black",
      },
    },
    thumb: {
      width: 24,
      height: 24,
      border: `2px solid #00AEEF`,
    },
    track: {
      borderRadius: 26 / 2,
      border: `2px solid #00AEEF`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {

    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
});

export default RecoSwitcher;