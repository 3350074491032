import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Box from '../../../components/_styles/Box';
import RadOnboarding from './Onboarding';



function RadAdminDashboard(){
  return (
    <Box p={30}>
      <Row>
        <Col md={12} lg={12} xl={10}>
          <h4>Onboarding</h4>
          <RadOnboarding />
        </Col>

      </Row>
    </Box>
  );
};

function mapStateToProps(state) {
  const { user } = state.auth;

  return {
    user,
  };
}

export default connect(mapStateToProps)(RadAdminDashboard);
