import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertContainer } from 'react-bs-notifier';
import InfiniteScroll from 'react-infinite-scroller';

import Row from './components/Row';
import { Container, Section, Header, Content } from './components/Common';
import {
  getAssignedAppointments,
  getAssignedAppointmentsNextPage,
  getUnassignedAppointments,
  getUnassignedAppointmentsNextPage,
  hideAlert,
  resetState
} from '../../actions/doctor';

const Index = () => {
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.auth.user.id);
  const alert = useSelector((state) => state.doctor.alert);
  const assignedAppointments = useSelector((state) => state.doctor.assignedAppointments.items);
  const unassignedAppointments = useSelector((state) => state.doctor.unassignedAppointments.items);
  const hasMoreAssigned = useSelector((state) => state.doctor.assignedAppointments.hasNextPage);
  const hasMoreUnassigned = useSelector((state) => state.doctor.unassignedAppointments.hasNextPage);

  const onDismiss = useCallback(() => {
    dispatch(hideAlert());
  }, [dispatch]);

  const onLoadMoreAssigned = useCallback(() => {
    dispatch(getAssignedAppointmentsNextPage(userId));
  }, [dispatch, userId]);

  const onLoadMoreUnassigned = useCallback(() => {
    dispatch(getUnassignedAppointmentsNextPage());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAssignedAppointments(userId));
    dispatch(getUnassignedAppointments());

    return () => {
      dispatch(resetState());
    };
  }, []);

  return (
    <>
      <AlertContainer>{alert.active && <Alert {...alert} onDismiss={onDismiss} />}</AlertContainer>
      <Container>
        <Section flex={1}>
          <Header type='primary' children='New Follow-up Appointments' />
          <Content>
            <InfiniteScroll
              loadMore={onLoadMoreUnassigned}
              hasMore={hasMoreUnassigned}
              initialLoad={false}
              useWindow={false}
              threshold={30}
            >
              {unassignedAppointments.map((a) => (
                <Row key={a.id} appointment={a} type='new' />
              ))}
            </InfiniteScroll>
          </Content>
        </Section>
        <Section flex={1.65}>
          <Header type='secondary' children='Upcoming Appointments' />
          <Content>
            <InfiniteScroll
              loadMore={onLoadMoreAssigned}
              hasMore={hasMoreAssigned}
              initialLoad={false}
              useWindow={false}
              threshold={30}
            >
              {assignedAppointments.map((a) => (
                <Row key={a.id} appointment={a} type='upcoming' />
              ))}
            </InfiniteScroll>
          </Content>
        </Section>
      </Container>
    </>
  );
};

export default Index;
