import axios from 'axios';

import { store } from '../reducers/store';
import CONSTANTS from '../utils/constants';
import { LOGOUT } from '../actions/auth';

axios.defaults.withCredentials = true
axios.interceptors.request.use((config) => {
    const token = store.getState().auth.token;

    if (token) {
        config.headers['Authorization'] = `Token ${token}`;
    }

    return config;
});


axios.interceptors.response.use((response) =>  {
    return response;
}, async (error) => {
    if ([401, 403].includes(error.response.status)) {
        store.dispatch({ type: LOGOUT })
        window.analytics.reset();
        window.location = '/login'
    } else {
        return Promise.reject(error);
    }
});

class API {
    constructor() {
        if (process.env.NODE_ENV === CONSTANTS.ENV.DEVELOPMENT && process.env.REACT_APP_RUN_LOCAL) {
            this.baseURL = CONSTANTS.API.LOCAL;
        }
        else if (process.env.NODE_ENV === CONSTANTS.ENV.DEVELOPMENT || window.location.host === CONSTANTS.ENV.STAGING_DEV) {
            this.baseURL = CONSTANTS.API.STAGING;
        }
        else if (process.env.NODE_ENV === CONSTANTS.ENV.DEVELOPMENT || window.location.host === CONSTANTS.ENV.STAGING_DEV_SAME_DOMAIN) {
            this.baseURL = CONSTANTS.API.STAGING;
        }
        else if (
            process.env.NODE_ENV === CONSTANTS.ENV.PRODUCTION ||
      window.location.host === CONSTANTS.ENV.STAGING_MASTER
        ) {
            this.baseURL = CONSTANTS.API.PRODUCTION;
        }
    }

    async putRequest(endpoint, data = {}) {
        return await axios.put(`${this.baseURL}${endpoint}`, data);
    }

    async patchRequest(endpoint, data = {}) {
        return await axios.patch(`${this.baseURL}${endpoint}`, data);
    }

    async postRequest(endpoint, data = {}) {
        return await axios.post(`${this.baseURL}${endpoint}`, data);
    }

    async deleteRequest(endpoint) {
        return await axios.delete(`${this.baseURL}${endpoint}`);
    }

    async postWithImageRequest(endpoint, data = {}, config = {}) {
        const defaultConfig = { headers: { 'Content-Type': 'multipart/form-data' } };
        return await axios.post(`${this.baseURL}${endpoint}`, data, { ...defaultConfig, ...config });
    }

    async getRequest(endpoint, headers) {
        return await axios.get(`${this.baseURL}${endpoint}`, headers);
    }
}

export default new API();
