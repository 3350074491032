import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import Flex from '../../components/_styles/Flex';
import Text from '../../components/_styles/Text';
import { Button } from '../../components/UI/Button';

const ReportCardWrapper = styled.div`
  height: 100%;
  padding: 25px;
  border-radius: 6px;
  border: 1px solid #d2d3d7;
  cursor: pointer;
  background: #fff;
  display: flex;
  flex-direction: column;

  &:hover {
    border: 1px solid #00AEEF;
  }
`;

const Field = styled(Flex)`
  ${Text} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${Text}:first-child {
    flex: 1;
    margin-right: 5px;
  }

  ${Text}:last-child {
    flex: 2;
  }
`;

// const ShareButton = styled.button.attrs({ type: 'button', children: 'Share report' })`
//   margin-top: 10px;
//   padding: 15px 32px;
//   border: none;
//   outline: none !important;
//   border-radius: 5px;
//   background: #d0f9da;
//   font-family: Roboto Condensed;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 14px;
//   line-height: 18px;
//   color: #00AEEF;
// `;

const ReportCard = ({ id, scan, date, doctor, office_location, pdf_report }) => {
  // const [isShareModalOpened, setShareModalOpened] = useState(false);

  const onClickShow = useCallback(() => {
    window.analytics.track('Show Report Button Click');
  }, []);

  const onClickDownload = useCallback(() => {
    window.analytics.track('Download Full Report click', { scan });
  }, [scan]);

  // const onOpenShareModal = useCallback(() => {
  //   setShareModalOpened(true);
  // }, []);

  // const onCloseShareModal = useCallback(() => {
  //   setShareModalOpened(false);
  // }, []);

  // const onSubmitFax = useCallback((value) => {
  //   console.log('FAX', value);
  //   setShareModalOpened(false);
  // }, []);

  // const onSubmitEmail = useCallback((value) => {
  //   console.log('EMAIL', value);
  //   setShareModalOpened(false);
  // }, []);

  return (
    <ReportCardWrapper>
      <Text children={scan} fontSize={2} mb={15} fontWeight='bold' />
      <Flex.Col mb={15} lineHeight='28px' flex='1'>
        <Field justifyContent='space-between'>
          <Text children='Report Date' fontSize={1} muted />
          <Text children={moment(date).format('MM/DD/YYYY')} fontSize={1} fontWeight='bold' />
        </Field>
        <Field justifyContent='space-between'>
          <Text children='Location' fontSize={1} muted />
          <Text children={`${office_location.provider.name} ${office_location.title}`} fontSize={1} fontWeight='bold' />
        </Field>
        <Field justifyContent='space-between'>
          <Text children='Doctor' fontSize={1} muted />
          <Text children={`${doctor.first_name} ${doctor.last_name}`} fontSize={1} fontWeight='bold' />
        </Field>
        {!pdf_report && (
          <Field justifyContent='space-between' flex='1'>
            <Text children='Radiologist' fontSize={1} muted />
            <Text children={`${doctor.first_name} ${doctor.last_name}`} fontSize={1} fontWeight='bold' />
          </Field>
        )}
      </Flex.Col>
      {pdf_report ? (
        <Button
          onClick={onClickDownload}
          children='Download Full Report'
          as='a'
          target='_blank'
          href={pdf_report}
          secondary
          block
        />
      ) : (
        <Button onClick={onClickShow} children='Show Report' as={Link} to={`/auth/reports/${id}`} secondary block />
      )}
      {/* Sharing functionality is commented out until we get backend for this done */}
      {/* <ShareButton onClick={onOpenShareModal} />
      <ShareModal
        opened={isShareModalOpened}
        onClose={onCloseShareModal}
        onSubmitFax={onSubmitFax}
        onSubmitEmail={onSubmitEmail}
      /> */}
    </ReportCardWrapper>
  );
};

export default ReportCard;
