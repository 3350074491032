import API from '../../libs/api';
import { showAlert } from '../../actions/radminUploads'
import moment from 'moment';


export const GET_ACTIVITY_LOGS_REQUEST = 'GET_ACTIVITY_LOGS_REQUEST';
export const GET_ACTIVITY_LOGS_SUCCESS = 'GET_ACTIVITY_LOGS_SUCCESS';
export const GET_ACTIVITY_LOGS_FAILURE = 'GET_ACTIVITY_LOGS_FAILURE';


export const getActivityData = (pageNumber=1, userData={}, date={}) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ACTIVITY_LOGS_REQUEST, payload : pageNumber });

    let responseData;
    let totalItems;
    let userFilter =  userData?.user?.length>0? userData?.user?.map(itm=>`&user=${itm.value}`).join(''):'';
    let dateFilter = date?.startDate && date?.endDate? `&changed_at__gte=${date.startDate}&changed_at__lte=${date.endDate}` :  '';
    
    const { data } = await API.getRequest(
      `/api/v1/reco_history/?page=${pageNumber}${userFilter}${dateFilter}&ordering=-pk`
    );
    responseData = data.results.map(itm=>({...itm, changed_at: moment(itm.changed_at).format('MM-DD-YYYY hh:mm A')}));
    totalItems = data.count;
    
    dispatch({
      type: GET_ACTIVITY_LOGS_SUCCESS,
      payload: {
        items: responseData,
        totalItems: totalItems,
        next: responseData.next,
        pageNumber: pageNumber,
      },
    });
  } catch (error) {
    dispatch({ type: GET_ACTIVITY_LOGS_FAILURE });
    dispatch(showAlert('danger', 'Error'));

  }
};




export const getUsers = () => async (dispatch) => {
    try {
      const { data } = await API.getRequest(
        `/api/v1/user/?page_size=${500}`
      );
      return data;
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };
  

  
export const getUserById = (id) => async (dispatch) => {
    try {
      const { data } = await API.getRequest(
        `/api/v1/user/${id}/`
      );
      return data;
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };
  