import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import ImagingAdherenceChart from './ImagingAdherenceChart';
import TreemapModalities from './TopModalities';
import AdherenceFunnelChart from './FunnelChart';
import { getImagingAdherenceAction, getModalitiesAction, getImagingAdherenceModalityOptionsAction, getFunnelAdherenceAction, getRecoModalitiesAction, getRecoAnatomiesAction, getRecommendationTimeframeAction, getAdherentCharges,getRecoAnatomiesAndModalitiesAction, getAttributeWindowCharges } from '../../../actions/radminUploads';
import { EmptyState } from '../../../components/EmptyState/EmptyState';
import { ReactComponent as EmptyStateDashboardImg } from '../../../assets/empty-state-dashboard.svg';
import RecomendationModalities from './RecomendationModalities';
import RecomendationAnatomies from './RecomendationAnatomies';
import AdherenceOfToday from './AdherenceOfToday';
import HistoricalAdherence from './HistoricalAdherence';
import RecommendationsTimeframe from './RecommendationsTimeframe';
import moment from 'moment';
import RevenueCard from './RevenueCard';
import ChargesTimeGraph from './ChargesTimeGraph';
import RecomendedAnatomiesAndModalities from './RecommendedAnatomiesAndModalities';
import KeyMetrics from './KeyMetrics';
import MarketingAttribution from './MarketingAttribution';

const Container = styled.div`
  padding-top: 30px
`

const BlockContainer = styled.div`
  padding: 0 30px;
`

function Overview(){
  const imagingAdherence = useSelector(state => state.radAdmin.imagingAdherence);
  const recommendationTimeframe = useSelector(state => state.radAdmin.recommendationTimeframe);
  const graphStartDate = useSelector(state => state.radAdmin?.recommendationTimeframe.startDate)
  const graphEndDate = useSelector(state => state.radAdmin?.recommendationTimeframe.endDate)
  const modalities = useSelector(state => state.radAdmin.modalities);
  const recoModalities = useSelector(state => state.radAdmin.recoModalities);
  const recoAnatomies = useSelector(state => state.radAdmin.recoAnatomies);
  const recoAnatomiesAndModalities = useSelector(state => state.radAdmin.recoAnatomiesAndModalities);
  const funnelAdherence = useSelector(state => state.radAdmin.funnelAdherence);
  const totalStatues = useSelector(state => state.radAdmin.followUpList.adherenceOfDate?.data);
  const featureFlags = useSelector(state => state.constants.featureFlags.find(itm => itm.name==='show-imagine-charge-data'));
  const revenueCharges = useSelector(state => state.radAdmin?.followUpList.totalRevenueCharges.data)
  const revenueChargesLoading = useSelector(state => state.radAdmin?.followUpList.totalRevenueCharges.loading)
  const attributeCharges = useSelector(state => state.radAdmin?.followUpList.totalAttributeRevenueCharges.data)
  const attributeChargesLoading = useSelector(state => state.radAdmin?.followUpList.totalAttributeRevenueCharges.loading)
  const recoConfigData = useSelector((state) => state.radAdmin.recoConfig.data);
  const enableKeyMetrics = useSelector(state => state.constants.featureFlags.find(itm => itm.name==='enable-key-metrics'));
  const enableMarketingAttribution = useSelector(state => state.constants.featureFlags.find(itm => itm.name==='enable-converted'));

  const dispatch = useDispatch();
  const mounted = useRef();

  const [recommendationFilters, setRecommendationFilters] = useState(null);
  const [recommendationTimeframeFilters, setRecommendationTimeframeFilters] = useState(null)
  const [recommended, setRecommended] = useState(null);
  const [recoModalitiesTimeframe, setRecoModalitiesTimeframe] = useState(null);
  const [recoAnatomiesTimeframe, setRecoAnatomiesTimeframe] = useState(null);
  const [recoAnatomiesAndModalitiesTimeframe, setRecoAnatomiesAndModalitiesTimeframe] = useState(null);
  const [needRerenderModalities, setNeedRerenderModalities] = useState(null);
  const [needRerenderAnatomies, setNeedRerenderAnatomies] = useState(null);
  const [needRerenderAnatomiesAndModalities, setNeedRerenderAnatomiesAndModalities] = useState(null);

  const onSetRecommended = (rec) => {
    window.analytics.track('Top-Modalities-Filter-Apply');
    setRecommended(rec);
  }

  const onSetRecommendationFilters = (values) => {
    window.analytics.track('Recommendations-Chart-Filter-Apply');
    setRecommendationFilters(values);
  }

  const onSetRecommendationTimeframeFilters = (values) => {
    setRecommendationTimeframeFilters(values);
  }

  const onSetRecoModalitiesTimeframe = (timeframe) => {
    window.analytics.track('Recommendation-Modalities-Chart-Filter');
    setRecoModalitiesTimeframe(timeframe);
  }

  const onSetRecoAnatomiesTimeframe = (timeframe) => {
    window.analytics.track('Recommendation-Anatomies-Chart-Filter');
    setRecoAnatomiesTimeframe(timeframe);
  }

  const onSetRecoAnatomiesAndModalitiesTimeframe = (timeframe) => {
    window.analytics.track('Recommendation-Anatomies-And-Modalities-Chart-Filter');
    setRecoAnatomiesAndModalitiesTimeframe(timeframe);
  }

  const recOptions = [
    {value: 'imgReco', label: 'yes'},
    {value: 'imgNotReco', label: 'no'},
  ];

  const recoTimeframeOptions = [
    {value: 'No Timeframe Identified', label: 'No Timeframe Identified'},
    {value: 'Incomplete Missing End', label: 'Incomplete Missing End'},
    {value: 'Incomplete Missing Start', label: 'Incomplete Missing Start'},
    {value: 'Complete Timeframe', label: 'Complete Timeframe'},
  ];

  const resolveTimeframeOptions = (name) => {
    switch(name) {
      case 'No Timeframe Identified':
        return 'no_timeframe_identified';
      case 'Incomplete Missing End':
        return 'incomplete_timeframe_missing_end';
      case 'Incomplete Missing Start':
        return 'incomplete_timeframe_missing_start';
      case 'Complete Timeframe':
        return 'complete_timeframe_identified';
      default:
        return '';
    }
  }

  useEffect(() => {
    if (!imagingAdherence.data && !imagingAdherence.isLoading) {
      dispatch(getImagingAdherenceAction({}));
    }
    if (imagingAdherence.data && recommendationFilters) {
      dispatch(getImagingAdherenceAction(recommendationFilters));
    }
    if (imagingAdherence.data && !recommendationFilters) {
      dispatch(getImagingAdherenceAction({}));
    }
  }, [recommendationFilters]);

  useEffect(() => {
    if(recoConfigData){
    const startDate =  mounted?.current ? graphStartDate : moment().subtract(recoConfigData?.defaultRangeStart, "days").format("YYYY-MM-DD");
    const endDate = mounted?.current?  graphEndDate :  moment().subtract(recoConfigData?.defaultRangeEnd, "days").format("YYYY-MM-DD");
    if (!recommendationTimeframe.data && !recommendationTimeframe.isLoading) {
      dispatch(getRecommendationTimeframeAction({},startDate,endDate ));
    }
    if (recommendationTimeframe.data && recommendationTimeframeFilters) {
      dispatch(getRecommendationTimeframeAction(recommendationTimeframeFilters, startDate, endDate));
    }
    if (recommendationTimeframe.data && !recommendationTimeframeFilters) {
      dispatch(getRecommendationTimeframeAction({} ,startDate, endDate));
    }
    mounted.current = true;

  }
  }, [recommendationTimeframeFilters,recoConfigData?.defaultRangeStart, recoConfigData?.defaultRangeEnd]);

  const dateRangeChange=(startDate, endDate)=>{
    window.analytics.track("Recommendation-Distribution-Timeframe-Change-Event",{startDate,endDate});
    dispatch(getRecommendationTimeframeAction(recommendationTimeframeFilters, startDate,endDate))
  }


  useEffect(() => {
    if (!imagingAdherence.modalityOptions.data && !imagingAdherence.modalityOptions.isLoading) {
      dispatch(getImagingAdherenceModalityOptionsAction());
    }
  }, []);

  useEffect(() => {
    if (!modalities.data && !modalities.isLoading) {
      dispatch(getModalitiesAction());
    }
    if (modalities.data && recommended) {
      dispatch(getModalitiesAction(recommended));
    }
    if (modalities.data && !recommended) {
      dispatch(getModalitiesAction());
    }

  }, [recommended]);

  useEffect(() => {
    if(!funnelAdherence.data && !funnelAdherence.isLoading) {
      dispatch(getFunnelAdherenceAction());
    }
  }, []);

  useEffect(() => {
    const option = resolveTimeframeOptions(recoModalitiesTimeframe);
    if(!recoModalities.data && !recoModalities.isLoading) {
      dispatch(getRecoModalitiesAction(option));
    }
    if(recoModalities.data && option) {
      dispatch(getRecoModalitiesAction(option));
    }
    if(recoModalities.data && !option) {
      dispatch(getRecoModalitiesAction());
    }
  }, [recoModalitiesTimeframe]);

  useEffect(() => {
    const option = resolveTimeframeOptions(recoAnatomiesTimeframe);
    if(!recoAnatomies.data && !recoModalities.isLoading) {
      dispatch(getRecoAnatomiesAction(option));
    }
    if(recoAnatomies.data && option) {
      dispatch(getRecoAnatomiesAction(option));
    }
    if(recoAnatomies.data && !option) {
      dispatch(getRecoAnatomiesAction());
    }
  }, [recoAnatomiesTimeframe]);

  useEffect(() => {
    if(needRerenderModalities) {
      dispatch(getRecoModalitiesAction());
      setNeedRerenderModalities(null);
    }
    if(needRerenderAnatomies) {
      dispatch(getRecoAnatomiesAction());
      setNeedRerenderAnatomies(null);
    }
    if(needRerenderAnatomiesAndModalities) {
      dispatch(getRecoAnatomiesAndModalitiesAction());
      setNeedRerenderAnatomiesAndModalities(null);
    }
  }, [needRerenderModalities, needRerenderAnatomies, needRerenderAnatomiesAndModalities]);

  useEffect(() => {
    const option = resolveTimeframeOptions(recoAnatomiesAndModalitiesTimeframe);
    if(!recoAnatomiesAndModalities.data && !recoAnatomiesAndModalities.isLoading) {
      dispatch(getRecoAnatomiesAndModalitiesAction(option));
    }
    if(recoAnatomiesAndModalities.data && option) {
      dispatch(getRecoAnatomiesAndModalitiesAction(option));
    }
    if(recoAnatomiesAndModalities.data && !option) {
      dispatch(getRecoAnatomiesAndModalitiesAction());
    }
  }, [recoAnatomiesAndModalitiesTimeframe]);

  useEffect(()=> {
   dispatch(getAdherentCharges())
   dispatch(getAttributeWindowCharges())
  },[])

  const isShowEmptyState = imagingAdherence?.data && imagingAdherence.data.length === 0 && modalities?.data && modalities.data.length === 0 &&
    funnelAdherence?.data && funnelAdherence.data.length === 0;

  if(isShowEmptyState) {
    return (
      <Container>
        <EmptyState title={'Start Building Your Dashboard!'} img={() => <EmptyStateDashboardImg />}>
          Before we can create any charts, we’ll first need to get some data in here!
        </EmptyState>
      </Container>
    )
  }

  return (
    <Container>
      <BlockContainer>
      
       
        { enableMarketingAttribution?.value &&
           <Row>
             <Col sm={12} lg={12} xl={12}>
              <MarketingAttribution/>
             </Col>
           </Row>
        }
        {
          enableKeyMetrics?.value &&
           <Row>
             <Col sm={12} lg={12} xl={12}>
               <KeyMetrics singleRowView={true}/>
             </Col>
           </Row>
           }

       
        
        <Row>
          {!featureFlags?.value ? (
            <Col sm={12} lg={12} xl={12}>
              <AdherenceOfToday />
            </Col>
          ) : (
            <>
              <Col sm={4} lg={4} xl={4}>
                <AdherenceOfToday />
              </Col>

              <Col sm={4} lg={4} xl>
                <RevenueCard
                  color="#64C67C"
                  price={revenueCharges?.adherent?.toLocaleString('en-US') ?? 0}
                  label={"Total Charges for Adherent Patients"}
                  isLoading={revenueChargesLoading}
                />
              </Col>
              <Col sm={4} lg={4} xl>
                <RevenueCard
                    color="#FDBB13"
                    price={revenueCharges?.adherent_with_msg_sent?.toLocaleString('en-US') ?? 0}
                    label={
                      "Total Charges For Adherent patients that were messaged"
                    }
                    isLoading={revenueChargesLoading}
                  />
              </Col>
              <Col sm={4} lg={4} xl>
                <RevenueCard
                    color="#FD1391"
                    price={attributeCharges?.attributed_messages_charges?.toLocaleString('en-US') ?? 0}
                    label={"Total Charges Within the Messaging Attribution Window"}
                    isLoading={attributeChargesLoading}
                  />
              </Col>
            </>
          )}
        </Row>
        {featureFlags?.value && recoConfigData &&  (
          <Row>
            <Col sm={12} lg={12} xl={12}>
              <ChargesTimeGraph  />
            </Col>
          </Row>
        )}
       {
        recoConfigData &&   <Row>
        <Col sm={12} lg={12} xl={12}>
          <HistoricalAdherence data={totalStatues} />
        </Col>
      </Row>
       }
      
        <Row>
          <Col sm={12} lg={12} xl={12}>
            <ImagingAdherenceChart
              data={imagingAdherence.data}
              handleFiltersChange={onSetRecommendationFilters}
            />
          </Col>
        </Row>
        {
          recoConfigData &&    <Row>
          <Col sm={12} lg={12} xl={12}>
            <RecommendationsTimeframe
              data={recommendationTimeframe.data}
              handleFiltersChange={onSetRecommendationTimeframeFilters}
              dateRangeChange={dateRangeChange}
              graphStartDate={graphStartDate}
              graphEndDate={graphEndDate}
            />
          </Col>
        </Row>
        }
     
        <Row>
          <Col sm={12} lg={12} xl={6}>
            <RecomendationModalities
              data={recoModalities.data}
              onSetTimeframe={onSetRecoModalitiesTimeframe}
              recommendedOptions={recoTimeframeOptions}
              currentValue={recoModalitiesTimeframe}
              isLoading={recoModalities.isLoading}
              needRerender={needRerenderModalities}
              setNeedRerender={setNeedRerenderModalities}
            />
          </Col>
          <Col sm={12} lg={12} xl={6}>
            <RecomendationAnatomies
              data={recoAnatomies.data}
              onSetTimeframe={onSetRecoAnatomiesTimeframe}
              recommendedOptions={recoTimeframeOptions}
              currentValue={recoAnatomiesTimeframe}
              isLoading={recoAnatomies.isLoading}
              needRerender={needRerenderAnatomies}
              setNeedRerender={setNeedRerenderAnatomies}
            />
          </Col>
        </Row>

       

      <Row>
        <Col sm={6} lg={6} xl={6}>
          <TreemapModalities data={modalities.data} onSetRecommended={onSetRecommended} recommendedOptions={recOptions} />
        </Col>
        <Col sm={6} lg={6} xl={6}>
          <AdherenceFunnelChart data={funnelAdherence.data} />
        </Col>
      </Row>

      </BlockContainer>
      <BlockContainer>
   
      <Row>
        <Col sm={12} lg={12} xl={6}>
          <RecomendedAnatomiesAndModalities
            data={recoAnatomiesAndModalities.data}
            onSetTimeframe={onSetRecoAnatomiesAndModalitiesTimeframe}
            recommendedOptions={recoTimeframeOptions}
            currentValue={recoAnatomiesAndModalitiesTimeframe}
            isLoading={recoAnatomiesAndModalities.isLoading}
            needRerender={needRerenderAnatomiesAndModalities}
            setNeedRerender={setNeedRerenderAnatomiesAndModalities}
            />
            </Col>
      </Row>
      </BlockContainer>
    </Container>
  );
};

export default Overview;
