import React, { useCallback } from 'react';
import DayPicker from 'react-day-picker';
import styled from 'styled-components';
import moment from 'moment';

import 'react-day-picker/lib/style.css';
import styles from './index.module.css';

import { ReactComponent as IconCalendar } from '../../../assets/calendar.svg';
import { ReactComponent as IconChevronLeft } from '../../../assets/chevron_left.svg';
import { ReactComponent as IconChevronRight } from '../../../assets/chevron_right.svg';

const WORKING_TIME = ['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00'];

const Container = styled.div`
  height: 530px;
  padding: 20px;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    height: unset;
    padding: 30px 0;
    flex-direction: column;
  }
`;

const DayPickerWrapper = styled.div`
  position: relative;
  width: 475px;
  height: 490px;
  max-width: 475px;
  max-height: 490px;
  border-radius: 6px;
  border: 1px solid #c2ccd9;
  background: #ffffff;

  @media (max-width: 768px) {
    width: auto;
    height: auto;
  }
`;

const TimePickerWrapper = styled.div`
  max-width: 475px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  @media (max-width: 768px) {
    padding: 10px 0 0 0;
  }
`;

const TimeButton = styled.button`
  padding: 10px 25px;
  margin-bottom: 10px;
  outline: none !important;
  border-radius: 5px;
  border: ${(props) =>
    props.active ? '1px solid #00AEEF' : props.disabled ? '1px solid #DCE0E0' : '1px solid #C2CCD9'};
  background: ${(props) => (props.active ? '#DFEFFF' : '#FFFFFF')};
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => (props.active ? '#00AEEF' : props.disabled ? '#DCE0E0' : '#3F4857')};

  &:last-child {
    margin-bottom: 0;
  }
`;

const DayPickerNavbar = ({ onPreviousClick, onNextClick }) => {
  return (
    <div style={{ position: 'absolute', top: '1.1em', right: '1.7em' }}>
      <IconChevronLeft style={{ width: 30, height: 30, cursor: 'pointer' }} onClick={() => onPreviousClick()} />
      <IconChevronRight style={{ width: 30, height: 30, cursor: 'pointer' }} onClick={() => onNextClick()} />
    </div>
  );
};

const Calendar = ({ date, time, onChange, disabledBeforeDate, ...props }) => {
  const isDayDisabled = useCallback(
    (date) => {
      const tmp = moment(date);
      const day = tmp.day();

      return day === 0 || day === 6 || tmp.isBefore(disabledBeforeDate ?? new Date(), 'day');
    },
    [disabledBeforeDate]
  );

  const isTimeDisabled = useCallback(
    (date, time) => {
      const [hh] = time.split(':');

      return (
        moment(date).isSame(disabledBeforeDate ?? new Date(), 'day') &&
        (disabledBeforeDate ? moment(disabledBeforeDate).hour() : moment().hour() + 4) >= hh
      );
    },
    [disabledBeforeDate]
  );

  const onDayClick = useCallback(
    (date, modifiers) => {
      if (!modifiers.disabled) onChange(date, time);
    },
    [time, onChange]
  );

  const onTimeClick = useCallback(
    (event) => {
      onChange(date, event.target.dataset.value);
    },
    [date, onChange]
  );

  return (
    <Container {...props}>
      <DayPickerWrapper>
        <IconCalendar style={{ width: 30, height: 30, position: 'absolute', top: '1.1em', left: '1.7em' }} />
        <DayPicker
          className={styles.day_picker}
          selectedDays={date}
          disabledDays={isDayDisabled}
          onDayClick={onDayClick}
          navbarElement={DayPickerNavbar}
          fromMonth={new Date()}
          fixedWeeks
        />
      </DayPickerWrapper>
      {Boolean(date) && (
        <TimePickerWrapper>
          {WORKING_TIME.map((t) => (
            <TimeButton
              key={t}
              children={moment(t, 'HH:mm').format('h:mm A')}
              data-value={t}
              active={t === time}
              disabled={isTimeDisabled(date, t)}
              onClick={onTimeClick}
              type='button'
            />
          ))}
        </TimePickerWrapper>
      )}
    </Container>
  );
};

export default Calendar;
