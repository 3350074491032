import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import moment from 'moment';
import { Title } from './RecomendationModalities';
import { isArray } from 'lodash';
import DatePicker from 'react-datepicker';
import Flex from '../../../components/_styles/Flex';
import { ReactComponent as TriangleDownIcon } from '../../../assets/triangle-down.svg';
import { Treemap, ResponsiveContainer, Tooltip } from 'recharts';
import { sortByAsc } from '../../../utils/utils';
import Filter from './Filter';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

const COLORS = ['#64C67C', '#FFD57C', '#FF7A00', '#FF426E', '#1A6DFF', '#D0F9DA'];

export const Card = styled.div`
  background: #FFFFFF;
  border: 1px solid #C2CCD9;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  border-radius: 8px;
`

export const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin: 15px 15px 50px 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const DatePickerContainer = styled(Flex)`
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`

export const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D2D3D7;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
`

export const DateContainer = styled(Flex)`
  margin-right: 15px;
`

export const TitleAndLegend = styled(Flex)`
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const TreemapContainer = styled(Flex)`
  justify-content: center;
  margin-left: 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
      margin: 10px 5px;
  }
`

const TooltipContainer = styled.div`
  width: 160px;
  background-color: #C4C4C4;
`

const TooltipModalityList = styled.ul`
  list-style: none;
  padding-bottom: 10px;
`

const ModalityListItem = styled.li`
  padding-top: 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
`

const Square = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 8px;
`

const CustomTooltip = ({ data, modalityColors }) => {
  if (!modalityColors) return null;
  const dataWithColors = data.map(item => {
    const el = modalityColors.find(itm => itm.modality === item.modality);
    const color = el ? el.color : '';
    return {
      ...item,
      color,
    }
  });
  return (
    <TooltipContainer>
      <TooltipModalityList>
        {
          dataWithColors.map(({ modality, modalityCount, color }) => <ModalityListItem key={modality}> <Square style={{ backgroundColor: color }}> </Square> {modality}: {modalityCount}</ModalityListItem>)
        }
      </TooltipModalityList>
    </TooltipContainer>
  )
}


export class DatePickerInput extends React.Component {
  formatDate = (date) => {
    return date ? moment(date).format('MMM, YY') : 'No Date';
  }
  render(props) {
    const { startDate, endDate} = this.props
    return (
      <DatePickerButton onClick={this.props.onClick}>
        <DateContainer>{`${this.formatDate(startDate)} - ${this.formatDate(endDate)}`}</DateContainer>
        <TriangleDownIcon />
    </DatePickerButton>
    )
  }
}




// const DatePickerInput = ({ onClick, startDate, endDate }) => {
//   const formatDate = (date) => {
//     return date ? moment(date).format('MMM, YY') : 'No Date';
//   }
//   return (
//     <DatePickerButton onClick={onClick}>
//       <DateContainer>{`${formatDate(startDate)} - ${formatDate(endDate)}`}</DateContainer>
//       <TriangleDownIcon />
//     </DatePickerButton>
//   )
// };

const CustomizedContent = ({ root, depth, x, y, width, height, index, colors, modality, modalityCount }) => {
  if (!root.children) {
    return null;
  }
  return (
    <g color={depth < 2 ? colors[Math.floor(index / root.children.length * 6)] : ''}>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: depth < 2 ? colors[Math.floor(index / root.children.length * 6)] : 'none',
        }}
      />
      {
        depth === 1 ? (
          <text
            x={x + width / 2}
            y={y + height / 2 + 7}
            textAnchor="middle"
            fill="#000000"
            fontFamily="Roboto Condensed"
            fontStyle="normal"
            fontSize={18}
            color="#000"
          >
            {modality}
          </text>
        ) : null
      }
      {
        depth === 1 ? (
          <text
            x={x + width / 2}
            y={y + height / 2 - 12}
            textAnchor="middle"
            fill="#000"
            fontFamily="Roboto Condensed"
            fontStyle="normal"
            fontSize={18}
            color="#000"
          >
            {modalityCount}
          </text>
        ) : null
      }
    </g>
  );
}

const TreemapModalities = ({ data, onSetRecommended, recommendedOptions }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [modalityColors, setModalityColors] = useState(null);
  const [modalityColorsType, setModalityColorsType] = useState(null);

  const minDate = data && data?.length && new Date(data[0].date);
  const maxDate = data && data?.length && new Date(data[data.length - 1].date);

  const loading = useSelector(state => state.radAdmin.modalities.isLoading);

  useEffect(() => {
    if (data?.length) {
      if (data?.length > 0) {
        if (data.length > 6) {
          setStartDate(moment(data[data.length - 6].date, "MM/DD/YYYY").toDate());
          setEndDate(moment(data[data.length - 1].date, "MM/DD/YYYY").toDate());
          return
        }
        setStartDate(moment(data[0].date, "MM/DD/YYYY").toDate());
        setEndDate(moment(data[data.length - 1].date, "MM/DD/YYYY").toDate());
      } else {
        setStartDate(Date.now());
        setEndDate(Date.now());
      }
    }
  }, [data]);

  useEffect(() => {
    if (startDate && endDate && data?.length) {
      const filteredModalitiesByDate = data.filter(d => (
        d.timestamp >= startDate.getTime() && d.timestamp <= endDate.getTime()
      ));
      const sortedModalitiesByCount = sortByAsc(filteredModalitiesByDate, 'modalityCount');
      const groupByAndSum = (arr, key, keyForSum) => {
        const res = [];
        arr.reduce((acc, item) => {
          if (!acc[item[key]]) {
            acc[item[key]] = {
              [key]: item[key],
              [keyForSum]: 0
            }
            res.push(acc[item[key]])
          }
          acc[item[key]][keyForSum] += item[keyForSum];
          return acc;
        }, {});
        return res;
      }

      const groupedModalities = groupByAndSum(sortedModalitiesByCount, 'modality', 'modalityCount');
      const topModalities = groupedModalities.slice(Math.max(groupedModalities.length - 6, 0));
      setFilteredData(topModalities);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (filteredData) {
      const modalityNamesColors = [];
      document.querySelectorAll('.recharts-treemap-depth-1').forEach(item => {
        const color = item.firstElementChild.childNodes[0].attributes[0].value;
        const modality = item.firstElementChild.childNodes[0].children[1].innerHTML;
        modalityNamesColors.push({
          color,
          modality,
        });
      });
      setModalityColors(modalityNamesColors);
      if (!modalityColors || modalityColors?.length === 0) {
        setModalityColorsType(modalityColors instanceof Array ? 'array' : 'null');
      }
    }
  }, [filteredData, modalityColorsType]);

  const onChange = dates => {
    if (isArray(dates)) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      window.analytics.track('Top-Modalities-Datetime-Selection');
    }
  };

  // if (!filteredData) return null;
   
  return (
    <Card>
      <Header>
        <div style={{display:'flex', gap:'50px'}}>
        <TitleAndLegend>
          <Title>Initial Modalities</Title>
        </TitleAndLegend>
        <DatePickerContainer>
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            dateFormat="MM/yyyy"
            customInput={<DatePickerInput startDate={startDate} endDate={endDate} />}
            shouldCloseOnSelect={false}
            selectsRange
            showMonthYearPicker
            minDate={minDate}
            maxDate={maxDate}
          />
        </DatePickerContainer>
        </div>
       <div style={{marginRight:'9px'}}>
       <Filter onSetSelected={onSetRecommended} optionsTitle={'recommendations'} options={recommendedOptions} />
       </div>
      </Header>
      <TreemapContainer>
        <ResponsiveContainer width='80%' height={300}>
        { loading ? <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "320px",
                }}
              >
                <Spinner
               animation="border"
               role="status"
               size="sm"
               style={{color:'black', fontSize:'20px' }}
              /> 
              </div>:
          <Treemap
            width={420}
            height={300}
            data={filteredData}
            dataKey="modalityCount"
            ratio={4 / 3}
            animationDuration={500}
            fill="#8884d8"
            content={<CustomizedContent colors={COLORS} />}
          >
            <Tooltip content={<CustomTooltip data={filteredData} modalityColors={modalityColors} />} />
          </Treemap>
}
        </ResponsiveContainer>
      </TreemapContainer>
    </Card>
  );
}

export default TreemapModalities;
