import React, { Component } from "react";
import Subpage from "../UI/Subpage/Subpage";
import Container from "../UI/Container/Container";
import Logo from "../UI/Logo/Logo";
import '../PreScanTelemed/JotForm.css';

class DocPreScanCallNotes extends Component {
  async componentDidMount() {
    const script = document.createElement("script");
    const jotformUrl = `${process.env.PUBLIC_URL}/jotform.js`;
    script.src = "https://form.jotform.com/200294859976169";
    script.async = true;

    document.body.appendChild(script);
  }
  constructor(props) {
    super(props);
    this.state = {value: ''};
  }

  render() {
    return (
      <React.Fragment>

      <header className="App-header">
        <Logo />
      </header>
        <Subpage>
          <Container>
            <div class="jotform-iframe">
              <iframe
              onload="window.parent.scrollTo(0,0)"
              allowtransparency="true"
              allowfullscreen="true"
              allow="geolocation; microphone; camera"
              src="https://form.jotform.com/200294859976169"
              frameborder="0"
              class="jotform-iframe">
              </iframe>
            </div>
            <div>
            </div>
        </Container>
        </Subpage>
    </React.Fragment>

    );
  }
}

export default DocPreScanCallNotes;
