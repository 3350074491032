import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import PublicRoutes from './router';
import { persistor, store } from './reducers/store';
import GlobalStyles from './components/_styles/GlobalStyles';
import theme from './theme';
import { getSegmentKey, getSentryKey} from './utils/keys.js';

import 'react-datepicker/dist/react-datepicker.css';
import './App.css';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing"
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

window.analytics.load(getSegmentKey());
window.analytics.page();
console.warn = () => {}

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: getSentryKey(),
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <PublicRoutes />
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default Sentry.withErrorBoundary(App, { fallback: ErrorBoundary });
