import styled, { css } from 'styled-components';

const Tooltip = styled.div`
  height: 108px;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 62px;
  font-size: 14px;

  .alert {
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    width: 350px;
    text-align: center;
  }

  .arrow {
    height: 15px;
    width: 15px;
    background: #cce5ff;
    position: absolute;
    transform: translateX(-50%) rotate(45deg);
    left: 50%;
    bottom: -7px;
    border-right: 1px solid #cce5ff;
    border-bottom: 1px solid #cce5ff;
  }

  ${(props) =>
    props.currentStep === 'Select Plan' &&
    css`
      transform: translateX(0%);
      left: -25%;

      .alert {
        width: 325px;
      }

      svg {
        position: absolute;
        left: -44px;
      }

      .arrow {
        left: 15%;
      }
    `}

  ${(props) =>
    props.currentStep === 'Review Results' &&
    css`
      transform: unset;
      right: -19%;
      left: unset;

      svg {
        position: absolute;
        right: -37px;
      }

      .arrow {
        left: unset;
        right: 12%;
      }
    `}
`;

export default Tooltip;
