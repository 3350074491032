import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { useHistory } from "react-router";
import { ReactComponent as CustomChartsIcon } from "../../../assets/no-custom-charts.svg";
import {
  getCustomChartData,
  getSavedCustomChartData,
} from "../../../actions/radminCustomChartData";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";

import {
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Tooltip,
  Legend,
  Rectangle,
  Sector,
} from "recharts";
import { Spinner } from "react-bootstrap";
import {
  Button,
  Container,
  LegendList,
  LegendListContainer,
  LegendListItem,
  SelectedItem,
  SelectedItemContainer,
  desiredOrderAppointments,
  desiredOrderEmails,
  desiredOrderNotes,
  desiredOrderPrescriptions,
  desiredOrderRecoHistory,
  desiredOrderRecommendations,
  desiredOrderSms,
  legendWrapperStyle,
  randomColor,
  taskColors,
} from "./Constants";
import {
  emailColors,
  recommendationStatusColors,
  smsColors,
  COLORS,
} from "./Constants";
import EditModal from "./EditChart";
import moment from "moment";
import { showAlert } from "../../../actions/radminUploads";
import { CSVLink } from "react-csv";
import styled from "styled-components";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  flattenCompareArray,
  flattenIndividualArray,
  groupStatusesByCategory,
} from "./util";

const editIcon = `${process.env.PUBLIC_URL}/${"Edit.svg"}`;

const StyledTableContainer = styled.table`
  & table:last-child tbody tr:last-child {
    border-bottom: none;
  }
`;

const AccordionHeaderFirstColumn = styled.th`
  padding: 10px 30px !important;
  text-align: left;
  border-right: 1px solid #dfdfdf;
  position: sticky;
  z-index: 1;
  background: white;
  left: 0;
  box-shadow: inset 0px -1px #dfdfdf;
`;

const AccordionHeaderRestColumns = styled.th`
  padding: 0px;
  text-align: center;
  border-right: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
`;

const AccordionBodyFirstColumn = styled.td`
  padding: 10px 30px !important;
  text-align: left;
  border-right: 1px solid #dfdfdf;
  position: sticky;
  z-index: 1;
  background: white;
  left: 0;
  font-weight: 600;
  white-space: nowrap;
  box-shadow: 0px -1px #dfdfdf;
  border-bottom: ${(props) => props.borderBottom};
`;

const AccordionBodyRestColumn = styled.td`
  padding: 0px;
  text-align: center;
  border-right: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
`;

const StyledCsvBtn = styled(CSVLink)`
  text-decoration: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  background: #00aeef;
  color: white;
  border-radius: 4px;
  border: 2px solid #00aeef;
  height: 38px;
  font-weight: 600;
  font-size: 14px;
  width: 183px;

  &:hover {
    color: #00aeef;
    background: transparent;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const CustomChartHeader = styled.h1`
  font-family: Roboto Condensed;
  font-weight: 700;
  font-size: 36px;
  color: #0c2945;
  margin-bottom: 0px;
`;

const CustomChartsIconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CustomChartBox = styled.div`
  min-height: 795px;
  background: white;
  margin-bottom: 30px;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  padding: 30px;
`;

const StyledLabel = styled.label`
  margin-right: 10px;
  font-weight: 700;
  font-size: 14px;
  color: #6d6e6e;
`;

const ChipsContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "white",
    boxShadow: "none",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  rectangle: {
    width: "80%",
    height: 200,
    backgroundColor: "blue",
    marginTop: 20,
  },
  accordionRoot: {
    width: 0,
    minWidth: "100%",
    overflowX: "auto",
    border: "1px solid #c3c3c3",
    boxShadow: "none",
    "&$expanded": {
      margin: 0,
    },
  },
  summaryRoot: {
    position: "sticky",
    zIndex: "1",
    background: "white",
    left: 0,
  },
}));

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  let percentage =
    (percent * 100).toFixed(0) > 0 ? `${(percent * 100).toFixed(0)}%` : "";

  return (
    <g>
      <text
        x={cx}
        y={cy - 30}
        dy={8}
        fill="#0C2945"
        textAnchor={"middle"}
        dominantBaseline="central"
        style={{ fontSize: "20px", lineHeight: "25px", fontWeight: 900 }}
      >
        {percentage}
      </text>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={"#A6ACBE"}
        style={{ fontSize: "10px", lineHeight: "13px", fontWeight: 600 }}
      >
        {payload?.status || payload?.gender}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius}
        outerRadius={outerRadius}
        fill={fill}
      />
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={"middle"}
        dominantBaseline="central"
      >
        {percentage}
      </text>
    </g>
  );
};

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  let percentage =
    (percent * 100).toFixed(0) > 0 ? `${(percent * 100).toFixed(0)}%` : "";
  return (
    <g>
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={"middle"}
        dominantBaseline="central"
      >
        {percentage}
      </text>
    </g>
  );
};

const CustomCharts = () => {
  const history = useHistory();
  const [customCharts, setCustomCharts] = useState([]);
  const [selectedChart, setSelectedChart] = useState(null);
  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [editing, setEditing] = useState({ visible: false, data: {} });
  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);
  const addPermission = rolePermissions
    ?.find((itm) => itm.name === "Custom Charts")
    ?.permissions.find(
      (itm) => itm.codename === "add_customcharts"
    )?.is_authorized;

  const selectedPieChart =
    selectedChart?.selectedValues["chart_type"]?.value === "pie";

  const selectedGaugeChart =
    selectedChart?.selectedValues["chart_type"]?.value === "gauge";
  const selectedBarChart =
    selectedChart?.selectedValues["chart_type"]?.value === "bar";
  const selectedLineChart =
    selectedChart?.selectedValues["chart_type"]?.value === "line";
  const selectedCompareFlowType =
    selectedChart?.selectedValues["flow_type"]?.value === "compare";
  const selectedIndividualFlowType =
    selectedChart?.selectedValues["flow_type"]?.value === "individual";

  const selectedAppointmentsDataType =
    selectedChart?.selectedValues["data_type"]?.value === "appointments";
  const selectedPrescriptionsDataType =
    selectedChart?.selectedValues["data_type"]?.value === "prescriptions";
  const selectedNotesDataType =
    selectedChart?.selectedValues["data_type"]?.value === "notes";
  const selectedSmsDataType =
    selectedChart?.selectedValues["data_type"]?.value === "sms";
  const selectedEmailsDataType =
    selectedChart?.selectedValues["data_type"]?.value === "emails";
  const selectedRecommendationsDataType =
    selectedChart?.selectedValues["data_type"]?.value === "recommendations";
  const selectedRecoHistoryDataType =
    selectedChart?.selectedValues["data_type"]?.value === "reco-history";
  const selectedReportsDataType =
    selectedChart?.selectedValues["data_type"]?.value === "reports";
  const selectedRadiologistsDataType =
    selectedChart?.selectedValues["data_type"]?.value === "radiologists";
  const selectedReferrersDataType =
    selectedChart?.selectedValues["data_type"]?.value === "referrers";

  const noGroupBySelected =
    selectedChart?.selectedValues["group_by"]?.length === 0 ||
    !selectedChart?.selectedValues["group_by"];

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getSavedCustomChartData(1)).then((res) => {
      if (res) {
        setCustomCharts(res.results);
        if (res.next) {
          setNextPage(2);
        }
      }
    });
  }, []);

  const Option = (props) => {
    return (
      <>
        <components.Option {...props}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: 5 }}>
                <input
                  type="radio"
                  checked={props.isSelected}
                  onChange={() => null}
                  style={{ cursor: "pointer" }}
                />{" "}
              </span>
              <label style={{ cursor: "pointer", marginBottom: 0 }}>
                {props.label}
              </label>
            </div>
            <div>
              <img
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setEditing({ visible: true, data: props.data });
                }}
                src={editIcon}
              />
            </div>
          </div>
        </components.Option>
      </>
    );
  };

  const handleChartSelect = (selectedOption) => {
    if (selectedOption?.value === selectedChart?.value) {
      return;
    }
    setSelectedChart(selectedOption);
    setData([]);
    window.analytics.track("Custom-Chart-Option-Click", { ...selectedOption });
    if (selectedOption) {
      setLoadingGraph(true);
      const { selectedValues, filterValue } = selectedOption;
      if (
        filterValue?.value === "Custom date range" &&
        selectedValues.startDate &&
        selectedValues.endDate
      ) {
        dispatch(
          getCustomChartData(
            selectedValues,
            selectedValues?.startDate,
            selectedValues?.endDate,
            filterValue
          )
        )
          .then((res) => setData(res))
          .catch((err) => Sentry.captureException(err))
          .finally(() => {
            setLoadingGraph(false);
          });
      } else {
        dispatch(
          getCustomChartData(
            selectedValues,
            selectedValues?.selectedDate,
            moment(new Date()).format("YYYY-MM-DD"),
            filterValue
          )
        )
          .then((res) => setData(res))
          .catch((err) => Sentry.captureException(err))
          .finally(() => {
            setLoadingGraph(false);
          });
      }
    }
  };

  const chartOptions =
    customCharts.length > 0 &&
    customCharts?.map((chart) => ({
      value: chart?.id,
      label: chart?.title,
      selectedValues: JSON.parse(chart?.filters),
      filterValue: JSON.parse(chart?.filters)?.timeframe,
    }));

  let flattenData = [];
  let uniqueStatuses = [];

  if (
    selectedChart?.selectedValues["group_by"]?.length > 0 &&
    selectedIndividualFlowType &&
    data?.length > 0
  ) {
    const dataObj = flattenIndividualArray(
      selectedChart?.selectedValues,
      data,
      flattenData,
      uniqueStatuses
    );
    flattenData = dataObj.flattenData;
    uniqueStatuses = dataObj.uniqueStatuses;
  }

  const [loadingMore, setLoadingMore] = useState(false);
  const [nextPage, setNextPage] = useState();

  const [loadingGraph, setLoadingGraph] = useState(false);

  const loadMoreOptions = () => {
    if (nextPage && !loadingMore) {
      setLoadingMore(true);
      dispatch(getSavedCustomChartData(nextPage)).then((res) => {
        setLoadingMore(false);
        if (res) {
          setCustomCharts((prev) => [...prev, ...res.results]);
          if (res.next) {
            setNextPage((prev) => prev + 1);
          } else {
            setNextPage();
          }
        }
      });
    }
  };

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <LegendListContainer>
        <LegendList>
          {payload
            .sort((a, b) => b.payload.id__count - a.payload.id__count)
            .map((entry, index) => (
              <LegendListItem
                key={index}
                color={COLORS[index % COLORS.length]}
              >{`${
                selectedRadiologistsDataType || selectedReferrersDataType
                  ? entry.payload["status"].replaceAll("_", " ")
                  : entry.payload[
                      selectedChart?.selectedValues["data_subset"]?.value
                    ]
              } - ${entry.payload.id__count}`}</LegendListItem>
            ))}
        </LegendList>
      </LegendListContainer>
    );
  };

  const chips =
    selectedChart?.selectedValues?.["flow_type"]?.value === "compare"
      ? selectedChart?.selectedValues &&
        Object.keys(selectedChart?.selectedValues)
          .filter(
            (itm) =>
              itm === "additional" ||
              itm === "data_type" ||
              itm === "data_subset" ||
              itm === "group_by"
          )
          ?.map((itm) => {
            if (itm === "data_type") {
              let arr = [];
              for (
                let i = 0;
                i < selectedChart?.selectedValues?.["data_type"].length;
                i++
              ) {
                arr.push({
                  value: [
                    selectedChart?.selectedValues?.["data_type"][i].value,
                  ],
                  label: itm,
                });
              }
              return arr;
            }
            if (itm === "data_subset") {
              let arr = [];
              for (
                let i = 0;
                i < selectedChart?.selectedValues?.["data_type"].length;
                i++
              ) {
                arr.push({
                  value:
                    selectedChart?.selectedValues?.["data_subset"][
                      selectedChart?.selectedValues?.["data_type"][i].value
                    ]?.value,
                  label: itm,
                });
              }
              return arr;
            }
            if (itm === "group_by") {
              let arr = [];
              for (
                let i = 0;
                i < selectedChart?.selectedValues?.["data_type"].length;
                i++
              ) {
                if (
                  selectedChart?.selectedValues?.["data_type"][i]?.value ===
                  "reports"
                ) {
                  continue;
                }
                for (
                  let j = 0;
                  j <
                  selectedChart?.selectedValues?.["group_by"][
                    selectedChart?.selectedValues?.["data_type"][i]?.value
                  ]?.length;
                  j++
                ) {
                  arr.push({
                    value:
                      selectedChart?.selectedValues?.["group_by"][
                        selectedChart?.selectedValues?.["data_type"][i].value
                      ][j]?.label,
                    label: itm,
                  });
                }

                //   value:
                //     selectedChart?.selectedValues?.["group_by"][
                //       selectedChart?.selectedValues?.["data_type"][i].value
                //     ]?.value,
                //   label: itm,
                // });
              }
              return arr;
            }
            if (itm === "additional") {
              let arr = [];
              for (
                let i = 0;
                i < selectedChart?.selectedValues?.["data_type"].length;
                i++
              ) {
                for (const [key, value] of Object.entries(
                  selectedChart?.selectedValues?.additional[
                    selectedChart.selectedValues.data_type[i].value
                  ]
                ) ?? {}) {
                  if (
                    value &&
                    key !== "report__patient__date_of_birth__gte" &&
                    key !== "report__patient__date_of_birth__lte" &&
                    key !== "patient_age_group"
                  ) {
                    if (Array.isArray(value)) {
                      value.map((itm) =>
                        arr.push({
                          value:
                            itm.category === "radiologist"
                              ? itm.label
                              : itm.value,
                          label:
                            itm.category === "radiologist"
                              ? "Radiologist"
                              : key,
                        })
                      );
                    } else {
                      arr.push({
                        value: value.value,
                        label: key,
                      });
                    }
                  }
                }
              }

              const filteredRecords = arr.filter(
                (record) =>
                  ![
                    "modality_type",
                    "anatomy_type",
                    "report__modality_type",
                    "exam_code_type",
                  ].includes(record.label)
              );

              function getTypeValue(type) {
                const typeRecord = arr.find((record) => record.label === type);
                return typeRecord ? typeRecord.value : null;
              }

              const combinedStrings = filteredRecords.map((record) => {
                let value = record.value;
                if (
                  record.label === "modality" &&
                  getTypeValue("modality_type") !== null
                ) {
                  value =
                    `${
                      getTypeValue("modality_type") === false ? "Is Not " : ""
                    }` + capitalizeWords(value);
                } else if (
                  record.label === "anatomy" &&
                  getTypeValue("anatomy_type") !== null
                ) {
                  value =
                    `${
                      getTypeValue("anatomy_type") === false ? "Is Not " : ""
                    }` + capitalizeWords(value);
                } else if (
                  record.label === "report__modality" &&
                  getTypeValue("report__modality_type") !== null
                ) {
                  value =
                    `${
                      getTypeValue("report__modality_type") === false
                        ? "Is Not "
                        : ""
                    }` + capitalizeWords(value);
                } else if (
                  record.label === "exam_code" &&
                  getTypeValue("exam_code_type") !== null
                ) {
                  value =
                    `${
                      getTypeValue("exam_code_type") === false ? "Is Not " : ""
                    }` + capitalizeWords(value);
                } else if (
                  record.label === "initial_exam_date_gte" ||
                  record.label === "initial_exam_date_lte" ||
                  record.label === "nlp_range_start_gte" ||
                  record.label === "nlp_range_start_lte" ||
                  record.label === "nlp_range_end_gte" ||
                  record.label === "nlp_range_end_lte"
                ) {
                  value = moment(value).format("YYYY-MM-DD");
                }
                return { label: record.label, value: value };
              });

              return combinedStrings;
            }
          })
          .flat(10)
      : selectedChart?.selectedValues &&
        Object.keys(selectedChart?.selectedValues)
          .filter(
            (itm) =>
              itm === "additional" ||
              itm === "data_type" ||
              itm === "data_subset" ||
              itm === "group_by"
          )
          ?.map((itm) => {
            if (itm === "data_type" || itm === "data_subset") {
              return {
                value:
                  selectedChart?.selectedValues[itm]?.value ===
                  "recommendations-pie"
                    ? "Recommendations"
                    : selectedChart?.selectedValues[itm]?.value ===
                      "modality,anatomy"
                    ? "Modality & Anatomy"
                    : selectedChart?.selectedValues[itm]?.value,
                label: itm,
              };
            }
            if (
              itm === "group_by" &&
              (selectedChart?.selectedValues["data_type"]?.value ===
                "recommendations" ||
                selectedChart?.selectedValues["data_type"]?.value === "notes" ||
                selectedChart?.selectedValues["data_type"]?.value === "sms" ||
                selectedChart?.selectedValues["data_type"]?.value ===
                  "emails" ||
                selectedChart?.selectedValues["data_type"]?.value ===
                  "reco-history" ||
                selectedChart?.selectedValues["data_type"]?.value ===
                  "prescriptions" ||
                selectedChart?.selectedValues["data_type"]?.value ===
                  "appointments")
            ) {
              let arr = [];
              for (
                let i = 0;
                i < selectedChart?.selectedValues[itm]?.length;
                i++
              ) {
                arr.push({
                  value: selectedChart?.selectedValues[itm][i]?.label,
                  label: itm,
                });
              }
              return arr;
            }
            if (itm === "additional") {
              let arr = [];
              for (const [key, value] of Object.entries(
                selectedChart?.selectedValues?.additional[
                  selectedChart.selectedValues.data_type.value
                ] ?? {}
              )) {
                if (
                  value &&
                  key !== "report__patient__date_of_birth__gte" &&
                  key !== "report__patient__date_of_birth__lte" &&
                  key !== "patient_age_group"
                ) {
                  if (Array.isArray(value)) {
                    value.map((itm) =>
                      arr.push({
                        value:
                          itm.category === "radiologist"
                            ? itm.label
                            : itm.value,
                        label:
                          itm.category === "radiologist" ? "Radiologist" : key,
                      })
                    );
                  } else {
                    arr.push({
                      value: value.value,
                      label: key,
                    });
                  }
                }
              }

              const filteredRecords = arr.filter(
                (record) =>
                  ![
                    "modality_type",
                    "anatomy_type",
                    "report__modality_type",
                    "exam_code_type",
                  ].includes(record.label)
              );

              function getTypeValue(type) {
                const typeRecord = arr.find((record) => record.label === type);
                return typeRecord ? typeRecord.value : null;
              }

              const combinedStrings = filteredRecords.map((record) => {
                let value = record.value;

                if (
                  record.label === "modality" &&
                  getTypeValue("modality_type") !== null
                ) {
                  value =
                    `${
                      getTypeValue("modality_type") === false ? "Is Not " : ""
                    }` + capitalizeWords(value);
                } else if (
                  record.label === "anatomy" &&
                  getTypeValue("anatomy_type") !== null
                ) {
                  value =
                    `${
                      getTypeValue("anatomy_type") === false ? "Is Not " : ""
                    }` + capitalizeWords(value);
                } else if (
                  record.label === "report__modality" &&
                  getTypeValue("report__modality_type") !== null
                ) {
                  value =
                    `${
                      getTypeValue("report__modality_type") === false
                        ? "Is Not "
                        : ""
                    }` + capitalizeWords(value);
                } else if (
                  record.label === "exam_code" &&
                  getTypeValue("exam_code_type") !== null
                ) {
                  value =
                    `${
                      getTypeValue("exam_code_type") === false ? "Is Not " : ""
                    }` + capitalizeWords(value);
                } else if (
                  record.label === "initial_exam_date_gte" ||
                  record.label === "initial_exam_date_lte" ||
                  record.label === "nlp_range_start_gte" ||
                  record.label === "nlp_range_start_lte" ||
                  record.label === "nlp_range_end_gte" ||
                  record.label === "nlp_range_end_lte"
                ) {
                  value = moment(value).format("YYYY-MM-DD");
                }
                return { label: record.label, value: value };
              });

              return combinedStrings;
            }
          })
          .flat(10)
          .filter(Boolean);

  function capitalizeWords(str) {
    const words = str?.split(/[_\s]+/);

    const capitalizedWords = words?.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    const capitalizedString = capitalizedWords?.join(" ");

    return capitalizedString;
  }

  const [finalData, setFinalData] = useState([]);
  const [dynamicStatuses, setDynamicStatuses] = useState([]);
  const [statusColors, setStatusColors] = useState({});

  useEffect(() => {
    let flattenedData = [];
    let aggregatedData = [];
    let finalData = [];
    let dynamicStatuses = [];
    setFinalData([]);
    setDynamicStatuses([]);
    if (
      selectedChart?.selectedValues?.["flow_type"]?.value === "compare" &&
      data?.length > 0 &&
      Array.isArray(selectedChart?.selectedValues?.["data_type"]) &&
      !loadingGraph &&
      data.some((element) => Array.isArray(element))
    ) {
      const dataObj = flattenCompareArray(data, flattenedData);
      flattenedData = dataObj.flattenedData;

      aggregatedData = flattenedData.reduce((acc, item) => {
        if (!acc[item.date]) {
          acc[item.date] = { date: item.date, category: item.category };
        }

        Object.keys(item).forEach((key) => {
          if (key !== "date" && key !== "category") {
            acc[item.date][key] = (acc[item.date][key] || 0) + item[key];
          }
        });
        return acc;
      }, {});

      finalData = Object.values(aggregatedData);

      dynamicStatuses = Array.from(
        new Set(
          finalData.flatMap((item) =>
            Object.keys(item).filter(
              (key) => key !== "date" && key !== "category"
            )
          )
        )
      );

      setFinalData(finalData);
      setDynamicStatuses(dynamicStatuses);
    }
  }, [data, selectedChart?.id, loadingGraph]);

  useEffect(() => {
    const getRandomColor = () => {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    if (dynamicStatuses.length > 0) {
      const generatedColors = dynamicStatuses.reduce((acc, status) => {
        acc[status] = getRandomColor();
        return acc;
      }, {});
      setStatusColors(generatedColors);
    }
  }, [dynamicStatuses]);

  const groupedStatuses = groupStatusesByCategory(
    selectedChart?.selectedValues,
    dynamicStatuses,
    statusColors
  );

  const prepareCSVData = () => {
    let csvData = [];

    if (selectedPieChart || selectedGaugeChart) {
      csvData.push(["Status", "Count"]);

      data.forEach((status) => {
        let statusLabel =
          selectedRadiologistsDataType || selectedReferrersDataType
            ? status["status"].replaceAll("_", " ")
            : status[selectedChart?.selectedValues["data_subset"]?.value];

        if (statusLabel) {
          let row = [statusLabel, status?.id__count];
          csvData.push(row);
        }
      });
    } else if (
      (selectedBarChart || selectedLineChart) &&
      selectedCompareFlowType
    ) {
      selectedChart.selectedValues["data_type"].forEach((chart) => {
        const categoryLabel = selectedChart?.selectedValues?.compare_preceeding
          ? `${chart.label} (${selectedChart?.selectedValues.timeframe.value} v/s Preceding Period)`
          : `${chart.label} (${selectedChart?.selectedValues.timeframe.value})`;
        csvData.push([categoryLabel]);
        let headers =
          !selectedChart?.selectedValues["group_by"][chart.value] ||
          selectedChart?.selectedValues?.["group_by"][chart.value]?.length === 0
            ? ["Metric"]
            : [
                selectedChart?.selectedValues["group_by"]?.[chart.value]
                  ?.map((itm) => itm.label)
                  ?.sort((a, b) => {
                    let desiredOrderArray = "";
                    if (chart?.value === "prescriptions") {
                      desiredOrderArray = desiredOrderPrescriptions;
                    }
                    if (chart?.value === "appointments") {
                      desiredOrderArray = desiredOrderAppointments;
                    }
                    if (chart?.value === "reco-history") {
                      desiredOrderArray = desiredOrderRecoHistory;
                    }
                    if (chart?.value === "sms") {
                      desiredOrderArray = desiredOrderSms;
                    }
                    if (chart?.value === "emails") {
                      desiredOrderArray = desiredOrderEmails;
                    }
                    if (chart?.value === "notes") {
                      desiredOrderArray = desiredOrderNotes;
                    }
                    if (chart?.value === "recommendations") {
                      desiredOrderArray = desiredOrderRecommendations;
                    }
                    return (
                      desiredOrderArray.indexOf(a) -
                      desiredOrderArray.indexOf(b)
                    );
                  })
                  .join(" - "),
              ];

        finalData.forEach((item) => {
          if (selectedChart?.selectedValues?.frequency?.value === "months") {
            headers.push(`${moment(item.date).format("MMM YYYY")}`);
            if (selectedChart?.selectedValues?.compare_preceeding) {
              headers.push(
                `${moment(item.date).subtract(1, "year").format("MMM YYYY")}`
              );
            }
          } else if (
            selectedChart?.selectedValues?.frequency?.value === "years"
          ) {
            headers.push(`${item.date}`);
            if (selectedChart?.selectedValues?.compare_preceeding) {
              headers.push(
                `${moment(item.date, "YYYY")
                  .subtract(1, "years")
                  .format("YYYY")}`
              );
            }
          } else if (
            selectedChart?.selectedValues?.frequency?.value === "days"
          ) {
            headers.push(`${item.date}`);
            if (selectedChart?.selectedValues?.compare_preceeding) {
              headers.push(
                `${moment(item.date).subtract(1, "years").format("YYYY-MM-DD")}`
              );
            }
          } else {
            headers.push(`${item.date}`);
            if (selectedChart?.selectedValues?.compare_preceeding) {
              headers.push(
                `${item.date
                  .split(" - ")
                  .map((date) =>
                    moment(date, "DD MMM YYYY")
                      .subtract(1, "year")
                      .format("DD MMM YYYY")
                  )
                  .join(" - ")}`
              );
            }
          }
        });
        csvData.push(headers);

        const statuses =
          chart.value === "prescriptions"
            ? uniquePrescriptions
            : chart.value === "appointments"
            ? uniqueAppointments
            : chart.value === "reco-history"
            ? uniqueRecoHistory
            : chart.value === "reports"
            ? uniqueReports
            : chart.value === "notes"
            ? uniqueNotes
            : chart.value === "recommendations"
            ? uniqueRecStatues
            : chart.value === "sms"
            ? uniqueSmsStatuses
            : uniqueEmailStatues;

        statuses.forEach((status) => {
          let row = [status];

          finalData.forEach((item) => {
            if (
              !selectedChart?.selectedValues["group_by"][chart.value] ||
              selectedChart?.selectedValues?.["group_by"][chart.value]
                ?.length === 0
            ) {
              row.push(
                item[
                  `${
                    chart.value === "reco-history"
                      ? "Reco-history"
                      : chart.label
                  }: ${selectedChart?.selectedValues.timeframe.value}`
                ] ?? 0
              );
            } else {
              row.push(
                item[
                  `${
                    chart.value === "reco-history"
                      ? "Reco-history"
                      : chart.label
                  }: ${
                    selectedChart?.selectedValues.timeframe.value
                  } (${status})`
                ] ?? 0
              );
            }
            if (selectedChart?.selectedValues?.compare_preceeding) {
              if (
                !selectedChart?.selectedValues["group_by"][chart.value] ||
                selectedChart?.selectedValues?.["group_by"][chart.value]
                  ?.length === 0
              ) {
                row.push(
                  item[
                    `${
                      chart.value === "reco-history"
                        ? "Reco-history"
                        : chart.label
                    }: Preceding period`
                  ] ?? 0
                );
              } else {
                row.push(
                  item[
                    `${
                      chart.value === "reco-history"
                        ? "Reco-history"
                        : chart.label
                    }: Preceding period (${status})`
                  ] ?? 0
                );
              }
            }
          });

          csvData.push(row);
        });
        csvData.push([], []);
      });
    } else if (
      (selectedBarChart || selectedLineChart) &&
      selectedIndividualFlowType &&
      noGroupBySelected
    ) {
      let headers = ["Reports"];
      data.forEach((item) => {
        headers.push(item.date);
      });
      csvData.push(headers);

      let row = [
        selectedChart?.selectedValues["data_subset"]?.value === "charge"
          ? "Revenue"
          : "Count",
      ];
      data.forEach((item) => {
        row.push(
          selectedChart?.selectedValues["data_subset"]?.value === "charge"
            ? item.data?.[0]?.imagine_metadata__charge_amount__sum?.toLocaleString(
                "en-US"
              ) || "0"
            : item.data?.[0]?.id__count || "0"
        );
      });
      csvData.push(row);
    } else {
      let headers = [
        selectedChart?.selectedValues["group_by"]
          .map((itm) => itm.label)
          ?.sort((a, b) => {
            let desiredOrderArray = "";
            if (selectedPrescriptionsDataType) {
              desiredOrderArray = desiredOrderPrescriptions;
            }
            if (selectedAppointmentsDataType) {
              desiredOrderArray = desiredOrderAppointments;
            }
            if (selectedRecoHistoryDataType) {
              desiredOrderArray = desiredOrderRecoHistory;
            }
            if (selectedSmsDataType) {
              desiredOrderArray = desiredOrderSms;
            }
            if (selectedEmailsDataType) {
              desiredOrderArray = desiredOrderEmails;
            }
            if (selectedNotesDataType) {
              desiredOrderArray = desiredOrderNotes;
            }
            if (selectedRecommendationsDataType) {
              desiredOrderArray = desiredOrderRecommendations;
            }
            return desiredOrderArray.indexOf(a) - desiredOrderArray.indexOf(b);
          })
          .join(" - "),
      ];
      flattenData.forEach((item) => {
        headers.push(item.date);
      });
      csvData.push(headers);

      uniqueStatuses.forEach((status) => {
        let row = [status];
        flattenData.forEach((item) => {
          row.push(item[status] || "0");
        });
        csvData.push(row);
      });
    }

    return csvData;
  };

  let recStatuses = [];
  let smsStatuses = [];
  let emailStatuses = [];
  let notesStatuses = [];
  let reportsStatuses = [];
  let recoHistoryStatuses = [];
  let prescriptionStatuses = [];
  let appointmentStatuses = [];

  Object.keys(groupedStatuses).map((category) => {
    if (category.includes("Recommendations:")) {
      recStatuses.push(...groupedStatuses[category]);
    }
    if (category.includes("Sms:")) {
      smsStatuses.push(...groupedStatuses[category]);
    }
    if (category.includes("Emails:")) {
      emailStatuses.push(...groupedStatuses[category]);
    }
    if (category.includes("Notes:")) {
      notesStatuses.push(...groupedStatuses[category]);
    }
    if (category.includes("Reports:")) {
      reportsStatuses.push(...groupedStatuses[category]);
    }
    if (category.includes("Reco-history:")) {
      recoHistoryStatuses.push(...groupedStatuses[category]);
    }
    if (category.includes("Prescriptions:")) {
      prescriptionStatuses.push(...groupedStatuses[category]);
    }
    if (category.includes("Appointments:")) {
      appointmentStatuses.push(...groupedStatuses[category]);
    }
  });

  let uniqueRecStatues = [
    ...new Set(recStatuses.map((item) => item.status).filter((itm) => itm)),
  ];
  let uniqueEmailStatues = [
    ...new Set(emailStatuses.map((item) => item.status).filter((itm) => itm)),
  ];
  let uniqueSmsStatuses = [
    ...new Set(smsStatuses.map((item) => item.status).filter((itm) => itm)),
  ];

  let uniqueNotes = [
    ...new Set(notesStatuses.map((item) => item.status).filter((itm) => itm)),
  ];
  let uniqueReports = [...new Set(reportsStatuses.map((item) => item.status))];

  let uniqueRecoHistory = [
    ...new Set(
      recoHistoryStatuses.map((item) => item.status).filter((itm) => itm)
    ),
  ];

  let uniquePrescriptions = [
    ...new Set(
      prescriptionStatuses.map((item) => item.status).filter((itm) => itm)
    ),
  ];
  let uniqueAppointments = [
    ...new Set(
      appointmentStatuses.map((item) => item.status).filter((itm) => itm)
    ),
  ];


  return (
    <Container
      isTimeGraph={selectedBarChart || selectedLineChart}
      style={{ padding: "22px 33px 20px 20px", height: "100%" }}
    >
      <HeaderRow>
        <CustomChartHeader>Custom Charts</CustomChartHeader>
        <Button
          onClick={() => {
            if ((permission && addPermission) || !permission) {
              history.push(`/auth/radAdmin/custom/charts/create`);
              window.analytics.track("Custom-Charts-Create-Click");
            } else {
              dispatch(
                showAlert(
                  "danger",
                  "Error",
                  `You don't have permission to create custom chart`
                )
              );
            }
          }}
        >
          Create Custom Chart
        </Button>
      </HeaderRow>

      {customCharts?.length === 0 ? (
        <CustomChartsIconContainer>
          <CustomChartsIcon />
        </CustomChartsIconContainer>
      ) : (
        <CustomChartBox>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "10px",
            }}
          >
            <StyledLabel htmlFor="customChartDropdown">
              SAVED CUSTOM CHARTS
            </StyledLabel>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ minWidth: "350px" }}>
                <Select
                  components={{ Option }}
                  id="customChartDropdown"
                  options={chartOptions}
                  value={selectedChart}
                  onChange={handleChartSelect}
                  isLoading={loadingMore}
                  onMenuScrollToBottom={loadMoreOptions}
                  isClearable={true}
                />
              </div>

              {selectedChart && (
                <ChipsContainer>
                  {chips?.map((itm) => {
                    return (
                      <SelectedItemContainer>
                        <SelectedItem>
                          {capitalizeWords(itm?.label)} :{" "}
                          {capitalizeWords(String(itm?.value))}
                        </SelectedItem>
                      </SelectedItemContainer>
                    );
                  })}
                </ChipsContainer>
              )}
            </div>
          </div>
          {loadingGraph ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {" "}
              <Spinner animation="border" role="status" size="sm" />
            </div>
          ) : (
            customCharts.length > 0 &&
            selectedChart && (
              <>
                <h4 style={{ textAlign: "center" }}>{selectedChart?.label}</h4>
                <div style={{ height: "400px", marginBottom: "30px" }}>
                  {!selectedChart?.selectedValues[
                    "chart_type"
                  ] ? null : selectedBarChart ? (
                    selectedCompareFlowType ? (
                      <>
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart
                            data={finalData}
                            margin={{
                              top: 20,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip wrapperStyle={{ zIndex: 2 }} />
                            {dynamicStatuses.map((status) => (
                              <Bar
                                key={status}
                                dataKey={status}
                                fill={statusColors[status]}
                              />
                            ))}
                          </BarChart>
                        </ResponsiveContainer>
                      </>
                    ) : noGroupBySelected ? (
                      <ResponsiveContainer width="100%" height="100%">
                        {selectedChart?.selectedValues["data_subset"]?.value ===
                        "charge" ? (
                          <BarChart
                            width={500}
                            height={300}
                            data={data?.map((item) => ({
                              date: item.date,
                              charge_amount_sum:
                                item.data?.[0]
                                  ?.imagine_metadata__charge_amount__sum,
                            }))}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip
                              formatter={(value) =>
                                `$${value?.toLocaleString("en-US")}`
                              }
                            />
                            <Legend />
                            <Bar
                              dataKey="charge_amount_sum"
                              fill="#8884d8"
                              activeBar={
                                <Rectangle fill="pink" stroke="blue" />
                              }
                              barSize={40}
                            />
                          </BarChart>
                        ) : (
                          <BarChart
                            width={500}
                            height={300}
                            data={data?.map((item) => ({
                              date: item.date,
                              id__count: item.data?.[0]?.id__count,
                            }))}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar
                              dataKey="id__count"
                              fill="#8884d8"
                              activeBar={
                                <Rectangle fill="pink" stroke="blue" />
                              }
                              barSize={40}
                            />
                          </BarChart>
                        )}
                      </ResponsiveContainer>
                    ) : (
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          width={500}
                          height={300}
                          data={flattenData}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="date" />
                          <YAxis />
                          <Tooltip wrapperStyle={{ zIndex: 2 }} />
                          <Legend />
                          {uniqueStatuses.map((status, index) => (
                            <Bar
                              key={index}
                              dataKey={status}
                              name={status}
                              barSize={40}
                              fill={
                                selectedAppointmentsDataType
                                  ? randomColor(index)
                                  : selectedPrescriptionsDataType
                                  ? randomColor(index)
                                  : selectedRecommendationsDataType &&
                                    selectedChart?.selectedValues["group_by"]
                                      ?.map((itm) => itm?.value)
                                      .join(",") === "status"
                                  ? recommendationStatusColors(status)
                                  : selectedRecommendationsDataType
                                  ? randomColor(index)
                                  : selectedNotesDataType &&
                                    selectedChart?.selectedValues["group_by"]
                                      ?.map((itm) => itm.value)
                                      .join(",") === "task"
                                  ? taskColors(status)
                                  : selectedNotesDataType
                                  ? randomColor(index)
                                  : selectedRecoHistoryDataType
                                  ? randomColor(index)
                                  : selectedSmsDataType
                                  ? smsColors(status)
                                  : emailColors(status)
                              }
                            />
                          ))}
                        </BarChart>
                      </ResponsiveContainer>
                    )
                  ) : selectedLineChart ? (
                    selectedCompareFlowType ? (
                      <ResponsiveContainer width="100%" height="100%">
                        {selectedChart?.selectedValues["data_subset"]?.value ===
                        "charge" ? (
                          <LineChart
                            width={500}
                            height={300}
                            data={data.map((item) => ({
                              date: item.date,
                              charge_amount_sum:
                                item.data[0]
                                  .imagine_metadata__charge_amount__sum,
                            }))}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip
                              formatter={(value) =>
                                `$${value?.toLocaleString("en-US")}`
                              }
                            />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="charge_amount_sum"
                              stroke="#8884d8"
                              activeDot={{ r: 8 }}
                            />
                          </LineChart>
                        ) : (
                          <LineChart
                            width={500}
                            height={300}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="date"
                              allowDuplicatedCategory={false}
                            />
                            <YAxis />
                            <Tooltip wrapperStyle={{ zIndex: 2 }} />
                            {dynamicStatuses.map((status) => (
                              <Line
                                dataKey={status}
                                stroke={statusColors[status]}
                                data={finalData}
                                strokeDasharray="10 10"
                                dot={false}
                                type="monotone"
                              />
                            ))}
                          </LineChart>
                        )}
                      </ResponsiveContainer>
                    ) : noGroupBySelected ? (
                      <ResponsiveContainer width="100%" height="100%">
                        {selectedChart?.selectedValues["data_subset"]?.value ===
                        "charge" ? (
                          <LineChart
                            width={500}
                            height={300}
                            data={data?.map((item) => ({
                              date: item.date,
                              charge_amount_sum:
                                item.data?.[0]
                                  ?.imagine_metadata__charge_amount__sum,
                            }))}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip
                              formatter={(value) =>
                                `$${value?.toLocaleString("en-US")}`
                              }
                            />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="charge_amount_sum"
                              stroke="#8884d8"
                              activeDot={{ r: 8 }}
                            />
                          </LineChart>
                        ) : (
                          <LineChart
                            width={500}
                            height={300}
                            data={data?.map((item) => ({
                              date: item.date,
                              id__count: item.data?.[0]?.id__count,
                            }))}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="id__count"
                              stroke="#8884d8"
                              activeDot={{ r: 8 }}
                            />
                          </LineChart>
                        )}
                      </ResponsiveContainer>
                    ) : (
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                          width={500}
                          height={300}
                          data={flattenData}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="date" />
                          <YAxis />
                          <Tooltip wrapperStyle={{ zIndex: 2 }} />
                          <Legend />

                          {uniqueStatuses.map((status, index) => (
                            <Line
                              key={index}
                              dataKey={status}
                              name={status}
                              stroke={
                                selectedAppointmentsDataType
                                  ? randomColor(index)
                                  : selectedPrescriptionsDataType
                                  ? randomColor(index)
                                  : selectedRecommendationsDataType &&
                                    selectedChart?.selectedValues["group_by"]
                                      ?.map((itm) => itm?.value)
                                      .join(",") === "status"
                                  ? recommendationStatusColors(status)
                                  : selectedRecommendationsDataType
                                  ? randomColor(index)
                                  : selectedNotesDataType &&
                                    selectedChart?.selectedValues["group_by"]
                                      ?.map((itm) => itm.value)
                                      .join(",") === "task"
                                  ? taskColors(status)
                                  : selectedNotesDataType
                                  ? randomColor(index)
                                  : selectedRecoHistoryDataType
                                  ? randomColor(index)
                                  : selectedSmsDataType
                                  ? smsColors(status)
                                  : emailColors(status)
                              }
                            />
                          ))}
                        </LineChart>
                      </ResponsiveContainer>
                    )
                  ) : selectedPieChart ? (
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={600} height={600}>
                        <Pie
                          data={data}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={180}
                          fill="#8884d8"
                          dataKey="id__count"
                        >
                          {data.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Legend
                          iconSize={10}
                          width={120}
                          height={140}
                          layout="vertical"
                          verticalAlign="middle"
                          wrapperStyle={legendWrapperStyle}
                          iconType={"circle"}
                          content={renderLegend}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  ) : (
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={600} height={600}>
                        <Pie
                          activeIndex={activeIndex}
                          activeShape={renderActiveShape}
                          dataKey="id__count"
                          startAngle={180}
                          endAngle={0}
                          data={data}
                          cy={240}
                          cx={"50%"}
                          outerRadius={220}
                          innerRadius={50}
                          fill="#8884d8"
                          label={renderCustomizedLabel}
                          labelLine={false}
                          onMouseEnter={onPieEnter}
                          animationDuration={0}
                          strokeWidth={0}
                        >
                          {data?.map((entry, index) => (
                            <Cell
                              key={`cell-${index}-${activeIndex}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Legend
                          iconSize={10}
                          width={120}
                          height={140}
                          layout="vertical"
                          verticalAlign="middle"
                          wrapperStyle={legendWrapperStyle}
                          iconType={"circle"}
                          content={renderLegend}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  )}
                </div>
                {selectedCompareFlowType &&
                  data?.length > 0 &&
                  Array.isArray(selectedChart?.selectedValues?.["data_type"]) &&
                  !loadingGraph &&
                  Object.keys(groupedStatuses).length > 0 && (
                    <div style={{ marginBottom: "20px" }}>
                      <table>
                        <thead>
                          <tr>
                            <th>Category</th>
                            <th>Legend</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(groupedStatuses).map((category) => (
                            <tr key={category}>
                              <td>{category}</td>
                              <td>
                                {groupedStatuses[category]
                                  .filter((itm) => itm.status)
                                  .map((statusInfo, index) => (
                                    <span
                                      key={index}
                                      style={{
                                        display: "inline-block",
                                        margin: "4px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          backgroundColor: statusInfo.color,
                                          width: "12px",
                                          height: "12px",
                                          display: "inline-block",
                                          marginRight: "6px",
                                        }}
                                      />
                                      {statusInfo.status}
                                    </span>
                                  ))}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                <StyledCsvBtn
                  data={prepareCSVData()}
                  filename={`${selectedChart?.label}.csv`}
                  target="_blank"
                >
                  Download as CSV
                </StyledCsvBtn>
                {(selectedBarChart || selectedLineChart) &&
                  selectedCompareFlowType && (
                    <>
                      {selectedChart?.selectedValues?.["data_type"]?.map(
                        (chart, index) => (
                          <div style={{ marginBottom: "20px" }} key={index}>
                            <Accordion
                              classes={{ root: classes.accordionRoot }}
                              defaultExpanded
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                                className={classes.summaryRoot}
                              >
                                <h4>
                                  {chart.label} (
                                  {selectedChart?.selectedValues
                                    ?.compare_preceeding
                                    ? `${selectedChart?.selectedValues.timeframe.value} v/s Preceding Period`
                                    : selectedChart?.selectedValues.timeframe
                                        .value}
                                  )
                                </h4>
                              </AccordionSummary>

                              <AccordionDetails>
                                {(chart.value === "prescriptions"
                                  ? uniquePrescriptions
                                  : chart.value === "appointments"
                                  ? uniqueAppointments
                                  : chart.value === "reco-history"
                                  ? uniqueRecoHistory
                                  : chart.value === "reports"
                                  ? uniqueReports
                                  : chart.value === "notes"
                                  ? uniqueNotes
                                  : chart.value === "recommendations"
                                  ? uniqueRecStatues
                                  : chart.value === "sms"
                                  ? uniqueSmsStatuses
                                  : uniqueEmailStatues
                                ).length > 0 ? (
                                  <StyledTableContainer
                                    style={{
                                      width: "100%",
                                      overflowX: "auto",
                                      border: "1px solid #DFDFDF",
                                      borderCollapse: "separate",
                                      borderSpacing: 0,
                                      borderRight: "none",
                                      borderBottom: "none",
                                    }}
                                  >
                                    <thead>
                                      <tr>
                                        <AccordionHeaderFirstColumn>
                                          {!selectedChart?.selectedValues[
                                            "group_by"
                                          ]?.[chart.value] ||
                                          selectedChart?.selectedValues[
                                            "group_by"
                                          ]?.[chart.value].length === 0
                                            ? "Metric"
                                            : selectedChart?.selectedValues[
                                                "group_by"
                                              ]?.[chart.value]
                                                ?.map((itm) => itm.label)
                                                ?.sort((a, b) => {
                                                  let desiredOrderArray = "";
                                                  if (
                                                    chart?.value ===
                                                    "appointments"
                                                  ) {
                                                    desiredOrderArray =
                                                      desiredOrderAppointments;
                                                  }
                                                  if (
                                                    chart?.value ===
                                                    "prescriptions"
                                                  ) {
                                                    desiredOrderArray =
                                                      desiredOrderPrescriptions;
                                                  }
                                                  if (
                                                    chart?.value ===
                                                    "reco-history"
                                                  ) {
                                                    desiredOrderArray =
                                                      desiredOrderRecoHistory;
                                                  }
                                                  if (chart?.value === "sms") {
                                                    desiredOrderArray =
                                                      desiredOrderSms;
                                                  }
                                                  if (
                                                    chart?.value === "emails"
                                                  ) {
                                                    desiredOrderArray =
                                                      desiredOrderEmails;
                                                  }
                                                  if (
                                                    chart?.value === "notes"
                                                  ) {
                                                    desiredOrderArray =
                                                      desiredOrderNotes;
                                                  }
                                                  if (
                                                    chart?.value ===
                                                    "recommendations"
                                                  ) {
                                                    desiredOrderArray =
                                                      desiredOrderRecommendations;
                                                  }
                                                  return (
                                                    desiredOrderArray.indexOf(
                                                      a
                                                    ) -
                                                    desiredOrderArray.indexOf(b)
                                                  );
                                                })
                                                .join(" - ")}
                                        </AccordionHeaderFirstColumn>
                                        {selectedChart?.selectedValues
                                          ?.frequency.value === "months"
                                          ? finalData?.map((item, idx) => (
                                              <AccordionHeaderRestColumns
                                                key={idx}
                                              >
                                                <div
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #DFDFDF",
                                                    padding: "10px",
                                                  }}
                                                >
                                                  {moment(item.date).format(
                                                    "MMM"
                                                  )}
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      padding: "10px 30px",
                                                      flexBasis: "50%",
                                                      borderRight: selectedChart
                                                        ?.selectedValues
                                                        ?.compare_preceeding
                                                        ? "1px solid #DFDFDF"
                                                        : "none",
                                                    }}
                                                  >
                                                    {moment(item.date).format(
                                                      "YYYY"
                                                    )}
                                                  </span>
                                                  {selectedChart?.selectedValues
                                                    ?.compare_preceeding && (
                                                    <span
                                                      style={{
                                                        padding: "10px 30px",
                                                        flexBasis: "50%",
                                                      }}
                                                    >
                                                      {moment(item.date)
                                                        .subtract(1, "year")
                                                        .format("YYYY")}
                                                    </span>
                                                  )}
                                                </div>
                                              </AccordionHeaderRestColumns>
                                            ))
                                          : selectedChart?.selectedValues
                                              ?.frequency.value === "years"
                                          ? finalData?.map((item, idx) => (
                                              <AccordionHeaderRestColumns
                                                key={idx}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      padding: "10px",
                                                      flexBasis: "50%",
                                                      borderRight: selectedChart
                                                        ?.selectedValues
                                                        ?.compare_preceeding
                                                        ? "1px solid #DFDFDF"
                                                        : "none",
                                                    }}
                                                  >
                                                    {item.date}
                                                  </span>
                                                  {selectedChart?.selectedValues
                                                    ?.compare_preceeding && (
                                                    <span
                                                      style={{
                                                        padding: "10px",
                                                        flexBasis: "50%",
                                                      }}
                                                    >
                                                      {moment(item.date, "YYYY")
                                                        .subtract(1, "years")
                                                        .format("YYYY")}
                                                    </span>
                                                  )}
                                                </div>
                                              </AccordionHeaderRestColumns>
                                            ))
                                          : selectedChart?.selectedValues
                                              ?.frequency.value === "days"
                                          ? finalData?.map((item, idx) => (
                                              <AccordionHeaderRestColumns
                                                key={idx}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      padding: "10px",
                                                      flexBasis: "50%",
                                                      borderRight: selectedChart
                                                        ?.selectedValues
                                                        ?.compare_preceeding
                                                        ? "1px solid #DFDFDF"
                                                        : "none",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    {item.date}
                                                  </span>
                                                  {selectedChart?.selectedValues
                                                    ?.compare_preceeding && (
                                                    <span
                                                      style={{
                                                        padding: "10px",
                                                        flexBasis: "50%",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                    >
                                                      {moment(item.date)
                                                        .subtract(1, "years")
                                                        .format("YYYY-MM-DD")}
                                                    </span>
                                                  )}
                                                </div>
                                              </AccordionHeaderRestColumns>
                                            ))
                                          : finalData?.map((item, idx) => (
                                              <AccordionHeaderRestColumns
                                                key={idx}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      padding: "10px",
                                                      flexBasis: "50%",
                                                      borderRight: selectedChart
                                                        ?.selectedValues
                                                        ?.compare_preceeding
                                                        ? "1px solid #DFDFDF"
                                                        : "none",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    {item.date
                                                      .split(" - ")
                                                      .map((date) =>
                                                        moment(
                                                          date,
                                                          "DD MMM YYYY"
                                                        ).format("YYYY-MM-DD")
                                                      )
                                                      .join(" - ")}
                                                  </span>
                                                  {selectedChart?.selectedValues
                                                    ?.compare_preceeding && (
                                                    <span
                                                      style={{
                                                        padding: "10px",
                                                        flexBasis: "50%",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                    >
                                                      {item.date
                                                        .split(" - ")
                                                        .map((date) =>
                                                          moment(
                                                            date,
                                                            "DD MMM YYYY"
                                                          )
                                                            .subtract(1, "year")
                                                            .format(
                                                              "YYYY-MM-DD"
                                                            )
                                                        )
                                                        .join(" - ")}
                                                    </span>
                                                  )}
                                                </div>
                                              </AccordionHeaderRestColumns>
                                            ))}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {(chart.value === "prescriptions"
                                        ? uniquePrescriptions
                                        : chart.value === "appointments"
                                        ? uniqueAppointments
                                        : chart.value === "reco-history"
                                        ? uniqueRecoHistory
                                        : chart.value === "reports"
                                        ? uniqueReports
                                        : chart.value === "notes"
                                        ? uniqueNotes
                                        : chart.value === "recommendations"
                                        ? uniqueRecStatues
                                        : chart.value === "sms"
                                        ? uniqueSmsStatuses
                                        : uniqueEmailStatues
                                      ).map((itm, itmIdx, arr) => (
                                        <tr key={itmIdx}>
                                          <AccordionBodyFirstColumn
                                            borderBottom={
                                              itmIdx === arr.length - 1
                                                ? "1px solid #dfdfdf"
                                                : "none"
                                            }
                                          >
                                            {chart.value === "reports"
                                              ? selectedChart?.selectedValues[
                                                  "data_subset"
                                                ]["reports"]?.value === "charge"
                                                ? "Sum"
                                                : "Count"
                                              : itm === ""
                                              ? "empty"
                                              : itm}
                                          </AccordionBodyFirstColumn>
                                          {finalData?.map((item, idx) => (
                                            <AccordionBodyRestColumn key={idx}>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    padding: "10px",
                                                    flexBasis: "50%",
                                                    borderRight: selectedChart
                                                      ?.selectedValues
                                                      ?.compare_preceeding
                                                      ? "1px solid #DFDFDF"
                                                      : "none",
                                                  }}
                                                >
                                                  {chart.value === "reports"
                                                    ? (selectedChart
                                                        ?.selectedValues[
                                                        "data_subset"
                                                      ]["reports"]?.value ===
                                                      "charge"
                                                        ? "$"
                                                        : "") +
                                                        item[
                                                          `${chart.label}: ${selectedChart?.selectedValues.timeframe.value}`
                                                        ] ?? 0
                                                    : !selectedChart
                                                        ?.selectedValues[
                                                        "group_by"
                                                      ][chart.value] ||
                                                      selectedChart
                                                        ?.selectedValues?.[
                                                        "group_by"
                                                      ][chart.value]?.length ===
                                                        0
                                                    ? item[
                                                        `${
                                                          chart.value ===
                                                          "reco-history"
                                                            ? "Reco-history"
                                                            : chart.label
                                                        }: ${
                                                          selectedChart
                                                            ?.selectedValues
                                                            .timeframe.value
                                                        }`
                                                      ] ?? 0
                                                    : item[
                                                        `${
                                                          chart.value ===
                                                          "reco-history"
                                                            ? "Reco-history"
                                                            : chart.label
                                                        }: ${
                                                          selectedChart
                                                            ?.selectedValues
                                                            .timeframe.value
                                                        } (${itm})`
                                                      ] ?? 0}
                                                </span>
                                                {selectedChart?.selectedValues
                                                  ?.compare_preceeding && (
                                                  <span
                                                    style={{
                                                      padding: "10px",
                                                      flexBasis: "50%",
                                                    }}
                                                  >
                                                    {chart.value === "reports"
                                                      ? (selectedChart
                                                          ?.selectedValues[
                                                          "data_subset"
                                                        ]["reports"]?.value ===
                                                        "charge"
                                                          ? "$"
                                                          : "") +
                                                          item[
                                                            `${chart.label}: Preceding period`
                                                          ] ?? 0
                                                      : !selectedChart
                                                          ?.selectedValues[
                                                          "group_by"
                                                        ][chart.value] ||
                                                        selectedChart
                                                          ?.selectedValues?.[
                                                          "group_by"
                                                        ][chart.value]
                                                          ?.length === 0
                                                      ? item[
                                                          `${
                                                            chart.value ===
                                                            "reco-history"
                                                              ? "Reco-history"
                                                              : chart.label
                                                          }: Preceding period`
                                                        ] ?? 0
                                                      : item[
                                                          `${
                                                            chart.value ===
                                                            "reco-history"
                                                              ? "Reco-history"
                                                              : chart.label
                                                          }: Preceding period (${itm})`
                                                        ] ?? 0}
                                                  </span>
                                                )}
                                              </div>
                                            </AccordionBodyRestColumn>
                                          ))}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </StyledTableContainer>
                                ) : (
                                  <span>No Data</span>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        )
                      )}
                    </>
                  )}

                {(selectedBarChart || selectedLineChart) &&
                  selectedIndividualFlowType &&
                  (noGroupBySelected ? (
                    <StyledTableContainer
                      style={{
                        width: "100%",
                        overflowX: "auto",
                        border: "1px solid #DFDFDF",
                        borderCollapse: "separate",
                        borderSpacing: 0,
                      }}
                    >
                      <table style={{ marginBottom: "0px" }}>
                        <thead>
                          <tr>
                            <th style={{ padding: "10px" }}>
                              {selectedChart?.selectedValues?.data_type.label}
                            </th>
                            {data?.map((item, index) => (
                              <th
                                style={{ padding: "10px", textAlign: "center" }}
                                key={index}
                              >
                                {item.date}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ padding: "10px" }}>
                              {" "}
                              {selectedChart?.selectedValues["data_subset"]
                                ?.value === "charge"
                                ? "Revenue"
                                : "Count"}
                            </td>
                            {data?.map((item, index) => (
                              <td
                                style={{ padding: "10px", textAlign: "center" }}
                                key={index}
                              >
                                {selectedChart?.selectedValues["data_subset"]
                                  ?.value === "charge"
                                  ? `$${
                                      item.data?.[0]?.imagine_metadata__charge_amount__sum?.toLocaleString(
                                        "en-US"
                                      ) || 0
                                    }`
                                  : item.data?.[0]?.id__count || 0}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </StyledTableContainer>
                  ) : (
                    <div
                      style={{
                        width: 0,
                        minWidth: "100%",
                        overflowX: "auto",
                        borderLeft: "1px solid #dfdfdf",
                        borderRight: "1px solid #dfdfdf",
                        boxShadow: "none",
                      }}
                    >
                      <StyledTableContainer
                        style={{
                          width: "100%",
                          overflowX: "auto",
                          border: "1px solid #DFDFDF",
                          borderCollapse: "separate",
                          borderSpacing: 0,
                          borderRight: "none",
                          borderBottom: "none",
                          marginBottom: "0px",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "10px",
                                position: "sticky",
                                left: 0,
                                zIndex: 1,
                                borderRight: "1px solid #DFDFDF",
                                position: "sticky",
                                background: "white",
                                boxShadow: "inset 0px -1px #dfdfdf",
                              }}
                            >
                              {selectedChart?.selectedValues["group_by"]
                                .map((itm) => itm.label)
                                .sort((a, b) => {
                                  let desiredOrderArray = "";
                                  if (selectedAppointmentsDataType) {
                                    desiredOrderArray =
                                      desiredOrderAppointments;
                                  }
                                  if (selectedPrescriptionsDataType) {
                                    desiredOrderArray =
                                      desiredOrderPrescriptions;
                                  }
                                  if (selectedRecoHistoryDataType) {
                                    desiredOrderArray = desiredOrderRecoHistory;
                                  }
                                  if (selectedSmsDataType) {
                                    desiredOrderArray = desiredOrderSms;
                                  }
                                  if (selectedEmailsDataType) {
                                    desiredOrderArray = desiredOrderEmails;
                                  }
                                  if (selectedNotesDataType) {
                                    desiredOrderArray = desiredOrderNotes;
                                  }
                                  if (selectedRecommendationsDataType) {
                                    desiredOrderArray =
                                      desiredOrderRecommendations;
                                  }
                                  return (
                                    desiredOrderArray.indexOf(a) -
                                    desiredOrderArray.indexOf(b)
                                  );
                                })
                                .join(" - ")}
                            </th>
                            {flattenData?.map((item, index) => (
                              <th
                                style={{
                                  padding: "10px",
                                  whiteSpace: "nowrap",
                                  textAlign: "center",
                                  borderRight: "1px solid #DFDFDF",
                                  borderBottom: "1px solid #DFDFDF",
                                }}
                                key={index}
                              >
                                {item.date}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {uniqueStatuses.map((status, statusIndex) => (
                            <tr key={statusIndex}>
                              <td
                                style={{
                                  padding: "10px",
                                  fontWeight: 600,
                                  position: "sticky",
                                  left: 0,
                                  backgroundColor: "#fff",
                                  zIndex: 1,
                                  textAlign: "left",
                                  borderRight: "1px solid #DFDFDF",
                                  position: "sticky",
                                  zIndex: "1",
                                  background: "white",
                                  left: 0,
                                  fontWeight: 600,
                                  whiteSpace: "nowrap",
                                  boxShadow: "0px -1px #dfdfdf",
                                  borderBottom:
                                    statusIndex === uniqueStatuses.length - 1
                                      ? "1px solid #dfdfdf"
                                      : "none",
                                }}
                              >
                                {status}
                              </td>
                              {flattenData?.map((item, index) => (
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    borderRight: "1px solid #DFDFDF",
                                    borderBottom: "1px solid #DFDFDF",
                                  }}
                                  key={index}
                                >
                                  {item[status] || 0}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </StyledTableContainer>
                    </div>
                  ))}

                {(selectedPieChart || selectedGaugeChart) && (
                  <StyledTableContainer
                    style={{
                      width: "100%",
                      overflowX: "auto",
                      border: "1px solid #DFDFDF",
                      borderRadius: "8px",
                    }}
                  >
                    <table style={{ marginBottom: "0px" }}>
                      <thead>
                        <tr>
                          <th style={{ padding: "10px" }}>Status</th>
                          <th style={{ padding: "10px" }}>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((status, statusIndex) => (
                          <tr key={statusIndex}>
                            <td style={{ padding: "10px" }}>{`${
                              selectedRadiologistsDataType ||
                              selectedReferrersDataType
                                ? status["status"].replaceAll("_", " ")
                                : status[
                                    selectedChart?.selectedValues["data_subset"]
                                      ?.value
                                  ]
                            }`}</td>

                            <td style={{ padding: "10px" }}>
                              {status?.id__count}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </StyledTableContainer>
                )}
              </>
            )
          )}
        </CustomChartBox>
      )}
      {editing.visible && (
        <EditModal
          selectedValues={editing.data}
          opened={editing.visible}
          onClose={() => setEditing({ visible: false, data: {} })}
          setCustomCharts={setCustomCharts}
          setNextPage={setNextPage}
          setSelectedChart={setSelectedChart}
        />
      )}
    </Container>
  );
};

export default CustomCharts;
