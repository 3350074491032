import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../UI/Logo/Logo";
import WizardLayout from "../UI/Layout/WizardLayout";

class ErrorBoundary extends React.Component {
  constructor() {
    super();

    this.state = {
      hasError: false,
      errorMessage: ""
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      errorMessage: error
    });
  }

  reset = () => {
    this.setState({
      hasError: false,
      errorMessage: ""
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.hasError ? (

          <WizardLayout>
          <header className="App-header">
            <Logo onWhite={false} />
          </header>
            <h1>Oops! There was an error</h1>
            <Link to="/" onClick={this.reset}>
              Take me back home
            </Link>
          </WizardLayout>
        ) : (
          this.props.children
        )}
      </React.Fragment>
    );
  }
}

export default connect(
  null,
  {}
)(ErrorBoundary);
