import API from '../../libs/api';

export const GET_PROVIDERS_FETCH = 'GET_PROVIDERS_FETCH';
export const GET_PROVIDERS_RESOLVE = 'GET_PROVIDERS_RESOLVE';
export const GET_PROVIDERS_REJECT = 'GET_PROVIDERS_REJECT';

export const GET_LOCATIONS_FETCH = 'GET_LOCATIONS_FETCH';
export const GET_LOCATIONS_RESOLVE = 'GET_LOCATIONS_RESOLVE';
export const GET_LOCATIONS_REJECT = 'GET_LOCATIONS_REJECT';

export const GET_PROVIDERS_AND_LOCATIONS_FETCH = 'GET_PROVIDERS_AND_LOCATIONS_FETCH';
export const GET_PROVIDERS_AND_LOCATIONS_RESOLVE = 'GET_PROVIDERS_AND_LOCATIONS_RESOLVE';
export const GET_PROVIDERS_AND_LOCATIONS_REJECT = 'GET_PROVIDERS_AND_LOCATIONS_REJECT';

export const SET_LOCATIONS_LAST_PAGE = 'SET_LOCATIONS_LAST_PAGE';

export const setLocationsLastPage = (payload) => ({ type: SET_LOCATIONS_LAST_PAGE, payload });

export const getProviders = (page = 1) => async (dispatch) => {
  try {
    dispatch({ type: GET_PROVIDERS_FETCH });
    const response = await API.getRequest(`/api/v1/provider/?page=${page}`);
    dispatch({ type: GET_PROVIDERS_RESOLVE, payload: response.data });

    return response.data;
  } catch (error) {
    console.error(error);
    dispatch({ type: GET_PROVIDERS_REJECT });
  }
};

export const getLocations = (page = 1) => async (dispatch, getState) => {
  try {
    const providerId = getState().checkout.providerId;

    dispatch({ type: GET_LOCATIONS_FETCH });
    const response = await API.getRequest(
      `/api/v1/location/?page=${page}` + (providerId ? `&provider=${providerId}` : '')
    );
    dispatch({ type: GET_LOCATIONS_RESOLVE, payload: response.data });

    return response.data;
  } catch (error) {
    console.error(error);
    dispatch({ type: GET_LOCATIONS_REJECT });
  }
};

export const getLocationsNextPage = () => async (dispatch, getState) => {
  const pagination = getState().provider.locationsPagination;

  if (pagination.hasNextPage) {
    const nextPage = pagination.lastPage + 1;

    const data = await dispatch(getLocations(nextPage));
    if (data?.results) dispatch(setLocationsLastPage(nextPage));

    window.analytics.track('Load More Locations', { pageId: nextPage });
  }
};

export const getProvidersAndLocations = () => async (dispatch, getState) => {
  try {
    const isLoading = getState().provider.loading;

    if (isLoading) return;

    dispatch({ type: GET_PROVIDERS_AND_LOCATIONS_FETCH });
    await Promise.all([dispatch(getProviders()), dispatch(getLocations())]);
    dispatch({ type: GET_PROVIDERS_AND_LOCATIONS_RESOLVE });
  } catch (error) {
    console.error(error);
    dispatch({ type: GET_PROVIDERS_AND_LOCATIONS_REJECT });
  }
};
