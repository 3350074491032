import React, { useState, useEffect, forwardRef } from "react";
import { Card, PageTitle } from "../../../styles";
import Table from "../../../components/Table/Table";
import { getActivityData, getUsers } from "../../../actions/radminActivityData";
import Box from "../../../components/_styles/Box";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { EmptyState } from "../../../components/EmptyState/EmptyState";
import { ReactComponent as EmptyStateImg } from "../../../assets/empty-followup.svg";
import { useHistory, useLocation } from "react-router";
import LoadMoreButton from "../../../components/LoadMoreButton";
import Flex from "../../../components/_styles/Flex";
import moment from "moment";
import { capitalize, isArray } from "lodash";
import { ReactComponent as RemoveRecoIcon } from "../../../assets/close-outline.svg";
import DatePicker from "react-datepicker";
import Select, { components } from "react-select";

import { ReactComponent as CalendarIcon } from "../../../assets/calendar-outline.svg";
import { CloseSign, SelectedItemContainer } from "../FollowUpList/FollowUpList";
import close from "../../../assets/cancel-outline-chip.svg";
import { StatusBadge as MessageStatus } from "../Messages/Messages";
import { useRef } from "react";

const Headercontainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => props.marginBottom};
`;

const DatePickerContainer = styled(Flex)`
  min-width: 227px;
  & > div > div > div {
    height: 38px;
    min-width: 227px;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 4px;
  font-size: 18px;
  width: max-content;
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
`;
const DateContainer = styled(Flex)`
  margin-right: 15px;
  font-size: 14px;
  color: #828282;
  font-family: Open Sans;
`;

const DateClearButton = styled.div`
  cursor: pointer;
  display: flex;
`;

const RemoveRecoText = ({ value }) => (
  <td style={{ fontStyle: 'italic', color: 'red' }}>{value ? "Rejected" : "Not Rejected"}</td>
  
);

const columns = [
  { key: "accession_number", name: "Accession Number", align: "left" },
  {
    key: "status_override_from",
    name: "Status Override From",
    displayCell: (status) =>
      status ? (
        <MessageStatus
          styles={{ marginLeft: 0 }}
          status={status}
          dropDown={false}
        />
      ) : (
        "No Status"
      ),
    align: "left",
    paddingLeft:'74px'
  },
  {
    key: "status_override_to",
    name: "Status Override To",
    displayCell: (status) =>
      status ? (
        <MessageStatus
          styles={{ marginLeft: 0 }}
          status={status}
          dropDown={false}
        />
      ) : (
        "No Status"
      ),
    align: "left",
  },
  { key: "user_rejected_status",
    name: "User Rejected Status",
    align: "left",
    displayCell: (value) => 
    value ? (
      <RemoveRecoText value={value} />) : ("Not Rejected")
    //  capitalize(String(value))
  },
  { key: "changed_at", name: "Changed At", align: "left" },
  { key: "user", name: "User", align: "left" }
];

const SelectDropdownTitle = styled.div`
  margin-bottom: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-transform: uppercase;
`;

const SelectContainer = styled.div`
  width: 227px;
  & div.select__control,
  div.select__value-container {
    height: 38px;
    cursor: pointer;
  }
`;

const Image = styled.img`
  display: block;
  width: 20px;
`;

const SelectedFilters = styled.div`
    display:'flex',
    flex-wrap:'wrap',
    width:'100%',
    justify-content: 'right'
`;

const { ValueContainer } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      {props.hasValue ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            left: "10px",
            color: "#828282",
            fontSize: "14px",
            fontWeight: 400,
            fontFamily: "Open Sans",
          }}
        >
          Edit selection...
        </div>
      ) : null}
      {React.Children.map(children, (child) =>
        child && child.props && child.props.className !== "clearIndicator"
          ? child
          : null
      )}
    </ValueContainer>
  );
};

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white", padding:'0px 7px' }),
  option: (styles, { isDisabled, isSelected }) => {
    return {
      ...styles,
      fontSize: "14px",
      color: "black",
      cursor: isDisabled ? "not-allowed" : "default",
      backgroundColor: "white",
      display: "flex",
      width:'100%',
      alignItems:'center'
    };
  },
  multiValue: (styles, { data }) => ({
    ...styles,
    borderRadius: "25px",
    backgroundColor: "#EEEEEE",
    color: "#A1A1A1",
    padding: "4px 10px",
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#A1A1A1",
    fontSize: "10px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#828282",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Open Sans",
    marginLeft:'0px'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
};

const IndicatorsContainer = (props) => {
  return (
    <div style={{ backgroundColor: "#FFF" }}>
      <components.IndicatorsContainer {...props} />
    </div>
  );
};

const MultiValue = (props) => {
  return null;
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', gap:'16px' }}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            style={{ margin: "0px", padding: '0px', cursor:'pointer' }}
          />
          <label style={{ margin: 0, padding: '0px', flex: '1', cursor:'pointer' }}>{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};

const ActivityLogs = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const itemsPortionSize = 10;
  const [displayItemsCount, setDisplayItemsCount] = useState(itemsPortionSize);

  let initStartDate = null;
  let initEndDate = null;
  let initRange = {
    startDate: null,
    endDate: null,
  };
  let user = [];
  const queryParams = new URLSearchParams(location.search);

  if (queryParams.has("startDate") && queryParams.has("endDate")) {
    initStartDate = new Date(queryParams.get("startDate"));
    initEndDate = new Date(queryParams.get("endDate"));
    initRange = {
      startDate: queryParams.get("startDate"),
      endDate: queryParams.get("endDate"),
    };
  }
  if (queryParams.has("user")) {
    const userList = queryParams.getAll("user");
    userList.forEach((itm) => {
      user.push({
        value: itm,
        category: "user",
        label: "",
      });
    });
  }

  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);
  const [DatesFilter, setDatesFilter] = useState({
    startDate: initRange.startDate,
    endDate: initRange.endDate,
  });
  const [users, setUsers] = useState([]);
  const mounted = useRef();

  const activityLogs = useSelector((state) => state.radAdmin.activityLogs);

  const totalItems = useSelector(
    (state) => state.radAdmin.activityLogs.totalItems
  );

  const [selectedValues, setSelectedValues] = useState({
    user: user.length > 0 ? user : "",
  });

  const DatePickerInput = forwardRef(({ onClick, startDate, endDate }, ref) => {
    const formatDate = (date) => {
      return date ? moment(date).format("MMM, DD, YYYY") : "No Date";
    };

    return (
      <DatePickerButton onClick={onClick} ref={ref}>
        <DateContainer>
          {!startDate && !endDate
            ? "Select..."
            : `${formatDate(startDate)} - ${formatDate(endDate)}`}
        </DateContainer>
        {!startDate && !endDate && <CalendarIcon />}
        {(startDate || endDate) && (
          <DateClearButton onClick={resetExamDateFilter}>
            <RemoveRecoIcon />
          </DateClearButton>
        )}
      </DatePickerButton>
    );
  });

  useEffect(() => {
    dispatch(getUsers()).then((res) => {
      if (res) {
        setUsers(
          res?.results?.map((itm) => ({
            label: itm.email,
            value: itm.id,
            category: "user",
          }))
        );
      }
    });
  }, []);

  useEffect(() => {
      const searchParams = new URLSearchParams(location.search);

      if (endDate) {
        searchParams.set("startDate", DatesFilter.startDate);
        searchParams.set("endDate", DatesFilter.endDate);
      } else {
        searchParams.delete("startDate");
        searchParams.delete("endDate");
      }
      if (selectedValues.user) {
        searchParams.delete("user");
        for (let i = 0; i < selectedValues.user.length; i++) {
          searchParams.append("user", selectedValues.user[i].value);
        }
      } else {
        searchParams.delete("user");
      }

      history.push({ search: searchParams.toString() });
      dispatch(getActivityData(pageNumber, selectedValues, DatesFilter));
  }, [selectedValues.user, endDate, pageNumber]);

  const onLoadMoreLogs = () => {
    if (totalItems >= 10) {
      window.analytics.track("Activity-Logs-Load-More-Click");
      setPageNumber((prevNumber) => prevNumber + 1);
      setDisplayItemsCount(displayItemsCount + itemsPortionSize);
    }
  };

  const closeSelected = (category, selectedItem) => {
    setPageNumber(1);
    const filters = selectedValues[category].filter((item) => {
      return item !== selectedItem;
    });
    setSelectedValues({
      ...selectedValues,
      [category]: filters,
    });
  };

  const resetExamDateFilter = () => {
    setPageNumber(1);
    setStartDate(null);
    setEndDate(null);
    setDatesFilter({
      startDate: null,
      endDate: null,
    });
  };

  const onDatesChange = (dates) => {
    if (isArray(dates)) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      setPageNumber(1);
      setDatesFilter({
        startDate: start ? moment(start).format("YYYY-MM-DD") : "",
        endDate: end ? moment(end).format("YYYY-MM-DD") : "",
      });
    }
  };

  return (
    <Box p={30}>
      <Headercontainer
        marginBottom={
          Object.values(selectedValues).filter(Boolean).length > 0
            ? "23px"
            : "67px"
        }
      >
        <PageTitle>Activity Logs</PageTitle>
        <div style={{ display: "flex", gap: "20px" }}>
          <div>
            <SelectDropdownTitle>Timeframe</SelectDropdownTitle>
            <DatePickerContainer>
              <DatePicker
                selected={startDate}
                onChange={onDatesChange}
                startDate={startDate}
                endDate={endDate}
                customInput={
                  <DatePickerInput startDate={startDate} endDate={endDate} />
                }
                shouldCloseOnSelect={false}
                selectsRange
                popperPlacement="auto"
                dateFormat={"dd/MM/yyyy"}
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: "5px, 10px",
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: "viewport",
                  },
                }}
              />
            </DatePickerContainer>
          </div>

          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <SelectContainer>
              <SelectDropdownTitle>User</SelectDropdownTitle>
              <Select
                classNamePrefix="select"
                styles={colourStyles}
                options={users}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  ValueContainer: CustomValueContainer,
                  Option,
                  IndicatorsContainer,
                  MultiValue,
                }}
                onChange={(value) =>{
                  setPageNumber(1);
                  setSelectedValues({ ...selectedValues, user: value });

                }
                }
                allowSelectAll={true}
                value={selectedValues["user"]}
              />
            </SelectContainer>
          </div>
        </div>
      </Headercontainer>
      <SelectedFilters
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "right",
          alignItems: "center",
          fontSize: "10px",
          gap: "12px",
        }}
      >
        {Object.values(selectedValues).map((item, index) => {
          if (!item) return null;
          return item.map((i) => {
            return (
              <SelectedItemContainer
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "0px",
                  fontWeight:600,
                  color:'#828282'
                }}
                key={i.value + index}
              >
                {i.label
                  ? i.label
                  : users?.find((user) => user.value === i.value)?.label}
                <CloseSign
                  style={{ marginRight: "0px", marginTop:'0px' }}
                  onClick={() => closeSelected(i.category, i)}
                >
                  <Image src={close} alt={"close sign"} />
                </CloseSign>
              </SelectedItemContainer>
            );
          });
        })}
      </SelectedFilters>
      <Card
        style={{
          marginTop:
            Object.values(selectedValues).filter(Boolean).length > 0
              ? "22px"
              : "0px",
        }}
      >
        <>
          <Table
            rows={activityLogs.data}
            columns={columns}
            initialPageNumber={activityLogs.pageNumber}
            totalItems={activityLogs.totalItems}
            isLoadingRows={activityLogs.isLoading && pageNumber < 2}
            getRowsAction={(pageNumber) =>
              getActivityData(pageNumber, selectedValues, DatesFilter)
            }
            shouldGetRowActions={false}
            noDataRender={() => (
              <EmptyState
                title={"No Results"}
                img={() => <EmptyStateImg />}
                imageContainerStyles={{ width: "73px", height: "81px" }}
              >
                Sorry, there are no results
              </EmptyState>
            )}
          />
          {activityLogs?.data && activityLogs?.data.length !== 0 && (
            <LoadMoreButton
              title="Load more logs"
              handleClick={onLoadMoreLogs}
              isDisabled={totalItems === activityLogs.data.length}
              isLoading={activityLogs.isLoading}
              style={{
                backgroundColor: "#0c2945",
                fontWeight: 600,
              }}
            />
          )}
        </>
      </Card>
    </Box>
  );
};

export default ActivityLogs;
