import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import Stripe from './Stripe';
import RightPanel from './PaymentRightPanel';
import ConfirmationModal from './ConfirmationModal';
import { SchedulingUIWrapper, Header } from '../common';
import CreditCard from '../../../assets/checkout/credit-card.svg';
import CreditCardLarge from '../../../assets/checkout/credit-card-large.png';
import { resetPurchase } from '../../../actions/checkout';

const CreditCardLargeHolder = styled.div`
  margin-top: 40px;
`;

const CreditCardLargeImg = styled.img`
  margin: auto;
`;

const PaymentInfo = () => {
  const dispatch = useDispatch();
  const paymentResponse = useSelector((state) => state.checkout.paymentResponse);

  const [modalOpen, toggleModalOpen] = useState(false);

  useEffect(() => {
    dispatch(resetPurchase());
  }, []);

  return (
    <SchedulingUIWrapper>
      <ConfirmationModal
        isOpen={modalOpen && paymentResponse.isDone && !paymentResponse.isError}
        onRequestClose={() => toggleModalOpen(false)}
        order={paymentResponse.id}
      />
      <div className='seven-cols'>
        <Header imgSrc={CreditCard} text='Payment information' />
        <CreditCardLargeHolder>
          <CreditCardLargeImg src={CreditCardLarge} alt='creditcard' />
        </CreditCardLargeHolder>
        <Stripe onSuccess={() => toggleModalOpen(true)} />
      </div>
      <RightPanel />
    </SchedulingUIWrapper>
  );
};

export default PaymentInfo;
