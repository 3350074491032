import React from 'react';
import styled from 'styled-components';
import Flex from '../../../../components/_styles/Flex';

const Card = styled.div`
  margin-top: 25px;
  background: #F7F8FA;
  border: 1px solid #C2CCD9;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 14px;
  margin-right: 17px;
  &:last-child {
    margin-right: 0; 
  }
`;

const Title = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 120%;
  color: #0C2945;
`

const UserHeaderKey = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #0C2945;
  margin-top: 1px;
  word-break: break-all;
`

const Status = styled.div`
  visibility: ${props => props.isDisplay ? 'visible' : 'hidden'}
  color: ${props => props.isRequired ? '#F73B3B' : '#219653'}
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  margin-top: 6px;
`

const MappedColumn = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 120%;
  color: #333333;
  margin-top: 30px;
`

const CardHeader = styled(Flex)`
  justify-content: space-between;
`

const CardHeaderText = styled(Flex)`
  flex-direction: column;
  width: 150px;
`

const SkipButton = styled(Flex)`
  visibility: ${props => props.isDisplay ? 'visible' : 'hidden'}
  width: 56px;
  height: 21px;
  font-size: 12px;
  color: #2F80ED;
  border: 1px solid #00AEEF;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  &:hover {
    cursor: pointer;
  }
`

const Selector = styled.select`
  width: 100%;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #C2CCD9;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 12px;
  color: #333333;
  padding: 0 6px;
`


const ColumnsMappingCard = ({ userHeaderKey, mappedAppHeaderKey, appHeaders, setMapping, skipUserHeader, idx }) => {
  const appHeader = mappedAppHeaderKey && appHeaders.find(appHeader => appHeader.key === mappedAppHeaderKey);
  const onChangeSelector = (e) => {
    setMapping(userHeaderKey, e.target.value);
  }
  const onSkip = () => skipUserHeader(userHeaderKey, idx);

  return (
    <Card>
      <CardHeader>
        <CardHeaderText>
          <Title>Imported Header Column</Title>
          <UserHeaderKey>{userHeaderKey}</UserHeaderKey>
          <Status isDisplay={mappedAppHeaderKey} isRequired={appHeader && appHeader.isRequired}>
            {appHeader && appHeader.isRequired ? (
              'Required for Importing'
            ) : (
              'Optional for Importing'
            )}
          </Status>
        </CardHeaderText>
        <SkipButton block onClick={onSkip} isDisplay={!(appHeader && appHeader.isRequired)}>Skip</SkipButton>
      </CardHeader>
      <MappedColumn>Mapped Column</MappedColumn>
      <Selector onChange={onChangeSelector} value={mappedAppHeaderKey}>
        <option value={''}>Choose an Attribute</option>
        {appHeaders.map(appHeader => (
          <option key={appHeader.key} value={appHeader.key}>{appHeader.name}</option>
        ))}
      </Selector>
    </Card>
  )
};

export default ColumnsMappingCard;
