import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Box from '../../../components/_styles/Box';
import Flex from '../../../components/_styles/Flex';
import { IntegrationsPanel } from './styles';
import Cerner from './CernerIcon';
import CSVUpload from './CSVUploadIcon';
import Epic from './EpicIcon';

function Integrations(){
  const csvUploadClickAnalytic = () => {
    window.analytics.track('csvUpload-Click');
  }

  return (
    <Box p={30}>
      <Row>
        <Col lg={12} xl={8}>
          <IntegrationsPanel p={20} minHeight={500} minWidth={900}>
          <h3>Integrations</h3>
            <Flex textAlign='left' flexDirection='column' flex='1'>
              <Flex.Col py={37} flex='1'>
                <p children='LightWeight' mb={15} uppercase="true" />
                <Link to={'/auth/radAdmin/Import'} onClick={csvUploadClickAnalytic}><CSVUpload /></Link>
              </Flex.Col>
            </Flex>
            <Flex textAlign='left' flexDirection='column' flex='1'>
              <Flex.Col py={37} flex='1'>
                <p children='Real Time HL7' mb={15} uppercase="true" />
                <Link to={'/auth/radAdmin/Import'}><Epic /></Link>
                <Link to={'/auth/radAdmin/Import'}><Cerner /></Link>
              </Flex.Col>
            </Flex>
          </IntegrationsPanel>
        </Col>
      </Row>
    </Box>

  );
};

export default Integrations;
