import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Flex from '../../components/_styles/Flex';
import Text from '../../components/_styles/Text';
import { PlusIcon } from '../RadiologySearchPage/Icons';

const CircleIcon = styled.div`
  background: #e0e5ed;
  height: 58px;
  width: 58px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircleWrapper = styled.div`
  border: 1px dashed #c2ccd9;
  border-radius: 8px;
  height: 100%;
  padding: 30px;
  cursor: pointer;

  &:hover {
    border-color: #00AEEF;
    text-decoration: none;
  }
`;

const CircleButton = () => {
  const onClick = useCallback(() => {
    window.analytics.track('Schedule New Scan Button Click');
  }, []);

  return (
    <CircleWrapper onClick={onClick} as={Link} to='/plans'>
      <Flex justifyContent='center' alignItems='center' height='inherit'>
        <Flex flexDirection='column' alignItems='center'>
          <CircleIcon>
            <PlusIcon />
          </CircleIcon>
          <Text>Schedule new scan</Text>
        </Flex>
      </Flex>
    </CircleWrapper>
  );
};

export default CircleButton;
