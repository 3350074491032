import {
    GET_PATIENT_PROFILE_FETCH,
    GET_PATIENT_PROFILE_RESOLVE,
    GET_PATIENT_PROFILE_REJECT,
    CREATE_OR_UPDATE_RISK_RESOLVE,
    CREATE_OR_UPDATE_RECOMMENDATION_RESOLVE,
    GET_SIGN_URL_SUCCESS,
    GET_SIGN_URL_ERROR,
    GET_PRESCRIPTION_FETCH,
    GET_PRESCRIPTION_RESOLVE,
    GET_PRESCRIPTION_REJECT,
    TOGGLE_B2B_PRESCRIPTION,
    TOGGLE_B2B_PRESCRIPTION_MODAL,
    CREATE_B2B_APPOINTMENT_RESOLVE
} from '../actions/patientProfile';

const initialState = {
    loading: false,
    user: null,
    contactInfo: null,
    healthHistory: null,
    appointments: {
        prescan: null,
        scan: null
    },
    report: null,
    signUrl: null,
    prescription: null,
    prescriptions: [],
    recommendations: [],
    risks: [],
    noteTypes: [],
    b2bPrescription: {
        active: false,
        modalOpened: false
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
    case GET_PATIENT_PROFILE_FETCH:
        return {
            ...state,
            loading: true
        };
    case GET_PATIENT_PROFILE_RESOLVE:
        return {
            ...state,
            ...action.payload,
            prescription: null,
            loading: false
        };
    case GET_PATIENT_PROFILE_REJECT:
        return {
            ...initialState
        };
    case CREATE_OR_UPDATE_RISK_RESOLVE:
        return {
            ...state,
            risks: state.risks
                .filter((r) => r.recommendation_type !== action.payload.recommendation_type)
                .concat(action.payload)
        };
    case CREATE_OR_UPDATE_RECOMMENDATION_RESOLVE:
        return {
            ...state,
            recommendations: state.recommendations
                .filter((r) => r.recommendation_type !== action.payload.recommendation_type)
                .concat(action.payload)
        };
    case GET_SIGN_URL_SUCCESS:
        return {
            ...state,
            signUrl: action.payload,
            loading: false
        };
    case GET_SIGN_URL_ERROR:
        return {
            ...state,
            signUrl: { error: true },
            loading: false
        };
    case GET_PRESCRIPTION_FETCH:
        return {
            ...state,
            loading: true
        };
    case GET_PRESCRIPTION_RESOLVE:
        return {
            ...state,
            loading: false,
            prescription: action.payload
        };
    case GET_PRESCRIPTION_REJECT:
        return {
            ...state,
            loading: false
        };
    case TOGGLE_B2B_PRESCRIPTION:
        return {
            ...state,
            b2bPrescription: {
                ...state.b2bPrescription,
                active: action.payload
            }
        };
    case TOGGLE_B2B_PRESCRIPTION_MODAL:
        return {
            ...state,
            b2bPrescription: {
                ...state.b2bPrescription,
                modalOpened: action.payload
            }
        };
    case CREATE_B2B_APPOINTMENT_RESOLVE:
        return {
            ...state,
            appointments: {
                ...state.appointments,
                scan: action.payload
            }
        };
    default:
        return state;
    }
};
