export default {
    API: {
        PRODUCTION: 'https://api.seewithin.co',
        STAGING: 'https://api-staging.seewithin.co',
        LOCAL: 'http://localhost:8000',
    },
    URLS: {
        PRODUCTION: 'https://app.seewithin.co',
        STAGING_DEV: 'https://staging-within-development.herokuapp.com',
        STAGING_DEV_SAME_DOMAIN: 'https://staging.seeithin.co',
        STAGING_MASTER: 'https://staging-within-master.herokuapp.com',
        LOCAL: 'http://localhost:8000',
    },
    ENV: {
        DEVELOPMENT: 'development',
        PRODUCTION: 'production',
        STAGING_DEV: 'staging-within-development.herokuapp.com',
        STAGING_DEV_SAME_DOMAIN: 'staging.seewithin.co',
        STAGING_MASTER: 'staging-within-master.herokuapp.com',
    },
    PATHNAME: {
        LOGIN: '/login',
        REGISTER: '/register',
        PAYMENT_INFO: '/payment-info',
    },
    INTEGRATION: {
        SEGMENT_KEY_LIVE:  process.env.REACT_APP_SEGMENT_KEY_LIVE,
        SEGMENT_KEY_TEST: process.env.REACT_APP_SEGMENT_KEY_TEST,
        HELLO_SIGN_CLIENT_ID_TEST: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID_TEST,
        HELLO_SIGN_CLIENT_ID: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID ,
        SENTRY_KEY: process.env.REACT_APP_SENTRY_KEY,
        CANNY_BOARD_TOKEN_TEST: process.env.REACT_APP_CANNY_BOARD_TOKEN_TEST ,
        CANNY_APP_ID_TEST: process.env.REACT_APP_CANNY_APP_ID_TEST,
        CANNY_BOARD_TOKEN_LIVE: process.env.REACT_APP_CANNY_BOARD_TOKEN_LIVE,
        CANNY_APP_ID_LIVE:process.env.REACT_APP_CANNY_APP_ID_LIVE,
    }
};
