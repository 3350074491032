import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Spinner } from 'react-bootstrap';
import Flex from '../_styles/Flex';
import { ReactComponent as NoDataGroup } from '../../assets/noDataGroup.svg';
const TableContainer = styled.table`
  width: 100%;
  overflow: auto;
  margin-bottom: 0;
  @media (max-width: 768px) {
    display: inline-block;
  }
`
export const Card = styled.div`
  background: #FFFFFF;
  border:${props => !props.paginated && '1px solid #C2CCD9'} ;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  border-radius:${props => !props.paginated && '8px'};
  padding:${props => !props.paginated && '15px'};
  height: ${props => props.paginated ? '16rem' : '30rem'};
  overflow:auto; 
`
const TableCell = styled.td`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align:${props => props.align};
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
  border: none;
  padding-left:0;
  &:first-child{
    padding-left: 16px;
  }
`
const TableHeaderCell = styled(TableCell)`
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
  font-weight: 600;
  font-size: 14px;
  text-align:${props => props.align};
  letter-spacing: 0.2px;
  color: #9fa2b4;
  padding: 3px 0px;
  letter-spacing: 0.2px;
`
const TableRow = styled.tr`
  border: none;
  &:hover {
    background: #F9FAFC;
    transition: all 0.3s ease;
  }
`
const HeaderContainer = styled.span`
  width: 100%;
  display: block;
  padding: 4px;
  border-radius: 10px;
  padding-left:0px;
`
const NoDataContainer = styled(Flex)`
  justify-content: center;
  align-items:center;
  padding-top:50px;
`
const SpinnerContainer = styled(Flex)`
  justify-content: center;
  height: 100%;
  align-items: center;
`

const DetailsTable = ({ rows, columns, isLoadingRow, isPaginated}) => {
  return (
    <Card paginated={isPaginated}>
      <TableContainer>
        <thead>
          <tr style={{background: '#E6E9F1'}}>
            {columns.map((column, i) => (
              <TableHeaderCell width={column.width} maxWidth={column.maxWidth} key={JSON.stringify(column + i)} align="left"><HeaderContainer>{column.name}</HeaderContainer></TableHeaderCell>
            ))}
          </tr>
        </thead>
        {!isLoadingRow && (
          <tbody>
          <>
            {rows && rows.map((row, idx) => (
              <TableRow key={JSON.stringify(row + idx)}>
                {columns.map((column, i) => (
                  <TableCell key={JSON.stringify(column + i)} align="left">{column.displayCell ? column.displayCell(row[column.key], row, idx) : row[column.key]}</TableCell>
                ))}
              </TableRow>
            ))}
          </>
          </tbody>
        )}
         
      </TableContainer>
      {
        !isLoadingRow && rows && isEmpty(rows) && ( 
          <NoDataContainer>
            <NoDataGroup/>
          </NoDataContainer>
        )
      }
      {isLoadingRow && (
        <SpinnerContainer>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </SpinnerContainer>
      )}
    </Card>
  )
}

export default DetailsTable;