import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGIN_COMPLETED,
    SIGNUP,
    SIGNUP_SUCCESS,
    SIGNUP_FAILED,
    LOGGED_IN,
    LOGGED_OUT,
    LOGOUT,
    GET_USER_INFO,
    GET_USER_INFO_SUCCESS,
    RESET_AUTH_ERROR,
    UPDATE_USER_SUCCESS,
    PATCH_USER_DATA,
    UPDATE_DOCTOR_PRACTICE_SUCCESS, SET_IS_HAVE_UPLOADS
} from '../actions/auth';
import { UPDATE_MEDICAL_HISTORY } from '../actions/checkout';

const initialUser = {
    url: '',
    id: '',
    role: '',
    email: '',
    first_name: '',
    last_name: '',
    doctor: {},
    contactInfo: {},
    healthHistory: {},
    appointments: [],
    medicalConditions: [],
    lastAppointmentSymptoms: []
};

const initialState = {
    isLoggedIn: false,
    loggingIn: false,
    loginError: null,
    signingUp: false,
    hasCheckedAuth: false,
    loadingUserInfo: false,
    loadedUserInfo: false,
    userType: null,
    loading: false,
    backButton: false,
    user: { ...initialUser }
};

export default (state = initialState, action) => {
    switch (action.type) {
    case PATCH_USER_DATA:
        return {
            ...state,
            user: {
                ...state.user,
                ...action.payload
            }
        };
    case LOGIN:
        return {
            ...state,
            isLoggedIn: false,
            hasCheckedAuth: false,
            loading: true
        };
    case LOGIN_SUCCESS:
        return {
            ...state,
            loggingIn: false,
            isLoggedIn: action.payload.isVerifiedUser? true : false,
            hasCheckedAuth: true,
            user: {
                ...state.user,
                ...action.payload.user
            },
            token: action.payload.token,
            loginError: null,
        };
    case LOGIN_FAILED:
        return {
            ...state,
            isLoggedIn: false,
            loggingIn: false,
            loginError: action.payload.error,
            hasCheckedAuth: false,
            userType: null,
        };
    case LOGIN_COMPLETED:
            return {
                ...state,
                loading: false
            };
    case SIGNUP:
        return {
            ...state,
            signingUp: true,
            isLoggedIn: false,
            hasCheckedAuth: false,
            loading: true
        };
    case SIGNUP_SUCCESS:
        return {
            ...state,
            signingUp: false,
            isLoggedIn: false,
            hasCheckedAuth: true,
            loading: false
        };
    case SIGNUP_FAILED:
        return {
            ...state,
            signingUp: false,
            isLoggedIn: false,
            hasCheckedAuth: false,
            userType: null,
            loading: false
        };
    case LOGGED_IN:
        return {
            ...state,
            hasCheckedAuth: true,
            isLoggedIn: true
        };
    case LOGGED_OUT:
        return {
            ...state,
            hasCheckedAuth: true,
            isLoggedIn: false,
            userType: null,
            token: null
        };
    case GET_USER_INFO:
        return {
            ...state,
            loadingUserInfo: true
        };
    case GET_USER_INFO_SUCCESS:
        return {
            ...state,
            loadingUserInfo: false,
            loadedUserInfo: true,
            user: action.payload.user
        };
    case LOGOUT:
        return {
            ...state,
            hasCheckedAuth: true,
            isLoggedIn: false,
            userType: null,
            token: null,
            user: {
                ...initialUser
            }
        };
    case RESET_AUTH_ERROR:
        return {
            ...state,
            signupError: {}
        };
    case UPDATE_USER_SUCCESS:
        return {
            ...state,
            user: {
                ...state.user,
                ...action.payload
            }
        };
    case UPDATE_MEDICAL_HISTORY:
        return {
            ...state,
            user: {
                ...state.user,
                healthHistory: action.payload
            }
        };
    case UPDATE_DOCTOR_PRACTICE_SUCCESS:
        return {
            ...state,
            user: {
                ...state.user,
                doctor: action.payload
            }
        };
    case SET_IS_HAVE_UPLOADS:
        return {
            ...state,
            user: {
                ...state.user,
                isHaveUploads: action.payload
            }
        };
    default:
        return state;
    }
};
