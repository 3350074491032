import React, { useEffect } from "react";
import { getCannyBoardToken } from "../../../utils/keys";
import { useState } from "react";
import { Spinner } from "react-bootstrap";

const BoardToken = getCannyBoardToken();

const Feedback = ({ ssoToken }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    function loadCannyScript() {
      if (!document.getElementById("canny-jssdk")) {
        const script = document.createElement("script");
        script.id = "canny-jssdk";
        script.src = "https://canny.io/sdk.js";
        script.async = true;

        script.onload = () => {
          window.Canny("render", {
            boardToken: BoardToken,
            basePath: null,
            ssoToken: ssoToken,
            theme: "light",
            onLoadCallback: () => setLoading(false),
          });
        };

        document.head.appendChild(script);
      } else {
        window?.Canny &&
          window.Canny("render", {
            boardToken: BoardToken,
            basePath: null,
            ssoToken: ssoToken,
            theme: "light",
            onLoadCallback: () => setLoading(false),
          });
      }
    }

    loadCannyScript();
  }, []);

  return (
    <>
      {loading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spinner animation="border" role="status" size="sm" />
        </div>
      )}
      <div style={{ display: loading ? "none" : "block" }} data-canny />
    </>
  );
};

export default Feedback;
