import React, { useRef, useState } from "react";
import RemoveConfirmation from "../Cohorts/RemoveConfirmation";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { deleteUser, getInvitedUsers, restoreUser } from "../../../actions/radminSettings";
import styled from "styled-components";
import InviteUsersModal from "./InviteUsersModal";
import LoadMoreButton from "../../../components/LoadMoreButton";
import { EmptyState } from "../../../components/EmptyState/EmptyState";
import DoubleDataCell from "../PatientList/LastScanCell";
import { Card } from "../../../styles";
import Table from "../../../components/Table/Table";
import { ReactComponent as EmptyStateImg } from "../../../assets/empty-state-search.svg";
import { useFormik } from "formik";
import Flex from "../../../components/_styles/Flex";
import { ReactComponent as Search } from "../../../assets/searchIconGrey.svg";
import { ReactComponent as RestoreIcon } from "../../../assets/restore-icon.svg";
import { ReactComponent as RestoreIconHover } from "../../../assets/restore-icon-hover.svg";
import RestoreConfirmation from "../../../components/RestoreConfirmation/RestoreConfirmation";
import { Tooltip, withStyles } from "@material-ui/core";


const cancelIcon = `${process.env.PUBLIC_URL}/${"cancel-circled.svg"}`;

const FormContainer = styled(Flex)`
  display: flex;
  justify-content: space-between;
  align-item: space-between;
`;
const InputContainer = styled.div`
  width: 283px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position:relative;
`;
const SearchIcon = styled.i`
  display: inline-block;
  position: absolute;
  color: green;
  min-width: 40px;
  left:10px;
`;
const CustomPlaceholder = styled.span`
  position: absolute;
  // margin-left: 100px;
  margin-right: 120px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #666666;
  @media (max-width: 768px) {
    margin-left: 60px;
  }
`;
const Input = styled.input`
  width: 283px;
  background: white;
  border: 1px solid #d2d3d7;
  border-radius: 5px;
  height: 38px;
  font: normal 16px "Roboto Condensed", sans-serif;
  padding-left: 35px;
`;

const ClearButton = styled.button`
  position: absolute;
  border-radius: 5%;
  z-index: 1;
  border: none;
  cursor: pointer;
  color: #000000;
  background-color: transparent;
  transform: translateX(2px);
  right:10px;
`;

const PageTitleText = styled.div`
  font-family: Roboto Condensed;
  font-style: bold;
  font-weight: 700;
  font-size: 28px;
  line-height: 33.6px;
  letter-spacing: 0.2px;
  color: #0C2945;
`;

const columns = [
  {
    key: "first_name",
    name: "Name",
    displayCell: (data, row) => row.first_name + " " + row.last_name,
    align: "left",
  },
  { key: "permission_level", name: "Role", align: "left" , displayCell: (data, row) =>row?.is_superuser? "superadmin" : row?.permission_level },
  { key: "email", name: "Email", align: "left" },
  {
    key: "date_joined",
    name: "Date Added",
    align: "left",
  },
  {
    key: "last_login",
    name: "Last Login",
    align: "left",
  },
  {
    key: "is_active",
    name: "User Active",
    align: "left",
    displayCell: (data, row) =>row?.is_active?"Active":"Inactive"
  },
];

const InviteUsersButton = styled.button`
  border-radius: 5px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  border: 2px solid #00aeef;
  background-color: #00aeef;
  color: #ffffff;
  width: 145px;
  height: 38px;
  &:hover {
    background:transparent;
    color:#00aeef;
  }
`;

const IconContainerBox = styled.span`
 position: relative;
 cursor: pointer;
 display: inline-block;
 width: 30px; 
 height: 30px; 

 svg {
  position: absolute;
  top: 0px;
  left: 0;
  transition: opacity 0.1s ease;
 }

 svg.dark {
  opacity: 1;
 }

 svg.light {
  opacity: 0; 
 }

 &:hover svg.dark {
  opacity: 0;
 }

 &:hover svg.light {
  opacity: 1;
 }

 .label {
  display: none;
  font-size:9px;
  font-weight: 700;
  color:${props=>props.color};
 }

 &:hover .label {
  display: block;
  position: absolute;
  top: ${props=>props.startGap || '-40%'};
  line-height:12px;
  left: 50%;
  transform: translateX(-50%);
 }
`;

const StyledTooltip = (props) => {
  const { className, ...other } = props;

  return (
    <Tooltip
      {...other}
      PopperProps={{
        className: className,
        style: {
          backgroundColor: "#AEE3F8",
          marginBottom: "20px",
          borderRadius: "4px",
          zIndex: 3,
        },
      }}
    />
  );
};

const StyledTooltipWrapper = withStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    color: "black!important",
    fontSize:"14px!important",
    fontWeight:400 + "!important",
    width: "220px",
    padding: "10px",
    textAlign:'center',
    margin: "0px",
    position: 'relative',
  },
 
}))(StyledTooltip);




const InvitedUsers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [invitedUsersPageNumber, setInvitedUsersPageNumber] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const itemsPortionSize = 10;
  const [displayInvitedUsersItemsCount, setDisplayInvitedUsersItemsCount] =
    useState(itemsPortionSize);

  const invitedUsers = useSelector((state) => state.radAdmin.invitedUsers);
  const [selectedData, setSelectedData] = useState({
    visibility: false,
    data: {},
  });

  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [restoreDialogOpen, setRestoreDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isLoadingDeleteUser, setIsLoadingDeleteUser] = useState(false);
  const [isLoadingRestoreUser, setIsLoadingRestoreUser] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const inputRef = useRef(null);

  const totalItems = useSelector(
    (state) => state.radAdmin.invitedUsers.totalItems
  );

  const dialogHandler = (data) => {
    setRemoveDialogOpen(!removeDialogOpen);
    setSelectedId(data.id);
  };

  const openRestoreHandler = (data) => {
    setRestoreDialogOpen(!restoreDialogOpen);
    setSelectedId(data.id);
  };


  const deleteUserHandler = () => {
    setIsLoadingDeleteUser(true);
    dispatch(deleteUser(selectedId)).then((res) => {
      setIsLoadingDeleteUser(false);
      if (res) {
        setInvitedUsersPageNumber(1);
        setRemoveDialogOpen(false);
        setSelectedId();
      }
    });
  };

  const restoreUserHandler = () => {
    setIsLoadingRestoreUser(true);
    dispatch(restoreUser(selectedId)).then((res) => {
      setIsLoadingRestoreUser(false);
      if (res) {
        setInvitedUsersPageNumber(1);
        setRestoreDialogOpen(false);
        setSelectedId();
      }
    });
  };


  const onLoadMoreInvitedUsers = () => {
    setIsLoadingMore(true);
    if (totalItems >= 10) {
      window.analytics.track("Invited-Users-Load-More-Click");
      dispatch(getInvitedUsers(invitedUsersPageNumber + 1));
      setInvitedUsersPageNumber((prevNumber) => prevNumber + 1);
      setDisplayInvitedUsersItemsCount(
        displayInvitedUsersItemsCount + itemsPortionSize
      );
    }
  };

  const onHidePlaceholder = () => {
    setShowPlaceholder(false);
  };

  const onShowPlaceholder = () => {
    if (!inputRef.current.value) {
      setShowPlaceholder(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      searchValue: "",
      searchValueRad: "",
    },
    onSubmit: (values) => {
      dispatch(getInvitedUsers(1, values.searchValue));
      setInvitedUsersPageNumber(1);
      return;
    },
  });

  const onResetForm = () => {
    formik.setFieldValue("searchValue", "");
    dispatch(getInvitedUsers(1, ""));
    setShowPlaceholder(true);
    setInvitedUsersPageNumber(1);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <PageTitleText>Manage Users</PageTitleText>
        <div style={{display:'flex', gap:'10px'}}>
          <FormContainer>
            <form onSubmit={formik.handleSubmit}>
              <InputContainer>
                <SearchIcon>
                  <Search />
                </SearchIcon>
                {showPlaceholder && (
                  <>
                    <CustomPlaceholder onClick={onHidePlaceholder}>
                      {"Search User"}
                    </CustomPlaceholder>
                  </>
                )}
                <Input
                  type="text"
                  id={"searchValue"}
                  name={"searchValue"}
                  onFocus={onHidePlaceholder}
                  onBlur={onShowPlaceholder}
                  onChange={formik.handleChange}
                  value={formik.values.searchValue}
                  ref={inputRef}
                />
                {!showPlaceholder && formik.values.searchValue && (
                  <ClearButton type={"reset"} onClick={onResetForm}>
                    <img src={cancelIcon} />
                  </ClearButton>
                )}
              </InputContainer>
            </form>
          </FormContainer>
          <InviteUsersButton
            onClick={() => setSelectedData({ visibility: true, data: {} })}
          >
            Invite New User
          </InviteUsersButton>
        </div>
      </div>
      <Card>
        <Table
          rows={invitedUsers.data}
          columns={columns.concat({
            key: "2",
            name: "",
            displayCell: (cellValue, rowData) => (
              <div style={{display:'flex', gap:'20px'}}>
                <DoubleDataCell
                mainInfo={"edit"}
                EditRecoHandler={() => {
                  setSelectedData({
                    visibility: true,
                    data: rowData,
                  });
                }}
                RemoveRecoHandler={() => dialogHandler(rowData)}
                isDisabled={rowData?.is_superuser}
                disabledTooltipText="contact support@seewithin.co to change"
                isActive={rowData?.is_active}
                customGap={true}
                customGapValue="20px"
              />
              {!rowData?.is_active &&  
               rowData?.is_superuser && <div>
               <StyledTooltipWrapper placement="top" title={"contact support@seewithin.co to change"}>
                <RestoreIcon style={{cursor:'not-allowed'}}  className='dark' />
               </StyledTooltipWrapper>
             </div> }
             {
               !rowData?.is_superuser && !rowData?.is_active &&  
              <IconContainerBox color="#A0A0A0">
             <span className="label" >
             RESTORE
             </span>
                 <RestoreIcon 
                 className='dark'
                 onClick={(e) =>{
                   e.stopPropagation()
                   openRestoreHandler(rowData)
                  }} 
                 style={{ cursor:'pointer' }}
                 />
                 <RestoreIconHover
                  className="light" 
                  onClick={(e) =>{
                   e.stopPropagation()
                   openRestoreHandler(rowData)
                   }} 
                   style={{ cursor:'pointer' }}
                 />

             </IconContainerBox>  }
              
              </div>
        
            ),
            width: "20px",
          })}
          initialPageNumber={invitedUsers.pageNumber}
          totalItems={invitedUsers.totalItems}
          isLoadingRows={invitedUsers.isLoading && invitedUsersPageNumber < 2}
          getRowsAction={(pageNumber) => getInvitedUsers(pageNumber)}
          noDataRender={() => (
            <EmptyState title={"No Results"} img={() => <EmptyStateImg />}>
              Sorry, there are no results
            </EmptyState>
          )}
         disabledRow={"is_active"}
        />
        {invitedUsers?.data && invitedUsers?.data.length !== 0 && (
          <LoadMoreButton
            title="Load more users"
            handleClick={onLoadMoreInvitedUsers}
            isDisabled={totalItems === invitedUsers.data.length}
            isLoading={invitedUsers.isLoading}
            style={{
              backgroundColor: "#0c2945",
              fontWeight: 600,
            }}
          />
        )}  
         <RestoreConfirmation
          content="Are you sure you want to restore the user?"
          openDialog={restoreDialogOpen}
          dialogHandler={openRestoreHandler}
          isLoading={isLoadingRestoreUser}
          restoreHandler={restoreUserHandler}
          heading={"Restore User"}
          restoreHeader={"Yes, Restore User"}
        />
        <RemoveConfirmation
          content="Are you sure you want to remove the user?"
          openDialog={removeDialogOpen}
          dialogHandler={dialogHandler}
          isLoading={isLoadingDeleteUser}
          deleteHandler={deleteUserHandler}
          heading={"Remove User"}
          removeHeader={"Yes, Remove User"}
        />
        {selectedData?.visibility && (
          <InviteUsersModal
            data={selectedData.data}
            showModal={selectedData.visibility}
            closeModal={() => setSelectedData({ visibility: false, data: {} })}
          />
        )}
      </Card>
    </>
  );
};

export default InvitedUsers;
