import React from "react";
import cs from "classnames";

import "./Subpage.css";

class Subpage extends React.Component {
  classes() {
    return cs(
      "subpage",
      this.props.noPadding ? "no-padding" : null,
      this.props.className
    );
  }
  render() {
    return <div className={this.classes()}>{this.props.children}</div>;
  }
}

export default Subpage;
