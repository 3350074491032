import React from 'react';
import { useSelector } from 'react-redux';
import { Image } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';


import Box from '../../components/_styles/Box';
import Flex from '../../components/_styles/Flex';
import { Button } from '../../components/UI/Button';
import Text, { Small } from '../../components/_styles/Text';
import { Sidebar, Navbar } from '../../components/Dashboard/styles';

const Divider = styled.div`
  background: #c2ccd9;
  height: 1px;
  margin: 20px 0;
`;

function fullName(user) {
  if (!user.first_name && !user.last_name) {
    return null;
  }

  let name;

  if (user.first_name) {
    name = user.first_name;
  }

  if (user.last_name) {
    name += ' ';
    name += user.last_name;
  }

  return name;
}

function PersonalSidebar() {
  const { user } = useSelector((state) => state.auth);
  const { healthHistory, contactInfo, medicalConditions, lastAppointmentSymptoms } = user;

  return (
    <Sidebar size='large'>
      <Navbar anchor='right' size='large'>
        <Box p={30}>
          <Text uppercase mb={15} muted fontWeight='bold'>
            Your Personal Info
          </Text>
          <Flex alignItems='center'>
            <Box mr={15}>
              <Image
                src='https://previews.123rf.com/images/metelsky/metelsky1809/metelsky180900233/109815470-man-avatar-profile-male-face-icon-vector-illustration-.jpg'
                roundedCircle
                height='50'
                className='avatar'
              />
            </Box>
            <Box>
              <Text fontSize={3} fontWeight='bold'>
                {fullName(user)}
              </Text>
              <Small uppercase muted>
                {contactInfo?.city && contactInfo?.state ? `${contactInfo.city}, ${contactInfo.state}` : ''}
              </Small>
            </Box>
          </Flex>
          <Divider />
          <Box mb={15}>
            <Small uppercase muted>
              Date of Birth
            </Small>
            <Text fontSize={1} fontWeight='bold'>
              {healthHistory?.date_of_birth}
            </Text>
          </Box>
          <Box mb={15}>
            <Small uppercase muted>
              Gender
            </Small>
            <Text fontSize={1} fontWeight='bold'>
              {healthHistory?.gender === 'M' ? 'Male' : 'Female'}
            </Text>
          </Box>
          <Box mb={15}>
            <Small uppercase muted>
              Medical Conditions
            </Small>
            {medicalConditions?.map((mc) => (
              <Text fontSize={1} fontWeight='bold' key={mc.id}>
                {mc.description}
              </Text>
            ))}
          </Box>
          <Box mb={15}>
            <Small uppercase muted>
              Experiencing Symptoms
            </Small>
            {lastAppointmentSymptoms?.map((s) => (
              <Text fontSize={1} fontWeight='bold' key={s.id}>
                {s.description}
              </Text>
            ))}
          </Box>
          <Box mb={15}>
            <Small uppercase muted>
              Metal in Body
            </Small>
            <Text fontSize={1} fontWeight='bold'>
              {healthHistory?.metal_present_in_body ? 'Yes' : 'No'}
            </Text>
          </Box>
          <Button as={Link} to="/auth/profile" block primary variant="outline">
            Edit Medical History
          </Button>
        </Box>
      </Navbar>
    </Sidebar>
  );
}

export default PersonalSidebar;
