import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Box from "../../../components/_styles/Box";
import { ReactComponent as DocsIcon } from "../../../assets/docs-icon.svg";
import { ReactComponent as EmailIcon } from "../../../assets/email-icon.svg";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Feedback from "./Feedback";
import CannyIdentification from "./IdentifyUser";
import { useEffect } from "react";
import {getSSOToken } from "../../../actions/radminSupport";


const StyledLink = styled(Link)`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #00aeef;
  display: block;
  margin-bottom: 4px;
`;

const StyledSectionHeader = styled.h2`
  font-size: 24px;
  font-family: Roboto Condensed;
  font-weight: 700;
  color: #0c2945;
  margin-bottom: 25px;
  text-align:center;
`;

const StyledButton = styled.button`
height: 38px
background: #00AEEF;
color: white;
border: none;
border-radius: 4px;
width: 183px;
font-size: 14px;
font-weight: 600;
`;

const StyledSubHeading = styled.h3`
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #000000;
`;

const StyledDescription = styled.p`
  color: #a5a5a5;
  line-height: 21px;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: ${({ margin }) => (margin ? margin : "23px")}};
  text-align:center;
`;

const StyledMainHeading = styled.h1`
  color: #0c2945;
  font-size: 36px;
  margin-bottom: 13px;
  font-weight: 700;
  font-family: Roboto Condensed;
`;

const StyledContainer = styled.div`
  margin: 0px 244px;
  display: flex;
  flex-direction: column;
  gap: 23px;
  @media (max-width: 768px) {
    margin: 0px;
  }
`;

const StyledInfoContainer = styled.div`
  border: 1px solid #c2ccd9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => (padding ? padding : "32px 34px 27px 34px")};
  height: 100%;
`;

const StyledFeatureContainer = styled.div`
  border: 1px solid #c2ccd9;
  border-radius: 8px;
  padding: 32px 61px 42px 61px;
`;

const StyledFeatureTopicsContainer = styled.div`
display: flex;
gap: 90px;
flex-wrap: wrap;
justify-content:space-between;
`

const baseDocsUrl = `https://docs.seewithin.co/docs/`;

function Support() {
  const user = useSelector((state) => state.auth.user);
  const ssoToken = useSelector((state)=> state.radAdmin.ssoToken.token)
  const dispatch = useDispatch()


  const handleButtonClick = () => {
    window.analytics.track("Support-Docs-Click");
    window.open(`${baseDocsUrl}getting-started`, "_blank");
  };

  const handleEmailButtonClick = () => {
    const email = "mailto:support@seewithin.co";
    window.analytics.track("Support-Email-Click");

    const mailtoLink = `${email}`;
    window.location.href = mailtoLink;
  };

  useEffect(()=>{
   dispatch(getSSOToken())
  },[])


  return (
    <>
      {user && <CannyIdentification user={user} />}
      <Box p={"20px 50px"}>
        <Row>
          <Col md={12} lg={12} xl={10}>
            <StyledMainHeading>Support</StyledMainHeading>
          </Col>
        </Row>
        <StyledContainer>
          <Row>
            <Col>
              <StyledInfoContainer>
                <DocsIcon style={{ marginBottom: "15px" }} />
                <StyledSectionHeader>Help Center Docs</StyledSectionHeader>
                <StyledDescription>
                  Comprehensive guides and documentation to help you start
                  working with Within Health as quickly as possible, as well as
                  support if you get stuck.
                </StyledDescription>
                <StyledButton onClick={handleButtonClick}>View</StyledButton>
              </StyledInfoContainer>
            </Col>
            <Col>
              <StyledInfoContainer>
                <EmailIcon style={{ marginBottom: "15px" }} />
                <StyledSectionHeader >Contact us</StyledSectionHeader>
                <StyledDescription  style={{display:'flex', flex:1}}>support@seewithin.co</StyledDescription>
                <StyledButton onClick={handleEmailButtonClick}>
                  Create Email
                </StyledButton>
              </StyledInfoContainer>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <StyledFeatureContainer>
                <div style={{ marginBottom: "25px" }}>
                  <StyledSectionHeader>Featured Topics</StyledSectionHeader>
                </div>
                <StyledFeatureTopicsContainer>
                  <div>
                    <div style={{ marginBottom: "21px" }}>
                      <StyledSubHeading>DOCUMENTATION</StyledSubHeading>
                      <StyledLink
                        to={{ pathname: `${baseDocsUrl}getting-started` }}
                        target="_blank"
                        onClick={()=> window.analytics.track("Support-Featured-Topics-Getting-Started-Click")}
                      >
                        Getting Started with RadNav
                      </StyledLink>
                    </div>
                    <div>
                      <StyledSubHeading>
                        CONNECTIVITY & SECURITY
                      </StyledSubHeading>
                      <StyledLink
                        to={{
                          pathname: `${baseDocsUrl}connecting-to-within-health`,
                        }}
                        target="_blank"
                        onClick={()=> window.analytics.track("Support-Featured-Topics-Connecting-To-Within-Health-Click")}
                      >
                        Connecting to RadNav
                      </StyledLink>
                      <StyledLink
                        to={{ pathname: `${baseDocsUrl}security-overview` }}
                        target="_blank"
                        onClick={()=> window.analytics.track("Support-Featured-Topics-Security-Overview-Click")}
                      >
                        Security and Privacy
                      </StyledLink>
                    </div>
                  </div>
                  <div>
                    <StyledSubHeading>ONBOARDING</StyledSubHeading>
                    <StyledLink
                      to={{ pathname: `${baseDocsUrl}welcome` }}
                      target="_blank"
                      onClick={()=> window.analytics.track("Support-Featured-Topics-Welcome-Click")}
                    >
                      Welcome!
                    </StyledLink>
                    <StyledLink
                      to={{
                        pathname: `${baseDocsUrl}reset-password-and-login`,
                      }}
                      target="_blank"
                      onClick={()=> window.analytics.track("Support-Featured-Topics-Reset-Password-And-Login-Click")}
                    >
                      Reset Password and Login
                    </StyledLink>
                    <StyledLink
                      to={{ pathname: `${baseDocsUrl}csv-upload` }}
                      target="_blank"
                      onClick={()=> window.analytics.track("Support-Featured-Topics-Csv-Upload-Click")}
                    >
                      Historical CSV Upload
                    </StyledLink>
                  </div>
                  <div>
                    <StyledSubHeading>PRODUCT GUIDES</StyledSubHeading>
                    <StyledLink
                      to={{ pathname: `${baseDocsUrl}the-follow-ups-list` }}
                      target="_blank"
                      onClick={()=> window.analytics.track("Support-Featured-Topics-The-Follow-Ups-List-Click")}
                    >
                      The Follow-Ups List
                    </StyledLink>
                    <StyledLink
                      to={{ pathname: `${baseDocsUrl}engagement` }}
                      target="_blank"
                      onClick={()=> window.analytics.track("Support-Featured-Topics-Engagement-Click")}
                    >
                      Messaging
                    </StyledLink>
                    <StyledLink
                      to={{ pathname: `${baseDocsUrl}notes-analytics` }}
                      target="_blank"
                      onClick={()=> window.analytics.track("Support-Featured-Topics-Notes-Analytics-Click")}
                    >
                      Analytics
                    </StyledLink>
                    <StyledLink
                      to={{ pathname: `${baseDocsUrl}analytics` }}
                      target="_blank"
                      onClick={()=> window.analytics.track("Support-Featured-Topics-Analytics-Click")}
                    >
                      Other Metrics
                    </StyledLink>
                  </div>
                </StyledFeatureTopicsContainer>
              </StyledFeatureContainer>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <StyledFeatureContainer>
                <StyledSectionHeader>Feature Requests</StyledSectionHeader>
                 {
                    ssoToken &&  <Feedback ssoToken={ssoToken}  />
                 } 
              </StyledFeatureContainer>
            </Col>
          </Row>
        </StyledContainer>
      </Box>
    </>
  );
}

export default Support;
