import React, { useCallback, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';

import { Button } from './Common';

const Container = styled.div`
  height: 250px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #c2ccd9;
  background: #ffffff;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    height: unset;
    flex-direction: column;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 50px;

  @media (max-width: 768px) {
    padding: 25px;
  }
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Field = styled.div`
  flex: 1 50%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  label {
    flex: 1;
    margin: 0 10px 0 0;
    color: #7e7e7e;
  }

  span {
    flex: 2;
    color: #000000;
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;

  ${Button} {
    margin-left: 30px;
    min-width: 180px;
  }

  ${Button}:first-child {
    margin-left: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    ${Button} {
      width: 100%;
      margin: 0 0 10px 0 !important;
    }
  }
`;

const Appointment = ({ appointment, onEdit, onViewResults, onViewPrescription }) => {
  const [loadingPrescription, setLoadingPrescription] = useState(false);

  const onClickViewPrescription = useCallback(async () => {
    setLoadingPrescription(true);
    await onViewPrescription(appointment);
    setLoadingPrescription(false);
  }, [appointment, onViewPrescription]);

  return (
    <Container>
      <img src={appointment.location.provider.logo} alt={appointment.location.provider.name} />
      <Content>
        <FieldGroup>
          <Field>
            <label children='Appointment Date' />
            <span children={moment(appointment.date_time).format('MM/DD/YYYY hh:mm A')} />
          </Field>
          <Field>
            <label children='Location' />
            <span children={appointment.location.address} />
          </Field>
          <Field>
            <label children='Type of Scan' />
            <span children={appointment.scan.name} />
          </Field>
          <Field />
        </FieldGroup>
        <ButtonGroup>
          {onEdit && <Button onClick={() => onEdit(appointment)} mode='ghost' children='Edit' />}
          {onViewResults && <Button onClick={() => onViewResults(appointment)} mode='ghost' children='View results' />}
          {onViewPrescription && (
            <Button onClick={onClickViewPrescription} disabled={loadingPrescription} mode='secondary'>
              {loadingPrescription && (
                <Spinner style={{ marginRight: '10px' }} as='span' animation='border' size='sm' />
              )}
              <span children='View prescription' />
            </Button>
          )}
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default Appointment;
