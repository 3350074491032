import React, { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { Section, Button, HFlex, VFlex } from './Common';

const Warning = styled.div`
  margin-bottom: 25px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: #8692a4;
  text-transform: uppercase;
`;

const FieldContainer = styled.div`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FieldLabel = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #3e3f41;
  opacity: 0.4;
`;

const FieldValue = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: ${({ small }) => (small ? '14px' : '20px')};
  line-height: ${({ small }) => (small ? '18px' : '20px')};
  color: #3e3f41;
`;

export const Separator = styled.div`
  margin: 10px 0;
`;

export const Field = styled(({ className, label, value, small }) => {
  return (
    <FieldContainer className={className}>
      <FieldLabel children={label} />
      <FieldValue children={value} small={small} />
    </FieldContainer>
  );
})``;

const Information = () => {
  const history = useHistory();
  const { id } = useParams();

  const isNotesSubmitted = useSelector(
    (state) =>
      state.patientProfile.healthHistory?.medical_conditions.length &&
      state.patientProfile.appointments.prescan?.symptoms.length
  );

  const patient = useSelector((state) => ({
    fname: state.patientProfile.user?.first_name,
    lname: state.patientProfile.user?.last_name,
    address: `${state.patientProfile.contactInfo?.address1} ${state.patientProfile.contactInfo?.city}, ${state.patientProfile.contactInfo?.state} ${state.patientProfile.contactInfo?.zip_code}`,
    phone: state.patientProfile.contactInfo?.phone_number,
    dob: state.patientProfile.healthHistory?.date_of_birth,
    gender: state.patientProfile.healthHistory?.gender,
    conditions: state.patientProfile.healthHistory?.medical_conditions,
    allergies: state.patientProfile.healthHistory?.allergies,
    metal: state.patientProfile.healthHistory?.metal_present_in_body,
    symptoms: state.patientProfile.appointments.prescan?.symptoms
  }));

  const mapConditions = useSelector((state) =>
    state.constants.medicalConditions.reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {})
  );

  const mapSymptoms = useSelector((state) =>
    state.constants.symptoms.reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {})
  );

  const mapGender = { 'F': 'Female', 'M': 'Male' };

  const onClickNotes = useCallback(() => {
    history.push(`/auth/patients/${id}/notes`);
  }, [history, id]);

  return (
    <Section title='patient information'>
      <HFlex height='100%'>
        <VFlex mr='20px' flex='1'>
          <Field label='first name' value={patient.fname} />
          <Field label='last name' value={patient.lname} />
          <Separator />
          <Field label='phone number' value={patient.phone} small />
          <Field label='address' value={patient.address} small />
        </VFlex>
        {isNotesSubmitted ? (
          <VFlex flex='2'>
            <Field label='date of birth' value={patient.dob && moment(patient.dob).format('MM.DD.YYYY')} small />
            <Field label='gender' value={mapGender[patient.gender]} small />
            <Field
              label='medical conditions'
              value={patient.conditions.map((i) => mapConditions[i]).join(', ')}
              small
            />
            <Field label='medication allergies' value={patient.allergies} small />
            <Field label='metal in body' value={patient.metal ? 'Yes' : 'None'} small />
            <Field label='Current symptoms' value={patient.symptoms.map((i) => mapSymptoms[i]).join(', ')} small />
          </VFlex>
        ) : (
          <VFlex flex='2' justifyContent='center' alignItems='center'>
            <Warning children='NO MEDICAL HISTORY YET' />
            <Button onClick={onClickNotes} children='Add Telehealth Notes' />
          </VFlex>
        )}
      </HFlex>
    </Section>
  );
};

export default Information;
