import React from 'react';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';
import { FormLabel, Input } from '../../Design/form';

const FormGroupHolder = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

function capitalizeEach(sentences) {
  return sentences
    .split('.')
    .map((sentence) => capitalize(sentence.trim()))
    .join('. ')
    .trim();
}

export default function FormGroup({
  type,
  input,
  placeholder,
  label,
  meta: { error, touched },
  apiErrors,
}) {
  return (
    <FormGroupHolder>
      <FormLabel>{label}</FormLabel>
      <Input
        placeholder={placeholder}
        type={type}
        error={touched && error}
        {...input}
      />
      <div className="error">{touched ? error : ''}</div>

      {apiErrors && apiErrors[input.name].length > 0 && (
        <div className="error">
          {apiErrors[input.name].map((err) => (
            <div key={err}>{capitalizeEach(err)}</div>
          ))}
        </div>
      )}
    </FormGroupHolder>
  );
}
