import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import { Card, PageTitle } from '../../../styles';
import { Button } from '@material-ui/core';
import Table from '../../../components/Table/Table';
import LoadMoreButton from '../../../components/LoadMoreButton';
import { EmptyState } from '../../../components/EmptyState/EmptyState';
import { ReactComponent as EmptyStateImg } from '../../../assets/empty-state-search.svg';
import Box from '../../../components/_styles/Box';
import {getCohorts, deleteCohort, setDimensionsToInitial} from '../../../actions/radminUploads';
import {HeaderContainer} from './ViewPatients';
import {TemplateButton} from '../Messages/SmsCreationDialog';
import {InfoButtonIcon} from '../FollowUpList/Icons';
import DoubleDataCell from '../PatientList/LastScanCell';
import { makeStyles } from '@material-ui/core/styles';
import RemoveConfirmation from './RemoveConfirmation';
import SearchField from '../SharedComponents/SearchField'
import { isEmpty } from 'lodash';
import styled from 'styled-components'
import { ReactComponent as SearchIconCustom } from "../../../assets/searchIconGrey.svg";

import { showAlert } from "../../../actions/radminUploads";


const useStyles = makeStyles({
    label: {
        padding: "5px 0px",
        fontSize: "12px",
        fontWeight: "bold"
    },
    campaignButton: {
        backgroundColor: "#00AEEF",
        textTransform: 'none',
        width: '8rem',
        height:'38px',
        color:'#FFFFFF',
        border:'2px solid #00AEEF',
        '&:hover':{
            backgroundColor:'transparent',
            color:'#00AEEF'
        },
    },
})

const SearchFieldContainer = styled.div`
    display: flex;
    flex: auto;
    justify-content: right;
    height:38px;
    margin-right:17px;
`

const columns = [
    { key: 'cohort_title', name: 'Name', width: "20%" },
    // { key: 'count', name: 'Count', width: "15%" }, TODO: Use when count added from BE
    { key: 'description', name: 'Description', align:"left"},
];

function CohortList() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    
    const cohortsList = useSelector(state => state.radAdmin.cohorts?.data)
    const isLoading = useSelector(state => state.radAdmin.cohorts.isLoading)
    const totalItems = useSelector(state => state.radAdmin.cohorts.totalItems)

    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [isLoadingDeleteCohort, setIsLoadingDeleteCohort] = useState(false)
    const [page, setPage] = useState(1);
    const [titleForSearch, setTitleForSearch] = useState('')
    const permission = useSelector((state) => state.auth.user.permission_level);
    const rolePermissions = useSelector(
    (state) =>state.auth.user.permissions
    );
    const createPermission = rolePermissions?.find(itm=>itm.name ==="Cohorts")?.permissions.find(itm=>itm.codename === "add_cohorts")?.is_authorized

    useEffect(() => {
            const query = new URLSearchParams(history.location.search);
            let title = query.get('title')
            if(title){
                dispatch(getCohorts(page, title));
            }else{
                dispatch(getCohorts(page, titleForSearch));
            }
        dispatch(setDimensionsToInitial())
    }, [])

    useEffect(() => {
        const query = new URLSearchParams(history.location.search);
        let title = query.get('title')
        if(title){
            setTitleForSearch(title)
            dispatch(getCohorts(null, title));
        }
    }, [])

    const viewPatientHandler = (data) => {
        window.analytics.track('Cohort-Patient-View-Button-Click');
        history.push({
            pathname: `/auth/radAdmin/messages/cohorts/patientView/${data.id}`,
            state: {data}
        })
    }

    const dialogHandler = (data) => {
        setRemoveDialogOpen(!removeDialogOpen);
        setSelectedId(data.id);
        window.analytics.track('Cohort-Delete-Button-Click');
    }

    const createCohortHandler = () => {
        if((permission && createPermission) || !permission){
            history.push('/auth/radAdmin/messages/cohorts/create');
            window.analytics.track('Cohort-Create-Button-Click');
          }else{
            dispatch(
              showAlert('danger', 'Error', `You don't have permission to create cohorts`)
            );
          }
    }

    const onLoadMore = () => {
        dispatch(getCohorts(page + 1, titleForSearch));
        setPage(page + 1)
    }

    const deleteCohortHandler = () => {
        setIsLoadingDeleteCohort(true)
        dispatch(deleteCohort(selectedId))
        .then(() => {
            setIsLoadingDeleteCohort(false)
            setRemoveDialogOpen(false);
        })
    }
    
    return (
        <Box p={30}>
            <RemoveConfirmation 
                content="Are you sure you want to remove this cohort?"
                openDialog={removeDialogOpen}
                dialogHandler={dialogHandler}
                isLoading={isLoadingDeleteCohort}
                deleteHandler={deleteCohortHandler}
                heading={'Remove Cohort'}
                removeHeader={'Yes, Remove Cohort'}
            />
            <HeaderContainer>
            <PageTitle>Cohorts</PageTitle>
            <SearchFieldContainer>
            <SearchField 
                placeholder="Search Cohort Title"
                value={titleForSearch}
                onChangeHandle={(e) => {
                    setTitleForSearch(e.target.value)
                    history.push({
                        search: `?title=${e.target.value}`
                      })
                }}
                onReset={() => {
                    if(titleForSearch){
                        dispatch(getCohorts(1, ''))
                        setPage(1)
                        setTitleForSearch('')
                        history.push({
                            search: `?title=`
                          })
                    }
                }}
                onSearchEnter={() => {
                    setPage(1)
                    dispatch(getCohorts(null, titleForSearch))
                } 
            }
            styles={{
                marginRight: 0,
                marginBottom: 0,
                width: "318px",
                position: "relative",
              }}
              placeholderStyles={{
                color: "#828282",
                fontWeight: 400,
                fontSize: "16px",
                fontFamily: "Open Sans",
                marginLeft: "32px",
              }}
              inputStyles={{
                background: "#FFFFFF",
                border: "1px solid #D2D3D7",
                textAlign: "left",
                paddingLeft: "20px",
                height:'38px'
              }}
              searchIconStyles={{
                marginLeft: "12px",
              }}
              clearIconStyle={{
                right: 20,
                marginLeft: 0,
              }}
              SearchIconCustom={<SearchIconCustom />}
            />
            </SearchFieldContainer>
            
            <div>
            <Button
                classes={{ label: classes.label, root: classes.campaignButton }}
                onClick={createCohortHandler}
            >
                Create Cohort
            </Button>
            </div>
            </HeaderContainer>
            <Card>
                <Table
                    rows={cohortsList && cohortsList}
                    columns={columns.slice(0,2).concat(
                        { key: '1', name: '', displayCell: (cellValue, rowData) => <TemplateButton clickHandler={() => viewPatientHandler(rowData)} color="#00AEEF" title="View Patients" width icon={<InfoButtonIcon />}/>, width:"20px" },
                        { 
                            key: '2',
                            name: '',
                            displayCell: (cellValue, rowData) =>  <DoubleDataCell mainInfo={'edit'}
                            EditRecoHandler={() =>{ 
                                if((permission && rolePermissions.find(itm=>itm.name ==="Cohorts")?.permissions.find(itm=>itm.codename === "change_cohorts")?.is_authorized) || !permission){
                                history.push('/auth/radAdmin/messages/cohorts/create', {data: rowData})
                                window.analytics.track('Cohort-Edit-Button-Click');
                                  }else{
                                    dispatch(
                                      showAlert('danger', 'Error', `You don't have permission to update cohort`)
                                    );
                                  }   
                            }}
                            RemoveRecoHandler={(() =>{
                                if((permission && rolePermissions.find(itm=>itm.name ==="Cohorts")?.permissions.find(itm=>itm.codename === "delete_cohorts")?.is_authorized) || !permission){
                                    dialogHandler(rowData)
                                  }else{
                                    dispatch(
                                      showAlert('danger', 'Error', `You don't have permission to delete cohort`)
                                    );
                                  }
                            } 
                           )}/>,
                            width:"20px"
                        }
                    )}
                    isLoadingRows={isLoading && (cohortsList.length === 0 || cohortsList.length === 1)}
                    getRowsAction={(pageNumber) => getCohorts(pageNumber, titleForSearch)}
                    noDataRender={() => (
                        <EmptyState title={'No Results'} img={() => <EmptyStateImg />}>
                          Sorry, there are no results
                        </EmptyState>
                      )}
                />
                 {cohortsList && cohortsList.length !== 0 && (
                    <LoadMoreButton
                        title="Load more"
                        isLoading={isLoading && totalItems !== cohortsList.length}
                        handleClick={onLoadMore}
                        isDisabled={totalItems === cohortsList.length}
                    />
                 )} 
            </Card>
        </Box>
    )
}

export default CohortList
