import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as ChevronRightIcon } from '../../../assets/chevron_right.svg';
import { Nav } from 'react-bootstrap';
import CustomTimeline from './CustomTimeline';
import { getRecoHistoryAction } from '../../../actions/radminUploads';

const StyledChevron = styled(ChevronRightIcon)`
  transform: rotate(${props => props.showdropdown&&'-'}90deg);
  & > path {
    fill: ${props => props.chevronFillColor? props.chevronFillColor: '#9FA2B4'} ;
  }
  &:hover {
    cursor: ${props => props.isDetails? 'pointer' : 'default'};
  }
  &:hover > path {
    fill: ${props => props.isDetails&&'green'};
  }
`

const StyledLink = styled(Nav.Link)`
  padding: 0;
  &:hover {
    cursor: ${props => props.isDetails? 'pointer' : 'default'};
  }
`

const Container = styled.div`
  position: absolute;
  height: 310px;
  width: 1365px;
  margin-top: 26px;
  background-color: #FFFFFF;
  z-index: 2;
  left: 0px;
  @media (max-width: 768px) {
    left: 0;
    margin-left: 31px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #FFD57C;
`

const Header = styled.p`
  margin: 0;
  padding: 0;
  margin-left: 43px;
  background-color: #FFD57C;
  text-transform: uppercase;
`

const TimelineDetails = ({row, width, setCanChangeAction, chevronFillColor='',activeRow}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { id, rangeStart, rangeEnd, reportDetails, status, accessionNumber, initialExamDate } = row;
  const dispatch = useDispatch();


  useEffect(()=>{
    if( activeRow?.id === row.id){
      if (id) {
        dispatch(getRecoHistoryAction(id, row));
        setShowDropdown(!showDropdown);
      }
    }else{
      setShowDropdown(false)
    }

  },[activeRow])

  return (
    <>
      <StyledLink  isdetails={reportDetails ? 1 : undefined}>
        <StyledChevron chevronFillColor={chevronFillColor} showdropdown={showDropdown ? 1 : undefined} isdetails={rangeStart && rangeEnd ?  1 : undefined} />
      </StyledLink>
      {
        showDropdown && <Container style={{width: width}}>
          <HeaderContainer>
            <Header>RECOMMENDATION TIMELINE DETAILS</Header>
          </HeaderContainer>
          <CustomTimeline
            start={rangeStart}
            end={rangeEnd}
            status={status}
            accessionNumber={accessionNumber}
            initialExamDate={initialExamDate} 
          />
        </Container>
      }
    </>
  );
}

export default TimelineDetails;
