import { uniq } from 'lodash';

import API from '../../libs/api';
import { getPrescription } from '../patientProfile';

const scoped = (name) => `DOCTOR/${name}`;

export const GET_ASSIGNED_APPOINTMENTS_FETCH = scoped('GET_ASSIGNED_APPOINTMENTS_FETCH');
export const GET_ASSIGNED_APPOINTMENTS_RESOLVE = scoped('GET_ASSIGNED_APPOINTMENTS_RESOLVE');
export const GET_ASSIGNED_APPOINTMENTS_REJECT = scoped('GET_ASSIGNED_APPOINTMENTS_REJECT');

export const GET_UNASSIGNED_APPOINTMENTS_FETCH = scoped('GET_UNASSIGNED_APPOINTMENTS_FETCH');
export const GET_UNASSIGNED_APPOINTMENTS_RESOLVE = scoped('GET_UNASSIGNED_APPOINTMENTS_RESOLVE');
export const GET_UNASSIGNED_APPOINTMENTS_REJECT = scoped('GET_UNASSIGNED_APPOINTMENTS_REJECT');

export const ASSIGN_APPOINTMENT_FETCH = scoped('ASSIGN_APPOINTMENT_FETCH');
export const ASSIGN_APPOINTMENT_RESOLVE = scoped('ASSIGN_APPOINTMENT_RESOLVE');
export const ASSIGN_APPOINTMENT_REJECT = scoped('ASSIGN_APPOINTMENT_REJECT');

export const UNASSIGN_APPOINTMENT_FETCH = scoped('UNASSIGN_APPOINTMENT_FETCH');
export const UNASSIGN_APPOINTMENT_RESOLVE = scoped('UNASSIGN_APPOINTMENT_RESOLVE');
export const UNASSIGN_APPOINTMENT_REJECT = scoped('UNASSIGN_APPOINTMENT_REJECT');

export const WRITE_PRESCRIPTION_FETCH = scoped('WRITE_PRESCRIPTION_FETCH');
export const WRITE_PRESCRIPTION_RESOLVE = scoped('WRITE_PRESCRIPTION_RESOLVE');
export const WRITE_PRESCRIPTION_REJECT = scoped('WRITE_PRESCRIPTION_REJECT');

export const SHOW_ALERT = scoped('SHOW_ALERT');
export const HIDE_ALERT = scoped('HIDE_ALERT');

export const SET_LAST_PAGE = scoped('SET_LAST_PAGE');

export const RESET_STATE = scoped('RESET_STATE');

export const resetState = () => ({ type: RESET_STATE });

export const setLastPage = (type, page) => ({ type: SET_LAST_PAGE, payload: { type, page } });

export const showAlert = (type, headline, content, timeout = 5000) => ({
  type: SHOW_ALERT,
  payload: { type, headline, timeout, children: content, active: true }
});

export const hideAlert = () => ({ type: HIDE_ALERT });

export const getAssignedAppointments = (userId, page = 1) => async (dispatch) => {
  try {
    dispatch({ type: GET_ASSIGNED_APPOINTMENTS_FETCH });

    const resAppointments = await API.getRequest(
      `/api/v1/appointment/?appointment_type=pre-scan&scan_purchase__purchase_status=Succeeded&doctor=${userId}&page=${page}`
    );

    const patients = uniq(resAppointments.data.results.map((r) => r.patient));
    const resPatients = await Promise.all(patients.map((id) => API.getRequest(`/api/v1/user/${id}`)));
    const mapPatients = resPatients.reduce((acc, r) => ({ ...acc, [r.data.id]: r.data }), {});
    resAppointments.data.results.forEach((r) => (r.patient = mapPatients[r.patient]));

    dispatch({ type: GET_ASSIGNED_APPOINTMENTS_RESOLVE, payload: resAppointments.data });

    return resAppointments.data;
  } catch (error) {
    console.error(error);
    dispatch({ type: GET_ASSIGNED_APPOINTMENTS_REJECT });
  }
};

export const getAssignedAppointmentsNextPage = (userId) => async (dispatch, getState) => {
  const hasNextPage = getState().doctor.assignedAppointments.hasNextPage;

  if (hasNextPage) {
    const nextPage = getState().doctor.assignedAppointments.lastPage + 1;

    const data = await dispatch(getAssignedAppointments(userId, nextPage));
    if (data?.results) dispatch(setLastPage('assignedAppointments', nextPage));
  }
};

export const getUnassignedAppointments = (page = 1) => async (dispatch) => {
  try {
    dispatch({ type: GET_UNASSIGNED_APPOINTMENTS_FETCH });

    const resAppointments = await API.getRequest(
      `/api/v1/appointment/?appointment_type=pre-scan&scan_purchase__purchase_status=Succeeded&doctor__isnull=true&page=${page}`
    );

    const patients = uniq(resAppointments.data.results.map((r) => r.patient));
    const resPatients = await Promise.all(patients.map((id) => API.getRequest(`/api/v1/user/${id}`)));
    const mapPatients = resPatients.reduce((acc, r) => ({ ...acc, [r.data.id]: r.data }), {});
    resAppointments.data.results.forEach((r) => (r.patient = mapPatients[r.patient]));

    dispatch({ type: GET_UNASSIGNED_APPOINTMENTS_RESOLVE, payload: resAppointments.data });

    return resAppointments.data;
  } catch (error) {
    console.error(error);
    dispatch({ type: GET_UNASSIGNED_APPOINTMENTS_REJECT });
  }
};

export const getUnassignedAppointmentsNextPage = () => async (dispatch, getState) => {
  const hasNextPage = getState().doctor.unassignedAppointments.hasNextPage;

  if (hasNextPage) {
    const nextPage = getState().doctor.unassignedAppointments.lastPage + 1;

    const data = await dispatch(getUnassignedAppointments(nextPage));
    if (data?.results) dispatch(setLastPage('unassignedAppointments', nextPage));
  }
};

export const assignAppointment = (scanPurchaseId, userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: ASSIGN_APPOINTMENT_FETCH });

    const resAppointments = await API.getRequest(`/api/v1/appointment/?scan_purchase=${scanPurchaseId}`);

    await Promise.all(
      resAppointments.data.results.map((a) =>
        API.putRequest(`/api/v1/appointment/${a.id}/`, {
          id: a.id,
          symptoms: a.symptoms,
          date_time: a.date_time,
          doctor: userId
        })
      )
    );

    const appointment = getState().doctor.unassignedAppointments.items.find((a) => a.scan_purchase === scanPurchaseId);
    appointment.doctor = userId;

    dispatch({ type: ASSIGN_APPOINTMENT_RESOLVE, payload: appointment });
    dispatch(showAlert('success', 'Success', 'Appointment assigned'));
  } catch (error) {
    console.error(error);
    dispatch({ type: ASSIGN_APPOINTMENT_REJECT });
    dispatch(showAlert('danger', 'Error', 'There was an error processing your request'));
  }
};

export const unassignAppointment = (scanPurchaseId) => async (dispatch, getState) => {
  try {
    dispatch({ type: UNASSIGN_APPOINTMENT_FETCH });

    const resAppointments = await API.getRequest(`/api/v1/appointment/?scan_purchase=${scanPurchaseId}`);

    await Promise.all(
      resAppointments.data.results.map((a) =>
        API.putRequest(`/api/v1/appointment/${a.id}/`, {
          id: a.id,
          symptoms: a.symptoms,
          date_time: a.date_time,
          doctor: null
        })
      )
    );

    const appointment = getState().doctor.assignedAppointments.items.find((a) => a.scan_purchase === scanPurchaseId);
    appointment.doctor = null;

    dispatch({ type: UNASSIGN_APPOINTMENT_RESOLVE, payload: appointment });
    dispatch(showAlert('success', 'Success', 'Appointment unassigned'));
  } catch (error) {
    console.error(error);
    dispatch({ type: UNASSIGN_APPOINTMENT_REJECT });
    dispatch(showAlert('danger', 'Error', 'There was an error processing your request'));
  }
};

export const writePrescription = (appointmentId) => async (dispatch) => {
  try {
    dispatch({ type: WRITE_PRESCRIPTION_FETCH });

    const resPrescription = await API.postRequest(`/api/v1/prescription/`, { appointment: appointmentId });
    dispatch(getPrescription(resPrescription.data.id));

    dispatch({ type: WRITE_PRESCRIPTION_RESOLVE });
  } catch (error) {
    console.error(error);
    dispatch({ type: WRITE_PRESCRIPTION_REJECT });
  }
};
