import React, { useReducer } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';

import { AccountContentContainer, HeadlineCTA } from './common';
import { Form, FormRow } from '../Design/form';
import Button from '../UI/Button/Button';
import FormGroup from './common/FormGroup';
import Box from '../_styles/Box';
import {
  resetPasswordConfirm,
  resetPasswordReducer,
  resetPasswordInitialState,
} from './reducers/resetPasswordReducer';

import * as authActions from '../../actions/auth/actions';

function ResetPassword({
  handleSubmit,
  logout,
  history,
  isLoggedIn,
  ...props
}) {
  const [state, dispatch] = useReducer(
    resetPasswordReducer,
    resetPasswordInitialState
  );

  const onFormSubmit = ({ password }) => {
    const { uid, token } = props.match.params;

    resetPasswordConfirm({
      uid,
      token,
      password,
      history,
      dispatch,
      isLoggedIn,
      logout,
    });
  };

  return (
    <AccountContentContainer>
      <HeadlineCTA
        headlineText="Reset Password"
        subtext="Please enter your new password. Changing your password will log you out of all browsers."
      />

      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <FormRow>
          <Field
            component={FormGroup}
            type="password"
            label="Your password"
            name="password"
            placeholder="Enter your password here."
          />
        </FormRow>
        {state.hasError && state.errors.length > 0 && (
          <div className="error">
            {state.errors.map((error) => (
              <div key={error}>{error}</div>
            ))}
          </div>
        )}
        <FormRow>
          <Field
            component={FormGroup}
            type="password"
            label="Confirm your password"
            name="confirmPassword"
            placeholder="Confirm password here"
          />
        </FormRow>

        <FormRow>
          <Button type="submit" primary block loading={state.isSubmitting}>
            {state.hasSubmitted ? 'Done!' : 'Submit'}
          </Button>
        </FormRow>
      </Form>

      {state.hasError && (
        <Box mt={30}>
          <Alert variant="danger">
            Something went wrong. Please try again later.
          </Alert>
        </Box>
      )}
    </AccountContentContainer>
  );
}

function validate({ password, confirmPassword }) {
  const errors = {};

  if (password !== confirmPassword) {
    errors.confirmPassword = "Passwords don't match";
  }

  return errors;
}

const form = reduxForm({
  form: 'reset-password',
  fields: ['password', 'confirmPassword'],
  validate,
})(ResetPassword);

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;

  return {
    isLoggedIn,
  };
}

export default connect(mapStateToProps, authActions)(form);
