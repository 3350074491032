import React from 'react';
import styled from 'styled-components';
import { space, layout, flexbox } from 'styled-system';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 450px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #c2ccd9;
  background: #ffffff;
`;

const SectionTitle = styled.div`
  margin-bottom: 20px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #8692a4;
  text-transform: uppercase;
`;

const SectionContent = styled.div`
  flex: 1;
`;

export const HFlex = styled.div`
  display: flex;
  flex-direction: row;

  ${space}
  ${layout}
  ${flexbox}
`;

export const VFlex = styled.div`
  display: flex;
  flex-direction: column;

  ${space}
  ${layout}
  ${flexbox}
`;

export const Button = styled.button.attrs(({ type }) => ({
  type: type ?? 'button'
}))`
  padding: 15px 50px;
  border-radius: 5px;
  border: none;
  outline: none !important;
  background: #00AEEF;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #f9fafc;

  &:disabled {
    border: 1px solid #d2d3d7;
    background: #f9fafc;
    color: #d2d3d7;
  }
`;

export const Section = styled(({ className, title, children }) => {
  return (
    <SectionContainer className={className}>
      <SectionTitle children={title} />
      <SectionContent children={children} />
    </SectionContainer>
  );
})``;

export const StyledTable = styled.div`
  display: table;
  width: 100%;
`;

export const StyledTableHeading = styled.div`
  display: table-header-group;
  color: #89909c;
  font-size: 0.7rem;
  line-height: 0.75rem;
`;

export const StyledTableRow = styled.div`
  display: table-row;
  ${(props) => props.isSelected && 'background: #f9fafc'}
  cursor: pointer;
  font-size: 0.7rem;
  line-height: 0.75rem;
`;

export const StyledTableBody = styled.div`
  display: table-row-group;
  padding: 20px 0 20px 0;
`;

export const StyledTableFoot = styled.div`
  display: table-footer-group;
`;

export const StyledTableCell = styled.div`
  display: table-cell;
  padding: 5px;
`;

export const StyledTableHead = styled.div`
  display: table-cell;
`;

export const HeaderWrapperLeft = styled.div`
  border-radius: 10px 0px 0px 10px;
  background-color: #e6e9f1;
  padding: 5px;
`;

export const HeaderWrapperRight = styled.div`
  border-radius: 0px 10px 10px 0px;
  background-color: #e6e9f1;
  padding: 5px;
`;

export const HeaderWrapperCenter = styled.div`
  background-color: #e6e9f1;
  padding: 5px;
`;
