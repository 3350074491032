import { generateId } from './generateSequenceId';

const sequences = {
  transition: {
    type: 'transition',
    label: '',
    tsText: '',
  },
  timeDelay: {
    type: 'timeDelay',
    text: 'Time Delay',
    stepLabel: '',
    btn: '',
    isStep: true,
  },
  removePatient: {
    type: 'remove_patient',
    text: 'Remove Patient',
    stepLabel: '',
    btn: 'red',
  },
  email: {
    type: 'email',
    text: 'Email 1',
    stepLabel: '',
    btn: 'green',
    isStep: true,
  },
  sms: {
    type: 'sms',
    text: 'SMS text 1',
    stepLabel: '',
    btn: 'green',
    isStep: true,
  },
};

export const generateNewSequence = ({ type, ...rest }) => {
  return {
    id: generateId(),
    ...sequences[type],
    ...rest,
  };
};

export const demoSequences = [
  generateNewSequence({ type: 'transition' }),
  generateNewSequence({ type: 'email' }),
  generateNewSequence({ type: 'transition' }),
  generateNewSequence({ type: 'sms' }),
  generateNewSequence({ type: 'transition', tsText: '2 days' }),
  generateNewSequence({ type: 'email', text: 'Email 2', btn: 'blue' }),
  generateNewSequence({ type: 'transition', tsText: '1 week' }),
  generateNewSequence({ type: 'sms', btn: 'yellow' }),
  generateNewSequence({ type: 'transition', tsText: '2 days' }),
  generateNewSequence({ type: 'sms', text: 'SMS Text 2', btn: 'yellow' }),
  generateNewSequence({ type: 'transition' }),
  generateNewSequence({ type: 'removePatient' }),
];

export const defaultSequences = [
  generateNewSequence({ type: 'transition' }),
  generateNewSequence({ type: 'removePatient' }),
];
