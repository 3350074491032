import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import { Header, CheckoutNavigationButton, CheckoutNavigationHolder, SchedulingUIWrapper } from '../common';
import { incrementCheckoutStep, setDateTime, setCurrentStep } from '../../../actions/checkout';
import SchedulingRightPanel from '../SchedulingStatusRightPanel';
import PhoneCall from '../../../assets/checkout/phone-call.svg';
import Calendar from '../SelectDateTime/Calendar';

const SelectTime = () => {
  const dispatch = useDispatch();
  const activeStep = useSelector((state) => state.checkout.activeStep);

  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  const onCalendarChange = useCallback((date, time) => {
    setDate(date);
    setTime(time);
  }, []);

  const onNext = useCallback(() => {
    const [hh, mm] = time.split(':');
    const dateTime = moment(date).hour(hh).minute(mm).toISOString();

    dispatch(setDateTime({ type: 'pre-scan', data: dateTime }));
    dispatch(incrementCheckoutStep(1));
    dispatch(setCurrentStep('CHECKOUT_SELECT_LOCATION'));

    window.analytics.track('PreScanAppt Submit', { dateTime });
  }, [dispatch, date, time]);

  const onPrevious = useCallback(() => {
    dispatch(incrementCheckoutStep(-1));
    dispatch(setCurrentStep('CHECKOUT_PERSONAL_INFO'));
  }, [dispatch]);

  if (activeStep < 0) {
    return <Redirect to='/plans' />;
  }

  return (
    <SchedulingUIWrapper>
      <div className='seven-cols'>
        <Header imgSrc={PhoneCall} text='Select a Date and Time' />
        <Calendar date={date} time={time} onChange={onCalendarChange} />
      </div>
      <div className='five-cols my-auto'>
        <SchedulingRightPanel />
      </div>
      <div className='twelve-cols'>
        <CheckoutNavigationHolder>
          <CheckoutNavigationButton previous onClick={onPrevious}>
            Previous Step
          </CheckoutNavigationButton>
          <CheckoutNavigationButton onClick={onNext} disabled={!date || !time} target='_blank'>
            Continue to Pick a Location
          </CheckoutNavigationButton>
        </CheckoutNavigationHolder>
      </div>
    </SchedulingUIWrapper>
  );
};

export default SelectTime;
