import React, { useState, useEffect, useRef } from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, Sector, Legend} from "recharts";
import RecomendationFilter from './RecomendationFilter';
import { Card, Header, TitleAndLegend, Title, PieChartContainer, LegendListContainer, LegendList, LegendListItem,
   FilterContainer, RADIAN, NoDataContainer, NoDataText} from './RecomendationModalities';
import { Spinner } from "react-bootstrap";

const COLORS = ["#4EAC9F", "#5CC9AD", "#8AF2DA", "#5DC9BF"];

const renderCustomized = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={"middle"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderActiveShape = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <g>
      <text
        x={cx}
        y={cy-30}
        dy={8}
        fill="#0C2945"
        textAnchor={"middle"}
        dominantBaseline="central"
        style={{fontSize: '20px', lineHeight: '25px', fontWeight: 900}}
      >
       {`${(percent * 100).toFixed(0)}%`}
      </text>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={'#A6ACBE'}
        style={{fontSize: '10px', lineHeight: '13px', fontWeight: 600}}
      >
        {payload.anatomy}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 9}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={"middle"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </g>
  );
};

const renderLegend = (props) => {
  const { payload } = props;
  return (
    <LegendListContainer>
      <LegendList>
        {
          payload.sort((a, b) => b.payload.id__count - a.payload.id__count).map((entry, index) => (
            <LegendListItem key={index} color={COLORS[index % COLORS.length]}>{`${entry.payload.anatomy} - ${entry.payload.id__count}`}</LegendListItem>
          ))
        }
      </LegendList>
    </LegendListContainer>
  );
}

const style = {
  top: 22,
  right: -110,
  height: '220px',
  width: '130px',
  lineHeight: "24px",
  borderLeft: '1px solid #C3C7CA',
  paddingLeft: '10px',
};

const RecomendationAnatomies = ({ data, onSetTimeframe, recommendedOptions, currentValue, isLoading, setNeedRerender}) => {
  const legendRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => setNeedRerender('anatomies'), 3000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Card>
      <Header>
        <TitleAndLegend>
          <Title>Recommended Anatomies</Title>
        </TitleAndLegend>
        <FilterContainer>
          <RecomendationFilter onSetSelected={onSetTimeframe} optionsTitle={'Timeframe'} options={recommendedOptions} currentValue={currentValue} />
        </FilterContainer>
      </Header>
      <PieChartContainer>
      { isLoading ? <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "320px",
                }}
              >
                <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{ color: "black", fontSize:'20px'  }}
                 /> 
              </div>:  !data?.length ? <NoDataContainer><NoDataText>{ !isLoading ? 'No data' : null}</NoDataText></NoDataContainer> :
         data?.length ? <ResponsiveContainer width={'80%'} height={320}>
          <PieChart width={550} height={350}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              dataKey='id__count'
              startAngle={180}
              endAngle={0}
              data={data}
              cy={240}
              outerRadius={220}
              innerRadius={50}
              fill="#8884d8"
              label={renderCustomized}
              labelLine={false}
              onMouseEnter={onPieEnter}
              animationDuration={0}
            >
              { data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Legend
              iconSize={10}
              width={120}
              height={140}
              layout="vertical"
              verticalAlign="middle"
              wrapperStyle={style}
              iconType={"circle"}
              content={renderLegend}
              ref={legendRef}
            />
          </PieChart>
        </ResponsiveContainer> : null
        }
      </PieChartContainer>
    </Card>
  );
}

export default RecomendationAnatomies;
