import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getChangelog } from "../../../actions/radminChangelog";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { ReactComponent as ClockBlueIcon } from "../../../assets/clock-blue-icon.svg";
import { ReactComponent as ClockGreyIcon } from "../../../assets/clock-grey-icon.svg";
import styled from "styled-components";

const Container = styled.div`
  padding: 18px 23px;
`;

const Header = styled.h2`
  color: #0c2945;
  font-size: 22px;
  font-weight: 700;
  font-family: Roboto Condensed;
  margin-bottom: 8px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 552px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 317px;
`;

const ChangelogBox = styled.div`
  padding: 10px;
  width: 317px;
  border: ${(props) => props.border};
  background: ${(props) => props.background};
  border-radius: 4px;
`;

const Title = styled.p`
  margin-bottom: 6px;
  color: #0c2945;
  font-family: Roboto Condensed;
  font-size: 18px;
  font-weight: 500;
`;

const Timestamp = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0px;
  color: ${(props) => props.color};
`;

const TimestampRow = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 6px;
  align-items: center;
`;

const Content = styled.div`
font-size: 14px;
font-weight: 400;
color: #252525; 
& p:last-child {
margin-bottom:0px;
}
& .rdmd-table {
overflow-x:scroll;
}
& .img {
margin-bottom: 10px;
display: inline-block;
}
& h1 {
font-size:26px;
}
& h2 {
font-size:22px;
}
& h3 {
font-size:18px;
}
& h4 {
font-size:14px;
}
& h5 {
font-size:12px;
}
& h6 {
font-size:10px;
}
& blockquote .callout-heading {
padding:10px;
display:flex;
align-items:center;
& p {
margin-bottom:0px;
}
}
& .callout_info {
background: #e3edf2;
border-left:2px solid #5bc0de;
color:#46b8da;
}
& .callout_okay {
background: #f3f8f3;
border-left:2px solid #50af51;
color:#489e49;
}
& .callout_warn {
background: #fcf8f2;
border-left:2px solid #f0ad4e;
color:#eea236;
}
& .callout_error{
background: #fdf7f7;
border-left:2px solid #d9534f;
color:#d43f3a;
}
`

const NoDataLabel = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 317px;
  font-size:18px;
  font-family:Roboto Condensed;
  color:#0C2945;
  font-weight:500;
`

const Changelogs = () => {
  const dispatch = useDispatch();
  const changeLogs = useSelector((state) => state.radAdmin.changeLogs);

  useEffect(() => {
    dispatch(getChangelog());
  }, []);

  return (
    <Container>
      <Header>Changelog</Header>
      <Body>
        {changeLogs?.isLoading && (
          <SpinnerContainer>
            <Spinner animation="border" role="status" size="sm" />
          </SpinnerContainer>
        )}
        {
          changeLogs?.data?.length === 0 && <NoDataLabel>No Data</NoDataLabel>
        }
        {changeLogs?.data?.map((itm) => (
          <ChangelogBox
            border={itm.is_seen ? "none" : "1px solid #00AEEF"}
            background={itm.is_seen ? "transparent" : "#00AEEF0A"}
          >
            <TimestampRow>
              {itm.is_seen ? <ClockGreyIcon /> : <ClockBlueIcon />}{" "}
              <Timestamp color={itm.is_seen ? "#C1C1C1" : "#00AEEF"}>
                {moment(itm.created).fromNow()}
              </Timestamp>
            </TimestampRow>
            <Title>{itm.title}</Title>
            <Content
              dangerouslySetInnerHTML={{ __html: itm.html }}
            />
          </ChangelogBox>
        ))}
      </Body>
    </Container>
  );
};

export default Changelogs;
