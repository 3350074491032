import React, { useState, useEffect, useRef } from "react";
import { Card } from "./ImagingAdherenceChart";
import moment from "moment";
import { Row, Col, Spinner } from "react-bootstrap";
import Flex from "../../../components/_styles/Flex";
import styled, { keyframes } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  LabelList,
  Legend,
} from "recharts";
import { Title } from "./RecomendationModalities";
import { TooltipContainer } from "./ImagingAdherenceChart";
import {
  CloseSign,
  Image,
  SelectedItemContainer,
} from "./../FollowUpList/FollowUpList";
import LegendItem from "./LegendItem";
import { ReactComponent as TriangleDownIcon } from "../../../assets/triangle-down.svg";
import {
  DateContainer,
  DatePickerButton,
} from "../../../components/Dialog/EditFormCenter";
import { getRevenueCharges } from "../../../actions/radminUploads";
import close from "../../../assets/cancel-outline-chip.svg";

import { DEFAULT_VALUES } from "./HistoricalAdheranceFilter";
import { filterTypes } from "../../RadAdmin/FollowUpList/FollowUpList";
import { AdherenceTimeFilter } from "./AdherenceTimeFilter";
import { RevenueFilter } from "./RevenueFilter";

const Container = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 55px;
  margin-top: 10px;
`;

const keyFrames = keyframes`
    from {opacity: 0;}
    to {opacity: 1;}
`;

const GraphContainer = styled.div`
  width: 95vw;
  display: flex;
  margin-top: 10px;
  animation-name: ${keyFrames}
  animation-duration: 2s;
`;

const FilterCard = styled(Flex)`
  justify-content: flex-end;
  flex-grow: 1;
  margin-top: 15px;
  margin-right: 14px;
`;

const SelectedFilters = styled.div`
    display:'flex',
    flex-wrap:'wrap',
    width:'100%',
    justify-content: 'right'
`;

const COLORS = {
  ADHERENT: "#64C67C",
  ADHERENT_MESSAGE_SENT: "#FDBB13",
  ADHERENT_WITHOUT_MAMMOGRAPHY: "#7F0CDA",
};

const HistoricalBenchmarkValue = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #000000;
`;

const LabelRow = styled.div`
display:flex;
gap:15px;
padding:20px;
align-items:center;
justify-content:center;
`
const LabelRowContainer = styled.div`
display:flex;
gap:5px;
align-items:center;
`
const ChargeValue = styled.span`
font-weight:600;
font-family:Roboto Condensed;
color: ${props=>props.color};
font-size:16px;
`
const ChargeLabel = styled.span`
font-weight:600;
font-size:16px;
`
const CircleGap = styled.div`
width:4px;
height:4px;
background:#0c2945;
border-radius:50%;
`

const LineChartCustomTooltip = ({ active, payload, benchmark }) => {
  const date = payload?.[0]?.payload?.name;

let formattedDate;

if (moment(date, "YYYY-MM-DD", true).isValid()) {
  formattedDate = moment(date).format("DD MMM, YY");
} else if (moment(date, "YYYY MMM", true).isValid()) {
  formattedDate = moment(date, "YYYY MMM").format("MMM, YY");
}
  if (active && payload) {
    return (
      <div>
        <TooltipContainer style={{ backgroundColor: "#D9ECFF" }}>
          <div style={{ color: "#00AEEF", fontSize: "10px" }}>
            {formattedDate}
          </div>
          <HistoricalBenchmarkValue>
            Adherent Historical Benchmark: $
            {benchmark.toFixed(2).toLocaleString("en-US")}{" "}
          </HistoricalBenchmarkValue>
          <div>
            <div style={{ margin: "5px" }}>
              <LegendItem
                legendText="Adherent: "
                color={COLORS.ADHERENT}
                value={
                  "$" + payload?.[0]?.payload?.adherent.toLocaleString("en-US")
                }
              />
            </div>
            <div style={{ margin: "5px" }}>
              <LegendItem
                legendText="Adherent with Message Sent: "
                color={COLORS.ADHERENT_MESSAGE_SENT}
                value={
                  "$" +
                  payload?.[0]?.payload?.adherent_with_msg_sent.toLocaleString(
                    "en-US"
                  )
                }
              />
            </div>
            <div style={{ margin: "5px" }}>
              <LegendItem
                legendText="Adherent without Mammography: "
                color={COLORS.ADHERENT_WITHOUT_MAMMOGRAPHY}
                value={
                  "$" +
                  payload?.[0]?.payload?.adherent_without_mammogram.toLocaleString(
                    "en-US"
                  )
                }
              />
            </div>
          </div>
        </TooltipContainer>
      </div>
    );
  }
  return null;
};

export const CustomizedActiveDot = ({
  cx,
  cy,
  fill,
}) => {
  return (
    <circle
      cx={cx}
      cy={cy}
      r={5}
      fill={fill}
      stroke={fill}
      cursor="pointer"
      strokeWidth={5}
      r={4}
    />
  );
};

export const CustomizedDot = ({ cx, cy, fill }) => {
  return (
    <circle
      cx={cx}
      cy={cy}
      r={5}
      fill={fill}
      stroke={fill}
      strokeWidth={5}
      r={4}
    />
  );
};

const CustomLabel = (props) => {
  const { x, y, value, fill } = props;
  const formattedValue = Number.isInteger(value) ? value : value?.toFixed(2);

  return (
    <text
      fontWeight={600}
      x={x}
      y={y}
      dy={-10}
      fill={fill}
      fontSize={10}
      textAnchor="middle"
    >
      ${formattedValue}
    </text>
  );
};

export class DatePickerInput extends React.Component {
  formatDate = (date) => {
    return date ? `${moment(date).format("LL")}` : "No Date";
  };
  render(props) {
    return (
      <DatePickerButton onClick={this.props.onClick}>
        <DateContainer>{`${this.formatDate(this.props.date)}`}</DateContainer>
        <TriangleDownIcon />
      </DatePickerButton>
    );
  }
}

function ChargesTimeGraph({ data }) {
  const dispatch = useDispatch();
  const recoConfigData = useSelector((state) => state.radAdmin.recoConfig.data);
  const graphData = useSelector(
    (state) => state.radAdmin?.followUpList.revenueCharges.data
  );
  const graphStartDate = useSelector(
    (state) => state.radAdmin?.followUpList.revenueCharges.startDate
  );
  const graphEndDate = useSelector(
    (state) => state.radAdmin?.followUpList.revenueCharges.endDate
  );
  
  const loading = useSelector(
    (state) => state.radAdmin?.followUpList.revenueCharges.loading
  );

  const revenueChargesAverage = useSelector(
    (state) => state.radAdmin?.followUpList.totalRevenueChargesAverage.data
  );

  const [filterValuesForRequest, setFilterValuesForRequest] =
    useState(DEFAULT_VALUES);
  const [filterBy, setFilterBy] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [frequency, setFrequency] = useState({ value: "months", label: "Per Month", category: "frequency" });

  const mounted  = useRef()


  const benchmarkData = graphData.map((itm) => ({
    ...itm,
    benchmark: revenueChargesAverage?.adherent ?? 0,
  }));


  useEffect(() => {
      const startDate = mounted?.current ?  graphStartDate :  moment().subtract(recoConfigData?.defaultRangeStart, "days").format("YYYY-MM-DD");

      const endDate = mounted?.current? graphEndDate : moment().subtract(recoConfigData?.defaultRangeEnd, "days").format("YYYY-MM-DD");

      dispatch(
        getRevenueCharges(
          filterValuesForRequest,
          startDate,
          endDate,
          "Custom date range",
          frequency
        )
      );

      mounted.current = true;
   
  }, [filterValuesForRequest]);

  const dateRangeChange = (startDate, endDate, type) => {
    dispatch(
      getRevenueCharges(filterValuesForRequest, startDate, endDate, type, frequency)
    );
  };

  const closeSelected = (category, selectedItem) => {
    setPageNumber(1);
    const filters = filterValuesForRequest[category].filter((item) => {
      return item !== selectedItem;
    });
    setFilterValuesForRequest({
      ...filterValuesForRequest,
      [category]: filters,
    });
  };

  return (
    <Card>
      <Row>
        <Col sm={12} md={4}>
          <Container>
            <Title right="32px">Follow-up Revenue</Title>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <AdherenceTimeFilter
                dateRangeChange={dateRangeChange}
                graphStartDate={graphStartDate}
                graphEndDate={graphEndDate}
                showGroupBy={true}
                frequency={frequency}
                setFrequency={setFrequency}
              />
            </div>
          </Container>
        </Col>
        <Col sm={12} md={8}>
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <SelectedFilters
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "right",
                alignItems: "center",
                fontSize: "10px",
              }}
            >
              {Object.values(filterValuesForRequest).map((item) => {
                if (!item) return null;
                return item.map((i) => {
                  return (
                    <SelectedItemContainer
                      style={{ display: "flex", alignItems: "center" }}
                      key={i.value}
                    >
                      {Number.isInteger(i.value)
                        ? i.label
                        : i.value.replace(/_/g, " ")}
                      <CloseSign onClick={() => closeSelected(i.category, i)}>
                        <Image src={close} alt={"close sign"} />
                      </CloseSign>
                    </SelectedItemContainer>
                  );
                });
              })}
            </SelectedFilters>
            <FilterCard>
              <RevenueFilter
                filterBy={filterBy}
                setFilter={setFilterBy}
                filterTypes={filterTypes}
                setFilterBy={setFilterBy}
                setPageNumber={setPageNumber}
                filterValuesForRequest={filterValuesForRequest}
                setFilterValuesForRequest={setFilterValuesForRequest}
                marginRight="true"
              />
            </FilterCard>
          </div>
        </Col>
      </Row>
      <Row>
       <Col sm={12} md={12}>
         <LabelRow>
           <LabelRowContainer>
             <ChargeLabel>Total Adherent:</ChargeLabel>
             <ChargeValue color='#64C67C'> ${benchmarkData?.reduce((acc,curr)=>acc + curr?.adherent,0)?.toLocaleString('en-US') ?? 0}</ChargeValue>
           </LabelRowContainer>
           <CircleGap  />

           <LabelRowContainer>
             <ChargeLabel>Total Adherent With Message Sent:  </ChargeLabel>
             <ChargeValue color="#FDBB13" > ${benchmarkData?.reduce((acc,curr)=>acc + curr?.adherent_with_msg_sent,0)?.toLocaleString('en-US') ?? 0}</ChargeValue> 
           </LabelRowContainer>
           <CircleGap  />

           <LabelRowContainer>
             <ChargeLabel>Total Adherent Without Mammography:</ChargeLabel> 
             <ChargeValue color='#7F0CDA' >${benchmarkData?.reduce((acc,curr)=>acc + curr?.adherent_without_mammogram,0)?.toLocaleString('en-US') ?? 0}</ChargeValue>
           </LabelRowContainer>
         </LabelRow>
      </Col>
    </Row>

      <GraphContainer>
        <ResponsiveContainer width="100%" height={400}>
        {
                loading?  
                <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner animation="border" role="status" size="sm"  /> 
              </div>: <LineChart
            width={500}
            height={350}
            data={graphData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              fontWeight={600}
              fontSize="13px"
              stroke="#3F4857"
              dataKey="name"
            />
            <YAxis
              tickFormatter={(value) => `$${value}`}
              padding={{ bottom: 50 }}
              fontWeight={700}
              stroke="#A6ACBE"
              fontSize="13px"
            />
            <Tooltip
              content={
                <LineChartCustomTooltip
                  benchmark={revenueChargesAverage.adherent}
                />
              }
            />

            <Line
              dataKey="adherent"
              stroke="#64C67C"
              dot={<CustomizedDot fill={"#64C67C"} />}
              activeDot={<CustomizedActiveDot fill={"#64C67C"} />}
              strokeWidth={3}
            >
              <LabelList content={<CustomLabel fill={"#64C67C"} />} />
            </Line>

            <Line
              dataKey="adherent_with_msg_sent"
              stroke="#FDBB13"
              dot={<CustomizedDot fill={"#FDBB13"} />}
              activeDot={<CustomizedActiveDot fill={"#FDBB13"} />}
              strokeWidth={3}
            >
              <LabelList content={<CustomLabel fill={"#FDBB13"} />} />
            </Line>

            <Line
              dataKey="adherent_without_mammogram"
              stroke="#7F0CDA"
              dot={<CustomizedDot fill={"#7F0CDA"} />}
              activeDot={<CustomizedActiveDot fill={"#7F0CDA"} />}
              strokeWidth={3}
            >
              <LabelList content={<CustomLabel fill={"#7F0CDA"} />} />
            </Line>

            {/* Benchmark Line */}

            <Line
              data={benchmarkData}
              type="monotone"
              dataKey="benchmark"
              stroke="#64C67C"
              strokeWidth={2}
              dot={false}
              isAnimationActive={false}
              strokeDasharray="5 5"
            />

            <Legend
              verticalAlign="bottom"
              align="center"
              height={30}
              iconType="plainline"
              iconSize={30}
              layout="horizontal"
              formatter={(value, entry, index) => {
                if (index === 0) {
                  return (
                    <span style={{ fontSize: "13px", fontWeight: 600 }}>
                      Adherent
                    </span>
                  );
                } else if (index === 1) {
                  return (
                    <span style={{ fontSize: "13px", fontWeight: 600 }}>
                      Adherent with Message Sent
                    </span>
                  );
                } else if (index === 2) {
                  return (
                    <span style={{ fontSize: "13px", fontWeight: 600 }}>
                      Adherent without Mammography
                    </span>
                  );
                } else if (index === 3) {
                  return (
                    <span style={{ fontSize: "13px", fontWeight: 600 }}>
                      Adherent Historical Benchmark of last 12 months
                    </span>
                  );
                }
              }}
            />
          </LineChart> }
          
        </ResponsiveContainer>
      </GraphContainer>
    </Card>
  );
}

export default ChargesTimeGraph;
