import React from 'react';

export const CloseIcon = () => (
  <svg role="presentation" viewBox="0 0 24 24" fill="none">
    <path
      d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z"
      fill="#fff"
    ></path>
  </svg>
);
export const SearchIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2583 16.075L14.425 13.25C15.3392 12.0854 15.8352 10.6472 15.8333 9.16667C15.8333 7.84813 15.4423 6.5592 14.7098 5.46287C13.9773 4.36654 12.9361 3.51206 11.7179 3.00747C10.4997 2.50289 9.15927 2.37087 7.86607 2.6281C6.57286 2.88534 5.38497 3.52027 4.45262 4.45262C3.52027 5.38497 2.88534 6.57286 2.6281 7.86607C2.37087 9.15927 2.50289 10.4997 3.00747 11.7179C3.51206 12.9361 4.36654 13.9773 5.46287 14.7098C6.5592 15.4423 7.84813 15.8333 9.16667 15.8333C10.6472 15.8352 12.0854 15.3392 13.25 14.425L16.075 17.2583C16.1525 17.3364 16.2446 17.3984 16.3462 17.4407C16.4477 17.4831 16.5567 17.5048 16.6667 17.5048C16.7767 17.5048 16.8856 17.4831 16.9872 17.4407C17.0887 17.3984 17.1809 17.3364 17.2583 17.2583C17.3364 17.1809 17.3984 17.0887 17.4407 16.9872C17.4831 16.8856 17.5048 16.7767 17.5048 16.6667C17.5048 16.5567 17.4831 16.4477 17.4407 16.3462C17.3984 16.2446 17.3364 16.1525 17.2583 16.075ZM4.16667 9.16667C4.16667 8.17776 4.45991 7.21106 5.00932 6.38882C5.55873 5.56657 6.33962 4.92571 7.25325 4.54727C8.16688 4.16883 9.17222 4.06982 10.1421 4.26274C11.112 4.45567 12.0029 4.93187 12.7022 5.63114C13.4015 6.3304 13.8777 7.22131 14.0706 8.19122C14.2635 9.16112 14.1645 10.1665 13.7861 11.0801C13.4076 11.9937 12.7668 12.7746 11.9445 13.324C11.1223 13.8734 10.1556 14.1667 9.16667 14.1667C7.84059 14.1667 6.56882 13.6399 5.63114 12.7022C4.69345 11.7645 4.16667 10.4928 4.16667 9.16667Z"
      fill="#3F4857"
    />
  </svg>
);
export const NeurovascularIcon = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="35" height="35" rx="12" fill="#ECE8FD" />
    <path
      d="M21.1641 17.4727C21.4555 17.4727 21.6914 17.2367 21.6914 16.9453C21.6914 16.073 22.4011 15.3633 23.2734 15.3633C24.7536 15.3633 25.9102 16.5791 25.9102 18C25.9102 19.4306 24.7446 20.6367 23.2734 20.6367C22.982 20.6367 22.7461 20.8727 22.7461 21.1641C22.7461 21.4555 22.982 21.6914 23.2734 21.6914C23.8281 21.6914 24.3493 21.5597 24.8217 21.3394C24.8378 21.4557 24.8555 21.5718 24.8555 21.6914C24.8549 23.4815 23.3161 24.9491 21.5564 24.8523C21.2818 25.8256 20.4903 26.8503 18.5273 27V9C20.5102 9.1512 21.2944 10.1934 21.5616 11.1625C23.4109 11.3335 24.8555 12.6792 24.8555 14.3086C24.8555 14.4274 24.8356 14.5426 24.8172 14.6581C24.3458 14.4393 23.8262 14.3086 23.2734 14.3086C22.745 14.3086 22.2545 14.4684 21.8412 14.7372C21.7568 14.6472 21.7176 14.5463 21.7053 14.4925C21.6858 14.4069 21.6605 14.188 21.8465 14.0022C22.0524 13.7962 22.0524 13.4625 21.8465 13.2565C21.6405 13.0505 21.3067 13.0505 21.1008 13.2565C20.7135 13.6438 20.5554 14.1932 20.677 14.7273C20.743 15.0158 20.8894 15.2685 21.0874 15.4726C20.803 15.8935 20.6367 16.4003 20.6367 16.9453C20.6367 17.2367 20.8727 17.4727 21.1641 17.4727ZM19.582 20.1094V21.1641C19.582 22.0364 20.2917 22.7461 21.1641 22.7461C21.4555 22.7461 21.6914 22.5102 21.6914 22.2188C21.6914 21.9273 21.4555 21.6914 21.1641 21.6914C20.8731 21.6914 20.6367 21.4551 20.6367 21.1641V20.1094C20.6367 19.8184 20.8731 19.582 21.1641 19.582C22.0364 19.582 22.7461 18.8723 22.7461 18C22.7461 17.7086 22.5102 17.4727 22.2188 17.4727C21.9273 17.4727 21.6914 17.7086 21.6914 18C21.6914 18.291 21.4551 18.5273 21.1641 18.5273C20.2917 18.5273 19.582 19.2371 19.582 20.1094Z"
      fill="#7056E1"
    />
    <path
      d="M14.4513 14.4959C14.4358 14.5622 14.3855 14.7007 14.2487 14.7998C13.8178 14.493 13.2946 14.3085 12.7266 14.3085C12.1738 14.3085 11.6542 14.4392 11.1828 14.658C11.1644 14.5425 11.1445 14.4272 11.1445 14.3085C11.1445 12.6873 12.5741 11.3472 14.4369 11.1691C14.7042 10.197 15.4884 9.15161 17.4727 9V26.9999C15.5089 26.8503 14.7177 25.8256 14.443 24.8527C12.6591 24.9186 11.1445 23.4808 11.1445 21.6913C11.1445 21.5717 11.1622 21.4556 11.1783 21.3393C11.6507 21.5596 12.1719 21.6913 12.7266 21.6913C13.018 21.6913 13.2539 21.4553 13.2539 21.1639C13.2539 20.8725 13.018 20.6366 12.7266 20.6366C11.2555 20.6366 10.0898 19.4304 10.0898 17.9999C10.0898 16.5774 11.2482 15.3631 12.7266 15.3631C13.5989 15.3631 14.3086 16.0729 14.3086 16.9452C14.3086 17.2366 14.5445 17.4725 14.8359 17.4725C15.1273 17.4725 15.3633 17.2366 15.3633 16.9452C15.3633 16.4343 15.2108 15.9609 14.9583 15.5565C15.2189 15.3371 15.4015 15.062 15.4781 14.7379C15.6048 14.2008 15.4472 13.6467 15.0569 13.2562C14.8509 13.0504 14.5172 13.0504 14.3112 13.2562C14.1052 13.4622 14.1052 13.7961 14.3112 14.0019C14.4987 14.1895 14.4719 14.4099 14.4513 14.4959ZM14.8359 18.5272C14.5449 18.5272 14.3086 18.2909 14.3086 17.9999C14.3086 17.7085 14.0727 17.4725 13.7812 17.4725C13.4898 17.4725 13.2539 17.7085 13.2539 17.9999C13.2539 18.8722 13.9636 19.5819 14.8359 19.5819C15.1269 19.5819 15.3633 19.8182 15.3633 20.1092V21.1639C15.3633 21.4549 15.1269 21.6913 14.8359 21.6913C14.5445 21.6913 14.3086 21.9272 14.3086 22.2186C14.3086 22.51 14.5445 22.746 14.8359 22.746C15.7083 22.746 16.418 22.0362 16.418 21.1639V20.1092C16.418 19.2369 15.7083 18.5272 14.8359 18.5272Z"
      fill="#7056E1"
    />
  </svg>
);
export const RibbonIcon = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="35" height="35" rx="12" fill="#FFECED" />
    <g clipPath="url(#clip0)">
      <path
        d="M21.8967 26.9777C22.1075 26.904 22.2553 26.7036 22.2553 26.4716V24.8193H24.3645C24.5754 24.8193 24.7547 24.7034 24.8391 24.5135C24.9339 24.3237 24.9023 24.1022 24.7652 23.944C18.279 16.5231 15.5132 13.3589 15.4319 13.2501C14.5069 12.1317 14.4201 10.9931 14.2823 10.5715C12.6264 12.2515 12.3345 14.429 13.7128 16.3086C13.7897 16.4141 21.7062 27.3363 21.2911 26.7675C21.2966 26.7749 21.3 26.7798 21.306 26.788C21.4431 26.9673 21.6858 27.041 21.8967 26.9777Z"
        fill="#FF5F65"
      />
      <path
        d="M15.1963 9.84334C15.4679 11.0363 15.6006 11.7989 16.2444 12.5778C16.253 12.5887 16.271 12.6098 16.2856 12.6271C16.8096 12.3404 17.4018 12.1638 18.0364 12.1638C18.6717 12.1638 19.2645 12.3407 19.789 12.6281L19.7964 12.6181C20.48 11.7881 20.5979 11.0624 20.8758 9.84306C20.0549 9.31613 19.0813 9 18.0364 9C16.9912 9 16.0172 9.31627 15.1963 9.84334Z"
        fill="#FF5F65"
      />
      <path
        d="M20.6409 13.25C20.272 13.7428 20.504 13.4666 18.75 15.4549L21.059 18.0971L22.3598 16.3085C23.6528 14.5282 23.5207 12.3266 21.7905 10.5713C21.6663 10.951 21.5707 12.1257 20.6409 13.25Z"
        fill="#FF5F65"
      />
      <path
        d="M14.1776 26.9775C14.3886 27.0408 14.6311 26.967 14.7683 26.7877L17.3876 23.1303L14.9478 19.7832C13.9401 20.9333 12.7442 22.3003 11.3091 23.9437C11.1719 24.1019 11.1403 24.3235 11.2352 24.5132C11.3197 24.7032 11.4989 24.8191 11.7098 24.8191H13.8191V26.4713C13.8191 26.7034 13.9667 26.9037 14.1776 26.9775Z"
        fill="#FF5F65"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect x="9" y="9" width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const IntellectIcon = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="35" height="35" rx="12" fill="#FFFAEE" />
    <path
      d="M21.1641 17.4727C21.4555 17.4727 21.6914 17.2367 21.6914 16.9453C21.6914 16.073 22.4011 15.3633 23.2734 15.3633C24.7536 15.3633 25.9102 16.5791 25.9102 18C25.9102 19.4306 24.7446 20.6367 23.2734 20.6367C22.982 20.6367 22.7461 20.8727 22.7461 21.1641C22.7461 21.4555 22.982 21.6914 23.2734 21.6914C23.8281 21.6914 24.3493 21.5597 24.8217 21.3394C24.8378 21.4557 24.8555 21.5718 24.8555 21.6914C24.8549 23.4815 23.3161 24.9491 21.5564 24.8523C21.2818 25.8256 20.4903 26.8503 18.5273 27V9C20.5102 9.1512 21.2944 10.1934 21.5616 11.1625C23.4109 11.3335 24.8555 12.6792 24.8555 14.3086C24.8555 14.4274 24.8356 14.5426 24.8172 14.6581C24.3458 14.4393 23.8262 14.3086 23.2734 14.3086C22.745 14.3086 22.2545 14.4684 21.8412 14.7372C21.7568 14.6472 21.7176 14.5463 21.7053 14.4925C21.6858 14.4069 21.6605 14.188 21.8465 14.0022C22.0524 13.7962 22.0524 13.4625 21.8465 13.2565C21.6405 13.0505 21.3067 13.0505 21.1008 13.2565C20.7135 13.6438 20.5554 14.1932 20.677 14.7273C20.743 15.0158 20.8894 15.2685 21.0874 15.4726C20.803 15.8935 20.6367 16.4003 20.6367 16.9453C20.6367 17.2367 20.8727 17.4727 21.1641 17.4727ZM19.582 20.1094V21.1641C19.582 22.0364 20.2917 22.7461 21.1641 22.7461C21.4555 22.7461 21.6914 22.5102 21.6914 22.2188C21.6914 21.9273 21.4555 21.6914 21.1641 21.6914C20.8731 21.6914 20.6367 21.4551 20.6367 21.1641V20.1094C20.6367 19.8184 20.8731 19.582 21.1641 19.582C22.0364 19.582 22.7461 18.8723 22.7461 18C22.7461 17.7086 22.5102 17.4727 22.2188 17.4727C21.9273 17.4727 21.6914 17.7086 21.6914 18C21.6914 18.291 21.4551 18.5273 21.1641 18.5273C20.2917 18.5273 19.582 19.2371 19.582 20.1094Z"
      fill="#F6C445"
    />
    <path
      d="M14.4513 14.4959C14.4358 14.5622 14.3855 14.7007 14.2487 14.7998C13.8178 14.493 13.2946 14.3085 12.7266 14.3085C12.1738 14.3085 11.6542 14.4392 11.1828 14.658C11.1644 14.5425 11.1445 14.4272 11.1445 14.3085C11.1445 12.6873 12.5741 11.3472 14.4369 11.1691C14.7042 10.197 15.4884 9.15161 17.4727 9V26.9999C15.5089 26.8503 14.7177 25.8256 14.443 24.8527C12.6591 24.9186 11.1445 23.4808 11.1445 21.6913C11.1445 21.5717 11.1622 21.4556 11.1783 21.3393C11.6507 21.5596 12.1719 21.6913 12.7266 21.6913C13.018 21.6913 13.2539 21.4553 13.2539 21.1639C13.2539 20.8725 13.018 20.6366 12.7266 20.6366C11.2555 20.6366 10.0898 19.4304 10.0898 17.9999C10.0898 16.5774 11.2482 15.3631 12.7266 15.3631C13.5989 15.3631 14.3086 16.0729 14.3086 16.9452C14.3086 17.2366 14.5445 17.4725 14.8359 17.4725C15.1273 17.4725 15.3633 17.2366 15.3633 16.9452C15.3633 16.4343 15.2108 15.9609 14.9583 15.5565C15.2189 15.3371 15.4015 15.062 15.4781 14.7379C15.6048 14.2008 15.4472 13.6467 15.0569 13.2562C14.8509 13.0504 14.5172 13.0504 14.3112 13.2562C14.1052 13.4622 14.1052 13.7961 14.3112 14.0019C14.4987 14.1895 14.4719 14.4099 14.4513 14.4959ZM14.8359 18.5272C14.5449 18.5272 14.3086 18.2909 14.3086 17.9999C14.3086 17.7085 14.0727 17.4725 13.7812 17.4725C13.4898 17.4725 13.2539 17.7085 13.2539 17.9999C13.2539 18.8722 13.9636 19.5819 14.8359 19.5819C15.1269 19.5819 15.3633 19.8182 15.3633 20.1092V21.1639C15.3633 21.4549 15.1269 21.6913 14.8359 21.6913C14.5445 21.6913 14.3086 21.9272 14.3086 22.2186C14.3086 22.51 14.5445 22.746 14.8359 22.746C15.7083 22.746 16.418 22.0362 16.418 21.1639V20.1092C16.418 19.2369 15.7083 18.5272 14.8359 18.5272Z"
      fill="#F6C445"
    />
  </svg>
);
export const MetabolismIcon = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="35" height="35" rx="12" fill="#E2EDFF" />
    <g clipPath="url(#clip0)">
      <path
        d="M26.299 12.3623C26.4283 12.233 26.4813 12.0461 26.4396 11.8684C26.3974 11.6903 26.266 11.5471 26.093 11.4894L22.7368 10.3709C22.5468 10.3065 22.3377 10.357 22.1971 10.4981C22.056 10.6392 22.0066 10.8483 22.0699 11.0378L23.1885 14.394C23.2462 14.567 23.3893 14.6983 23.5675 14.7405C23.7528 14.7833 23.9368 14.7245 24.0614 14.5999L24.7601 13.9012C25.5 15.1283 25.9453 16.5491 25.9453 18C25.9453 22.3619 22.3619 25.9453 18 25.9453C17.7085 25.9453 17.4727 26.1811 17.4727 26.4726C17.4727 26.7641 17.7085 27 18 27C22.9433 27 27 22.9433 27 18C27 16.2676 26.4548 14.5792 25.5259 13.1354L26.299 12.3623Z"
        fill="#186EF0"
      />
      <path
        d="M9.54088 23.4954C9.41161 23.6246 9.35856 23.8116 9.40029 23.9893C9.44251 24.1674 9.57386 24.3106 9.74686 24.3683L13.103 25.4868C13.2889 25.5499 13.5 25.5022 13.6427 25.3596C13.7839 25.2185 13.8333 25.0094 13.7699 24.8199L12.6509 21.4643C12.5932 21.2907 12.45 21.1599 12.2719 21.1177C12.0937 21.076 11.9067 21.1295 11.778 21.2583L11.1399 21.8964C10.464 20.7101 10.0547 19.3819 10.0547 18C10.0547 13.6381 13.6381 10.0547 18 10.0547C18.2915 10.0547 18.5273 9.81882 18.5273 9.52734C18.5273 9.23586 18.2915 9 18 9C13.0567 9 9 13.0567 9 18C9 19.6633 9.50861 21.2605 10.3698 22.6665L9.54088 23.4954Z"
        fill="#186EF0"
      />
      <path
        d="M19.0551 11.1094C18.1449 11.1094 17.3412 11.5733 16.8672 12.2768C17.234 12.2039 17.6125 12.1641 18.0004 12.1641C19.389 12.1641 20.6644 12.6556 21.6641 13.4724C21.525 12.1486 20.4152 11.1094 19.0551 11.1094Z"
        fill="#186EF0"
      />
      <path
        d="M21.1641 22.7461C22.0378 22.7461 22.7461 22.0378 22.7461 21.1641C22.7461 20.2903 22.0378 19.582 21.1641 19.582C20.2903 19.582 19.582 20.2903 19.582 21.1641C19.582 22.0378 20.2903 22.7461 21.1641 22.7461Z"
        fill="#186EF0"
      />
      <path
        d="M14.3086 21.6914C14.8911 21.6914 15.3633 21.2192 15.3633 20.6367C15.3633 20.0542 14.8911 19.582 14.3086 19.582C13.7261 19.582 13.2539 20.0542 13.2539 20.6367C13.2539 21.2192 13.7261 21.6914 14.3086 21.6914Z"
        fill="#186EF0"
      />
      <path
        d="M14.3086 15.3281C14.8911 15.3281 15.3633 14.8559 15.3633 14.2734C15.3633 13.6909 14.8911 13.2188 14.3086 13.2188C13.7261 13.2188 13.2539 13.6909 13.2539 14.2734C13.2539 14.8559 13.7261 15.3281 14.3086 15.3281Z"
        fill="#186EF0"
      />
      <path
        d="M18 13.2188C17.3925 13.2188 16.8164 13.3434 16.2825 13.5514C16.3656 13.7776 16.418 14.0187 16.418 14.2734C16.418 15.4368 15.4719 16.3828 14.3086 16.3828C14.0539 16.3828 13.8128 16.3304 13.5866 16.2474C13.3786 16.7813 13.2539 17.3925 13.2539 18C13.2539 18.2665 13.2902 18.5235 13.3324 18.7781C13.6256 18.6233 13.9545 18.5273 14.3086 18.5273C15.4719 18.5273 16.418 19.4734 16.418 20.6367C16.418 21.2367 16.1635 21.7757 15.7602 22.1601C16.4302 22.5218 17.1852 22.7461 18 22.7461C18.337 22.7461 18.6648 22.7085 18.982 22.6418C18.6954 22.2199 18.5273 21.7114 18.5273 21.1641C18.5273 19.7102 19.7102 18.5273 21.1641 18.5273C21.7114 18.5273 22.2199 18.6954 22.6418 18.982C22.7085 18.6648 22.7461 18.337 22.7461 18C22.7461 15.3788 20.6212 13.2188 18 13.2188Z"
        fill="#186EF0"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect x="9" y="9" width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const ArrowDownward = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9997 16C12.2334 16.0005 12.4598 15.9191 12.6397 15.77L18.6397 10.77C18.8439 10.6003 18.9724 10.3564 18.9967 10.092C19.0211 9.82753 18.9395 9.56425 18.7697 9.36003C18.6 9.15581 18.3561 9.02739 18.0916 9.00301C17.8272 8.97863 17.5639 9.06029 17.3597 9.23003L11.9997 13.71L6.63972 9.39003C6.53744 9.30697 6.41974 9.24493 6.2934 9.2075C6.16706 9.17007 6.03457 9.15798 5.90354 9.17192C5.77251 9.18586 5.64553 9.22555 5.52989 9.28873C5.41426 9.3519 5.31225 9.4373 5.22972 9.54003C5.13814 9.64285 5.06878 9.76348 5.02599 9.89435C4.9832 10.0252 4.9679 10.1635 4.98104 10.3006C4.99418 10.4377 5.03549 10.5705 5.10237 10.6909C5.16926 10.8112 5.26027 10.9165 5.36973 11L11.3697 15.83C11.5548 15.9555 11.7766 16.0154 11.9997 16Z"
      fill="#3F4857"
    />
  </svg>
);
export const PulseIcon = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="35" height="35" rx="12" fill="#FDF0FB" />
    <path
      d="M22.2188 10.1602C20.0862 10.1602 18.7174 11.6371 18 12.9673C17.2826 11.6371 15.9138 10.1602 13.7812 10.1602C11.0755 10.1602 9 12.3555 9 15.2672C9 16.027 9.15212 16.7284 9.43221 17.4023H13.4553L14.3642 15.5844C14.5434 15.2271 15.1284 15.2271 15.3077 15.5844L16.9453 18.8598L17.5283 17.6939C17.7075 17.3365 18.2925 17.3365 18.4717 17.6939L19.0547 18.8598L20.6923 15.5845C20.8716 15.2271 21.4566 15.2271 21.6358 15.5845L22.5447 17.4023H26.5678C26.8479 16.7284 27 16.027 27 15.2672C27 12.3555 24.9245 10.1602 22.2188 10.1602Z"
      fill="#DD6ECD"
    />
    <path
      d="M22.2184 18.457C22.0186 18.457 21.8363 18.3443 21.7467 18.1656L21.1637 16.9996L19.5261 20.2749C19.4365 20.4536 19.2542 20.5664 19.0544 20.5664C18.8545 20.5664 18.6722 20.4536 18.5826 20.2749L17.9997 19.109L17.4167 20.2749C17.2375 20.6323 16.6525 20.6323 16.4733 20.2749L14.8356 16.9996L14.2527 18.1656C14.163 18.3443 13.9808 18.457 13.7809 18.457H9.98535C11.0707 20.1647 13.0255 21.7868 15.4706 23.8505C16.157 24.4298 16.8873 25.0463 17.6541 25.7111C17.7535 25.7971 17.8766 25.8398 17.9996 25.8398C18.1227 25.8398 18.2458 25.7971 18.3452 25.7111C19.112 25.0463 19.8423 24.4298 20.5287 23.8505C22.9739 21.7868 24.9286 20.1647 26.0139 18.457H22.2184Z"
      fill="#DD6ECD"
    />
  </svg>
);
export const PlusIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 13H13M24 13H13M13 13V2M13 13V24"
      stroke="#8692A4"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
);
export const PinOutlineIcon = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C9.89206 1.99989 7.86926 2.83176 6.37124 4.31479C4.87323 5.79782 4.02108 7.81216 4 9.92C4 15.4 11.05 21.5 11.35 21.76C11.5311 21.9149 11.7616 22.0001 12 22.0001C12.2384 22.0001 12.4689 21.9149 12.65 21.76C13 21.5 20 15.4 20 9.92C19.9789 7.81216 19.1268 5.79782 17.6288 4.31479C16.1307 2.83176 14.1079 1.99989 12 2ZM12 19.65C10.33 18.06 6 13.65 6 9.92C6 8.3287 6.63214 6.80258 7.75736 5.67736C8.88258 4.55214 10.4087 3.92 12 3.92C13.5913 3.92 15.1174 4.55214 16.2426 5.67736C17.3679 6.80258 18 8.3287 18 9.92C18 13.62 13.67 18.06 12 19.65Z"
      fill="#CBCCCF"
    />
    <path
      d="M12 6C11.3078 6 10.6311 6.20527 10.0555 6.58986C9.47993 6.97444 9.03133 7.52107 8.76642 8.16061C8.50152 8.80015 8.4322 9.50388 8.56725 10.1828C8.7023 10.8618 9.03564 11.4854 9.52513 11.9749C10.0146 12.4644 10.6383 12.7977 11.3172 12.9327C11.9961 13.0678 12.6999 12.9985 13.3394 12.7336C13.9789 12.4687 14.5256 12.0201 14.9101 11.4445C15.2947 10.8689 15.5 10.1922 15.5 9.5C15.5 8.57174 15.1313 7.6815 14.4749 7.02513C13.8185 6.36875 12.9283 6 12 6ZM12 11C11.7033 11 11.4133 10.912 11.1666 10.7472C10.92 10.5824 10.7277 10.3481 10.6142 10.074C10.5007 9.79994 10.4709 9.49834 10.5288 9.20736C10.5867 8.91639 10.7296 8.64912 10.9393 8.43934C11.1491 8.22956 11.4164 8.0867 11.7074 8.02882C11.9983 7.97094 12.2999 8.00065 12.574 8.11418C12.8481 8.22771 13.0824 8.41997 13.2472 8.66665C13.412 8.91332 13.5 9.20333 13.5 9.5C13.5 9.89782 13.342 10.2794 13.0607 10.5607C12.7794 10.842 12.3978 11 12 11Z"
      fill="#CBCCCF"
    />
  </svg>
);
export const GlobeIcon = props => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10ZM17.93 9H15C14.8351 6.64814 14.0293 4.38635 12.67 2.46C14.0665 2.95597 15.2977 3.83007 16.2265 4.98484C17.1553 6.13962 17.745 7.52967 17.93 9ZM7.08 11H13C12.7441 13.4706 11.6911 15.7908 10 17.61C8.31007 15.7984 7.28119 13.4693 7.08 11ZM7.08 9C7.31289 6.54183 8.33759 4.22572 10 2.4C11.7047 4.20612 12.7601 6.52804 13 9H7.08ZM7.44 2.43C6.07355 4.36702 5.2548 6.63672 5.07 9H2.07C2.2589 7.5115 2.86261 6.10612 3.81217 4.94437C4.76172 3.78262 6.01886 2.91133 7.44 2.43ZM2.07 11H5.07C5.23469 13.3613 6.04788 15.6313 7.42 17.56C6.00397 17.0763 4.75199 16.2052 3.80627 15.0456C2.86055 13.886 2.25902 12.4844 2.07 11ZM12.62 17.55C13.9884 15.6207 14.8107 13.3577 15 11H17.95C17.7609 12.4802 17.1615 13.8781 16.2196 15.0356C15.2778 16.193 14.0309 17.064 12.62 17.55Z"
      fill="#CBCCCF"
    />
  </svg>
);
export const PhoneIcon = props => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.75 13.91C19.695 13.7495 19.6 13.6056 19.4741 13.4919C19.3481 13.3781 19.1953 13.2984 19.03 13.26L13.03 11.89C12.8671 11.8531 12.6976 11.8575 12.5369 11.9029C12.3762 11.9484 12.2294 12.0333 12.11 12.15C11.97 12.28 11.96 12.29 11.31 13.53C9.1532 12.5362 7.42499 10.8009 6.44 8.64C7.71 8 7.72 8 7.85 7.85C7.96671 7.73056 8.05165 7.58379 8.09706 7.42308C8.14248 7.26238 8.14693 7.09286 8.11 6.93L6.74 1C6.70165 0.834696 6.62186 0.681871 6.50814 0.555912C6.39443 0.429952 6.25053 0.335002 6.09 0.28C5.85647 0.196589 5.61531 0.1363 5.37 0.0999999C5.11725 0.0413952 4.85933 0.00789934 4.6 0C3.38 0 2.20998 0.484642 1.34731 1.34731C0.484642 2.20998 0 3.38 0 4.6C0.00529192 8.68271 1.62949 12.5967 4.5164 15.4836C7.40331 18.3705 11.3173 19.9947 15.4 20C16.0041 20 16.6022 19.881 17.1603 19.6498C17.7184 19.4187 18.2255 19.0798 18.6527 18.6527C19.0798 18.2255 19.4187 17.7184 19.6498 17.1603C19.881 16.6022 20 16.0041 20 15.4C20.0003 15.1455 19.9802 14.8913 19.94 14.64C19.898 14.3916 19.8344 14.1474 19.75 13.91ZM15.4 18C11.8469 17.9974 8.44011 16.5847 5.92769 14.0723C3.41528 11.5599 2.00265 8.15309 2 4.6C2.00263 3.91125 2.27741 3.25146 2.76443 2.76443C3.25146 2.27741 3.91125 2.00263 4.6 2H4.93L6 6.64L5.46 6.92C4.6 7.37 3.92 7.73 4.28 8.51C4.86622 10.1696 5.81477 11.6777 7.05674 12.9249C8.2987 14.172 9.80288 15.1269 11.46 15.72C12.3 16.06 12.63 15.43 13.08 14.56L13.37 14.01L18 15.07V15.4C17.9974 16.0888 17.7226 16.7485 17.2356 17.2356C16.7485 17.7226 16.0888 17.9974 15.4 18Z"
      fill="#CBCCCF"
    />
  </svg>
);
export const ClockIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.675 14.825L14.8 13.7L10.825 9.7V4.675H9.325V10.3L13.675 14.825ZM10 20C8.63333 20 7.34167 19.7375 6.125 19.2125C4.90833 18.6875 3.84583 17.9708 2.9375 17.0625C2.02917 16.1542 1.3125 15.0917 0.7875 13.875C0.2625 12.6583 0 11.3667 0 10C0 8.63333 0.2625 7.34167 0.7875 6.125C1.3125 4.90833 2.02917 3.84583 2.9375 2.9375C3.84583 2.02917 4.90833 1.3125 6.125 0.7875C7.34167 0.2625 8.63333 0 10 0C11.3667 0 12.6583 0.2625 13.875 0.7875C15.0917 1.3125 16.1542 2.02917 17.0625 2.9375C17.9708 3.84583 18.6875 4.90833 19.2125 6.125C19.7375 7.34167 20 8.63333 20 10C20 11.3667 19.7375 12.6583 19.2125 13.875C18.6875 15.0917 17.9708 16.1542 17.0625 17.0625C16.1542 17.9708 15.0917 18.6875 13.875 19.2125C12.6583 19.7375 11.3667 20 10 20ZM10 18.5C12.3333 18.5 14.3333 17.6667 16 16C17.6667 14.3333 18.5 12.3333 18.5 10C18.5 7.66667 17.6667 5.66667 16 4C14.3333 2.33333 12.3333 1.5 10 1.5C7.66667 1.5 5.66667 2.33333 4 4C2.33333 5.66667 1.5 7.66667 1.5 10C1.5 12.3333 2.33333 14.3333 4 16C5.66667 17.6667 7.66667 18.5 10 18.5Z" fill="#C2C2C2"/>
</svg>

);
export const Arrow = () => (
  <svg
    width="15"
    height="10"
    viewBox="0 0 22 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 8H18.86L15.23 12.36C15.146 12.4611 15.0826 12.5778 15.0437 12.7034C15.0047 12.829 14.9909 12.961 15.003 13.0919C15.0274 13.3564 15.1558 13.6003 15.36 13.77C15.5642 13.9397 15.8275 14.0214 16.0919 13.997C16.3563 13.9726 16.6003 13.8442 16.77 13.64L21.77 7.64C21.8036 7.59228 21.8337 7.54214 21.86 7.49C21.86 7.44 21.91 7.41 21.93 7.36C21.9753 7.24534 21.9991 7.12329 22 7C21.9991 6.87671 21.9753 6.75466 21.93 6.64C21.93 6.59 21.88 6.56 21.86 6.51C21.8337 6.45786 21.8036 6.40773 21.77 6.36L16.77 0.360002C16.676 0.247119 16.5582 0.156339 16.4252 0.0941181C16.2921 0.0318971 16.1469 -0.000236672 16 1.91036e-06C15.7663 -0.000454603 15.5399 0.0809208 15.36 0.230002C15.2587 0.313951 15.175 0.417051 15.1137 0.533399C15.0523 0.649746 15.0145 0.777054 15.0025 0.908031C14.9904 1.03901 15.0043 1.17108 15.0433 1.29668C15.0824 1.42229 15.1458 1.53895 15.23 1.64L18.86 6H1C0.734784 6 0.48043 6.10536 0.292893 6.2929C0.105357 6.48043 0 6.73479 0 7C0 7.26522 0.105357 7.51957 0.292893 7.70711C0.48043 7.89464 0.734784 8 1 8Z"
      fill="#00AEEF"
    />
  </svg>
);
export const DashboardIcon = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="16" height="4.72222" fill="#C2C2C2"/>
  <rect y="6.61133" width="7.2" height="10.3889" fill="#C2C2C2"/>
  <rect x="8.80005" y="6.61133" width="7.2" height="10.3889" fill="#C2C2C2"/>
  </svg>

);
export const DashboardBlueIcon = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="16" height="4.72222" fill="#00AEEF"/>
  <rect y="6.61133" width="7.2" height="10.3889" fill="#00AEEF"/>
  <rect x="8.80005" y="6.61133" width="7.2" height="10.3889" fill="#00AEEF"/>
  </svg>
);
export const ClipboardIcon = () => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H0V20H18V2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM11 16H4V14H11V16ZM14 12H4V10H14V12ZM14 8H4V6H14V8Z" fill="#C2CCD9"/>
  </svg>


);
export const ClipboardBlueIcon = () => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H0V20H18V2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM11 16H4V14H11V16ZM14 12H4V10H14V12ZM14 8H4V6H14V8Z" fill="#00AEEF"/>
  </svg>
);
export const PinIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 10.0833C11.7594 10.0833 12.375 9.46774 12.375 8.70834C12.375 7.94895 11.7594 7.33334 11 7.33334C10.2406 7.33334 9.625 7.94895 9.625 8.70834C9.625 9.46774 10.2406 10.0833 11 10.0833Z"
      fill="#C2CCD9"
    />
    <path
      d="M11.0001 1.83334C9.0678 1.83325 7.21357 2.59579 5.84039 3.95524C4.46721 5.31468 3.68607 7.16116 3.66675 9.09334C3.66675 14.1167 10.1292 19.7083 10.4042 19.9467C10.5703 20.0887 10.7816 20.1667 11.0001 20.1667C11.2186 20.1667 11.4299 20.0887 11.5959 19.9467C11.9167 19.7083 18.3334 14.1167 18.3334 9.09334C18.3141 7.16116 17.533 5.31468 16.1598 3.95524C14.7866 2.59579 12.9324 1.83325 11.0001 1.83334ZM11.0001 11.9167C10.3655 11.9167 9.74523 11.7285 9.21763 11.376C8.69002 11.0234 8.2788 10.5224 8.03597 9.93612C7.79314 9.34987 7.7296 8.70478 7.85339 8.08243C7.97719 7.46007 8.28275 6.8884 8.73145 6.43971C9.18014 5.99102 9.75181 5.68545 10.3742 5.56166C10.9965 5.43786 11.6416 5.5014 12.2279 5.74423C12.8141 5.98706 13.3152 6.39828 13.6677 6.92589C14.0202 7.4535 14.2084 8.07379 14.2084 8.70834C14.2084 9.55925 13.8704 10.3753 13.2687 10.977C12.667 11.5787 11.851 11.9167 11.0001 11.9167Z"
      fill="#C2CCD9"
    />
  </svg>
);
export const MessageSquareIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M20 0H0.00999999L0 20L4 16H20V0ZM7 9H5V7H7V9ZM11 9H9V7H11V9ZM15 9H13V7H15V9Z" fill="#C2CCD9"/>
  </svg>

);
export const MessageSquareBlueIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M20 0H0.00999999L0 20L4 16H20V0ZM7 9H5V7H7V9ZM11 9H9V7H11V9ZM15 9H13V7H15V9Z" fill="#00AEEF"/>
  </svg>
);
export const FileTextIcon = () => (
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.975 15.75H12.025V14.25H3.975V15.75ZM3.975 11.5H12.025V10H3.975V11.5ZM0 20V0H10.525L16 5.475V20H0ZM9.775 6.15V1.5H1.5V18.5H14.5V6.15H9.775Z" fill="#C2C2C2"/>
  </svg>

);
export const BellIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.6502 19.0126L23.4002 16.7501V11.1751C23.4301 9.10483 22.71 7.09369 21.3729 5.51297C20.0357 3.93226 18.1718 2.88872 16.1252 2.57505C14.9375 2.41863 13.73 2.51732 12.5835 2.86452C11.4369 3.21172 10.3776 3.79945 9.47612 4.5885C8.57469 5.37755 7.8519 6.34977 7.35597 7.44029C6.86005 8.53082 6.6024 9.71456 6.60021 10.9126V16.7501L4.35021 19.0126C4.0676 19.2999 3.87594 19.6641 3.79921 20.0598C3.72248 20.4555 3.76408 20.865 3.91881 21.2371C4.07354 21.6092 4.33453 21.9275 4.66915 22.1522C5.00378 22.3768 5.39719 22.4978 5.80021 22.5001H10.0002V22.9251C10.0586 24.1941 10.6177 25.3882 11.5549 26.2457C12.4921 27.1033 13.731 27.5543 15.0002 27.5001C16.2694 27.5543 17.5083 27.1033 18.4456 26.2457C19.3828 25.3882 19.9418 24.1941 20.0002 22.9251V22.5001H24.2002C24.6032 22.4978 24.9966 22.3768 25.3313 22.1522C25.6659 21.9275 25.9269 21.6092 26.0816 21.2371C26.2363 20.865 26.2779 20.4555 26.2012 20.0598C26.1245 19.6641 25.9328 19.2999 25.6502 19.0126ZM17.5002 22.9251C17.4309 23.5263 17.1323 24.0776 16.6666 24.4642C16.2008 24.8507 15.6039 25.0427 15.0002 25.0001C14.3965 25.0427 13.7996 24.8507 13.3339 24.4642C12.8682 24.0776 12.5695 23.5263 12.5002 22.9251V22.5001H17.5002V22.9251Z"
      fill="#C2CCD9"
    />
  </svg>
);

export const SettingIcon = () => (
<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.478 10.54C16.518 10.24 16.538 9.93 16.538 9.6C16.538 9.28 16.518 8.96 16.468 8.66L18.498 7.08C18.678 6.94 18.728 6.67 18.618 6.47L16.698 3.15C16.578 2.93 16.328 2.86 16.108 2.93L13.718 3.89C13.218 3.51 12.688 3.19 12.098 2.95L11.738 0.41C11.698 0.17 11.498 0 11.258 0H7.418C7.178 0 6.988 0.17 6.948 0.41L6.588 2.95C5.998 3.19 5.458 3.52 4.968 3.89L2.578 2.93C2.358 2.85 2.108 2.93 1.988 3.15L0.0780003 6.47C-0.0419997 6.68 -0.00199977 6.94 0.198 7.08L2.228 8.66C2.178 8.96 2.138 9.29 2.138 9.6C2.138 9.91 2.158 10.24 2.208 10.54L0.178 12.12C-0.00199975 12.26 -0.0519996 12.53 0.0580004 12.73L1.978 16.05C2.098 16.27 2.348 16.34 2.568 16.27L4.958 15.31C5.458 15.69 5.988 16.01 6.578 16.25L6.938 18.79C6.988 19.03 7.178 19.2 7.418 19.2H11.258C11.498 19.2 11.698 19.03 11.728 18.79L12.088 16.25C12.678 16.01 13.218 15.69 13.708 15.31L16.098 16.27C16.318 16.35 16.568 16.27 16.688 16.05L18.608 12.73C18.728 12.51 18.678 12.26 18.488 12.12L16.478 10.54ZM9.338 13.2C7.358 13.2 5.738 11.58 5.738 9.6C5.738 7.62 7.358 6 9.338 6C11.318 6 12.938 7.62 12.938 9.6C12.938 11.58 11.318 13.2 9.338 13.2Z" fill="#C2CCD9"/>
</svg>

);

export const SettingBlueIcon = () => (
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.478 10.54C16.518 10.24 16.538 9.93 16.538 9.6C16.538 9.28 16.518 8.96 16.468 8.66L18.498 7.08C18.678 6.94 18.728 6.67 18.618 6.47L16.698 3.15C16.578 2.93 16.328 2.86 16.108 2.93L13.718 3.89C13.218 3.51 12.688 3.19 12.098 2.95L11.738 0.41C11.698 0.17 11.498 0 11.258 0H7.418C7.178 0 6.988 0.17 6.948 0.41L6.588 2.95C5.998 3.19 5.458 3.52 4.968 3.89L2.578 2.93C2.358 2.85 2.108 2.93 1.988 3.15L0.0780003 6.47C-0.0419997 6.68 -0.00199977 6.94 0.198 7.08L2.228 8.66C2.178 8.96 2.138 9.29 2.138 9.6C2.138 9.91 2.158 10.24 2.208 10.54L0.178 12.12C-0.00199975 12.26 -0.0519996 12.53 0.0580004 12.73L1.978 16.05C2.098 16.27 2.348 16.34 2.568 16.27L4.958 15.31C5.458 15.69 5.988 16.01 6.578 16.25L6.938 18.79C6.988 19.03 7.178 19.2 7.418 19.2H11.258C11.498 19.2 11.698 19.03 11.728 18.79L12.088 16.25C12.678 16.01 13.218 15.69 13.708 15.31L16.098 16.27C16.318 16.35 16.568 16.27 16.688 16.05L18.608 12.73C18.728 12.51 18.678 12.26 18.488 12.12L16.478 10.54ZM9.338 13.2C7.358 13.2 5.738 11.58 5.738 9.6C5.738 7.62 7.358 6 9.338 6C11.318 6 12.938 7.62 12.938 9.6C12.938 11.58 11.318 13.2 9.338 13.2Z" fill="#00AEEF"/>
</svg>
);

export const UserIcon = () => (
  <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.0024 4.5625C10.3897 4.5625 9.86435 4.33938 9.42639 3.89313C8.98843 3.44685 8.76944 2.9104 8.76944 2.28375C8.76944 1.65708 8.98761 1.11979 9.42394 0.671875C9.8603 0.223958 10.3848 0 10.9976 0C11.6103 0 12.1356 0.223124 12.5736 0.669374C13.0116 1.11565 13.2306 1.6521 13.2306 2.27875C13.2306 2.90542 13.0124 3.44271 12.5761 3.89062C12.1397 4.33854 11.6152 4.5625 11.0024 4.5625ZM7.73056 25V8.34375C6.34537 8.23958 5.00602 8.09375 3.7125 7.90625C2.41898 7.71875 1.18148 7.47917 0 7.1875L0.458333 5.3125C2.18981 5.72917 3.91111 6.02604 5.62222 6.20312C7.33333 6.38021 9.12593 6.46875 11 6.46875C12.8741 6.46875 14.6667 6.38021 16.3778 6.20312C18.0889 6.02604 19.8102 5.72917 21.5417 5.3125L22 7.1875C20.8185 7.47917 19.581 7.71875 18.2875 7.90625C16.994 8.09375 15.6546 8.24088 14.2694 8.34763V25H12.4361V16.875H9.56389V25H7.73056Z" fill="#C2C2C2"/>
</svg>

);

export const CheckListIcon = () => (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 2.5L2.5 4L5.5 1" stroke="#C2C2C2" stroke-width="1.5"/>
  <path d="M7.5 2.5H17.5" stroke="#C2C2C2" stroke-width="1.5"/>
  <path d="M1 8L2.5 9.5L5.5 6.5" stroke="#C2C2C2" stroke-width="1.5"/>
  <path d="M7.5 8H17.5" stroke="#C2C2C2" stroke-width="1.5"/>
  <path d="M1 13.5L2.5 15L5.5 12" stroke="#C2C2C2" stroke-width="1.5"/>
  <path d="M7.5 13.5H17.5" stroke="#C2C2C2" stroke-width="1.5"/>
  </svg>


);

export const CheckListBlueIcon = () => (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 2.5L2.5 4L5.5 1" stroke="#00AEEF" stroke-width="1.5"/>
  <path d="M7.5 2.5H17.5" stroke="#00AEEF" stroke-width="1.5"/>
  <path d="M1 8L2.5 9.5L5.5 6.5" stroke="#00AEEF" stroke-width="1.5"/>
  <path d="M7.5 8H17.5" stroke="#00AEEF" stroke-width="1.5"/>
  <path d="M1 13.5L2.5 15L5.5 12" stroke="#00AEEF" stroke-width="1.5"/>
  <path d="M7.5 13.5H17.5" stroke="#00AEEF" stroke-width="1.5"/>
  </svg>
);

export const ExportIcon = () => (
  <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 20V17.9289H13V20H0ZM5.41494 14.5852V3.93063L1.69026 7.49249L0.169647 6.04132L6.5 0L12.8304 6.04132L11.3097 7.49249L7.58506 3.93063V14.5852H5.41494Z" fill="#C2C2C2"/>
</svg>

);
export const RecoConfigIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.52778 20V13.75H10.1944V16.0556H20V17.7222H10.1944V20H8.52778ZM0 17.7222V16.0556H6.86111V17.7222H0ZM5.19444 13.1111V10.8333H0V9.16667H5.19444V6.83333H6.86111V13.1111H5.19444ZM8.52778 10.8333V9.16667H20V10.8333H8.52778ZM13.1389 6.25V0H14.8056V2.27778H20V3.94444H14.8056V6.25H13.1389ZM0 3.94444V2.27778H11.4722V3.94444H0Z" fill="#C2C2C2"/>
</svg>

);