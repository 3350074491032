import React, { useRef, useState } from 'react';
import { Grid, TextareaAutosize } from '@material-ui/core';
import {
  SmsContent,
  SmsInfoContainer,
  ParagraphContainer,
} from '../../Messages/SmsCreationDialog';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  contentContainer: {
    height: 200,
    overflow: 'scroll',
    cursor: 'text',
  },
  smsToSec: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  smsFromSec: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  smsMainContainer: {
    border: '1px solid #C2CCD9',
    borderRadius: 6,
    marginTop: 15,
  },
  divider: {
    borderBottom: '1px solid #C2CCD9',
  },
});

function SMSContent({ content, onChange }) {
  const classes = useStyles();
  const smsContent = useRef();

  return (
    <div className={classes.smsMainContainer}>
      <SmsInfoContainer>
        <Grid className={classes.smsFromSec}>
          <SmsContent>SMS Content</SmsContent>
          <SmsContent style={{ marginRight: '20%' }}>From: 456789</SmsContent>
        </Grid>
        <Grid className={classes.smsToSec}>
          <SmsContent style={{ marginLeft: '20%' }}>To: 567890</SmsContent>
          <div>
            <ArrowDropDownIcon style={{ cursor: 'pointer' }} />
          </div>
        </Grid>
      </SmsInfoContainer>

      <div className={classes.divider} />
      <ParagraphContainer
        onClick={() => smsContent.current.focus()}
        style={{ cursor: 'text' }}
        className={classes.contentContainer}
      >
        <TextareaAutosize
          ref={smsContent}
          aria-label="maximum height"
          placeholder="Type your message..."
          value={content}
          onChange={(e) => onChange(e.target.value)}
          style={{
            width: '100%',
            border: 'none',
            fontSize: 13,
            color: '#3F4857',
            fontFamily: 'Roboto Condensed',
            display: 'inline',
            lineHeight: 2,
            resize: 'none',
          }}
        ></TextareaAutosize>
      </ParagraphContainer>
    </div>
  );
}

export default SMSContent;
