import React, { Fragment, useState, useContext } from 'react';
import styled from 'styled-components';
import Box from '../../components/_styles/Box';

const AccordionContext = React.createContext({});

export const Panel = styled(Box)`
  background: #fff;
  border-radius: 8px;
  border: 1px solid #c2ccd9;
  margin-bottom: 15px;

  &:hover {
    border: 1px solid #00AEEF;
  }
`;
Panel.Header = styled.div`
  padding: 20px;
  cursor: pointer;

  .arrow-downward {
    ${props => (props.isOpen ? 'transform: rotate(-180deg);' : null)}
  }
`;
Panel.Body = styled.div`
  border-top: 1px solid #c2ccd9;
`;
const Accordion = ({ children }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <AccordionContext.Provider value={{ handleClick, open }}>
      <Panel>{children}</Panel>
    </AccordionContext.Provider>
  );
};
const AccordionHeader = ({ children }) => {
  const { handleClick, open } = useContext(AccordionContext);

  return (
    <Panel.Header isOpen={open} onClick={handleClick}>
      {children}
    </Panel.Header>
  );
};
const AccordionBody = ({ children }) => {
  const { open } = useContext(AccordionContext);

  return <Fragment>{open && <Panel.Body>{children}</Panel.Body>}</Fragment>;
};

export { Accordion, AccordionBody, AccordionHeader };
