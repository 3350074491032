import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import { ReactComponent as Search } from '../../assets/search.svg';
import { ReactComponent as ClearInput } from '../../assets/clear-field.svg';
import Flex from '../_styles/Flex';
import styled from 'styled-components';

const FormContainer = styled(Flex)`
  justify-content: space-between;
  @media (max-width: 768px) {
    padding-bottom: 65px;
  }
`;

const InputContainer = styled.div`
  width: 350px;
  @media (max-width: 1230px) {
    width: 270px;
  }
  @media (max-width: 1050px) {
    width: 220px;
  }
  @media (max-width: 768px) {
    width: 350px;
    margin-top: 30px;
  }
`;

const SearchIcon = styled.i`
  display: inline-block;
  position: absolute;
  margin-left: 50px;
  margin-top: -8px;
  padding: 8px;
  min-width: 45px;
  @media (max-width: 1230px) {
    margin-left: 7px;
  }
  @media (max-width: 995px) {
    margin-left: 0px;
  }
  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;

const Input = styled.input`
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  border-radius: 5px;
  height: 33px;
  text-align: center;
  font: normal 16px 'Open Sans', sans-serif;
`;

const CustomPlaceholder = styled.span`
  position: absolute;
  margin-top: 8px;
  margin-left: 100px;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  @media (max-width: 1230px) {
    margin-left: 40px;
  }
  @media (max-width: 995px) {
    margin-left: 30px;
  }
  @media (max-width: 768px) {
    margin-left: 60px;
  }
`;

const ClearButton = styled.button`
  position: absolute;
  margin-left: -40px;
  border-radius: 5%;
  z-index: 1;
  border: none;
  cursor: pointer;
  color: #000000;
  background-color: transparent;
  transform: translateX(2px);
  @media (max-width: 768px) {
    margin-left: -30px;
  }
`;

const SearchInput = ({ placeholder = '', handleSubmit, handleResetForm, style = {}, searchIconStyle={}, customPlaceholderStyle={}, inputStyle={} }) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const inputRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      searchValue: '',
    },
    onSubmit: values => handleSubmit(values),
  });

  const onShowPlaceholder = () => {
    if (!inputRef.current.value) {
      setShowPlaceholder(true);
    }
  };

  const onHidePlaceholder = () => {
    setShowPlaceholder(false);
  };

  const onResetForm = () => {
    formik.resetForm();
    handleResetForm();
    setShowPlaceholder(true);
  };

  return (
    <FormContainer>
      <form onSubmit={formik.handleSubmit}>
        <InputContainer style={style}>
          {showPlaceholder && (
            <>
              <SearchIcon style={searchIconStyle}>
                <Search />
              </SearchIcon>
              <CustomPlaceholder style={customPlaceholderStyle} onClick={onHidePlaceholder}>{placeholder}</CustomPlaceholder>
            </>
          )}
          <Input
            type="text"
            id={'searchValue'}
            name={'searchValue'}
            onFocus={onHidePlaceholder}
            onBlur={onShowPlaceholder}
            onChange={formik.handleChange}
            value={formik.values.searchValue}
            ref={inputRef}
            style={inputStyle}
          />
          {!showPlaceholder && inputRef.current.value && (
            <ClearButton type={'reset'} onClick={onResetForm}>
              <ClearInput/>
            </ClearButton>
          )}
        </InputContainer>
      </form>
    </FormContainer>
  );
};

export default SearchInput;
