import { uniqBy } from 'lodash';

import {
    GET_ASSIGNED_APPOINTMENTS_FETCH,
    GET_ASSIGNED_APPOINTMENTS_RESOLVE,
    GET_ASSIGNED_APPOINTMENTS_REJECT,
    GET_UNASSIGNED_APPOINTMENTS_FETCH,
    GET_UNASSIGNED_APPOINTMENTS_RESOLVE,
    GET_UNASSIGNED_APPOINTMENTS_REJECT,
    ASSIGN_APPOINTMENT_RESOLVE,
    UNASSIGN_APPOINTMENT_RESOLVE,
    SHOW_ALERT,
    HIDE_ALERT,
    SET_LAST_PAGE,
    RESET_STATE
} from '../actions/doctor';
import { SET_PROGRESS_BAR_VALUE } from '../actions/radminUploads';

const initialState = {
    importProgressBarValue: null,
    assignedAppointments: {
        items: [],
        lastPage: 1,
        loading: false,
        hasNextPage: false
    },
    unassignedAppointments: {
        items: [],
        lastPage: 1,
        loading: false,
        hasNextPage: false
    },
    alert: {
        type: '',
        headline: '',
        children: '',
        timeout: 0,
        active: false
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
    case GET_ASSIGNED_APPOINTMENTS_FETCH:
        return {
            ...state,
            assignedAppointments: {
                ...state.assignedAppointments,
                loading: true
            }
        };
    case GET_ASSIGNED_APPOINTMENTS_RESOLVE:
        return {
            ...state,
            assignedAppointments: {
                ...state.assignedAppointments,
                loading: false,
                items: uniqBy([...state.assignedAppointments.items, ...action.payload.results], 'id'),
                hasNextPage: Boolean(action.payload.next)
            }
        };
    case GET_ASSIGNED_APPOINTMENTS_REJECT:
        return {
            ...state,
            assignedAppointments: {
                ...initialState.assignedAppointments
            }
        };
    case GET_UNASSIGNED_APPOINTMENTS_FETCH:
        return {
            ...state,
            unassignedAppointments: {
                ...state.unassignedAppointments,
                loading: true
            }
        };
    case GET_UNASSIGNED_APPOINTMENTS_RESOLVE:
        return {
            ...state,
            unassignedAppointments: {
                ...state.unassignedAppointments,
                loading: false,
                items: uniqBy([...state.unassignedAppointments.items, ...action.payload.results], 'id'),
                hasNextPage: Boolean(action.payload.next)
            }
        };
    case GET_UNASSIGNED_APPOINTMENTS_REJECT:
        return {
            ...state,
            unassignedAppointments: {
                ...initialState.unassignedAppointments
            }
        };
    case ASSIGN_APPOINTMENT_RESOLVE:
        return {
            ...state,
            assignedAppointments: {
                ...state.assignedAppointments,
                items: [...state.assignedAppointments.items, action.payload]
            },
            unassignedAppointments: {
                ...state.unassignedAppointments,
                items: state.unassignedAppointments.items.filter((i) => i.id !== action.payload.id)
            }
        };
    case UNASSIGN_APPOINTMENT_RESOLVE:
        return {
            ...state,
            assignedAppointments: {
                ...state.assignedAppointments,
                items: state.assignedAppointments.items.filter((i) => i.id !== action.payload.id)
            },
            unassignedAppointments: {
                ...state.unassignedAppointments,
                items: [...state.unassignedAppointments.items, action.payload]
            }
        };
    case SHOW_ALERT:
        return {
            ...state,
            alert: {
                ...action.payload
            }
        };
    case HIDE_ALERT:
        return {
            ...state,
            alert: {
                ...initialState.alert
            }
        };
    case SET_LAST_PAGE:
        return {
            ...state,
            [action.payload.type]: {
                ...state[action.payload.type],
                lastPage: action.payload.page
            }
        };
    case SET_PROGRESS_BAR_VALUE:
        return {
            ...state,
            importProgressBarValue: action.payload
        };
    case RESET_STATE:
        return {
            ...initialState
        };
    default:
        return state;
    }
};
