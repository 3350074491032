import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import moment from 'moment';
import { Title } from './RecomendationModalities';
import { isArray } from 'lodash';
import { Row, Col, Spinner } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip,Legend } from 'recharts';
import DatePicker from 'react-datepicker';
import Flex from '../../../components/_styles/Flex';
import LegendItem, { Square } from './LegendItem';
import { ReactComponent as TriangleDownIcon } from '../../../assets/triangle-down.svg';
import Filter from './RecommendationFilter';
import { CloseSign, Image, SelectedItemContainer, SelectedItem } from './../FollowUpList/FollowUpList'
import { filterTypes, restStatusesObj } from '../../RadAdmin/FollowUpList/FollowUpList';
import {  DEFAULT_VALUES } from './HistoricalAdheranceFilter';
import close from '../../../assets/cancel-outline-chip.svg';
import RoundedBar from './RoundedBar';
import { useSelector } from 'react-redux';


const SelectedFilters = styled.div`
    display:'flex',
    flex-wrap:'wrap',
    width:'100%',
    justify-content: 'right'
`

const FilterCard = styled(Flex)`
   justify-content: flex-end;
   flex-grow: 1;
`;

export const Card = styled.div`
  background: #FFFFFF;
  border: 1px solid #C2CCD9;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  border-radius: 8px;
`

export const TitleAndLegend = styled(Flex)`
  // padding-bottom: 10px;
  // border-bottom: 1px solid #EEF0F7;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  display: block;
  margin: 16px 16px 50px 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const DatePickerContainer = styled(Flex)`
& .react-datepicker__input-container > div {
 height:38px;
}
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`

export const TooltipContainer = styled.div`
  padding: 10px;
  background: #E3E3E3;
  border-radius: 8px;
`

export const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D2D3D7;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
`

export const DateContainer = styled(Flex)`
  margin-right: 15px;
`

export const TooltipRow = styled(Flex)`
  align-items: center;
`

const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items:center;
  flex-wrap:wrap;
`

const COLORS = {
  PURPLE: '#6F52ED',
  RED: '#CE2030',
  GREEN: '#22B66E',
}


export class DatePickerInput extends React.Component {
  formatDate = (date) => {
    return date ? moment(date).format('MMM, YY') : 'No Date';
  }

  render(props) {
    const { startDate, endDate } = this.props
    return (
      <DatePickerButton onClick={this.props.onClick}>
        <DateContainer>{`${this.formatDate(startDate)} - ${this.formatDate(endDate)}`}</DateContainer>
        <TriangleDownIcon />
      </DatePickerButton>
    )
  }
}

// export const DatePickerInput = ({ onClick, startDate, endDate }) => {
//   const formatDate = (date) => {
//     return date ? moment(date).format('MMM, YY') : 'No Date';
//   }
//   return (
//     <DatePickerButton onClick={onClick}>
//       <DateContainer>{`${formatDate(startDate)} - ${formatDate(endDate)}`}</DateContainer>
//       <TriangleDownIcon />
//     </DatePickerButton>
//   )
// };



const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    return (
      <TooltipContainer> 
        <div>{moment(label).format('MMM, YY')}</div>
        <TooltipRow>
          <Square color={COLORS.PURPLE} />
          <div>{`Total Imaging Exams: ${payload[0]?.payload?.imagingExamsCount}`}</div>
        </TooltipRow>
        <TooltipRow>
          <Square color={COLORS.RED} />
          <div>{`Follow-Ups Recommended: ${payload[0]?.payload?.followUpsMadeCount}`}</div>
        </TooltipRow> 
      </TooltipContainer>
    );
  }

  return null;
};

export const CustomTick = ({ x, y, payload }) => {
  return (
    <text stroke="none" className="recharts-text recharts-cartesian-axis-tick-value" textAnchor="middle" x={x} y={y} dy={16} fill="#666">{moment(payload.value).format('MMM, YY')}</text>
  )
}

const ImagingAdherenceChart = ({ data, handleFiltersChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [filterValuesForRequest, setFilterValuesForRequest] = useState(DEFAULT_VALUES);
  const [filterBy, setFilterBy] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  const minDate = data && data?.length && new Date(data[0].date);
  const maxDate = data && data?.length && new Date(data[data.length - 1].date);

  const loading = useSelector(state => state.radAdmin.imagingAdherence.isLoading);


  useEffect(() => {
    if (data?.length) {
      if (data?.length > 0) {
        if (data.length > 6) {
          setStartDate(moment(data[data.length - 6].date, "MM/DD/YYYY").toDate());
          setEndDate(moment(data[data.length - 1].date, "MM/DD/YYYY").toDate());
          return
        }
        setStartDate(moment(data[0].date, "MM/DD/YYYY").toDate());
        setEndDate(moment(data[data.length - 1].date, "MM/DD/YYYY").toDate());
      } else {
        setStartDate(Date.now());
        setEndDate(Date.now());
      }
    }else{
      setFilteredData(data)
    }
  }, [data]);

  useEffect(() => {
    if (startDate && endDate && data?.length) {
      setFilteredData(data.filter(d => (
        d.timestamp >= startDate.getTime() && d.timestamp <= endDate.getTime()
      )));
    }
  }, [startDate, endDate]);

  const onChange = dates => {
    if (isArray(dates)) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      window.analytics.track('Recommendations-Chart-Datetime-Selection');
    }
  };

  const closeSelected = (category, selectedItem) => {
    setPageNumber(1);
    setFilterValuesForRequest( (prev) =>({ ...prev, [category]: "" }));
};

useEffect(()=>{
  handleFiltersChange(filterValuesForRequest)
    },[filterValuesForRequest])

  // if (!filteredData) return null;
  return (
    <Card>
      <Header>
      <Row>
        <Col md={12} sm={12} lg={12} xl={12}>
          <Row >
          <Col>
            <TitleAndLegend>
              <Title right='50px'>Reports to Recommendations</Title>
              <DatePickerContainer>
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MM/yyyy"
                  customInput={<DatePickerInput startDate={startDate} endDate={endDate} />}
                  shouldCloseOnSelect={false}
                  selectsRange
                  showMonthYearPicker
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </DatePickerContainer>
            </TitleAndLegend>
            </Col>
           
            <Col >
            <div style={{ display: "flex", alignItems:'center', height:'100%' }}>
              <SelectedFilters
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  justifyContent: "right",
                  alignItems: "center",
                  fontSize:'10px'
                }}
              >
                {Object.values(filterValuesForRequest).map((item,idx) => {
                  if (!item) return null;
                 return  <SelectedItemContainer style={{display:'flex', alignItems:'center'}} key={idx}>
                      {Number.isInteger(item.value)
                        ? item.label
                        : item.value.replace(/_/g, " ")}
                      <CloseSign onClick={() => closeSelected(item.category, item)}>
                        <Image src={close} alt={"close sign"} />
                      </CloseSign>
                    </SelectedItemContainer>
                })}
              </SelectedFilters>
              <FilterCard>
                <Filter
                  filterBy={filterBy}
                  setFilter={setFilterBy}
                  filterTypes={filterTypes}
                  setFilterBy={setFilterBy}
                  setPageNumber={setPageNumber}
                  filterValuesForRequest={filterValuesForRequest}
                  setFilterValuesForRequest={setFilterValuesForRequest}
                  marginRight="true"
                />
              </FilterCard>
            </div>
          </Col>
          </Row>
        </Col>
       
      </Row>
      </Header>

      <ResponsiveContainer width='100%' height={320}>
      {
                loading?  
                <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner  animation="border" role="status" size="sm" /> 
              </div>:  <BarChart
          data={filteredData}
          margin={{
            top: 20, right: 20, bottom: 20, left: 20,
          }}
        >
          <CartesianGrid strokeDasharray="5 5" vertical={false} />
          <XAxis dataKey="date" axisLine={false} tickLine={false} scale="point" padding={{ left: 40, right: 40 }} tick={<CustomTick />} />
          <YAxis axisLine={false} tickLine={false} />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="imagingExamsCount" stackId="a" shape={<RoundedBar />} barSize={30} fill={COLORS.PURPLE} />
          <Bar dataKey="none" barSize={10} />
          <Bar dataKey="followUpsMadeCount" shape={<RoundedBar />} barSize={30} fill={COLORS.RED} />

          <Legend
                  verticalAlign="bottom"
                  align="center"
                  iconType="rect"
                  layout="horizontal"
                  payload={[
                    {
                      value: 'Total Imaging Exams',
                      type: 'rect',
                      color: COLORS.PURPLE,
                    
                    },
                    {
                      value: 'Follow-Ups Recommended',
                      type: 'rect',
                      color: COLORS.RED,
                     
                    },
                  ]}
                  wrapperStyle={{
                    fontSize: '13px',      
                    fontWeight: 600, 
                    margin: '-10px 0', 
                  }}  
                />
      
        </BarChart> }
       
      </ResponsiveContainer>
    </Card>
  )
};

export default ImagingAdherenceChart;
