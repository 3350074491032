import React from 'react';
import styled from 'styled-components';

import RightPanelDetails from './RightPanelDetails';

const PanelHolder = styled.div`
  border: 2px solid #e3e6eb;
  box-sizing: border-box;
  border-radius: 12px;
  margin-left: 40px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  text-align: start;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const PanelHeader = styled.div`
  display: flex;
`;

const PanelHeaderTextHolder = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
`;

const PanelHeaderHeadline = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #2e2f30;
`;

const PanelHeaderDescription = styled.div`
  font-size: 14px;
  line-height: 140.5%;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 10px;
`;

function SchedulingRightPanel() {
  return (
    <div className='five-cols my-auto'>
      <PanelHolder>
        <PanelHeader>

          <PanelHeaderTextHolder>
            <PanelHeaderHeadline>The Basics</PanelHeaderHeadline>
            <PanelHeaderDescription>
              We are looking to capture some basic contact information so that we can get in touch with you about your exam.
            </PanelHeaderDescription>
          </PanelHeaderTextHolder>
        </PanelHeader>
        <RightPanelDetails />
      </PanelHolder>
    </div>
  );
}

export default SchedulingRightPanel;
