import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import PersonalInfo from '../../components/Scheduling/EnterPersonalInfo';
import MedicalHistory from '../../components/Scheduling/MedicalHistory';
import SelectTime from '../../components/Scheduling/SelectTime';
import SelectLocation from '../../components/Scheduling/PreScanRadiologySearchPage';
import Payment from '../../components/Scheduling/PaymentInfo';
import { serializeStateToUrl, deserializeStateFromUrl } from '../../actions/checkout';

/*
Checkout flow:

ONLINE: CHECKOUT_PERSONAL_INFO -> CHECKOUT_MEDICAL_HISTORY -> CHECKOUT_SELECT_LOCATION -> CHECKOUT_PAYMENT

VOICE: CHECKOUT_PERSONAL_INFO -> CHECKOUT_SELECT_TIME -> CHECKOUT_SELECT_LOCATION -> CHECKOUT_PAYMENT
*/

const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const step = useSelector((state) => state.checkout.currentStep);

  useEffect(() => {
    dispatch(deserializeStateFromUrl(history));
  }, [dispatch, history]);

  useEffect(() => {
    if (step && isLoggedIn) {
      dispatch(serializeStateToUrl(history));
    }
  }, [dispatch, history, step, isLoggedIn]);

  if (!isLoggedIn) {
    return <Redirect to='/login' />;
  }

  if (step === 'CHECKOUT_PERSONAL_INFO') {
    return <PersonalInfo />;
  }

  if (step === 'CHECKOUT_MEDICAL_HISTORY') {
    return <MedicalHistory />;
  }

  if (step === 'CHECKOUT_SELECT_TIME') {
    return <SelectTime />;
  }

  if (step === 'CHECKOUT_SELECT_LOCATION') {
    return <SelectLocation />;
  }

  if (step === 'CHECKOUT_PAYMENT') {
    return <Payment />;
  }

  return null;
};

export default Index;
